import { DateLike } from './isDateLike';

/**
 * Gets the number of days in a month
 */
export const daysInMonth = (date: DateLike): number => {
  const d = new Date(date);
  d.setMonth(d.getMonth() + 1, 0);
  return d.getDate();
};
