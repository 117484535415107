import * as React from 'react';

import tokens from '@cobbler-io/design-tokens';

import { cx } from '@cobbler-io/utils/src/cx';

import { Button } from '@cobbler-io/core-ui/src/Button';
import { Card } from '@cobbler-io/core-ui/src/Card';
import { Delay } from '@cobbler-io/core-ui/src/Delay';
import { LoadingDots } from '@cobbler-io/core-ui/src/LoadingDots';
import { Logo } from '@cobbler-io/core-ui/src/Logo';

import styles from './Login.scss';

type Props = {
  onClick: () => void;
  loading?: boolean;
  disabled?: boolean;
}

export const Login: React.FC<Props> = props => {
  const { disabled = false, onClick = () => {}, loading = false } = props;

  return (
    <div className={styles.login}>
      <Delay ms={1000}>
        <Card className={cx(styles.box, 'fade-in')}>
          <Card.Header as="h1">
            <Logo className="flex-center" width={150} />
          </Card.Header>
          <div className={styles.buttonContainer}>
            <Button disabled={disabled || loading} name="login" onClick={onClick}>
              {disabled && 'Login Unavailable'}
              {loading && <LoadingDots color={tokens.colorPaper} size={30} />}
              {!disabled && !loading && 'Login'}
            </Button>
          </div>
        </Card>
      </Delay>
    </div>
  );
};

Login.displayName = 'Login';

export default Login;
