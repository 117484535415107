/* eslint-disable sort-keys, sort-keys-fix/sort-keys-fix, @typescript-eslint/no-magic-numbers */
import { DateRange } from './DateRange';
import { getEndOfMonth } from './getEndOfMonth';
import { getStartOfMonth } from './getStartOfMonth';

const monthName = new Intl.DateTimeFormat(undefined, {
  month: 'long',
  year: 'numeric',
});

const shortMonthName = new Intl.DateTimeFormat(undefined, {
  month: 'short',
  year: 'numeric',
});

const maybePad0 = (x0: number): string => (x0 < 10 ? `0${x0}` : `${x0}`);

export const ISO8601 = (x0: Date): string =>
  [x0.getFullYear(), ...[x0.getMonth() + 1, x0.getDate()].map(maybePad0)].join('-');

export const getFiscalYearForDate = (x0: Date, startMonth: number): number =>
  x0.getFullYear() - (x0.getMonth() < startMonth ? 1 : 0);

export const getQuarterForDate = (x0: Date, startMonth: number): number => {
  const m = x0.getMonth() - startMonth;

  return Math.floor((m < 0 ? 11 - ~m : m) / 3) + 1;
};

/**
 * Gets a reproducible string form a of month
 */
export const getMonthId = (x0: Date): string =>
  ['month', x0.getFullYear(), maybePad0(x0.getMonth() + 1)].join('-');

export const getQuarterId = (x0: Date, startMonth: number): string => {
  const fiscalYear = getFiscalYearForDate(x0, startMonth);
  const quarter = getQuarterForDate(x0, startMonth);

  return ['quarter', quarter, fiscalYear].join('-');
};

export const getFiscalYearId = (x0: Date, startMonth: number): string => {
  return ['fiscal-year', getFiscalYearForDate(x0, startMonth)].join('-');
};

export type Period = {
  id: string;
  name: string;
  shortName: string;
  start: Date;
  end: Date;
  range: DateRange;
};

type WithQuarter<T> = T & { quarter: number };

type WithFiscalYear<T> = T & { fiscalYear: number };

export type MonthPeriod = WithFiscalYear<WithQuarter<Period>>;

export type QuarterPeriod = WithFiscalYear<WithQuarter<Period>>;

export type FiscalYearPeriod = WithFiscalYear<Period>;

export const getMonth = (x0: Date, startMonth: number): MonthPeriod => {
  const e = new Date(getEndOfMonth(x0));

  const fiscalYear = getFiscalYearForDate(x0, startMonth);
  const quarter = getQuarterForDate(x0, startMonth);

  return {
    id: getMonthId(x0),
    name: monthName.format(x0),
    shortName: shortMonthName.format(x0),
    start: x0,
    end: e,
    quarter,
    fiscalYear,
    range: new DateRange(x0, e),
  };
};

export const getQuarter = (q: number, fiscalYear: number, startMonth: number): QuarterPeriod => {
  const start = new Date(fiscalYear, (q - 1) * 3 + startMonth, 1, 0, 0, 0, 0);
  const e = new Date(start);
  e.setMonth(e.getMonth() + 2);
  const end = getEndOfMonth(e);

  return {
    id: getQuarterId(start, startMonth),
    name: `Q${q} ${fiscalYear}`,
    shortName: `Q${q}`,
    start,
    end,
    quarter: q,
    fiscalYear,
    range: new DateRange(start, end),
  };
};

export const getFiscalYear = (fiscalYear: number, startMonth: number): FiscalYearPeriod => {
  const start = new Date(fiscalYear, startMonth, 1, 0, 0, 0, 0);
  const e = new Date(start);
  e.setMonth(e.getMonth() + 11);
  const end = getEndOfMonth(e);

  return {
    id: getFiscalYearId(start, startMonth),
    name: `FY${fiscalYear}`,
    shortName: `${fiscalYear}`,
    start,
    end,
    fiscalYear,
    range: new DateRange(start, end),
  };
};

/* eslint-disable functional/immutable-data */

export type DatePeriod = MonthPeriod | QuarterPeriod | FiscalYearPeriod;

export type PeriodsInRange = {
  months: MonthPeriod[];
  quarters: QuarterPeriod[];
  fiscalYears: FiscalYearPeriod[];
};

/**
 * Returns the overlapping months, quarters, and fiscal years in a date range
 *
 * Takes non-local dates
 */
export const getPeriodsInRange = (start: Date, end: Date, startMonth: JSMonth): PeriodsInRange => {
  const months: Record<string, MonthPeriod> = {};
  const quarters: Record<string, QuarterPeriod> = {};
  const fiscalYears: Record<string, FiscalYearPeriod> = {};

  const reference = getStartOfMonth(start); // ensure that we start at the beginning

  // eslint-disable-next-line no-unmodified-loop-condition
  while (reference < end) {
    const monthId = getMonthId(reference);
    if (monthId in months) {
      // We already have the month id, so let's not remake it
      reference.setMonth(reference.getMonth() + 1);
      continue; // eslint-disable-line no-continue
    }

    const fiscalYear = getFiscalYearForDate(new Date(reference), startMonth);
    const quarter = getQuarterForDate(new Date(reference), startMonth);

    months[monthId] = getMonth(new Date(reference), startMonth);

    const quarterId = getQuarterId(reference, startMonth);
    const fiscalYearId = getFiscalYearId(reference, startMonth);

    if (!(quarterId in quarters)) {
      quarters[quarterId] = getQuarter(quarter, fiscalYear, startMonth);
    }

    if (!(fiscalYearId in fiscalYears)) {
      fiscalYears[fiscalYearId] = getFiscalYear(fiscalYear, startMonth);
    }

    reference.setMonth(reference.getMonth() + 1);
  }

  return {
    months: Object.values(months),
    quarters: Object.values(quarters),
    fiscalYears: Object.values(fiscalYears),
  };
};

/* eslint-enable functional/immutable-data */
