import * as React from 'react';

const randomText = () =>
  Math.random()
    .toString(36)
    .slice(2);

export const useDefaultOrRandom = (def?: string) => React.useMemo(() => def || randomText(), [def]);

export default useDefaultOrRandom;
