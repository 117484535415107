import * as React from 'react';

import { useToggle } from '@cobbler-io/hooks/src/useToggle';

import { Spinner, SpinnerProps } from '@cobbler-io/core-ui/src/Spinner';

import styles from './Loading.scss';

const useTimer = (ms = 2000) => {
  const { active, activate } = useToggle(false);

  React.useEffect(() => {
    const timer = setTimeout(() => requestAnimationFrame(activate), ms);

    return () => clearTimeout(timer);
  });

  return active;
};

export type LoadingProps = SpinnerProps & {
  /**
   * Delay before showing the spinner in ms
   * default: `2000`
   */
  delay?: number;
};

export const Loading = ({ delay = 1500, ...props }: LoadingProps): JSX.Element | null => {
  const load = useTimer(delay);

  return load ? <Spinner {...props} className={styles.default} size="4rem" /> : null;
};

Loading.displayName = 'Loading';

export default Loading;
