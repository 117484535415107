import { useEffect } from 'react';

import { useClickAwayContext } from './ClickAwayContext';
import { ClickAwayTarget } from './types';

export const useClickAway = <T extends HTMLElement>(
  ref?: ClickAwayTarget<T>,
  callback?: AnyFn,
  disable?: boolean,
): void => {
  const { register, unregister } = useClickAwayContext<T>();

  useEffect(() => {
    if (!ref || !callback) {
      console.error('Failed to register ClickAway listener: either ref or callback is empty.');
      return;
    }

    if (!disable) {
      register(ref, callback);
    }

    // eslint-disable-next-line consistent-return
    return () => unregister(ref, callback);
  }, [ref, callback, disable, register, unregister]);
};
