/* eslint-disable max-lines-per-function */
import * as React from 'react';

import { cx } from '@cobbler-io/utils/src/cx';
import { isFunction } from '@cobbler-io/utils/src/isFunction';

import { useForwardedRef } from '@cobbler-io/hooks/src/useForwardedRef';
import { useGetLatest } from '@cobbler-io/hooks/src/useGetLatest';

import { useClickAway } from '../ClickAway';
import { useCurrentModal } from '../Modal';
import { ClearButton } from './ClearButton';
import { DropdownPopup } from './DropdownPopup';
import { Item } from './Item';
import { Listbox } from './Listbox';
import { ListboxNoOptions } from './ListboxNoOptions';
import { ListboxOption } from './ListboxOption';
import { ToggleButton } from './ToggleButton';
import { useDropdown, UseDropdownParams } from './useDropdown';
import { useSFInterface, ValidateMultipleFn, ValidateSingleFn } from './useSFInterface';

import styles from './Dropdown.scss';

type DropdownSingle<TValue extends any> = {
  multiple?: false;
  defaultValue?: TValue;
  validate?: ValidateSingleFn<TValue> | ValidateSingleFn<TValue>[];
};

type DropdownMultiple<TValue extends any> = {
  multiple: true;
  defaultValue?: TValue[];
  validate?: ValidateMultipleFn<TValue> | ValidateMultipleFn<TValue>[];
};

type DropdownBaseProps<TValue extends any> = UseDropdownParams<TValue> & {
  // Add props here
  id: string;
  name: string;
  autoFocus?: boolean;
  readonly?: boolean;
  disabled?: boolean;
  open?: boolean;
  defaultOpen?: boolean;
  activeIndex?: number;
  defaultActiveIndex?: number;
  items: Item<TValue>[];
  /**
   * Run when the popup list closes
   */
  onCloseModal?: (...args: any[]) => any;

  /**
   * Run when the popup list opens
   */
  onOpenModal?: (...args: any[]) => any;

  /**
   * The label for the component
   */
  label?: string;

  /**
   * The max number of suggestions to show
   */
  limitSuggestions?: number;

  /**
   * Whether or not to show a clear button
   *
   * default: `false`
   */
  clearButton?: boolean;

  /**
   * Allow multiple values
   *
   * default: `false`
   */
  multiple?: boolean;

  /**
   * Whether or not to close the list after selecting an item
   *
   * default: `false`
   */
  preventCloseOnSelect?: boolean;

  className?: string;

  dropdownClassName?: string;

  dropdownStyles?: React.CSSProperties;

  onChange?: (value: TValue | TValue[]) => any;
};

export type DropdownProps<TValue extends any = any> = DropdownBaseProps<TValue> &
  XOR<DropdownSingle<TValue>, DropdownMultiple<TValue>>;

const getDefaultValue = <TValue extends any = any>(
  value: TValue | TValue[] | undefined,
  items: Item<TValue>[],
): Item<TValue>[] => {
  const tmp = Array.isArray(value) ? value : [value];

  // Does not support items that are not in items array...
  return tmp.map(val => items.find(item => item.value === val)).filter(Boolean) as Item<TValue>[];
};

const InlineTextValues = <TValue extends any = any>(props: { selected: Item<TValue>[] }) => {
  const { selected } = props;

  return (
    <>
      {selected.map((item: Item<TValue>) => {
        if (item.selectedLabel) {
          return (
            <span key={item.id}>
              {isFunction(item.selectedLabel) ? item.selectedLabel(item.value) : item.selectedLabel}
            </span>
          );
        }

        if (isFunction(item.label)) {
          return <span key={item.id}>{item.label(item.value)}</span>;
        }

        if (typeof item.label === 'string' || React.isValidElement(item.label)) {
          return <span key={item.id}>{item.label}</span>;
        }

        // This is obviously not a good path
        return null;
      })}
    </>
  );
};

InlineTextValues.displayName = 'Dropdown__InlineTextValues';

export const Dropdown = React.forwardRef(
  (props: DropdownProps, forwardRef: React.RefObject<HTMLDivElement>): JSX.Element => {
    const dropdownHook = useDropdown({
      ...props,
      defaultSelected: getDefaultValue(props.defaultValue, props.items),
    });
    const {
      open,
      getComboBoxProps,
      getInputProps,
      getClearButtonProps,
      getItemProps,
      getListboxProps,
      getToggleButtonProps,
      activeItems,
      closeList,
      clearFilter,
      selected,
      removeSelected,
      filter,
      disabled,
      readonly,
      refs,
    } = dropdownHook;

    const getSelected = useGetLatest(selected);

    const currentModal = useCurrentModal();
    currentModal?.watchRef?.(refs.comboBox);
    currentModal?.watchRef?.(refs.listBox);

    const ref = useForwardedRef(forwardRef);
    const containerRef = refs.comboBox;

    const clickAwayRefs = React.useRef<React.RefObject<HTMLDivElement>[]>([
      refs.comboBox,
      refs.listBox,
    ]);

    useClickAway(clickAwayRefs, () => {
      closeList();
      clearFilter();
    });

    const { errors, focus } = useSFInterface<any>({
      ...props,
      ...dropdownHook,
      getSelected,
      inputRef: refs.input,
      ref,
    });

    /* eslint-disable jsx-a11y/click-events-have-key-events,
                      jsx-a11y/no-static-element-interactions
    */

    const { label, id, className, multiple, clearButton, dropdownClassName, dropdownStyles } =
      props;

    return (
      <div className={cx(styles.container, errors.length > 0 && styles.hasErrors, className)}>
        {label && (
          <label className={styles.label} htmlFor={id}>
            {label}
          </label>
        )}
        <div
          {...getComboBoxProps()}
          className={cx(styles.combobox, styles[multiple ? 'multiple' : 'single'])}
          onClick={focus}
        >
          <div className={styles.values}>
            {/* Only show the selected value if multiple or single but not filtering */}

            <InlineTextValues selected={selected} />

            <input
              {...getInputProps()}
              readOnly
              className={cx(styles.textbox, 'listbox-textbox')}
              placeholder={!selected?.length ? props.placeholder : ''}
              type="search"
            />
          </div>

          <div className={styles.controls}>
            {clearButton && !readonly && !disabled && Boolean(selected.length || filter) && (
              <ClearButton {...getClearButtonProps()} className="listbox-button-clear" />
            )}
            <ToggleButton {...getToggleButtonProps()} className="listbox-button-toggle" />

            {open && (
              <DropdownPopup
                ref={refs.listBox}
                className={cx(dropdownClassName, 'listbox-dropdown-popup')}
                reference={containerRef}
                style={dropdownStyles}
              >
                <Listbox {...getListboxProps()}>
                  {activeItems.length === 0 && <ListboxNoOptions />}
                  {activeItems.map((item: Item, index: number) => (
                    // eslint-disable-next-line react/jsx-key
                    <ListboxOption {...getItemProps(item, index)} />
                  ))}
                </Listbox>
              </DropdownPopup>
            )}
          </div>
        </div>
        <div className={styles.errors}>
          {errors.map(err => (
            <div key={JSON.stringify(err)} className={styles.error}>
              {err}
            </div>
          ))}
        </div>
      </div>
    );
  },
);

Dropdown.displayName = 'Dropdown';

export default Dropdown;
