/* eslint-disable sort-keys-fix/sort-keys-fix */
/* eslint-disable functional/no-this-expression */
/* eslint-disable no-restricted-syntax */
/* eslint-disable functional/no-class */
import { DateLike, isDateLike } from './isDateLike';

export type DateRangeObject = {
  start: Date;
  end: Date;
};

export class DateRange {
  start: Date;

  end: Date;

  constructor(start: DateRangeObject | DateRange, end?: never);
  constructor(start: DateLike, end: DateLike);
  constructor(start: DateLike | DateRangeObject | DateRange, end?: DateLike) {
    if (start instanceof DateRange) {
      this.start = start.start;
      this.end = start.end;
    } else if (isDateLike(start)) {
      this.start = new Date(start);

      if (!isDateLike(end)) {
        throw new TypeError('new DateRange(string | Date, string | Date) takes 2 arguments');
      }

      this.end = new Date(end);
    } else {
      this.start = new Date(start.start);
      this.end = new Date(start.end);
    }
  }

  asStringRange(): { start: string; end: string } {
    const options = { year: 'numeric', month: 'numeric', day: 'numeric' } as const;

    return {
      start: this.start.toLocaleString('sv-SE', options),
      end: this.end.toLocaleString('sv-SE', options),
    };
  }

  includes(date: DateLike | DateRange): boolean {
    if (date instanceof Date) {
      return this.start <= date && date <= this.end;
    }

    if (isDateLike(date)) {
      return this.includes(new Date(date));
    }

    if (date instanceof DateRange) {
      return this.includesRange(date);
    }

    throw new TypeError('`date` must be a Date or a DateRange');
  }

  includesEvery(...dates: (DateLike | DateRange)[]): boolean {
    return dates.every(d => this.includes(d));
  }

  includesSome(...dates: (DateLike | DateRange)[]): boolean {
    return dates.some(d => this.includes(d));
  }

  includesRange(range: DateRange): boolean {
    return this.includes(range.start) && this.includes(range.end);
  }

  overlaps(range: DateRange): boolean {
    return (
      this.includes(range.start) ||
      this.includes(range.end) ||
      range.includes(this.start) ||
      range.includes(this.end)
    );
  }

  overlapsEvery(...ranges: DateRange[]): boolean {
    return ranges.every(r => this.overlaps(r));
  }

  overlapsSome(...ranges: DateRange[]): boolean {
    return ranges.some(r => this.overlaps(r));
  }
}
