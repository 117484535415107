/* eslint-disable no-negated-condition */
import * as React from 'react';

import { head } from '@cobbler-io/utils/src/array/head';

import Fuse from 'fuse.js';
import { equals, includes } from 'ramda';

import { Item } from './Item';

export type State<TValue extends any> = {
  activeIndex: number;
  filter: string;
  selected: Item<TValue>[];
  open: boolean;
  items: Item<TValue>[];
  activeItems: Item<TValue>[];

  initialActiveIndex: number;
  initialFilter: string;
  initialSelected: Item<TValue>[];
  initialOpen: boolean;
  initialActiveItems: Item<TValue>[];
  adjustActiveItems?: (input: string, items: Item<TValue>[]) => Item<TValue>[];
};

export type Action<TValue extends any = any> =
  | { type: 'SELECTED/ADD'; selected: Item<TValue> }
  | { type: 'SELECTED/REMOVE'; selected: Item<TValue> }
  | { type: 'SELECTED/TOGGLE'; selected: Item<TValue> }
  | { type: 'SELECTED/SET'; selected: Item<TValue>[] }
  | { type: 'SELECTED/CLEAR' }
  | { type: 'FILTER/SET'; filter: string }
  | { type: 'FILTER/CLEAR' }
  | { type: 'OPEN/OPEN' }
  | { type: 'OPEN/CLOSE' }
  | { type: 'OPEN/TOGGLE' }
  | { type: 'ACTIVE/SET'; activeIndex: number }
  | { type: 'ACTIVE/CLEAR' }
  | { type: 'ITEMS/REPLACE'; items: Item<TValue>[] }
  | { type: 'RESET' };

const getActiveItems = <TValue extends any = any>(
  state: State<TValue>,
  filter: string,
  force = false,
): State<TValue> => {
  const { adjustActiveItems, items } = state;

  if (!force && state.filter === filter) {
    return state;
  }

  if (!filter) {
    return { ...state, activeIndex: 0, activeItems: items, filter };
  }

  // We can do better here
  const keysToSearch = items.every(({ label }) => typeof label === 'string')
    ? ['label']
    : ['search'];

  const fuse = new Fuse(items, {
    includeMatches: true,
    keys: keysToSearch,
    shouldSort: true,
  });

  const activeItems = fuse.search(filter.trim()).map(({ item }) => item);

  return {
    ...state,
    activeIndex: 0,
    activeItems: adjustActiveItems ? adjustActiveItems(filter.trim(), activeItems) : activeItems,
    filter,
  };
};

/* eslint-disable complexity, max-lines-per-function */
export const reducer = <TValue extends any = any>(
  state: State<TValue>,
  action: Action<TValue>,
): State<TValue> => {
  // We can do better here
  switch (action.type) {
    case 'SELECTED/ADD':
      return state.selected.includes(action.selected)
        ? state
        : { ...state, selected: [...state.selected, action.selected] };

    case 'SELECTED/REMOVE':
      return !state.selected.includes(action.selected)
        ? state
        : {
            ...state,
            selected: state.selected.filter(item => item !== action.selected),
          };

    case 'SELECTED/TOGGLE':
      return {
        ...state,
        selected: includes(action.selected, state.selected)
          ? state.selected.filter(item => !equals(action.selected, item))
          : [...state.selected, action.selected],
      };

    case 'SELECTED/SET':
      return equals(state.selected, action.selected)
        ? state
        : { ...state, selected: action.selected };

    case 'SELECTED/CLEAR':
      return { ...state, selected: [] };

    case 'FILTER/SET':
      return getActiveItems<TValue>(state, action.filter);

    case 'FILTER/CLEAR':
      return getActiveItems<TValue>(state, '');

    case 'OPEN/OPEN':
      return state.open
        ? state
        : { ...state, activeIndex: state.activeIndex === -1 ? 0 : state.activeIndex, open: true };

    case 'OPEN/CLOSE':
      return !state.open ? state : { ...state, open: false };

    case 'OPEN/TOGGLE':
      return { ...state, open: !state.open };

    case 'ACTIVE/SET':
      return state.activeIndex === action.activeIndex
        ? state
        : { ...state, activeIndex: action.activeIndex };

    case 'ACTIVE/CLEAR':
      return state.activeIndex === -1 ? state : { ...state, activeIndex: -1 };

    case 'ITEMS/REPLACE':
      return {
        ...state,
        // If the items are replaced, make sure that we don't have old active items in them
        ...getActiveItems<TValue>({ ...state, items: action.items }, state.filter, true),
        // Actually replace the items
        items: action.items,
        // clear the selected items that are no longer in the list
        selected: state.selected.filter(i => action.items.includes(i)),
      };

    case 'RESET':
      return {
        ...state,
        activeIndex: state.initialActiveIndex, // This may not work if we change the items
        activeItems: state.initialActiveItems, // This may not work if we change the items
        filter: state.initialFilter,
        open: state.initialOpen,
        selected: state.initialSelected,
      };

    default:
      return state;
  }
};

/* eslint-enable complexity, max-lines-per-function */

export type DropdownActions<TValue extends any = any> = {
  addSelected: (selected: Item<TValue>) => void;
  clearSelected: () => void;
  removeSelected: (selected: Item<TValue>) => void;
  setSelected: (selected: Item<TValue>[]) => void;
  toggleSelected: (selected: Item<TValue>) => void;
  setFilter: (filter: string) => void;
  clearFilter: () => void;
  openList: () => void;
  closeList: () => void;
  toggleList: () => void;
  setActiveIndex: (activeIndex: number) => void;
  clearActiveIndex: () => void;
  replaceItems: (nextItems: Item<TValue>[]) => void;
  reset: () => void;
};

/* eslint-disable sort-keys-fix/sort-keys-fix, sort-keys */
/* Actions */
export const createActions = <TValue extends any = any>(
  dispatch: React.Dispatch<Action>,
): DropdownActions<TValue> => ({
  /* Actions dealing with selections */
  addSelected: (selected: Item<TValue>) => dispatch({ selected, type: 'SELECTED/ADD' }),
  clearSelected: () => dispatch({ type: 'SELECTED/CLEAR' }),
  removeSelected: (selected: Item<TValue>) => dispatch({ selected, type: 'SELECTED/REMOVE' }),
  setSelected: (selected: Item<TValue>[]) => dispatch({ selected, type: 'SELECTED/SET' }),
  toggleSelected: (selected: Item<TValue>) => dispatch({ selected, type: 'SELECTED/TOGGLE' }),

  /* Actions dealing with the filter */
  setFilter: (filter: string) => dispatch({ filter, type: 'FILTER/SET' }),
  clearFilter: () => dispatch({ type: 'FILTER/CLEAR' }),

  /* Actions dealing with opening / close popup */
  openList: () => dispatch({ type: 'OPEN/OPEN' }),
  closeList: () => dispatch({ type: 'OPEN/CLOSE' }),
  toggleList: () => dispatch({ type: 'OPEN/TOGGLE' }),

  /* Actions determining Accessibility Focus (the active items focused index) */
  setActiveIndex: (activeIndex: number) => dispatch({ activeIndex, type: 'ACTIVE/SET' }),
  clearActiveIndex: () => dispatch({ type: 'ACTIVE/CLEAR' }),

  replaceItems: (nextItems: Item<TValue>[]) =>
    dispatch({ items: nextItems, type: 'ITEMS/REPLACE' }),

  /* Misc actions */
  reset: () => dispatch({ type: 'RESET' }),
});

type GetInitialStateParams<TValue extends any = any> = {
  open?: boolean;
  defaultOpen?: boolean;
  activeIndex?: number;
  defaultActiveIndex?: number;
  selected?: Item<TValue>[];
  defaultSelected?: Item<TValue>[];
  filter?: string;
  defaultFilter?: string;
  items: Item<TValue>[];
  adjustActiveItems?: (input: string, items: Item<TValue>[]) => Item<TValue>[];
  preventClearFilterOnSelect?: boolean;
  readonly?: boolean;
  disabled?: boolean;
};

export const getInitialState = <TValue extends any = any>(
  params: GetInitialStateParams<TValue>,
): State<TValue> => {
  const {
    adjustActiveItems = (_, x) => x,
    preventClearFilterOnSelect = false,
    readonly = false,
    disabled = false,
  } = params;

  const filter = params.filter ?? params.defaultFilter ?? '';
  const items =
    readonly || disabled ? params.items.map(item => ({ ...item, disabled: true })) : params.items;

  const activeItems = filter ? adjustActiveItems(filter, items) : items;

  const selected = params.selected ?? params.defaultSelected ?? [];
  const defaultSelectedItem = head(selected);
  const selectedIndex = defaultSelectedItem
    ? activeItems.findIndex(x => x.value === defaultSelectedItem.value)
    : -1;

  const activeIndex = params.activeIndex ?? params.defaultActiveIndex ?? selectedIndex;

  const values = {
    /**
     * The index of the Accessibility Focused Item
     */
    activeIndex,

    /**
     * The items that have made it through the filter
     */
    activeItems,

    /**
     * The current filter applied
     */
    filter,

    /**
     * The full set of items that are passed for reference
     */
    items,

    /**
     * Whether or not the Popup is open
     */
    open: params.open ?? params.defaultOpen ?? false,

    /**
     * The current "value" of the field
     */
    selected,
  };

  return {
    ...values,
    adjustActiveItems,
    defaultActiveIndex: activeIndex,
    initialActiveIndex: values.activeIndex,
    initialActiveItems: values.activeItems,
    initialFilter: values.filter,
    initialOpen: values.open,
    initialSelected: values.selected,
    preventClearFilterOnSelect,
  };
};
