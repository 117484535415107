import * as React from 'react';

import {
  __, all, concat, equals, filter, flip, includes, map, none, of, pipe, without,
} from 'ramda';

const isTrue = equals(true);
const allTrue = all(isTrue);
const noneTrue = none(isTrue);
const isIn = flip(includes) as any;

export const useSelectableList = <TItem = any>(
  allItems: TItem[],
  defaultSelectedItems: TItem[] = [],
) => {
  const [selected, setSelected] = React.useState<TItem[]>(defaultSelectedItems);
  const isSelected = includes(__, selected);
  const selections = map(isSelected, allItems);
  const selectItem = (item: TItem) => setSelected(concat([item], selected));
  const deselectItem = (item: TItem) => setSelected(without([item], selected)); // pipe(of, flip(without)(selected), setSelected)
  const toggleItem = (item: TItem) => (isSelected(item) ? deselectItem(item) : selectItem(item));
  const selectAll = () => setSelected(allItems);
  const selectNone = () => setSelected([]);
  const selectOnly = pipe<TItem, TItem[], void>(of, setSelected);
  const allSelected = allTrue(selections);
  const noneSelected = noneTrue(selections);
  const someSelected = !allSelected && !noneSelected;
  const selectedItems = filter(isIn(selected), allItems);
  return {
    isSelected,
    selectItem,
    deselectItem,
    toggleItem,
    selectAll,
    selectNone,
    selectOnly,
    selectedItems,
    allSelected,
    noneSelected,
    someSelected,
  };
};
