import * as React from 'react';

import { cx } from '@cobbler-io/utils/src/cx';

type Props = {
  // Add props here
  /**
   * The type of html element to create
   *
   * default: `h2`
   */
  as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'span' | 'div';

  /**
   * Uses a named font-size
   *
   * TODO ensure that these cover all the standards, also, renormalize to the mocks
   */
  size?:
    | 'tiny' /* 10px */
    | 'caption' /* 12px */
    | 'button' /* 14px */
    | 'body' /* 14px */
    | 'body-large' /* 16px */
    | 'title' /* 20px */
    | 'headline' /* 24px */
    | 'display' /* 36px */
    | 'jumbotron' /* 48px */;

  weight?: 'normal' | 'bold';
} & Omit<React.HTMLProps<HTMLDivElement>, 'size'>;

/**
 * Presentational component that lets us be a bit more semantic with the headers but not redeclare
 * as many css classes
 */
export const Heading = ({
  as = 'h2',
  size = 'title',
  weight,
  className,
  ...props
}: Props): JSX.Element =>
  React.createElement(as, {
    ...props,
    className: cx(size && `font-${size}`, weight === 'bold' && 'bold', className),
  });

Heading.displayName = 'Heading';

export default Heading;
