import * as React from 'react';

import { Tab, TabRouter } from '@cobbler-io/core-ui/src/TabRouter';

import { useSelectedBudgetRevisionId } from '@cobbler-io/redux/src/modules/current-budget';

import { useGetActualParentBudgetLineIdQuery } from '@cobbler-io/app/src/api/graphql-types';
import { NotFound } from '@cobbler-io/app/src/pages/NotFound';
import { CurrentActualProvider } from '@cobbler-io/app/src/providers/CurrentActualProvider';
import { useCurrentBudgetLine } from '@cobbler-io/app/src/providers/CurrentBudgetLineProvider';
import { budgetUrls } from '@cobbler-io/app/src/urls/urls'; // ndmUrls

import { Redirect, useMatch } from '@reach/router';
import gql from 'graphql-tag';

import { ActualDetailsDiscussions } from './ActualDetailsDiscussions';
import { ActualDetailsInfo } from './ActualDetailsInfo';
import { ActualDetailsResolvedDiscussions } from './ActualDetailsResolvedDiscussions';

export type ActualDetailsProps = {
  refetchList: () => Promise<any>;
  isRevenue: boolean;
};

export const ActualDetails = (props: ActualDetailsProps): JSX.Element => {
  const { refetchList, isRevenue } = props;
  const ActualDetailsInfoWithRefetch = React.useCallback(
    () => <ActualDetailsInfo isRevenue={isRevenue} refetchList={refetchList} />,
    [refetchList, isRevenue],
  );
  const queryParams = isRevenue ? '?type=revenue' : '';

  return (
    <CurrentActualProvider>
      <TabRouter>
        <Tab
          Component={ActualDetailsInfoWithRefetch}
          id="details"
          label="Details"
          path={`${budgetUrls.actualDetails.path}${queryParams}`}
        />
        <Tab
          Component={ActualDetailsDiscussions}
          id="discussions"
          label="Discussion"
          path={`${budgetUrls.actualDiscussions.path}${queryParams}`}
        />
        <Tab
          Component={ActualDetailsResolvedDiscussions}
          id="resolved"
          label="Archive"
          path={`${budgetUrls.actualDiscussionsArchive.path}${queryParams}`}
        />
      </TabRouter>
    </CurrentActualProvider>
  );
};
ActualDetails.displayName = 'ActualDetails';

// TODO: [Backend] Avoid having to fetch data by including the matching
//       budget id in the app notification. :'(
gql`
  query GetActualParentBudgetLineIdQuery($id: ID!) {
    actual(id: $id) {
      ... on ActualExpenseType {
        matchedBudgetLine {
          ... on BudgetLineType {
            parent {
              ... on BudgetLineType {
                id
              }
            }
          }
        }
      }
    }
  }
`;

export const RedirectToActualDetails = (): JSX.Element => {
  const match = useMatch('/actuals/:id/*') ?? { id: '', '*': '' };
  const id = match.id;
  const tab = match['*'];

  const currentRevisionId = useSelectedBudgetRevisionId()!;
  const budgetLineContext = useCurrentBudgetLine();
  const budgetLineId = budgetLineContext?.id;

  const { data, error, loading } = useGetActualParentBudgetLineIdQuery({
    skip: !id,
    variables: { id },
  });

  if (!id) {
    return <NotFound />;
  }

  if (loading && !data) {
    return <>Loading...</>;
  }

  const matchedBudgetLineId = data?.actual?.matchedBudgetLine?.parent?.id;

  if (error || !matchedBudgetLineId) {
    return <>Error getting transaction details.</>;
  }

  const revisionId = budgetLineId === matchedBudgetLineId ? currentRevisionId : undefined;

  const redirectParams = { actualId: id, budgetId: matchedBudgetLineId, revisionId };

  if (tab === 'discussions') {
    return <Redirect noThrow to={budgetUrls.actualDiscussions(redirectParams)} />;
  }

  if (tab === 'archive') {
    return <Redirect noThrow to={budgetUrls.actualDiscussionsArchive(redirectParams)} />;
  }

  return <Redirect noThrow to={budgetUrls.actualDetails(redirectParams)} />;
};

RedirectToActualDetails.displayName = 'RedirectToActualDetails';
