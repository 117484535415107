export type TreeNode = { parent: TreeNode | null };

export const getTreeNodeDepth = <T extends TreeNode>(modal: T): number => {
  let { parent } = modal;
  let depth = 0;
  while (parent) {
    depth++; // eslint-disable-line no-plusplus
    parent = parent.parent; // eslint-disable-line prefer-destructuring
  }
  return depth;
};
