export const LOCALES = [
  'af',
  'af-NA',
  'af-ZA',
  'agq',
  'agq-CM',
  'ak',
  'ak-GH',
  'am',
  'am-ET',
  'ar',
  'ar-001',
  'ar-AE',
  'ar-BH',
  'ar-DZ',
  'ar-EG',
  'ar-IQ',
  'ar-JO',
  'ar-KW',
  'ar-LB',
  'ar-LY',
  'ar-MA',
  'ar-OM',
  'ar-QA',
  'ar-SA',
  'ar-SD',
  'ar-SY',
  'ar-TN',
  'ar-YE',
  'as',
  'as-IN',
  'asa',
  'asa-TZ',
  'az',
  'az-Cyrl',
  'az-Cyrl-AZ',
  'az-Latn',
  'az-Latn-AZ',
  'bas',
  'bas-CM',
  'be',
  'be-BY',
  'bem',
  'bem-ZM',
  'bez',
  'bez-TZ',
  'bg',
  'bg-BG',
  'bm',
  'bm-ML',
  'bn',
  'bn-BD',
  'bn-IN',
  'bo',
  'bo-CN',
  'bo-IN',
  'br',
  'br-FR',
  'brx',
  'brx-IN',
  'bs',
  'bs-BA',
  'ca',
  'ca-ES',
  'cgg',
  'cgg-UG',
  'chr',
  'chr-US',
  'cs',
  'cs-CZ',
  'cy',
  'cy-GB',
  'da',
  'da-DK',
  'dav',
  'dav-KE',
  'de',
  'de-AT',
  'de-BE',
  'de-CH',
  'de-DE',
  'de-LI',
  'de-LU',
  'dje',
  'dje-NE',
  'dua',
  'dua-CM',
  'dyo',
  'dyo-SN',
  'ebu',
  'ebu-KE',
  'ee',
  'ee-GH',
  'ee-TG',
  'el',
  'el-CY',
  'el-GR',
  'en',
  'en-AE',
  'en-AR',
  'en-AS',
  'en-AT',
  'en-AU',
  'en-BB',
  'en-BE',
  'en-BG',
  'en-BH',
  'en-BM',
  'en-BR',
  'en-BW',
  'en-BZ',
  'en-CA',
  'en-CH',
  'en-CL',
  'en-CN',
  'en-CO',
  'en-CR',
  'en-CY',
  'en-CZ',
  'en-DE',
  'en-DK',
  'en-DO',
  'en-EE',
  'en-EG',
  'en-ES',
  'en-FI',
  'en-GB',
  'en-GE',
  'en-GF',
  'en-GH',
  'en-GI',
  'en-GR',
  'en-GU',
  'en-GY',
  'en-HK',
  'en-HR',
  'en-HU',
  'en-ID',
  'en-IE',
  'en-IL',
  'en-IN',
  'en-IS',
  'en-IT',
  'en-JM',
  'en-JO',
  'en-JP',
  'en-KR',
  'en-KW',
  'en-KY',
  'en-LB',
  'en-LI',
  'en-LT',
  'en-LU',
  'en-LV',
  'en-MA',
  'en-MC',
  'en-MG',
  'en-MH',
  'en-MK',
  'en-MO',
  'en-MP',
  'en-MT',
  'en-MU',
  'en-MX',
  'en-MY',
  'en-NA',
  'en-NL',
  'en-NO',
  'en-NZ',
  'en-OM',
  'en-PE',
  'en-PH',
  'en-PK',
  'en-PL',
  'en-PR',
  'en-PT',
  'en-PY',
  'en-QA',
  'en-RO',
  'en-RU',
  'en-SA',
  'en-SE',
  'en-SG',
  'en-SK',
  'en-SI',
  'en-TH',
  'en-TR',
  'en-TT',
  'en-TW',
  'en-UA',
  'en-UM',
  'en-US',
  'en-US-POSIX',
  'en-UY',
  'en-VE',
  'en-VI',
  'en-VN',
  'en-ZA',
  'en-ZW',
  'eo',
  'es',
  'es-419',
  'es-AR',
  'es-BO',
  'es-CL',
  'es-CO',
  'es-CR',
  'es-DO',
  'es-EC',
  'es-ES',
  'es-GQ',
  'es-GT',
  'es-HN',
  'es-MX',
  'es-NI',
  'es-PA',
  'es-PE',
  'es-PR',
  'es-PY',
  'es-SV',
  'es-US',
  'es-UY',
  'es-VE',
  'et',
  'et-EE',
  'eu',
  'eu-ES',
  'ewo',
  'ewo-CM',
  'fa',
  'fa-AF',
  'fa-IR',
  'ff',
  'ff-SN',
  'fi',
  'fi-FI',
  'fil',
  'fil-PH',
  'fo',
  'fo-FO',
  'fr',
  'fr-BE',
  'fr-BF',
  'fr-BI',
  'fr-BJ',
  'fr-BL',
  'fr-CA',
  'fr-CD',
  'fr-CF',
  'fr-CG',
  'fr-CH',
  'fr-CI',
  'fr-CM',
  'fr-DJ',
  'fr-FR',
  'fr-GA',
  'fr-GF',
  'fr-GN',
  'fr-GP',
  'fr-GQ',
  'fr-KM',
  'fr-LU',
  'fr-MC',
  'fr-MF',
  'fr-MG',
  'fr-ML',
  'fr-MQ',
  'fr-NE',
  'fr-RE',
  'fr-RW',
  'fr-SN',
  'fr-TD',
  'fr-TG',
  'fr-YT',
  'ga',
  'ga-IE',
  'gl',
  'gl-ES',
  'gsw',
  'gsw-CH',
  'gu',
  'gu-IN',
  'guz',
  'guz-KE',
  'gv',
  'gv-GB',
  'ha',
  'ha-Latn',
  'ha-Latn-GH',
  'ha-Latn-NE',
  'ha-Latn-NG',
  'haw',
  'haw-US',
  'he',
  'he-IL',
  'hi',
  'hi-IN',
  'hr',
  'hr-HR',
  'hu',
  'hu-HU',
  'hy',
  'hy-AM',
  'id',
  'id-ID',
  'ig',
  'ig-NG',
  'ii',
  'ii-CN',
  'is',
  'is-IS',
  'it',
  'it-CH',
  'it-IT',
  'ja',
  'ja-JP',
  'jmc',
  'jmc-TZ',
  'ka',
  'ka-GE',
  'kab',
  'kab-DZ',
  'kam',
  'kam-KE',
  'kde',
  'kde-TZ',
  'kea',
  'kea-CV',
  'khq',
  'khq-ML',
  'ki',
  'ki-KE',
  'kk',
  'kk-Cyrl',
  'kk-Cyrl-KZ',
  'kl',
  'kl-GL',
  'kln',
  'kln-KE',
  'km',
  'km-KH',
  'kn',
  'kn-IN',
  'ko',
  'ko-KR',
  'kok',
  'kok-IN',
  'ksb',
  'ksb-TZ',
  'ksf',
  'ksf-CM',
  'kw',
  'kw-GB',
  'lag',
  'lag-TZ',
  'lg',
  'lg-UG',
  'ln',
  'ln-CD',
  'ln-CG',
  'lt',
  'lt-LT',
  'lu',
  'lu-CD',
  'luo',
  'luo-KE',
  'luy',
  'luy-KE',
  'lv',
  'lv-LV',
  'mas',
  'mas-KE',
  'mas-TZ',
  'mer',
  'mer-KE',
  'mfe',
  'mfe-MU',
  'mg',
  'mg-MG',
  'mgh',
  'mgh-MZ',
  'mk',
  'mk-MK',
  'ml',
  'ml-IN',
  'mr',
  'mr-IN',
  'ms',
  'ms-BN',
  'ms-MY',
  'mt',
  'mt-MT',
  'mua',
  'mua-CM',
  'my',
  'my-MM',
  'naq',
  'naq-NA',
  'nb',
  'nb-NO',
  'nd',
  'nd-ZW',
  'ne',
  'ne-IN',
  'ne-NP',
  'nl',
  'nl-AW',
  'nl-BE',
  'nl-CW',
  'nl-NL',
  'nl-SX',
  'nmg',
  'nmg-CM',
  'nn',
  'nn-NO',
  'nus',
  'nus-SD',
  'nyn',
  'nyn-UG',
  'om',
  'om-ET',
  'om-KE',
  'or',
  'or-IN',
  'pa',
  'pa-Arab',
  'pa-Arab-PK',
  'pa-Guru',
  'pa-Guru-IN',
  'pl',
  'pl-PL',
  'ps',
  'ps-AF',
  'pt',
  'pt-AO',
  'pt-BR',
  'pt-GW',
  'pt-MZ',
  'pt-PT',
  'pt-ST',
  'rm',
  'rm-CH',
  'rn',
  'rn-BI',
  'ro',
  'ro-MD',
  'ro-RO',
  'rof',
  'rof-TZ',
  'ru',
  'ru-MD',
  'ru-RU',
  'ru-UA',
  'rw',
  'rw-RW',
  'rwk',
  'rwk-TZ',
  'saq',
  'saq-KE',
  'sbp',
  'sbp-TZ',
  'seh',
  'seh-MZ',
  'ses',
  'ses-ML',
  'sg',
  'sg-CF',
  'shi',
  'shi-Latn',
  'shi-Latn-MA',
  'shi-Tfng',
  'shi-Tfng-MA',
  'si',
  'si-LK',
  'sk',
  'sk-SK',
  'sl',
  'sl-SI',
  'sn',
  'sn-ZW',
  'so',
  'so-DJ',
  'so-ET',
  'so-KE',
  'so-SO',
  'sq',
  'sq-AL',
  'sr',
  'sr-Cyrl',
  'sr-Cyrl-BA',
  'sr-Cyrl-ME',
  'sr-Cyrl-RS',
  'sr-Latn',
  'sr-Latn-BA',
  'sr-Latn-ME',
  'sr-Latn-RS',
  'sv',
  'sv-FI',
  'sv-SE',
  'sw',
  'sw-KE',
  'sw-TZ',
  'swc',
  'swc-CD',
  'ta',
  'ta-IN',
  'ta-LK',
  'te',
  'te-IN',
  'teo',
  'teo-KE',
  'teo-UG',
  'th',
  'th-TH',
  'ti',
  'ti-ER',
  'ti-ET',
  'to',
  'to-TO',
  'tr',
  'tr-TR',
  'twq',
  'twq-NE',
  'tzm',
  'tzm-Latn',
  'tzm-Latn-MA',
  'uk',
  'uk-UA',
  'ur',
  'ur-IN',
  'ur-PK',
  'uz',
  'uz-Arab',
  'uz-Arab-AF',
  'uz-Cyrl',
  'uz-Cyrl-UZ',
  'uz-Latn',
  'uz-Latn-UZ',
  'vai',
  'vai-Latn',
  'vai-Latn-LR',
  'vai-Vaii',
  'vai-Vaii-LR',
  'vi',
  'vi-VN',
  'vun',
  'vun-TZ',
  'xog',
  'xog-UG',
  'yav',
  'yav-CM',
  'yo',
  'yo-NG',
  'zh',
  'zh-Hans',
  'zh-Hans-AE',
  'zh-Hans-AR',
  'zh-Hans-AT',
  'zh-Hans-AU',
  'zh-Hans-BE',
  'zh-Hans-BG',
  'zh-Hans-BH',
  'zh-Hans-BR',
  'zh-Hans-BW',
  'zh-Hans-CA',
  'zh-Hans-CH',
  'zh-Hans-CL',
  'zh-Hans-CN',
  'zh-Hans-CO',
  'zh-Hans-CR',
  'zh-Hans-CY',
  'zh-Hans-CZ',
  'zh-Hans-DE',
  'zh-Hans-DK',
  'zh-Hans-DO',
  'zh-Hans-EE',
  'zh-Hans-EG',
  'zh-Hans-ES',
  'zh-Hans-FI',
  'zh-Hans-GB',
  'zh-Hans-GE',
  'zh-Hans-GF',
  'zh-Hans-GH',
  'zh-Hans-GI',
  'zh-Hans-GR',
  'zh-Hans-HK',
  'zh-Hans-HR',
  'zh-Hans-HU',
  'zh-Hans-ID',
  'zh-Hans-IE',
  'zh-Hans-IL',
  'zh-Hans-IN',
  'zh-Hans-IS',
  'zh-Hans-IT',
  'zh-Hans-JO',
  'zh-Hans-JP',
  'zh-Hans-KR',
  'zh-Hans-KW',
  'zh-Hans-KY',
  'zh-Hans-LB',
  'zh-Hans-LI',
  'zh-Hans-LT',
  'zh-Hans-LU',
  'zh-Hans-LV',
  'zh-Hans-MA',
  'zh-Hans-MC',
  'zh-Hans-MG',
  'zh-Hans-MK',
  'zh-Hans-MO',
  'zh-Hans-MT',
  'zh-Hans-MU',
  'zh-Hans-MX',
  'zh-Hans-MY',
  'zh-Hans-NA',
  'zh-Hans-NL',
  'zh-Hans-NO',
  'zh-Hans-NZ',
  'zh-Hans-OM',
  'zh-Hans-PE',
  'zh-Hans-PH',
  'zh-Hans-PK',
  'zh-Hans-PL',
  'zh-Hans-PR',
  'zh-Hans-PT',
  'zh-Hans-PY',
  'zh-Hans-QA',
  'zh-Hans-RO',
  'zh-Hans-RU',
  'zh-Hans-SA',
  'zh-Hans-SE',
  'zh-Hans-SG',
  'zh-Hans-SK',
  'zh-Hans-SI',
  'zh-Hans-TH',
  'zh-Hans-TR',
  'zh-Hans-TW',
  'zh-Hans-UA',
  'zh-Hans-US',
  'zh-Hans-UY',
  'zh-Hans-VE',
  'zh-Hans-VN',
  'zh-Hans-ZA',
  'zh-Hant',
  'zh-Hant-AE',
  'zh-Hant-AR',
  'zh-Hant-AT',
  'zh-Hant-AU',
  'zh-Hant-BE',
  'zh-Hant-BG',
  'zh-Hant-BH',
  'zh-Hant-BR',
  'zh-Hant-BW',
  'zh-Hant-CA',
  'zh-Hant-CH',
  'zh-Hant-CL',
  'zh-Hant-CN',
  'zh-Hant-CO',
  'zh-Hant-CR',
  'zh-Hant-CY',
  'zh-Hant-CZ',
  'zh-Hant-DE',
  'zh-Hant-DK',
  'zh-Hant-DO',
  'zh-Hant-EE',
  'zh-Hant-EG',
  'zh-Hant-ES',
  'zh-Hant-FI',
  'zh-Hant-GB',
  'zh-Hant-GE',
  'zh-Hant-GF',
  'zh-Hant-GH',
  'zh-Hant-GI',
  'zh-Hant-GR',
  'zh-Hant-HK',
  'zh-Hant-HR',
  'zh-Hant-HU',
  'zh-Hant-ID',
  'zh-Hant-IE',
  'zh-Hant-IL',
  'zh-Hant-IN',
  'zh-Hant-IS',
  'zh-Hant-IT',
  'zh-Hant-JO',
  'zh-Hant-JP',
  'zh-Hant-KR',
  'zh-Hant-KW',
  'zh-Hant-KY',
  'zh-Hant-LB',
  'zh-Hant-LI',
  'zh-Hant-LT',
  'zh-Hant-LU',
  'zh-Hant-LV',
  'zh-Hant-MA',
  'zh-Hant-MC',
  'zh-Hant-MG',
  'zh-Hant-MK',
  'zh-Hant-MO',
  'zh-Hant-MT',
  'zh-Hant-MU',
  'zh-Hant-MX',
  'zh-Hant-MY',
  'zh-Hant-NA',
  'zh-Hant-NL',
  'zh-Hant-NO',
  'zh-Hant-NZ',
  'zh-Hant-OM',
  'zh-Hant-PE',
  'zh-Hant-PH',
  'zh-Hant-PK',
  'zh-Hant-PL',
  'zh-Hant-PR',
  'zh-Hant-PT',
  'zh-Hant-PY',
  'zh-Hant-QA',
  'zh-Hant-RO',
  'zh-Hant-RU',
  'zh-Hant-SA',
  'zh-Hant-SE',
  'zh-Hant-SG',
  'zh-Hant-SK',
  'zh-Hant-SI',
  'zh-Hant-TH',
  'zh-Hant-TR',
  'zh-Hant-TW',
  'zh-Hant-UA',
  'zh-Hant-US',
  'zh-Hant-UY',
  'zh-Hant-VE',
  'zh-Hant-VN',
  'zh-Hant-ZA',
  'zu',
  'zu-ZA',
] as const;

export type Locale = typeof LOCALES[number];
