import * as React from 'react';

import { composeEventHandlers } from '@cobbler-io/utils/src/composeEventHandlers';
import { cx } from '@cobbler-io/utils/src/cx';

import { useDefaultOrRandom } from '@cobbler-io/hooks/src/useDefaultOrRandom';
import { useForwardedRef } from '@cobbler-io/hooks/src/useForwardedRef';
import { useIsMounted } from '@cobbler-io/hooks/src/useIsMounted';
import { useToggle } from '@cobbler-io/hooks/src/useToggle';

import { Button } from '../../Button';
import { Icon } from '../../Icon';
import { Modal, useModal } from '../../Modal';

import styles from './DropdownSegment.scss';

type MenuSeparator = {
  key: string;
  id?: string;
  type: 'separator';
};

type MenuButton = {
  /**
   * A key. If not provided, then `name` will be used
   */
  key?: string;
  /**
   * This is both the name of the button and used as the key if no `key` is present
   */
  name: string;
  /**
   * Just a prop to switch on
   */
  type: 'button';
  /**
   * This is literally the event handler for the button
   */
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  /**
   * A DOM node title (will show on most browsers when the user hovers)
   */
  title?: string;
  /**
   * The contents of the button
   */
  children: React.ReactNode;
  /**
   * An id for the button
   */
  id?: string;
};

type DropdownItem = MenuButton | MenuSeparator;
type DropdownItems = DropdownItem[];

export type DropdownSegmentProps = {
  key?: string;
  id?: string;
  children: React.ReactNode;
  items: DropdownItems;
  name: string;
};

export const DropdownSegment = React.forwardRef<HTMLButtonElement, DropdownSegmentProps>(
  ({ children, items, ...props }, ref) => {
    const { create } = useModal();
    const isMounted = useIsMounted();
    const buttonRef = useForwardedRef<HTMLButtonElement>(ref);
    const { active, activate, deactivate } = useToggle();
    const id = useDefaultOrRandom(props.id); // eslint-disable-line react/destructuring-assignment

    const onClick: React.MouseEventHandler<HTMLButtonElement> = () => {
      activate();

      create(
        <Modal
          arrow
          className={cx('box-shadow-standard', styles.dropdownModal)}
          overlay="transparent"
        >
          {({ close }) => (
            <div className={styles.dropdownContainer}>
              {items.map((item, index) => {
                if (item.type === 'button') {
                  const { name, key, onClick: handleClick, children: buttonChildren } = item;

                  return (
                    <Button
                      {...item}
                      key={key || name}
                      name={name}
                      variant="text"
                      onClick={composeEventHandlers(handleClick, close)}
                    >
                      {buttonChildren}
                    </Button>
                  );
                }

                if (item.type === 'separator') {
                  const { key } = item;
                  return <hr key={key || index} />;
                }

                return null;
              })}
            </div>
          )}
        </Modal>,
        {
          target: buttonRef.current!,
          placement: 'bottom-start',
          onAfterClose: () => isMounted.current && deactivate(),
        },
      );
    };

    return (
      <Button
        {...props}
        ref={buttonRef}
        className={cx(styles.dropdownButton, active && styles.active)}
        id={id}
        name="open dropdown"
        variant="text"
        onClick={onClick}
      >
        {children}
        <Icon
          aria-hidden
          id={`${id}-icon`}
          type={active ? 'chevronFilledUp' : 'chevronFilledDown'}
        />
      </Button>
    );
  },
);

DropdownSegment.displayName = 'DropdownSegment';
