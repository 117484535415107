/* eslint-disable max-lines-per-function */
import * as React from 'react';
import { unstable_batchedUpdates as batch } from 'react-dom';

import { clamp } from '@cobbler-io/utils/src';
import { getImageDimensionsFromFile, ImageMeasurements } from '@cobbler-io/utils/src/image';
import { noop } from '@cobbler-io/utils/src/noop';

import { useLatest } from '@cobbler-io/hooks/src/useLatest';

import { useGesture } from 'react-use-gesture';

import { applyAdjustments, canvasToFile, createCanvasFrameOverlay } from './canvasHelpers';
import { ControlBar } from './ControlBar';

import styles from './ImageEditor.scss';

export type ImageEditorProps = {
  file: File;
  minZoom?: number;
  maxZoom?: number;
  onAccept: (file: File) => any;
};

const initialTransform = {
  top: 0,
  left: 0,
  scale: 100,
  translateX: 0,
  translateY: 0,
  brightness: 100,
  contrast: 100,
  saturate: 100,
};

const createCSSTransforms = (state: typeof initialTransform) => ({
  filter: [
    `brightness(${state.brightness}%)`,
    `contrast(${state.contrast}%)`,
    `saturate(${state.saturate}%)`,
  ].join(' '),
  transform: [
    `translate(${state.translateX}%, ${state.translateY}%)`,
    `scale(${state.scale / 100})`,
  ].join(' '),
  left: `${state.left}px`,
  top: `${state.top}px`,
});

const clampMinMax100 = (n: number) => clamp({ min: -100, max: 100 }, n);

export const ImageEditor = (props: ImageEditorProps): JSX.Element => {
  const { file, minZoom = 5, maxZoom = 500, onAccept = noop } = props;
  const overlayCanvasRef = React.useRef<HTMLCanvasElement>(null);
  const previewDivRef = React.useRef<HTMLDivElement>(null);
  const imageRef = React.useRef<HTMLImageElement>(null);
  const sizingRef = React.useRef<HTMLDivElement>(null);
  const dragRef = React.useRef<boolean>(false);

  const [imgUrl, setImageUrl] = React.useState<string | null>(null);
  const [dimensions, setImageDimensions] = React.useState<null | ImageMeasurements>(null);
  const [imageTransform, setImageTransform] = React.useState(initialTransform);
  const [firstTransform, setFirstTransform] = React.useState(initialTransform);

  // we'll keep this in a ref so that we can use it in the useGesture hook without
  // having things jump a bunch
  const transformRef = useLatest(imageTransform);

  const bounds = { minZoom, maxZoom, minPanX: -100, maxPanX: 100, minPanY: -100, maxPanY: 100 };

  const setImageAndFirstTransform = React.useCallback(
    (callback: React.SetStateAction<typeof initialTransform>) => {
      batch(() => {
        setImageTransform(callback);
        setFirstTransform(callback);
      });
    },
    [setImageTransform, setFirstTransform],
  );

  React.useEffect(() => {
    if (overlayCanvasRef.current && overlayCanvasRef.current.parentElement) {
      const { clientHeight, clientWidth } = overlayCanvasRef.current.parentElement;
      overlayCanvasRef.current.height = clientHeight;
      overlayCanvasRef.current.width = clientWidth;
      createCanvasFrameOverlay(overlayCanvasRef.current);
    }

    const url = URL.createObjectURL(file);
    setImageUrl(url);

    return () => {
      URL.revokeObjectURL(url);
    };
  }, [file]);

  const bind = useGesture(
    {
      onPinch: pinchState => {
        if (dragRef.current) {
          return;
        }

        const { delta } = pinchState;

        pinchState.event?.preventDefault();
        if (typeof pinchState.event?.persist === 'function') {
          pinchState.event.persist();
        }

        setImageTransform(prev => ({
          ...prev,
          scale: clamp({ min: minZoom, max: maxZoom }, Math.round(prev.scale - delta[1])),
        }));
      },
      onDragStart: () => {
        dragRef.current = true;
      },
      onDrag: ({ event, first, offset: [x, y] }) => {
        event?.preventDefault();

        if (first) {
          // the "first" event that passes seems to be pretty jumpy, so we'll just ignore it
          // the difference will be made up on the second event
          return;
        }

        // We need to translate pixel difference
        setImageTransform(prev => ({
          ...prev,
          translateX: clampMinMax100(
            firstTransform.translateX + (x / (dimensions?.width ?? 640)) * 100,
          ),
          translateY: clampMinMax100(
            firstTransform.translateY + (y / (dimensions?.height ?? 480)) * 100,
          ),
        }));
      },
      onDragEnd: () => {
        dragRef.current = false;
      },
    },

    {
      domTarget: imageRef,
      eventOptions: { passive: false },
      pinch: {
        initial: () => [transformRef.current.scale / 100, 0],
        distanceBounds: { min: minZoom, max: maxZoom },
        rubberband: true,
      },
      drag: {
        initial: () => [transformRef.current.translateX, transformRef.current.translateY],
        filterTaps: true,
        bounds: () => ({
          top: -(imageRef.current?.height ?? 480) / 2,
          right: (imageRef.current?.width ?? 640) / 2,
          bottom: (imageRef.current?.height ?? 480) / 2,
          left: -(imageRef.current?.width ?? 640) / 2,
        }),
        rubberband: true,
      },
    },
  );

  React.useEffect(bind, [
    bind,
    dimensions,
    imageRef.current,
    firstTransform.scale,
    firstTransform.translateX,
    firstTransform.translateY,
  ]);

  React.useEffect(() => {
    getImageDimensionsFromFile(file).then(d => {
      if (!d || !previewDivRef.current) {
        batch(() => {
          setImageDimensions(d);
          setImageTransform(_ => initialTransform);
        });

        return;
      }

      const rect = previewDivRef.current.getBoundingClientRect();
      const ratioHeight = d.height / rect.height;
      const ratioWidth = d.width / rect.width;
      const initialRatio = 1 / Math.max(ratioHeight, ratioWidth);

      // Get the scale as a percentage
      const scale = parseFloat(
        clamp({ min: minZoom, max: maxZoom }, initialRatio * 100).toPrecision(2),
      );

      const transform = {
        ...initialTransform,
        // This is counter-intuitive, but the scale doesn't affect the width or height, so we'll
        // aim for the center of the preview div with a left/top and then adjust so that we
        // move the image halfway each way. These numbers will be large for large images and smaller
        // for smaller images, but it works
        left: rect.width / 2 - d.width / 2,
        top: rect.height / 2 - d.height / 2,
        scale,
      };

      batch(() => {
        setImageDimensions(d);
        setImageAndFirstTransform(_ => transform);
      });
    });
  }, [file]);

  const imgStyle = {
    ...createCSSTransforms(imageTransform),
    visibility: file ? 'visible' : 'hidden',
  } as const;

  return (
    <div className={styles.imageEditor}>
      <div>
        <div ref={previewDivRef} className={styles.previewContainer}>
          <img
            ref={imageRef}
            alt={imgUrl ? 'preview of uploaded' : 'Drag a photo to this'}
            className={styles.preview}
            src={imgUrl ?? undefined}
            style={imgStyle}
          />
          <canvas ref={overlayCanvasRef} className={styles.overlayCanvas} />
          {/* We just use this to determine the clip path for when exporting */}
          <div ref={sizingRef} className={styles.sizingDiv} />
        </div>
      </div>
      <ControlBar
        bounds={bounds}
        imageTransform={imageTransform}
        setState={setImageAndFirstTransform}
        onConfirm={() => {
          const canvas = applyAdjustments(imageRef.current!, sizingRef.current!, imageTransform);
          canvasToFile(canvas, file.name).then(onAccept);
        }}
      />
    </div>
  );
};

ImageEditor.displayName = 'ImageEditor';

export default ImageEditor;
