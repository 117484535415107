import * as React from 'react';

import { cx } from '@cobbler-io/utils/src/cx';
import { upperFirst } from '@cobbler-io/utils/src/string/upperFirst';

import { UserType } from '@cobbler-io/core-ui/src/UserDisplay';
import { ProfileIconSize } from '@cobbler-io/core-ui/src/UserInitial';

import styles from './UserPicture.scss';

export type UserPictureProps = {
  user: UserType;
  size?: ProfileIconSize;
  shape?: 'square' | 'round';
  onError?: React.ReactEventHandler<HTMLImageElement>;
  className?: string;
};

export const UserPicture = (props: UserPictureProps): JSX.Element => {
  const {
    size,
    user: { fullName, email, profileImageUrl },
    shape = 'round',
    onError,
    className,
  } = props;
  return (
    <picture title={fullName ?? email}>
      <img
        alt={fullName ?? email}
        className={cx(
          styles.userPicture,
          typeof size === 'string' && styles[`size${upperFirst(size)}`],
          styles[shape],
          className,
        )}
        src={profileImageUrl!}
        style={typeof size === 'number' ? { fontSize: size } : {}}
        onError={onError}
      />
    </picture>
  );
};

UserPicture.displayName = 'UserPicture';

export default UserPicture;
