/* eslint-disable no-restricted-syntax, max-classes-per-file, react/no-multi-comp, functional/no-class, functional/no-this-expression */
import * as React from 'react';

import { noop } from '@cobbler-io/utils/src';

import throttle from 'lodash/throttle';
import warning from 'tiny-warning';

import { HandledEvent, handledEvents } from './handled-events';

type RegistryProps = Record<string, unknown>;

type RegistryState = Record<string, unknown>;

type EventRegistration = {
  event: HandledEvent;
  handler: React.EventHandler<any>;
  capture?: boolean;
  passive?: boolean;
};

type EventUnregistration = {
  event: HandledEvent;
  handler: React.EventHandler<any>;
};

type ContextType = {
  register: (props: EventRegistration) => void;
  unregister: (props: EventUnregistration) => void;
};

const windowEvents = ['resize'];
const defaultThrottled = {
  resize: 100,
  scroll: 100,
};

const Context = React.createContext<ContextType>({ register: noop, unregister: noop });

const cache = new WeakMap();
// TODO retype this
const createThrottle = (fn: (event: Event) => void, wait: number) => {
  if (cache.has(fn)) {
    return cache.get(fn);
  }

  cache.set(
    fn,
    throttle((event: Event) => requestAnimationFrame(() => fn(event)), wait, { trailing: true }),
  );
  return cache.get(fn);
};

export class EventHandlerRegistry extends React.Component<RegistryProps, RegistryState> {
  displayName = 'EventHandlerRegistry';

  constructor(props: HandlerProps) {
    super(props);
    this.handlers = new Map();
    this.contextFns = {
      register: this.register,
      unregister: this.unregister,
    };
  }

  contextFns: ContextType;

  handlers: Map<HandledEvent, Set<React.EventHandler<any>>>;

  register = ({ event, handler }: EventRegistration): void => {
    warning(
      !(event in handledEvents),
      `Event type "${event}" passed to EventHandlerRegister is invalid.`,
    );

    if (!this.handlers.has(event)) {
      this.handlers.set(event, new Set());
      if (windowEvents.includes(event)) {
        window.addEventListener(event, this.handleEvent);
      } else {
        document.addEventListener(event, this.handleEvent);
      }
    }

    if (Object.prototype.hasOwnProperty.call(defaultThrottled, event)) {
      const throttledHandler = createThrottle(handler, defaultThrottled[event]);
      this.handlers.get(event)!.add(throttledHandler);
    } else {
      this.handlers.get(event)!.add(handler);
    }
  };

  unregister = ({ event, handler }: EventUnregistration): void => {
    const reg = this.handlers.get(event);
    if (reg) {
      reg.delete(cache.has(handler) ? cache.get(handler) : handler);
    }
    // We'll knock this into the next loop so that we don't unregister if
    // we're about to add something else.
    setTimeout(() => this.maybeCleanup(event), 0);
  };

  maybeCleanup = (event: HandledEvent): void => {
    const reg = this.handlers.get(event);
    if (reg && !reg.size) {
      this.handlers.delete(event);
      if (windowEvents.includes(event)) {
        window.removeEventListener(event, this.handleEvent);
      } else {
        document.removeEventListener(event, this.handleEvent);
      }
    }
  };

  handleEvent = (event: Event): void => {
    if (event.defaultPrevented) {
      return;
    }

    const type = event.type as HandledEvent;
    if (!type) {
      return;
    }

    const fns = this.handlers.get(type);
    if (!fns) {
      return;
    }

    fns.forEach(handler => {
      if (!event.defaultPrevented) {
        handler(event);
      }
    });
  };

  render(): JSX.Element {
    const { children } = this.props;
    return <Context.Provider value={this.contextFns}>{children}</Context.Provider>;
  }
}

export type HandlerProps = {
  event: HandledEvent;
  handler: React.EventHandler<any>;
};

export class EventHandlerComponent extends React.Component<HandlerProps & ContextType> {
  displayName = 'EventHandlerComponent';

  componentDidMount(): void {
    const { event, handler, register } = this.props;
    register({ event, handler });
  }

  componentDidUpdate(prev: HandlerProps): void {
    const { register, unregister, event, handler } = this.props;
    if (prev.handler !== handler || prev.event !== event) {
      unregister({ event: prev.event, handler: prev.handler });
      register({ event, handler });
    }
  }

  componentWillUnmount(): void {
    const { unregister, event, handler } = this.props;
    unregister({ event, handler });
  }

  render(): JSX.Element {
    return <div />;
  }
}

export const EventHandler = (props: HandlerProps): JSX.Element => {
  const { event, handler } = props;
  const { register, unregister } = React.useContext(Context);

  React.useEffect(() => {
    register({ event, handler });

    return () => unregister({ event, handler });
  }, [register, unregister, event, handler]);

  // @ts-expect-error: this is fine for React
  return null;
};

EventHandler.displayName = 'EventHandler';

export const useEventHandler = (event: HandledEvent, handler: React.EventHandler<any>): void => {
  const { register, unregister } = React.useContext(Context);
  React.useEffect(() => {
    register({ event, handler });

    return () => unregister({ event, handler });
  }, [register, unregister, event, handler]);
};

export default EventHandlerRegistry;
