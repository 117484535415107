import * as React from 'react';

import { propIs } from '@cobbler-io/utils/src/propIs';

import { Button } from '@cobbler-io/core-ui/src/Button';
import { Icon } from '@cobbler-io/core-ui/src/Icon';
import { Modal, useModal } from '@cobbler-io/core-ui/src/Modal';

import { WebcamContext } from './WebcamContext';

import styles from './SelectDeviceButton.scss';

/**
 * Creates a MediaStreamConstraints object with an exact match for a device
 * @returns MediaStreamConstraints
 */
const selectVideoDevice = (deviceId: string): MediaStreamConstraints => ({
  video: { deviceId: { exact: deviceId } },
  audio: false,
});

/**
 * Filter function for grabbing only video inputs
 */
const isVideoInput = propIs('kind', 'videoinput');

/**
 * Checks if a current stream is coming from a particular device
 */
const streamIsNotDevice = (stream: MediaStream | null, deviceId: string) =>
  !stream ? false : stream.getTracks().every(track => track.getSettings().deviceId !== deviceId);

export type SelectDeviceButtonProps = {
  className?: string;
  style?: React.CSSProperties;
};

export const SelectDeviceButton = (props: SelectDeviceButtonProps): JSX.Element | null => {
  const { create: createModal } = useModal();
  const { stream, refs, cameraPermission, state } = React.useContext(WebcamContext);
  const chooseDeviceMenu: React.MouseEventHandler<HTMLButtonElement> = event => {
    const { currentTarget } = event;

    navigator.mediaDevices.enumerateDevices().then(devices => {
      createModal(
        <Modal arrow className={styles.flyout}>
          {({ close }) => (
            <>
              {devices.filter(isVideoInput).map(device => (
                <Button
                  key={device.deviceId}
                  small
                  className={styles.flyoutButton}
                  name="choose device"
                  variant="text"
                  onClick={() => {
                    if (streamIsNotDevice(refs.stream.current, device.deviceId)) {
                      stream.start(selectVideoDevice(device.deviceId));
                    }
                    close();
                  }}
                >
                  {device.label || 'Unknown Camera'}
                </Button>
              ))}
            </>
          )}
        </Modal>,
        { target: currentTarget, placement: 'left-start' },
      );
    });
  };

  if (!['granted', 'UNSUPPORTED', 'unknown'].includes(cameraPermission) || state.capture) {
    return null;
  }

  return (
    <Button {...props} name="settings" variant="svg" onClick={chooseDeviceMenu}>
      <Icon size={20} type="more" />
    </Button>
  );
};
