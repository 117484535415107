import * as React from 'react';

import { cx } from '@cobbler-io/utils/src';

import { Button } from '@cobbler-io/core-ui/src/Button';
import { Icon } from '@cobbler-io/core-ui/src/Icon';

import { ColumnInstance, FilterValue, IdType } from 'react-table';

import styles from './FilterContent.scss';

type FilterContentProps<D extends UnknownObject> = {
  column: ColumnInstance<D>;
  isFiltered: boolean;
  setFilter: (filter: { id: IdType<D>; value: FilterValue }) => void;
  hideFilter: (columnId: string) => void;
};

export const FilterContent = <D extends UnknownObject>(
  props: FilterContentProps<D>,
): JSX.Element => {
  const { column, setFilter, hideFilter, isFiltered } = props;
  const { canFilter, Filter } = column;

  // If the column is not filterable, don't render anything
  const isFilterable = canFilter && Boolean(Filter);
  if (!isFilterable) {
    // @ts-expect-error: this is fine for react
    return null;
  }

  // Set the filter value and show the green line in case the value is set
  const handleFilter = (id: string, value: string): void => {
    setFilter({ id, value });
  };

  // Update the reference to the filter component to override the default behavior of updating the filter value
  // without clicking the button.
  const refFilter = {
    ...column.instance,
    column,
    setFilter: handleFilter,
  };

  return (
    <div className={cx(styles.container, isFiltered && styles.filtered)}>
      <div className={styles.filterContainer}>
        {/* Render the filter component */}
        <div className={styles.filter}>{column.Filter(refFilter)}</div>
        <Button
          small
          className={styles.hideFilter}
          name={`hide-filter-${column.id}`}
          variant="svg"
          onClick={() => hideFilter(column.id)}
        >
          <Icon size={14} type="removeCircle" />
        </Button>
      </div>
    </div>
  );
};

FilterContent.displayName = 'FilterContent';
