/* eslint-disable complexity */
import * as React from 'react';

import { cx } from '@cobbler-io/utils/src/cx';

import { useForwardedRef } from '@cobbler-io/hooks/src/useForwardedRef';
import { useGetLatest } from '@cobbler-io/hooks/src/useGetLatest';

import { useClickAway } from '@cobbler-io/core-ui/src/ClickAway';

import { useCurrentModal } from '../Modal';
import { ChipValues } from './ChipValues';
import { ClearButton } from './ClearButton';
import { ComboBoxLoading } from './ComboBoxLoading';
import { DropdownPopup } from './DropdownPopup';
import { InlineTextValues } from './InlineTextValues';
import { Item } from './Item';
import { Listbox } from './Listbox';
import { ListboxNoOptions } from './ListboxNoOptions';
import { ListboxOption } from './ListboxOption';
import { ToggleButton } from './ToggleButton';
import { ComboBoxProps, ComboBoxType } from './types';
import { useDropdown } from './useDropdown';
import { useFallback } from './useFallback';
import { useSFInterface } from './useSFInterface';

import styles from './ComboBox.scss';

/* eslint-disable max-lines-per-function */

const getDefaultValue = <TValue extends any = any>(
  value: TValue | TValue[] | undefined,
  items: Item<TValue>[],
): Item<TValue>[] => {
  const tmp = Array.isArray(value) ? value : [value];

  // Does not support items that are not in items array...
  return tmp.map(val => items.find(item => item.value === val)).filter(Boolean) as Item<TValue>[];
};

export const ComboBox: ComboBoxType = React.forwardRef(
  (props: ComboBoxProps, forwardRef: React.RefObject<HTMLDivElement>) => {
    const {
      className,
      id,
      label,
      multiple,
      addFallback,
      required = false,
      clearButton = false,
      limitSuggestions = Infinity,
      variant = 'inlineText',
      dropdownClassName,
      dropdownStyles,
      adjustActiveItems: userAdjust,
    } = props;

    const adjustForFallback = useFallback(addFallback, limitSuggestions);

    const adjustActiveItems = userAdjust
      ? (input: string, items: Item[]) => adjustForFallback(input, userAdjust(input, items))
      : adjustForFallback;

    const dropdownHook = useDropdown({
      ...props,
      adjustActiveItems,
      defaultSelected: props.defaultSelected ?? getDefaultValue(props.defaultValue, props.items),
    });

    const {
      open,
      getComboBoxProps,
      getInputProps,
      getToggleButtonProps,
      getClearButtonProps,
      getItemProps,
      getListboxProps,
      activeItems,
      closeList,
      clearFilter,
      selected,
      removeSelected,
      filter,
      disabled,
      readonly,
      refs,
    } = dropdownHook;

    const getSelected = useGetLatest(selected);

    const currentModal = useCurrentModal();
    currentModal?.watchRef?.(refs.comboBox);
    currentModal?.watchRef?.(refs.listBox);

    const ref = useForwardedRef(forwardRef);
    const containerRef = refs.comboBox;

    const clickAwayRefs = React.useRef([refs.comboBox, refs.listBox]);

    useClickAway(clickAwayRefs, () => {
      closeList();
      clearFilter();
    });

    const { errors, focus } = useSFInterface<any>({
      ...props,
      ...dropdownHook,
      getSelected,
      inputRef: refs.input,
      ref,
    });

    /* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */

    return (
      <div
        className={cx(
          styles.container,
          required && 'required',
          errors.length > 0 && styles.hasErrors,
          className,
        )}
      >
        {label && (
          <label className={styles.label} htmlFor={id}>
            {label}
          </label>
        )}
        {variant === 'chip' && (
          <div className={styles.chips}>
            <ChipValues removeSelected={removeSelected} selected={selected} />
          </div>
        )}
        <div
          {...getComboBoxProps()}
          className={cx(styles.combobox, styles[multiple ? 'multiple' : 'single'])}
          onClick={focus}
        >
          <div className={styles.values}>
            {/* Only show the selected value if multiple or single but not filtering */}
            {['inlineChip', 'inlineText'].includes(variant) && (multiple || !filter) && (
              <>
                {variant === 'inlineChip' && (
                  <div className={styles.inlineChips}>
                    <ChipValues removeSelected={removeSelected} selected={selected} />
                  </div>
                )}
                {variant === 'inlineText' && <InlineTextValues selected={selected} />}
              </>
            )}

            <input
              {...getInputProps()}
              className={cx(styles.textbox, 'listbox-textbox')}
              placeholder={selected?.length ? '' : props.placeholder}
              type="search"
            />
          </div>

          <div className={styles.controls}>
            {clearButton && !readonly && !disabled && Boolean(selected.length || filter) && (
              <ClearButton {...getClearButtonProps()} className="listbox-button-clear" />
            )}
            <ToggleButton {...getToggleButtonProps()} className="listbox-button-toggle" />

            {open && (
              <DropdownPopup
                ref={refs.listBox}
                className={cx(dropdownClassName, 'listbox-dropdown-popup')}
                reference={containerRef}
                style={dropdownStyles}
              >
                <Listbox {...getListboxProps()}>
                  {activeItems.length === 0 && <ListboxNoOptions />}
                  {activeItems.map((item: Item, index: number) => (
                    // eslint-disable-next-line react/jsx-key
                    <ListboxOption {...getItemProps(item, index)} />
                  ))}
                </Listbox>
              </DropdownPopup>
            )}
          </div>
        </div>
        <div className={styles.errors}>
          {errors.map(err => (
            <div key={JSON.stringify(err)} className={styles.error}>
              {err}
            </div>
          ))}
        </div>
      </div>
    );
  },
);

ComboBox.displayName = 'ComboBox';
ComboBox.Loading = ComboBoxLoading; // eslint-disable-line
