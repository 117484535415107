import { pick } from '@cobbler-io/utils/src/pick';

/* eslint-disable no-console */
import introspectionQueryResultData from '@cobbler-io/app/src/api/fragment-matcher';
import { BudgetEditorPlannedSpendType } from '@cobbler-io/app/src/api/graphql-types';

import {
  defaultDataIdFromObject,
  InMemoryCache,
  IntrospectionFragmentMatcher,
} from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { onError } from 'apollo-link-error';
import { HttpLink } from 'apollo-link-http';

declare const __DEV__: boolean;

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
});

const createFromKeys = <T extends Record<string, unknown>, K extends keyof T = keyof T>(
  keys: K[],
  obj: T,
) => {
  return Object.values(pick(keys, obj)).join(':');
};

const cache = new InMemoryCache({
  dataIdFromObject: object => {
    switch (object.__typename) {
      case 'BudgetEditorPlannedSpendType':
        return createFromKeys(
          ['__typename', 'budgetLineId', 'revisionId', 'start'],
          object as BudgetEditorPlannedSpendType,
        );
      case 'RevisionAmountType2':
        // TODO make this more identifiable
        return defaultDataIdFromObject(object);
      default:
        return defaultDataIdFromObject(object); // fall back to default handling
    }
  },
  fragmentMatcher,
});

// type ServerParseError
type ServerParseError = {
  response: Response; // Object returned from fetch()
  statusCode: number; // HTTP status code
  bodyText: string; // text that was returned from server
};

const isServerParseError = (err: any): err is ServerParseError =>
  err.response && typeof err.statusCode === 'number' && typeof err.bodyText === 'string';

const shouldLogout = (err: any) => err && isServerParseError(err) && err.statusCode === 401;

type Params = {
  signOut: () => void;
  gqlClient: any;
  setAuthHeaders: () => { [key: string]: string };
};

const loggerLink = new ApolloLink((operation, forward) => {
  operation.setContext({ start: new Date() });
  return forward(operation).map(response => {
    const responseTime = +new Date() - operation.getContext().start;
    console.info(`GraphQL operation ${operation.operationName} took: ${responseTime}ms`);
    return response;
  });
});

const errorLink = (signOut: () => void) =>
  onError(errResponse => {
    const { graphQLErrors, networkError } = errResponse;
    console.error(errResponse);
    if (shouldLogout(networkError)) {
      signOut();
    } else {
      // Maybe we should throw a sentry notification here
      // 400 as a bad request
      // 500 for something bad
      // Else...
      if (graphQLErrors) {
        graphQLErrors.forEach(err => {
          console.group('GraphQL Error');
          if (err.extensions) {
            console.error('Code:', err.extensions.code);
            console.error('Message:', err.extensions.data?.message ?? 'No message');
          } else {
            console.log(`Message: ${err.message}, Location: ${err.locations}, Path: ${err.path}`);
          }
          console.groupEnd();
        });
      }
      if (networkError) {
        console.log(`[Network error]: ${networkError}`);
      }
    }
  });

const createFetch =
  (getAuthHeader: () => { [key: string]: string }) => async (uri: string, options: RequestInit) =>
    fetch(uri, { ...options, headers: { ...options.headers, ...getAuthHeader() } });

export const createGQLClient = ({ signOut, gqlClient, setAuthHeaders }: Params) => {
  if (gqlClient) {
    return gqlClient;
  }

  return new ApolloClient({
    cache,
    defaultOptions: {
      query: {
        errorPolicy: 'all',
        fetchPolicy: 'cache-first',
      },
      watchQuery: {
        errorPolicy: 'ignore',
        fetchPolicy: 'cache-and-network',
      },
    },
    link: ApolloLink.from(
      [
        __DEV__ && loggerLink,
        errorLink(signOut),
        new HttpLink({
          credentials: 'same-origin',
          fetch: createFetch(setAuthHeaders),
          uri: '/api/graphql',
        }),
      ].filter(Boolean) as ApolloLink[],
    ),
  });
};

export default createGQLClient;
