import * as React from 'react';

import { Button } from '@cobbler-io/core-ui/src/Button';
import { Icon } from '@cobbler-io/core-ui/src/Icon';

import NotificationBadge from '../../../NotificationBadge';
import { useCurrentDataGrid } from '../../DataGridContext';

import styles from './FilterButton.scss';

export const FilterButton = (): JSX.Element => {
  const { toggleFilterDrawer, state } = useCurrentDataGrid().getInstance() ?? {};
  const numFilters = state.filters.length;

  return (
    <Button
      small
      className={styles.iconButtonToolbar}
      name="toggle-grid-filters"
      variant="text"
      onClick={toggleFilterDrawer}
    >
      <NotificationBadge small count={numFilters}>
        <Icon size={14} type="filterList" />
      </NotificationBadge>
      Filter
    </Button>
  );
};

FilterButton.displayName = 'FilterButton';
