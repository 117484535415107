import { addMonth } from '@cobbler-io/dates/src/addMonth';
import { getFiscalYear } from '@cobbler-io/dates/src/getFiscalYear';
import { getHalfDates } from '@cobbler-io/dates/src/getHalfDates';
import { DateLike } from '@cobbler-io/dates/src/isDateLike';

export type HalfPeriod = {
  start: Date;
  end: Date;
  name: string;
  year: number;
  half: number;
};

/**
 * Gets Halves in a period.
 *
 * Currently, it'll return any halves if there is partial overlap, so for a twleve-month period,
 * you'll get either four to six halves back (depending on overlap). We might consider adding an
 * option that will return only periods that fit squarely into the range (e.g. no partials).
 */
export const getHalvesInPeriod = (start: DateLike, end: DateLike, startMonth = 0): HalfPeriod[] => {
  let reference = new Date(start);
  const endDate = new Date(end);

  const halves = [];

  // This has some duplication in it, and it's likely doing three-plus times the work it should
  while (reference < endDate) {
    const half = getHalfDates(reference, startMonth);
    const fiscalYear = getFiscalYear(reference, startMonth);
    halves.push({ ...half, year: fiscalYear, name: `H${half.half} ${fiscalYear}` });
    reference = addMonth(reference);
  }

  return Object.values(
    halves.reduce<Record<string, HalfPeriod>>((acc, half) => ({ ...acc, [half.name]: half }), {}),
  );
};
