import * as React from 'react';

import { Discussion } from '@cobbler-io/app/src/ndm/components/Discussion';
import { useBudgetLineDetails } from '@cobbler-io/app/src/providers';

const ENTITY_TYPE = 'BUDGET_LINE';
export const BudgetLineDetailsResolvedDiscussions = (): JSX.Element | null => {
  const { budgetLineName, discussions } = useBudgetLineDetails();

  if (!discussions) {
    return null;
  }
  const resolvedDiscussions = discussions.filter(d => d.isResolved);

  return (
    <>
      <h2 className="font-title">{budgetLineName || 'Discussions'}</h2>

      {!resolvedDiscussions.length && (
        <p>
          <em>There aren’t any archived discussions for this line.</em>
        </p>
      )}

      {resolvedDiscussions.map(discussion => (
        <Discussion
          key={discussion.id}
          showStatus
          comments={discussion.comments}
          entityType={ENTITY_TYPE}
          id={discussion.id}
          resolved={discussion.isResolved}
        />
      ))}
    </>
  );
};

BudgetLineDetailsResolvedDiscussions.displayName = 'BudgetLineDetailsResolvedDiscussions';

export default BudgetLineDetailsResolvedDiscussions;
