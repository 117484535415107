// ReactTable plugins mutate a lot data, so we have to disable a lot of our own
// rules. Also, the reducer takes more than 3 params so we allow it.
/* eslint-disable max-params, @typescript-eslint/no-dynamic-delete, functional/immutable-data */
import { actions, ActionType, IdType, Row, TableInstance, TableState } from 'react-table';

// Actions
actions.resetSelectedRows = 'resetSelectedRows';
actions.toggleAllRowsSelected = 'toggleAllRowsSelected';
actions.toggleRowSelected = 'toggleRowSelected';
actions.toggleAllPageRowsSelected = 'toggleAllPageRowsSelected';

export const reducer = <D extends UnknownObject>(
  state: TableState<D>,
  action: ActionType,
  previousState: TableState<D>,
  instance: TableInstance<D>,
): TableState<D> => {
  if (action.type === actions.init) {
    return {
      ...state,
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      selectedRowIds: {} as Record<IdType<D>, boolean>,
    };
  }

  if (action.type === actions.resetSelectedRows) {
    return {
      ...state,
      selectedRowIds: instance.initialState.selectedRowIds || {},
    };
  }

  if (action.type === actions.toggleAllRowsSelected) {
    const { value: setSelected } = action;
    const { isAllRowsSelected, rowsById, nonGroupedRowsById = rowsById } = instance;

    const shouldSelectAll = typeof setSelected === 'undefined' ? !isAllRowsSelected : setSelected;
    const selectedRowIds = { ...state.selectedRowIds };

    if (shouldSelectAll) {
      // Add all selected ids to the state
      Object.keys(nonGroupedRowsById).forEach(rowId => {
        const id = rowId as IdType<D>;
        selectedRowIds[id] = true;
      });
    } else {
      // Remove all selected ids from the state
      Object.keys(nonGroupedRowsById).forEach(rowId => {
        const id = rowId as IdType<D>;
        delete selectedRowIds[id];
      });
    }

    return {
      ...state,
      selectedRowIds,
    };
  }

  if (action.type === actions.toggleRowSelected) {
    const { id, value: setSelected } = action;

    const { rowsById, selectSubRows = true, getSubRows } = instance;

    const isSelected = state.selectedRowIds[id];
    const shouldExist = typeof setSelected === 'undefined' ? !isSelected : setSelected;

    if (isSelected === shouldExist) {
      return state;
    }

    const newSelectedRowIds = { ...state.selectedRowIds };

    const handleRowById = (rowId: IdType<D>): void => {
      const row = rowsById[rowId];

      if (!row.isGrouped) {
        if (shouldExist) {
          newSelectedRowIds[rowId] = true;
        } else {
          delete newSelectedRowIds[rowId];
        }
      }

      if (selectSubRows && getSubRows(row)) {
        return getSubRows(row).forEach((r: Row<D>) => {
          handleRowById(r.id);
        });
      }
    };

    handleRowById(id);

    return {
      ...state,
      selectedRowIds: newSelectedRowIds,
    };
  }

  if (action.type === actions.toggleAllPageRowsSelected) {
    const { value: setSelected } = action;
    const { page, rowsById, selectSubRows = true, isAllPageRowsSelected, getSubRows } = instance;

    const selectAll = typeof setSelected === 'undefined' ? !isAllPageRowsSelected : setSelected;

    const newSelectedRowIds = { ...state.selectedRowIds };

    const handleRowById = (id: IdType<D>): void => {
      const row = rowsById[id];

      if (!row.isGrouped) {
        if (selectAll) {
          newSelectedRowIds[id] = true;
        } else {
          delete newSelectedRowIds[id];
        }
      }

      if (selectSubRows && getSubRows(row)) {
        return getSubRows(row).forEach((r: Row<D>) => {
          handleRowById(r.id);
        });
      }
    };

    page.forEach((r: Row<D>) => {
      handleRowById(r.id);
    });

    return {
      ...state,
      selectedRowIds: newSelectedRowIds,
    };
  }
  return state;
};
