export const takeWhile = <T extends any>(
  x: number,
  callback: UnaryFn<T, boolean>,
  arr: T[],
): T[] => {
  const out: T[] = [];

  for (const item of arr) {
    if (callback(item)) {
      // eslint-disable-next-line functional/immutable-data
      out.push(item);

      if (out.length >= x) {
        return out;
      }
    }
  }

  return out;
};
