/* eslint-disable max-params */
import { Row } from 'react-table';

const getRowValuesByColumnID = <D extends UnknownObject>(
  row1: Row<D>,
  row2: Row<D>,
  columnId: string,
): [any, any] => {
  return [row1.values[columnId], row2.values[columnId]];
};

const toString = (a: unknown) => {
  if (typeof a === 'number') {
    if (isNaN(a) || a === Infinity || a === -Infinity) {
      return '';
    }
    return String(a);
  }
  if (typeof a === 'string') {
    return a;
  }
  return '';
};

const reSplitAlphaNumeric = /(?:number[0-9]+)/gmu;
// Default sort function extracted from react-table
// It handles numbers, mixed alphanumeric combinations, and even
// null, undefined, and Infinity
export const alphanumeric = <D extends UnknownObject>(
  rowA: Row<D>,
  rowB: Row<D>,
  columnId: string,
  desc: boolean = false,
  caseInsensitive: boolean = false,
): number => {
  // eslint-disable-next-line functional/no-let, no-restricted-syntax
  let [a, b] = getRowValuesByColumnID(rowA, rowB, columnId);

  // Force to strings (or "" for unsupported types)
  a = toString(a);
  b = toString(b);

  // If case insensitive, lowercase the strings
  if (caseInsensitive) {
    a = a.toLocaleLowerCase();
    b = b.toLocaleLowerCase();
  }

  // Split on number groups, but keep the delimiter
  // Then remove falsey split values
  a = a.split(reSplitAlphaNumeric).filter(Boolean);
  b = b.split(reSplitAlphaNumeric).filter(Boolean);

  // While
  while (a.length && b.length) {
    const aa = a.shift();
    const bb = b.shift();

    const an = parseInt(aa);
    const bn = parseInt(bb);

    // eslint-disable-next-line @typescript-eslint/require-array-sort-compare
    const combo = [an, bn].sort();

    // Both are string
    if (isNaN(combo[0])) {
      if (aa > bb) {
        return 1;
      }
      if (bb > aa) {
        return -1;
      }

      // eslint-disable-next-line no-continue
      continue;
    }

    // One is a string, one is a number
    if (isNaN(combo[1])) {
      return isNaN(an) ? -1 : 1;
    }

    // Both are numbers
    if (an > bn) {
      return 1;
    }
    if (bn > an) {
      return -1;
    }
  }

  return a.length - b.length;
};

export const alphanumericInsensitive = (
  rowA: Row,
  rowB: Row,
  columnId: string,
  desc: boolean,
): number => {
  return alphanumeric(rowA, rowB, columnId, desc, true);
};

export const sortBoolean = <D extends UnknownObject>(
  rowA: Row<D>,
  rowB: Row<D>,
  columnId: string,
): number => {
  const [a, b] = getRowValuesByColumnID(rowA, rowB, columnId, false);
  if (a === b) {
    return 0;
  }
  if (a) {
    return 1;
  }
  return -1;
};

export const sortNumeric = <D extends UnknownObject>(
  rowA: Row<D>,
  rowB: Row<D>,
  columnId: string,
): number => {
  const [a, b] = getRowValuesByColumnID(rowA, rowB, columnId, false);
  if (a === b) {
    return 0;
  }
  if (a > b) {
    return 1;
  }
  return -1;
};
