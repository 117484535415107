/* eslint-disable functional/immutable-data */
/* eslint-disable func-style */
import * as React from 'react';

import { sumBy } from '@cobbler-io/utils/src/array/sumBy';
import { noop } from '@cobbler-io/utils/src/noop';

import {
  actions, ActionType, CellProps, ColumnInstance, defaultColumn, ensurePluginOrder, HeaderProps,
  TableInstance, UseTableInstanceProps,
} from 'react-table';

export type UsePinnedColumnsState = {
  pinnedColumns: string[];
};

// Default Column
defaultColumn.canPin = false;

// Actions
actions.unpinAllColumns = 'unpinAllColumns';
actions.unpinColumn = 'unpinColumn';
actions.pinColumn = 'pinColumn';
actions.togglePinColumn = 'togglePinColumn';
actions.resetPinnedColumns = 'resetPinnedColumns';
/* eslint-disable no-param-reassign */

function useInstance(instance: UseTableInstanceProps<Record<string, unknown>>) {
  const { plugins, dispatch } = instance;
  ensurePluginOrder(plugins, ['useAbsoluteLayout', 'useResizeColumns'], 'usePinnedColumns');
  const pinColumn = React.useCallback(
    (columnId: string) => dispatch({ columnId, type: actions.pinColumn }),
    [dispatch],
  );

  const unpinColumn = React.useCallback(
    (columnId: string) => dispatch({ columnId, type: actions.unpinColumn }),
    [dispatch],
  );

  const unpinAllColumns = React.useCallback(
    () => dispatch({ type: actions.unpinAllColumns }),
    [dispatch],
  );

  const togglePinColumn = React.useCallback(
    (columnId: string) => dispatch({ columnId, type: actions.togglePinColumn }),
    [dispatch],
  );

  Object.assign(instance, { pinColumn, togglePinColumn, unpinAllColumns, unpinColumn });
}

// eslint-disable-next-line max-params
function reducer(
  state: UsePinnedColumnsState,
  action: ActionType,
  _: any,
  instance: TableInstance,
) {
  if (action.type === actions.init) {
    return {
      // @ts-expect-error: warns that it will be overwritten, but this is the init phase
      pinnedColumns: [],
      ...state,
    };
  }

  if (action.type === actions.pinColumn) {
    const { columnId } = action;

    // @todo we need to figure out what to do with the non-leaf headers
    if (state.pinnedColumns.includes(columnId)) {
      return state;
    }

    return {
      ...state,
      pinnedColumns: [...new Set([...state.pinnedColumns, columnId])],
    };
  }

  if (action.type === actions.unpinColumn) {
    const { columnId } = action;

    if (!state.pinnedColumns.includes(columnId)) {
      return state;
    }

    return {
      ...state,
      pinnedColumns: state.pinnedColumns.filter(x => x !== columnId),
    };
  }

  if (action.type === actions.unpinAllColumns) {
    if (!state.pinnedColumns.length) {
      return state;
    }

    return { ...state, pinnedColumns: [] };
  }

  if (action.type === actions.togglePinColumn) {
    const { columnId } = action;
    if (state.pinnedColumns.includes(columnId)) {
      return {
        ...state,
        pinnedColumns: state.pinnedColumns.filter(x => x !== columnId),
      };
    }

    return {
      ...state,
      pinnedColumns: [...state.pinnedColumns, columnId],
    };
  }

  if (action.type === actions.resetPinnedColumns) {
    return {
      ...state,
      pinnedColumns: instance.initialState.pinnedColumns || [],
    };
  }

  return state;
}

const defaultGetCellPinnableProps = (
  props: CellProps<any>,
  { cell, instance }: { cell: any; instance: TableInstance & { state: UsePinnedColumnsState } },
) => {
  const { style: prevStyle } = props;
  const { isPinned } = cell.column;

  if (!isPinned) {
    // If it's not pinned, don't do anything
    return props;
  }

  const pinnedColumns = instance.visibleColumns.filter(x =>
    instance.state.pinnedColumns.includes(x.id),
  );
  const before = pinnedColumns.slice(
    0,

    pinnedColumns.findIndex(x => x.id === cell.column.id),
  );

  const left = sumBy(before, 'width', 0) - 1;

  const style = {
    ...prevStyle,
    left,
    position: 'sticky',
    zIndex: 1,
  };

  return { ...props, style };
};

const defaultGetPinnableHeaderProps = (
  props: HeaderProps<any>,
  {
    column,
    instance,
  }: {
    column: ColumnInstance & {
      canPin: boolean;
      pinColumn: () => void;
      unpinColumn: () => void;
      togglePinColumn: () => void;
      isPinned: boolean;
    };
    instance: TableInstance & { state: UsePinnedColumnsState };
  },
) => {
  const { style: prevStyle } = props;
  const { id, canPin } = column;

  column.pinColumn = canPin ? () => instance.pinColumn(id) : noop;
  column.unpinColumn = canPin ? () => instance.unpinColumn(id) : noop;

  column.togglePinColumn = canPin ? () => instance.togglePinColumn(id) : noop;

  column.isPinned = instance.state.pinnedColumns.includes(id);

  if (!column.isPinned) {
    return props;
  }

  const pinnedColumns = instance.visibleColumns.filter(x =>
    instance.state.pinnedColumns.includes(x.id),
  );
  const before = pinnedColumns.slice(
    0,

    pinnedColumns.findIndex(x => x.id === column.id),
  );

  const left = sumBy(before, 'width', 0) - 1;

  const style = column.isPinned
    ? {
        ...prevStyle,
        left,
        position: 'sticky',
        zIndex: 1,
      }
    : prevStyle;

  return { ...props, style };
};

// TODO type better than this
export const usePinnedColumns = (hooks: Record<string, any>) => {
  hooks.getPinnableProps = [defaultGetCellPinnableProps];
  hooks.getHeaderProps.push(defaultGetPinnableHeaderProps);
  hooks.getCellProps.push(defaultGetCellPinnableProps);
  hooks.getFooterProps.push(defaultGetPinnableHeaderProps);
  hooks.stateReducers.push(reducer);
  hooks.useInstance.push(useInstance);
};

usePinnedColumns.pluginName = 'usePinnedColumns';
