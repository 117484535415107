import * as React from 'react';

import { head } from '@cobbler-io/utils/src/array/head';
import { noop } from '@cobbler-io/utils/src/noop';
import { prop } from '@cobbler-io/utils/src/prop';

import { FormContext, SlideContext } from '@swan-form/helpers';

import { Item } from './Item';

export type ValidateSingleFn<TValue extends any> = (value: TValue) => false | string | JSX.Element;
export type ValidateMultipleFn<TValue extends any> = (
  value: TValue[],
) => false | string | JSX.Element;

type UseSFInterfaceParams<T extends any> = {
  ref: React.RefObject<HTMLDivElement>;
  inputRef: React.RefObject<HTMLInputElement>;
  selected: T[];
  getSelected: () => T[];
  multiple: boolean;
  items: Item<T>[];
  name: string;
  setSelected: (selected: Item<T>[]) => void;
  setFilter: (value: string) => void;
} & XOR<
  { multiple: true; validate?: ValidateMultipleFn<T> },
  { multiple: false; validate?: ValidateSingleFn<T> }
>;

const emptyArray: boolean | any[] = [];

export type SFHandle<T> = {
  focus: () => void;
  value: () => T;
  setValue: (value: T) => void;
  validate: (values: T[]) => false | any[];
  setFilter: (value: string) => void;
};

type UseSFInterface = {
  errors: React.ReactNode[];
  focus: () => void;
};

/**
 * Hooks up a dropdown to swan-form
 */
export const useSFInterface = <T extends any>(params: UseSFInterfaceParams<T>): UseSFInterface => {
  const formContext = React.useContext(FormContext);
  const slideContext = React.useContext(SlideContext);

  const [errors, setErrors] = React.useState<React.ReactNode[]>(emptyArray);
  const {
    ref,
    inputRef,
    multiple,
    items,
    setSelected,
    name,
    setFilter,
    validate: userValidate,
    getSelected,
  } = params;

  const fieldInterface = React.useMemo(() => {
    return {
      focus: () => {
        inputRef.current?.focus();
      },
      getRef: () => inputRef.current,
      getValue: () => {
        const justValues = getSelected().map(prop('value'));
        return multiple ? justValues : head(justValues);
      },
      name,
      reset: noop,
      setValue: (value: T) => {
        console.log('Calling setValue with T', value);
        const tmp = Array.isArray(value) ? value : [value];
        const itemValues = tmp
          .map(val => items.find((item: Item) => item.value === val))
          .filter(Boolean);
        setSelected(itemValues as Item[]);
      },
      validate: (value: any[]) => {
        if (!userValidate) {
          return false;
        }

        if (params.multiple) {
          const err = (
            Array.isArray(userValidate)
              ? (userValidate as ValidateMultipleFn<T>[]).map(fn => fn(value))
              : [(userValidate as ValidateMultipleFn<T>)(value)]
          ).filter(Boolean);

          if (err.length) {
            setErrors(err);
            return err;
          }

          setErrors(emptyArray);
          return emptyArray;
        }

        const val = Array.isArray(value) ? head(value) : value;
        const err = (
          Array.isArray(userValidate)
            ? (userValidate as ValidateSingleFn<any>[]).map(fn => fn(val))
            : [userValidate(val)]
        ).filter(Boolean);

        if (err.length) {
          setErrors(err);
          return err;
        }

        setErrors(emptyArray);
        return emptyArray;
      },
    };
  }, [inputRef, getSelected, items, multiple, name, params.multiple, setSelected, userValidate]);

  // Integrate with our forms
  React.useEffect(() => {
    formContext.registerWithForm(fieldInterface);
    slideContext.registerWithSlide(fieldInterface);

    return () => {
      formContext.unregisterFromForm(name);
      slideContext.unregisterFromSlide(name);
    };
  }, [name, formContext, slideContext, fieldInterface]);

  React.useImperativeHandle<SFHandle<T>, SFHandle<T>>(
    ref as unknown as React.RefObject<SFHandle<T>>,
    () => ({
      focus: fieldInterface.focus,
      setFilter,
      setValue: fieldInterface.setValue,
      validate: fieldInterface.validate,
      value: fieldInterface.getValue,
    }),
    [fieldInterface, setFilter],
  );

  return { errors, focus: fieldInterface.focus };
};
