import * as React from 'react';

import { hydrateDataSets } from '@cobbler-io/app/src/api/graph/hydrateDataSets';
import { useGetDataSetsQuery } from '@cobbler-io/app/src/api/graphql-types';

import { useGraphContext } from '@cobbler-io/collection/src/Graph/react';

export const LoadDataSets = (): JSX.Element => {
  const { graph } = useGraphContext();
  const { data, error } = useGetDataSetsQuery();

  React.useEffect(() => {
    if (data?.dataSets) {
      hydrateDataSets({ data: data.dataSets, graph });
    }
  }, [graph, data]);

  if (error) {
    console.error('There was an error loading datasets', error);
  }

  // @ts-expect-error: this is fine for react
  return null;
};

LoadDataSets.displayName = 'LoadDataSets';
