import * as React from 'react';

import { FormContext } from '@swan-form/form';

import styles from './DisplayFormErrors.scss';

type DisplayErrorsProps = {
  errors: string | null | Record<string, unknown>;
  dict?: Record<string, string>;
};

export const DisplayErrors = (props: DisplayErrorsProps): JSX.Element => {
  const { errors, dict } = props;

  if (typeof errors === 'string') {
    if (dict?.[errors]) {
      return <div className={styles.formErrors}>{dict[errors]}</div>;
    }

    return <div className={styles.formErrors}>{errors}</div>;
  }

  if (Array.isArray(errors)) {
    // @ts-expect-error: this is fine for React
    return (
      <div className={styles.formErrors}>
        {errors.map(err => {
          if (typeof err === 'string') {
            return err;
          }

          return JSON.stringify(err);
        })}
      </div>
    );
  }

  // @ts-expect-error: this is fine for React
  return <div className={styles.formErrors}>{JSON.stringify(errors)}</div>;
};

DisplayErrors.displayName = 'DisplayErrors';

export type DisplayFormErrorsProps = Record<string, unknown> & DisplayErrorsProps;

/**
 * Will automatically display form errors received in swan-form.
 *
 * It's probably better to capture the errors and decorate them.
 */
export const DisplayFormErrors = (props: DisplayFormErrorsProps) => {
  const { errors, dict } = props;
  const ctx = React.useContext(FormContext);

  if (typeof errors === 'string') {
    return <DisplayErrors dict={dict} errors={errors} />;
  }

  if (Array.isArray(errors) && errors.length) {
    return <DisplayErrors dict={dict} errors={errors} />;
  }

  if (typeof errors === 'object' && errors !== null && Object.keys(errors).length) {
    return <DisplayErrors dict={dict} errors={Object.values(errors)} />;
  }

  if (Array.isArray(ctx.formErrors) && ctx.formErrors.length) {
    return <DisplayErrors dict={dict} errors={ctx.formErrors} />;
  }

  if (
    typeof ctx.formErrors === 'object' &&
    ctx.formErrors !== null &&
    Object.keys(ctx.formErrors).length
  ) {
    return <DisplayErrors dict={dict} errors={Object.values(ctx.formErrors)} />;
  }

  return null;
};

DisplayFormErrors.displayName = 'DisplayFormErrors';
