import * as React from 'react';

import { cx } from '@cobbler-io/utils/src/cx';

import { ColumnInstance } from 'react-table';

import { isNumericColumn } from '../Column';
import { DataGridContext } from '../DataGridContext';

import styles from './FooterCell.scss';

type FooterCellProps<T extends Record<string, unknown> = Record<string, unknown>> = {
  column: ColumnInstance<T>;
};

export const FooterCell = <T extends Record<string, unknown> = Record<string, unknown>>({
  column,
}: FooterCellProps<T>): JSX.Element => {
  const { stuck } = React.useContext(DataGridContext);
  return (
    <div
      {...column.getHeaderProps()}
      {...column.getFooterProps()}
      className={cx(
        styles.footerCell,
        column.isPinned && stuck.includes(column.id) && styles.pinned,
        column.className,
        column.footerClassName,
        isNumericColumn(column) && styles.alignRight,
      )}
    >
      {column.render('Footer')}
    </div>
  );
};

FooterCell.displayName = 'DataGrid__FooterCell';
