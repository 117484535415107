import * as React from 'react';

import { Button } from '@cobbler-io/core-ui/src/Button';
import { Icon } from '@cobbler-io/core-ui/src/Icon';

import { useCurrentDataGrid } from '../DataGridContext';

import styles from './NoResultsState.scss';

export const NoResultsState = (): JSX.Element => {
  const instance = useCurrentDataGrid().getInstance();
  return (
    <div className={styles.container}>
      <span className={styles.title}>
        No results found
        <span aria-label="search" className={styles.search} role="img">
          🔎
        </span>
      </span>
      <span className={styles.text}>Clear your filters and try again</span>
      <Button
        className={styles.button}
        name="clearFilters"
        variant="outline"
        onClick={() => instance.setAllFilters([])}
      >
        Clear filters
      </Button>
    </div>
  );
};

NoResultsState.displayName = 'NoResultsState';
