import * as Predicate from './Predicates';
import * as Step from './Steps';
import * as Token from './tokens';
import { Traversal } from './Traversal';
import { NotImplemented } from './Traversal/NotImplemented';
import { Traverser } from './Traverser';

export const Gremlin = {
  NotImplemented,
  Predicate,
  Step,
  Token,
  Traversal,
  Traverser,
};

export { Edge } from './Edge';
export { Graph } from './Graph';
export { Traversal } from './Traversal';
export { Traverser } from './Traverser';
export { Vertex } from './Vertex';
export type { Element, GraphElement, isElement } from './Element';
export type {
  EdgeAddedEvent,
  EdgeRemovedEvent,
  GraphEvents as GraphEvent,
  VertexAddedEvent,
  VertexRemovedEvent,
} from './GraphEvents';

export * as P from './Predicates';
export * as TraversalSteps from './Steps';
