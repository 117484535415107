import { arrayIsUniform } from '@cobbler-io/utils/src/array/arrayIsUniform';

import { BudgetLineAtRevision } from '../types';
import { trimZeroes } from './trimZeroes';

/**
 * Checks if an expense is recurring based on the spend interval
 *
 * Removes leading and trailing zeroes and checks that every expense is the same.
 */
export const isExpenseRecurring = (current: BudgetLineAtRevision | null) => {
  // An item is recurring if there is a range that is all the same amount
  // it could be      0   0   0 300 300 300   0   0
  // or it could be 300 300 300 300 300 300 300 300
  if (!current) {
    // Product decision: we always default to repeated expense schedules
    return true;
  }

  const amounts = current.intervals.map(
    ({ start }) => current.plannedByInterval[start].allocated ?? 0,
  );

  return arrayIsUniform(trimZeroes(amounts));
};
