import * as React from 'react';

import { useCurrentUser } from '@cobbler-io/redux/src/modules/current-user';

import { useGetUserQuery } from '@cobbler-io/app/src/api/graphql-types';

import { MessageTemplateParameter } from '../ParameterizedMessage';

export const TaskUserToken = (props: MessageTemplateParameter): JSX.Element => {
  const { displayValue, resourceId } = props;
  const { id: currentUserId } = useCurrentUser();

  const { data } = useGetUserQuery({
    variables: {
      id: resourceId,
    },
  });

  if (resourceId === currentUserId) {
    return <span>you</span>;
  }

  const user = data?.user?.item;
  const displayName =
    // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
    displayValue || (user ? user.preferredName || user.fullName || user.email || 'No Name' : '');

  return <span>{displayName}</span>;
};

TaskUserToken.displayName = 'TaskUserToken';
