/* eslint-disable jsx-a11y/img-redundant-alt, max-lines-per-function */
import * as React from 'react';

import { cx } from '@cobbler-io/utils/src/cx';
import { imageMimeTypes } from '@cobbler-io/utils/src/image';

import { useDragAndDropFile } from '@cobbler-io/hooks/src/useDragAndDropFile';

import { Button } from '@cobbler-io/core-ui/src/Button';
import { CloseButton } from '@cobbler-io/core-ui/src/CloseButton';
import { Field } from '@cobbler-io/core-ui/src/Field';
import { Heading } from '@cobbler-io/core-ui/src/Heading';
import { Icon } from '@cobbler-io/core-ui/src/Icon';
import { ImageEditor } from '@cobbler-io/core-ui/src/ImageEditor';
import { Modal, useModal } from '@cobbler-io/core-ui/src/Modal';
import { WebcamPhoto } from '@cobbler-io/core-ui/src/WebcamPhoto';

import { required } from '@swan-form/helpers';

import styles from './BasicInformation.scss';

type BasicInformationProps = {
  imageUrl: string | null;
  setImageFile: (...args: any) => any;
};

const acceptedMimeTypes = [...imageMimeTypes];

export const BasicInformation = (props: BasicInformationProps): JSX.Element => {
  const { setImageFile, imageUrl } = props;
  const [tempFile, setTempFile] = React.useState<File | null>(null);

  const actions = {
    add: (_: string, file: File) => setTempFile(file),
    clobber: (_: string, file: File) => setTempFile(file),
  };

  const { status, ...handlers } = useDragAndDropFile(acceptedMimeTypes, false, actions);

  const createImageEditorModal = useModal(
    <Modal overlay className={styles.editorModal} clickAway="none">
      <ImageEditor file={tempFile} onAccept={setImageFile} />
    </Modal>,
    { onAfterClose: () => setTempFile(null) },
  );

  const createWebcamModal = useModal(
    <Modal overlay className={styles.webcam} clickAway="none">
      {({ close: closeWebcam }) => (
        <>
          <CloseButton className={styles.closeButton} />
          <WebcamPhoto
            height={480}
            width={640}
            onAcceptPicture={picture => {
              closeWebcam();
              setTempFile(new File([picture], 'webcam-photo.jpeg'));
            }}
          />
        </>
      )}
    </Modal>,
    { onAfterClose: () => setImageFile(null) },
  );

  React.useEffect(() => {
    if (tempFile) {
      createImageEditorModal();
    }
  }, [tempFile, createImageEditorModal]);

  return (
    <div
      {...handlers}
      className={cx(
        styles.dropzone,
        status === 'accept' && styles.accept,
        status === 'reject' && styles.reject,
      )}
    >
      <Heading>Basic Information</Heading>
      {imageUrl ? (
        <img alt="temporary-profile-picture" className={styles.blankImage} src={imageUrl} />
      ) : (
        <div className={styles.blankImage}>
          <Icon type="outlinedPerson" />
        </div>
      )}

      <div className="button-container">
        <div className={styles.decomposedBody}>
          <label className={styles.button} htmlFor="upload-picture">
            <Icon type="addPicture" /> <span>Upload a photo</span>
            <input
              accept={imageMimeTypes.join(',')}
              className="hidden"
              id="upload-picture"
              name="upload-picture"
              type="file"
              onChange={event => {
                if (event.currentTarget.files instanceof FileList) {
                  const f = event.currentTarget.files[0];
                  setTempFile(f);
                }
              }}
            />
          </label>
          <Button
            className={styles.button}
            name="webcam-photo"
            variant="text"
            onClick={createWebcamModal}
          >
            <Icon type="addCamera" /> <span>Take a photo</span>
          </Button>
        </div>
      </div>
      <div>
        <Field
          aria-required
          required
          label="Full name"
          name="fullName"
          type="text"
          validate={required}
        />
      </div>
      <div>
        <Field required label="Preferred name (optional)" name="preferredName" type="text" />
      </div>
    </div>
  );
};
