import * as React from 'react';

import { date } from '@cobbler-io/formatters/src/dates';

import { Heading } from '@cobbler-io/core-ui/src/Heading';

import { useCurrencyFormatter } from '@cobbler-io/redux/src/modules/currency';

import { CurrentActualQueryQueryResult } from '@cobbler-io/app/src/api/graphql-types';
import { ActualLine } from '@cobbler-io/app/src/ndm/components/ActualSlice';

import { ActualProperty } from '../ActualProperty';
import { normalizeSplitActual } from '../normalizeSplitActual';

import styles from './MovedTransactionDetails.scss';

type SplitActual = NonNullable<NonNullable<CurrentActualQueryQueryResult['data']>['actual']>;

type SplitTransactionDetailsProps = {
  actual: SplitActual;
};

export const MovedTransactionDetails = (props: SplitTransactionDetailsProps): JSX.Element => {
  const { actual } = props;
  const { convertToMinorUnit } = useCurrencyFormatter();

  const normalizedActual = normalizeSplitActual(actual, convertToMinorUnit);

  if (!actual) {
    // @ts-expect-error: this is fine for React
    return null;
  }

  const [original, ...slices] = normalizedActual.slices;

  return (
    <div>
      <div className={styles.header}>
        <Heading>Transaction Moved</Heading>
      </div>

      <ActualProperty
        label="Date Moved"
        value={normalizedActual.modifiedAt ? date(new Date(normalizedActual.modifiedAt)) : null}
      />

      <div className={styles.group}>
        <div className={styles.label}>Original</div>
        <ActualLine {...original} />
      </div>

      <div className={styles.group}>
        <div className={styles.label}>Moved to</div>
        {/* There should only be one slice, but just in case... */}
        {slices.map(slice => (
          <ActualLine key={slice.id} {...slice} />
        ))}
      </div>
    </div>
  );
};

MovedTransactionDetails.displayName = 'MovedTransactionDetails';
