import * as React from 'react';

import { cx } from '@cobbler-io/utils/src/cx';

import styles from './Shimmer.scss';

export type ShimmerProps = React.HTMLProps<HTMLDivElement> & {
  // Add props here
};

export const Shimmer = ({ className, ...props }: ShimmerProps): JSX.Element => {
  return (
    <div {...props} className={cx(styles.shimmer, className)} style={{ width: props.width }} />
  );
};

Shimmer.displayName = 'Shimmer';

export default Shimmer;
