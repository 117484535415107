/* eslint-disable sort-keys-fix/sort-keys-fix */
/* eslint-disable max-lines-per-function, functional/immutable-data, sort-keys */
import React, {
  createRef, MutableRefObject, ReactNode, RefObject, SyntheticEvent, useMemo,
} from 'react'; // React from 'react';
import { unstable_batchedUpdates as batch } from 'react-dom';

import { execIfFunc } from '@cobbler-io/utils/src/execIfFunc';
import { rando } from '@cobbler-io/utils/src/rando';

import { useGetLatest } from '@cobbler-io/hooks/src/useGetLatest';

import { complement, equals, filter, isNil, not, pipe, pluck, prop, reverse } from 'ramda';

import { getDescendantTreeNodes } from './getDescendantTreeNodes';
import { ModalContext } from './ModalContext';
import { ModalLayer } from './ModalLayer';
import { useModalReducer } from './modalReducer';
import { CreateModal, CreateModalWithParent, ModalId, ModalTreeNode } from './types';

const isNotNil = complement(isNil);
const filterNil = filter(isNotNil);
const ensureArray = <T extends unknown>(x: T | T[]): T[] => ([] as T[]).concat(x);

// So.... we need something like channels. And then we need to turn the FocusZone into some sort
// of Singleton, so that it can coordinate all other focus zones

/**
 * Ensures an array filtered of nullish values
 *
 * E.g. filterArray(0): [0], filterArray([null, 2, x => x, undefined]): [2, x => x]
 */
const filterArray = <T extends unknown>(
  x: T | T[] | readonly T[],
): readonly Exclude<T, null | undefined>[] =>
  // @ts-expect-error: the pipe doesn't work well with the types
  pipe(ensureArray, filterNil)(x);

const getRoot = (modal: ModalTreeNode): ModalTreeNode => modal.root;

const getParent = (modal: ModalTreeNode): ModalTreeNode | null => modal.parent;

const getChildren = (modal: ModalTreeNode): ModalTreeNode[] => Array.from(modal.children.values());

const getSiblings = (modal: ModalTreeNode): ModalTreeNode[] => {
  const parent = getParent(modal);

  const isNotThisModal: UnaryFn<ModalTreeNode, boolean> = pipe(prop('id'), equals(modal.id), not);

  return parent ? filter(isNotThisModal, getChildren(parent)) : [];
};

export const ModalManager = (props: { children: ReactNode }): JSX.Element => {
  const { children } = props;
  const [state, dispatch] = useModalReducer();
  const getState = useGetLatest(state);

  const context = useMemo(() => {
    const createModalWithParent: CreateModalWithParent = (parent, modal, options = {}) => {
      const id = options.id ?? rando();

      const node: ModalTreeNode = {
        children: new Map(),
        id,
        isSentinel: options.isSentinel ?? false,
        type: options.type ?? 'default',
        modal,
        onAfterClose: filterArray(options.onAfterClose),
        onAfterOpen: filterArray(options.onAfterOpen),
        onBeforeClose: filterArray(options.onBeforeClose),
        options,
        parent,
        ref: options.ref ?? createRef<HTMLElement>(),
        // @ts-expect-error: this will be corrected
        root: parent?.root,
        target: options.target ?? null,
        // @ts-expect-error: this will be corrected
        treeRefs: parent?.root.treeRefs ?? createRef<RefObject<HTMLElement>[]>(),
        // @ts-expect-error: this will be corrected
        watchedRefs: createRef<MutableRefObject<HTMLElement>[]>(),
      };

      node.root ??= node;
      node.treeRefs.current ??= [];
      node.treeRefs.current.push(node.ref);
      parent?.children.set(node.id, node);

      node.getRoot = () => getRoot(node);

      node.getParent = () => getParent(node);

      node.getChildren = () => getChildren(node);

      node.getSiblings = () => getSiblings(node);

      node.getDescendants = () => getDescendantTreeNodes(node);

      node.createChild = (childModal, childOptions = {}): ModalTreeNode =>
        createModalWithParent(node, childModal, childOptions);

      node.createModal = (childModal, childOptions = {}): ModalTreeNode =>
        createModalWithParent(null, childModal, childOptions);

      node.watchedRefs.current ??= [];
      node.watchRef = x => {
        node.watchedRefs.current.push(x);
      };
      node.unwatchRef = x => {
        node.watchedRefs.current = node.watchedRefs.current.filter(y => x !== y);
      };

      /* eslint-disable func-style */
      // We're adding all of these overloads so that it's easier to pipe this into callback handlers
      // for things like `onClick={close}`. We're also keeping the ability to pipe close handlers
      // into the close function
      function close(): void;
      function close(event: Event): void;
      function close(event: SyntheticEvent<unknown>): void;
      function close(...handlers: AnyFn[]): void;
      function close(...args: Event[] | SyntheticEvent<unknown>[] | AnyFn[] | undefined[]): void {
        batch(() => {
          filterArray(node.onBeforeClose).forEach(fn => {
            execIfFunc(fn);
          });

          dispatch({ type: 'Modal/Close', payload: id });

          if (Array.isArray(args)) {
            // We have callback handlers
            args.forEach(execIfFunc);
          }
        });

        queueMicrotask(() =>
          filterArray(node.onAfterClose).forEach(fn => {
            execIfFunc(fn);
          }),
        );
      }
      node.close = close;
      /* eslint-enable func-style */

      node.closeChild = (childId: ModalId): void => {
        node.children.get(childId)?.close();
      };

      node.closeChildren = (): void => {
        // We're running this backwards
        reverse(Array.from(node.children.values()))
          .map(prop('close'))
          .forEach(fn => {
            execIfFunc(fn);
          });
      };

      /* eslint-disable func-style */
      function closeToSentinel(): void;
      function closeToSentinel(event: Event): void;
      function closeToSentinel(event: SyntheticEvent<unknown>): void;
      function closeToSentinel(...handlers: AnyFn[]): void;
      function closeToSentinel(
        ...args: Event[] | SyntheticEvent<unknown>[] | AnyFn[] | undefined[]
      ): void {
        if (node.isSentinel || node.root === node) {
          node.close(...(args as unknown as AnyFn[]));
          return;
        }

        if (node.parent) {
          let parent: ModalTreeNode | null = node.parent; // eslint-disable-line

          while (parent) {
            if (parent.isSentinel || parent.root === parent) {
              parent.close(...(args as unknown as AnyFn[]));
              break;
            } else {
              parent = parent.parent; // eslint-disable-line
            }
          }

          return;
        }

        node.root.close(...(args as unknown as AnyFn[]));
      }
      /* eslint-enable func-style */

      node.closeToSentinel = closeToSentinel;

      /* eslint-disable func-style */
      function closeSelfWithoutChildren(): void;
      function closeSelfWithoutChildren(event: Event): void;
      function closeSelfWithoutChildren(event: SyntheticEvent<unknown>): void;
      function closeSelfWithoutChildren(...handlers: AnyFn[]): void;
      function closeSelfWithoutChildren(
        ...args: Event[] | SyntheticEvent<unknown>[] | AnyFn[] | undefined[]
      ): void {
        node.getChildren().forEach(child => {
          child.parent = node.parent ?? null; // eslint-disable-line no-param-reassign

          // Remove the child from the current node's children map
          node.children.delete(child.id);
          // Add the child to any parent's children map
          child.parent?.children.set(child.id, child);

          if (node.root === node) {
            child.root = child; // eslint-disable-line no-param-reassign
          }
        });

        // @ts-expect-error: this is erroring for some reason, even though it matches the above
        node.close(...args);
      }
      node.closeSelfWithoutChildren = closeSelfWithoutChildren;
      /* eslint-enable func-style */

      node.closeTree = (): void => void node.root.close();

      dispatch({ type: 'Modal/Create', payload: node });

      return node;
    };

    const closeAllModals = (): void => {
      batch(() => pluck('close', reverse(getState().flatModals)).forEach(execIfFunc));
    };

    const create: CreateModal = (modal, options): ModalTreeNode =>
      createModalWithParent(null, modal, options);

    return { create, closeAllModals, modals: [] };
  }, [dispatch, getState]);

  const layerContext = useMemo(() => {
    return {
      modals: state.modals,
      create: context.create,
      closeAllModals: context.closeAllModals,
    };
  }, [state.modals, context.create, context.closeAllModals]);

  return (
    <>
      <ModalContext.Provider key="modal-layer" value={layerContext}>
        <ModalLayer flatModals={state.flatModals} />
      </ModalContext.Provider>
      <ModalContext.Provider key="normal-tree" value={context}>
        {children}
      </ModalContext.Provider>
    </>
  );
};

ModalManager.displayName = 'ModalManager';
