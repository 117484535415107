/**
 * Gets either the decimal separator (radix point) or the grouping separator for a locale
 */
export const getNumericSeparator = (locale?: string, type: 'decimal' | 'group' = 'decimal') => {
  const testNumber = 1000.1;

  return Intl.NumberFormat(locale)
    .formatToParts(testNumber)
    .find(part => part.type === type)!.value;
};

/**
 * Gets the locale-mark used to separate larger numbers into groups.
 *
 * E.g., - in en-US, 1/2 is written as 0.5, so the radix point is `.`
 *       - in de-DE, 1/2 is written as 0,5, so the grouping mark is ','
 */
export const getRadixPoint = (locale?: string) => getNumericSeparator(locale, 'decimal');

/**
 * Gets the locale-mark used to separate larger numbers into groups.
 *
 * E.g., - in en-US, 1000 is written as 1,000, so the grouping mark is `,`
 *       - in de-DE, 1000 is written as 1.000, so the grouping mark is '.'
 *
 * @todo find a canoical name for this
 */
export const getGroupingPoint = (locale?: string) => getNumericSeparator(locale, 'group');
