import * as React from 'react';

import { Button } from '@cobbler-io/core-ui/src/Button';
import { Icon } from '@cobbler-io/core-ui/src/Icon';

type ToggleButtonProps = { open: boolean } & Omit<
  React.HTMLProps<HTMLButtonElement>,
  'type' | 'ref'
>;

export const ToggleButton = ({ open, ...props }: ToggleButtonProps) => (
  <Button small name="toggle-button" variant="svg" {...props}>
    <Icon aria-hidden size={18} type={open ? 'chevronFilledUp' : 'chevronFilledDown'} />
  </Button>
);

ToggleButton.displayName = 'ToggleButton';
