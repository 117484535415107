import * as React from 'react';

type Props = {
  // Add props here
  color?: 'white' | 'gray';
} & React.SVGProps<SVGSVGElement>

const getColor = (color?: 'white' | 'gray') => {
  const gray = 'rgb(61, 75, 85)'; // TODO USE COLOR TOKENS
  const white = 'rgb(255, 255, 255)'; // TODO USE COLOR TOKENS

  switch (color) {
    case 'white':
      return white;
    case 'gray':
      return gray;
    default:
      return gray;
  }
};

export const CobblerText: React.FC<Props> = ({ color = 'gray', ...props }) => {
  return (
    <svg
      {...props}
      fill={getColor(color)}
      version="1.0"
      viewBox="0 0 1655 361"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Cobbler</title>
      <desc>Styled word mark for Cobbler</desc>
      <path d="M551.3 124.2c.3 110 .5 123.2 2.1 130.8a125.7 125.7 0 0045.6 76.9c14.5 10.9 28 17.3 45.5 21.7 14 3.5 38.7 4.4 53.6 1.9A118 118 0 00764 322c18-18 30.3-42.3 34.6-68.5a173 173 0 00-2-55.3c-14.7-58.4-63.9-97.3-118.8-93.8a94.2 94.2 0 00-56.9 21.4l-5.8 5.3-.3-52.8-.3-52.8-2.8-5.7a33.3 33.3 0 00-15.6-15.2c-4.1-1.9-6.7-2.1-24.9-2.4l-20.3-.3.4 122.3zm132 38.8a60.6 60.6 0 0146.1 34.5c14.4 29.4 8.6 64.7-14 85.4a57.5 57.5 0 01-40 15.5c-14.5-.2-28.2-6-39.3-16.7a72.5 72.5 0 01-2.3-99.8 59 59 0 0134.2-18.8c7.7-1.2 7.7-1.2 15.3-.1z" />
      <path d="M836.3 126.2c.3 114.8.4 124.9 2.1 131.8a126.5 126.5 0 0036.3 66.1 119.7 119.7 0 0085.7 32.9c35.7 0 64.3-11.4 87.7-34.9a121.3 121.3 0 0036.3-91.1c0-18.7-1.1-26.5-6-42.3-16.7-52.6-64.9-87.7-115.9-84.4a94 94 0 00-58.2 22.4l-5.2 4.4-.3-53.3-.3-53.3-2.3-4.7c-3-6-8.9-11.9-15-14.9-4.4-2.2-6.1-2.4-25-2.7l-20.3-.3.4 124.3zm141.2 38.9a67.1 67.1 0 0142 46.4 81.8 81.8 0 01-2.6 46.8c-10.5 26.7-37.8 43.8-63.4 39.7a62.7 62.7 0 01-49.9-42.8c-11.7-32.7 2.4-71.5 31.5-86.2a72 72 0 0119.4-6.3c5.1-.8 16.7.4 23 2.4z" />
      <path d="M1130.2 164.7l.3 162.8 2.6 5.6a32.4 32.4 0 0015.8 15.8c5.3 2.5 6.5 2.6 24.9 2.9l19.2.4V189.8c0-182.5.7-167.5-7.7-176.8a43.2 43.2 0 00-9.7-7.8c-5-2.6-5.6-2.7-25.4-3l-20.2-.3.2 162.8z" />
      <path d="M115 103.6c-18.3 2.1-32.3 6-46 12.9-33.6 17-56.9 48-65.1 86.8a157.8 157.8 0 00-.1 54.4c9.7 45 39 79 80.2 92.7 13.9 4.6 26.6 6.6 43 6.6 21.5 0 39-3.6 55.6-11.4a109.6 109.6 0 0059.7-71.4l1.6-5.2h-67.4l-3 5.1a59.9 59.9 0 01-34.6 24.4c-39 9.2-76.4-27.2-73.6-71.5 2.4-37.2 29-65.9 61.2-66 16.6 0 34.6 9.4 44.9 23.4l4.8 6.6h33.4a291 291 0 0033.4-.9c0-.5-1.4-4.9-3.1-9.8a111.3 111.3 0 00-87.8-74.8c-8.4-1.5-30.5-2.7-37.1-1.9z" />
      <path d="M378.6 104.1c-43.7 6-79.2 33.1-96.2 73.3-7.6 18-10.4 32.1-10.4 53.6 0 29.7 8.1 55.4 24.4 77.5a118.3 118.3 0 0097.1 48.5 118 118 0 0086.6-36 124 124 0 0035.5-84.5 131.2 131.2 0 00-34.7-95.9 120.6 120.6 0 00-102.3-36.5zm25.6 58c19.9 4.1 37.3 21.2 44.4 43.5a92.6 92.6 0 01-.2 51.4c-12.5 37.2-51.3 53.2-83.3 34.3a66 66 0 01-28.2-40.9c-1.8-7.5-2-11-1.6-22.4.5-15.7 2-22.1 7.9-34a61 61 0 0138.1-31.6 58.8 58.8 0 0122.9-.3z" />
      <path d="M1347.1 104.1a127.8 127.8 0 00-56.7 22.6 129 129 0 00-20.2 189 126.9 126.9 0 0056.9 36.6c22.6 6.6 53.2 6.2 76.9-.9a110.2 110.2 0 0075-75.9c0-1.3-4.6-1.5-33.3-1.5h-33.4l-3.2 5c-9.6 14.8-31.2 24.2-51.9 22.6a57.4 57.4 0 01-38.7-18 65.6 65.6 0 01-15.4-25.4l-1-3.2h181.8l.7-4.7c1-6.9-.2-34.6-2-44.6-1.9-10.2-6.5-25-10.7-34.5a131.5 131.5 0 00-35.9-44.5 126 126 0 00-88.9-22.6zm31.3 56.9c17 4.3 31 15.7 38.5 31.2a49 49 0 014.1 10.1c0 1.6-4.5 1.7-59 1.7-32.4 0-59-.2-59-.5s1.3-3.5 3-7.1a62.2 62.2 0 0140.8-35.8 75 75 0 0131.6.4z" />
      <path d="M1620 104c-47.3 4.9-79.4 33.6-90.3 80.5-2 8.6-2.1 12.2-2.4 88.3l-.4 79.4 19.8-.4c19.3-.3 20-.4 25.5-3.1 7-3.4 13-10.1 15.8-17.3 1.9-5.3 2-7.6 2-65.2 0-66 .2-68.2 6.2-79.4a46.8 46.8 0 0130.8-22.9 90.7 90.7 0 0125.9-.4c2.1.6 2.1.6 2.1-28.9 0-16.2-.3-29.7-.7-29.9a200 200 0 00-34.3-.7z" />
    </svg>
  );
};

export default CobblerText;
