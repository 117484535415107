import { ActionCreator, Reducer } from 'redux';

const initialState = {
  title: 'Cobbler',
};

const SET_TITLE = 'PAGE/SET_TITLE';
type SetTitleAction = { type: 'PAGE/SET_TITLE'; payload: string };
export const setTitle: ActionCreator<SetTitleAction> = (title: string) => ({
  payload: title,
  type: SET_TITLE,
});

type Actions = SetTitleAction;

export const reducer: Reducer<typeof initialState, Actions> = (state = initialState, action) => {
  switch (action.type) {
    case SET_TITLE:
      return {
        ...state,
        title: action.payload,
      };
    default:
      return state;
  }
};

export const actions = {
  setTitle,
};
