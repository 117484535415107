import { Graph, Vertex } from '@cobbler-io/collection/src/Graph';

import { HydrateBudgetGraphQuery } from '../graphql-types';
import { getAccountChildId } from './utils';

type Data = NonNullable<NonNullable<HydrateBudgetGraphQuery['accounts']>['items']>;

type HydrateAccountsParams = {
  graph: Graph<any, any>;
  data: Data;
};

// Pull these to a higher scope so that we don't have to garbage collect as many of them
const childAccountLabels = ['ACCOUNT'];
const rootAccountLabels = ['ACCOUNT', 'ACCOUNT_ROOT'];

export const hydrateAccounts = (params: HydrateAccountsParams): void => {
  // We're going to do a single pass to create all the vertices, and then we'll do another
  // pass to connect them in a tree. So, we'll store the eventual connections here
  const queue = new Map<string, Set<Vertex>>();

  const { graph, data } = params;

  // Cycle through the responses and create vertexes
  for (const account of data) {
    const labels = account.parentId ? childAccountLabels : rootAccountLabels;
    const { id, parentId = null } = account;
    const vertex = graph.addVertex({
      id,
      labels,
      properties: {
        code: account.displayCode,
        name: account.displayName,
      },
    });

    // If there is a parent Id, then we want to store it in the queue
    if (parentId) {
      if (!queue.has(parentId)) {
        // A parent can have multiple children, so we need to instantiate it with a set to store all
        queue.set(parentId, new Set());
      }

      queue.get(parentId)!.add(vertex);
    }
  }

  for (const [parentId, children] of queue) {
    const parent = graph.getVertexById(parentId);

    if (!parent) {
      console.warn('Could not find parent account vertex', parentId);
      continue;
    }

    for (const child of children) {
      graph.addEdge({
        id: getAccountChildId(parent.id, child.id),
        from: parent,
        to: child,
        labels: ['HAS_CHILD'],
        properties: {},
      });
    }
  }
};
