import * as React from 'react';

import { identity } from '@cobbler-io/utils/src/identity';

import { Margin } from '@cobbler-io/core-ui/src/Chart/CartesianChart';

import { Group } from '@visx/group';
import Pie from '@visx/shape/lib/shapes/Pie';
import { clamp } from 'ramda';

import { SingleDonutChart } from './SingleDonutChart';

type BinaryNumberTuple = [number, number];

const MAX_RECURSIONS = 50;

// Get the percentage of the donut chart in a recursive way
const getData = (val: number, out: BinaryNumberTuple[] = [], count = 0): BinaryNumberTuple[] => {
  if (count > MAX_RECURSIONS) {
    return out;
  }

  if (val > 0) {
    const x = clamp(0, 100, val);
    out.push([x, 100 - x]);
    return getData(val - x, out, count + 1);
  }
  return out;
};

type GetSlicesParams = {
  data: BinaryNumberTuple[];
  padding: number;
  donutThickness: number;
  radius: number;
  colors: { main: string; secondary: string };
};

// Get Slices for the donut chart in a recursive way
const getSlices = (params: GetSlicesParams, slices: JSX.Element[] = []): JSX.Element[] => {
  const { data, donutThickness, radius, colors, padding } = params;
  const [datum, ...rest] = data;

  if (!datum) {
    return slices;
  }
  return getSlices(
    {
      data: rest,
      donutThickness: clamp(1, Number.MAX_SAFE_INTEGER, donutThickness / 2),
      radius: clamp(0, Number.MAX_SAFE_INTEGER, radius - donutThickness - padding),
      colors,
      padding,
    },
    slices.concat(
      <Pie
        key={slices.length + 1}
        cornerRadius={1}
        data={datum}
        fill={colors.main}
        innerRadius={radius - donutThickness}
        outerRadius={radius}
        pieSortValues={() => 1}
        pieValue={identity}
      >
        {p => <path d={p.path(p.arcs[0])!} fill={colors.main} />}
      </Pie>,
    ),
  );
};

export type DonutChartProps = {
  width?: number;
  height?: number;
  margin?: Margin;
  donutThickness?: number;
  padding?: number;
  percentage: number;
  colors: {
    main: string;
    secondary: string;
  };
};
const defaultMargin: Margin = { top: 0, right: 0, bottom: 0, left: 0 };

export const DonutChart = ({
  width = 30,
  height = 30,
  margin = defaultMargin,
  donutThickness = 4,
  padding = 2,
  percentage,
  colors,
}: DonutChartProps) => {
  // Calculate the data for the pie chart
  const innerWidth = width - margin.left - margin.right;
  const innerHeight = height - margin.top - margin.bottom;
  const centerY = innerHeight / 2;
  const centerX = innerWidth / 2;
  const radius = Math.min(innerWidth, innerHeight) / 2;

  return (
    <svg height={height} width={width}>
      <Group left={centerX + margin.left} top={centerY + margin.top}>
        {percentage <= 100 ? (
          <SingleDonutChart
            colors={colors}
            donutThickness={donutThickness}
            percentage={percentage}
            radius={radius}
          />
        ) : (
          getSlices({
            data: getData(percentage),
            radius,
            donutThickness,
            colors,
            padding,
          })
        )}
      </Group>
    </svg>
  );
};

DonutChart.displayName = 'DonutChart';

export default DonutChart;
