import { useCallback, useEffect } from 'react';

import { getCloseBehavior } from './getCloseBehavior';
import { CloseBehavior, ModalTreeNode } from './types';

export const useEscapeKeyHandler = (
  modal: ModalTreeNode,
  escape: CloseBehavior,
  getIsTop: () => boolean,
): void => {
  const escapeKeyHandler = useCallback(
    (event: KeyboardEvent): void => {
      if (!(event.key === 'Escape' && getIsTop())) {
        return;
      }
      // The getIsTop is preventing the ability to close the tree on escape... ish
      getCloseBehavior(escape, modal)();
    },
    [escape, getIsTop, modal],
  );

  useEffect(() => {
    if (escape === 'none') {
      return;
    }

    document.addEventListener('keydown', escapeKeyHandler);
    // eslint-disable-next-line consistent-return
    return () => document.removeEventListener('keydown', escapeKeyHandler);
  }, [escape, escapeKeyHandler]);
};
