/* eslint-disable array-callback-return, react/boolean-prop-naming  */
import * as React from 'react';

import { execIfFunc } from '@cobbler-io/utils/src/execIfFunc';
import { prop } from '@cobbler-io/utils/src/prop';

import { Button } from '@cobbler-io/core-ui/src/Button';
import { autosizeAll } from '@cobbler-io/core-ui/src/DataGrid/autosizeAll';
import { DataGridContext } from '@cobbler-io/core-ui/src/DataGrid/DataGridContext';
import { getColumnLabel } from '@cobbler-io/core-ui/src/DataGrid/getColumnLabel';
import { Icon } from '@cobbler-io/core-ui/src/Icon';
import { Menu, MenuChild, useFlyoutMenu } from '@cobbler-io/core-ui/src/Menu';

import { ColumnInstance } from 'react-table';

export type MenuItemsFromColumns = (
  columns: ColumnInstance<any>[],
  extraFlag?: boolean,
) => JSX.Element[];

export const getHideableMenuItems: MenuItemsFromColumns = (hideableColumns, isTableHidden) => {
  const initial = isTableHidden
    ? [
        <Menu.Item
          key="show-all-columns"
          label={<strong>Show all columns</strong>}
          onSelect={() => {
            hideableColumns.filter(col => !col.isVisible).forEach(col => col.toggleHidden(false));
          }}
        />,
      ]
    : [];

  const next = hideableColumns
    .map(col =>
      getColumnLabel(col) ? (
        <Menu.Item
          key={col.id}
          isToggle
          toggle
          checked={col.isVisible}
          label={
            col.isVisible ? (
              <span>{getColumnLabel(col)}</span>
            ) : (
              <strong>{getColumnLabel(col)}</strong>
            )
          }
          onSelect={selected => col.toggleHidden(!selected)}
        />
      ) : null,
    ) // @ts-expect-error: the boolean filter gets incorrectly typed
    .filter<JSX.Element>(Boolean);

  return initial.concat(next);
};

export const getSortableMenuItems: MenuItemsFromColumns = (sortableColumns, isTableSorted) => {
  const initialSortableLinks = isTableSorted
    ? [
        <Menu.Item
          key="clear-sort"
          label={<strong>Clear sort</strong>}
          onSelect={() => sortableColumns.find(prop('isSorted'))?.clearSortBy()}
        />,
      ]
    : [];

  return initialSortableLinks.concat(
    sortableColumns
      .map((col: ColumnInstance<any>) =>
        getColumnLabel(col) ? (
          <Menu.Item
            key={col.id}
            label={
              col.isSorted ? (
                <strong>Clear sort on {getColumnLabel(col)}</strong>
              ) : (
                `Sort ${getColumnLabel(col)!}`
              )
            }
            onSelect={() => (col.isSorted ? col.clearSortBy() : col.toggleSortBy(false, false))}
          />
        ) : null,
      ) // @ts-expect-error: the boolean filter gets incorrectly typed
      .filter<JSX.Element>(Boolean),
  );
};

export const getGroupableMenuItems: MenuItemsFromColumns = (groupableColumns, isTableGrouped) => {
  const [firstColumn] = groupableColumns;
  const initialGroupableLink = isTableGrouped
    ? [
        <Menu.Item
          key="clear-grouping"
          label={<strong>Clear grouping</strong>}
          onSelect={() => firstColumn?.instance.setGroupByExclusive()}
        />,
      ]
    : [];

  return initialGroupableLink.concat(
    groupableColumns
      .map((col: ColumnInstance<any>) => {
        const preparedLabel = getColumnLabel(col);
        if (!preparedLabel) {
          return null;
        }

        return (
          <Menu.Item
            key={col.id}
            label={
              col.isGrouped ? (
                <strong>Stop grouping by {preparedLabel}</strong>
              ) : (
                `Group by ${preparedLabel}`
              )
            }
            onSelect={() =>
              col.isGrouped
                ? col.instance.setGroupByExclusive()
                : col.instance.setGroupByExclusive(col.id)
            }
          />
        );
      }) // @ts-expect-error: the boolean filter gets incorrectly typed
      .filter<JSX.Element>(Boolean),
  );
};

export const getPinnableMenuItems: MenuItemsFromColumns = pinnableColumns => {
  return pinnableColumns
    .map(col => {
      const preparedLabel = getColumnLabel(col);
      if (!preparedLabel) {
        return null;
      }
      // col.isPinned seems to lag behind... fix this
      const isPinned = col.instance.state.pinnedColumns.includes(col.id);

      return (
        <Menu.Item
          key={col.id}
          iconType={isPinned ? 'close' : 'pushPin'}
          label={isPinned ? <strong>Unpin {preparedLabel}</strong> : `Pin ${preparedLabel}`}
          onSelect={() => execIfFunc(col?.togglePinColumn)}
        />
      );
    }) // @ts-expect-error: the boolean filter gets incorrectly typed
    .filter<JSX.Element>(Boolean);
};

export type ToolbarMenuProps = {
  autosize?: boolean;
  groupable?: boolean;
  hideable?: boolean;
  pinnable?: boolean;
  /**
   * The size of the menu icon
   */
  size?: number;
  sortable?: boolean;
  small?: boolean;

  children?: React.ReactNode;
};

const DEFAULT_ICON_SIZE = 24;

export const ToolbarMenu = <D extends Record<string, unknown>>(
  props: ToolbarMenuProps,
): JSX.Element => {
  const {
    autosize = false,
    groupable = false,
    hideable = false,
    pinnable = false,
    size = DEFAULT_ICON_SIZE,
    sortable = false,
    small = false,
    children,
  } = props;
  const ctx = React.useContext(DataGridContext);
  const { getInstance } = ctx;

  const toolbarMenu = useFlyoutMenu(() => {
    const instance = getInstance();
    const { allColumns, state } = instance;

    const getColumnById = (id?: string) =>
      id ? allColumns.find((col: ColumnInstance<D>) => col.id === id) : null;

    const createButtonLabel = (name: string, selected?: string) =>
      [name, getColumnLabel(getColumnById(selected))].filter(Boolean).join(': ');

    const sortableColumns = allColumns.filter(prop('canSortBy'));
    const pinnableColumns = allColumns.filter(prop('canPin'));
    const groupableColumns = allColumns.filter(prop('canGroupBy'));
    const hideableColumns = allColumns.filter(prop('hideable'));

    const canSort = Boolean(sortableColumns.length);
    const canPin = Boolean(pinnableColumns.length);
    const canGroup = Boolean(groupableColumns.length);
    const canHide = Boolean(hideableColumns.length);

    const isTableSorted = Boolean(state.sortBy?.length);
    const isTableGrouped = Boolean(state.groupBy?.length);
    const isTablePinned = Boolean(state.pinnedColumns?.length);
    const isTableHidden = Boolean(state.hiddenColumns?.length);

    const items = [
      sortable && canSort && (
        <Menu.Item
          key="sort"
          iconType="sort"
          label={createButtonLabel('Sort', state.sortBy[0]?.id)}
        >
          {getSortableMenuItems(sortableColumns, isTableSorted)}
        </Menu.Item>
      ),
      pinnable && canPin && (
        <Menu.Item key="pin" iconType="pushPin" label="Pinned">
          {getPinnableMenuItems(pinnableColumns, isTablePinned)}
        </Menu.Item>
      ),
      groupable && canGroup && (
        <Menu.Item
          key="group"
          iconType="bulletList"
          label={createButtonLabel('Group', state.groupBy[0])}
        >
          {getGroupableMenuItems(groupableColumns, isTableGrouped)}
        </Menu.Item>
      ),
      hideable && canHide && (
        <Menu.Item key="hide" iconType="view" label="Fields">
          {getHideableMenuItems(hideableColumns, isTableHidden)}
        </Menu.Item>
      ),

      autosize && (
        <Menu.Item key="autosize" label="Autosize" onSelect={() => autosizeAll(instance)} />
      ),
    ].filter(Boolean);

    return (
      <Menu small>
        {items as unknown as MenuChild}
        {children as unknown as MenuChild}
      </Menu>
    );
  });

  return (
    <Button name="datagrid-actions" small={small} variant="svg" onClick={toolbarMenu}>
      <Icon size={size} type="more" />
    </Button>
  );
};

ToolbarMenu.displayName = 'DataGrid__ToolbarMenu';
