import { DateRange } from '@cobbler-io/dates/src/DateRange';
import { tzAdjust } from '@cobbler-io/dates/src/tzAdjust';

export type RangeProperties = {
  start: ISO8601String;
  startDate: LocalDate;
  end: ISO8601String;
  endDate: LocalDate;
  range: DateRange;
};

/**
 * Passing in a start and end string will return an enhanced object with local dates
 */
export const createRangeProperties = (
  start: ISO8601String,
  end: ISO8601String,
): RangeProperties => {
  const startDate = tzAdjust(start);
  const endDate = tzAdjust(end);
  const range = new DateRange(startDate, endDate);

  return { start, startDate, end, endDate, range };
};
