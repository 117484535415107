/* eslint-disable sort-keys, sort-keys-fix/sort-keys-fix */
import * as React from 'react';

import { useToggle } from '@cobbler-io/hooks/src';

import { Field } from '@cobbler-io/core-ui/src/Field';

import { BudgetLineAtRevision } from '../types';
import { CustomExpense } from './CustomExpense';
import { useCustomExpense } from './CustomExpense/useCustomExpense';
import { DatePeriodType } from './getDatePeriods';
import { isExpenseRecurring } from './isExpenseRecurring';
import { RecurringExpense } from './RecurringExpense';
import { useRecurringExpense } from './useRecurringExpense';

const getResolutionDescription = (resolution: DatePeriodType): { noun: string; adverb: string } =>
  ({
    MONTH: { noun: 'month', adverb: 'monthly' },
    QUARTER: { noun: 'quarter', adverb: 'quarterly' },
    HALF: { noun: 'half', adverb: 'semiannually' },
    YEAR: { noun: 'year', adverb: 'yearly' },
    ONCE: { noun: 'once', adverb: 'once' },
    FULL: { noun: '', adverb: '' },
  }[resolution] ?? { noun: '', adverb: '' });

type ExpenseScheduleProps = {
  parent: BudgetLineAtRevision;
  current: BudgetLineAtRevision | null;
  isRevenueRootChild: boolean;
  overplannedThreshold?: MinorCurrency;
  skipAmountValidation?: boolean;
  maxHintLabel?: string;
};

export const ExpenseSchedule = (props: ExpenseScheduleProps): JSX.Element => {
  const { current } = props;
  const { active: isSame, toggle: toggleIsSame } = useToggle(isExpenseRecurring(current));

  const recurring = useRecurringExpense(props);
  const customExpenseFields = useCustomExpense(props);

  const label = isSame
    ? `Transaction is the same amount ${getResolutionDescription(recurring.recurrence).adverb}`
    : 'Transaction is the same amount';

  return (
    <>
      <div className="row">
        <div className="col small-12">
          <Field
            checked={isSame}
            label={label}
            name="expense-is-the-same"
            type="checkbox"
            onChange={toggleIsSame}
          />
        </div>
      </div>

      {isSame ? (
        <RecurringExpense {...recurring} />
      ) : (
        <CustomExpense fields={customExpenseFields} />
      )}
    </>
  );
};

ExpenseSchedule.displayName = 'ExpenseSchedule';
