/* eslint-disable max-lines-per-function, react/no-unused-prop-types */
import * as React from 'react';

import { clamp0 } from '@cobbler-io/utils/src/numbers/clamp';

import { CurrencyField, Field } from '@cobbler-io/core-ui/src/Field';

import { useCurrencyFormatter } from '@cobbler-io/redux/src/modules/currency';

import { required } from '@swan-form/helpers';

import { MaxHint } from '../MaxHint';
import { RecurringExpenseType } from './useRecurringExpense';

export const RecurringExpense = (props: RecurringExpenseType): JSX.Element => {
  const {
    amount,
    start,
    end,
    max,
    maxHintLabel,
    datePeriodStartOptions,
    datePeriodEndOptions,
    recurrence,
    recurrenceOptions,
    handleChangeSetStart,
    handleChangeSetEnd,
    validateStart,
    validateEnd,
    validateAmount,
    handleChangeAmount,
    handleChangeRecurrence,
  } = props;

  const { simpleFromMinorUnit, convertFromMinorUnit } = useCurrencyFormatter();

  // If the value exceeds the available amount, we will hide the max hint to avoid redundancy
  const exceedsAmount = validateAmount(String(convertFromMinorUnit(amount || 0)));
  return (
    <div className="row">
      <div className="col small-12 medium-6">
        <CurrencyField
          validateOnBlur
          validateOnChange
          label="Amount"
          name="expense-value"
          validate={[required, validateAmount]}
          value={convertFromMinorUnit(amount || 0)}
          onChange={handleChangeAmount}
        />
        {!exceedsAmount && (
          <MaxHint format={simpleFromMinorUnit} label={maxHintLabel} value={clamp0(max)} />
        )}
      </div>
      <div className="col small-12 medium-6">
        <Field
          label="Can be spent..."
          name="frequency"
          options={recurrenceOptions}
          type="select"
          value={recurrence}
          onChange={handleChangeRecurrence}
        />
      </div>
      <div className="col small-6">
        <Field
          validateOnChange
          label={recurrence === 'ONCE' ? 'In...' : 'From...'}
          name="expense-start"
          options={datePeriodStartOptions}
          type="select"
          validate={recurrence === 'ONCE' ? undefined : validateStart}
          value={start}
          onChange={handleChangeSetStart}
        />
      </div>
      {recurrence !== 'ONCE' && (
        <div className="col small-6">
          <Field
            validateOnChange
            label="To..."
            name="expense-end"
            options={datePeriodEndOptions}
            type="select"
            validate={validateEnd}
            value={end}
            onChange={handleChangeSetEnd}
          />
        </div>
      )}
    </div>
  );
};

RecurringExpense.displayName = 'RecurringExpense';
