import { DateRange } from '@cobbler-io/utils/src/date';

import {
  FiscalYearPeriod, getFiscalYearsInPeriod,
} from '@cobbler-io/dates/src/getFiscalYearsInPeriod';
import { getHalvesInPeriod, HalfPeriod } from '@cobbler-io/dates/src/getHalvesInPeriod';
import { getMonthsInPeriod, MonthPeriod } from '@cobbler-io/dates/src/getMonthsInPeriod';
import { getQuartersInPeriod, QuarterPeriod } from '@cobbler-io/dates/src/getQuartersInPeriod';
import { DateLike } from '@cobbler-io/dates/src/isDateLike';
import { tzAdjust } from '@cobbler-io/dates/src/tzAdjust';

import { BudgetResolution } from '@cobbler-io/app/src/api';

export type DatePeriodType = 'MONTH' | 'QUARTER' | 'HALF' | 'YEAR' | 'FULL' | 'ONCE';

export type DatePeriod =
  | MonthPeriod
  | QuarterPeriod
  | HalfPeriod
  | FiscalYearPeriod
  | { start: Date; end: Date; name: string };

export type DatePeriodWithRange = DatePeriod & { range: DateRange };

export type GetDatePeriodParams = {
  type: DatePeriodType;
  min: DateLike;
  max: DateLike;
  budgetResolution: BudgetResolution;
  fysm?: number;
};

export const getDatePeriods = (params: GetDatePeriodParams): DatePeriod[] => {
  const { type, min: start, max: end, fysm = 0, budgetResolution } = params;
  const startDate = tzAdjust(start);
  const endDate = tzAdjust(end);

  switch (type) {
    case 'MONTH':
      return getMonthsInPeriod(startDate, endDate, fysm);
    case 'QUARTER':
      return getQuartersInPeriod(startDate, endDate, fysm);
    case 'HALF':
      return getHalvesInPeriod(startDate, endDate, fysm);
    case 'YEAR':
      return getFiscalYearsInPeriod(startDate, endDate, fysm);
    case 'ONCE':
      return getDatePeriods({ ...params, type: budgetResolution });
    case 'FULL':
      return [{ name: 'Full budget', start: startDate, end: endDate }];
    default:
      throw new Error(`Does not handle ${type}`);
  }
};

export const getDatePeriodsWithRange = (params: GetDatePeriodParams): DatePeriodWithRange[] =>
  getDatePeriods(params).map(period => ({
    ...period,
    range: new DateRange(period.start, period.end),
  }));
