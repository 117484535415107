import { Row } from 'react-table';

export const sortLocalDate =
  (key: string) =>
  <T extends Record<string, unknown>>(rowA: Row<T>, rowB: Row<T>, _columnId: string): number => {
    if (!rowA?.original || !rowB?.original) {
      return 0;
    }
    const endDateA = rowA.original[key] as string;
    const endDateB = rowB.original[key] as string;
    if (!endDateA && !endDateB) {
      return 0;
    }
    if (!endDateA) {
      return -1;
    }
    if (!endDateB) {
      return 1;
    }

    return new Date(endDateA).valueOf() - new Date(endDateB).valueOf();
  };
