/* eslint-disable no-restricted-syntax, max-lines-per-function, no-console */
import * as React from 'react';

/**
 * Gets the mimeTypes from all files in a DataTransfer object
 *
 * @param data DataTransfer
 */
const getTypesFromFiles = (data: DataTransfer): string[] => {
  const types: string[] = [];
  // The data transfer object is array-like but not array-like enough to avoid a for loop
  for (let i = 0; i < data.items.length; i++) {
    if (data.items[i].type) {
      types.push(data.items[i].type.toLowerCase());
    }
  }

  return [...new Set(types)].sort();
};

/**
 * Checks that every file in a DataTranser object are acceptable mimeTypes
 */
const shouldAcceptFiles = (accept: string[], dataTransfer: DataTransfer): boolean => {
  return getTypesFromFiles(dataTransfer).every(type => accept.includes(type));
};

export const useDragAndDropFile = (accept: string[], multiple = false, actions: any) => {
  const [status, setStatus] = React.useState<'ready' | 'accept' | 'reject'>('ready');

  const onDragEnter: React.DragEventHandler<HTMLDivElement> = event => {
    event.stopPropagation();
    event.preventDefault();

    const { dataTransfer } = event;
    const { items } = dataTransfer;
    const { length: count } = items;

    if (!multiple && count > 1) {
      console.info('REJECTION, reason: no multiple');
      // @todo do something to reject the drop
      return setStatus('reject');
    }

    // ensure that all of the file types upload are in the accept thingie
    if (!shouldAcceptFiles(accept, dataTransfer)) {
      console.info('REJECTION, reason: no accepted types');
      // @todo do something to reject the drop
      return setStatus('reject');
    }

    return setStatus('accept');
  };

  const onDragLeave: React.DragEventHandler<HTMLDivElement> = React.useCallback(event => {
    event.stopPropagation();
    event.preventDefault();
    setStatus('ready');
  }, []);

  const onDragOver = React.useCallback(event => {
    event.stopPropagation();
    event.preventDefault();

    const { dataTransfer } = event;
    const { items } = dataTransfer;
    const { length: count } = items;

    if ((!multiple && count > 1) || !shouldAcceptFiles(accept, dataTransfer)) {
      return setStatus('reject');
    }

    return setStatus('accept');
  }, []);

  const onDrop: React.DragEventHandler<HTMLDivElement> = event => {
    event.stopPropagation();
    event.preventDefault();
    const { dataTransfer } = event;

    if (!shouldAcceptFiles(accept, dataTransfer) || status === 'reject') {
      return setStatus('ready');
    }

    for (const file of Array.from(dataTransfer.files)) {
      const { name } = file;
      if (multiple) {
        // Do some better checking here...
        // - look for duplicated files (name / size / mimeType) and replace rather than duplicate
        // - ensure that you can't drag and drop multiple files when multiple is not accepted
        actions.add(name, file);
      } else {
        actions.clobber(name, file);
      }
    }
    return setStatus('ready');
  };

  return {
    onDragEnter,
    onDragOver,
    onDragLeave,
    onDrop,
    status,
  };
};
