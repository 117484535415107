/* eslint-disable max-params */
import * as React from 'react';

import { createPopper, Instance as PopperInstance, Options as PopperOptions } from '@popperjs/core';

const popperOptions: Partial<PopperOptions> = {
  placement: 'bottom-start',
  strategy: 'fixed',
  modifiers: [
    { name: 'preventOverflow', options: { padding: [5, 10] } },
    { name: 'offset', options: { offset: [0, 8] } },
  ],
};

export type UsePopperArgs = {
  reference: React.MutableRefObject<any>;
  modal: React.MutableRefObject<any>;
  options?: Partial<PopperOptions>;
};

const findStrategy = (element: HTMLElement) => {
  if (!element) {
    return 'absolute';
  }

  // eslint-disable-next-line prefer-destructuring
  let parentElement = element.parentElement;
  while (parentElement) {
    if (parentElement.style.position === 'fixed') {
      return 'fixed';
    }
    if (parentElement.tagName.toLowerCase() === 'body') {
      break;
    }
    parentElement = parentElement.parentElement;
  }

  return 'absolute';
};

export const usePopper = (
  { reference, modal, options = popperOptions }: UsePopperArgs,
  ...deps: any[]
) => {
  const popperRef = React.useRef<PopperInstance | null>(null);
  React.useLayoutEffect(() => {
    if (reference.current && modal.current) {
      if (!popperRef.current) {
        popperRef.current = createPopper(reference.current, modal.current, {
          ...options,
          strategy: findStrategy(reference.current),
        });
      } else {
        popperRef.current.update();
      }
    }
  }, [...deps]);

  return popperRef;
};
