import { getBy } from '../getBy';
import { sum } from './sum';

/**
 * Sums the subkeys of an array of items
 */
export const sumBy = <T extends UnknownObject>(
  arr: readonly T[] | T[],
  key: string | (string | number)[],
  initial: number = 0,
): number =>
  Array.isArray(arr)
    ? sum(
        arr.map(item => getBy(item, key, 0)),
        initial,
      )
    : initial;

// TODO: Replace with: const sumBy = converge(sum, [pluck]); (in ramda-utils)
// We loose the `initial` param but we can easily do `initial + sumBy(...)`,
// and the best part is that this is curried, so we can do sumByA = sumBy('a').
