import * as React from 'react';

import styles from './ListboxNoOptions.scss';

export const ListboxNoOptions = () => (
  <li className={styles.listboxNoOptions}>
    <span>No options</span>
  </li>
);

ListboxNoOptions.displayName = 'ListBox__NoOptions';
