import { arraysAreEqual } from '@cobbler-io/utils/src/array/arraysAreEqual';

import { useRevisions } from '@cobbler-io/redux/src/modules/current-budget';

import { GetBudgetsDocument, GetBudgetsQuery } from '@cobbler-io/app/src/api/graphql-types';
import { useRegisterAsyncJob } from '@cobbler-io/app/src/api/useRegisterAsyncJob';

import { map, prop } from 'ramda';

const getId = prop('id');
const getIds = map(getId);
const sortStrings = (a: string, b: string) => a.localeCompare(b);

type CreateRevisionStatusParams<T extends Record<string, unknown>> = {
  onSuccess: (data: T) => any;
}

export const useCreateRevisionStatus = <T extends Record<string, unknown>>(): UnaryFn<CreateRevisionStatusParams<T>, void>  => {
  const registerAsyncJob = useRegisterAsyncJob();
  const currentRevisionsIds = [...getIds(useRevisions()?.revisions ?? [])].sort(sortStrings);
  
  const register = (params: CreateRevisionStatusParams<T>): void => {
    const { onSuccess } = params;

    registerAsyncJob({
      onSuccess,
      query: GetBudgetsDocument,
      retryInterval: 1_000,
      testSuccess: (data: GetBudgetsQuery) => {
        return !arraysAreEqual(
          [...getIds(data.a_budgets[0].revisions ?? [])].sort(sortStrings), 
          currentRevisionsIds
        );
      },
      variables: {},
    });
  }
  return register;
}
