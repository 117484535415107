/* eslint-disable sort-keys, sort-keys-fix/sort-keys-fix */

import { timer } from '@cobbler-io/utils/src/timer';

import { Graph } from '@cobbler-io/collection/src/Graph';

import { HydrateBudgetGraphQuery, UserType } from '../graphql-types';
import { createTimeVertices } from './createTimeVertices';
import { hydrateAccounts } from './hydrateAccounts';
import { hydrateBudgetLines } from './hydrateBudgetLines';
import { hydrateDepartments } from './hydrateDepartments';
import { hydratePlannedSpend } from './hydratePlannedSpend';
import { hydratePlanningVersions } from './hydratePlanningVersions';
import { hydrateProposedChanges } from './hydrateProposedChanges';
import { hydrateSpent } from './hydrateSpent';
import { hydrateUsers } from './hydrateUsers';
import { hydrateVendors } from './hydrateVendors';
import { upsertUserVertex } from './upsertUserVertex';

type HydrateBudgetGraphParams = {
  currentUser: UserType;
  end: ISO8601String;
  graph: Graph<any, any>;
  start: ISO8601String;
  /**
   * The 0 indexed start month of the fiscal year
   */
  startMonth: JSMonth;

  data: HydrateBudgetGraphQuery;
};

export const hydrateBudgetGraph = (params: HydrateBudgetGraphParams): void => {
  // TODO: Add Budget and Revision data to hydrate the Graph with it.
  const { currentUser, data, end, graph, start, startMonth } = params;

  // Steps
  // 1
  // Create the time vertices
  const timeTimeVertices = timer('1. Hydrating Time Vertices');
  createTimeVertices({ graph, start, end, startMonth });
  timeTimeVertices();

  // 2 Create the CurrentUser and all the Users Vertices
  const timeUserVertices = timer('2. User Vertices');
  upsertUserVertex({ graph, user: currentUser, isCurrentUser: true, isDeletedUser: false });

  hydrateUsers({ graph, data: data.users });
  timeUserVertices();

  // 3
  // Create the Accounts, Departments, and Vendors vertices and trees
  const details = timer('3. accounts, departments, vendors');
  if (data.accounts?.items && Array.isArray(data.accounts?.items)) {
    hydrateAccounts({ graph, data: data.accounts.items });
  }

  if (data.departments?.items && Array.isArray(data.departments?.items)) {
    hydrateDepartments({ graph, data: data.departments.items });
  }

  if (data.vendors?.items && Array.isArray(data.vendors?.items)) {
    hydrateVendors({ graph, data: data.vendors.items });
  }

  details();

  // 4
  // Create the BudgetLine vertices
  // Add the CurrentUser permissions edges to the BudgetLines
  // Connect the OwnerVertices with the BudgetLines
  const budgetLines = timer('4. budget lines');
  if (data.budgetLines) {
    hydrateBudgetLines({ data: data.budgetLines, graph });
  }

  budgetLines();

  // 6
  // Create the PlanningVersion vertices and edges between BudgetLines and etc...
  const timePlanningVersions = timer('6. Planning versions');
  if (data.planningVersions) {
    hydratePlanningVersions({ data: data.planningVersions, graph });
  }
  timePlanningVersions();

  // 7
  // Create the PlannedSpend vertices
  const timePlannedSpend = timer('7. Planned spend');
  hydratePlannedSpend({ data: data.plannedSpendAtRevision, graph, startMonth });
  timePlannedSpend();

  // 8
  // Create the ActualAggregate Vertices
  const timeSpent = timer('8. Spend Data');
  hydrateSpent({ data: data.spend, graph, startMonth });
  timeSpent();

  // 9
  // Create any proposed change
  const timeProposedChanges = timer('9. Proposed Changes');
  hydrateProposedChanges({ data: data.proposedChanges, graph });
  timeProposedChanges();
};
