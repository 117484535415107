import * as React from 'react';

import { arraysAreEqual } from '@cobbler-io/utils/src/array/arraysAreEqual';

import { CurrencyCode } from '@cobbler-io/formatters/src/currencies';

import { graphColors } from '@cobbler-io/core-ui/src/utils';

import { useCurrency } from '@cobbler-io/redux/src/modules/currency';
import { useTenantSettings } from '@cobbler-io/redux/src/modules/tenant-settings';

import { useGetTenantSettingsQuery } from '@cobbler-io/app/src/api/graphql-types';
import { Loading } from '@cobbler-io/app/src/components/Loading';

type TenantSettingsGateProps = {
  children: JSX.Element;
};

export const TenantSettingsGate = ({ children }: TenantSettingsGateProps): JSX.Element => {
  const { data, loading, error } = useGetTenantSettingsQuery();
  const [reduxCurrency, setCurrencyCode] = useCurrency();
  const [settings, updateTenantSettings] = useTenantSettings();

  const {
    fiscalYearStartMonth: fysm,
    overplanningThreshold,
    accountingCycleClosed,
    chartColors: tenantChartColors,
    copilotDefaultQuestions,
  } = settings;

  // Protect against server returning bad data
  if (!loading && !data?.settings?.defaultCurrency) {
    // eslint-disable-next-line no-console
    console.warn("Unable to get tenant's default currency from server. Falling back to 'USD'.");
  }

  const currency = data?.settings?.defaultCurrency ?? 'USD';
  const fiscalYearStartMonth = data?.settings?.fiscalYearStartMonth ?? 1;
  const budgetAllocationDrift = data?.settings?.budgetPlanAllocationDrift ?? 0;
  const accountingCycle = data?.settings?.lastCloseDate ?? null;
  const chartColors = data?.settings?.chartColors?.length ? data.settings.chartColors : graphColors;
  const copilotQuestions = data?.settings?.copilotDefaultQuestions ?? copilotDefaultQuestions;

  React.useEffect(() => {
    if (
      fysm !== fiscalYearStartMonth ||
      overplanningThreshold !== budgetAllocationDrift ||
      accountingCycleClosed !== accountingCycle ||
      tenantChartColors !== chartColors ||
      !arraysAreEqual(copilotQuestions, copilotDefaultQuestions)
    ) {
      updateTenantSettings({
        accountingCycleClosed: accountingCycle,
        chartColors,
        copilotDefaultQuestions: copilotQuestions,
        fiscalYearStartMonth,
        id: null,
        overplanningThreshold: budgetAllocationDrift as MinorCurrency,
      });
    }
    // dispatch is pure and doesn't change, so it doesn't need to be in the deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fysm,
    fiscalYearStartMonth,
    budgetAllocationDrift,
    overplanningThreshold,
    copilotDefaultQuestions,
    accountingCycle,
    chartColors,
    tenantChartColors,
  ]);

  React.useEffect(() => {
    if (currency && currency !== reduxCurrency) {
      setCurrencyCode(currency as CurrencyCode);
    }
    // dispatch is pure and doesn't change, so it doesn't need to be in the deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currency, reduxCurrency]);

  if (error) {
    console.error(error);
    if (!currency) {
      return <div>Error getting currency and locale information</div>;
    }
  }

  if (loading) {
    return <Loading />;
  }

  return children;
};

TenantSettingsGate.displayName = 'TenantSettingsGate';
