/* eslint-disable camelcase */
import * as React from 'react';

import { isObject } from 'lodash';

import { ErrorCallout } from '@cobbler-io/core-ui/src/Callout';
import { MinimalChrome } from '@cobbler-io/core-ui/src/MinimalChrome';

import { navigate } from '@reach/router';

import { AuthContext } from '../AuthContext';

const WAITLIST_URL = 'https://cobbler.io/waitlist';

const ERRORS = {
  /**
   * Occurs when the user exists but is not assigned to the tenant
   *
   * @see https://github.com/cobbler-io/cobbler/issues/760
   */
  access_denied: 'User+is+not+assigned+to+the+client+application',
};

type ErrorResponse = {
  name: 'ErrorResponse';
  error: string;
  error_description: string;
  error_uri: string;
  state: string;
  session_state: string;
};

const isErrorResponse = (x: unknown): x is ErrorResponse => {
  // https://github.com/IdentityModel/oidc-client-js/blob/dev/src/ErrorResponse.js
  if (!x) {
    return false;
  }

  if (!isObject(x)) {
    return false;
  }

  // @ts-expect-error: we've already checked it's an object
  if (x?.name === 'ErrorResponse') {
    return true;
  }

  return false;
};

type SignInProps = {
  // nothing yet
};

export const SignIn: React.FC<SignInProps> = () => {
  const {
    state: { manager },
    actions: { loadUser },
  } = React.useContext(AuthContext);

  const [error, setError] = React.useState<string | null>(null);

  React.useLayoutEffect(() => {
    if (!manager) {
      // eslint-disable-next-line no-console
      console.error('[oidc]', 'No user manager found. Returning to login screen.');
      // This should be a hard load, which might solve the problem
      window.location.assign('/login');
      return;
    }

    manager
      .signinRedirectCallback()
      .then(user => {
        if (user) {
          loadUser(user);
          navigate(user?.state?.redirect || '/');
        }
      })
      .catch(err => {
        // eslint-disable-next-line no-console
        console.error('[oidc]', 'Error trying to process login', err);
        // Remove any artifacts that might be lying around
        manager.clearStaleState();

        if (
          isErrorResponse(err) &&
          err.error === 'access_denied' &&
          err.error_description === ERRORS.access_denied
        ) {
          return setError(err.error_description);
        }

        // This should be a hard load, which might solve the problem
        return window.location.assign('/');
      });
  }, [manager]);

  if (error === ERRORS.access_denied) {
    return (
      <MinimalChrome>
        <ErrorCallout>
          <span>
            Whoops! Looks like don&apos;t have an account yet. If your company has a corporate
            account, then try navigating to your company tenant at YOURCOMPANYNAME.cobbler.io. If
            you&apos;re looking to create a brand new account, then feel free to{' '}
            <a href={WAITLIST_URL} rel="noopenner noreferrer" target="_blank">
              sign up
            </a>{' '}
            for our{' '}
            <a href={WAITLIST_URL} rel="noopenner noreferrer" target="_blank">
              waitlist
            </a>
            .
          </span>
        </ErrorCallout>
      </MinimalChrome>
    );
  }

  return <div />;
};
