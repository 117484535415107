import * as React from 'react';

import { Tab, TabRouter } from '@cobbler-io/core-ui/src/TabRouter';

import { useSelectedBudgetRevisionId } from '@cobbler-io/redux/src/modules/current-budget';

import { useGetBudgetLineAncestorsQuery } from '@cobbler-io/app/src/api/graphql-types';
import { NotFound } from '@cobbler-io/app/src/pages/NotFound';
import { BudgetLineDetailsProvider } from '@cobbler-io/app/src/providers';
import { useCurrentBudgetLine } from '@cobbler-io/app/src/providers/CurrentBudgetLineProvider';
import { ndmUrls as urls } from '@cobbler-io/app/src/urls';

import { Redirect, useMatch } from '@reach/router';

import { BudgetLineDetailsDiscussions } from './BudgetLineDetailsDiscussions';
import { BudgetLineDetailsResolvedDiscussions } from './BudgetLineDetailsResolvedDiscussions';

export type BudgetLineDetailsProps = {
  budgetLineId: BudgetLineId;
  budgetLineName: string;
};

export const BudgetLineDetails = ({
  budgetLineId,
  budgetLineName,
}: BudgetLineDetailsProps): JSX.Element => {
  // This is only a wrapper for now, but in the future it might show
  // more info or tabs
  return (
    <BudgetLineDetailsProvider budgetLineId={budgetLineId} budgetLineName={budgetLineName}>
      <TabRouter>
        <Tab
          Component={BudgetLineDetailsDiscussions}
          id="discussions"
          label="Discussion"
          path={urls.budgetUrls.lineDiscussions.path}
        />
        <Tab
          Component={BudgetLineDetailsResolvedDiscussions}
          id="resolved"
          label="Archive"
          path={urls.budgetUrls.lineDiscussionsArchive.path}
        />
      </TabRouter>
    </BudgetLineDetailsProvider>
  );
};

BudgetLineDetails.displayName = 'BudgetLineDetails';

export const RedirectToBudgetLineDetails = (): JSX.Element => {
  const match = useMatch('/lines/:id/*') ?? { id: '', '*': '' };
  const id = match.id;
  const tab = match['*'];

  const budgetLineContext = useCurrentBudgetLine();
  const currentRevisionId = useSelectedBudgetRevisionId();
  const currentBudgetLineId = budgetLineContext?.id;

  const { data, error, loading } = useGetBudgetLineAncestorsQuery({
    skip: !id,
    variables: { budgetLineId: id },
  });

  if (!id) {
    return <NotFound />;
  }

  if (loading && !data) {
    return <>Loading...</>;
  }

  const parentBudgetLineId = data?.a_budgetLine?.ancestors[0]?.id;

  if (error || !parentBudgetLineId) {
    return <>Error getting transaction details.</>;
  }

  // If the target budget line is the same as the one the user was already seeing,
  // then we use the currently selected revision.
  const revisionId =
    currentBudgetLineId === parentBudgetLineId ? currentRevisionId ?? undefined : undefined;

  const redirectParams = { budgetId: parentBudgetLineId, revisionId, lineId: id };

  if (tab === 'archive') {
    return <Redirect noThrow to={urls.budgetUrls.lineDiscussionsArchive(redirectParams)} />;
  }

  return <Redirect noThrow to={urls.budgetUrls.lineDiscussions(redirectParams)} />;
};

RedirectToBudgetLineDetails.displayName = 'RedirectToBudgetLineDetails';
