import memoize from 'lodash/memoize';
/**
 * Creates a reducer that has a single toggle-able boolean prop called `active`
 *
 * This is useful for modals, off-canvas drawers, or just about anything that
 * needs a toggle-able state.
 *
 * The reducer is memoized to be useful with SFCs.
 */
import { Reducer } from 'redux';

const ACTIVE = { active: true };
const INACTIVE = { active: false };

export type ToggleState = {
  active: boolean;
};

const parseBool = (s: string) => {
  if (s.toLowerCase() === 'true') {
    return true;
  }

  if (s.toLowerCase() === 'false') {
    return false;
  }

  return Boolean(s);
};

/**
 * Creates a reducer and actions with a name
 */
export const createToggleReducer = memoize(
  (name: string, initialState = INACTIVE, cacheKey?: string) => {
    const ACTIVATE = `${name}/activate`;
    const DEACTIVATE = `${name}/deactivate`;
    const TOGGLE = `${name}/toggle`;

    const initial = cacheKey
      ? {
          active: parseBool(localStorage.getItem(cacheKey) ?? '') ?? initialState.active,
        }
      : initialState;

    const reducer: Reducer<ToggleState> = (state = initial, action) => {
      switch (action.type) {
        case ACTIVATE:
          cacheKey && localStorage.setItem(cacheKey, 'true');
          return ACTIVE;
        case DEACTIVATE:
          cacheKey && localStorage.setItem(cacheKey, 'false');
          return INACTIVE;
        case TOGGLE:
          cacheKey && localStorage.setItem(cacheKey, (!state.active).toString());
          return { active: !state.active };
        default:
          return state;
      }
    };

    /**
     * Opens the off canvas
     */
    const activate = () => ({ type: ACTIVATE });
    /**
     * Closes the off canvas
     */
    const deactivate = () => ({ type: DEACTIVATE });
    /**
     * Toggles the off canvas
     */
    const toggle = () => ({ type: TOGGLE });

    return { activate, deactivate, reducer, toggle };
  },
);
