/* eslint-disable jsx-a11y/media-has-caption, max-lines-per-function */
import * as React from 'react';

import { cx } from '@cobbler-io/utils/src/cx';

import { usePermission } from '@cobbler-io/hooks/src/usePermission';

import { ErrorCallout } from '@cobbler-io/core-ui/src/Callout';

import { AcceptButton } from './AcceptButton';
import { DisplayStreamError } from './DisplayStreamError';
import { MainButton } from './MainButton';
import { RejectButton } from './RejectButton';
import { SelectDeviceButton } from './SelectDeviceButton';
import { VideoAndPreview } from './VideoAndPreview';
import { WebcamProvider } from './WebcamContext';

import styles from './WebcamPhoto.scss';

export type WebcamPhotoProps = {
  height: number;
  width: number;
  onAcceptPicture: (picture: Blob) => any;
  mimeType?: 'image/png' | 'image/jpeg' | 'image/webp';
  quality?: number;
};

export const WebcamPhoto = (props: WebcamPhotoProps): JSX.Element => {
  const { width = 640, height = 480 } = props;

  const cameraPermission = usePermission('camera');

  if (cameraPermission === 'denied') {
    return (
      <div className={cx(styles.webcamPhoto, styles.error)} style={{ width, height }}>
        <ErrorCallout title="Permissions error">
          The camera could not be accessed due to security constraints.
        </ErrorCallout>
      </div>
    );
  }

  return (
    <WebcamProvider {...props}>
      <div
        className={cx(styles.webcamPhoto, cameraPermission === 'prompt' && styles.permissionNeeded)}
        style={{ width, height }}
      >
        <DisplayStreamError />
        <VideoAndPreview height={height} width={width} />
        <MainButton className={cx(styles.cameraButton, styles.centered)} />
        <SelectDeviceButton className={cx(styles.cameraButton, styles.right)} />
        <div className={styles.centered}>
          <RejectButton className={styles.cameraButton} />
          <AcceptButton className={styles.cameraButton} />
        </div>
      </div>
    </WebcamProvider>
  );
};

WebcamPhoto.displayName = 'WebcamPhoto';

export default WebcamPhoto;
