import * as React from 'react';

import { execIfFunc, noop } from '@cobbler-io/utils/src';
import { cx } from '@cobbler-io/utils/src/cx';
import { mapEvent } from '@cobbler-io/utils/src/event';

import { Button } from '@cobbler-io/core-ui/src/Button';
import { Heading } from '@cobbler-io/core-ui/src/Heading';
import { Modal, ModalProps } from '@cobbler-io/core-ui/src/Modal';
import { useModalImplementation } from '@cobbler-io/core-ui/src/Modal/useModalImplementation';

import styles from './Dialog.scss';

export type DialogProps = ModalProps & {
  /**
   * Function to run when the `confirm` button is pressed
   *
   * Send null to skip rendering the `confirm` button
   */
  onConfirm: null | (() => void);
  /**
   * Function to run when the `cancel` button is pressed
   *
   * Send null to skip rendering the `cancel` button
   */
  onCancel?: null | (() => void);
  /**
   * Text / JSX for the cancel button
   */
  cancel?: React.ReactNode;
  /**
   * Text / JSX for the confirm button
   */
  confirm?: React.ReactNode;
  /**
   * The title for the Dialog (prefer: strings)
   */
  title?: React.ReactNode;

  /**
   * The className for the dialog
   */
  className?: string;

  children: React.ReactNode;
};

export const Dialog = (props: DialogProps): JSX.Element => {
  const {
    title,
    onCancel = noop,
    onConfirm = noop,
    overlay = true,
    children,
    className,
    confirm = 'Confirm',
    cancel = 'Cancel',
    ...rest
  } = props;

  const { modal } = useModalImplementation();
  const buttonRef = React.useRef<HTMLButtonElement>(null);

  return (
    <Modal
      {...rest}
      lock
      className={cx(styles.container, className)}
      initialFocusRef={buttonRef}
      overlay={overlay}
      role="dialog"
    >
      <div className={styles.dialog}>
        {title && (
          <Heading as="h2" className={styles.title} size="headline">
            {title}
          </Heading>
        )}
        <div className={styles.body}>{execIfFunc(children, props)}</div>
        <div>
          <div className="button-container right">
            {onCancel && (
              <Button
                dim
                name="cancel"
                variant="text"
                onClick={mapEvent<React.MouseEvent<HTMLButtonElement>>(onCancel, modal!.close)}
              >
                {cancel}
              </Button>
            )}
            {onConfirm && (
              <Button
                ref={buttonRef}
                name="confirm"
                onClick={mapEvent<React.MouseEvent<HTMLButtonElement>>(onConfirm, modal!.close)}
              >
                {confirm}
              </Button>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

Dialog.displayName = 'Dialog';

export default Dialog;
