import * as React from 'react';

import { APIClient } from '@cobbler-io/utils/src/api-client';

import { useLatest } from '@cobbler-io/hooks/src/useLatest';

import { AuthContext as NewAuthContext } from '@cobbler-io/app/src/oidc/AuthContext';

import { ApolloProvider } from '@apollo/react-hooks';
import { useSelector } from 'react-redux';

import { AuthContext } from './AuthContext';
import { createGQLClient } from './create-gql-client';

type AuthManagerProps = {
  children: JSX.Element;
};

export const AuthManager = (props: AuthManagerProps): JSX.Element => {
  const { children } = props;
  const { user } = useSelector(state => state.auth);
  const ctx = React.useContext(NewAuthContext);
  const userRef = useLatest(user);
  const managerRef = useLatest(ctx.state.manager);

  const getAuthHeader = React.useCallback(() => {
    if (!userRef.current) {
      return { Authorization: '' };
    }

    const { token_type: tokenType, access_token: accessToken } = userRef.current;
    return { Authorization: `${tokenType} ${accessToken}` };
  }, [userRef]);

  const getAuthHeaderString = React.useCallback(() => {
    if (!userRef.current) {
      return '';
    }

    const { token_type: tokenType, access_token: accessToken } = userRef.current;
    return `${tokenType} ${accessToken}`;
  }, [userRef]);

  const gqlClient = React.useMemo(
    () =>
      createGQLClient({
        gqlClient: null,
        setAuthHeaders: getAuthHeader,
        signOut: () => managerRef.current?.signoutRedirect().catch(console.error),
      }),
    [getAuthHeader, managerRef],
  );

  const fetchClient = React.useMemo(() => {
    const client = new APIClient();
    client.registerGetAuthHeader(getAuthHeaderString);
    return client;
  }, [getAuthHeaderString]);

  const providerValue = React.useMemo(
    () => ({
      client: fetchClient,
      manager: managerRef.current,
      profile: userRef.current?.profile ?? {},
    }),
    [fetchClient, userRef, managerRef],
  );

  if (!user) {
    return null;
  }

  return (
    <AuthContext.Provider value={providerValue}>
      <ApolloProvider client={gqlClient}>{children}</ApolloProvider>
    </AuthContext.Provider>
  );
};

AuthManager.displayName = 'AuthManager';
export default AuthManager;
