import * as React from 'react';

import { cx } from '@cobbler-io/utils/src/cx';

import { CobblerMark } from '../CobblerMark';
import { CobblerText } from '../CobblerText';

import styles from './Logo.scss';

export type LogoProps = {
  /**
   * Width in pixels
   *
   * The mark gets 30%, and the text gets 70% of those
   *
   * Default: `100`
   */
  width?: number;
} & React.HTMLProps<HTMLDivElement>

export const Logo = ({ className, width = 100, ...props }: LogoProps): JSX.Element => {
  return (
    <div {...props} className={cx(className, styles.logo)}>
      <CobblerMark style={{ marginRight: `${width / 10}px` }} width={width * 0.3} />{' '}
      <CobblerText width={width * 0.7} />
    </div>
  );
};

Logo.displayName = 'Logo';

export default Logo;
