import { stringify } from 'qs';

export const DEFAULT_REVISION_TAG = 'active';

export type RevisionTarget = BudgetRevisionId | 'latest' | 'original' | 'active';

export const budgetBasePath = '/budgets/:budgetId/:revisionId';
export const lineBasePath = `${budgetBasePath}/lines/:lineId`;
export const actualBasePath = `${budgetBasePath}/actuals/:actualId`;

export const budgetBaseUrl = (budgetId: BudgetLineId, revisionId: RevisionTarget) =>
  `/budgets/${budgetId}/${revisionId}`;

type BuildUrlParams = {
  budgetId: BudgetLineId;
  revisionId?: RevisionTarget;
  subPath?: string;
  queryParams?: Record<string, any>;
};

const buildUrl = ({
  budgetId,
  revisionId = DEFAULT_REVISION_TAG,
  subPath = '',
  queryParams = {},
}: BuildUrlParams): string => {
  const search = `?${stringify(queryParams)}`;
  return `${budgetBaseUrl(budgetId, revisionId)}${subPath}${search.substr(1) && search}`;
};

export type BudgetUrlParams = Pick<BuildUrlParams, 'budgetId' | 'revisionId' | 'queryParams'>;

export type BudgetUrl<T extends BudgetUrlParams> = {
  (params: T): string;
  path: string;
};

export type LineUrlParams = BudgetUrlParams & { lineId: BudgetLineId };
export type ActualUrlParams = BudgetUrlParams & { actualId: ActualId };

export type BudgetUrls = {
  actualDetails: BudgetUrl<ActualUrlParams>;
  actualDiscussions: BudgetUrl<ActualUrlParams>;
  actualDiscussionsArchive: BudgetUrl<ActualUrlParams>;
  actuals: BudgetUrl<BudgetUrlParams>;
  dashboard: BudgetUrl<BudgetUrlParams>;
  editor: BudgetUrl<BudgetUrlParams>;
  lineDetails: BudgetUrl<LineUrlParams>;
  lineDiscussions: BudgetUrl<LineUrlParams>;
  lineDiscussionsArchive: BudgetUrl<LineUrlParams>;
  overview: BudgetUrl<BudgetUrlParams>;
  settings: BudgetUrl<BudgetUrlParams>;
  team: BudgetUrl<BudgetUrlParams>;
};

// Budget
const overviewUrl = (params: BudgetUrlParams) => buildUrl({ ...params, subPath: '' });
overviewUrl.path = `${budgetBasePath}/*`;

const editorUrl = (params: BudgetUrlParams) => buildUrl({ ...params, subPath: '/edit' });
editorUrl.path = `${budgetBasePath}/edit`;

const actualsUrl = (params: BudgetUrlParams) => buildUrl({ ...params, subPath: '/actuals' });
actualsUrl.path = `${budgetBasePath}/actuals/*`;

const settingsUrl = (params: BudgetUrlParams) => buildUrl({ ...params, subPath: '/settings' });
settingsUrl.path = `${budgetBasePath}/settings`;

const teamUrl = (params: BudgetUrlParams) => buildUrl({ ...params, subPath: '/team' });
teamUrl.path = `${budgetBasePath}/team`;

const dashboardUrl = (params: BudgetUrlParams) => buildUrl({ ...params, subPath: '/dashboard' });
dashboardUrl.path = `${budgetBasePath}/dashboard`;

// Lines (sub-budgets)
const lineDetailsUrl = (params: LineUrlParams) =>
  buildUrl({ ...params, subPath: `/lines/${params.lineId}/details` });
lineDetailsUrl.path = `${lineBasePath}/details`;

const lineDiscussionsUrl = (params: LineUrlParams) =>
  buildUrl({ ...params, subPath: `/lines/${params.lineId}/discussions` });
lineDiscussionsUrl.path = `${lineBasePath}/discussions`;

const lineDiscussionsArchiveUrl = (params: LineUrlParams) =>
  buildUrl({ ...params, subPath: `/lines/${params.lineId}/archive` });
lineDiscussionsArchiveUrl.path = `${lineBasePath}/archive`;

// Actuals
const actualDetailsUrl = (params: ActualUrlParams) =>
  buildUrl({ ...params, subPath: `/actuals/${params.actualId}/details` });
actualDetailsUrl.path = `${actualBasePath}/details`;

const actualDiscussionsUrl = (params: ActualUrlParams) =>
  buildUrl({ ...params, subPath: `/actuals/${params.actualId}/discussions` });
actualDiscussionsUrl.path = `${actualBasePath}/discussions`;

const actualDiscussionsArchiveUrl = (params: ActualUrlParams) =>
  buildUrl({ ...params, subPath: `/actuals/${params.actualId}/archive` });
actualDiscussionsArchiveUrl.path = `${actualBasePath}/archive`;

export const budgetUrls: BudgetUrls = {
  actualDetails: actualDetailsUrl,
  actualDiscussions: actualDiscussionsUrl,
  actualDiscussionsArchive: actualDiscussionsArchiveUrl,
  actuals: actualsUrl,
  dashboard: dashboardUrl,
  editor: editorUrl,
  lineDetails: lineDetailsUrl,
  lineDiscussions: lineDiscussionsUrl,
  lineDiscussionsArchive: lineDiscussionsArchiveUrl,
  overview: overviewUrl,
  settings: settingsUrl,
  team: teamUrl,
};
