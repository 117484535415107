import * as React from 'react';

import { cx } from '@cobbler-io/utils/src/cx';
import { identity } from '@cobbler-io/utils/src/identity';
import { isGuid } from '@cobbler-io/utils/src/isGuid';

import { ComboBox, ComboBoxProps, FallbackFunction, Item } from '@cobbler-io/core-ui/src/Dropdown';
import { Icon } from '@cobbler-io/core-ui/src/Icon';
import { Persona } from '@cobbler-io/core-ui/src/Persona';

import { UserType } from '@cobbler-io/app/src/gql-queries';

import { ApolloError } from 'apollo-client';

import styles from './UserDropdown.scss';

export type UserDropdownProps = Omit<
  ComboBoxProps<string>,
  'autoComplete' | 'pageSize' | 'items' | 'addFallback'
> & {
  /* Explicitly pass `null` to avoid the default addFallback function */
  fallbackFunction?: FallbackFunction | null;
  transformOptions?: (items: Item[]) => Item[];
  returnType?: 'id' | 'user';
  users?: UserType[];
};

const addFallback: FallbackFunction<string> = (input, items) => {
  // If it is a GUID, then it's probably nothing to write home about
  if (isGuid(input)) {
    return null;
  }

  if (items.some(item => item.value === input)) {
    return null;
  }

  return { id: input, label: input, value: input };
};

export const UserDropdown = React.forwardRef(
  (props: UserDropdownProps, ref: React.RefObject<typeof ComboBox>) => {
    const { id, fallbackFunction, transformOptions = identity, returnType = 'id', users } = props;

    if (!users) {
      return null;
    }

    const items: Item<typeof returnType extends 'id' ? string : UserType>[] = transformOptions(
      users.map(user => ({
        id: user.id,
        value: returnType === 'id' ? user.id : user,
        search: cx(user.fullName, user.email),
        label: <Persona key={user.id} user={user} />,
      })),
    );

    // Get the default value directly from the items array
    const defaultValue =
      returnType === 'id'
        ? props.defaultValue
        : items.find(item => item.id === props.defaultValue)?.value || props.defaultValue;

    return (
      <ComboBox
        {...props}
        ref={ref}
        clearButton
        addFallback={fallbackFunction === null ? null : fallbackFunction || addFallback}
        defaultValue={defaultValue}
        id={id || 'userDropdown'}
        items={items}
        multiple={false}
      />
    );
  },
);

UserDropdown.displayName = 'UserDropdown';

type UserDropdownErrorProps = {
  error?: ApolloError;
};

export const UserDropdownError = ({ error }: UserDropdownErrorProps) => {
  // eslint-disable-next-line no-console
  error && console.error('UserDropdown error', error);
  return (
    <span className={styles.error}>
      <Icon color="currentColor" size={18} type="info" />
      Could not fetch users
    </span>
  );
};

UserDropdownError.displayName = 'UserDropdownError';
