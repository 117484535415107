import React, { RefObject } from 'react';

import { head } from '@cobbler-io/utils/src/array';

import { focusElement } from '@cobbler-io/dom/src/focusElement';
import { getActiveElement } from '@cobbler-io/dom/src/getActiveElement';

import { useForwardedRef } from '@cobbler-io/hooks/src/useForwardedRef';
import { useIsMounted } from '@cobbler-io/hooks/src/useIsMounted';

import { getFocusableNodes } from './getFocusableNodes';
import { isFocusLost } from './isFocusLost';
import { maybeFocusRef } from './maybeFocusRef';
import { useFocusOut } from './useFocusOut';

type HTMLTags = keyof React.ReactHTML;

type FocusZoneBaseProps<T extends HTMLElement = HTMLElement> = {
  /**
   * A type of HTML tag to render the FocusZone as (defaults to `div`)
   */
  as?: HTMLTags;
  children: React.ReactNode;
  /**
   * Whether or not to move focus from the last to the first when hitting the end
   *
   * @todo Implement this
   */
  cycle?: boolean;
  /**
   * Whether or not to disable the focus zone until it receives focus
   */
  defer?: boolean;
  /**
   * Whether to disable the focus zone entirely
   */
  disable?: boolean;
  /**
   * The initial item to be focused. If not passed, then it will find the
   * first focusable item and use that. If no focusable items are found then
   * it will focus on the zone itself
   */
  initial?: RefObject<T>;
  /**
   * A ref or array of refs to consider the focus zone. Passing this will override other
   * things
   */
  zoneRef?: RefObject<T> | RefObject<RefObject<T>[]>;
};

type FocusZoneStandardProps = {
  bare?: false;
};

type FocusZoneBareProps = {
  bare: true;
};

export type FocusZoneProps = FocusZoneBaseProps & XOR<FocusZoneStandardProps, FocusZoneBareProps>;

type UseFocusZoneProps = {
  ref: React.ForwardedRef<HTMLDivElement>;
  disable: boolean;
  defer: boolean;
  initial?: RefObject<any>;
};

const useFocusZone = (params: UseFocusZoneProps) => {
  // const { ref: forwardedRef, disable = false, defer = false, initial } = params;
  const { ref: forwardedRef, disable = false, defer = false, initial } = params;

  const ref = useForwardedRef(forwardedRef as RefObject<HTMLDivElement>);
  const previous = React.useRef<HTMLElement | null>(null);
  const isMounted = useIsMounted();

  const actions = React.useMemo(() => {
    const focusFirst = (): void => {
      const node = head(getFocusableNodes(ref));
      node && queueMicrotask(() => focusElement(node));
    };

    const onFocusOut: HandleFocusOut = ({ lastFocusedElement }) => {
      // If we don't have a last element focused, then just take the element
      if (!lastFocusedElement) {
        focusFirst();
        return;
      }

      if (!isFocusLost(ref, lastFocusedElement as HTMLElement)) {
        // We lost focus, but the last element that was focused is still
        // in the container, so we will refocus that one
        focusElement(lastFocusedElement as HTMLElement);
        return;
      }

      // We cannot restore focus, so just focus the first one
      focusFirst();
    };

    return { focusFirst, onFocusOut };
  }, [ref]);

  React.useEffect(() => {
    previous.current ??= getActiveElement() as HTMLElement;

    if (defer || disable) {
      return;
    }

    if (ref.current && !ref.current.contains(previous.current)) {
      if (initial) {
        maybeFocusRef(initial);
      } else {
        actions.focusFirst();
      }
    }

    // eslint-disable-next-line consistent-return
    return () =>
      queueMicrotask(() => {
        maybeFocusRef(previous);
      });
  }, [actions, defer, disable, initial, isMounted, ref]);

  return {
    ref,
    onFocusOut: actions.onFocusOut,
  };
};

type HandleFocusOutParams = {
  lastFocusedElement: EventTarget | null;
};
type HandleFocusOut = (params: HandleFocusOutParams) => void;

export const FocusZone = React.forwardRef(
  (props: FocusZoneProps, forwardedRef: React.ForwardedRef<HTMLDivElement>) => {
    const { children, as = 'div', bare = false, disable = false, defer = false } = props;

    const { ref, onFocusOut } = useFocusZone({
      ...props,
      disable,
      defer,
      ref: forwardedRef,
    });

    useFocusOut(ref, onFocusOut, disable || defer);

    if (bare) {
      return <>{children}</>;
    }

    return React.createElement(as, { ref, 'data-focus-zone': true }, children);
  },
);

FocusZone.displayName = 'FocusZone';
