import * as React from 'react';

import {
  areDatesEqual, getDate, isLastFiscalYear, isLastMonth, isLastQuarter, isNextFiscalYear,
  isThisFiscalYear, isThisMonth, isThisQuarter,
} from '@cobbler-io/utils/src/fiscal-year-dates';

import { BudgetResolution, createHasResolution } from '@cobbler-io/app/src/api';

import { getDateRange } from './getDateRange';

const hasLastMonth = (min: Date, max: Date) => {
  const d = new Date();
  d.setMonth(d.getMonth() - 1);

  return min < d && d < max;
};

const inBudgetRange = (min: Date, max: Date) => {
  const d = new Date();

  return min < d && d < max;
};

const hasLastQuarter = (min: Date, max: Date) => {
  const d = new Date();
  // We're just going to assume a max quarter is 93 days
  d.setDate(d.getDate() - 93);

  return min < d && d < max;
};

type Params = {
  maxResolution: BudgetResolution;
  min: Date;
  max: Date;
  fysm: ServerMonth;
};
export const useNamedDateRange = (options: Params) => {
  const { maxResolution, min, max, fysm } = options;
  const resolutionFilter = React.useMemo(() => createHasResolution(maxResolution), [maxResolution]);

  const showCurrent = inBudgetRange(min, max);
  const showNext = inBudgetRange(min, max); // currently the same as above, but we might want to split the logic

  // This filters out things that are not in the resolution as well as things that are just way too old to matter
  const dateRangeNames = React.useMemo(
    () =>
      [
        resolutionFilter('MONTH') && showCurrent && 'this month',
        resolutionFilter('MONTH') && hasLastMonth(min, max) && 'last month',
        resolutionFilter('QUARTER') && showCurrent && 'this quarter',
        resolutionFilter('QUARTER') && hasLastQuarter(min, max) && 'last quarter',
        resolutionFilter('YEAR') && showCurrent && 'this year',
        resolutionFilter('YEAR') && showNext && 'next year',
        'all time',
        'custom',
      ].filter(Boolean) as string[],
    [resolutionFilter, min, max, showCurrent, showNext],
  );

  const getRangeFromName = React.useCallback(
    (name: string) => getDateRange({ name, min, max, fysm }),
    [min, max, fysm],
  );

  const getNameFromRange = (
    range: [start: string | null, end: string | null],
    format: (d: Date) => string,
  ) => {
    const [s, e] = range;

    if (isLastMonth(s, e)) {
      return 'last month';
    }

    if (isThisMonth(s, e)) {
      return 'this month';
    }

    if (isLastQuarter(fysm)(s, e)) {
      return 'last quarter';
    }

    if (isThisQuarter(fysm)(s, e)) {
      return 'this quarter';
    }

    if (isLastFiscalYear(fysm)(s, e)) {
      return 'last year';
    }

    if (isThisFiscalYear(fysm)(s, e)) {
      return 'this year';
    }

    if (isNextFiscalYear(fysm)(s, e)) {
      return 'next year';
    }

    const start = getDate(s);
    const end = getDate(e);

    if (start && end) {
      if (areDatesEqual(min, start) && areDatesEqual(max, end)) {
        return 'all time';
      }

      // Custom
      return [format(start), format(end)].join('-');
    }

    return 'unknown';
  };

  return {
    dateRangeNames,
    resolutionFilter,
    getRangeFromName,
    getNameFromRange,
  };
};
