// Need to mutate defaultProps and isColumn
/* eslint-disable functional/immutable-data */
import { isReactElementOfType } from '@cobbler-io/utils/src/isReactElementOfType';

import {
  Accessor, AggregatorFn, CellProps, ColumnInstance, DefaultFilterTypes, FilterProps, FilterType,
  HeaderProps, Renderer, Row,
} from 'react-table';

import { CustomFilterTypes } from '../filterTypes';
import { aggregations } from './aggregations';

// @see childrenToColumns.ts to find out how this `Column` component gets
// mapped and passed to `useTable()`.

export const Column = <
  T extends Record<string, unknown>,
  Extra extends Record<string, unknown> = Record<string, unknown>,
>(
  _: Extra & React.PropsWithChildren<ColumnProps<T>>,
): JSX.Element => {
  // @ts-expect-error: This is fine for react.
  return null;
};

export const StringColumnTypes = ['String'] as const;

export type ColumnStringTypes = typeof StringColumnTypes[number];

/**
 * Different numeric formats to handle
 *
 * On these, we need to also support "rounding"
 */
export const NumericColumnTypes = [
  'Number', // Just a plain number
  'Accounting', // $ sign is left aligned, numbers are right aligned
  'Currency', // $XXX,XXX.XX (rounded to normal digits)
  'CurrencyRounded', // $XXX,XXX
  'Financial', // Currency without `$`; Negative numbers are in `()'s`.
  'FinancialRounded', // Same as financial, but without cents
  'Percent', // Regular Percent
] as const;
export type ColumnNumberTypes = typeof NumericColumnTypes[number];

export const isNumericColumn = (
  props: ColumnProps<any> | (ColumnInstance<any> & { type?: ColumnTypes }),
): boolean => {
  const { type } = props;

  // @ts-expect-error: this is a runtime check
  return NumericColumnTypes.includes(type);
};

export const DateTimeColumnTypes = ['Date', 'DateTime', 'Time', 'Duration'] as const;
/**
 * Different types of Date/Time to handle
 */
export type ColumnDateTypes = typeof DateTimeColumnTypes[number];

export type ColumnTypes = ColumnStringTypes | ColumnNumberTypes | ColumnDateTypes;

// export isNumericColumn = (type)

type Updater<D extends UnknownObject = UnknownObject, V extends any = any, R extends any = any> = (
  props: CellProps<D>,
  newValue: V,
) => Promise<R>;

export type ColumnProps<D extends UnknownObject = UnknownObject, V extends any = any> = {
  /**
   * String that represents the header, or a render method for the column's Header
   */
  Header?: string | Renderer<HeaderProps<D>>;

  /**
   * A string fallback for a Header when the Header is rich and we need a string
   */
  label?: string;

  /**
   * Custom render method for how the column's cells will be rendered
   */
  Cell?: Renderer<CellProps<D, V>>;

  /**
   * Renderer for a column
   *
   * Defaults to a `NullRenderer`
   */
  Footer?: Renderer<CellProps<D, V>>;

  /**
   * Render method for how the cell will be rendered if the DataGrid is grouped
   */
  Aggregated?: Renderer<CellProps<D, V>>;

  /**
   * Not used internally, but the current pattern for defining an Editor
   */
  Editor?: Renderer<CellProps<D, V>>;

  /**
   * Used internally to determine if a field is editable
   */
  editable?: boolean;

  /**
   * Whether or not a column is exportable
   */
  exportable?: boolean;

  Export?: (cellValue: V, original: D, row: Row<D>) => string | number | null;

  required?: boolean;

  /**
   * Generally, a mutation function that receives the CellProps and a "new value"
   */
  updater?: Updater<D, V>;

  /**
   * Defines a widget to filter an a column by
   */

  Filter?: Renderer<FilterProps<D>>;
  disableFilters?: boolean;
  defaultCanFilter?: boolean;

  filter?: FilterType<D> | DefaultFilterTypes | CustomFilterTypes | string;
  filterKey?: number;

  filterable?: boolean;

  pinnable?: boolean;
  defaultPinned?: boolean;

  /**
   * Gets re-keyed to `columns`
   */
  children?: React.ReactNode;

  /**
   * An ID for the column
   *
   * This will be generated if not supplied, but if you need a stable handler, then do provide
   * one that is unique within the grid.
   */
  id?: string;

  /**
   * How to access the data to be displayed in the column.
   *
   * If a string, then a string version of JS object syntax will work `first.name` reads
   * `record.first.name` from `records[]`. If it's an accessor function, then it should be memoized.
   */
  accessor?: string | Accessor<D>;

  /**
   * *Internal* (but included here for internal mapping). Use `children` instead.
   */
  columns?: ColumnProps<D>[];

  /**
   * @deprecated Grouping is no longer supported in favor of nesting
   * Whether or not the column can be grouped
   *
   * Default: `false`
   */
  groupable?: boolean;

  /**
   * Whether or not the column can be sorted
   *
   * Default: `true`
   */
  sortable?: boolean;

  sortType?:
    | 'basic'
    | 'datetime'
    | 'alphanumeric'
    | ((rowA: Row<D>, rowB: Row<D>, columnId: string, desc: boolean) => number);

  /**
   * Whether or not the column can be resized
   *
   * Default: `true`
   */
  resizeable?: boolean;

  /**
   * Whether or not the column can be hidden
   *
   * Default: `true`
   */
  hideable?: boolean;

  defaultHidden?: boolean;

  /**
   * If set to `true` it hides all the icons that indicate if the column is pinned, filtered, etc.
   */
  hideIndicators?: boolean;

  /**
   * @deprecated Grouping is no longer supported in favor of nesting
   * An aggregation function that will be run when the DataGrid is grouped. The aggregation appears
   * as the value of the cell on the pivot row
   */
  aggregate?: keyof typeof aggregations | AggregatorFn<D>;

  /**
   * @deprecated Grouping is no longer supported in favor of nesting
   * A different way to aggregate the value. E.g. if you want the average, it could be
   * `${average} value`, which will display that string
   */
  aggregateValue?: string;

  /**
   * A higher level "type" of column. Providing this will have a few effects. For instance,
   * a `Currency` type will be rendered as `$XXX,XXX.XX` and aligned to the right.
   *
   */
  type?: ColumnTypes;

  /**
   * Add a static className to the header, cell, and footer in this column
   */
  className?: string;

  /**
   * Add a static className only to the header
   */
  headerClassName?: string;

  /**
   * Add a static className only to the header
   */
  footerClassName?: string;

  /**
   * Add a className with some functions
   *
   * Merged with `className` if both are provided
   */
  classNameFn?: (props: CellProps<D>) => string;

  /**
   * @deprecated Grouping is no longer supported in favor of nesting
   * Indicates that a column should always be displayed before a grouped column
   *
   * TODO rename this dumb thing
   */
  groupByBoundary?: boolean;

  /**
   * Modified event handler that takes the CellProps as a second argument.
   *
   * This gets hoisted to the TD cell itself
   */
  onKeyDown?: (event: React.KeyboardEvent<HTMLTableCellElement>, props: CellProps<D>) => void;

  /**
   * Modified event handler that takes the CellProps as a second argument
   *
   * This gets hoisted to the TD cell itself
   */
  onFocus?: (event: React.FocusEvent<HTMLTableCellElement>, props: CellProps<D>) => void;

  /**
   * Modified event handler that takes the CellProps as a second argument
   *
   * This gets hoisted to the TD cell itself
   */
  onBlur?: (event: React.FocusEvent<HTMLTableCellElement>, props: CellProps<D>) => void;

  /**
   * Modified event handler that takes the CellProps as a second argument
   *
   * This gets hoisted to the TD cell itself
   */
  onClick?: (event: React.MouseEvent<HTMLTableCellElement>, props: CellProps<D>) => void;

  /**
   * Modified event handler that takes the CellProps as a second argument
   *
   * This gets hoisted to the TD cell itself
   */
  onContextMenu?: (event: React.MouseEvent<HTMLTableCellElement>, props: CellProps<D>) => void;

  /**
   * Modified event handler that takes the CellProps as a second argument
   *
   * This gets hoisted to the TD cell itself
   */
  onTouchStart?: (event: React.TouchEvent<HTMLTableCellElement>, props: CellProps<D>) => void;

  /**
   * Minimum width for a column
   */
  minWidth?: number;

  title?: string | ((props: D) => string);

  focusedCells?: Record<string, string[]>;

  tableLabel?: string | React.ReactElement;

  summaryLabel?: string | React.ReactElement;

  summaryData?: D[];

  summaryAccessor?: (record: D) => any;
};

Column.displayName = 'DataGrid__Column';

Column.defaultProps = {
  defaultPinned: false,
  filter: 'text',
  filterKey: 0,
  filterable: false,
  groupable: false,
  hideIndicators: false,
  hideable: true,
  pinnable: false,
  resizeable: true,
  sortable: true,
  type: 'String',
};

export type ColumnElement<P> = {
  type: (props: P) => React.ReactElement<P> | null;
  props: P;
  key: React.Key | null;
};

// @ts-expect-error: the type is correct, but the React types mangle it a bit
export const isColumn = isReactElementOfType<ColumnElement<any>>(Column);

Column.isColumn = isColumn;
