import * as React from 'react';

import { DepartmentVertex } from '@cobbler-io/app/src/api/graph';

import { TreeNode } from '@cobbler-io/collection/src/Tree';

import styles from './DepartmentLabel.scss';

type DepartmentLabelProps = {
  base: TreeNode<DepartmentVertex>;
  node: TreeNode<DepartmentVertex>;
};

export const DepartmentLabel = (props: DepartmentLabelProps): JSX.Element => {
  const { base, node } = props;
  const spans = node.getAncestors(base).map(x => (
    <span key={x.id} className={styles.branch}>
      {x.value.properties.name}
    </span>
  ));

  return (
    <div className={styles.container}>
      <div className={styles.value}>{node.value.properties.name}</div>
      <div className={styles.path}>
        {spans}
        <span className={styles.leaf}>{node.value.properties.name}</span>
      </div>
    </div>
  );
};

DepartmentLabel.displayName = 'DepartmentLabel';
