import * as React from 'react';

export const CobblerMark = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg {...props} version="1.0" viewBox="0 0 1752 1772" xmlns="http://www.w3.org/2000/svg">
      <title>Cobbler Logo</title>
      {/* eslint-disable react/jsx-curly-brace-presence */}
      <desc>{"Cobbler Inc's logo"}</desc>
      {/* eslint-enable react/jsx-curly-brace-presence */}
      <path
        d="M838.5.6c-60 4.4-98.2 9.6-141 19-35.1 7.7-73.5 18.5-84.8 23.7-27.3 12.6-44.8 36.2-50.3 68-2.6 15.1-.4 32.4 6.1 47.1 3.4 7.7 319.4 555.2 325.3 563.6 18.5 26.4 40.2 45 68.4 58.5 19.3 9.3 38 14.4 60.3 16.5 15.6 1.4 642.4 1.4 653.2-.1 10.4-1.4 24.2-6.1 32.5-11 20.4-12 36.2-33.1 42-56 1.7-7 1.9-10.3 1.6-23.3-.5-14.2-.8-16.3-5.7-35.6-25.3-98.7-64.7-188.4-119.6-272-35.9-54.7-72.2-98.8-120.1-146-68-66.9-141.7-119.1-229.4-162.4-89.4-44.2-183.1-72.2-283-84.5C957.9 1.6 943.1.8 892.5.5c-26.9-.1-51.2-.1-54 .1z"
        fill="rgb(235, 186, 80)"
      />
      <path
        d="M326 213.4c-19.9 4.3-30 10.3-52.3 31.2-33.5 31.5-68.7 71.5-99.2 112.4C84 478.6 25.4 624.2 6.6 774 1.2 816.8.5 828.9.5 885c0 53.6.4 63.3 4.6 100.5 19.8 178.7 94.6 347.4 215.5 486.3 16.2 18.6 61.8 64.4 70.4 70.7 23.8 17.3 52.6 21.7 81.3 12.5 16.9-5.4 33.2-17.3 43.2-31.5 6.8-9.7 319.3-551.9 325.1-564 5.9-12.6 10.3-25.7 13.6-40.6 3-14.1 3.3-50.5.5-63.9-4.4-20.8-11.4-39.9-20.4-55.4C659.9 669.8 417.7 251 414.9 247.3c-8.3-11.2-22.2-22.5-33.5-27.3-11.9-5.2-21.4-7.2-35.4-7.6-9-.2-15.6.1-20 1z"
        fill="rgb(92, 178, 155)"
      />
      <path
        d="M1014 975.5c-49.3 6.7-92.1 33-119.9 73.5-8.1 11.8-322.7 557-326.1 565-4.9 11.8-6.5 20.3-6.4 34 .1 14.6 2.3 24.7 8.4 37.6 5.3 11.4 10.5 18.8 19.3 27 13 12.4 23.1 17.3 53.9 26 57.7 16.3 116.1 26.5 180.8 31.6 25.4 2 104.1 1.7 130-.5 106.9-9.1 200-32.7 293.5-74.4 164.5-73.4 303.1-195.6 396.3-349.6 46.7-76.9 80.2-157.4 102.8-246.7 4.3-16.9 4.7-19.8 5.2-33.3.5-16.8-.4-23.1-5-35.1-6.4-16.5-22.4-35.5-37.7-44.6-8.2-4.9-20.7-9.4-30.1-10.9-10.4-1.7-652.6-1.3-665 .4z"
        fill="rgb(201, 56, 116)"
      />
    </svg>
  );
};

export default CobblerMark;
