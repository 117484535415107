import { ISO8601 } from '@cobbler-io/formatters/src/dates';

import { addMonth, getEndOfDay } from '@cobbler-io/dates/src';
import { DateRange } from '@cobbler-io/dates/src/DateRange';
import { getEndOfMonth } from '@cobbler-io/dates/src/getEndOfMonth';
import { getFiscalYear } from '@cobbler-io/dates/src/getFiscalYear';
import { getFiscalYearDates } from '@cobbler-io/dates/src/getFiscalYearDates';
import { getHalfDates } from '@cobbler-io/dates/src/getHalfDates';
import { getMonthDates } from '@cobbler-io/dates/src/getMonthDates';
import { getQuarterDates } from '@cobbler-io/dates/src/getQuarterDates';
import { tzAdjust } from '@cobbler-io/dates/src/tzAdjust';

const findEndOfQuarter = (quarterStart: Date) => {
  const date = new Date(quarterStart);
  const month = date.getMonth();
  date.setMonth(month + 3, 0);
  date.setHours(23, 59, 59, 999);

  return date;
};

const fiscalQuarter = (date: Date, fysm = 0) => {
  return Math.floor(Math.abs(((date.getMonth() - fysm + 12) % 12) / 3)) + 1;
};

export type DateRangeWithName = {
  name: string;
  start: Date;
  end: Date;
  range: DateRange;
};

export const convertAllTime = (min: string, max: string): DateRangeWithName => {
  const start = new Date(min);
  const end = new Date(max);
  end.setDate(end.getDate() + 1);
  end.setMilliseconds(-1);

  return {
    name: 'All time',
    start,
    end,
    range: new DateRange(start, end),
  };
};

type Half = {
  name: string;
  half: number;
  start: Date;
  end: Date;
};
export const getHalf = (date: Date, fysm = 0): Half => {
  const half = getHalfDates(date, fysm);
  const year = getFiscalYear(date, fysm);
  return { ...half, name: `H${half.half} ${year}` };
};

type Quarter = {
  name: string;
  start: Date;
  end: Date;
};
export const getQuarter = (date: Date, fysm = 0): Quarter => {
  const q = fiscalQuarter(date, fysm);
  const year = date.getFullYear() - (date.getMonth() < fysm ? 1 : 0);
  const start = new Date(year, (q - 1) * 3 + fysm, 1);

  return {
    name: `Q${q} ${year}`,
    start,
    end: findEndOfQuarter(start),
  };
};

export const getCurrentQuarter = (fysm: number = 0): DateRangeWithName => {
  const now = new Date();
  const fy = getFiscalYear(now, fysm);
  const { start, end, quarter } = getQuarterDates(now, fysm);

  return {
    name: `Q${quarter} ${fy}`,
    start,
    end,
    range: new DateRange(start, end),
  };
};

export const getLastQuarter = (fysm: number = 0): DateRangeWithName => {
  const current = getCurrentQuarter(fysm);
  const reference = current.start;
  reference.setMonth(current.start.getMonth() - 3);
  const last = getQuarterDates(reference, fysm);
  const fy = getFiscalYear(last.start, fysm);

  const { start, end, quarter } = last;

  return {
    name: `Q${quarter} ${fy}`,
    start,
    end,
    range: new DateRange(start, end),
  };
};

export const getCurrentFiscalYear = (fysm: number = 0): DateRangeWithName => {
  const { start, end, name } = getFiscalYearDates(new Date(), fysm);
  const range = new DateRange(start, end);

  return { name, start, end, range };
};

export const getNextFiscalYear = (fysm: number = 0): DateRangeWithName => {
  const date = new Date();
  date.setFullYear(date.getFullYear() + 1);
  const { start, end, name } = getFiscalYearDates(date, fysm);
  const range = new DateRange(start, end);

  return { name, start, end, range };
};

export const getLastFiscalYear = (fysm: number = 0): DateRangeWithName => {
  const date = new Date();
  date.setFullYear(date.getFullYear() - 1);
  const { start, end, name } = getFiscalYearDates(date, fysm);
  const range = new DateRange(start, end);

  return { name, start, end, range };
};

export type DatePeriodWithName = {
  name: string;
  start: Date;
  end: Date;
};

export const getMonthsInPeriod = (period: {
  start: LocalDate;
  end: LocalDate;
  formatter: (date: LocalDate) => string;
}): DatePeriodWithName[] => {
  const { formatter } = period;
  const months: DatePeriodWithName[] = [];
  // eslint-disable-next-line functional/no-let, no-restricted-syntax
  let d = new Date(period.start);

  while (d < period.end) {
    const name = formatter(d);
    const start = new Date(d);
    const end = getEndOfDay(getEndOfMonth(d));
    // eslint-disable-next-line functional/immutable-data
    months.push({ end, name, start });
    d = addMonth(d);
  }

  return months;
};

// @TODO write these functions to fix the SpendOverTime card to take
//       periods other than months

// export const getQuartersInPeriod = (
//   period: { start: Date; end: Date },
//   fysm: number = 0,
// ): DatePeriodWithName[] => {
//   const quarters: DatePeriodWithName[] = [];
// };

// export const getHalvesInPeriod = (
//   period: { start: Date; end: Date },
//   fysm: number = 0,
// ): DatePeriodWithName[] => {
//   const halves: DatePeriodWithName[] = [];
// };

// export const getFiscalYearsInPeriod = (
//   period: {
//     start: Date;
//     end: Date;
//   },
//   fysm: number = 0,
// ): DatePeriodWithName[] => {
//   const fiscalYears: DatePeriodWithName[] = [];
// };

export const thisMonth = (): DateRangeWithName => {
  const tmp = getMonthDates(new Date());
  return {
    ...tmp,
    range: new DateRange(tmp.start, tmp.end),
  };
};

export const getCurrentMonth = thisMonth;

export const lastMonth = (): DateRangeWithName => {
  const d = new Date();
  d.setMonth(d.getMonth() - 1);
  const { start, end, name } = getMonthDates(d);
  return { start, end, range: new DateRange(start, end), name };
};

export const thisQuarter = (fysm: ServerMonth): DateRangeWithName => getCurrentQuarter(fysm - 1);

export const lastQuarter = (fysm: ServerMonth): DateRangeWithName => getLastQuarter(fysm - 1);

export const thisFiscalYear = (fysm: ServerMonth) => getCurrentFiscalYear(fysm - 1);

export const nextFiscalYear = (fysm: ServerMonth) => getNextFiscalYear(fysm - 1);

export const lastFiscalYear = (fysm: ServerMonth) => getLastFiscalYear(fysm - 1);

export const areDatesEqual = (d1: Date, d2: Date): boolean => {
  return (
    d1.getFullYear() === d2.getFullYear() &&
    d1.getMonth() === d2.getMonth() &&
    d1.getDate() === d2.getDate()
  );
};

/**
 * Turns the input into a date if possible.
 *
 * If null, return null
 * If Date, return input
 * If string, then run through `tzAdjust`
 */
export const getDate = (date: string | Date | null): Date | null => {
  if (date === null || date instanceof Date) {
    return date;
  }

  return tzAdjust(date);
};

export const createIsRange =
  (getRange: (fysm?: number) => { start: Date; end: Date }, fysm?: number) =>
  (start: string | Date | null, end: string | Date | null): boolean => {
    const s = getDate(start);
    const e = getDate(end);

    if (s === null || e === null) {
      return false;
    }

    const reference = getRange(fysm);
    return areDatesEqual(s, reference.start) && areDatesEqual(e, reference.end);
  };

export const isThisMonth = createIsRange(thisMonth);

export const isLastMonth = createIsRange(lastMonth);

export const isThisQuarter = (fysm: ServerMonth) => createIsRange(thisQuarter, fysm);

export const isLastQuarter = (fysm: ServerMonth) => createIsRange(lastQuarter, fysm);

export const isThisFiscalYear = (fysm: ServerMonth) => createIsRange(thisFiscalYear, fysm);

export const isNextFiscalYear = (fysm: ServerMonth) => createIsRange(nextFiscalYear, fysm);

export const isLastFiscalYear = (fysm: ServerMonth) => createIsRange(lastFiscalYear, fysm);
