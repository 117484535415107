export const focusableElements = [
  '[contenteditable]',
  'a[href]',
  'area[href]',
  'audio[controls]',
  'button:not([disabled])',
  'embed',
  'iframe',
  'input:not([disabled]):not([type=hidden])',
  'object',
  'select:not([disabled])',
  'summary',
  'textarea:not([disabled])',
  'video[controls]',
];
