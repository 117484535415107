/* eslint-disable functional/no-class, no-restricted-syntax, sort-keys, sort-keys-fix/sort-keys-fix,
functional/immutable-data, functional/no-this-expression */
import qs from 'qs';

import { isFunction } from './index';

type Options = {
  method?: 'POST' | 'GET' | 'PUT' | 'DELETE' | 'PATCH';
  mode?: 'no-cors' | 'cors' | 'same-origin';
  cache?: 'default' | 'no-cache' | 'reload' | 'force-cache' | 'only-if-cached';
  credentials?: 'include' | 'same-origin' | 'omit';
  headers?: { [key: string]: string };
  redirect?: 'manual' | 'follow' | 'error';
  referrer?: 'no-referrer' | 'client';
  data?: { [key: string]: any };
};

type FileArg = FileList | null;

const defaultOptions: Options = {
  method: 'GET',
  mode: 'same-origin',
  cache: 'no-cache',
  credentials: 'same-origin',
  headers: {
    'Content-Type': 'application/json',
  },
  redirect: 'follow',
  referrer: 'no-referrer',
};

const getOptions = (options: Options): Options =>
  Object.keys(defaultOptions).reduce(
    (acc, name) => ({
      ...acc,
      [name]: options[name] || defaultOptions[name],
    }),
    {},
  );

const throwIfNotOkay = async (res: Response) => {
  if (res.ok) {
    return Promise.resolve(res);
  }
  // Log the error
  // eslint-disable-next-line no-console
  console.error(res);
  // Throw the error, well, throw the response actually
  throw res;
};

const json = async (res: Response) => res.json().catch(async _ => Promise.resolve());

export class APIClient {
  token: string;

  tokenType: string;

  authHeader: () => string;

  constructor(token: string = '') {
    this.token = token;
  }

  addAuthHeader(headers: Headers): void {
    if (this.authHeader && isFunction(this.authHeader)) {
      const header = this.authHeader();

      if (header) {
        headers.set('Authorization', header);
      }
    }
  }

  registerGetAuthHeader(fn: () => string): void {
    this.authHeader = fn;
  }

  async doFetch<T extends any = any>(url: string, options: Options, files: FileArg): Promise<T> {
    const opts = getOptions(options);
    const formData = new FormData();

    const headers = new Headers();
    if (opts.headers) {
      Object.keys(opts.headers).forEach(name => {
        if (opts.headers?.[name]) {
          headers.set(name, opts.headers[name]);
        }
      });
    }

    if (files) {
      const { data } = options;

      // For some reason, this makes it work...
      headers.delete('Content-Type');

      if (data) {
        Object.keys(data).forEach(key => {
          formData.set(key, data[key]);
        });
      }

      if (typeof files.length === 'number') {
        for (const file of files) {
          formData.set('file', file, file.name);
        }
      }
    }

    this.addAuthHeader(headers);

    const body = files ? formData : JSON.stringify(options.data);

    return fetch(url, { ...opts, headers, body })
      .then(throwIfNotOkay)
      .then(json);
  }

  async get<T extends any = any>(
    url: string,
    options: Options = {},
    files: FileArg = null,
  ): Promise<T> {
    const { data, ...rest } = options;

    return this.doFetch(data ? [url, qs.stringify(data)].join('?') : url, rest, files);
  }

  async patch(url: string, options: Options = {}, files: FileArg = null): Promise<Response> {
    return this.doFetch(url, { ...options, method: 'PATCH' }, files);
  }

  async post(url: string, options: Options = {}, files: FileArg = null): Promise<Response> {
    return this.doFetch(url, { ...options, method: 'POST' }, files);
  }

  async put(url: string, options: Options = {}, files: FileArg = null): Promise<Response> {
    return this.doFetch(url, { ...options, method: 'PUT' }, files);
  }

  async delete(url: string, options: Options = {}, files: FileArg = null): Promise<Response> {
    return this.doFetch(url, { ...options, method: 'DELETE' }, files);
  }
}

export const Client = new APIClient();
export default Client;
