import * as React from 'react';

import { Link } from '@reach/router';

import { MessageTemplateParameter } from '../ParameterizedMessage';

export const BudgetLineDiscussionToken = ({
  resourceId,
  displayValue,
}: MessageTemplateParameter): JSX.Element =>
  !resourceId ? (
    <>{displayValue}</>
  ) : (
    <Link to={`/lines/${resourceId}/discussions`}>{displayValue}</Link>
  );

BudgetLineDiscussionToken.displayName = 'BudgetLineDiscussionToken';
