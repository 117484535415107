/* eslint-disable */

export interface IntrospectionResultData {
  __schema: {
    types: {
      kind: string;
      name: string;
      possibleTypes: {
        name: string;
      }[];
    }[];
  };
}
const result: IntrospectionResultData = {
  __schema: {
    types: [
      {
        kind: 'UNION',
        name: 'ActorType',
        possibleTypes: [
          {
            name: 'SystemActorType',
          },
          {
            name: 'UserReference',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'AssumptionFilterValue',
        possibleTypes: [
          {
            name: 'Department',
          },
          {
            name: 'EmploymentStatus',
          },
          {
            name: 'EmploymentSubType',
          },
          {
            name: 'EmploymentType',
          },
          {
            name: 'Location',
          },
          {
            name: 'PersonUDFValue',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'CopilotMessage',
        possibleTypes: [
          {
            name: 'CopilotDataMessage',
          },
          {
            name: 'CopilotTextMessage',
          },
          {
            name: 'CopilotUserMessage',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'Footer',
        possibleTypes: [
          {
            name: 'FooterAggregate',
          },
          {
            name: 'FooterValue',
          },
        ],
      },
      {
        kind: 'INTERFACE',
        name: 'HistoryEntry',
        possibleTypes: [
          {
            name: 'ActualConvertedBackFromOriginEventType',
          },
          {
            name: 'ActualConvertedToSplitOriginEventType',
          },
          {
            name: 'ActualCreatedAsSliceEventType',
          },
          {
            name: 'ActualManuallyMatchedToBudgetLineEventType',
          },
          {
            name: 'ActualSplitSliceDeletedEventType',
          },
          {
            name: 'AllocationAddedToBudgetPlanEventType',
          },
          {
            name: 'AllocationChangedInBudgetPlanEventType',
          },
          {
            name: 'AllocationRemovedFromBudgetPlanEventType',
          },
          {
            name: 'BudgetPlanCreatedByUserEventType',
          },
          {
            name: 'ComparisonRevisionIdChangedInBudgetPlanEventType',
          },
          {
            name: 'DeletedBudgetPlanEventType',
          },
          {
            name: 'HistoricalEventType',
          },
          {
            name: 'NameChangedInBudgetPlanEventType',
          },
          {
            name: 'OwnerChangedToUserInBudgetPlanEventType',
          },
          {
            name: 'RevisionAddedToBudgetPlanEventType',
          },
          {
            name: 'RevisionNameChangedInBudgetPlanEventType',
          },
          {
            name: 'RevisionRemovedFromBudgetPlanEventType',
          },
        ],
      },
      {
        kind: 'INTERFACE',
        name: 'Node',
        possibleTypes: [
          {
            name: 'AccountType',
          },
          {
            name: 'Assumption',
          },
          {
            name: 'BudgetLineStub',
          },
          {
            name: 'BudgetRevision',
          },
          {
            name: 'BvaCommentaryRoutine',
          },
          {
            name: 'BvaCommentaryRoutineRun',
          },
          {
            name: 'CommentType',
          },
          {
            name: 'Conversation',
          },
          {
            name: 'CopilotDataMessage',
          },
          {
            name: 'CopilotFeedback',
          },
          {
            name: 'CopilotTextMessage',
          },
          {
            name: 'CopilotUserMessage',
          },
          {
            name: 'DataSet',
          },
          {
            name: 'DataSetColumnDefinition',
          },
          {
            name: 'Department',
          },
          {
            name: 'DepartmentType',
          },
          {
            name: 'DiscussionType',
          },
          {
            name: 'EmploymentStatus',
          },
          {
            name: 'EmploymentSubType',
          },
          {
            name: 'EmploymentType',
          },
          {
            name: 'JobFamilyType',
          },
          {
            name: 'JobLevelType',
          },
          {
            name: 'JobTrackType',
          },
          {
            name: 'LevelingGuideEntry',
          },
          {
            name: 'Location',
          },
          {
            name: 'Person',
          },
          {
            name: 'PersonDepartment',
          },
          {
            name: 'PersonUDF',
          },
          {
            name: 'PersonUDFListItem',
          },
          {
            name: 'PersonUDFType',
          },
          {
            name: 'PersonUDFValue',
          },
          {
            name: 'PlannedSpendStub',
          },
          {
            name: 'PlannedSpendType',
          },
          {
            name: 'PlanningVersionStub',
          },
          {
            name: 'ProposedChangesStub',
          },
          {
            name: 'RecurringTaskType',
          },
          {
            name: 'SpendStub',
          },
          {
            name: 'SuggestedFollowUpQuestion',
          },
          {
            name: 'TaskType',
          },
          {
            name: 'VarianceCommentaryRoutine',
          },
          {
            name: 'VarianceCommentaryRoutineRun',
          },
          {
            name: 'VendorType',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'Routine',
        possibleTypes: [
          {
            name: 'BvaCommentaryRoutine',
          },
          {
            name: 'VarianceCommentaryRoutine',
          },
        ],
      },
      {
        kind: 'INTERFACE',
        name: 'RoutineRunInterface',
        possibleTypes: [
          {
            name: 'BvaCommentaryRoutineRun',
          },
          {
            name: 'VarianceCommentaryRoutineRun',
          },
        ],
      },
      {
        kind: 'INTERFACE',
        name: 'RoutineRunOutputInterface',
        possibleTypes: [
          {
            name: 'BvaCommentaryRoutineRunOutput',
          },
          {
            name: 'VarianceCommentaryRoutineRunOutput',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'Series',
        possibleTypes: [
          {
            name: 'DeclaredSeries',
          },
          {
            name: 'DerivedSeries',
          },
        ],
      },
    ],
  },
};
export default result;
