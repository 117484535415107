export const addItemToSet = <T extends any = any>(prev: Set<T>, item: T) => {
  if (prev.has(item)) {
    return prev;
  }

  const next = new Set(prev);
  next.add(item);
  return next;
};

export const addItemsToSet = <T extends any = any>(prev: Set<T>, items: T[]) =>
  !items.every(i => prev.has(i)) ? new Set(Array.from(prev).concat(items)) : prev;

export const addItemOrItemsToSet = <T extends any = any>(prev: Set<T>, items: T | T[]) =>
  Array.isArray(items) ? addItemsToSet(prev, items) : addItemToSet(prev, items);

export const removeItemFromSet = <T extends any = any>(prev: Set<T>, item: T) => {
  if (!prev.has(item)) {
    return prev;
  }

  const next = new Set(prev);
  next.delete(item);

  return next;
};

export const removeItemsFromSet = <T extends any = any>(prev: Set<T>, items: T[]) =>
  !items.every(i => !prev.has(i))
    ? new Set(Array.from(prev).filter(i => !items.includes(i)))
    : prev;

export const removeItemOrItemsFromSet = <T extends any = any>(prev: Set<T>, items: T | T[]) =>
  Array.isArray(items) ? removeItemsFromSet(prev, items) : removeItemFromSet(prev, items);

export const toggleItemInSet = <T extends any = any>(prev: Set<T>, item: T) => {
  const next = new Set(prev);
  if (next.has(item)) {
    next.delete(item);
  } else {
    next.add(item);
  }

  return next;
};

export const toggleItemsInSet = <T extends any = any>(prev: Set<T>, items: T[]) => {
  const next = new Set(prev);
  return items.reduce((set: Set<T>, item: T) => {
    if (set.has(item)) {
      set.delete(item);
    } else {
      set.add(item);
    }

    return set;
  }, next);
};

export const toggleItemOrItemsInSet = <T extends any = any>(prev: Set<T>, items: T | T[]) =>
  Array.isArray(items) ? toggleItemsInSet(prev, items) : toggleItemInSet(prev, items);
