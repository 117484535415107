import { Edge } from '../Edge';
import { isElement } from '../isElement';
import { Traversal } from '../Traversal';
import { Traverser } from '../Traverser';
import { Vertex } from '../Vertex';
import { StepGenus } from './types';

export const id = (): UnaryFn<Traversal<any, any>> => traversal => {
  const genus: StepGenus = 'map';
  const species = 'id';

  const callback = function* (prev: Iterable<Traverser<any>>) {
    for (const t of prev) {
      const element = t.get() as Vertex<any> | Edge<any, any, any>;

      // This only works on elements (vertices and edges), so we'll skip any non-elements
      if (isElement(element) && 'id' in element) {
        yield t.clone({ value: element.id });
      }
    }
  };

  return traversal.addStep({ args: [], callback, genus, species });
};
