/* eslint-disable react/boolean-prop-naming, max-lines-per-function */
import * as React from 'react';

import { useFeatureFlag } from '@cobbler-io/core-ui/src/FeatureFlag';
import { VerticalLink, VerticalNav } from '@cobbler-io/core-ui/src/VerticalNav';

import {
  useGetCurrentUserPermissionsQuery, useGetTasksAssignedToMeQuery,
} from '@cobbler-io/app/src/api/graphql-types';
import { AuthContext } from '@cobbler-io/app/src/oidc/AuthContext';

import { useQuery } from '@apollo/react-hooks';
import { Location } from '@reach/router';
import gql from 'graphql-tag';

// eslint-disable-next-line @typescript-eslint/no-magic-numbers
const POLLING_INTERVAL_MS = __DEV__ && __DISABLE_POLLING__ ? 100000000 : 1000 * 60 * 60 * 5; // 5 minutes

export const GET_UNREAD_NOTIFICATION_COUNT = gql`
  query GetUnreadNotificationCount {
    unreadNotificationCount
  }
`;

type NavProps = {
  active: boolean;
  toggle: () => void;
};

export const Nav = ({ active, toggle }: NavProps): JSX.Element => {
  /* Get relevant feature flags */
  const SHOW_REPORTS = !useFeatureFlag('HideReports');
  const SHOW_TASKS = !useFeatureFlag('HideTasks');
  const SHOW_HEADCOUNT = useFeatureFlag('headcount');
  const SHOW_ANOMALIES = useFeatureFlag('anomalies');
  const SHOW_DASHBOARDS = useFeatureFlag('dashboards');
  const SHOW_COPILOT = !useFeatureFlag('HideCopilot');
  const SHOW_ROUTINES = useFeatureFlag('routines');

  // We started to change the app that we're selling so that we're providing "Copilot" as the
  // main view of the app and turning off legacy features...
  const IS_MAIN_COPILOT_USER = useFeatureFlag('CopilotView');

  const SHOW_DATASETS = useFeatureFlag('UploadAnyData');

  const {
    state: { manager },
  } = React.useContext(AuthContext);

  const handleLogout = React.useCallback(() => {
    manager?.signoutRedirect().catch(error => {
      console.error('[oidc]', 'Error thrown while logging out', error);
    });
  }, [manager]);

  /* Notifications */
  const HIDE_INBOX = IS_MAIN_COPILOT_USER;
  const SHOW_INBOX = !HIDE_INBOX;

  const { data } = useQuery(GET_UNREAD_NOTIFICATION_COUNT, {
    pollInterval: POLLING_INTERVAL_MS,
    skip: IS_MAIN_COPILOT_USER,
  });

  /* Drivers */
  const { data: userPermissions } = useGetCurrentUserPermissionsQuery();
  const showDrivers = Boolean(userPermissions?.currentUserPermissions?.canReadDrivers);

  /* Tasks */
  const { data: tasksAssignedToMe } = useGetTasksAssignedToMeQuery();
  const showTaskBadge = Boolean(
    tasksAssignedToMe?.tasksAssignedToMe?.items?.find(task =>
      ['PENDING', 'IN_PROGRESS'].includes(task.status),
    ),
  );

  const links: VerticalLink[] = React.useMemo(() => {
    if (IS_MAIN_COPILOT_USER) {
      return [
        SHOW_COPILOT && {
          icon: 'chatBubble' as const,
          id: 'copilot',
          label: 'Copilot',
          uri: '/copilot',
        },
        SHOW_ROUTINES && {
          icon: 'schedule' as const,
          id: 'routines',
          label: 'Routines',
          uri: '/routines',
        },
        SHOW_DATASETS && {
          icon: 'database' as const,
          id: 'data',
          label: 'Data',
          uri: '/datasets',
        },
        {
          icon: 'dollar' as const,
          id: 'budgets-link',
          label: 'Budget',
          uri: '/budgets/',
        },
        SHOW_HEADCOUNT && {
          icon: 'people' as const,
          id: 'headcount-link',
          label: 'Headcount',
          uri: '/headcount/',
        },
        SHOW_REPORTS && {
          icon: 'charts' as const,
          id: 'reports-link',
          label: 'Reports',
          uri: '/reports/',
        },
        SHOW_TASKS && {
          icon: 'checkCircle' as const,
          id: 'task-list',
          label: 'Tasks',
          showBadge: showTaskBadge,
          uri: '/tasks/',
        },
        {
          icon: 'settings' as const,
          id: 'global-settings-link',
          label: 'Settings',
          uri: `/settings/`,
        },
      ].filter(Boolean) as VerticalLink[];
    }

    return [
      {
        icon: 'dollar' as const,
        id: 'budgets-link',
        label: 'Budget',
        uri: '/budgets/',
      },
      SHOW_HEADCOUNT && {
        icon: 'people' as const,
        id: 'headcount-link',
        label: 'Headcount',
        uri: '/headcount/',
      },
      SHOW_ANOMALIES && {
        icon: 'warning' as const,
        id: 'anomalies-link',
        label: 'Anomalies',
        uri: '/anomalies/',
      },
      SHOW_DASHBOARDS && {
        icon: 'dashboard' as const,
        id: 'dashboard-link',
        label: 'Dashboard',
        uri: '/dashboard/',
      },
      showDrivers && {
        icon: 'mediation' as const,
        id: 'drivers-link',
        label: 'Drivers',
        uri: '/drivers/',
      },
      SHOW_REPORTS && {
        icon: 'charts' as const,
        id: 'reports-link',
        label: 'Reports',
        uri: '/reports/',
      },
      SHOW_TASKS && {
        icon: 'checkCircle' as const,
        id: 'task-list',
        label: 'Tasks',
        showBadge: showTaskBadge,
        uri: '/tasks/',
      },
      SHOW_INBOX && {
        icon: 'mail' as const,
        id: 'inbox-link',
        label: 'Inbox',

        // Only showing a badge for now. In the future we might want to show
        // the number of new notifications.
        showBadge: Boolean(data?.unreadNotificationCount),
        uri: '/inbox/',
      },
      {
        icon: 'chatBubble' as const,
        id: 'copilot',
        label: 'Copilot',
        uri: '/copilot',
      },
      SHOW_ROUTINES && {
        icon: 'schedule' as const,
        id: 'routines',
        label: 'Routines',
        uri: '/routines',
      },
      SHOW_DATASETS && {
        icon: 'database' as const,
        id: 'data',
        label: 'Data',
        uri: '/datasets',
      },
      {
        icon: 'settings' as const,
        id: 'global-settings-link',
        label: 'Settings',
        uri: `/settings/`,
      },
    ].filter(Boolean) as VerticalLink[];
  }, [
    IS_MAIN_COPILOT_USER,
    SHOW_ANOMALIES,
    SHOW_DASHBOARDS,
    SHOW_HEADCOUNT,
    SHOW_INBOX,
    SHOW_REPORTS,
    SHOW_TASKS,
    SHOW_COPILOT,
    SHOW_ROUTINES,
    SHOW_DATASETS,
    data?.unreadNotificationCount,
    showDrivers,
    showTaskBadge,
  ]);

  return (
    <Location>
      {({ location: { pathname } }) => (
        <VerticalNav
          active={active}
          links={links}
          pathname={pathname}
          toggle={toggle}
          onClickLogout={handleLogout}
        />
      )}
    </Location>
  );
};

Nav.displayName = 'Nav';

export default Nav;
