import * as React from 'react';

import { APIClient, Client } from '@cobbler-io/utils/src/api-client';
import { UserManager } from 'oidc-client';

import { UserProfile } from './auth';

export type AuthContextType<T extends UserManager = UserManager> = {
  manager: T | null;
  profile: Record<string, unknown> | UserProfile;
  client: APIClient;
};

export const AuthContext = React.createContext<AuthContextType>({
  client: Client,
  manager: null,
  profile: {},
});

export default AuthContext;
