import React from 'react';

import { CurrencyField } from '@cobbler-io/core-ui/src/Field';

import { useCurrencyFormatter } from '@cobbler-io/redux/src/modules/currency';
import { useSelectedBudgetRevisionId } from '@cobbler-io/redux/src/modules/current-budget';

import {
  EditLinePlannedSpendInputType, EditPlannedSpendMutation, Exact, useEditPlannedSpendMutation,
} from '@cobbler-io/app/src/api/graphql-types';

import { MutationUpdaterFn } from 'apollo-client';
import gql from 'graphql-tag';
import { path, pipe } from 'ramda';
import { CellProps } from 'react-table';

import { EditorLine } from '../types';
import {
  acceptedNumericKeys, EditorInput, ModalEditorHandlers, useModalEditor,
} from './useModalEditor';

type OptResp = (
  vars: Exact<{
    input: EditLinePlannedSpendInputType;
  }>,
) => EditPlannedSpendMutation;

/**
 * Creates an optimistic response for the mutation, which is basically the return
 * of the mutation
 * @see https://www.apollographql.com/docs/react/v2/performance/optimistic-ui/
 */
const optimisticResponse: OptResp = ({ input }) => {
  //
  const { revisionId, start, planned, budgetLineId } = input;
  return {
    __typename: 'Mutation',
    // eslint-disable-next-line camelcase
    a_editLinePlannedSpend: {
      __typename: 'EditLinePlannedSpendPayloadType',
      budgetLineId,
      planned,
      revisionId,
      start,
    },
  };
};

const WRITE_PLANNED_SPENT_FRAGMENT = gql`
  fragment writePlannedSpendPlanned on BudgetEditorPlannedSpendType {
    __typename
    planned
  }
`;

/**
 * The update receives the input, and returns a function that will update the cache.
 *
 * It's weird, but I need the budgetLineId, which is not in the response
 *
 * This seems to work, but every once in awhile it seems to do nothing. There might have
 * been some cache evictions.
 */
const update: MutationUpdaterFn<EditPlannedSpendMutation> = (cache, result) => {
  /**
   * Updates the columns on the BudgetEditorTable
   */
  const updateBudgetEditorPlannedSpendType = () => {
    const { a_editLinePlannedSpend } = result.data!;
    const { budgetLineId, revisionId, start, planned } = a_editLinePlannedSpend;
    const __typename = 'BudgetEditorPlannedSpendType';
    const id = ['BudgetEditorPlannedSpendType', budgetLineId, revisionId, start].join(':');
    cache.writeFragment({
      data: { __typename, budgetLineId, planned, revisionId, start },
      fragment: WRITE_PLANNED_SPENT_FRAGMENT,
      id,
    });
  };

  /**
   * Updates the columns on the BudgetOverviewTable
   */
  const updateBudgetOverviewLineTable = () => {
    /**
      Do update logic for the overview cache here. But we have a bit of an identification
      problem: we need to identify the exact `RevisionType2` to update, but we don't have
      the cache keys.

      Here's an example cache key for the `RevisionAmountType2`:
      ```
$ROOT_QUERY.a_budgetLineOverview({"dateRange":{"end":"2021-12-31","start":"2021-01-01"},"id":"92718e64-3b57-4a07-acc7-28c252dc879a"}).lines.8.intervalValues.4.plannedByRevision.0
      ```

       We could reconstruct that, but we'd need to grab the exact args and then query fragments
       and drill down to the exact one.

       We could change the output of these and then construct stable identifiers so that they're
       easier to identify. I did edit the backend in the editor data so that we could make this
       happen correctly. We could also reconstruct the graph ourselves so that we could identify
       things well enough as an actual graph. For this example, we'd effectively have a single graph
       node that the budget overview and the budget editor would point to.
       */
  };

  updateBudgetEditorPlannedSpendType();
  updateBudgetOverviewLineTable();
};

type RelativeEditorParams = {
  getWeight: NullaryFn<number>;
};

export const useEditPlannedSpend = (
  params: RelativeEditorParams,
): ModalEditorHandlers & {
  onCopy: (event: React.ClipboardEvent, cellProps: CellProps<EditorLine, MajorCurrency>) => void;
  onPaste: (event: React.ClipboardEvent, cellProps: CellProps<EditorLine, MajorCurrency>) => void;
} => {
  const selectedRevisionId = useSelectedBudgetRevisionId();
  const { parseFloatLocale, convertToMinorUnit, minorUnit } = useCurrencyFormatter();
  const toValue = (value: string): MinorCurrency =>
    convertToMinorUnit(parseFloatLocale(value) as MajorCurrency);
  const { getWeight } = params;

  const getDefaultValue = (cellValue: number): string => {
    const formatter = new Intl.NumberFormat(undefined, {
      maximumFractionDigits: minorUnit,
      minimumFractionDigits: minorUnit,
      style: 'decimal',
    });

    const proposedValue = formatter.format(cellValue).replace(/[^0-9-.]/gu, '');

    return proposedValue.endsWith('0'.repeat(minorUnit))
      ? proposedValue.slice(0, -(minorUnit + 1))
      : proposedValue;
  };

  const createInput = (p: { value: number; cellProps: CellProps<EditorLine, MajorCurrency> }) => ({
    budgetLineId: p.cellProps.row.original.id,
    planned: p.value,
    revisionId: selectedRevisionId!,
    start: p.cellProps.cell.column.parent!.id,
  });

  const onCopy = (event: React.ClipboardEvent, cellProps: CellProps<EditorLine, MajorCurrency>) => {
    event.clipboardData.setData('text/plain', `${cellProps.cell.value}`);
    event.preventDefault();
  };

  const [editPlannedSpend] = useEditPlannedSpendMutation();
  const onPaste = (
    event: React.ClipboardEvent,
    cellProps: CellProps<EditorLine, MajorCurrency>,
  ) => {
    const paste = event.clipboardData.getData('text');
    const proposedValue = toValue(paste);
    if (Number.isNaN(proposedValue)) {
      // This is a no-op
      return;
    }

    event.preventDefault();

    // Todo add some validation here?

    void editPlannedSpend({
      optimisticResponse,
      update,
      variables: { input: createInput({ cellProps, value: proposedValue }) },
    });
  };

  const mainHandlers = useModalEditor<
    EditLinePlannedSpendInputType,
    EditPlannedSpendMutation,
    EditorInput,
    MajorCurrency
  >({
    Field: CurrencyField,
    acceptedKeysHandler: acceptedNumericKeys,
    createInput,
    getCurrentValue: pipe(path(['cell', 'value']), convertToMinorUnit),
    getDefaultValue,
    getWeight,
    optimisticResponse: () => optimisticResponse,
    shouldDisable: cell => cell.row.original.linkedToDriver,
    toValue,
    update: () => update,
    useMutationHook: useEditPlannedSpendMutation,
  });

  return { ...mainHandlers, onCopy, onPaste };

  // Basically, this should be the inner cell. We need to make it so that when the focus lands on
  // the outer cell, we check for keys. If it's valid to start editing, then we automatically switch
  // to the inner cell and start editing. If it's not, then, well, we do nothing.
};
