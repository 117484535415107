import {
  DateRangeWithName, lastMonth, lastQuarter, nextFiscalYear, thisFiscalYear, thisMonth, thisQuarter,
} from '@cobbler-io/utils/src/fiscal-year-dates';

import { DateRange } from '@cobbler-io/dates/src/DateRange';

type GetDateRangeParams = {
  name: string;
  max: Date;
  min: Date;
  fysm: ServerMonth;
};

export const getDateRange = (params: GetDateRangeParams): DateRangeWithName | null => {
  const { name, max, min, fysm } = params;
  switch (name) {
    case 'all time':
      return {
        name,
        start: min,
        end: max,
        range: new DateRange(min, max),
      };
    case 'this month':
      return thisMonth();
    case 'last month':
      return lastMonth();
    case 'this quarter':
      return thisQuarter(fysm);
    case 'last quarter':
      return lastQuarter(fysm);
    case 'this year':
      return thisFiscalYear(fysm);
    case 'next year':
      return nextFiscalYear(fysm);
    default:
      return null;
  }
};
