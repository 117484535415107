import * as React from 'react';

import { budgetUrls } from '@cobbler-io/app/src/urls';

import { Link } from '@reach/router';

import { MessageTemplateParameter } from '../ParameterizedMessage';

export const BudgetLineToken = ({
  resourceId,
  displayValue,
  revisionId,
}: MessageTemplateParameter): JSX.Element =>
  !resourceId ? (
    <>{displayValue}</>
  ) : (
    <Link
      to={budgetUrls.overview({
        budgetId: resourceId,
        revisionId,
      })}
    >
      {displayValue}
    </Link>
  );

BudgetLineToken.displayName = 'BudgetLineToken';
