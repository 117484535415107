import { VirtualElement } from '@popperjs/core/lib/types';

/**
 * Creates a fake element to use as base for positioning.
 * It reports a fake `getBoundingClientRect` at the provided x & y coordinates.
 * Mostly used by Popper.
 * @param x X coordinate
 * @param y Y coordinate
 */
export const createVirtualElement = (x: number, y: number): VirtualElement => ({
  getBoundingClientRect: () => ({ width: 0, height: 0, top: y, right: x, bottom: y, left: x }),
});
