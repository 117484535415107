import * as React from 'react';

import { lower } from '@cobbler-io/utils/src/string/lower';

import {
  getInitialFrontendFlags, useUpdateFeatureFlags,
} from '@cobbler-io/redux/src/modules/feature-flags';

import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';

export const GET_FEATURES = gql`
  query FeatureFlags {
    features {
      name
      enabled
    }
  }
`;

type ServerFeatureFlag = { name: string; enabled: boolean };

const setFlagsByName = (acc: Record<string, boolean>, { name, enabled }: ServerFeatureFlag) => {
  const key = lower(name);

  // We don't allow feature flags to be turned off, so the server value will only override
  // them if they have yet to be set.
  if (!Object.prototype.hasOwnProperty.call(acc, key) || !acc[key]) {
    acc[key] = enabled;
  }

  return acc;
};

export const FeatureFlagProvider = (props: { children: JSX.Element }): JSX.Element => {
  const { children } = props;

  // Set the feature flags defined in the frontend code and from the URLSearchParams
  const features = React.useRef<Record<string, boolean>>(getInitialFrontendFlags());
  const { update: setFeatureFlags } = useUpdateFeatureFlags();

  // Get the server defined feature flags
  const { data, error } = useQuery<{ features: { name: string; enabled: boolean }[] }>(
    GET_FEATURES,
    { fetchPolicy: 'cache-and-network' },
  );

  React.useEffect(() => {
    if (data && Array.isArray(data.features)) {
      // Merge the feature flags together with the current client side flags as well as the
      // server side flags. Server side flags will be set only if they have yet to be defined
      // by a client side flag.
      features.current = data.features.reduce(setFlagsByName, features.current);
    }

    // Set the flags in Redux
    setFeatureFlags({ ...features.current });
  }, [data, setFeatureFlags]);

  if (error) {
    // eslint-disable-next-line
    console.error('[FeatureFlagProvider]: Error fetching server defined feature flags', error);
  }

  return children;
};

export default FeatureFlagProvider;
