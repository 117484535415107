import * as React from 'react';

import styles from './SkippedLine.scss';

export type SkippedLineProps = {
  name: string;
  owner: string | null;
  accounts: readonly string[];
  departments: readonly string[];
};

export const SkippedLine = (props: SkippedLineProps): JSX.Element => {
  const { name, owner, accounts, departments } = props;
  return (
    <div className={styles.skippedLine}>
      <h3>{name}</h3>
      <ul>
        {/* Owner */}
        {owner && <li>“{owner}” is not a valid email address.</li>}

        {/* Accounts */}
        {accounts.length === 1 && <li>Account code “{accounts[0]}” does not exist.</li>}
        {accounts.length > 1 && <li>Account codes “{accounts.join('”, “')}” do not exist.</li>}

        {/* Departments */}
        {departments.length === 1 && <li>Department “{departments[0]}” does not exist.</li>}
        {departments.length > 1 && <li>Departments “{departments.join('”, “')}” do not exist.</li>}
      </ul>
    </div>
  );
};
SkippedLine.displayName = 'SkippedLine';
