import { createCssGetterSetter } from './createCssGetterSetter';
import { documentExists } from './documentExists';
import { noop } from './noop';
import { windowExists } from './windowExists';

export const createScrollLock = (element?: HTMLElement) => {
  if (!documentExists() || !windowExists()) {
    return { lockScroll: noop, unlockScroll: noop };
  }

  const el = element ?? document.body;
  const elCss = createCssGetterSetter(el);

  /**
   * Sets `document.body.style.overflow` to `hidden`
   */
  const lock = () => {
    elCss('position', 'fixed');
    elCss('top', `-${element ? window.scrollY : el.scrollTop}px`);
    elCss('left', `-${element ? window.scrollX : el.scrollLeft}px`);
  };

  /**
   * Reverts `document.body.style.overflow` to something other than hidden
   */
  const unlock = () => {
    elCss('position', '');
    elCss('top', '');
  };

  return {
    /**
     * Sets `document.body.style.overflow` to `hidden`
     */
    lockScroll: lock,
    /**
     * Reverts `document.body.style.overflow` to something other than hidden
     */
    unlockScroll: unlock,
  };
};
