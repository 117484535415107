/* TODO fix Card.Header */
import * as React from 'react';

import { cx } from '@cobbler-io/utils/src/cx';

import styles from './Card.scss';

type Props = {
  elevation?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;
  hoverable?: boolean;
  fullBleed?: boolean;
  flat?: boolean;
} & React.HTMLProps<HTMLDivElement>;

type Context = {
  flat: boolean;
};

const { Provider, Consumer } = React.createContext<Context>({
  flat: false,
});

export const Card = ({
  children,
  className,
  elevation = 1,
  fullBleed = false,
  hoverable = false,
  flat = false,
  ...props
}: Props): JSX.Element => (
  <div
    {...props}
    className={cx(
      styles.card,
      styles[`card${elevation}`],
      hoverable && styles.hoverable,
      fullBleed && styles.fullBleed,
      flat && styles.flat,
      className,
    )}
  >
    <Provider value={{ flat }}>{children}</Provider>
  </div>
);

Card.displayName = 'Card';

type HeaderProps = {
  subHead?: React.ReactNode;
  as?: 'div' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
} & React.HTMLProps<HTMLDivElement>;

// TODO Fix Header, maybe use `Heading`
const Header = ({ children, className, as = 'div', ...props }: HeaderProps): JSX.Element => (
  <Consumer>
    {({ flat }) =>
      React.createElement(
        as,
        { ...props, flat: flat || undefined, className: cx(styles.header, className) },
        children,
      )
    }
  </Consumer>
);

Header.displayName = 'Card.Header';

Card.Header = Header;

export default Card;
