import * as React from 'react';

import { thisFiscalYear } from '@cobbler-io/utils/src/fiscal-year-dates';

import { addYears, getEndOfDay, getStartOfDay } from '@cobbler-io/dates/src';
import { getEndOfMonth } from '@cobbler-io/dates/src/getEndOfMonth';
import { getStartOfMonth } from '@cobbler-io/dates/src/getStartOfMonth';
import { tzAdjust } from '@cobbler-io/dates/src/tzAdjust';

import usePageTitle from '@cobbler-io/hooks/src/usePageTitle';

import { Card } from '@cobbler-io/core-ui/src/Card';
import { ErrorBoundary } from '@cobbler-io/core-ui/src/ErrorBoundary';
import { useFeatureFlag } from '@cobbler-io/core-ui/src/FeatureFlag';
import { Heading } from '@cobbler-io/core-ui/src/Heading';
import { Icon } from '@cobbler-io/core-ui/src/Icon';
import { Tab, TabRouter } from '@cobbler-io/core-ui/src/TabRouter';

import {
  CurrentBudgetState,
  useCurrentBudget,
  useSelectedBudgetRevision,
} from '@cobbler-io/redux/src/modules/current-budget';
import { useFiscalYearStart } from '@cobbler-io/redux/src/modules/tenant-settings';

import { BudgetPlanMissing } from '@cobbler-io/app/src/components/BudgetPlanMissing';
import { BudgetBreadcrumbs } from '@cobbler-io/app/src/ndm/components/BudgetBreadcrumbs';
import { BudgetLineEditor } from '@cobbler-io/app/src/ndm/components/BudgetLineEditor/BudgetLineEditor';
import {
  CurrentRangeProvider,
  CurrentRangeProviderProps,
} from '@cobbler-io/app/src/providers/CurrentRangeProvider';

import { memoize } from 'lodash';

import { useCurrentBudgetLine } from '../providers/CurrentBudgetLineProvider';

import styles from './Budgets.scss';

type LazyProps = {
  Component: React.ComponentType<any>;
  fallback?: React.ComponentType<any>;
};

const Loading = <div>Loading...</div>;

const Lazy = (props: LazyProps): JSX.Element => {
  const { Component, fallback = Loading } = props;

  return (
    <React.Suspense fallback={fallback}>
      <Component />
    </React.Suspense>
  );
};

Lazy.displayName = 'Lazy';

const BudgetOverview = React.lazy(
  async () => import(/* webpackChunkName: "budget-overview" */ './screens/BudgetOverview'),
);

const BudgetDashboard = React.lazy(async () =>
  import(/* webpackChunkName: "ndm-budget-dashboard" */ './screens/BudgetDashboard').then(x => ({
    default: x.BudgetDashboard,
  })),
);

const ActualsList = React.lazy(async () =>
  import(/* webpackChunkName: "ndm-actuals-list" */ './screens/ActualsList').then(x => ({
    default: x.ActualsList,
  })),
);

// const BudgetEditor = loadable(
//   () => import(/* webpackChunkName: "budget-editor" */ '../pages/BudgetEditor'),
// );

const TeamView = React.lazy(async () =>
  import(/* webpackChunkName: "ndm-team-view" */ './screens/TeamView').then(x => ({
    default: x.TeamView,
  })),
);

// const TeamBudget = loadable(
//   () => import(/* webpackChunkName: "team-budget" */ '../pages/TeamBudget'),
// );

const Settings = React.lazy(
  async () => import(/* webpackChunkName: "ndm-settings" */ './screens/Settings'),
);

const VendorReport = React.lazy(
  async () => import(/* webpackChunkName: "VendorReport" */ './screens/VendorReport'),
);

const wrapper = memoize((Component: React.ComponentType) => {
  const fn = () => {
    return (
      <ErrorBoundary>
        <Lazy Component={Component} />
      </ErrorBoundary>
    );
  };

  fn.displayName = `Wrapper(${Component.name})`;

  return fn;
});

const NotReadyYet = () => {
  return <Card>This feature is currently being reworked and will be available again soon.</Card>;
};

NotReadyYet.displayName = 'NotReadyYet';

const TempEditor = () => {
  return <BudgetLineEditor />;
};

TempEditor.displayName = 'TempEditor';

const DEFAULT_MIN = getStartOfDay(tzAdjust('1970-01-01'));
const DEFAULT_MAX = getEndOfDay(tzAdjust('2069-12-31'));

const getMinMax = (
  budget: CurrentBudgetState | null,
): { defaultMin: LocalDate; defaultMax: LocalDate } => {
  const { min, max } = budget ?? {};

  const defaultMin = min ? getStartOfDay(tzAdjust(min)) : DEFAULT_MIN;
  const defaultMax = max ? getEndOfDay(tzAdjust(max)) : DEFAULT_MAX;

  return { defaultMin, defaultMax };
};

// eslint-disable-next-line max-lines-per-function
export const Budgets = (): JSX.Element => {
  const IS_MAIN_COPILOT_USER = useFeatureFlag('CopilotView');
  const HIDE_ACTUALS = useFeatureFlag('HideActuals');
  const ctx = useCurrentBudgetLine();
  const fysm = useFiscalYearStart();
  const selectedRevision = useSelectedBudgetRevision()!;
  const fy = thisFiscalYear(+fysm + 1);
  const [budget] = useCurrentBudget();
  const title = usePageTitle();

  const canRead = ctx?.permissions?.read ?? false;
  const canEdit = ctx?.permissions?.edit ?? false;
  const canSeeSettings = ctx?.permissions.edit ?? ctx?.permissions.manage ?? false;
  const { defaultMin, defaultMax } = getMinMax(budget);

  // These are mocked for now. We need to get the start / end
  const rangeProviderParams: Omit<CurrentRangeProviderProps, 'children'> = React.useMemo(
    () => ({
      cacheIdentifier: budget?.id,
      defaultEnd: getEndOfDay(fy.end) as LocalDate,
      defaultMax: getEndOfDay(defaultMax) as LocalDate,
      defaultMin: getStartOfMonth(addYears(defaultMin, -1)) as LocalDate,
      defaultPeriodLength: 'MONTH' as const,
      defaultStart: getStartOfDay(fy.start) as LocalDate,
      max: getEndOfMonth(addYears(defaultMax, 1)) as LocalDate,
      min: getStartOfMonth(addYears(defaultMin, -1)) as LocalDate,
    }),
    [budget, defaultMin, defaultMax], // eslint-disable-line react-hooks/exhaustive-deps
  );

  if (!selectedRevision) {
    return <span>Error: Revision not found </span>;
  }

  if (!ctx) {
    return <BudgetPlanMissing />;
  }

  // TODO: Use the paths defined in urls.ts
  const basePath = ':budgetId/:revisionTag';

  const isLocked = selectedRevision?.isLocked ?? false;
  const editLabel = isLocked ? (
    <div className={styles.disabledEdit}>
      <Icon size={14} type="lock" /> Edit
    </div>
  ) : (
    'Edit'
  );

  if (!budget?.id) {
    // @ts-expect-error: this is fine for React
    return null;
  }

  return (
    <CurrentRangeProvider key={budget.id} {...rangeProviderParams}>
      <ErrorBoundary>
        <Heading size="headline" weight="bold" style={{ marginLeft: '1rem' }}>
          {title}
        </Heading>
        <BudgetBreadcrumbs />

        <TabRouter>
          <Tab
            Component={wrapper(BudgetOverview)}
            id="overview"
            label="Overview"
            path={`${basePath}/*`}
          />

          {!IS_MAIN_COPILOT_USER && canEdit && (
            <Tab
              Component={TempEditor}
              disabled={isLocked}
              id="edit"
              label={editLabel}
              path={`${basePath}/edit`}
            />
          )}

          <Tab
            Component={VendorReport}
            id="vendor-report"
            label="Vendors"
            path={`${basePath}/vendors`}
          />

          {!IS_MAIN_COPILOT_USER && (
            <Tab
              Component={wrapper(BudgetDashboard)}
              id="dashboard"
              label="Dashboard"
              path={`${basePath}/dashboard`}
            />
          )}

          {!HIDE_ACTUALS && (
            <Tab
              Component={wrapper(ActualsList)}
              id="actuals-list"
              label="Actuals"
              path={`${basePath}/actuals/*`}
            />
          )}

          {!IS_MAIN_COPILOT_USER && canRead && (
            <Tab
              Component={wrapper(TeamView)}
              id="team-view"
              label="Team"
              path={`${basePath}/team`}
            />
          )}

          {canSeeSettings && (
            <Tab
              Component={wrapper(Settings)}
              id="settings"
              label="Settings"
              path={`${basePath}/settings`}
            />
          )}
        </TabRouter>
      </ErrorBoundary>
    </CurrentRangeProvider>
  );
};

Budgets.displayName = 'Budgets';

export default Budgets;
