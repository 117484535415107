const getSuffix = (isPast: boolean) => (isPast ? 'ago' : 'from now');

const pluralize = new Intl.PluralRules('en-US', { type: 'ordinal' });

const getPlural = (num: number) => {
  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  if (Math.abs(num) < 5) {
    return pluralize.select(num) === 'other' ? num : pluralize.select(num);
  }

  return num;
};

export const relativeTime = (num: number, unit: string, isPast: boolean): string => {
  const plural = getPlural(num);

  return [plural === 'few' ? 'a few' : plural, num > 1 ? `${unit}s` : unit, getSuffix(isPast)].join(
    ' ',
  );
};
