import * as React from 'react';

import { actions as reduxActions } from '@cobbler-io/redux/src/modules/Auth';

import * as Sentry from '@sentry/browser';
import { User, UserManager } from 'oidc-client';
import { useDispatch } from 'react-redux';

type OIDCState = {
  loading: boolean;
  error: string | null;
  manager: UserManager | null;
  user: User | null;
  configured: boolean;
};

type OIDCAction =
  | { type: 'ERROR'; message: string }
  | { type: 'SET_CONFIGURED'; configured: boolean }
  | { type: 'LOAD_USER'; user: User }
  | { type: 'LOAD_MANAGER'; manager: UserManager }
  | { type: 'UNLOAD_USER' }
  | { type: 'LOADING' };

type OIDCReducerType = React.Reducer<OIDCState, OIDCAction>;

const defaultState = {
  manager: null,
  error: null,
  user: null,
  loading: false,
  configured: true, // this might be the wrong default
};

export const OIDCReducer: OIDCReducerType = (state, action) => {
  switch (action.type) {
    case 'SET_CONFIGURED':
      return { ...state, configured: action.configured, loading: false };
    case 'ERROR':
      return { ...state, error: action.message, loading: false };
    case 'LOAD_MANAGER':
      return { ...state, manager: action.manager, loading: false };
    case 'LOAD_USER':
      return { ...state, user: action.user, loading: false };
    case 'UNLOAD_USER':
      return { ...state, user: null, loading: false };
    case 'LOADING':
      return { ...state, loading: true };
    default:
      return state;
  }
};

const registerSentryUser = (user: User) => {
  Sentry.setUser({ email: user.profile?.email, name: user.profile?.name });
};

const unregisterSentryUser = () => {
  Sentry.configureScope(scope => void scope.setUser(null));
};

const registerHeapUser = (user: User) => {
  try {
    if (window && window.heap && user?.profile?.email) {
      window.heap.identify(user.profile.email);
    }
  } catch (e) {
    // noop
  }
};

export const handleError = (dispatch: React.Dispatch<OIDCAction>) => (error: Error) => {
  console.error('[oidc]', error); // eslint-disable-line no-console
  dispatch({ type: 'ERROR', message: error.message });
};

export const loadUser =
  (dispatch: React.Dispatch<OIDCAction>, reduxDispatch: any) => (user: User) => {
    // Side-Effect: Register the user information with Sentry for bug reports
    registerSentryUser(user);
    // Side-Effect: Register the user with Heap for better tracking
    registerHeapUser(user);
    // Hydrate the context
    dispatch({ type: 'LOAD_USER', user });
    // Push the user into the auth store for redux things
    reduxDispatch(reduxActions.load(user));
  };

export const loadManager =
  (dispatch: React.Dispatch<OIDCAction>, reduxDispatch: any) => (manager: UserManager) => {
    dispatch({ type: 'LOAD_MANAGER', manager });
    manager.getUser().then(user => {
      user && loadUser(dispatch, reduxDispatch)(user);
    });
  };

export const unloadUser = (dispatch: React.Dispatch<OIDCAction>, reduxDispatch: any) => () => {
  // Remove the user information from sentry. We might rethink this
  unregisterSentryUser();
  // Dump the user from the auth context
  dispatch({ type: 'UNLOAD_USER' });
  // Dump the user from the redux connected things
  reduxDispatch(reduxActions.signOut());
};

export const setConfigured = (dispatch: React.Dispatch<OIDCAction>) => (configured: boolean) => {
  dispatch({ type: 'SET_CONFIGURED', configured });
};

export const useOIDC = () => {
  const [state, dispatch] = React.useReducer<OIDCReducerType>(OIDCReducer, defaultState);
  const reduxDispatch = useDispatch();

  if (__DEV__) {
    React.useDebugValue(state);
  }

  const actions = React.useMemo(
    () => ({
      handleError: handleError(dispatch),
      loadUser: loadUser(dispatch, reduxDispatch),
      loadManager: loadManager(dispatch, reduxDispatch),
      unloadUser: unloadUser(dispatch, reduxDispatch),
      setConfigured: setConfigured(dispatch),
    }),
    [],
  );

  return { state, dispatch, actions };
};
