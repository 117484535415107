import * as React from 'react';

import { execOrMapFuncs } from '@cobbler-io/utils/src/execOrMapFuncs';

export const useIsFirstMount = () => {
  const isFirstMount = React.useRef<boolean>(true);
  React.useEffect(() => {
    isFirstMount.current = false;
  }, []);

  return isFirstMount.current;
};

type NullaryFunction = () => any;

export const useOnMount = (...fns: NullaryFunction[]) => {
  if (useIsFirstMount()) {
    execOrMapFuncs(fns);
  }
};
