import { CellProps } from 'react-table';

const percent = new Intl.NumberFormat(undefined, {
  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  maximumFractionDigits: 2,
  minimumFractionDigits: 0,
  style: 'percent',
});

export const DisplayPercent = ({ cell: { value } }: CellProps<any>): string =>
  percent.format(value);
// `${value * 100}%`;
