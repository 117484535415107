import React from 'react';

import { cx } from '@cobbler-io/utils/src/cx';
import { execIfFunc } from '@cobbler-io/utils/src/execIfFunc';
import { isFunction } from '@cobbler-io/utils/src/isFunction';

import { useGetLatest } from '@cobbler-io/hooks/src/useGetLatest';
import { useLatest } from '@cobbler-io/hooks/src/useLatest';
import { useOnMount } from '@cobbler-io/hooks/src/useOnMount';
import { usePopper } from '@cobbler-io/hooks/src/usePopper';

import { useClickAway } from '@cobbler-io/core-ui/src/ClickAway';

import { FocusZone } from '../FocusZone';
import { ScrollLock } from '../ScrollLock';
import { CurrentModalContext } from './CurrentModalContext';
import { getCloseBehavior } from './getCloseBehavior';
import { getTreeNodeDepth } from './getTreeNodeDepth';
import { Overlay } from './Overlay';
import { renderIntoPortal } from './renderIntoPortal';
import { ModalProps } from './types';
import { useEscapeKeyHandler } from './useEscapeKeyHandler';
import { useModalImplementation } from './useModalImplementation';

import styles from './Modal.scss';

const MODAL_LEVEL = 5000;
const ARROW_PADDING = 0;
const OVERFLOW_PADDING_X = 5;
const OVERFLOW_PADDING_Y = 10;
const OFFSET_SKIDDING = 0;
const OFFSET_DISTANCE = 8;

export const DEFAULT_POPPER_MODIFIERS = [
  { name: 'arrow', options: { padding: ARROW_PADDING } },
  { name: 'preventOverflow', options: { padding: [OVERFLOW_PADDING_X, OVERFLOW_PADDING_Y] } },
  { name: 'offset', options: { offset: [OFFSET_SKIDDING, OFFSET_DISTANCE] } },
];

// eslint-disable-next-line max-lines-per-function, complexity
export const Modal = (props: ModalProps): JSX.Element => {
  const {
    children,
    className,
    style,
    initialFocusRef,
    arrow = false,
    clickAway = 'close',
    escape = 'close',
    onClickOverlay = clickAway,
    closeTreeOnClose = false,
    lock = false,
    overlay = false,
    bare = false,
    deferFocus = false, // this is the opposite of initial focus...
    ...rest
  } = props;

  const { modal, isTop = false } = useModalImplementation();

  const getIsTop = useGetLatest(isTop);

  React.useDebugValue(modal?.ref);

  // TODO we use this to layer modals on top of each other, but also, we should have a way to figure
  // out the overlays to ensure that overlays do not clash or hide modals that should be focusable
  // within the same tree
  const zIndex = Modal.MODAL_LEVEL + (modal ? getTreeNodeDepth(modal) * 2 : 0);
  const appliedStyle = React.useMemo(() => ({ ...(style ?? {}), zIndex }), [style, zIndex]);

  useOnMount(() =>
    modal?.onAfterOpen.forEach(fn => {
      execIfFunc(fn);
    }),
  );

  // Do something to help out with the fact that the modal might not exist yet
  // It won't exist because it gets injected. So the JSX object could be made
  // without `modal`, but it will never be rendered without it.

  if (modal) {
    if (closeTreeOnClose && modal.root !== modal) {
      modal.close = modal.closeTree; // eslint-disable-line functional/immutable-data
    }
  }

  const clickAwayRefFnProto = React.useCallback(
    () => ({
      current: (modal?.watchedRefs.current ?? [])
        // this is confusing. we should be able to watch the tree refs for all trees
        .concat(modal?.treeRefs.current ?? modal?.ref ?? [])
        .filter(Boolean),
    }),
    [modal],
  );
  const clickAwayRef = useLatest(clickAwayRefFnProto);

  // We can use either all the refs in the tree or just the single ref...
  useClickAway(clickAwayRef, getCloseBehavior(clickAway, modal!), !modal || clickAway === 'none');

  const popper = usePopper({
    options: {
      modifiers: modal?.options.popperModifiers ?? DEFAULT_POPPER_MODIFIERS,
      placement: modal?.options.placement ?? 'auto',
    },
    popper: modal?.ref ?? null,
    target: modal?.target ?? null,
  });

  const handleOverlayClick = getCloseBehavior(onClickOverlay, modal!);

  useEscapeKeyHandler(modal!, escape, getIsTop);

  if (!modal) {
    // There is no modal yet. This is likely because the user created the object
    // and it has not yet gone through a rendering layer, which is fine.
    // @ts-expect-error: this is fine for React
    return null;
  }

  modal.popper = popper; // eslint-disable-line

  // If this is a child modal, then we'll render it into the parent's portal.
  const treePortalElement = modal.parent?.ref.current?.parentElement ?? null;

  const displayLayerProps = {
    className: cx(styles.modal, className),
    style,
    zIndex,
  };

  if (bare) {
    return renderIntoPortal(
      treePortalElement,
      <CurrentModalContext.Provider value={modal}>
        {lock && <ScrollLock />}
        {overlay && (
          <Overlay
            isTransparent={overlay === 'transparent'}
            zIndex={zIndex - 1}
            onClick={handleOverlayClick}
          />
        )}
        <FocusZone
          ref={modal.ref as React.RefObject<HTMLDivElement>}
          defer={deferFocus}
          disable={!getIsTop()}
          initial={initialFocusRef}
        >
          {isFunction(children) ? children(modal, displayLayerProps) : children}
        </FocusZone>
      </CurrentModalContext.Provider>,
    );
  }

  return renderIntoPortal(
    treePortalElement,
    <CurrentModalContext.Provider value={modal}>
      {lock && <ScrollLock />}
      {overlay && (
        <Overlay
          isTransparent={overlay === 'transparent'}
          zIndex={zIndex - 1}
          onClick={handleOverlayClick}
        />
      )}
      <FocusZone
        ref={modal.ref as React.RefObject<HTMLDivElement>}
        bare
        defer={deferFocus}
        disable={!getIsTop()}
        initial={initialFocusRef}
      >
        <div
          {...rest}
          ref={modal.ref as React.RefObject<HTMLDivElement>}
          className={cx(styles.modal, className)}
          data-modal-id={modal.id}
          style={appliedStyle}
        >
          {arrow && <div data-popper-arrow className={styles.arrow} />}
          {arrow && <div className={styles.backgroundMask} />}
          {isFunction(children) ? children(modal, displayLayerProps) : children}
        </div>
      </FocusZone>
    </CurrentModalContext.Provider>,
  );
};

Modal.displayName = 'Modal';

/**
 * The base zIndex for Modals
 */
Modal.MODAL_LEVEL = MODAL_LEVEL; // eslint-disable-line
