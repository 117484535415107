import * as React from 'react';

import { Button } from '@cobbler-io/core-ui/src/Button';
import { ErrorMessage } from '@cobbler-io/core-ui/src/ErrorMessage';

import { navigate } from '@reach/router';

export const BudgetPlanMissing = (): JSX.Element => {
  return (
    <div className="col align-center" data-testid="budget-plan-missing">
      <ErrorMessage
        description="The budget plan that you are trying to view is either missing, or you do not have access to view it."
        title="Whoops!"
      />
      <div className="button-container center">
        <Button name="back" variant="text" onClick={async () => navigate(-1, { replace: true })}>
          Go back
        </Button>
        <Button name="home" onClick={async () => navigate('/', { replace: true })}>
          Go home
        </Button>
      </div>
    </div>
  );
};

BudgetPlanMissing.displayName = 'BudgetPlanMissing';
