import * as React from 'react';

import { CurrencyField } from '@cobbler-io/core-ui/src/Field';

import { useCurrencyFormatter } from '@cobbler-io/redux/src/modules/currency';

import { MaxHint } from '../../MaxHint';
import { pasteCellsIntoValues } from './pasteCellsIntoValues';
import { CustomExpenseType } from './useCustomExpense';

type CustomExpenseProps = {
  fields: CustomExpenseType[];
};

export const CustomExpense = (props: CustomExpenseProps): JSX.Element => {
  const ref = React.useRef<HTMLDivElement>(null);
  const { fields } = props;
  const { simpleFromMinorUnit, convertFromMinorUnit } = useCurrencyFormatter();

  const onPaste = pasteCellsIntoValues(ref);

  return (
    <div ref={ref} className="row">
      {fields?.map(field => {
        const {
          name,
          label,
          defaultValue,
          validateMax,
          available,
          onChange,
          maxHintLabel,
          currentValue,
        } = field;
        // If the value exceeds the available amount, we will hide the max hint to avoid redundancy
        const exceedsAmount = validateMax(String(convertFromMinorUnit(currentValue)));
        return (
          <div key={name} className="col small-6">
            <CurrencyField
              key={name}
              validateOnBlur
              validateOnChange
              defaultValue={convertFromMinorUnit(defaultValue)}
              label={label}
              name={name}
              placeholder="$0"
              validate={validateMax}
              onChange={onChange}
              onPaste={onPaste}
            />
            {!exceedsAmount && (
              <MaxHint format={simpleFromMinorUnit} label={maxHintLabel} value={available} />
            )}
          </div>
        );
      })}
    </div>
  );
};

CustomExpense.displayName = 'CustomExpense';
