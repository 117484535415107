/* eslint-disable func-style */
import { Predicate } from '../List';

export function* takeWhile<T>(predicate: Predicate<T>, iterable: Iterable<T>): Iterable<T> {
  for (const iteration of iterable) {
    if (!predicate(iteration)) {
      break;
    }

    yield iteration;
  }
}
