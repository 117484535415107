/**
 * Creates a reducer that has a single toggle-able boolean prop called `active`
 *
 * This is useful for modals, off-canvas drawers, or just about anything that
 * needs a toggle-able state.
 *
 * The reducer is memoized to be useful with SFCs.
 */
import { Reducer } from 'redux';

export type CounterState = {
  count: number;
};

/**
 * Creates a reducer and actions with a name
 */
export const createCounterReducer = (name: string) => {
  const INCREMENT = `${name}/activate`;
  const DECREMENT = `${name}/deactivate`;
  const RESET = `${name}/reset`;

  const initialState = { count: 0 };

  const reducer: Reducer<CounterState> = (state = initialState, action) => {
    switch (action.type) {
      case INCREMENT:
        return { count: state.count + 1 };
      case DECREMENT:
        return { count: state.count - 1 };
      case RESET:
        return initialState;
      default:
        return state;
    }
  };

  /**
   * Increments the counter
   */
  const increment = () => ({ type: INCREMENT });
  /**
   * Decrements the counter
   */
  const decrement = () => ({ type: DECREMENT });
  /**
   * Resets the counter
   */
  const reset = () => ({ type: RESET });

  return { decrement, increment, reducer, reset };
};
