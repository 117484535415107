/* eslint-disable new-cap */
import * as React from 'react';

import { head } from '@cobbler-io/utils/src/array/head';
import { omit } from '@cobbler-io/utils/src/omit';
import { loadXLSX } from '@cobbler-io/utils/src/parseSpreadsheet';

import { ISO8601 } from '@cobbler-io/formatters/src/dates';

import { CloseButton } from '@cobbler-io/core-ui/src/CloseButton';
import { useCurrentModal } from '@cobbler-io/core-ui/src/Modal';
import { useNotification } from '@cobbler-io/core-ui/src/Notification';
import { Step, Wizard } from '@cobbler-io/core-ui/src/Wizard';

import { Cell, Row, TableInstance } from 'react-table';

import Alert from '../../Alert';
import { ColumnSelection, PreviewExport } from './Steps';

import styles from './ExportWizard.scss';

type ExportWizardProps = {
  getInstance: NullaryFn<TableInstance>;
  file: string;
  includeDate?: boolean;
};

const getExportedValue = (cell: Cell<Record<string, unknown>>): string | number | null =>
  cell.column.Export ? cell.column.Export(cell.value, cell.row.original, cell.row) : cell.value;

/**
 * Add more options:
 *
 * @see https://docs.sheetjs.com/docs/api/write-options#supported-output-formats
 */
const EXPORT_OPTIONS = {
  csv: { bookType: 'csv', compression: false, ext: 'csv', type: 'string' },
  ods: { bookType: 'ods', compression: true, ext: 'ods', type: 'binary' },
  xlsx: { bookType: 'xlsx', compression: true, ext: 'xlsx', type: 'binary' },
} as const;

export type ExportableFileType = keyof typeof EXPORT_OPTIONS;

export const ExportWizard = (props: ExportWizardProps): JSX.Element => {
  const { getInstance, includeDate, file } = props;
  const { close, id: modalId } = useCurrentModal();
  const notify = useNotification();

  const { prepareRow, isRowExportable } = getInstance();

  const allRows = getInstance().rows;

  const exportableRows = allRows.filter(row => {
    prepareRow(row);
    return isRowExportable(row);
  });

  const count = exportableRows.length;

  const createBook = async (filename: string, type: ExportableFileType, data: any[][]) => {
    const xlsx = await loadXLSX();
    const opts = EXPORT_OPTIONS[type];
    const book = xlsx.utils.book_new();
    const sheet = xlsx.utils.aoa_to_sheet(data);
    xlsx.utils.book_append_sheet(book, sheet);
    xlsx.writeFile(book, `${filename}.${opts.ext}`, omit(opts, ['ext']));
  };

  const getData = (columns: Record<string, boolean>, rows: Row[]) => {
    const header = ['id'].concat(
      head(rows)
        ?.allCells.filter(cell => columns[cell.column.id])
        .map(
          x =>
            x.column.label ?? (typeof x.column.Header === 'string' ? x.column.Header : x.column.id),
        ),
    );
    const data = rows.map(row =>
      [row.original?.id ?? row.id].concat(
        row.allCells.filter(cell => columns[cell.column.id]).map(getExportedValue),
      ),
    );

    return [header, ...data];
  };

  const onSubmit = async (values: Record<string, boolean>) => {
    if (count === 0) {
      return Promise.resolve(values);
    }

    /* eslint-disable no-underscore-dangle */
    const fileType = Object.keys(EXPORT_OPTIONS).includes(
      values.__filetype as unknown as ExportableFileType,
    )
      ? (values.__filetype as unknown as ExportableFileType)
      : 'xlsx';
    /* eslint-enable no-underscore-dangle */

    void createBook(
      [includeDate && ISO8601(new Date()), file].filter(Boolean).join('--'),
      fileType,
      getData(values, exportableRows),
    );
    return Promise.resolve(values);
  };

  const afterSubmit = () => {
    notify({
      body: 'Your download will start shortly',
      title: 'Starting download...',
    });
    close();
  };

  if (count === 0 && allRows.length === 0) {
    // The DataGrid has absolutely no data, so this should be obvious for the user
    return (
      <Alert confirm="Close" title="Nothing to export">
        No data is available to export.
      </Alert>
    );
  }

  if (count === 0) {
    return (
      <Alert confirm="Close" title="Nothing to export">
        There is no exportable data.
      </Alert>
    );
  }

  return (
    <>
      {modalId && <CloseButton />}
      <Wizard
        afterSubmit={afterSubmit}
        className={styles.wizard}
        FinishButton="Export"
        onSubmit={onSubmit}
      >
        <Step shouldShowIf={() => count === 0}>Nothing to export.</Step>
        <Step className={styles.step}>
          <ColumnSelection count={count} getInstance={getInstance} />
        </Step>
        <Step className={styles.step}>
          {({ getFormValues }) => (
            <PreviewExport
              count={count}
              getData={getData}
              getFormValues={getFormValues}
              getInstance={getInstance}
            />
          )}
        </Step>
      </Wizard>
    </>
  );
};

ExportWizard.displayName = 'DataGrid__ExportWizard';
