import * as React from 'react';

import { cx, noop } from '@cobbler-io/utils/src';
import { execIfFunc } from '@cobbler-io/utils/src/execIfFunc';
import { isFunction } from '@cobbler-io/utils/src/isFunction';
import { prefersReducedData } from '@cobbler-io/utils/src/prefersReducedData';

import { Link, useMatch } from '@reach/router';

import styles from './TabRouterHeader.scss';

const maybePreload = (Component?: any) => {
  if (
    Component &&
    'preload' in Component &&
    typeof Component.preload === 'function' &&
    !prefersReducedData() // if the user prefers reduced data, then do not preload
  ) {
    return () => {
      if (typeof Component.preload === 'function') {
        Component.preload();
      }
    };
  }

  return noop;
};

export type TabRouterHeaderProps = {
  link: string;
  path: string;
  children: React.ReactNode;
  Component: React.ComponentType<any>;
  disabled?: boolean;
  id: string;
  active: boolean;
  subscribe?: Record<string, unknown>;
  isOverflow?: boolean;
};
export const TabRouterHeader = React.forwardRef(
  (props: TabRouterHeaderProps, ref: React.RefObject<HTMLAnchorElement>) => {
    const { link, children, Component, disabled, id, subscribe } = props;
    const active = Boolean(useMatch(link.split('?')[0]));
    const onClick = disabled
      ? (e: React.MouseEvent<HTMLAnchorElement>) => {
          e.preventDefault();
        }
      : noop;

    const onMouseOver = maybePreload(Component);

    React.useLayoutEffect(() => {
      return isFunction(subscribe) ? subscribe({ ref, props }) : noop;
    }, [subscribe]);

    return (
      <Link
        ref={ref}
        aria-controls={`panel-${id}`}
        aria-disabled={disabled}
        className={cx(styles.tabLabel, active && styles.active, disabled && styles.disabled)}
        id={`tab-router-header-${id}`}
        role="tab"
        to={link}
        onClick={onClick}
        onFocus={onMouseOver}
        onMouseOver={onMouseOver}
      >
        {execIfFunc(children, {})}
      </Link>
    );
  },
);

TabRouterHeader.displayName = 'TabRouterHeader';
