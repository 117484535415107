/* eslint-disable camelcase */
import * as React from 'react';

import { execIfFunc } from '@cobbler-io/utils/src';
import { noop } from '@cobbler-io/utils/src/noop';

import { Login } from '@cobbler-io/app/src/pages/Login';

import { navigate } from '@reach/router';
import { User, UserManager } from 'oidc-client';

import { AuthContext } from './AuthContext';
import { useOIDC } from './authReducer';
import { OIDCRouter } from './components/OIDCRouter';

type WellKnownConfig = {
  enabled: boolean;
  name?: string;
  auth?: {
    authority: string;
    client_id: string;
    redirect_uri: string;
    silent_redirect_uri: string;
    post_logout_redirect_uri: string;
    response_type: string;
    scope: string;
    companyName: string;
  };
};

const staticConfig = {
  automaticSilentRenew: true,
  includeIdTokenInSilentRenew: false,
  filterProtocolClaims: true,
  loadUserInfo: true,
};

export const getConfig: () => Promise<WellKnownConfig> = async () =>
  fetch('/api/bootstrap').then(async res => res.json());

type AuthProviderProps = {
  // We currently take no props
};

type OIDCActions = ReturnType<typeof useOIDC>['actions'];

/* eslint-disable no-console */
const onAccessTokenExpiring = () => console.info('[oidc]', 'Access Token Expiring');
const handleAccessTokenExpired = (manager: UserManager, actions: OIDCActions) => () => {
  console.info('[oidc]', 'Access Token Expired');
  // Remove the user from the manager when we unload it
  if (manager) {
    void manager.removeUser().then(() => {
      // Remove the user from the local store
      actions.unloadUser();
    });
  }
};

const handleSilentRenewError = (manager: UserManager, actions: OIDCActions) => () => {
  console.error('[oidc]', 'Silent renew error. Logging out.');
  if (manager) {
    void manager.removeUser().then(() => {
      // Remove the user from the local store
      actions.unloadUser();
    });
  }
};

const onUserSessionChange = () => console.info('[oidc]', 'User session changed');

const handleUserSignedOut = (actions: OIDCActions) => () => {
  console.info('[oidc]', 'User signed out');
  actions.unloadUser();
};

const handleUserUnloaded = (manager: UserManager, actions: OIDCActions) => () => {
  actions.unloadUser();
  // Clear any stale state to reduce some error, and then redirect to the login page
  void manager.clearStaleState().then(() => {
    // Go back to the login page
    void navigate(`/login/?redirect=${encodeURIComponent(window.location.pathname)}`);
  });
};

const handleUserLoaded = (actions: OIDCActions) => (user: User) => {
  actions.loadUser(user);
};

/* eslint-enable no-console */

const addEvents = (manager: UserManager, actions: OIDCActions) => {
  // Create some events that need references to things
  const onAccessTokenExpired = handleAccessTokenExpired(manager, actions);
  const onSilentRenewError = handleSilentRenewError(manager, actions);
  const onUserLoaded = handleUserLoaded(actions);
  const onUserSignedOut = handleUserSignedOut(actions);
  const onUserUnloaded = handleUserUnloaded(manager, actions);

  manager.events.addAccessTokenExpired(onAccessTokenExpired);
  manager.events.addAccessTokenExpiring(onAccessTokenExpiring);
  manager.events.addSilentRenewError(onSilentRenewError);
  manager.events.addUserLoaded(onUserLoaded);
  manager.events.addUserSessionChanged(onUserSessionChange);
  manager.events.addUserSignedOut(onUserSignedOut);
  manager.events.addUserUnloaded(onUserUnloaded);

  const removeEvents = () => {
    manager.events.removeAccessTokenExpired(onAccessTokenExpired);
    manager.events.removeAccessTokenExpiring(onAccessTokenExpiring);
    manager.events.removeSilentRenewError(onSilentRenewError);
    manager.events.removeUserLoaded(onUserLoaded);
    manager.events.removeUserSessionChanged(onUserSessionChange);
    manager.events.removeUserSignedOut(onUserSignedOut);
    manager.events.removeUserUnloaded(onUserUnloaded);
  };

  return removeEvents;
};

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const store = useOIDC();
  const { state, actions } = store;
  const removeEventsRef = React.useRef<undefined | (() => void)>(noop);

  React.useEffect(() => {
    void getConfig().then(config => {
      const { enabled, auth } = config;
      if (enabled) {
        const manager = new UserManager({ ...staticConfig, ...auth });
        removeEventsRef.current = addEvents(manager, actions);
        actions.loadManager(manager);
      } else {
        actions.setConfigured(false);
      }
    });

    // This might not actually work
    return () => execIfFunc(removeEventsRef.current);
  }, [actions]);

  if (!state.configured) {
    return <Login disabled onClick={noop} />;
  }

  if (!state.manager) {
    // If we don't have the manager, just render the login page with the loading dots.
    // After the manager loads, this will re-render and have something usable
    return <Login loading onClick={noop} />;
  }

  return (
    <AuthContext.Provider value={store}>
      <OIDCRouter>{children}</OIDCRouter>
    </AuthContext.Provider>
  );
};

AuthProvider.displayName = 'AuthProvider';
