/* eslint-disable max-lines-per-function */
import * as React from 'react';

import { cx } from '@cobbler-io/utils/src';
import { upperFirst } from '@cobbler-io/utils/src/string';

import { useToggle } from '@cobbler-io/hooks/src';

import { Button } from '@cobbler-io/core-ui/src/Button';
import { Field } from '@cobbler-io/core-ui/src/Field';

import { useCurrencyFormatter } from '@cobbler-io/redux/src/modules/currency';

import { UpsertBudgetLineInputType } from '@cobbler-io/app/src/api/graphql-types';

import { indexBy, not, pipe, propOr } from 'ramda';

import { BudgetLineAtRevision } from '../BudgetLineEditor/types';
import { intervalStartToPeriod } from './utils';

import styles from './ForecastUploaderPreview.scss';

type PatchedValue<T> = {
  current: T;
  previous: T | null;
};

type PatchedPlanInterval = {
  start: ISO8601String;
  amount: PatchedValue<number>;
};

type PatchedLine = {
  id: string | null;
  operation: 'add' | 'delete' | 'update' | null;
  name: PatchedValue<string>;
  planIntervals: PatchedPlanInterval[];

  // TODO: Compare these props as well
  ownerUserEmail: string | null;
  vendorNames: readonly string[] | null;
  departmentNames: readonly string[] | null;
  accountCodes: readonly string[] | null;
};

type PatchedData = {
  lines: PatchedLine[];
  totalPlanned: PatchedValue<number>;
  totalPlannedPerInterval: PatchedPlanInterval[];
  totalAllocated: PatchedValue<number>;
  totalAllocatedPerInterval: PatchedPlanInterval[];
  stats: { updates: number; additions: number };
};

const info = ['Name', 'Vendors', 'Owner Email', 'Department', 'Account Codes'];

const operationSymbols = {
  add: '+',
  delete: '-',
  update: '*',
};

const getDeltaClassName = (value: PatchedValue<number>, threshold: number = 0) => {
  if (value.previous === null || Math.abs(value.current - value.previous) <= threshold) {
    return 'same';
  }
  return value.current > value.previous ? 'over' : 'under';
};

const getPatchedData = (
  budgetLineAtRevision: BudgetLineAtRevision,
  lines: UpsertBudgetLineInputType[],
): PatchedData => {
  // Get a canonical array of intervals for this budget
  const { intervals } = budgetLineAtRevision;

  // Split lines to be added and lines to be updated:

  const getId = propOr(null, 'id');
  const hasNoId = pipe(getId, not);

  // Split lines to be added and lines to be updated:
  const updateLines = lines.filter(getId);
  const addLines = lines.filter(hasNoId);

  // Create a map of updated lines to access them by id easier
  const updateLinesById = indexBy(getId, updateLines);

  const stats = { additions: 0, updates: 0 };

  // Create the array of patched lines with additions at the bottom
  const patchedData: PatchedLine[] = budgetLineAtRevision.lines
    .map(original => {
      // First, map over all the current lines and mark the ones that have updates
      const updated = updateLinesById[original.id];
      let hasChanges = Boolean(updated) && updated.name !== original.name;

      // An updated line was found/matched
      if (updated) {
        const patched: PatchedLine = {
          accountCodes: updated.accountCodes,

          departmentNames: updated.departmentNames,
          // No changes. We'll calculate this value later.
          id: original.id,
          name: {
            current: updated.name,
            previous: original.name,
          },

          operation: null,
          ownerUserEmail: updated.ownerUserEmail,
          planIntervals: intervals.map((interval, i) => {
            // It is safer to look up the correct spend plan by comparing months
            // but at this point the data has been sanitized enough and it's OK
            // to rely on the planned spend index to increase perf a little bit.
            const originalPlan = original.plannedByInterval[interval.start];
            const updatedPlan = updated.plannedSpends![i];

            const amount = {
              current: updatedPlan?.planned ?? originalPlan?.allocated ?? 0,
              previous: originalPlan?.allocated ?? 0,
            };
            hasChanges = amount.current !== amount.previous || hasChanges;
            return {
              amount,
              start: interval.start,
            };
          }),
          vendorNames: updated.vendorNames,
        };
        patched.operation = hasChanges ? 'update' : null;
        stats.updates += hasChanges ? 1 : 0;

        return patched;
      }

      const patched: PatchedLine = {
        accountCodes: null,
        departmentNames: null,
        id: original.id,
        name: {
          current: original.name,
          previous: original.name,
        },
        operation: null,
        ownerUserEmail: null,
        planIntervals: intervals.map(interval => {
          const current = original.plannedByInterval[interval.start]?.allocated ?? 0;
          const amount = { current, previous: current };
          return {
            amount,
            start: interval.start,
          };
        }),
        vendorNames: null,
      };

      // No changes were actually registered, set operation as null;
      patched.operation = hasChanges ? 'update' : null;
      stats.updates += hasChanges ? 1 : 0;

      return patched;
    })

    // Add new lines at the end
    .concat(
      addLines.map<PatchedLine>(added => ({
        accountCodes: added.accountCodes,
        departmentNames: added.departmentNames,
        id: null,
        name: {
          current: added.name,
          previous: null,
        },
        operation: 'add',
        ownerUserEmail: added.ownerUserEmail,
        planIntervals: (added.plannedSpends || []).map(({ start, planned }) => ({
          amount: {
            current: planned,
            previous: null,
          },
          start,
        })),
        vendorNames: added.vendorNames,
      })),
    );

  // Now that all the data has been massaged, we can iterate over it and get
  // the totals. This could have been done on the first pass, but it's easier
  // to read in two steps and in general we will only be dealing with just a
  // few records (< 1000).

  // Total planned doesn't really have changes but keeping current & previous
  // for consistency.
  const totalPlanned = {
    current: budgetLineAtRevision.totals.available,
    previous: budgetLineAtRevision.totals.available,
  };
  const totalPlannedPerInterval = intervals.map<PatchedPlanInterval>(interval => ({
    amount: {
      current: budgetLineAtRevision.totalsByInterval[interval.start].available,
      previous: budgetLineAtRevision.totalsByInterval[interval.start].available,
    },
    start: interval.start,
  }));

  const totalAllocated = { current: 0, previous: 0 };
  const totalAllocatedPerInterval = intervals.map<PatchedPlanInterval>((interval, i) => {
    let current = 0;
    let previous = 0;

    patchedData.forEach(line => {
      current += line.planIntervals[i].amount.current;
      previous += line.planIntervals[i].amount.previous || 0;
    });

    totalAllocated.current += current;
    totalAllocated.previous += previous;

    return {
      amount: { current, previous },
      start: interval.start,
    };
  });

  stats.additions = addLines.length;

  return {
    lines: patchedData,
    stats,
    totalAllocated,
    totalAllocatedPerInterval,
    totalPlanned,
    totalPlannedPerInterval,
  };
};

export type ForecastUploaderPreviewProps = {
  lines: UpsertBudgetLineInputType[];
  overplanningThreshold?: MinorCurrency;
  budgetRevision: BudgetLineAtRevision;
  showSettings: boolean;
  onSubmit: (data: { makeActive: boolean; lines: UpsertBudgetLineInputType[] }) => void;
  onCancel: () => void;
};

export const ForecastUploaderPreview = (props: ForecastUploaderPreviewProps): JSX.Element => {
  const {
    budgetRevision,
    lines,
    onSubmit,
    onCancel,
    showSettings = false,
    overplanningThreshold = 0,
  } = props;
  const { intervals, isRoot } = budgetRevision;
  const { currencyFromMinorUnit } = useCurrencyFormatter();
  const { active, toggle: toggleActive } = useToggle(false);

  // The idea here is to take the original budget (`budget`) and the imported lines
  // (`lines`) and compare them to create an array of "patched" budget lines that
  // will be easy to represent visually and, in the future, to edit.

  const {
    lines: data,
    totalPlannedPerInterval,
    totalPlanned,
    totalAllocated,
    totalAllocatedPerInterval,
  } = getPatchedData(budgetRevision, lines);

  const deltaClassName = (i: number) =>
    getDeltaClassName(
      {
        current: totalAllocatedPerInterval[i].amount.current,
        previous: totalPlannedPerInterval[i].amount.current,
      },
      overplanningThreshold,
    );

  // Get an array of all the intervals that were overplanned
  const overallocationsInIntervals = isRoot
    ? []
    : totalAllocatedPerInterval.filter(
        (total, i) =>
          total.amount.current > totalPlannedPerInterval[i].amount.current + overplanningThreshold,
      );

  return (
    <div className={styles.forecastUploadPreview}>
      {overallocationsInIntervals.length > 0 && (
        <div className={styles.error}>
          <p>
            You have exceeded your allocation allotment across {overallocationsInIntervals.length}{' '}
            {overallocationsInIntervals.length > 1 ? 'periods' : 'period'}. Please review the
            information below.
          </p>
        </div>
      )}
      {!overallocationsInIntervals.length &&
        totalAllocated.current > totalPlanned.current + overplanningThreshold && (
          <div className={styles.error}>
            <p>
              You have exceeded your total allocation allotment. Please review the information
              below.
            </p>
          </div>
        )}
      <div className={styles.scrollingContainer}>
        <table className={styles.previewTable}>
          <thead>
            <tr>
              <th aria-label="Change type" />

              {info.map(name => (
                <th key={name}>{name}</th>
              ))}

              {intervals.map(({ start }, i) => (
                <th key={start} className={styles[deltaClassName(i)]}>
                  {upperFirst(intervalStartToPeriod(start))}
                </th>
              ))}
            </tr>
          </thead>

          <tbody>
            {data.map(line => (
              <tr key={line.id ?? line.name.current} className={styles[line.operation ?? 'none']}>
                <td aria-label={line.operation ?? 'none'} className={styles.operation}>
                  {line.operation && operationSymbols[line.operation]}
                </td>

                <td>{line.name.current}</td>
                <td>{line.vendorNames?.join('; ')}</td>
                <td>{line.ownerUserEmail}</td>
                <td>{line.departmentNames?.join('; ')}</td>
                <td>{line.accountCodes?.join('; ')}</td>

                {intervals.map((interval, i) => (
                  <td
                    key={interval.start}
                    className={styles[getDeltaClassName(line.planIntervals[i].amount)]}
                  >
                    {currencyFromMinorUnit(line.planIntervals[i].amount.current)}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>

          <tfoot>
            <tr className={styles.totals}>
              <td colSpan={info.length + 1}>Total</td>
              {intervals.map((interval, i) => (
                <td key={interval.start} className={styles[deltaClassName(i)]}>
                  {currencyFromMinorUnit(totalAllocatedPerInterval[i].amount.current)}
                </td>
              ))}
            </tr>
            <tr>
              <td colSpan={info.length + 1}>Available</td>
              {intervals.map((interval, i) => (
                <td key={interval.start}>
                  {currencyFromMinorUnit(totalPlannedPerInterval[i].amount.current)}
                </td>
              ))}
            </tr>
          </tfoot>

          <colgroup className={styles.operation}>
            <col />
          </colgroup>

          <colgroup className={styles.infoGroup}>
            {info.map(v => (
              <col key={v} />
            ))}
          </colgroup>

          <colgroup className={styles.periodGroup}>
            {intervals.map((period, i) => (
              <col key={period.name} className={styles[deltaClassName(i)]} />
            ))}
          </colgroup>
        </table>
      </div>

      {showSettings && (
        <div className={styles.makeActive}>
          <Field
            checked={active}
            label="Make this the active forecast"
            name="make-forecast-active"
            type="checkbox"
            onChange={toggleActive}
          />
          <p className="text-secondary font-caption" style={{ marginTop: -12 }}>
            Selecting an active forecast will replace the existing budget or forecast for all edit
            functionality and spend tracking for everyone in your organization. The active forecast
            can be changed at Settings {'>'} Forecast.
          </p>
        </div>
      )}

      <div className={cx('row align-center right', styles.actions)}>
        <Button small name="Cancel upload" variant="text" onClick={onCancel}>
          Back
        </Button>
        <Button
          name="upload-forecast"
          onClick={() => {
            // TODO: In the future, we might allow the user to edit the lines
            // and we would be passing a new lines array with changes ;)
            onSubmit({ lines, makeActive: active });
          }}
        >
          Upload
        </Button>
      </div>
    </div>
  );
};
ForecastUploaderPreview.displayName = 'ForecastUploaderPreview';
