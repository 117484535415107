import * as React from 'react';

import { cx, isDefined } from '@cobbler-io/utils/src';

import { clamp } from 'ramda';

import { DonutChart } from '../DonutChart';

import styles from './DonutChartData.scss';

type DonutChartDatum = { label: string; value: string; percentage?: number; key?: string };

type DonutChartDataProps = {
  data: DonutChartDatum[];
  percentage: number;
  colors: {
    main: string;
    secondary: string;
  };
  className?: string;
  margin?: { top: number; right: number; bottom: number; left: number };
  chartSize?: { width: number; height: number; donutThickness: number };
};
const defaultMargin = { top: 0, right: 10, bottom: 0, left: 0 };
const defaultChartSize = { width: 40, height: 40, donutThickness: 4 };

const clampTo200 = clamp(0, 200);

export const DonutChartData = ({
  colors,
  data,
  percentage,
  className,
  margin = defaultMargin,
  chartSize = defaultChartSize,
}: DonutChartDataProps) => {
  return (
    <div className={cx(styles.container, className)}>
      <div className={styles.chart}>
        <DonutChart
          colors={colors}
          donutThickness={chartSize.donutThickness}
          height={chartSize.height}
          margin={margin}
          percentage={clampTo200(percentage)}
          width={chartSize.width}
        />
      </div>
      <div className={styles.data}>
        {data.map(({ label, value, percentage: dataPercentage, key = label }) => (
          <div key={key} className={styles.row}>
            <span className={styles.value}>{value}</span>
            <span className={styles.label}>&nbsp;{label}</span>
            {isDefined(dataPercentage) && isFinite(dataPercentage!) && (
              <span className={styles.percentage}>&nbsp;({dataPercentage}%)</span>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

DonutChartData.displayName = 'DonutChartData';

export default DonutChartData;
