import * as React from 'react';

import { cx } from '@cobbler-io/utils/src/cx';
import { getBy } from '@cobbler-io/utils/src/getBy';

import { AppBar } from '@cobbler-io/core-ui/src/AppBar';
import { CssVar } from '@cobbler-io/core-ui/src/CssVar';
import { SmallOnly } from '@cobbler-io/core-ui/src/SmallOnly';

import { actions } from '@cobbler-io/redux/src/modules';

import { Nav } from '@cobbler-io/app/src/components/Nav';
import { Routes } from '@cobbler-io/app/src/Routes';

import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { LogoutButton } from '../oidc';

import styles from './App.scss';

const mapStateToProps = (state: any) => getBy(state, ['verticalNav', 'active'], false);

// const appBackground = [{ name: 'app-background-color', value: '#ffffff' }];

export const App = (): JSX.Element => {
  const sidePanelActive = useSelector(mapStateToProps, shallowEqual);
  const dispatch = useDispatch();
  const togglePanel = React.useCallback(() => dispatch(actions.verticalNav.toggle()), [dispatch]);

  return (
    <div className={styles.root}>
      <SmallOnly>
        <AppBar sidePanelActive={sidePanelActive} toggleSidePanel={togglePanel}>
          <LogoutButton />
        </AppBar>
      </SmallOnly>
      {/* <CssVar values={appBackground} /> */}
      <Nav active={sidePanelActive} toggle={togglePanel} />
      <div className={cx(styles.body, sidePanelActive && styles.push)}>
        <Routes className={styles.content} />
      </div>
    </div>
  );
};

App.displayName = 'App';

export default App;
