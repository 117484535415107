import * as React from 'react';

import { createNamedContext } from '@cobbler-io/utils/src/createNamedContext';

import {
  BudgetLineDiscussionsQuery, useBudgetLineDiscussionsQuery,
} from '@cobbler-io/app/src/api/graphql-types';

export type BudgetLineDetailsProviderProps = {
  budgetLineId: BudgetLineId;
  budgetLineName: string;
  children: JSX.Element;
};

type Discussion = BudgetLineDiscussionsQuery['budgetLineDiscussions'][number];

type BudgetLineDetailsContextType = {
  budgetLineId: BudgetLineId;
  budgetLineName: string;
  loading: boolean;
  error: boolean;
  discussions: readonly Discussion[];
  refetch: () => Promise<any>;
};

const BudgetLineDetailsContext = createNamedContext<BudgetLineDetailsContextType | null>(
  'BudgetLineDetailsContext',
  null,
);

export const BudgetLineDetailsProvider = ({
  budgetLineId,
  budgetLineName,
  ...props
}: BudgetLineDetailsProviderProps) => {
  const variables = { id: budgetLineId };
  const { data, error, loading, refetch } = useBudgetLineDiscussionsQuery({ variables });

  React.useEffect(() => {
    void refetch(variables);
    // This is fine, more targetted but fine.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch, variables.id]);

  const value = React.useMemo(() => {
    const discussions = data?.budgetLineDiscussions ?? [];
    return {
      budgetLineId,
      budgetLineName,
      discussions,
      error: Boolean(error) || !data?.budgetLineDiscussions,
      loading,
      refetch,
    };
  }, [budgetLineId, budgetLineName, loading, error, data?.budgetLineDiscussions, refetch]);

  return <BudgetLineDetailsContext.Provider value={value} {...props} />;
};

BudgetLineDetailsProvider.displayName = 'BudgetLineDetailsProvider';

export const useBudgetLineDetails = (): BudgetLineDetailsContextType => {
  const context = React.useContext(BudgetLineDetailsContext);
  if (!context) {
    throw new Error('useBudgetLineDetails must be used within BudgetLineDetailsProvider');
  }
  return context;
};
