/**
 * Gets the last item in an array
 */
export const tail = <T extends any[] | readonly any[]>(
  arr: T,
): T extends [...any[], infer R]
  ? R
  : T extends readonly [...any[], infer R]
  ? R
  : T extends (infer U)[]
  ? U
  : T extends readonly (infer U)[]
  ? U
  : never => arr[arr.length - 1];
