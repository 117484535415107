import * as React from 'react';

import { Link } from '@reach/router';

import { MessageTemplateParameter } from '../ParameterizedMessage';

export const ActualDiscussionToken = ({
  resourceId,
  displayValue,
}: MessageTemplateParameter): JSX.Element =>
  !resourceId ? (
    <>{displayValue}</>
  ) : (
    <Link to={`/actuals/${resourceId}/discussions`}>{displayValue}</Link>
  );

ActualDiscussionToken.displayName = 'ActualDiscussionToken';
