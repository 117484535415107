import { isBase64Encoded } from '@cobbler-io/utils/src/isBase64Encoded';
import { isGuid } from '@cobbler-io/utils/src/isGuid';

export type DestructedRelayId =
  | readonly [type: string, id: string]
  | readonly [type: null, id: null];

const nullTuple = (): readonly [type: null, id: null] => [null, null];

export const destructureRelayId = (relayId?: string): DestructedRelayId => {
  if (!relayId || !isBase64Encoded(relayId)) {
    return nullTuple();
  }

  // `atob` seems to throw a lot of errors if things aren't perfect,
  // so we'll actually throw a try/catch to avoid major problems
  /* eslint-disable functional/no-try-statement */
  try {
    const [type, id, ...rest] = window.atob(relayId).split(':');

    // The relay id should only be type:id, so if anything else if left, we have a problem
    if (rest.length) {
      return nullTuple();
    }

    return isGuid(id) ? [type, id] : nullTuple();
  } catch (e: unknown) {
    console.error(e);
    return nullTuple();
  }
  /* eslint-enable functional/no-try-statement */
};

const cache: Map<string, string | null> = new Map();
export const extractGuidFromRelayId = (relayId?: string): string | null => {
  if (!relayId) {
    return null;
  }

  if (cache.has(relayId)) {
    return cache.get(relayId)!;
  }

  const [, id] = destructureRelayId(relayId);

  cache.set(relayId, id);

  return id;
};
