import * as React from 'react';

import { Dialog, DialogProps } from '@cobbler-io/core-ui/src/Dialog';

import styles from './OverplannedDialog.scss';

export type OverplannedDialogProps = Omit<DialogProps, 'children'> & {
  isForecast?: boolean;
};

export const OverplannedDialog = ({
  isForecast = false,
  onConfirm,
  ...props
}: OverplannedDialogProps): JSX.Element => {
  return (
    <Dialog onConfirm={onConfirm} {...props} className={styles.overplannedDialog}>
      You are proposing an increase to your allocated {isForecast ? 'forecast' : 'budget'}. Are you
      sure?
    </Dialog>
  );
};

OverplannedDialog.displayName = 'OverplannedDialog';
