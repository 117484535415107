/* eslint-disable max-lines-per-function */
import * as React from 'react';

import { head } from '@cobbler-io/utils/src/array/head';
import { rando } from '@cobbler-io/utils/src/rando';

import { Button } from '@cobbler-io/core-ui/src/Button';
import { Heading } from '@cobbler-io/core-ui/src/Heading';
import { useCurrentModal } from '@cobbler-io/core-ui/src/Modal';
import { useNotification } from '@cobbler-io/core-ui/src/Notification';

import { useCurrencyFormatter } from '@cobbler-io/redux/src/modules/currency';
import { useSelectedBudgetRevision } from '@cobbler-io/redux/src/modules/current-budget';

import { extractGraphQLErrors, FormAPIError } from '@cobbler-io/app/src/api/extractGraphQLErrors';
import {
  AddBudgetLineMutation,
  BudgetLineEditorDataDocument,
  BudgetLineEditorDataQuery,
  BudgetLineTypeEnum,
  PlannedSpendInputType,
  useAddBudgetLineMutation,
} from '@cobbler-io/app/src/api/graphql-types';
import { Loading } from '@cobbler-io/app/src/components';
import { useCurrentBudgetLine } from '@cobbler-io/app/src/providers/CurrentBudgetLineProvider';

import { MutationUpdaterFn } from 'apollo-client';
import { append, indexBy, lensPath, over, prop } from 'ramda';

import { BudgetLineForm } from '../BudgetLineForm';
import { BudgetLineType, EditorLine } from '../types';
import { useCurrentEditorData } from '../useCurrentEditorData';
import { extractSpendPlansFromExpenseSchedule, UpdateBudgetFormValues } from '../utils';

import styles from './AddChildBudgetLineForm.scss';

type AddChildBudgetPlanFormProps = {
  addAnother: Record<string, unknown>;
};

type AddFormValues = {
  name: string;
  type: BudgetLineTypeEnum;
  ownerUserId: UserId | null;
  accountIds: AccountId[];
  departmentIds: DepartmentId[];
  vendors: string[];
} & UpdateBudgetFormValues;

const FORM_NAME = 'add-child-budget-line';
const linesLens = lensPath<BudgetLineEditorDataQuery>(['a_budgetLineChildrenEditorData', 'lines']);
const isTempId = (id: BudgetLineId) => id.length < 12; // Super simple but effective...

export const AddChildBudgetLineForm = (props: AddChildBudgetPlanFormProps): JSX.Element => {
  const { addAnother } = props;
  const notify = useNotification();
  const { close } = useCurrentModal();
  const { convertToMinorUnit } = useCurrencyFormatter();
  const [formErrors, setFormErrors] = React.useState<FormAPIError>(null);
  const willAddAnother = React.useRef<boolean>(false);

  const selectedRevision = useSelectedBudgetRevision();
  const budgetLineContext = useCurrentBudgetLine();
  const id = budgetLineContext?.id;

  const { data, loading, error, mappedData: parent, refetch, variables } = useCurrentEditorData();

  const [mutate, { loading: isSubmitting }] = useAddBudgetLineMutation();

  if (loading) {
    return <Loading />;
  }

  if (error || !id || !data?.a_budgetLineChildrenEditorData || !selectedRevision || !parent) {
    if (error) {
      // eslint-disable-next-line no-console
      console.error('Error caught', error);
    }
    return <>Error</>;
  }

  const maxWeight = [...(data?.a_budgetLineChildrenEditorData.lines ?? [])].reduce((acc, line) => {
    if (line.linkedToDriver) {
      return acc;
    }

    return Math.max(acc, line.weight);
  }, 0);

  const successNotification = (name: string) =>
    notify({ body: `Successfully added "${name}"`, title: 'Added Budget Line' });

  const buildOptimisticResponse = (
    values: AddFormValues,
    plannedSpendInput: PlannedSpendInputType[],
    lineType: BudgetLineTypeEnum,
  ): AddBudgetLineMutation => ({
    __typename: 'Mutation',
    createdBudgetLine: {
      __typename: 'BudgetLinePayloadType',

      // TODO: Get this from cached GetAllUsersQuery
      accounts: [],

      // TODO: Get this from cached GetAllVendorsQuery
      departments: [],

      id: rando(),

      isRevenue: lineType === BudgetLineType.REVENUE,

      name: values.name,

      owner: null,

      // TODO: Get this from cached GetAccountsQuery
      plannedSpend: plannedSpendInput.map(p => ({ __typename: 'PlannedSpendType', ...p })),

      type: lineType,
      vendors: [],
      weight: maxWeight + 10,
    },
  });

  const update: MutationUpdaterFn<AddBudgetLineMutation> = (cache, mutationResponse) => {
    const query = BudgetLineEditorDataDocument;

    // We want to update the cache for the BudgetLineEditorData query, so we
    // get the cached data, modify it and write it back
    const cachedData = cache.readQuery<BudgetLineEditorDataQuery>({ query, variables });
    if (!cachedData) {
      // eslint-disable-next-line no-console
      console.warn('BudgetLineEditorDataQuery cache not found.');
      return;
    }

    if (!mutationResponse.data) {
      // eslint-disable-next-line no-console
      console.warn('Invalid response payload.');
      return;
    }

    const { createdBudgetLine } = mutationResponse.data;
    const { departments } = createdBudgetLine;
    const plannedByInterval = indexBy(prop('start'), createdBudgetLine.plannedSpend);
    const isOptimistic = isTempId(createdBudgetLine.id);
    const newLine: EditorLine = {
      __typename: 'BudgetEditorLineType',
      accounts: createdBudgetLine.accounts,
      ancestors: [],
      canUpdate: !isOptimistic,
      childrenCount: 0,
      departments,
      id: createdBudgetLine.id,
      isRevenue: createdBudgetLine.isRevenue,
      linkedToDriver: false,
      name: `${createdBudgetLine.name}${isOptimistic ? ' (loading...)' : ''}`,
      owner: {
        __typename: 'UserReference',
        id: createdBudgetLine.owner?.id ?? null,
        item: createdBudgetLine.owner,
      },
      permissions: {
        __typename: 'ResourcePermissionType',
        canDelete: false,
        canRead: false,
        canUpdate: false,
        canUpdatePermissions: false,
      },
      plannedSpend: parent.intervals.map(interval => ({
        __typename: 'BudgetEditorPlannedSpendType',
        budgetLineId: createdBudgetLine.id,
        planned: plannedByInterval[interval.start]?.planned ?? 0,
        plannedRevenue: plannedByInterval[interval.start]?.plannedRevenue ?? 0,
        revisionId: selectedRevision.id,
        spent: 0,
        spentShallow: 0,
        start: interval.start,
      })),
      type: createdBudgetLine.type,
      vendors: createdBudgetLine.vendors,
      weight: createdBudgetLine.weight,
    };

    cache.writeQuery({
      data: over(linesLens, append(newLine), cachedData),
      query,
      variables,
    });
  };

  const handleSubmit = async (values: AddFormValues) => {
    const plannedSpend: PlannedSpendInputType[] = extractSpendPlansFromExpenseSchedule(
      parent,
      values,
    ).map(p => ({
      planned: convertToMinorUnit(p.amount),
      start: p.start,
    }));

    const lineType = parent.isRoot ? values.type : parent.type;

    const submission = mutate({
      optimisticResponse: buildOptimisticResponse(values, plannedSpend, lineType),
      update,
      variables: {
        accountIds: values.accountIds,
        departmentIds: values.departmentIds,
        name: values.name,
        ownerUserId: values.ownerUserId,
        parentId: id,
        plannedSpend,
        revisionId: selectedRevision.id,
        type: lineType,
        vendors: values.vendors,
        weight: maxWeight + 10,
      },
    })
      .then(() => {
        successNotification(values.name);

        if (willAddAnother.current) {
          willAddAnother.current = false;
          queueMicrotask(() => addAnother());
        }

        close();
      })
      .catch(err => {
        console.error('Error adding a new budget line', err);
        const gqlError = head(extractGraphQLErrors(err));
        setFormErrors(
          gqlError || {
            code: 500,
            message:
              'There was an error getting a response from the server. Please retry or refresh the page.',
          },
        );
        // Since this is an unknown error (500), there's a chance the data was successfully saved
        // but the server failed to deliver the response. We trigger a refetch to update the data
        // grid below the modal in case there's something new.
        void refetch(variables);
      });

    return submission;
  };

  return (
    <div className={styles.addBudgetLineModal}>
      <Heading size="title">Add Budget Line</Heading>

      <BudgetLineForm
        defaultValues={{}}
        formErrors={formErrors}
        name={FORM_NAME}
        parent={parent}
        onSubmit={handleSubmit}
      />

      <div className="button-container right">
        <Button name="cancel-add-line" variant="outline" onClick={() => close()}>
          Cancel
        </Button>
        <Button
          disabled={isSubmitting}
          form={FORM_NAME}
          name="save-line-and-add-another"
          type="submit"
          variant="outline"
          onClick={() => {
            willAddAnother.current = true;
          }}
        >
          Save and Add Another
        </Button>
        <Button disabled={isSubmitting} form={FORM_NAME} name="save-line" type="submit">
          Save
        </Button>
      </div>
    </div>
  );
};
AddChildBudgetLineForm.displayName = 'AddChildBudgetLineForm';
