/* eslint-disable sort-keys, sort-keys-fix/sort-keys-fix */
import { Graph } from '@cobbler-io/collection/src/Graph';

import { HydrateBudgetGraphQuery } from '../graphql-types';
import { createRangeProperties } from './createRangeProperties';
import { hasAccountId, hasDepartmentId, hasPlanningVersionId, hasVendorId } from './utils';

type Data = NonNullable<HydrateBudgetGraphQuery['planningVersions']>;

type HydratePlanningVersionsParams = {
  graph: Graph<any, any>;
  data: Data;
};

const planningVersionLabels = ['PLANNING_VERSION'];

const hasPlanningVersionLabels = ['HAS_PLANNING_VERSION'];

const hasAccountLabels = ['HAS_ACCOUNT'];
const hasDepartmentLabels = ['HAS_DEPARTMENT'];
const hasVendorLabels = ['HAS_VENDOR'];

export const hydratePlanningVersions = (params: HydratePlanningVersionsParams): void => {
  const { data, graph } = params;

  for (const planningVersion of data) {
    const version = graph.addVertex({
      id: planningVersion.id,
      labels: planningVersionLabels,
      properties: { start: planningVersion.start, end: planningVersion.end },
    });

    const line = graph.getVertexById(planningVersion.budgetLineId);

    // A side effect is the the `LocalDate` and `DateRange` will be shared between them
    const properties = createRangeProperties(planningVersion.start, planningVersion.end);

    // Connect the planning version to the BudgetLine
    graph.addEdge({
      id: hasPlanningVersionId(planningVersion.id, line.id),
      labels: hasPlanningVersionLabels,
      from: line,
      to: version,
      properties,
    });

    // Next, we need to connect all the accounts with the budget lines
    for (const accountId of planningVersion.accountIds) {
      if (!graph.hasVertex(accountId)) {
        console.error('Skipping account with id', accountId);
        continue; // eslint-disable-line no-continue
      }

      const account = graph.getVertexById(accountId)!;

      // Add an edge between the planning version and the account
      graph.addEdge({
        id: hasAccountId(accountId, version.id),
        labels: hasAccountLabels,
        from: version,
        to: account,
        properties,
      });

      // Add an edge between the line and the account
      graph.addEdge({
        id: hasAccountId(accountId, line.id),
        labels: hasAccountLabels,
        from: line,
        to: account,
        properties,
      });
    }

    // Now we connect all the departments with the budget lines
    for (const departmentId of planningVersion.departmentIds) {
      if (!graph.hasVertex(departmentId)) {
        console.error('Skipping department with id', departmentId);
        continue; // eslint-disable-line no-continue
      }

      const department = graph.getVertexById(departmentId)!;

      // Add an edge between the planning version and the department
      graph.addEdge({
        id: hasDepartmentId(departmentId, planningVersion.id),
        labels: hasDepartmentLabels,
        from: version,
        to: department,
        properties,
      });

      // Add an edge between the line and the department
      graph.addEdge({
        id: hasDepartmentId(departmentId, line.id),
        labels: hasDepartmentLabels,
        from: line,
        to: department,
        properties,
      });
    }

    // Now we connect the vendors
    for (const vendorId of planningVersion.vendorIds) {
      if (!graph.hasVertex(vendorId)) {
        console.error('Skipping vendor with id', vendorId);
        continue; // eslint-disable-line no-continue
      }

      const vendor = graph.getVertexById(vendorId)!;

      // Add an edge between the planning version and the vendor
      graph.addEdge({
        id: hasVendorId(vendorId, planningVersion.id),
        labels: hasVendorLabels,
        from: version,
        to: vendor,
        properties,
      });

      // Add an edge between the line and the vendor
      graph.addEdge({
        id: hasVendorId(vendorId, line.id),
        labels: hasVendorLabels,
        from: line,
        to: vendor,
        properties,
      });
    }
  }
};
