import * as React from 'react';

export type StringSegmentProps = { key?: string; children: string };

export const StringSegment = React.forwardRef(
  ({ key, children }: StringSegmentProps, ref: React.RefObject<HTMLSpanElement>) => (
    <span key={key || children} ref={ref}>
      {children}
    </span>
  ),
);

StringSegment.displayName = 'StringSegment';
