export type FieldParams = {
  start: ISO8601String;
  name: string;
  initialValue: MinorCurrency;
};

export type FieldValue = {
  start: ISO8601String;
  name: string;
  label: string;
  current: MinorCurrency;
  default: MinorCurrency;
  delta: () => MinorCurrency;
};

export const createFieldValue = (params: FieldParams): FieldValue => ({
  /**
   * A unique key to iterate over the field
   */
  start: params.start,
  /**
   * Something unique that can be used for the `name` attribute on an input field
   */
  name: params.start,
  /**
   * A label to label the field
   */
  label: params.name,
  /**
   * The default value of the field
   */
  default: params.initialValue,
  /**
   * The current value of the field (this includes user changes that have yet to be saved)
   */
  current: params.initialValue,
  /**
   * A function that computes difference between the current the the default values
   */
  delta(): MinorCurrency {
    return this.default - this.current;
  },
});
