import * as React from 'react';

import { Dialog, DialogProps } from '../Dialog';

export type AlertProps = Omit<DialogProps, 'onCancel'> & {
  onConfirm: (event?: React.MouseEvent<any>) => void;
};

export const Alert = ({ children, className, onConfirm, ...props }: AlertProps): JSX.Element => {
  return (
    <Dialog {...props} onCancel={null} onConfirm={onConfirm}>
      {children}
    </Dialog>
  );
};

/* eslint-enable react/destructuring-assignment */

Alert.displayName = 'Alert';

export default Alert;
