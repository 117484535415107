import { execIfFunc } from '@cobbler-io/utils/src';
import { addItemToSet, removeItemFromSet } from '@cobbler-io/utils/src/immutable-set';

export const permissionsTypes: readonly PermissionName[] = [
  'geolocation',
  'notifications',
  'push',
  'midi',
  'camera', // no FF
  'microphone', // no FF
  'speaker',
  'device-info',
  // "background-fetch", // not fully supported
  'background-sync',
  'bluetooth',
  'persistent-storage',
  'ambient-light-sensor',
  'accelerometer',
  'gyroscope',
  'magnetometer',
  'clipboard',
  // "display-capture", // not fully supported
  // "nfc", // not fully supported
];

// Safari has not implemented support for changeListeners on permission status, so we'll do a
// feature test on the module level.
let permissionsChangeListenerSupported = false;
(async () => {
  if (typeof navigator?.permissions?.query !== 'function') {
    return;
  }

  // This is an arbitrary permission that is widely supported to check to see if 'addEventListener'
  // is in the resulting PermissionStatus
  if ('addEventListener' in (await navigator.permissions.query({ name: 'geolocation' }))) {
    permissionsChangeListenerSupported = true;
  }
})();

type PermissionChangeHandler = (state: PermissionState) => any;

export class PermissionTracker {
  map: Map<PermissionName, PermissionStatus>;

  listeners: Map<PermissionName, Set<(state: PermissionState) => any>>;

  constructor() {
    this.map = new Map();
    this.listeners = new Map();
  }

  /**
   * If listeners aren't supported, then we need to update this each time we query
   */
  query(
    name: PermissionName,
    callback?: PermissionChangeHandler,
  ): PermissionState | 'UNSUPPORTED' | Promise<PermissionState | 'UNSUPPORTED'> {
    const status = this.map.get(name);

    if (
      typeof navigator?.permissions?.query !== 'function' ||
      typeof PermissionStatus === 'undefined'
    ) {
      return 'UNSUPPORTED';
    }

    if (status instanceof PermissionStatus) {
      if (!permissionsChangeListenerSupported) {
        // Listeners on PermissionStatus objects are not supported, so we will change this operation
        // so that we have a cache-and-validate approach to the query method
        navigator.permissions
          .query({ name })
          .then(permission => {
            if (permission.state !== status.state) {
              // If the previous state is not the same as the new query, then we'll update our map
              // internally, call the callback, and then notify all the listeners
              this.map.set(name, permission);
              execIfFunc(callback, permission.state);
              this.handlePermissionChange(name);
            }
          })
          .catch(async err => {
            if (err instanceof TypeError) {
              console.error(err); // eslint-disable-line
            }

            return Promise.resolve('UNSUPPORTED');
          });
      }
      return status.state;
    }

    return navigator.permissions
      .query({ name })
      .then(permission => {
        this.map.set(name, permission);
        this.addListenerToStatus(name, permission);
        execIfFunc(callback, permission.state);
        return permission.state;
      })
      .catch(async _ => {
        return Promise.resolve('UNSUPPORTED');
      });
  }

  listen = (name: PermissionName, changeListener: PermissionChangeHandler) => {
    // Grab the curent status
    const status = this.map.get(name);
    // Add the change listener to listener set
    this.listeners.set(name, addItemToSet(this.listeners.get(name) ?? new Set(), changeListener));

    if (status) {
      // If we already have the status, then go ahead and run the callback
      changeListener(status.state);
    } else {
      // We don't have the status, so query and run the callback
      this.query(name, changeListener);
    }

    // Return an unsubscribe function
    return () => {
      this.listeners.set(
        name,
        removeItemFromSet(this.listeners.get(name) ?? new Set(), changeListener),
      );
    };
  };

  addListenerToStatus(name: PermissionName, status: PermissionStatus) {
    if (permissionsChangeListenerSupported) {
      status.addEventListener('change', this.handlePermissionChange.bind(this, name));
    }
  }

  handlePermissionChange(name: PermissionName) {
    const status = this.map.get(name);
    const listeners = this.listeners.get(name);

    if (!status || !listeners) {
      // womp
      return;
    }

    listeners.forEach(listener => listener(status.state));
  }
}
