import * as React from 'react';

import { percentage } from '@cobbler-io/formatters/src/numbers';

import { useCurrencyFormatter } from '@cobbler-io/redux/src/modules/currency';

import {
  NormalizedActualSlice,
} from '@cobbler-io/app/src/ndm/screens/ActualsList/normalizeSplitActual';
import { useCurrentBudgetLine } from '@cobbler-io/app/src/providers/CurrentBudgetLineProvider';

import { Link } from '@reach/router';

import styles from './ActualSlice.scss';

export type ActualSliceProps = NormalizedActualSlice;

/**
 * A linked representation of an actual slice
 */
export const ActualSlice = (props: ActualSliceProps): JSX.Element => {
  const { id, budgetLine, amount, originalAmount } = props;
  const budgetLineContext = useCurrentBudgetLine();
  const { urls } = budgetLineContext!;
  const { currencyFromMinorUnit } = useCurrencyFormatter();

  if (!budgetLine || !budgetLineContext) {
    // @ts-expect-error: this is fine for React
    return null;
  }

  return (
    <Link
      className={styles.actualSlice}
      to={urls.actualDetails({ budgetId: budgetLine.id, actualId: id })}
    >
      <div className={styles.budgetName}>{budgetLine.name}</div>
      <div className={styles.budgetFullName}>{budgetLine.fullName}</div>
      <div className={styles.amountRow}>
        <div className={styles.amount}>{percentage(amount / originalAmount, 2)}</div>
        <div className={styles.amount}>{currencyFromMinorUnit(amount)}</div>
      </div>
    </Link>
  );
};
ActualSlice.displayName = 'ActualSlice';

/**
 * Basically the same as ActualSlice but shows `originalAmount` instead
 */
export const OriginalActual = (props: ActualSliceProps): JSX.Element => {
  const { id, budgetLine, originalAmount } = props;
  const budgetLineContext = useCurrentBudgetLine();
  const { urls } = budgetLineContext!;
  const { currencyFromMinorUnit } = useCurrencyFormatter();

  if (!budgetLine) {
    // @ts-expect-error: this is fine for React
    return null;
  }

  return (
    <Link
      className={styles.actualSlice}
      to={urls.actualDetails({ budgetId: budgetLine.id, actualId: id })}
    >
      <div className={styles.budgetName}>{budgetLine.name}</div>
      <div className={styles.budgetFullName}>{budgetLine.fullName}</div>
      <div className={styles.amountRow}>
        <div className={styles.amount}>{currencyFromMinorUnit(originalAmount)}</div>
      </div>
    </Link>
  );
};
OriginalActual.displayName = 'OriginalActual';

/**
 * A simple version of a slice. Only shows the matched budget. No amounts. Non-clickable.
 */
export const ActualLine = (props: ActualSliceProps): JSX.Element => {
  const { budgetLine } = props;

  if (!budgetLine) {
    // @ts-expect-error: this is fine for React
    return null;
  }

  return (
    <div className={styles.actualSlice}>
      <div className={styles.budgetName}>{budgetLine.name}</div>
      <div className={styles.budgetFullName}>{budgetLine.fullName}</div>
    </div>
  );
};
ActualLine.displayName = 'ActualLine';
