import * as React from 'react';

import { cx } from '@cobbler-io/utils/src/cx';

import { Button, Props as ButtonProps } from '../Button';
import { Icon } from '../Icon';

import styles from './Hamburger.scss';

type Props = {
  active: boolean;
  name?: string;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  color?: string;
} & Omit<ButtonProps, 'type' | 'variant' | 'name' | 'ref' | 'children'>;

export const Hamburger = ({
  active = false,
  color = 'currentColor',
  className,
  ...props
}: Props): JSX.Element => {
  return (
    <Button
      name="hamburger menu"
      {...props}
      className={cx(styles.hamburger, active && styles.active, className)}
      variant="svg"
    >
      <Icon
        color={color}
        desc="controls side panel"
        id="hamburger-menu"
        role="img"
        title="hamburger menu"
        type="hamburger"
      />
    </Button>
  );
};

Hamburger.displayName = 'Hamburger';

export default Hamburger;
