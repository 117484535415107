import * as React from 'react';

import { cx } from '@cobbler-io/utils/src/cx';
import { clamp } from '@cobbler-io/utils/src/numbers/clamp';

import styles from './ProgressBar.scss';

const getProgress = (value: number, max = 0): number => {
  if (value === 0 && max === 0) {
    return 100;
  }
  // This is not truly correct
  if (max === 0) {
    return 100;
  }
  return (value / max) * 100;
};

export type ProgressBarProps = {
  min?: number;
  max: number;
  value: number;
  className?: string;
  format?: (x: number) => string;
  hideText?: boolean;

  thick?: boolean;
  small?: boolean;
  large?: boolean;

  /**
   * variant for showing the difference instead of the max value
   * for the second number.
   */
  delta?: boolean;

  /**
   * variant for showing the tracking percent allocated value below the bar, and optional suffix
   * to attach to the tracking indicator.
   */
  percent?: boolean;
  percentSuffix?: string;

  /**
   * Puts the total on the left and the max on the right
   */
  separate?: boolean;

  style?: React.CSSProperties;

  /**
   * Optional suffixes to attach to current value and max value
   */
  valueSuffix?: string;
  maxValueSuffix?: string;

  color?: string;

  backgroundColor?: string;
};

const emptyStyle: React.CSSProperties = {};

const defaultFormat = (x: number) => String(x);

/**
 * @todo figure out ARIA cues that will make assistive devices not clamp this sort of thing at 100%
 */
export const ProgressBar = (props: ProgressBarProps): JSX.Element => {
  const {
    className,
    max,
    value,
    separate,
    hideText,
    thick,
    large,
    delta,
    percent,
    percentSuffix,
    format = defaultFormat,
    color,
    backgroundColor,
    style = emptyStyle,
    valueSuffix,
    maxValueSuffix,
    ...rest
  } = props;
  const progress = getProgress(value, max);
  const barStyle = React.useMemo(
    () =>
      // eslint-disable-next-line prefer-object-spread
      Object.assign(
        {},
        { width: `${clamp({ min: 0, max: 100 }, progress)}%` },
        color && { backgroundColor: color },
      ),
    [progress, color],
  );

  const endValue = delta ? max - value : max;

  // Hard coding the "maxSuffix" here isn't the best, but it's fine for now
  const title = delta ? `${format(max)} total` : `${format(max - value)} remaining`;

  return (
    <div {...rest} className={className} style={style} title={title}>
      {!hideText && (
        <div className={styles.numbers}>
          <span>
            {format(value)} {valueSuffix}
          </span>{' '}
          {!separate && (
            <span>
              {format(endValue)} {maxValueSuffix}
            </span>
          )}
        </div>
      )}
      <div
        className={cx(
          styles.progressBar,
          thick && styles.thick,
          large && styles.large,
          separate && styles.separate,
          value === 0 && endValue === 0 && styles.grayscale,
        )}
        style={{ backgroundColor }}
      >
        <div
          className={cx(styles.progressBarInner, progress > 100 && styles.over)}
          style={barStyle}
        />
      </div>
      {separate && (
        <div>
          {format(endValue)} {maxValueSuffix}
        </div>
      )}
      {percent && (
        <div className={styles.trackingIndicator}>
          {Math.floor(progress)}% {percentSuffix}
        </div>
      )}
    </div>
  );
};

ProgressBar.displayName = 'ProgressBar';

export default ProgressBar;
