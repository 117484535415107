import * as React from 'react';

import {
  useBudgetRevisions, useCurrentBudgetId,
} from '@cobbler-io/redux/src/modules/current-budget';
import {
  UpdateRevisionsPayload,
} from '@cobbler-io/redux/src/modules/current-budget/current-budget';
import { Revisions } from '@cobbler-io/redux/src/modules/current-budget/types';

import {
  GetRevisionsForBudgetDocument, GetRevisionsForBudgetQuery, GetRevisionsForBudgetQueryVariables,
} from '@cobbler-io/app/src/api/graphql-types';

import { useApolloClient } from '@apollo/react-hooks';

/**
 * Updates the revision information from the API and re-seeds the redux store
 *
 * This takes a network call, so avoid it if possible
 */

export const useRefreshRevisions = (): NullaryFn<Promise<Revisions>> => {
  const [revisions, { update }] = useBudgetRevisions();
  const budgetId = useCurrentBudgetId()!;

  const client = useApolloClient();

  return React.useCallback(
    async () =>
      new Promise<Revisions>((resolve, reject) => {
        client
          .query<GetRevisionsForBudgetQuery, GetRevisionsForBudgetQueryVariables>({
            query: GetRevisionsForBudgetDocument,
            variables: { id: budgetId },
            fetchPolicy: 'network-only',
          })
          .then(res => {
            if (res?.data?.budget) {
              update(res.data.budget as unknown as UpdateRevisionsPayload);
              queueMicrotask(() => resolve(revisions!));
            } else {
              reject('Could not load data');
            }
          })
          .catch(() => {
            reject('Network error. Could not refresh revisions');
          });
      }),
    [budgetId, client, revisions, update],
  );
};
