import * as React from 'react';

import { cx } from '@cobbler-io/utils/src/cx';

import { RootState } from '@cobbler-io/types';

import { useSelector } from 'react-redux';

import { Hamburger } from '../Hamburger';
import { Heading } from '../Heading';

import styles from './AppBar.scss';

type AppBarProps = {
  sidePanelActive: boolean;
  toggleSidePanel: () => void;
  className?: string;
  children?: React.ReactNode;
};

export const AppBar = (props: AppBarProps): JSX.Element => {
  const title = useSelector<RootState, string>(state => state.page.title);
  const { toggleSidePanel: toggle, sidePanelActive: active, className, children } = props;
  return (
    <header
      className={cx(
        'grid',
        'row',
        'justify-space-between',
        'align-center',
        styles.appBar,
        'fade-in',
        className,
      )}
    >
      <Hamburger small active={active} className={cx('col', styles.hamburger)} onClick={toggle} />
      <Heading as="h1" className={styles.headline} size="headline" weight="bold">
        {title}
      </Heading>
      {children}
    </header>
  );
};

AppBar.displayName = 'AppBar';

export default AppBar;
