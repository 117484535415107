/* eslint-disable func-style */
import React, { MouseEvent } from 'react';

import { execIfFunc } from '@cobbler-io/utils/src';
import { createVirtualElement } from '@cobbler-io/utils/src/createVirtualElement';

import { CreateModal, ModalOptions, useChildModal } from '../Modal';
import { MenuProps } from './Menu';

export type UseFlyoutMenuOptions = {
  createModal?: CreateModal;
  placement?: ModalOptions['placement'];
};

type MenuComponent = React.ReactElement<MenuProps> | (() => React.ReactElement<MenuProps>);

export function useFlyoutMenu(): UnaryFn<MenuComponent, UnaryFn<MouseEvent<HTMLElement>, void>>;
export function useFlyoutMenu(
  menu: MenuComponent | null,
  options?: UseFlyoutMenuOptions,
): UnaryFn<MouseEvent<HTMLElement>, void>;
export function useFlyoutMenu(
  menu?: MenuComponent | null,
  options?: UseFlyoutMenuOptions,
):
  | UnaryFn<MenuComponent, UnaryFn<MouseEvent<HTMLElement>, void>>
  | UnaryFn<MouseEvent<HTMLElement>, void> {
  const hookCreateModal = useChildModal();
  const { createModal: optionsCreateModal } = options ?? {};

  // Prefer a "createModal" function passed in via options
  const create = optionsCreateModal ?? hookCreateModal;

  const handle = (m: MenuComponent, event: MouseEvent<HTMLElement>): void => {
    const { type, currentTarget, clientX, clientY } = event;
    const isRightClick = type === 'contextmenu';

    if (isRightClick) {
      event.preventDefault();
    }

    create(execIfFunc(m), {
      isSentinel: true,
      placement: options?.placement ?? isRightClick ? 'bottom-start' : 'bottom-end',
      target: isRightClick ? createVirtualElement(clientX, clientY) : currentTarget,
    });
  };

  if (menu) {
    return (event: MouseEvent<HTMLElement>) => handle(menu, event);
  }

  return (m: MenuComponent) => (event: MouseEvent<HTMLElement>) => handle(m, event);
}
