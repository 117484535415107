/* eslint-disable complexity */
import * as React from 'react';

import { cx } from '@cobbler-io/utils/src/cx';
import { isNullish } from '@cobbler-io/utils/src/isNullish';
import { flipSign } from '@cobbler-io/utils/src/numbers/flipSign';

import { date } from '@cobbler-io/formatters/src/dates';

import { Button } from '@cobbler-io/core-ui/src/Button';
import { Chip } from '@cobbler-io/core-ui/src/Chip';
import { useFeatureFlag } from '@cobbler-io/core-ui/src/FeatureFlag';
import { Heading } from '@cobbler-io/core-ui/src/Heading';
import Icon from '@cobbler-io/core-ui/src/Icon';
import { Modal, useChildModal } from '@cobbler-io/core-ui/src/Modal';

import { useCurrencyFormatter } from '@cobbler-io/redux/src/modules/currency';

/* eslint-disable max-lines-per-function */
import { useBudgetLinesTreeQuery } from '@cobbler-io/app/src/api/graphql-types';
import { useCurrentActual } from '@cobbler-io/app/src/providers/CurrentActualProvider';
import { useCurrentBudgetLine } from '@cobbler-io/app/src/providers/CurrentBudgetLineProvider';

import { ActualProperty } from '../ActualProperty';
import { MovedTransactionDetails } from '../MovedTransactionDetails';
import { MoveTransactionModal } from '../MoveTransactionModal';
import { normalizeSplitActual } from '../normalizeSplitActual';
import { SplitTransactionDetails } from '../SplitTransactionDetails';
import { SplitTransactionModal } from '../SplitTransactionModal';

import styles from './ActualDetailsInfo.scss';

// eslint-disable-next-line react/display-name
const Separator = () => <div className={styles.linebreak} />;

type PropertyTuple = [string, string | null | undefined | false];

const Group = (props: { data: PropertyTuple[] }): JSX.Element => {
  const { data } = props;
  const valid = data.filter(([_, value]) => !isNullish(value));

  if (!valid.length) {
    // @ts-expect-error: this is fine for react
    return null;
  }

  // @ts-expect-error: this is fine for react
  return valid
    .map(([label, value]) => <ActualProperty key={label} label={label} value={value} />)
    .concat(<Separator key="separator" />);
};

export type ActualDetailsInfoProps = {
  refetchList: () => Promise<any>;
  isRevenue: boolean;
};

export const ActualDetailsInfo = (props: ActualDetailsInfoProps) => {
  const { refetchList, isRevenue } = props;
  const { actual, error, loading, refetch } = useCurrentActual();
  const { currency, convertToMinorUnit } = useCurrencyFormatter();
  const budgetLineContext = useCurrentBudgetLine();
  const createModal = useChildModal();
  const IS_MAIN_COPILOT_USER = useFeatureFlag('CopilotView');

  // We're pre-loading the query so it's faster with the cache if they open the split transaction modal
  useBudgetLinesTreeQuery({
    variables: { budgetLineId: budgetLineContext!.id },
    fetchPolicy: 'cache-first',
  });

  if (loading && !actual) {
    return <>Loading...</>;
  }

  if (error || !actual) {
    return <>Error getting details.</>;
  }

  const top: PropertyTuple[] = [
    [isRevenue ? 'Customer' : 'Vendor', actual.vendor],
    ['Budget Line', actual.matchedBudgetLine?.name],
  ];

  const middle: PropertyTuple[] = [
    ['Document Number', actual.documentId],
    ['Department', actual.department],
    ['Account', actual.account],
    [
      'Transaction Date',
      actual.transactionDate && actual.transactionDate !== actual.effectiveDate
        ? date(new Date(actual.transactionDate))
        : undefined,
    ],
    ['Memo', actual.memo],
  ];

  const normalizedActual = normalizeSplitActual(actual, convertToMinorUnit, isRevenue);
  const isSplit = actual.isSplitOrigin || actual.isSplitSlice;
  const isMove = actual.splitMetadata?.splitType === 'MANUAL_BUDGET_LINE_MATCH';
  const canUpdateAllMatchedLines = normalizedActual.slices.every(s => s.budgetLine?.canUpdate);

  // Allow editing when you have edit permissions on the actual's line and, if
  // it's a split, you have update permissions in all the split slices.
  const canEdit = !!budgetLineContext?.permissions.edit && (!isSplit || canUpdateAllMatchedLines);

  const showSplitTransactionDetails: boolean = isSplit && !isMove;
  const showMovedTransactionDetails: boolean = isMove;

  const showMoveButton: boolean = canEdit && (isMove || !isSplit);
  const showSplitButton: boolean = canEdit && !isMove;

  const refetchData = async () => {
    refetchList(); // Updates list
    return refetch(); // Updates details
  };

  const splitTransaction = () =>
    createModal(
      <Modal className="floating-box absolute-center">
        <SplitTransactionModal actual={normalizedActual} refetch={refetch} />
      </Modal>,
    );

  const moveTransaction = () =>
    createModal(
      <Modal overlay className="floating-box absolute-center" clickAway="none">
        <MoveTransactionModal
          actualIds={[actual.slices[0]?.id || actual.id]}
          budgetLineId={actual.matchedBudgetLine!.id}
          refetch={refetchData}
        />
      </Modal>,
    );

  return (
    <div>
      <Heading className={styles.header} size="headline">
        Transaction Details
      </Heading>
      <div className={styles.group}>
        <div className={styles.amount}>{currency(flipSign(actual.amount, isRevenue))}</div>
        <div className={cx(styles.title, styles.date)}>{date(new Date(actual.effectiveDate))}</div>
        <div className={cx('font-body-large', styles.description)}>{actual.description}</div>
        <div>
          {actual.isAccrualActivity && (
            <Chip small checked={false} variant="display">
              Accrual activity
            </Chip>
          )}
        </div>
      </div>

      <Group data={top} />
      <Group data={middle} />

      {!IS_MAIN_COPILOT_USER && (
        <>
          {showSplitTransactionDetails && (
            <>
              <SplitTransactionDetails actual={actual} isRevenue={isRevenue} />
              <Separator />
            </>
          )}

          {showMovedTransactionDetails && (
            <>
              <MovedTransactionDetails actual={actual} />
              <Separator />
            </>
          )}

          {(showSplitButton || showMoveButton) && (
            <div className={styles.advancedOptions}>
              <Heading className={styles.header}>Advanced Options</Heading>

              {showSplitButton && (
                <Button name="split-actual" variant="text" onClick={splitTransaction}>
                  <Icon type="split" /> Split transaction
                </Button>
              )}

              {showMoveButton && (
                <Button name="match-actual" variant="text" onClick={moveTransaction}>
                  <Icon type="arrowRightAlt" /> Move transaction
                </Button>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default ActualDetailsInfo;

ActualDetailsInfo.displayName = 'ActualDetailsInfo';
