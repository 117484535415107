import * as React from 'react';

import { cx } from '@cobbler-io/utils/src/cx';

import styles from './Overlay.scss';

type OverlayProps = {
  /**
   * Click handler, usually something to close the modal
   */
  onClick?: React.MouseEventHandler<HTMLDivElement>;

  /**
   * Any particular style to modify the overlay
   */
  style?: React.CSSProperties;

  /**
   * Whether or not to make the overylay entirely transparent
   */
  isTransparent?: boolean;

  /**
   * a zIndex to help with stacking contexts if multiple overlays and modals are open
   */
  zIndex?: number;
};

export const Overlay = ({
  zIndex,
  isTransparent = false,
  style = {},
  ...rest
}: OverlayProps): JSX.Element => (
  <div
    {...rest}
    className={cx(styles.overlay, isTransparent && styles.transparent)}
    style={{ ...style, zIndex }}
  />
);

Overlay.displayName = 'Overlay';

export default Overlay;
