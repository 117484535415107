import * as React from 'react';

import {
  addItemOrItemsToSet, removeItemOrItemsFromSet, toggleItemOrItemsInSet,
} from '@cobbler-io/utils/src/immutable-set';

import { useGetLatest } from '@cobbler-io/hooks/src/useGetLatest';

import { IconTypes } from '@cobbler-io/core-ui/src/Icon';

import { ExpandableTreeContext } from './ExpandableTreeContext';
import { ExpandableTreeNodeProps } from './ExpandableTreeNode';

import styles from './ExpandableTree.scss';

export type ExpandableTreeProps = {
  children:
    | React.ReactElement<ExpandableTreeNodeProps>
    | React.ReactElement<ExpandableTreeNodeProps>[];

  expandedIcon?: IconTypes;
  collapsedIcon?: IconTypes;
  leafIcon?: IconTypes;
};

export const ExpandableTree = (props: ExpandableTreeProps) => {
  const { children, expandedIcon = 'minus', collapsedIcon = 'plus', leafIcon = 'blank' } = props;
  const [expanded, setExpanded] = React.useState<Set<string>>(new Set<string>());
  const getExpanded = useGetLatest(expanded);
  const isExpanded = (id: string) => getExpanded().has(id);
  const expand = (id: string | string[]) => setExpanded(prev => addItemOrItemsToSet(prev, id));

  const collapse = (id: string | string[]) =>
    setExpanded(prev => removeItemOrItemsFromSet(prev, id));
  const toggle = (id: string | string[]) => setExpanded(prev => toggleItemOrItemsInSet(prev, id));

  const ctxValue = {
    expand,
    collapse,
    toggle,
    isExpanded,
    expandedIcon,
    collapsedIcon,
    leafIcon,
  };

  return (
    <ExpandableTreeContext.Provider value={ctxValue}>
      <div className={styles.expandableTree}>{children}</div>
    </ExpandableTreeContext.Provider>
  );
};

export default ExpandableTree;
