import * as React from 'react';

import { ModalContextType, ModalTreeNode } from './types';

export const ModalContext = React.createContext<ModalContextType>({
  modals: [],
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  create: () => ({} as ModalTreeNode),
  closeAllModals: () => {},
});

ModalContext.displayName = 'ModalContext';
