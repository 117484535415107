/* eslint-disable camelcase */
import { identity } from '@cobbler-io/utils/src/identity';

import {
  EditLineOwnerInput, EditOwnerMutation, Exact, useEditOwnerMutation, useGetAllUsersQuery,
} from '@cobbler-io/app/src/api/graphql-types';
import { UserPicker } from '@cobbler-io/app/src/ndm/components/UserPicker';

import { MutationUpdaterFn } from 'apollo-client';
import gql from 'graphql-tag';
import { pluck } from 'ramda';
import { CellProps } from 'react-table';

import { EditorLine } from '../types';
import {
  acceptedTextKeys, EditorInput, ModalEditorHandlers, useModalEditor,
} from './useModalEditor';

type OptResp<Input, Payload> = (vars: Exact<{ input: Input }>) => Payload;

type UserType = {
  __typename: 'UserType';
  deleted: boolean;
  email: string;
  fullName: string | null;
  id: UserId;
  preferredName: string | null;
  profileImageUrl: string | null;
};

type IntermediateArgs = {
  users: UserType[];
};

type CellValue = DeepWriteable<EditorLine['owner']>;

type Updater<T> = (params: IntermediateArgs) => (cellProps: CellProps<EditorLine, CellValue>) => T;

/**
 * Creates an optimistic response for the mutation, which is basically the return
 * of the mutation
 * @see https://www.apollographql.com/docs/react/v2/performance/optimistic-ui/
 */
const optimisticResponse: Updater<OptResp<EditLineOwnerInput, EditOwnerMutation>> =
  ({ users }) =>
  cellProps =>
  ({ input }): EditOwnerMutation => {
    const { ownerId } = input;
    const nextOwner: UserType | null = (ownerId ? users.find(x => x.id === ownerId) : null) ?? null;
    const response: EditOwnerMutation = {
      __typename: 'Mutation' as const,
      a_editLineOwner: {
        __typename: 'EditLineOwnerPayload' as const,
        id: cellProps.row.original.id, // This should be the budgetLineId
        owner: nextOwner,
      },
    };
    return response;
  };

const BUDGET_LINE_OWNER_FRAGMENT = gql`
  fragment readBudgetLineOwner on BudgetEditorLineType {
    __typename
    id
    owner {
      ... on UserReference {
        id
        __typename
        item {
          ... on UserType {
            __typename
            id
            email
            deleted
            fullName
            preferredName
            profileImageUrl
          }
        }
      }
    }
  }
`;

/**
 * The update receives the input, and returns a function that will update the cache.
 *
 * It's weird, but I need the budgetLineId, which is not in the response
 *
 * This seems to work, but every once in awhile it seems to do nothing. There might have
 * been some cache evictions.
 */
const update: Updater<MutationUpdaterFn<EditOwnerMutation>> =
  () => cellProps => (cache, result) => {
    const budgetLineId = cellProps.row.original.id;
    /**
     * Updates the columns on the BudgetEditorTable
     */
    const updateBudgetEditor = () => {
      const { a_editLineOwner } = result.data!;
      const { owner: nextOwner } = a_editLineOwner;

      const fragmentId = ['BudgetEditorLineType', budgetLineId].join(':');
      const lineFragment = cache.readFragment({
        fragment: BUDGET_LINE_OWNER_FRAGMENT,
        id: fragmentId,
      });

      const owner = nextOwner
        ? {
            __typename: 'UserReference',
            id: nextOwner.id,
            item: { ...nextOwner },
          }
        : nextOwner;

      cache.writeFragment({
        data: { ...(lineFragment as Record<string, unknown>), owner },
        fragment: BUDGET_LINE_OWNER_FRAGMENT,
        id: fragmentId,
      });
    };

    /**
     * Updates the columns on the BudgetOverviewTable
     */
    const updateBudgetOverview = () => {};

    updateBudgetEditor();
    updateBudgetOverview();
  };

const getIds = pluck('id');

type RelativeEditorParams = {
  getWeight: NullaryFn<number>;
};

export const useEditOwner = (p: RelativeEditorParams): ModalEditorHandlers => {
  const { data } = useGetAllUsersQuery({ fetchPolicy: 'cache-first' });
  const users = (data?.usersForUserPicker ?? []) as UserType[];
  const toValue = identity;
  const { getWeight } = p;

  const getDefaultValue = (cellValue: CellValue): UserId[] => {
    if (cellValue?.item?.id) {
      return [cellValue.item.id];
    }
    return Array.isArray(cellValue) ? getIds(cellValue) : [];
  };

  const createInput = (params: {
    value: UserId | null;
    cellProps: CellProps<EditorLine, CellValue>;
  }): EditLineOwnerInput => ({
    id: params.cellProps.row.original.id,
    ownerId: params.value,
  });

  return useModalEditor<EditLineOwnerInput, EditOwnerMutation, EditorInput, CellValue>({
    Field: UserPicker,
    acceptedKeysHandler: acceptedTextKeys,
    createInput,
    getDefaultValue,
    getWeight,
    optimisticResponse: optimisticResponse({ users }),
    toValue,
    update: update({ users }),
    useMutationHook: useEditOwnerMutation,
  });
};
