import * as React from 'react';

import { head } from '@cobbler-io/utils/src/array';

import { isNil, reject } from 'ramda';
import { CellProps } from 'react-table';

import { EditorLine } from '../../types';

const isEmptyArray = (x: unknown): boolean => {
  if (!Array.isArray(x)) {
    return false;
  }

  return reject(isNil, x).length === 0;
};

export const DepartmentColumn = ({
  cell: { value: departments },
}: CellProps<EditorLine, EditorLine['departments']>): string | null => {
  if (!departments || isEmptyArray(departments)) {
    return null;
  }

  if (departments.length === 1) {
    return head(departments).name;
  }

  if (departments.length > 1) {
    return [head(departments).name, `(+${departments.length - 1} more)`].join(' ');
  }

  return null;
};

DepartmentColumn.displayName = 'DepartmentColumn';
