/* eslint-disable max-lines-per-function */
import * as React from 'react';

import { sum } from '@cobbler-io/utils/src/array';
import { upperFirst } from '@cobbler-io/utils/src/string';

import { Button } from '@cobbler-io/core-ui/src/Button';
import { Icon } from '@cobbler-io/core-ui/src/Icon';

import { useCurrencyFormatter } from '@cobbler-io/redux/src/modules/currency';

import { UpsertBudgetLineInputType } from '@cobbler-io/app/src/api/graphql-types';
import { BudgetLineAtRevision } from '@cobbler-io/app/src/ndm/components/BudgetLineEditor/types';

import { indexBy, map, pathOr, pipe, prop } from 'ramda';

import { intervalStartToPeriod } from './utils';

import styles from './BudgetLineUploaderPreview.scss';

const info = ['Name', 'Vendors', 'Owner Email', 'Department', 'Account Codes'];

const pluralize = (amount: number, unit: string) => `${amount} ${unit}${amount !== 1 ? 's' : ''}`;

const getIntervalAmount = (
  { plannedSpends }: UpsertBudgetLineInputType,
  interval: BudgetLineAtRevision['intervals'][number],
) => {
  if (!plannedSpends) {
    throw new Error(
      'The supplied UpsertBudgetLineInputType does not contain plannedSpends information.',
    );
  }
  const matchedPlan = plannedSpends.find(ps => ps.start === interval.start);
  return matchedPlan?.planned ?? 0;
};

type PeriodAmounts = {
  name: string;
  amounts: number[];
  total: number;
  allocated: number;
  available: number;
};

const getAmountsPerPeriod = (
  budgetLineAtRevision: BudgetLineAtRevision,
  lines: UpsertBudgetLineInputType[],
) => {
  const { intervals, totalsByInterval } = budgetLineAtRevision;

  const perInterval = map(interval => {
    const amounts = lines.map(line => getIntervalAmount(line, interval));
    return {
      allocated: pathOr(0, [interval.start, 'allocated'], totalsByInterval),
      amounts,
      available: pathOr(0, [interval.start, 'unallocated'], totalsByInterval),
      name: interval.start,
      total: sum(amounts),
    };
  }, intervals);

  return {
    amountsPerInterval: indexBy(prop('name'), perInterval),
    totalAvailable: budgetLineAtRevision.totals.unallocated,
    totalPlanned: pipe(map(prop('total')), sum)(perInterval),
  };
};

const getDeltaClassName = ({ total, available }: PeriodAmounts, threshold: MinorCurrency = 0) => {
  if (Math.abs(available - total) <= threshold) {
    return 'same';
  }
  return total > available ? 'over' : 'under';
};

export type BudgetLineUploaderPreviewProps = {
  budgetLineAtRevision: BudgetLineAtRevision;
  overplanningThreshold?: MinorCurrency;
  lines: UpsertBudgetLineInputType[];
  allowOverplanning?: boolean;
  onSubmit: (lines: UpsertBudgetLineInputType[]) => void;
  onCancel: () => void;
};

export const BudgetLineUploaderPreview = (props: BudgetLineUploaderPreviewProps): JSX.Element => {
  const {
    budgetLineAtRevision,
    lines,
    onSubmit,
    onCancel,
    overplanningThreshold = 0,
    allowOverplanning = false,
  } = props;
  const { intervals } = budgetLineAtRevision;
  const { currencyFromMinorUnit } = useCurrencyFormatter();
  const { totalAvailable, totalPlanned, amountsPerInterval } = getAmountsPerPeriod(
    budgetLineAtRevision,
    lines,
  );
  const overallocations = Object.values(amountsPerInterval).filter(
    ({ total, available }) => total > available + overplanningThreshold,
  );

  const isOverallocated =
    overallocations.length > 0 || totalPlanned > totalAvailable + overplanningThreshold;

  return (
    <div className={styles.budgetUploadPreview}>
      {overallocations.length > 0 && (
        <div className={styles.error}>
          <p>
            You have exceeded your allocation allotment across{' '}
            {pluralize(overallocations.length, 'period')}. Please review the information below.
          </p>
        </div>
      )}
      {!overallocations.length && totalPlanned > totalAvailable + overplanningThreshold && (
        <div className={styles.error}>
          <p>
            You have exceeded your total allocation allotment. Please review the information below.
          </p>
        </div>
      )}
      <div className={styles.scrollingContainer}>
        <table className={styles.previewTable}>
          <thead>
            <tr>
              {info.map(name => (
                <th key={name}>{name}</th>
              ))}

              {intervals.map(({ start }) => (
                // Highlight overplanned header
                <th
                  key={start}
                  className={
                    styles[getDeltaClassName(amountsPerInterval[start], overplanningThreshold)]
                  }
                >
                  {upperFirst(intervalStartToPeriod(start))}
                </th>
              ))}
            </tr>
          </thead>

          <tbody>
            {lines.map((line, i) => (
              <tr key={line.name + line.ownerUserEmail}>
                <td>{line.name}</td>
                <td>{line.vendorNames?.join('; ')}</td>
                <td>{line.ownerUserEmail}</td>
                <td>{line.departmentNames?.join('; ')}</td>
                <td>{line.accountCodes?.join('; ')}</td>

                {intervals.map(({ start }) => (
                  <td key={start}>{currencyFromMinorUnit(amountsPerInterval[start].amounts[i])}</td>
                ))}
              </tr>
            ))}
          </tbody>

          <tfoot>
            <tr className={styles.totals}>
              <td
                colSpan={info.length}
                title={[
                  `Total available: ${currencyFromMinorUnit(totalAvailable)}`,
                  `Total allocated: ${currencyFromMinorUnit(totalPlanned)}`,
                ].join('\n')}
              >
                Total
              </td>
              {intervals.map(({ start }) => (
                // Highlight "Total" footer if over threshold
                <td
                  key={start}
                  className={
                    styles[getDeltaClassName(amountsPerInterval[start], overplanningThreshold)]
                  }
                >
                  {currencyFromMinorUnit(amountsPerInterval[start].total)}
                </td>
              ))}
            </tr>

            <tr>
              <td colSpan={info.length}>Available</td>
              {intervals.map(({ start }) => (
                <td key={start}>{currencyFromMinorUnit(amountsPerInterval[start].available)}</td>
              ))}
            </tr>
          </tfoot>

          <colgroup className={styles.infoGroup}>
            {info.map(v => (
              <col key={v} />
            ))}
          </colgroup>

          <colgroup className={styles.periodGroup}>
            {/* Highlight overplan columns */}
            {intervals.map(({ start }) => (
              <col
                key={start}
                className={
                  styles[getDeltaClassName(amountsPerInterval[start], overplanningThreshold)]
                }
              />
            ))}
          </colgroup>
        </table>
      </div>

      <div className={styles.actions}>
        <Button name="Cancel upload" variant="outline" onClick={onCancel}>
          <Icon type="left" /> Cancel
        </Button>
        <Button
          disabled={isOverallocated && !allowOverplanning}
          name="Upload buget"
          onClick={() => {
            // TODO: In the future, we might allow the user to edit the lines
            // and we would be passing a new lines array with changes ;)
            onSubmit(lines);
          }}
        >
          Upload <Icon type="right" />
        </Button>
      </div>
    </div>
  );
};
BudgetLineUploaderPreview.displayName = 'BudgetLineUploaderPreview';
