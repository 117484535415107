/* eslint-disable functional/immutable-data */
import { memoize } from 'lodash';
import { FilterType, FilterValue, IdType, Row } from 'react-table';

/**
 * DFS traverses a tree of rows and uses the supplied filterFn
 * to remove/keep rows.
 */
const dfsFilterWith =
  <D extends UnknownObject>(filterFn: (row: Row<D>) => boolean) =>
  (rows: Row<D>[]): Row<D>[] =>
    rows.reduce<Row<D>[]>((acc, row) => {
      const subRows: Row<D>[] = dfsFilterWith(filterFn)(row?.subRows ?? []);
      return subRows.length || filterFn(row) ? [...acc, { ...row, subRows }] : acc;
    }, []);

/**
 * Turns a FilterType<D> function to a curried filter function
 * ```ts
 * type From = (rows: Row[], ids: IdType[], val: FilterValue) => Row[]
 * type To   = (ids: IdType[], val: FilterValue) => (row: Row) => boolean
 * ```
 */
export const toFilter =
  <D extends UnknownObject>(filterTypeFn: FilterType<D>) =>
  (columnIds: IdType<D>[], filterValue: FilterValue) =>
  (row: Row<D>): boolean =>
    filterTypeFn([row], columnIds, filterValue).length > 0;

/**
 * Runs a FilterType function in a DFS fashion. As opposed to
 * ReactTable's BFS way.
 */
export const asDfsFilterType = memoize(
  <D extends UnknownObject>(filterTypeFn: FilterType<D>): FilterType<D> => {
    const dfsFilterTypeFn: FilterType<D> = (
      rows: Row<D>[],
      columnIds: IdType<D>[],
      filterValue: FilterValue,
    ) =>
      !rows[0] || rows[0].depth > 0 // Skips filtering on deeper levels. See `dfsText()`
        ? [...rows]
        : dfsFilterWith(toFilter(filterTypeFn)(columnIds, filterValue))(rows);

    dfsFilterTypeFn.autoRemove = filterTypeFn.autoRemove;

    return dfsFilterTypeFn;
  },
);

/**
 * Returns true if a row "contains" the supplied value in any of the
 * supplied columns (ids).
 */
const textRowFilter =
  <D extends UnknownObject>(columnIds: IdType<D>[], filterValue: FilterValue) =>
  (row: Row<D>): boolean =>
    columnIds.some(id => {
      // Get the value of the row at the specified column (basically, the cell
      // value), and, if there isn't a cell value, try to inherit the parent
      // row's value.
      const cellValue = row.values[id] || row?.parentRow?.values[id];

      // If no value is present, automatically filter out.
      if (!cellValue) {
        return false;
      }

      // Otherwise, we keep the row if the cell value includes the supplied string.
      const includesText = String(cellValue)
        .toLowerCase()
        .includes(String(filterValue).toLowerCase());

      return includesText;
    });

// This is ReactTable's default filter type ("text"), typed and decomposed.
// Left here as an example
// @see https://github.com/TanStack/react-table/blob/v7/src/filterTypes.js
const text = <D extends UnknownObject>(
  rows: Row<D>[],
  columnIds: IdType<D>[],
  filterValue: FilterValue,
): Row<D>[] => rows.filter(textRowFilter(columnIds, filterValue));

text.autoRemove = (filterValue: FilterValue) => !filterValue;

/**
 * Custom filter type that traverses the whole tree and returns all the
 * ancestors of any node that passes the filter. Use this as a recipe for
 * future DFS filters.
 */
const dfsText = <D extends UnknownObject>(
  rows: Row<D>[],
  columnIds: IdType<D>[],
  filterValue: FilterValue,
): Row<D>[] => {
  // React table runs the filters in a BFS way. It filters the root rows and
  // then it moves to the filtered row's sub-rows, and so on. Basically, if a
  // parent row get's filtered out, its children will never show even if they
  // pass the filter condition. To work around this, we traverse the whole tree
  // on the first pass, and when ReactTable tries to move to the next depth, we
  // always return true since we've already done all the filtering.
  // @see https://github.com/TanStack/react-table/blob/v7/src/plugin-hooks/useFilters.js#L193-L254
  if (!rows[0] || rows[0].depth > 0) {
    return [...rows];
  }

  return dfsFilterWith(textRowFilter(columnIds, filterValue))(rows);
};
dfsText.autoRemove = (filterValue: FilterValue) => !filterValue;

export const filterTypes = {
  // In case we want to use the original algo, we have to manually set the
  // filter prop of <Column> to "bfsText".
  bfsText: text,

  // By setting "text" in this object we overwrite the default filter for
  // all columns.
  text: dfsText,
};

export type CustomFilterTypes = keyof typeof filterTypes;
