/* eslint-disable max-lines-per-function */
import * as React from 'react';

import { useToggle } from '@cobbler-io/hooks/src';

import { ErrorCallout } from '@cobbler-io/core-ui/src/Callout';
import { useFeatureFlag } from '@cobbler-io/core-ui/src/FeatureFlag';
import { Field } from '@cobbler-io/core-ui/src/Field';
import { useModal } from '@cobbler-io/core-ui/src/Modal';

import { useCurrentBudget } from '@cobbler-io/redux/src/modules/current-budget';
import { useOverplanningThreshold } from '@cobbler-io/redux/src/modules/tenant-settings';

import { FormAPIError } from '@cobbler-io/app/src/api/extractGraphQLErrors';

import { Form } from '@swan-form/form';
import { required } from '@swan-form/helpers';

import { AccountPicker } from '../AccountPicker';
import { DepartmentPicker } from '../DepartmentPicker';
import { LineTypePicker } from '../LineTypePicker';
import { UserPicker } from '../UserPicker';
import { VendorPicker } from '../VendorPicker';
import { ExpenseSchedule } from './ExpenseSchedule';
import { OverplannedDialog } from './OverplannedDialog/OverplannedDialog';
import { BudgetLineAtRevision, BudgetLineType } from './types';

import styles from './BudgetLineForm.scss';

type BudgetLineFormProps = {
  afterSubmit?: (values: Record<string, any>) => Promise<any>;
  current?: BudgetLineAtRevision;
  defaultValues: Record<string, any>;
  formErrors: FormAPIError;
  name: string;
  onSubmit: (values: Record<string, any>) => Promise<any>;
  parent: BudgetLineAtRevision;
};

// Since we don't have access to the form's errors we query the DOM to check
// if all the errors are related to overplanning. This is fragile and it can
// stop working easily, so we should try to update the upstream lib to support
// more comprehensive error reporting.
const onlyOverplanningErrors = (formContainer: HTMLDivElement | null) =>
  Array.from(formContainer?.querySelectorAll('span.sf--error') ?? [])
    .map(el => el.textContent ?? '')
    .every(msg => /This change will propose an increase./u.test(msg));

export const BudgetLineForm = (props: BudgetLineFormProps): JSX.Element => {
  const { defaultValues, onSubmit, afterSubmit, formErrors, parent, current, name } = props;
  const overplannedThreshold = useOverplanningThreshold();
  const [budget] = useCurrentBudget();

  // If we get to this point without a budget, there's something really wrong
  const { selectedRevisionId, originalRevisionId } = budget!;
  const isForecast = selectedRevisionId !== originalRevisionId;

  // Only allow overplanning on forecasts or is root (meaning that the user is an admin).
  // TEMP: Relax these rule for now...
  const allowOverplanning = true; // isForecast || parent.isRoot;

  const fieldsRef = React.useRef<HTMLDivElement>(null);
  const formRef = React.useRef<Form>(null);
  const { create: createModal } = useModal();
  const {
    active: skipAmountValidation,
    deactivate: enableAmountValidation,
    activate: disableAmountValidation,
  } = useToggle(current?.isRevenue ?? parent.isRevenue); // Skip amount validation when is revenue line

  const {
    active: isRevenueLine,
    activate: disableVendor,
    deactivate: enableVendor,
  } = useToggle(
    defaultValues.type === BudgetLineType.REVENUE || parent.type === BudgetLineType.REVENUE,
  );

  const onResubmit = () => {
    if (!skipAmountValidation) {
      disableAmountValidation();
      setTimeout(() => {
        void formRef.current?.doSubmit();
      }, 0);
    }
  };

  const maxHintLabel = React.useMemo(() => {
    if (isRevenueLine) {
      return 'remaining';
    }
    return 'available';
  }, [isRevenueLine]);

  return (
    <Form
      ref={formRef}
      afterSubmit={afterSubmit}
      autoComplete={false}
      defaultValues={defaultValues}
      // @ts-expect-error: Check with @shawnrice if id is indeed not needed or we need to tweak the type definitions for Form
      id={name}
      name={name}
      style={{ maxWidth: '40rem' }}
      onError={err => {
        // @TODO: This is a major hack that allows us to submit the form even
        // when there is validation errors related to overplanning.
        queueMicrotask(() => {
          allowOverplanning &&
          err === 'Form is not valid' &&
          !skipAmountValidation &&
          onlyOverplanningErrors(fieldsRef.current)
            ? createModal(<OverplannedDialog isForecast={isForecast} onConfirm={onResubmit} />)
            : enableAmountValidation();
        });
      }}
      onSubmit={onSubmit}
    >
      <div ref={fieldsRef} className="grid">
        <div className="row">
          <div className="col small-12">
            <Field aria-label="Name" name="name" placeholder="Name" validate={required} />
          </div>
          <div className="col small-12">
            <LineTypePicker
              aria-label="Type"
              defaultValue={defaultValues.type}
              id="type"
              label="Line Type"
              name="type"
              parent={parent}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                event.target.value === BudgetLineType.REVENUE ? disableVendor() : enableVendor()
              }
            />
          </div>
        </div>
        <div className="row">
          <div className="col small-12 medium-6">
            <VendorPicker
              arial-label="Vendors"
              className={isRevenueLine ? styles.disabled : ''}
              defaultValue={defaultValues.vendors}
              disabled={isRevenueLine}
              id="vendors"
              label="Vendors"
              name="vendors"
            />
          </div>
          <div className="col small-12 medium-6">
            <UserPicker
              aria-label="Owner"
              defaultValue={defaultValues.ownerUserId ?? undefined}
              id="ownerUserId"
              label="Owner"
              name="ownerUserId"
            />
          </div>
        </div>

        <div className="row">
          <div className="col small-12 medium-6">
            <AccountPicker
              arial-label="Accounts"
              defaultValue={defaultValues.accountIds}
              id="accountIds"
              label="Accounts"
              name="accountIds"
            />
          </div>
          <div className="col small-12 medium-6">
            <DepartmentPicker
              arial-label="Department"
              defaultValue={defaultValues.departmentIds}
              id="departmentIds"
              label="Department"
              name="departmentIds"
            />
          </div>
        </div>

        <ExpenseSchedule
          key={isRevenueLine ? 'Revenue' : 'Expense'}
          current={current ?? null}
          isRevenueRootChild={parent.isRoot && isRevenueLine}
          maxHintLabel={maxHintLabel}
          overplannedThreshold={overplannedThreshold}
          parent={parent}
          skipAmountValidation={skipAmountValidation || isRevenueLine}
        />

        {formErrors && <ErrorCallout autoFocus>{formErrors.message}</ErrorCallout>}
      </div>
    </Form>
  );
};

BudgetLineForm.displayName = 'BudgetLineForm';
