/* eslint-disable jsx-a11y/no-static-element-interactions, jsx-a11y/no-noninteractive-tabindex, max-lines-per-function */

import * as React from 'react';

import { cx } from '@cobbler-io/utils/src/cx';
import { imageMimeTypes } from '@cobbler-io/utils/src/image';

import { Button } from '@cobbler-io/core-ui/src/Button';
import { CloseButton } from '@cobbler-io/core-ui/src/CloseButton';
import { Icon } from '@cobbler-io/core-ui/src/Icon';
import { ImageEditor } from '@cobbler-io/core-ui/src/ImageEditor';
import { Modal, useModal } from '@cobbler-io/core-ui/src/Modal';
import { useNotification } from '@cobbler-io/core-ui/src/Notification';
import { WebcamPhoto } from '@cobbler-io/core-ui/src/WebcamPhoto';

import { useSetUser } from '@cobbler-io/redux/src/modules/current-user';

import { uploadProfilePicture } from '@cobbler-io/app/src/api/uploadProfilePicture';

import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { useSelector } from 'react-redux';

import styles from './ProfilePictureUploader.scss';

const GET_PROFILE_PICTURE = gql`
  query GetProfilePicture {
    currentUser {
      id
      profileImageUrl
    }
  }
`;

type GetProfilePicture = {
  currentUser: {
    id: string;
    profileImageUrl: string | null;
  };
};

type ProfilePictureUploaderProps = {
  children: React.ReactNode;
  className?: string;
};

type DecayingTimerParams<T> = {
  getData: () => Promise<T>;
  isSuccess: (data: T) => boolean;
  onSuccess: (data: T) => any;
  retries?: number;
  time?: number;
};

const createDecayingTimer = <T extends any>(params: DecayingTimerParams<T>) => {
  const { getData, isSuccess, onSuccess, time = 2000, retries = 5 } = params;
  const fn = async () =>
    getData().then(res => {
      if (isSuccess(res)) {
        onSuccess(res);
      } else if (retries) {
        createDecayingTimer({ ...params, time: time * 2, retries: retries - 1 });
      }
    });

  setTimeout(fn, time);
};

export const ProfilePictureUploader = (props: ProfilePictureUploaderProps): JSX.Element => {
  // @ts-expect-error: TODO fix redux typings
  const { token_type: type, access_token: token } = useSelector(state => state.auth.user);
  const { children, className } = props;
  const { data, client } = useQuery<GetProfilePicture>(GET_PROFILE_PICTURE);
  const [file, setFile] = React.useState<File | null>(null);
  const ref = React.useRef<HTMLDivElement>(null);

  const updateUser = useSetUser();
  const notify = useNotification();

  // We want to track (down the way) if the profile image has changed, so we'll store it in the
  // ref, and then we'll keep it updated with every render. We'll use it in our "success" callback
  const imageRef = React.useRef<string | null | undefined>();
  if (data?.currentUser?.profileImageUrl !== imageRef.current) {
    imageRef.current = data?.currentUser?.profileImageUrl;
  }

  // A fn to query the current user. We'll use it to grab the profileImageUrl to see if the
  // server-side processing on the image is done
  const getData = async () => client.query<GetProfilePicture>({ query: GET_PROFILE_PICTURE });

  // Success test for the decaying callback, basically: is there a profile image, and has it changed?
  const isSuccess = (res: { data: GetProfilePicture }) => {
    const next = res.data?.currentUser?.profileImageUrl;
    return Boolean(next && next !== imageRef.current);
  };

  // A success callback for when the profile image has been updated. We'll push it into the
  // currentUser store, which should make the images update
  const onSuccess = (res: { data: GetProfilePicture }) => updateUser(res.data.currentUser);

  const onAcceptFile = (accepted: File) => {
    uploadProfilePicture({ tokenType: type, accessToken: token, file: accepted })
      .then(() => {
        notify({ title: 'Success', body: 'Uploaded profile picture. It will appear shortly.' });
        // We're not sure when the lambda is going to be finished, so we need to poll for it,
        // so, we'll try to grab it five times over the next two minutes. If there is any
        // success (e.g. profileImageUrl is not null and has changed), then we'll call the
        // onSucces, which will update the currentUser store in Redux.
        createDecayingTimer<{ data: GetProfilePicture }>({ getData, isSuccess, onSuccess });
      })
      .catch(() =>
        notify({
          title: 'Error uploading file',
          body: 'An unknown error occurred when trying to upload the file',
        }),
      );
  };

  const createWebcamModal = useModal(
    <Modal overlay className={styles.webcam} clickAway="none">
      {({ close: closeWebcam }) => (
        <>
          <CloseButton className={styles.closeButton} />
          <WebcamPhoto
            height={480}
            width={640}
            onAcceptPicture={picture => {
              closeWebcam();
              setFile(new File([picture], 'webcam-photo.jpeg'));
            }}
          />
        </>
      )}
    </Modal>,
    { onAfterClose: () => setFile(null) },
  );

  const createFlyoutModal = useModal(
    <Modal arrow className={styles.modal} overlay="transparent">
      {({ close }) => (
        <div className={styles.body}>
          <label className={styles.button} htmlFor="upload-picture">
            <Icon type="addPicture" /> <span>Upload a photo</span>
            <input
              accept={imageMimeTypes.join(',')}
              className="hidden"
              id="upload-picture"
              name="upload-picture"
              type="file"
              onChange={event => {
                if (event.currentTarget.files instanceof FileList) {
                  const f = event.currentTarget.files[0];
                  setFile(f);
                }
                close();
              }}
            />
          </label>
          <Button
            className={styles.button}
            name="webcam-photo"
            variant="text"
            onClick={() => {
              close();
              createWebcamModal();
            }}
          >
            <Icon type="addCamera" /> <span>Take a photo</span>
          </Button>
        </div>
      )}
    </Modal>,
    { target: ref.current!, placement: 'right' },
  );

  const createImageEditorModal = useModal(
    <Modal overlay className={styles.editorModal} clickAway="none">
      <ImageEditor file={file} onAccept={onAcceptFile} />
    </Modal>,
    { onAfterClose: () => setFile(null) },
  );

  const openProfileImageUploader = (event: React.SyntheticEvent<HTMLDivElement>) => {
    // We're just going to bind this to keydown as well as click. But we'll allow it to
    // be activated only on space or enter
    if (event.type === 'keydown') {
      const { key } = event as React.KeyboardEvent<HTMLDivElement>;
      if (key !== ' ' && key !== 'Enter') {
        return;
      }
    }

    createFlyoutModal();
  };

  React.useEffect(() => {
    if (file) {
      createImageEditorModal();
    }
  }, [file]);

  return (
    <div
      ref={ref}
      className={cx(styles.target, className)}
      tabIndex={0}
      onClick={openProfileImageUploader}
      onKeyDown={openProfileImageUploader}
    >
      {children}
    </div>
  );
};

ProfilePictureUploader.displayName = 'ProfilePictureUploader';
