/* eslint-disable functional/immutable-data */
/* eslint-disable no-param-reassign */
import { Hooks, makePropGetter, Meta, Row } from 'react-table';

import { reducer } from './reducer';
import {
  getToggleAllPageRowsSelectedProps, getToggleAllRowsSelectedProps, getToggleRowSelectedProps,
} from './toggleProps';
import { useInstance } from './useInstance';

const prepareRow = <D extends UnknownObject>(row: Row<D>, meta: Meta<D>): void => {
  const { instance } = meta;

  // Add direct access to `toggleRowSelected` & `getToggleRowSelectedProps` from
  // each row. We just added these new props to the instance in this plugin.
  row.toggleRowSelected = set => instance.toggleRowSelected(row.id, set);
  row.getToggleRowSelectedProps = makePropGetter(
    // @ts-expect-error: Looks like `makePropGetter` is incorrectly typed.
    instance.getHooks().getToggleRowSelectedProps,
    { instance, row },
  );
};

export const useRowSelect = <D extends UnknownObject>(hooks: Hooks<D>): void => {
  // New methods to be exposed. These will be accessed by useInstance and added
  // to the instance and rows.
  hooks.getToggleRowSelectedProps = [getToggleRowSelectedProps];
  hooks.getToggleAllRowsSelectedProps = [getToggleAllRowsSelectedProps];
  hooks.getToggleAllPageRowsSelectedProps = [getToggleAllPageRowsSelectedProps];

  // Enhance existing lifecycle methods
  hooks.stateReducers.push(reducer);
  hooks.useInstance.push(useInstance);
  hooks.prepareRow.push(prepareRow);
};
useRowSelect.pluginName = 'useRowSelect';
