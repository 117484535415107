import * as React from 'react';

import { extractGuidFromRelayId } from '@cobbler-io/utils/src/destructureRelayId';

import { BreadCrumbs, SegmentType } from '@cobbler-io/core-ui/src/BreadCrumbs';

import { BudgetLineVertex } from '@cobbler-io/app/src/api/graph';
import { canRead } from '@cobbler-io/app/src/api/graph/permissionsBitMap';
import { useBudgetLineTree } from '@cobbler-io/app/src/api/graph/useBudgetLineTree';
import { createBudgetLineStubId } from '@cobbler-io/app/src/api/graph/utils';
import { useCurrentBudgetLine } from '@cobbler-io/app/src/providers';
import { BudgetUrlParams } from '@cobbler-io/app/src/urls';

import { TreeNode } from '@cobbler-io/collection/src/Tree';

import { navigate } from '@reach/router';

import styles from './BudgetBreadcrumbs.scss';

const createSegmentFromBudget = (
  budgetLineNode: TreeNode<BudgetLineVertex>,
  path: string,
): SegmentType => ({
  children: budgetLineNode.value.properties.name,
  key: budgetLineNode.id,
  to: path,
});

const createDropdownSegment = (
  budgetLineNode: TreeNode<BudgetLineVertex>,
  siblings: TreeNode<BudgetLineVertex>[],
  url: (params: BudgetUrlParams) => string,
): SegmentType => {
  const {
    value: {
      properties: { name },
    },
  } = budgetLineNode;

  return {
    children: name,
    id: budgetLineNode.id,
    items: siblings.map(x => {
      const siblingName = x.value.properties.name;
      return {
        children: siblingName === name ? <strong>{siblingName}</strong> : siblingName,
        name: siblingName,
        onClick: async () => navigate(url({ budgetId: extractGuidFromRelayId(x.id)! })),
        title: siblingName,
        type: 'button' as const,
      };
    }),
    key: budgetLineNode.id,
    name,
    type: 'dropdown' as const,
  };
};

export const BudgetBreadcrumbs = (): JSX.Element => {
  const { id: budgetLineId, urls } = useCurrentBudgetLine()!;
  const [, dict] = useBudgetLineTree();

  const segments = React.useMemo(() => {
    const currentBudgetLineNode = dict[createBudgetLineStubId(budgetLineId) ?? ''];

    // Check if the budget line node exists in the tree
    if (currentBudgetLineNode) {
      const { ancestors } = currentBudgetLineNode;

      // Get all the path nodes from the root to the current node
      const budgetLines = ancestors.concat(currentBudgetLineNode);

      // Create a segment for each node, depending on whether it has siblings or not
      return budgetLines.map(budgetLine => {
        const hasSiblings = budgetLine.parent?.children && budgetLine.parent.children.length > 1;
        if (hasSiblings) {
          const siblings = budgetLine.parent.children;
          return createDropdownSegment(budgetLine, siblings, urls.overview);
        }

        if (!canRead(budgetLine.value.properties.permissions)) {
          return budgetLine.value.properties.name;
        }

        return createSegmentFromBudget(
          budgetLine,
          urls.overview({ budgetId: extractGuidFromRelayId(budgetLine.id)! }),
        );
      });
    }
    // If the budget line node doesn't exist, return an empty array
    return [];
  }, [budgetLineId, dict, urls]);

  return (
    <div className={styles.budgetBreadcrumbs}>
      <BreadCrumbs segments={segments} />
    </div>
  );
};

BudgetBreadcrumbs.displayName = 'BudgetBreadcrumbs';
export default BudgetBreadcrumbs;
