export type UserType = {
  id: string | null;
  email: string | null;
  preferredName?: string | null;
  fullName?: string | null;
};

export const getPersonIdentifier = (user: UserType): string =>
  user.preferredName ?? user.fullName ?? user.email ?? user.id ?? 'Unknown';

export default getPersonIdentifier;
