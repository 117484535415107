import { useApolloClient } from '@apollo/react-hooks';
import { DocumentNode } from 'graphql';

type RegisterAsyncJobParams<V extends Record<string, unknown>, D extends Record<string, unknown>> = {
  query: DocumentNode;
  variables: V;
  testSuccess: UnaryFn<D, boolean>;
  onSuccess: UnaryFn<D, void>;
  retryInterval: number;
}

export const useRegisterAsyncJob = <V extends Record<string, unknown>, D extends Record<string, unknown>>(): UnaryFn<RegisterAsyncJobParams<V, D>, void> => { 
  const client = useApolloClient();

  return (params: RegisterAsyncJobParams<V, D>): void => {
    const { query, variables, testSuccess, onSuccess, retryInterval } = params;
    
    const checkQuery = () => {
      client.query({
        fetchPolicy: 'network-only',
        query,
        variables,
      }).then(res => {
        if(testSuccess(res.data)) {
          onSuccess(res.data);
        } else {
          setTimeout(checkQuery, retryInterval);
        }
      }).catch(err => {
        console.error(err)
      });
    }
    
    checkQuery();
  }
};
