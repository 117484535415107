import { BudgetLineVertex } from '@cobbler-io/app/src/api/graph';

import { Graph } from '@cobbler-io/collection/src/Graph';
import { useGraphContext, useGraphTraversal } from '@cobbler-io/collection/src/Graph/react';
import { TreeNode } from '@cobbler-io/collection/src/Tree';

export type BudgetLineTreeDict = Record<string, TreeNode<BudgetLineVertex>>;

export type BudgetLineTreeTuple = [TreeNode<BudgetLineVertex> | null, BudgetLineTreeDict];

const createBudgetLineTree = (rootLine: BudgetLineVertex): BudgetLineTreeDict => {
  // Seed the queue with the rootLine. There should be only one root line. If you have multiple
  // roots, then you can call this once per root and then merge the results. If values
  // are shared, then there will be problems.
  const queue: [TreeNode<BudgetLineVertex> | null, BudgetLineVertex][] = [[null, rootLine]];

  const dict: BudgetLineTreeDict = {};

  /* eslint-disable functional/immutable-data */
  while (queue.length) {
    // Grab the head of the queue. Since we're hydrating this tree top-down, we should always
    // hydrate the parents before the children. Note: we aren't checking to see if this
    // cycles. That shouldn't be a problem because this has those same checks on the backend.
    const [parent, item] = queue.shift() as [TreeNode<BudgetLineVertex> | null, BudgetLineVertex];

    // Create the tree node. The first half of this ternary should be called each time but the first
    const node = parent ? parent.createChild(item, item.id) : TreeNode.from(item, item.id);

    // Save this into the dict. We want a keyed record of this because it'll help accessing when you
    // want to start with part of the tree.
    dict[item.id] = node;

    // Do a quick traversal to get all the children
    const children: BudgetLineVertex[] =
      (item.graph.traverse(g => g.V(item.id).out('HAS_CHILD').toArray()) as BudgetLineVertex[]) ??
      [];

    // Push each child onto the queue
    for (const child of children) {
      queue.push([node, child]);
    }
  }
  /* eslint-enable functional/immutable-data */

  return dict;
};

const cache = new WeakMap<Graph<any, any>, Map<string, BudgetLineTreeDict>>();

/**
 * Bound to the graph, this returns a tree node and a dict of the tree nodes
 */
export const useBudgetLineTree = (): BudgetLineTreeTuple => {
  // Grab the graph so we can get access to the latest snapshot
  const { graph } = useGraphContext();

  // Find the root line. There should be only one of these in the graph. If there is more than one,
  // then we will have unpredictable behavior.
  const [rootLine] = useGraphTraversal(g =>
    g.V().hasLabel('ROOT_LINE').toArray(),
  ) as BudgetLineVertex[];

  // If there is no root line, then we can't do anything
  if (!rootLine) {
    return [null, {}];
  }

  // We'll make sure that this regens when the graph changes. We can implement a better caching
  // system later
  const snapshot = graph.snapshot();

  // If we have already calculated this, then just retrieve the cache. We're caching with an outer
  // WeakMap using the graph snapshot as the main key and an inner Map with the root line's id as
  // the key. We could find a different signal to create this cache (e.g one that busts this cache
  // only when we add / remove / restructure budget lines), but that's a project for another day
  if (cache.has(snapshot) && cache.get(snapshot)!.has(rootLine.id)) {
    const dict = cache.get(snapshot)!.get(rootLine.id)!;
    const rootNode = dict[rootLine.id];

    // return the tuple
    return [rootNode, dict];
  }

  // We don't have the cache so create the Tree
  const dict = createBudgetLineTree(rootLine);
  // Grab the rootLine's node.
  const rootNode = dict[rootLine?.id]!;

  if (!dict || !rootNode) {
    // We shouldn't hit this edge case, but it's theoretically possible in the code
    return [null, dict];
  }

  // We have everything we need, so set the cache
  if (cache.has(snapshot)) {
    // So, we have a cache for the snapshot, but not for the rootline... this is a weird variation
    cache.get(snapshot)!.set(rootLine.id, dict);
  } else {
    // This is the happy path. Neither level of the cache will exist, and we'll create it from scratch
    cache.set(snapshot, new Map([[rootLine.id, dict]]));
  }

  return [rootNode, dict];
};
