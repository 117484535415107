import { pad0 } from '@cobbler-io/utils/src/pad0';

/**
 * Converts a JavaScript Date to a zero-padded, Month-Year string
 */
export const dateToYYYYMM = (date: Date): string =>
  [date.getFullYear(), date.getMonth() + 1].map(pad0).join('-');

/**
 * Converts a string representation of Month-Year to a Date
 *
 * @param s A string representing a date: MM-YYYY
 */
export const dateFromYYYYMM = (s: string): Date => {
  const [year, month] = s.split('-').map(x => parseInt(x, 10));
  const d = new Date(year, month - 1);
  return d;
};

/**
 * Checks if two dates have the same month and year
 *
 * @param d1 The first date to compare
 * @param d2 The second date to compare
 */
export const YYYYMMAreEqual = (d1: Date, d2: Date): boolean =>
  d1 && d2 && d1.getFullYear() === d2.getFullYear() && d1.getMonth() === d2.getMonth();
