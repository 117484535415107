import { isUndefined } from './isUndefined';

const pathToSegments = (path: string | (string | number)[]) =>
  Array.isArray(path)
    ? path
    : path
        .replace(/\]/g, '')
        .replace(/\[/g, '.')
        .split('.');

/**
 * Deeply retrieves a key from an object
 */
export const getBy = <O extends Record<string, unknown>, T extends any = any>(
  obj: O,
  path: string | (string | number)[],
  fallback: T,
): T => {
  if (!obj) {
    return obj;
  }

  const val = pathToSegments(path).reduce((c, k) => (isUndefined(c) || !c ? c : c[k]), obj);

  return isUndefined(val) ? fallback : val;
};
