import * as React from 'react';

export const setCSSProperties = <T extends HTMLElement>(el: T, css: React.CSSProperties) => {
  Object.entries(css).forEach(([key, value]) => {
    // eslint-disable-next-line no-param-reassign
    el.style[key] = value;
  });

  return el;
};
