import curry from 'lodash/curry';

const internalProp = <T extends Record<string, unknown>, K extends keyof T>(property: K, is: any, obj: T): boolean =>
  obj[property] === is;

/**
 * Gets a property from an object (supports currying)
 *
 * @todo make this a generic somehow
 */
export const propIs = curry<string, any, Record<string, unknown>, boolean>(internalProp);
