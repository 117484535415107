/* eslint-disable sort-keys, sort-keys-fix/sort-keys-fix */

import { CsvMetadataDataType, GetDataSetsQuery } from '@cobbler-io/app/src/api/graphql-types';

import { Graph } from '@cobbler-io/collection/src/Graph';

import { DataSetColumn, DataSetVertexProperties, PascalCase } from './graph';

type HydrateDataSetsParams = {
  data: GetDataSetsQuery['dataSets'];
  graph: Graph<any, any>;
};

type DataSetColumnProperties = {
  displayName: string;
  index: number;
  name: string;
  type: PascalCase<CsvMetadataDataType>;
};

type HydrateDataSetParams = {
  data: GetDataSetsQuery['dataSets'][number];
  graph: Graph<any, any>;
};

export const hydrateDataSet = (params: HydrateDataSetParams): void => {
  const { data, graph } = params;

  const columns: DataSetColumn[] = data.columns.map(c => ({
    id: c.id,
    displayName: c.displayName,
    index: c.index,
    name: c.name,
    type: c.type as PascalCase<CsvMetadataDataType>,
  }));

  const cols = columns.map(c =>
    graph.addVertex<DataSetColumnProperties>({
      id: c.id,
      labels: ['DATA_SET_COLUMN'],
      properties: {
        displayName: c.displayName,
        index: c.index,
        name: c.name,
        type: c.type,
      },
    }),
  );

  const vertex = graph.addVertex<DataSetVertexProperties>({
    id: data.id,
    labels: ['DATA_SET'],
    properties: {
      name: data.name,
      description: data.description,
      columns,
    },
  });

  for (const col of cols) {
    graph.addEdge({
      id: `HAS_COLUMN_${vertex.id}_${col.id}`,
      from: vertex,
      to: col,
      labels: ['HAS_COLUMN'],
      properties: {},
    });
  }
};

export const hydrateDataSets = (params: HydrateDataSetsParams): void => {
  const { data, graph } = params;

  for (const dataSet of data) {
    hydrateDataSet({ data: dataSet, graph });
  }
};
