import { hasFlag, hasFlags } from '@cobbler-io/utils/src/hasFlag';

const CAN_READ = 0b0001;
const CAN_UPDATE = 0b0010;
const CAN_DELETE = 0b0100;
const CAN_UPDATE_PERMISSION = 0b1000;

export const canRead = hasFlag(CAN_READ);

export const canEdit = hasFlags(CAN_UPDATE, CAN_DELETE);

export const canManage = hasFlag(CAN_UPDATE_PERMISSION);

type CreatePermissionsParams = {
  read?: boolean;
  edit?: boolean;
  manage?: boolean;
}

const permissionValues = {
  read: CAN_READ,
  edit: (CAN_UPDATE + CAN_DELETE) as 6,
  manage: CAN_UPDATE_PERMISSION,
} as const;

export const createPermissions = (params: CreatePermissionsParams = {}): number => {
   const { read = false, edit = false, manage = false } = params;

   const values = [
    (read ? permissionValues.read : 0),
    (edit ? permissionValues.edit : 0),
    (manage ? permissionValues.manage : 0)
   ];

   return values.reduce((y, x) => x + y, 0);
}
