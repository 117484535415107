/**
 * Forked from https://github.com/tannerlinsley/react-table/blob/master/src/plugin-hooks/useResizeColumns.js to create
 * ways to resize programmatically
 *
 * TODO clean this up
 */
import * as React from 'react';

import memoize from 'lodash/memoize';
import {
  actions, ActionType, ColumnInstance, defaultColumn, ensurePluginOrder, HeaderProps,
  makePropGetter, TableDispatch, TableInstance, useGetLatest, UseTableInstanceProps,
} from 'react-table';

export type EventType = MouseEvent | TouchEvent | React.MouseEvent | React.TouchEvent;

// functional definition of TouchEvent
export function isTouchEvent(event?: EventType): event is TouchEvent | React.TouchEvent {
  return !!event && 'changedTouches' in event;
}

// functional definition of event
export function isEvent(event?: EventType | Element): event is EventType {
  return (
    !!event &&
    (event instanceof Event || ('nativeEvent' in event && event.nativeEvent instanceof Event))
  );
}

export type UseResizeColumnsState = {
  columnResizing: {
    startX?: number;
    columnWidth: number;
    headerIdWidths: [string, number][];
    columnWidths: any;
    isResizingColumn?: string;
  };
}

export function getFirstDefined<T extends any = any>(...args: any[]): T | undefined {
  for (let i = 0; i < args.length; i += 1) {
    if (typeof args[i] !== 'undefined') {
      return args[i];
    }
  }

  return undefined;
}

function getLeafHeaders(header: ColumnInstance<Record<string, unknown>>) {
  const leafHeaders: ColumnInstance<Record<string, unknown>>[] = [];
  const recurseHeader = (head: ColumnInstance<Record<string, unknown>>) => {
    if (head.columns && head.columns.length) {
      head.columns.forEach(recurseHeader);
    }
    leafHeaders.push(header);
  };

  recurseHeader(header);

  return leafHeaders;
}

// Default Column
defaultColumn.canResize = true;

// Actions
actions.columnStartResizing = 'columnStartResizing';
actions.columnResizing = 'columnResizing';
actions.columnDoneResizing = 'columnDoneResizing';
actions.setColumnSize = 'setColumnSize';

/* eslint-disable no-param-reassign */
let raf: number;
const createOnResizeStart = (dispatch: TableDispatch) =>
  function onResizeStart(e: React.MouseEvent | React.TouchEvent, header: ColumnInstance<Record<string, unknown>>) {
    // lets not respond to multiple touches (e.g. 2 or 3 fingers)
    const eventIsTouchEvent = isTouchEvent(e) && e.touches && e.touches.length > 1;

    const headersToResize = getLeafHeaders(header);
    const headerIdWidths = headersToResize.map(d => [d.id, d.totalWidth]);

    const clientX = eventIsTouchEvent
      ? Math.round((e ).touches[0].clientX)
      : (e as React.MouseEvent).clientX;

    const dispatchMove = (x: number) => {
      dispatch({ type: actions.columnResizing, clientX: x });
    };
    const dispatchEnd = () => {
      window.requestAnimationFrame(() => dispatch({ type: actions.columnDoneResizing }));
    };

    const handlersAndEvents: Record<
      string,
      {
        moveEvent: string;
        moveHandler: EventListener;
        upEvent: string;
        upHandler: EventListener;
      }
    > = {
      mouse: {
        moveEvent: 'mousemove',
        moveHandler: (event: MouseEvent) => {
          const { clientX: x } = event;
          window.cancelAnimationFrame(raf);
          window.requestAnimationFrame(() => dispatchMove(x));
        },
        upEvent: 'mouseup',
        upHandler: () => {
          document.removeEventListener('mousemove', handlersAndEvents.mouse.moveHandler);
          document.removeEventListener('mouseup', handlersAndEvents.mouse.upHandler);
          dispatchEnd();
        },
      },
      touch: {
        moveEvent: 'touchmove',
        moveHandler: (event: TouchEvent) => {
          if (event.cancelable) {
            event.preventDefault();
            event.stopPropagation();
          }
          const { clientX: x } = event.touches[0];
          window.cancelAnimationFrame(raf);
          window.requestAnimationFrame(() => dispatchMove(x));
          return false;
        },
        upEvent: 'touchend',
        upHandler: () => {
          document.removeEventListener(
            handlersAndEvents.touch.moveEvent,
            handlersAndEvents.touch.moveHandler,
          );
          document.removeEventListener(
            handlersAndEvents.touch.upEvent,
            handlersAndEvents.touch.moveHandler,
          );
          dispatchEnd();
        },
      },
    };

    const events = eventIsTouchEvent ? handlersAndEvents.touch : handlersAndEvents.mouse;
    document.addEventListener(events.moveEvent, events.moveHandler, {
      passive: false,
    });
    document.addEventListener(events.upEvent, events.upHandler, {
      passive: false,
    });

    dispatch({
      type: actions.columnStartResizing,
      columnId: header.id,
      columnWidth: header.totalWidth,
      headerIdWidths,
      clientX,
    });
  };

const defaultGetResizerProps = (
  props,
  { instance, header }: { instance: TableInstance<Record<string, unknown>>; header: HeaderProps<Record<string, unknown>> },
) => {
  const { dispatch } = instance;

  const onResizeStart = memoize(createOnResizeStart(dispatch));

  return [
    props,
    {
      onMouseDown: (e: React.MouseEvent<HTMLElement>) => {
        e.persist();
        return onResizeStart(e, header);
      },
      onTouchStart: (e: React.TouchEvent<HTMLElement>) => {
        e.persist();
        return onResizeStart(e, header);
      },
      style: { cursor: 'ew-resize' },
      draggable: false,
      role: 'separator',
    },
  ];
};

function useInstance(instance: UseTableInstanceProps<Record<string, unknown>>) {
  const { plugins, dispatch } = instance;
  ensurePluginOrder(plugins, ['useAbsoluteLayout'], 'useResizeColumns');
  const setColumnSize = React.useCallback(
    (columnId, width, header) => {
      dispatch({ type: actions.setColumnSize, columnId, width, header });
    },
    [dispatch],
  );

  Object.assign(instance, { setColumnSize });
}

const useInstanceBeforeDimensions = (instance: TableInstance<Record<string, unknown>>) => {
  const {
    flatHeaders,
    disableResizing,
    getHooks,
    state: { columnResizing },
  } = instance;

  const getInstance = useGetLatest(instance);

  flatHeaders.forEach((header: ColumnInstance<Record<string, unknown>>) => {
    const canResize = getFirstDefined(
      header.disableResizing === true ? false : undefined,
      disableResizing === true ? false : undefined,
      true,
    );

    header.canResize = canResize;
    header.width = columnResizing.columnWidths[header.id] || header.width;
    header.isResizing = columnResizing.isResizingColumn === header.id;

    if (canResize) {
      header.getResizerProps = makePropGetter(getHooks().getResizerProps, {
        instance: getInstance(),
        header,
      });
    }
  });
};

function reducer(state: UseResizeColumnsState, action: ActionType) {
  if (action.type === actions.init) {
    return {
      // @ts-expect-error: warns that it will be overwritten, but this is the init phase
      columnResizing: { columnWidths: {} },
      ...state,
    };
  }

  if (action.type === actions.setColumnSize) {
    const { columnId, width, header } = action;

    // const column =
    const headersToResize = getLeafHeaders(header);
    const percentageDeltaX = (width - header.totalWidth) / header.totalWidth;
    const headerIdWidths = headersToResize.map(d => [d.id, d.totalWidth] as const);
    const newColumnWidths = headerIdWidths.reduce(
      (widths, [id, w]) => ({
        ...widths,
        [id]: Math.max(w + w * percentageDeltaX, 0),
      }),
      { [columnId]: width },
    );

    return {
      ...state,
      columnResizing: {
        columnWidths: {
          ...state.columnResizing.columnWidths,
          ...newColumnWidths,
        },
      },
    };
  }

  if (action.type === actions.columnStartResizing) {
    const { clientX, columnId, columnWidth, headerIdWidths } = action;

    return {
      ...state,
      columnResizing: {
        ...state.columnResizing,
        startX: clientX,
        headerIdWidths,
        columnWidth,
        isResizingColumn: columnId,
      },
    } as UseResizeColumnsState;
  }

  if (action.type === actions.columnResizing) {
    const { clientX } = action;
    const { startX, columnWidth, headerIdWidths } = state.columnResizing;

    const deltaX = clientX - (startX ?? 0);
    const percentageDeltaX = deltaX / columnWidth;

    const newColumnWidths = {};

    headerIdWidths.forEach(([headerId, headerWidth]) => {
      newColumnWidths[headerId] = Math.max(headerWidth + headerWidth * percentageDeltaX, 0);
    });

    return {
      ...state,
      columnResizing: {
        ...state.columnResizing,
        columnWidths: {
          ...state.columnResizing.columnWidths,
          ...newColumnWidths,
        },
      },
    };
  }

  if (action.type === actions.columnDoneResizing) {
    return {
      ...state,
      columnResizing: {
        ...state.columnResizing,
        startX: null,
        isResizingColumn: null,
      },
    };
  }

  return state;
}

// TODO type better than this
export const useResizeColumns = (hooks: Record<string, any>) => {
  hooks.getResizerProps = [defaultGetResizerProps];
  hooks.getHeaderProps.push({
    style: {
      position: 'relative',
    },
  });
  hooks.stateReducers.push(reducer);
  hooks.useInstance.push(useInstance);
  hooks.useInstanceBeforeDimensions.push(useInstanceBeforeDimensions);
};

useResizeColumns.pluginName = 'useResizeColumns';
