import { lower } from '@cobbler-io/utils/src/string/lower';

import { BudgetResolution } from '@cobbler-io/app/src/api';

import { DatePeriodType } from './getDatePeriods';

const resolutions = ['MONTH', 'QUARTER', 'HALF', 'YEAR'] as const;

const getDatePeriodTypes = (resolution: BudgetResolution): BudgetResolution[] => {
  const resolutionIndex = resolutions.findIndex(res => res === resolution);

  if (resolutionIndex < 0) {
    throw new Error('Cannot find resolution');
  }

  return resolutions.slice(resolutionIndex);
};

// TODO We should remove full budget if it's a year and matches fiscal year
type RecurrenceOption = { label: string; value: DatePeriodType };

export const getRecurrenceOptions = (resolution: BudgetResolution): RecurrenceOption[] =>
  getDatePeriodTypes(resolution)
    .map<RecurrenceOption>(value => ({ label: `Each ${lower(value)}`, value }))
    .concat([
      { label: 'Once', value: 'ONCE' },
      { label: 'Full budget period', value: 'FULL' },
    ]);
