import * as React from 'react';

import { cx } from '@cobbler-io/utils/src/cx';

import { ToolbarMenu, ToolbarMenuProps } from './ToolbarMenu';

import styles from './Toolbar.scss';

type ToolbarSectionProps = {
  children?: React.ReactNode;
  className?: string;
} & React.HTMLProps<HTMLDivElement>;

type ToolbarSection = ((props: ToolbarSectionProps) => JSX.Element) & { displayName?: string };

const Left: ToolbarSection = ({ children, className, ...props }) => (
  <div {...props} className={cx('data-grid-toolbar-left', styles.left, className)}>
    {children}
  </div>
);

Left.displayName = 'DataGrid__Toolbar__Left';

const Center: ToolbarSection = ({ children, className, ...props }) => (
  <div {...props} className={cx('data-grid-toolbar-center', styles.center, className)}>
    {children}
  </div>
);

Center.displayName = 'DataGrid__Toolbar__Center';

const Right: ToolbarSection = ({ children, className, ...props }) => (
  <div {...props} className={cx('data-grid-toolbar-right', styles.right, className)}>
    {children}
  </div>
);

Right.displayName = 'DataGrid__Toolbar__Right';

type ToolbarSubComponents = {
  Left: ToolbarSection;
  Center: ToolbarSection;
  Right: ToolbarSection;
  Menu: ((props: ToolbarMenuProps) => JSX.Element) & { displayName?: string };
};

export const Toolbar: ToolbarSection & ToolbarSubComponents = ({
  children,
  className,
}): JSX.Element => (
  <div className={cx('data-grid-toolbar', styles.toolbar, className)}>{children}</div>
);

Toolbar.displayName = 'DataGrid__Toolbar';

/* eslint-disable functional/immutable-data */
Toolbar.Left = Left;
Toolbar.Center = Center;
Toolbar.Right = Right;
Toolbar.Menu = ToolbarMenu;
/* eslint-enable functional/immutable-data */
