import * as React from 'react';

import { cx } from '@cobbler-io/utils/src/cx';

import { createFloatString, percentage } from '@cobbler-io/formatters/src/numbers';

import { useForwardedRef } from '@cobbler-io/hooks/src/useForwardedRef';
import { useToggle } from '@cobbler-io/hooks/src/useToggle';

import { Button } from '@cobbler-io/core-ui/src/Button';
import { Icon } from '@cobbler-io/core-ui/src/Icon';

import { useCurrencyFormatter } from '@cobbler-io/redux/src/modules/currency';

import { Field, Radios } from '@swan-form/field';

import { MaskedField, MaskedFieldProps } from './MaskedField';

import styles from './Field.scss';

export type FieldProps = React.ComponentProps<typeof Field>;

type RadiosProps = React.ComponentProps<typeof Radios>;

const FieldWrapper = React.forwardRef<any, FieldProps>(({ className, ...props }, ref) => {
  // This is needed in order to make regular `useRef` work well with the legacy functional refs that
  // are expected by swan form.
  const setRef = React.useCallback(
    (el: HTMLElement) => {
      // This is already a function ref, so, just call it
      if (typeof ref === 'function') {
        return ref(el);
      }

      // This is ref object, so let's do what React would do
      if (typeof ref === 'object' && ref !== null && 'current' in ref) {
        ref.current = el;
      }

      // We'll get here, legitimately, only if there is no ref. So, the rest of this
      // would be a noop
    },
    [ref],
  );
  return <Field {...props} className={cx(styles.standard, className)} setRef={setRef} />;
});

FieldWrapper.displayName = 'Field';

const RadioWrapper = React.forwardRef<any, RadiosProps>(({ className, inline, ...props }, ref) => {
  return (
    <Radios
      {...props}
      className={cx(styles.standard, styles.radios, inline && styles.inlineRadios, className)}
      setRef={ref}
    />
  );
});

RadioWrapper.displayName = 'Radios';

export const Select = React.forwardRef<any, FieldProps>(({ className, ...props }, ref) => {
  return <Field {...props} className={cx(styles.standard, className)} setRef={ref} type="select" />;
});

Select.displayName = 'Select';

export const Checkbox = React.forwardRef<HTMLInputElement, any>((props, forwardedRef) => {
  const ref = useForwardedRef<HTMLInputElement>(forwardedRef as React.RefObject<HTMLInputElement>);
  const { indeterminate, className, ...rest } = props;

  React.useEffect(() => {
    if (ref.current) {
      ref.current.indeterminate = indeterminate;
    }
  }, [indeterminate, ref]);

  return (
    <FieldWrapper
      {...rest}
      className={cx(indeterminate && styles.indeterminate, className)}
      type="checkbox"
    />
  );
});

Checkbox.displayName = 'Checkbox';

export const CurrencyField = React.forwardRef<
  HTMLInputElement,
  Omit<MaskedFieldProps, 'format' | 'defaultValue' | 'value'> & {
    defaultValue?: string | number | MajorCurrency;
    value?: string | number | MajorCurrency;
  }
>((props, ref) => {
  const { defaultValue = '0', ...rest } = props;
  const { locale, simple, parseFloatLocale } = useCurrencyFormatter();

  const sanitizer = React.useMemo(() => createFloatString(locale), [locale]);
  const simpleFormatter = React.useCallback(
    (value?: string) => {
      if (typeof value !== 'undefined') {
        const val = parseFloatLocale(value);
        return simple(Number.isNaN(val) ? 0 : val);
      }

      return value;
    },
    [simple, parseFloatLocale],
  );

  return (
    <MaskedField
      {...rest}
      ref={ref}
      defaultValue={typeof defaultValue === 'number' ? `${defaultValue}` : defaultValue}
      format={simpleFormatter}
      placeholder="0"
      sanitizer={sanitizer}
      type="text"
    />
  );
});

CurrencyField.displayName = 'CurrencyField';

export const PercentField = React.forwardRef<
  HTMLInputElement,
  Omit<MaskedFieldProps, 'format' | 'defaultValue' | 'value'> & {
    defaultValue?: string | number;
    value?: string | number;
    /**
     * Number 0=11 (how many decimals)
     * @default 2
     */
    precision?: number;
  }
>((props, ref) => {
  const { defaultValue = '0', precision = 2, ...rest } = props;
  const { locale, parseFloatLocale } = useCurrencyFormatter();

  const sanitizer = React.useMemo(() => createFloatString(locale), [locale]);
  const percentageFormatter = React.useCallback(
    (value?: string) => {
      if (typeof value !== 'undefined') {
        const val = parseFloatLocale(value);
        return percentage((Number.isNaN(val) ? 0 : val) / 100, precision);
      }

      return value;
    },
    [parseFloatLocale, precision],
  );

  return (
    <MaskedField
      {...rest}
      ref={ref}
      defaultValue={typeof defaultValue === 'number' ? `${defaultValue}` : defaultValue}
      format={percentageFormatter}
      placeholder="0"
      sanitizer={sanitizer}
      type="text"
    />
  );
});

PercentField.displayName = 'PercentField';

type ShowHideButtonProps = {
  togglePasswordVisibility: () => void;
  showPassword: boolean;
};
const ShowHideButton = ({ togglePasswordVisibility, showPassword }: ShowHideButtonProps) => {
  return (
    <Button
      small
      aria-label="Toggle password visibility"
      name="toggle password"
      tabIndex={-1}
      variant="svg"
      onClick={togglePasswordVisibility}
    >
      <Icon
        aria-hidden
        id="show-password-visibility"
        size={20}
        type={showPassword ? 'visibilityOff' : 'visibility'}
      />
    </Button>
  );
};

ShowHideButton.displayName = 'ShowHideButton';

export const Password = React.forwardRef<HTMLInputElement, FieldProps>(
  ({ className, ...props }, forwardRef) => {
    const ref = useForwardedRef<HTMLInputElement>(forwardRef);
    const { active: showPassword, toggle: togglePasswordVisibility } = useToggle(false);
    React.useEffect(() => {
      if (ref && ref.current) {
        ref.current.removeAttribute('value');
      }
    });

    return (
      <Field
        {...props}
        className={cx(styles.standard, className)}
        icon={
          <ShowHideButton
            showPassword={showPassword}
            togglePasswordVisibility={togglePasswordVisibility}
          />
        }
        setRef={ref}
        type={showPassword ? 'text' : 'password'}
      />
    );
  },
);

Password.displayName = 'Password';

export { FieldWrapper as Field, RadioWrapper as Radios };

export default FieldWrapper;
