import * as React from 'react';

import { upperFirst } from '@cobbler-io/utils/src/string/upperFirst';

import { InfoCallout, WarningCallout } from '@cobbler-io/core-ui/src/Callout';
import { Password } from '@cobbler-io/core-ui/src/Field';
import { Meter } from '@cobbler-io/core-ui/src/Meter';

import { ZXCVBNResult } from 'zxcvbn';

type SetPasswordProps = Record<string, unknown>;

const returnTrue = () => ({ feedback: {}, crack_times_display: {} } as ZXCVBNResult);

const useComplexityChecker = () => {
  const ref = React.useRef<(password: string) => ZXCVBNResult>(returnTrue);

  React.useEffect(() => {
    import('zxcvbn').then(zxcvbn => {
      ref.current = zxcvbn.default;
    });
  }, [ref]);

  return ref;
};

const getLetters = (str: string) => str.replace(/[^a-zA-Z\u00C0-\u00ff]/gu, '');

const countLength = (str: string) => str.length;
const countLowerCase = (str: string) => {
  const letters = getLetters(str);
  let count = 0;
  for (let i = 0; i < letters.length; i++) {
    const char = letters[i];
    if (char.toLocaleLowerCase() === char) {
      count++; // eslint-disable-line no-plusplus
    }
  }
  return count;
};

const countUpperCase = (str: string) => {
  const letters = getLetters(str);
  let count = 0;
  for (let i = 0; i < letters.length; i++) {
    const char = letters[i];
    if (char.toLocaleUpperCase() === char) {
      count++; // eslint-disable-line no-plusplus
    }
  }
  return count;
};

const countNumbers = (str: string) => {
  const numbers = str.replace(/\D/g, '');
  return numbers.length;
};

const countSymbols = (str: string) => {
  const symbols = str.replace(/[0-9a-zA-Z\u00C0-\u00ff]/gu, '');
  return symbols.length;
};

const analyzePassword = (str: string) => {
  return {
    length: countLength(str),
    lowercase: countLowerCase(str),
    uppercase: countUpperCase(str),
    numeric: countNumbers(str),
    symbols: countSymbols(str),
  };
};

type PasswordRequirements = {
  minimumLength: number;
  minimumLowercase: number;
  minimumUppercase: number;
  minimumNumeric: number;
  minimumSymbols: number;
};

const createValidatePassword = (requirements: PasswordRequirements) => (password: string) => {
  const analysis = analyzePassword(password);

  const checks = Object.entries(analysis).map(([key, value]) => {
    const required = requirements[`minimum${upperFirst(key)}`];
    return required <= value ? false : `Minimum ${key}: ${required}`;
  });

  return checks.filter(Boolean) as string[];
};

const defaultRequirements = {
  minimumLength: 12,
  minimumLowercase: 1,
  minimumUppercase: 1,
  minimumNumeric: 1,
  minimumSymbols: 1,
};

const strength = {
  0: 'Worst',
  1: 'Bad',
  2: 'Weak',
  3: 'Good',
  4: 'Strong',
};

export const SetPassword = (_: SetPasswordProps) => {
  const [password, setPassword] = React.useState('');

  const checker = useComplexityChecker();

  const validatePassword = React.useCallback(createValidatePassword(defaultRequirements), [
    defaultRequirements,
  ]);

  const validateConfirmPassword = React.useCallback(
    confirmPassword => (password === confirmPassword ? false : 'Passwords do not match'),
    [password],
  );

  const {
    feedback: { warning, suggestions },
    score: passwordScore,
    crack_times_display: { online_no_throttling_10_per_second: crackTime },
  } = checker.current(password);

  const score = validatePassword(password).length ? 0 : passwordScore;

  return (
    <div>
      <div>
        <div>
          <Password
            validateOnChange
            autoComplete="new-password"
            label="New password"
            name="password"
            validate={validatePassword}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setPassword(event.currentTarget.value)
            }
          />
        </div>

        <div>
          {/* We need to improve the experience of validating this. It should be a validate on blur for the first time. After that, it needs to revalidate when the password changes, and it also should be a validate on change when either password or confirm password changes */}
          <Password
            validateOnChange
            autoComplete="new-password"
            label="Confirm password"
            name="confirm-password"
            validate={validateConfirmPassword}
          />
        </div>
      </div>

      <div>
        <div>
          {password && strength[score]}
          <Meter
            low={2}
            max={4}
            optimum={4}
            title={crackTime ? `Time to crack: ${crackTime}` : ''}
            value={score}
          />
        </div>
        <div>&nbsp;</div>
        <div>
          {Boolean(password && warning) && <WarningCallout>{warning}</WarningCallout>}
          {Boolean(password && suggestions && suggestions.length) &&
            suggestions.map((suggestion: string) => (
              <InfoCallout key={suggestion}>{suggestion}</InfoCallout>
            ))}
        </div>
      </div>
    </div>
  );
};

SetPassword.displayName = 'SetPassword';
