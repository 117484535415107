import * as React from 'react';

import { execIfFunc } from '@cobbler-io/utils/src/execIfFunc';

export type UseTogglePayload = {
  active: boolean;
  activate: () => void;
  deactivate: () => void;
  set: (on?: boolean) => void;
  toggle: () => void;
};

type UseToggle = (initial?: boolean, onChange?: (active: boolean) => void) => UseTogglePayload;

export const useToggle: UseToggle = (initial, onChange) => {
  const [active, setActive] = React.useState(initial ?? false);

  const actions = React.useMemo(
    () => ({
      activate: () => {
        setActive(_ => {
          execIfFunc(onChange, true);
          return true;
        });
      },
      deactivate: () => {
        setActive(_ => {
          execIfFunc(onChange, false);
          return false;
        });
      },
      set: (on: boolean) => {
        setActive(prev => {
          if (prev === on) {
            return prev;
          }

          execIfFunc(onChange, on);
          return on;
        });
      },
      toggle: () => {
        setActive(prev => {
          execIfFunc(onChange, !prev);
          return !prev;
        });
      },
    }),
    [setActive, onChange],
  );

  return { active, ...actions };
};

export default useToggle;
