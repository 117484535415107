import * as React from 'react';

import { date } from '@cobbler-io/formatters/src/dates';

import Button from '@cobbler-io/core-ui/src/Button';
import Heading from '@cobbler-io/core-ui/src/Heading';
import { useCurrentModal } from '@cobbler-io/core-ui/src/Modal';
import { useNotification } from '@cobbler-io/core-ui/src/Notification';

import {
  MatchActualsMutation, useMatchActualsMutation,
} from '@cobbler-io/app/src/api/graphql-types';
import {
  BudgetLineDropdown, BudgetLinesTreeFilterFunction,
} from '@cobbler-io/app/src/ndm/components/BudgetLineDropdown';
import { useCurrentBudgetLine } from '@cobbler-io/app/src/providers/CurrentBudgetLineProvider';
import { budgetUrls } from '@cobbler-io/app/src/urls/urls';
import {
  useNormalizedNavigate as useNavigate,
} from '@cobbler-io/app/src/utils/useNormalizedNavigate';

import { useMatch } from '@reach/router';
import Form from '@swan-form/form';

import styles from './MoveTransactionModal.scss';

const filterLinesWithUpdateAccess: BudgetLinesTreeFilterFunction = line =>
  !!line.permissions?.canUpdate;

type MoveTransactionFormValues = {
  budgetLineId: BudgetLineId;
};

type SkippedActual = MatchActualsMutation['a_matchActualsToBudgetLine']['errors'][number];

export type MoveTransactionModalProps = {
  actualIds: ActualId[];
  budgetLineId: BudgetLineId;
  refetch: () => Promise<any>;
};

// eslint-disable-next-line max-lines-per-function
export const MoveTransactionModal = (props: MoveTransactionModalProps): JSX.Element => {
  const { actualIds, budgetLineId, refetch } = props;
  const { length } = actualIds;
  const [error, setError] = React.useState<string | null>(null);
  const [skipped, setSkipped] = React.useState<readonly SkippedActual[]>([]);
  const notify = useNotification();
  const container = useCurrentModal();
  const [mutate, { loading: isSubmitting }] = useMatchActualsMutation();
  const navigateTo = useNavigate();
  const match = useMatch(budgetUrls.actualDetails.path);
  const budgetLineContext = useCurrentBudgetLine();

  if (!length || !budgetLineId) {
    console.error('Invalid props passed to MoveTransactionModal');
    return (
      <div className={styles.container}>
        <Heading as="h2" size="headline">
          Error
        </Heading>
        <p>Unable to load data, please close this window and try again.</p>
        <div className={styles.actions}>
          <Button name="actuals-match-modal-cancel" variant="text" onClick={container?.close}>
            Close
          </Button>
        </div>
      </div>
    );
  }

  const handleSubmit = async (values: MoveTransactionFormValues) => {
    setError(null);

    // If no changes, we just close the window
    if (budgetLineId === values.budgetLineId) {
      container?.close();
    }

    try {
      const { data } = await mutate({
        variables: {
          actualIds,
          budgetLineId: values.budgetLineId,
        },
      });

      // If we're editing a single actual and we arrived here from the actuals
      // drawer, update the drawer's data.
      const actuals = data?.a_matchActualsToBudgetLine.matchedActuals || [];
      const errors = data?.a_matchActualsToBudgetLine.errors || [];
      if (match?.actualId && actuals.length === 1) {
        navigateTo(budgetLineContext!.urls.actualDetails({ actualId: actuals[0].id }));
      }
      refetch();
      notify({ body: `Your transactions were matched successfully."`, title: 'Success' });

      if (!errors.length) {
        queueMicrotask(() => {
          container?.close();
        });
      } else {
        setSkipped(errors);
      }
    } catch (err) {
      console.error('Could not match actuals', err);
      setError('There was an error matching the transaction(s). Please try again.');
    }
  };

  const defaultValues: MoveTransactionFormValues = {
    budgetLineId,
  };

  const showSummary = skipped.length > 0;
  const allFailed = skipped.length >= actualIds.length;

  if (showSummary) {
    return (
      <div className={styles.container}>
        <header>
          <Heading size="title">{allFailed ? 'Error' : 'Summary'}</Heading>
          <Heading as="h3" size="body-large">
            {allFailed
              ? 'None of your transactions were moved:'
              : `Your transactions were moved successfully but ${skipped.length} out of ${actualIds.length} were skipped:`}
          </Heading>
        </header>
        <div className={styles.summaryItems}>
          {skipped.map(item => (
            <div key={item.actualId} className={styles.skippedActual}>
              <span>{item.actualEffectiveDate && date(new Date(item.actualEffectiveDate))}</span>
              <h3>{item.actualDescription || <em>No description</em>}</h3>
              <p>{item.failReason}</p>
            </div>
          ))}
        </div>
        <div className={styles.actions}>
          <Button name="actuals-match-modal-ok" onClick={container?.close}>
            Ok
          </Button>
        </div>
      </div>
    );
  }

  return (
    <Form
      className={styles.container}
      defaultValues={defaultValues}
      name="actuals-match-form"
      onSubmit={handleSubmit}
    >
      <Heading as="h2" size="title">
        {length > 1 ? `Move ${length} transactions` : 'Move transaction'}
      </Heading>
      <BudgetLineDropdown
        budgetLineId={budgetLineId}
        defaultValue={actualIds.length > 1 ? undefined : budgetLineId}
        filterBudgetLines={filterLinesWithUpdateAccess}
        label="Move to"
        name="budgetLineId"
        placeholder="Select a budget line"
      />

      {error && <p className={styles.error}>{error}</p>}

      <div className={styles.actions}>
        <Button name="actuals-match-modal-cancel" variant="text" onClick={container?.close}>
          Cancel
        </Button>
        <Button 
          disabled={isSubmitting} 
          name="actuals-match-modal-move"
          type="submit"
        >
          Move
        </Button>
      </div>
    </Form>
  );
};
MoveTransactionModal.displayName = 'MoveTransactionModal';
