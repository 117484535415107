/* eslint-disable sort-keys */
/* eslint-disable sort-keys-fix/sort-keys-fix */

import { ISO8601 } from '@cobbler-io/formatters/src/dates';

import {
  getFiscalYearId, getMonthId, getPeriodsInRange, getQuarterId,
} from '@cobbler-io/dates/src/getPeriodsInRange';
import { tzAdjust } from '@cobbler-io/dates/src/tzAdjust';

import {
  FiscalYearVertex, MonthInQuarterEdge, MonthInYearEdge, MonthVertex, QuarterInYearEdge,
  QuarterVertex, TimeProperties,
} from '@cobbler-io/app/src/api/graph';

import { Graph } from '@cobbler-io/collection/src/Graph';

type CreateTimeVerticesParams = {
  graph: Graph<any, any>;
  start: ISO8601String;
  end: ISO8601String;
  startMonth: JSMonth;
};

export { getFiscalYearId, getMonthId, getQuarterId };

/**
 * Creates vertices that represent months, quarters, and years
 */
export const createTimeVertices = (params: CreateTimeVerticesParams): void => {
  const { graph, start, end, startMonth } = params;
  const { months, quarters, fiscalYears } = getPeriodsInRange(
    tzAdjust(start),
    tzAdjust(end),
    startMonth,
  );

  fiscalYears.forEach(year => {
    graph.addVertex<TimeProperties>({
      id: year.id,
      labels: ['FISCAL_YEAR'],
      properties: {
        name: year.name,
        shortName: year.shortName,
        start: ISO8601(year.start),
        startDate: year.start,
        end: ISO8601(year.end),
        endDate: year.end,
        range: year.range,
        type: 'fiscal-year',
      },
    }) as FiscalYearVertex;
  });

  quarters.forEach(quarter => {
    const q = graph.addVertex<TimeProperties>({
      id: quarter.id,
      labels: ['QUARTER'],
      properties: {
        name: quarter.name,
        shortName: quarter.shortName,
        start: ISO8601(quarter.start),
        end: ISO8601(quarter.end),
        startDate: quarter.start,
        endDate: quarter.end,
        range: quarter.range,
        type: 'quarter',
      },
    }) as QuarterVertex;

    const fyId = getFiscalYearId(quarter.start, startMonth);
    const year = graph.getVertexById<FiscalYearVertex>(fyId)!;

    graph.addEdge({
      id: ['QUARTER_IN_YEAR', quarter.quarter, quarter.fiscalYear].join('-'),
      labels: ['QUARTER_IN_YEAR'],
      properties: {},
      from: year,
      to: q,
    }) as QuarterInYearEdge;
  });

  months.forEach(month => {
    const m = graph.addVertex<TimeProperties>({
      id: month.id,
      labels: ['MONTH'],
      properties: {
        name: month.name,
        shortName: month.shortName,
        start: ISO8601(month.start),
        startDate: month.start,
        end: ISO8601(month.end),
        endDate: month.end,
        range: month.range,
        type: 'month',
      },
    }) as MonthVertex;

    const fyId = getFiscalYearId(month.start, startMonth);
    const quarterId = getQuarterId(month.start, startMonth);

    const year = graph.getVertexById<FiscalYearVertex>(fyId)!;
    const quarter = graph.getVertexById<QuarterVertex>(quarterId)!;

    graph.addEdge({
      id: ['MONTH_IN_YEAR', month.name, month.fiscalYear].join('-'),
      labels: ['MONTH_IN_YEAR'],
      properties: {},
      from: m,
      to: year,
    }) as MonthInYearEdge;

    graph.addEdge({
      id: ['MONTH_IN_QUARTER', month.name, month.quarter, month.fiscalYear].join('-'),
      labels: ['MONTH_IN_QUARTER'],
      properties: {},
      from: m,
      to: quarter,
    }) as MonthInQuarterEdge;
  });
};
