/* eslint-disable functional/immutable-data */
import { drawCanvasBadge, DrawCanvasBadgeOptions } from './drawCanvasBadge';

export const getImageWithBadge = async (
  src: string,
  badgeOptions: DrawCanvasBadgeOptions,
): Promise<HTMLImageElement> => {
  const img = new Image();
  img.src = src;
  await img.decode();
  const { width, height } = img;
  const canvas = document.createElement('canvas');
  canvas.width = width;
  canvas.height = height;
  const ctx = canvas.getContext('2d');

  if (!ctx || !badgeOptions.text) {
    return img;
  }

  ctx.drawImage(img, 0, 0);
  drawCanvasBadge(ctx, {
    hAlign: 'right',
    x: width,
    y: 0,
    ...badgeOptions,
  });

  img.src = canvas.toDataURL();
  await img.decode();
  return img;
};
