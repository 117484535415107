import * as fp from '../../fp';
import { isElement } from '../isElement';
import { Traversal } from '../Traversal';
import { Traverser } from '../Traverser';
import { execute } from './execute';
import { StepGenus } from './types';

const genus: StepGenus = 'map';
const species = 'label';

export const label = (): UnaryFn<Traversal<any, any>> => {
  const callback = function* (prev: Iterable<Traverser<any>>) {
    for (const t of prev) {
      const element = t.get();

      if (isElement(element)) {
        // For, Vertices and Edges, we'll yield the labels
        yield* fp.map((value: string) => t.clone({ value }), element.labels);
      } else if (typeof element === 'object' && element !== null) {
        // If it's an actual object, then we'll yield to the keys
        yield* fp.map((value: string) => t.clone({ value }), Object.keys(element));
      }
    }
  };

  const addStep = (t: Traversal) => t.addStep({ args: [], callback, genus, species });

  return execute(addStep, callback);
};
