import * as React from 'react';
import * as ReactDOM from 'react-dom';

import { getScrollbarSize } from '@cobbler-io/utils/src/getScrollBarSize';

export type MeasureScrollbarProps = {
  children?: React.ReactNode;
};

const getStyle = (size: number) => `
:root {
  --scrollbar-size: ${size}px;
}
`;

export const MeasureScrollbar = () => {
  const [scrollBarSize, setScrollbarSize] = React.useState<number>(8);
  React.useEffect(() => {
    const size = getScrollbarSize();
    if (scrollBarSize !== size) {
      setScrollbarSize(_ => scrollBarSize);
    }
  }, [scrollBarSize]);

  return ReactDOM.createPortal(
    // eslint-disable-next-line react/no-danger
    <style dangerouslySetInnerHTML={{ __html: getStyle(scrollBarSize) }} />,
    document.head,
  );
};

MeasureScrollbar.displayName = 'MeasureScrollbar';

export default MeasureScrollbar;
