import { useSelector } from 'react-redux';

import { getRevisionTag } from './getRevisionTag';
import { revisionsSelector } from './revisionsSelector';
import { BudgetRevisionTag } from './types';

export function useGetRevisionTag(revisionId: BudgetRevisionId): BudgetRevisionTag;
export function useGetRevisionTag(): UnaryFn<BudgetRevisionId, BudgetRevisionTag>;
export function useGetRevisionTag(
  revisionId?: BudgetRevisionId,
): BudgetRevisionTag | UnaryFn<BudgetRevisionId, BudgetRevisionTag> {
  const revisions = useSelector(revisionsSelector);

  if (!revisions) {
    throw new Error('Cannot load forecast information');
  }

  if (!revisionId) {
    return (x: BudgetRevisionId): BudgetRevisionTag => getRevisionTag(revisions, x);
  }

  return getRevisionTag(revisions, revisionId);
}
