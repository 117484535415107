/* eslint-disable no-bitwise */

export const hasFlag =
  (flag: number): ((x0: number) => boolean) =>
  x0 =>
    (x0 & flag) === flag;

export const hasFlags =
  (...flags: number[]): ((x0: number) => boolean) =>
  x0 => {
    for (const flag of flags) {
      if ((x0 & flag) !== flag) {
        return false;
      }
    }

    return true;
  };
