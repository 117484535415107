import { useContext } from 'react';

import { ModalContext } from './ModalContext';
import { ModalContextType, ModalOptions, ModalTreeNode, ModalType } from './types';

/* eslint-disable func-style */
export function useModal(): ModalContextType;
export function useModal(modal: ModalType, options?: ModalOptions): () => ModalTreeNode;
export function useModal(
  modal?: ModalType,
  options?: ModalOptions,
): ModalContextType | (() => ModalTreeNode) {
  const ctx = useContext(ModalContext);

  if (!modal && !options) {
    return ctx;
  }

  return () => ctx.create(modal as ModalType, options);
}
/* eslint-enable func-style */
