/* eslint-disable camelcase */
import { identity } from '@cobbler-io/utils/src/identity';

import {
  EditAccountsMutation, EditLineAccountsInputType, Exact, useEditAccountsMutation,
  useGetAccountsQuery,
} from '@cobbler-io/app/src/api/graphql-types';
import { AccountPicker } from '@cobbler-io/app/src/ndm/components/AccountPicker';
import {
  RangeContextType, useCurrentRange,
} from '@cobbler-io/app/src/providers/CurrentRangeProvider';
import { toDateToken } from '@cobbler-io/app/src/utils/toDateToken';

import { MutationUpdaterFn } from 'apollo-client';
import gql from 'graphql-tag';
import { map, pluck, prop } from 'ramda';
import { CellProps } from 'react-table';

import { EditorLine } from '../types';
import {
  acceptedTextKeys, EditorInput, ModalEditorHandlers, useModalEditor,
} from './useModalEditor';

type OptResp<Input, Payload> = (
  vars: Exact<{
    input: Input;
  }>,
) => Payload;

type IntermediateArgs = {
  accounts: {
    id: AccountId;
    __typename: 'AccountType';
    displayCode: string;
    displayName: string;
  }[];
  range: RangeContextType;
};

type Updater<T> = (params: IntermediateArgs) => (cellProps: CellProps<EditorLine>) => T;

/**
 * Creates an optimistic response for the mutation, which is basically the return
 * of the mutation
 * @see https://www.apollographql.com/docs/react/v2/performance/optimistic-ui/
 */
const optimisticResponse: Updater<OptResp<EditLineAccountsInputType, EditAccountsMutation>> =
  ({ accounts }) =>
  cellProps =>
  ({ input }): EditAccountsMutation => {
    const { accountIds } = input;
    const response: EditAccountsMutation = {
      __typename: 'Mutation' as const,
      a_editLineAccounts: {
        __typename: 'EditLineAccountsPayloadType' as const,
        // This should be the budgetLineId
        accounts: accountIds
          .map(x => {
            const account = accounts.find(y => x === y.id);
            if (account) {
              return { ...account };
            }
            return null;
          })
          .filter(Boolean) as EditorLine['accounts'],
        id: cellProps.row.original.id,
      },
    };
    return response;
  };

const BUDGET_LINE_FRAGMENT = gql`
  fragment readBudgetLineAccounts on BudgetEditorLineType {
    __typename
    id
    accounts {
      ... on AccountType {
        __typename
        id
        displayCode
        displayName
      }
    }
  }
`;

/**
 * The update receives the input, and returns a function that will update the cache.
 *
 * It's weird, but I need the budgetLineId, which is not in the response
 *
 * This seems to work, but every once in awhile it seems to do nothing. There might have
 * been some cache evictions.
 */
const update: Updater<MutationUpdaterFn<EditAccountsMutation>> =
  () => cellProps => (cache, result) => {
    const budgetLineId = cellProps.row.original.id;
    /**
     * Updates the columns on the BudgetEditorTable
     */
    const updateBudgetEditor = () => {
      const { a_editLineAccounts } = result.data!;
      const { accounts: nextAccounts } = a_editLineAccounts;

      const fragmentId = ['BudgetEditorLineType', budgetLineId].join(':');
      const lineFragment = cache.readFragment({
        fragment: BUDGET_LINE_FRAGMENT,
        id: fragmentId,
      });

      cache.writeFragment({
        data: { ...(lineFragment as Record<string, unknown>), accounts: [...nextAccounts] },
        fragment: BUDGET_LINE_FRAGMENT,
        id: fragmentId,
      });
    };

    /**
     * Updates the columns on the BudgetOverviewTable
     */
    const updateBudgetOverview = () => {};

    updateBudgetEditor();
    updateBudgetOverview();
  };

const getIds = pluck('id');

type RelativeEditorParams = {
  getWeight: NullaryFn<number>;
};

export const useEditAccounts = (params: RelativeEditorParams): ModalEditorHandlers => {
  const { data } = useGetAccountsQuery({ fetchPolicy: 'cache-first' });
  const { getWeight } = params;
  const allAccounts = data?.a_accounts?.items ?? [];
  const toValue = identity;
  const range = useCurrentRange();

  const getDefaultValue = (cellValue: EditorLine['accounts']): AccountId[] => {
    return Array.isArray(cellValue) ? getIds(cellValue) : [];
  };

  const createInput = (p: {
    value: AccountId[];
    cellProps: CellProps<EditorLine, EditorLine['accounts']>;
  }) => ({
    accountIds: p.value,
    end: toDateToken(range.end),
    id: p.cellProps.row.original.id,
    start: toDateToken(range.start),
  });

  return useModalEditor<
    EditLineAccountsInputType,
    EditAccountsMutation,
    EditorInput,
    EditorLine['accounts']
  >({
    Field: AccountPicker,
    acceptedKeysHandler: acceptedTextKeys,
    createInput,
    getDefaultValue,
    getWeight,
    optimisticResponse: optimisticResponse({ accounts: allAccounts, range }),
    shouldDisable: cell => cell.row.original.linkedToDriver,
    toValue,
    update: update({ accounts: allAccounts, range }),
    useMutationHook: useEditAccountsMutation,
  });

  // Basically, this should be the inner cell. We need to make it so that when the focus lands on
  // the outer cell, we check for keys. If it's valid to start editing, then we automatically switch
  // to the inner cell and start editing. If it's not, then, well, we do nothing.
};
