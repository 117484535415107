/* eslint-disable camelcase */
import { identity } from '@cobbler-io/utils/src/identity';

import { isVendorId } from '@cobbler-io/app/src/api';
import {
  EditLineVendorsInputType, EditVendorsMutation, Exact, GetAllVendorsDocument, GetAllVendorsQuery,
  useEditVendorsMutation, useGetAllVendorsQuery,
} from '@cobbler-io/app/src/api/graphql-types';
import { VendorPicker } from '@cobbler-io/app/src/ndm/components/VendorPicker';
import {
  RangeContextType, useCurrentRange,
} from '@cobbler-io/app/src/providers/CurrentRangeProvider';
import { toDateToken } from '@cobbler-io/app/src/utils/toDateToken';

import { MutationUpdaterFn } from 'apollo-client';
import gql from 'graphql-tag';
import { indexBy, pathOr, pluck, prop } from 'ramda';
import { CellProps } from 'react-table';

import { EditorLine } from '../types';
import {
  acceptedTextKeys, EditorInput, ModalEditorHandlers, useModalEditor,
} from './useModalEditor';

type OptResp<Input, Payload> = (
  vars: Exact<{
    input: Input;
  }>,
) => Payload;

type IntermediateArgs = {
  vendors: {
    [id: string]: {
      readonly __typename: 'VendorType';
      readonly id: VendorId;
      readonly name: string;
    };
  };
  range: RangeContextType;
};

type Updater<T> = (params: IntermediateArgs) => (cellProps: CellProps<EditorLine>) => T;

/**
 * Creates an optimistic response for the mutation, which is basically the return
 * of the mutation
 * @see https://www.apollographql.com/docs/react/v2/performance/optimistic-ui/
 */
const optimisticResponse: Updater<OptResp<EditLineVendorsInputType, EditVendorsMutation>> =
  ({ vendors: allVendors }) =>
  cellProps =>
  ({ input }): EditVendorsMutation => {
    const { vendors } = input;
    const response: EditVendorsMutation = {
      __typename: 'Mutation' as const,
      a_editLineVendors: {
        __typename: 'EditLineVendorsPayloadType' as const,
        id: cellProps.row.original.id, // This should be the budgetLineId
        planningVersions: [
          // we need to fake the planning versions here...
        ],
        vendors: vendors.map(x => {
          return isVendorId(x)
            ? allVendors[x]
            : {
                __typename: 'VendorType',
                id: x,
                name: x,
              };
        }),
      },
    };
    return response;
  };

const BUDGET_LINE_FRAGMENT = gql`
  fragment readBudgetLineVendors on BudgetEditorLineType {
    __typename
    id
    vendors {
      ... on VendorType {
        __typename
        id
        name
      }
    }
  }
`;

const VENDOR_TYPE_FRAGMENT = gql`
  fragment partialVendor on VendorType {
    __typename
    id
    name
  }
`;

/**
 * The update receives the input, and returns a function that will update the cache.
 *
 * It's weird, but I need the budgetLineId, which is not in the response
 *
 * This seems to work, but every once in awhile it seems to do nothing. There might have
 * been some cache evictions.
 */
const update: Updater<MutationUpdaterFn<EditVendorsMutation>> =
  ({ vendors }) =>
  cellProps =>
  (cache, result) => {
    const budgetLineId = cellProps.row.original.id;

    const updateVendorsList = () => {
      const newVendors =
        result.data?.a_editLineVendors.vendors.filter(x => isVendorId(x.id) && !vendors[x.id]) ??
        [];
      newVendors.forEach(x => {
        cache.writeFragment({
          data: { ...x },
          fragment: VENDOR_TYPE_FRAGMENT,
          id: ['VendorType', x.id].join(':'),
        });
      });

      const oldQuery = cache.readQuery<GetAllVendorsQuery>({
        query: GetAllVendorsDocument,
      });

      if (newVendors.length && oldQuery?.a_vendors?.items) {
        cache.writeQuery({
          data: {
            a_vendors: {
              __typename: 'VendorConnection',
              items: [...oldQuery.a_vendors.items, ...newVendors],
              totalCount: oldQuery.a_vendors.totalCount! + newVendors.length,
            },
          },
          query: GetAllVendorsDocument,
        });
      }
    };

    /**
     * Updates the columns on the BudgetEditorTable
     */
    const updateBudgetEditor = () => {
      // We need to do different logic for updating a temporary record with an ID
      // that we generated vs a record that is canonical. This function will
      // update both on the optimistic response as well as the regular response,
      // so we need to switch the logic based on the context
      const { a_editLineVendors } = result.data!;
      const { vendors: nextVendors } = a_editLineVendors;

      const fragmentId = ['BudgetEditorLineType', budgetLineId].join(':');
      const lineFragment = cache.readFragment({
        fragment: BUDGET_LINE_FRAGMENT,
        id: fragmentId,
      });

      cache.writeFragment({
        data: {
          ...(lineFragment as Record<string, unknown>),
          vendors: [...nextVendors],
        },
        fragment: BUDGET_LINE_FRAGMENT,
        id: fragmentId,
      });
    };

    /**
     * Updates the columns on the BudgetOverviewTable
     */
    const updateBudgetOverview = () => {};

    updateVendorsList();
    updateBudgetEditor();
    updateBudgetOverview();
  };

const getIds = pluck('id');
const keyById = <T extends { id: string; [key: string]: any }>(list: T[] | readonly T[]) =>
  indexBy(prop('id'), list);

const shouldDisable = (cellProps: CellProps<EditorLine>) =>
  cellProps.row.original.linkedToDriver ||
  pathOr(false, ['row', 'original', 'isRevenue'], cellProps);

type RelativeEditorParams = {
  getWeight: NullaryFn<number>;
};

export const useEditVendors = (params: RelativeEditorParams): ModalEditorHandlers => {
  const { data } = useGetAllVendorsQuery({ fetchPolicy: 'cache-first' });
  const allVendors = keyById(data?.a_vendors?.items ?? []);
  const toValue = identity;
  const range = useCurrentRange();
  const { getWeight } = params;

  const getDefaultValue = (cellValue: EditorLine['vendors']): AccountId[] => {
    return Array.isArray(cellValue) ? getIds(cellValue) : [];
  };

  const createInput = (params: {
    value: string[];
    cellProps: CellProps<EditorLine, EditorLine['vendors']>;
  }): EditLineVendorsInputType => ({
    end: toDateToken(range.end),
    id: params.cellProps.row.original.id,
    start: toDateToken(range.start),
    vendors: params.value,
  });

  return useModalEditor<
    EditLineVendorsInputType,
    EditVendorsMutation,
    EditorInput,
    Writeable<EditorLine['vendors']>
  >({
    Field: VendorPicker,
    acceptedKeysHandler: acceptedTextKeys,
    createInput,
    getDefaultValue,
    getWeight,
    optimisticResponse: optimisticResponse({ range, vendors: allVendors }),
    shouldDisable,
    toValue,
    update: update({ range, vendors: allVendors }),
    useMutationHook: useEditVendorsMutation,
  });

  // Basically, this should be the inner cell. We need to make it so that when the focus lands on
  // the outer cell, we check for keys. If it's valid to start editing, then we automatically switch
  // to the inner cell and start editing. If it's not, then, well, we do nothing.
};
