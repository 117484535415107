import { bankersRound } from '@cobbler-io/utils/src/numbers/bankersRound';

import {
  convertFromMinorUnit as convertFromMinorUnitUnbound,
  convertToMinorUnit as convertToMinorUnitUnbound,
  createCurrencyFormatter,
  createFinancialCurrencyFormatters,
  createSimpleCurrencyFormatter,
  CurrencyCode,
  currencyCodes,
  getMinorUnit,
  NumberFormatter,
  parseFloatLocale,
} from '@cobbler-io/formatters/src/currencies';
import { Locale, LOCALES } from '@cobbler-io/formatters/src/locales';

import { useDispatch, useSelector } from 'react-redux';
import { ActionCreator, Reducer } from 'redux';

export const getDefaultLocale = (): Locale => {
  return typeof window !== 'undefined'
    ? (window.navigator.language as Locale)
    : ('en-US' as Locale);
};

type CurrencyFormatters = {
  currencyCode: CurrencyCode;
  locale: Locale;
  /**
   * The number of decimals allowed in a particular currency. It's almost always `2`
   */
  minorUnit: number;
  /**
   * A bound version of bankers round that always rounds to the minor unit
   */
  bankersRound: (x: Float) => Float;
  parseFloatLocale: (x: string) => Float;
  formatFinancial: (x: number, displayCurrencySymbol?: boolean | undefined) => string;
  formatFinancialRounded: (x: number, displayCurrencySymbol?: boolean | undefined) => string;
  currency: NumberFormatter;
  currencyRounded: NumberFormatter;
  simple: (x: string | number) => string;
  convertToMinorUnit: (x: MajorCurrency | string) => MinorCurrency;
  convertFromMinorUnit: (x: MinorCurrency) => MajorCurrency;
  simpleToMinorUnit: (x: MajorCurrency) => string;
  simpleFromMinorUnit: (x: MinorCurrency) => string;
  currencyToMinorUnit: (x: MajorCurrency) => string;
  currencyFromMinorUnit: (x: MinorCurrency) => string;
  currencyRoundedToMinorUnit: (x: MajorCurrency) => string;
  currencyRoundedFromMinorUnit: (x: MinorCurrency) => string;
  formatFinancialToMinorUnit: (x: MajorCurrency) => string;
  formatFinancialFromMinorUnit: (x: MinorCurrency) => string;
  formatFinancialRoundedToMinorUnit: (x: MajorCurrency) => string;
  formatFinancialRoundedFromMinorUnit: (x: MinorCurrency) => string;
};

const createFormatters = (currencyCode: CurrencyCode, locale: Locale): CurrencyFormatters => {
  const minorUnit = getMinorUnit(currencyCode);

  const { format: formatFinancial, rounded: formatFinancialRounded } =
    createFinancialCurrencyFormatters(currencyCode, locale);

  const convertToMinorUnit = convertToMinorUnitUnbound(currencyCode, locale);
  const convertFromMinorUnit = convertFromMinorUnitUnbound(currencyCode);

  // Factory function to create a convesion from a minor unit
  const fromMinor = (fn: (num: number) => string) => (num: number) => fn(convertFromMinorUnit(num));

  // Factory function to create a conversion to a minor unit
  const toMinor = (fn: (num: number) => string) => (num: number) => fn(convertToMinorUnit(num));

  const currency = createCurrencyFormatter(currencyCode, locale);
  const currencyRounded = createCurrencyFormatter(currencyCode, locale, true);
  const simple = createSimpleCurrencyFormatter(currencyCode, locale);

  const simpleToMinorUnit = toMinor(simple);
  const simpleFromMinorUnit = fromMinor(simple);
  const currencyToMinorUnit = toMinor(currency);
  const currencyFromMinorUnit = fromMinor(currency);
  const currencyRoundedToMinorUnit = toMinor(currencyRounded);
  const currencyRoundedFromMinorUnit = fromMinor(currencyRounded);
  const formatFinancialToMinorUnit = toMinor(formatFinancial);
  const formatFinancialFromMinorUnit = fromMinor(formatFinancial);
  const formatFinancialRoundedToMinorUnit = toMinor(formatFinancialRounded);
  const formatFinancialRoundedFromMinorUnit = fromMinor(formatFinancialRounded);

  return {
    bankersRound: (x: Float) => bankersRound(x, minorUnit),
    convertToMinorUnit,
    currency,
    convertFromMinorUnit,
    currencyCode,
    currencyFromMinorUnit,
    currencyRounded,
    currencyRoundedFromMinorUnit,
    formatFinancial,
    currencyRoundedToMinorUnit,
    locale,
    currencyToMinorUnit,
    minorUnit,
    formatFinancialFromMinorUnit,
    parseFloatLocale: (s: string) => parseFloatLocale(s, locale),
    formatFinancialRounded,
    formatFinancialRoundedFromMinorUnit,
    formatFinancialRoundedToMinorUnit,
    simple,
    formatFinancialToMinorUnit,
    simpleFromMinorUnit,
    simpleToMinorUnit,
  };
};

type DuckState = {
  code: CurrencyCode;
  locale: Locale; // @todo update
  formatters: CurrencyFormatters;
};

const initialState: DuckState = {
  code: 'USD',
  formatters: createFormatters('USD', getDefaultLocale()),
  locale: getDefaultLocale(),
};

const SET_CURRENCY_CODE = 'CURRENCY/SET_CODE';
type SetCurrencyCode = { type: 'CURRENCY/SET_CODE'; payload: CurrencyCode };

export const setCurrencyCode: ActionCreator<SetCurrencyCode> = (code: CurrencyCode) => ({
  payload: code,
  type: SET_CURRENCY_CODE,
});

const SET_LOCALE = 'CURRENCY/SET_LOCALE';

type SetLocaleCode = { type: 'CURRENCY/SET_LOCALE'; payload: Locale };

export const setLocaleCode: ActionCreator<SetLocaleCode> = (locale: Locale) => ({
  payload: locale,
  type: SET_LOCALE,
});

type Actions = SetCurrencyCode | SetLocaleCode;

export const reducer: Reducer<typeof initialState, Actions> = (state = initialState, action) => {
  switch (action.type) {
    case SET_CURRENCY_CODE:
      return currencyCodes.includes(action.payload)
        ? {
            ...state,
            code: action.payload,
            formatters: createFormatters(action.payload, state.locale),
          }
        : state;
    case SET_LOCALE:
      return LOCALES.includes(action.payload)
        ? {
            ...state,
            formatters: createFormatters(state.code, action.payload),
            locale: action.payload,
          }
        : state;
    default:
      return state;
  }
};

export const actions = {
  setCurrencyCode,
  setLocaleCode,
};

export const currencySelector = (state: { currency: { code: CurrencyCode } }) =>
  state.currency.code;
export const localeSelector = (state: { currency: { locale: Locale } }) => state.currency.locale;

export const formattersSelector = (state: { currency: { formatters: CurrencyFormatters } }) =>
  state.currency.formatters;

export const currencyAndLocaleSelector = (state: {
  currency: { code: CurrencyCode; locale: Locale };
}) => ({
  currencyCode: state.currency.code,
  locale: state.currency.locale,
});

export const useCurrency = (): [CurrencyCode, (code: CurrencyCode) => void] => {
  const currency = useSelector(currencySelector);
  const dispatch = useDispatch();
  const setCurrency = (code: CurrencyCode) => dispatch(actions.setCurrencyCode(code));

  return [currency, setCurrency];
};

export const useCurrencyAndLocale = () => {
  return useSelector(currencyAndLocaleSelector);
};

export const useMinorUnit = (): number => {
  const currencyCode = useSelector(currencySelector);
  return getMinorUnit(currencyCode);
};

/**
 * Returns bound functions for formatting currencies and converting between "dollars" and "cents"
 */
export const useCurrencyFormatter = (): CurrencyFormatters => useSelector(formattersSelector);
