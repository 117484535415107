import * as React from 'react';

import { Field, FieldProps } from '@cobbler-io/core-ui/src/Field';

import { any, prop } from 'ramda';

import { BudgetLineAtRevision, BudgetLineType } from '../BudgetLineEditor/types';

import styles from './LineTypePicker.scss';

export type LineTypePickerProps = {
  parent: BudgetLineAtRevision;
} & Omit<FieldProps, 'type' | 'options'>;

const options = [
  { label: 'Expense', value: BudgetLineType.EXPENSE },
  { label: 'Revenue', value: BudgetLineType.REVENUE },
];

export const LineTypePicker = React.forwardRef<HTMLSelectElement, LineTypePickerProps>(
  (props, ref): JSX.Element => {
    const { parent, defaultValue } = props;

    const disabled: boolean =
      Boolean(defaultValue) || !parent.isRoot || any(prop('isRevenue'), parent.lines);

    return (
      <Field
        {...props}
        ref={ref}
        className={disabled ? styles.disabled : ''}
        defaultValue={defaultValue ?? (parent.isRoot ? BudgetLineType.EXPENSE : parent.type)}
        disabled={disabled}
        options={options}
        type="select"
      />
    );
  },
);

LineTypePicker.displayName = 'LineTypePicker';
