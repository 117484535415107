import * as React from 'react';

import styles from './Separator.scss';

export const MenuSeparator = (): JSX.Element => (
  <hr className={styles.separator} role="presentation" />
);
MenuSeparator.displayName = 'MenuSeparator';

MenuSeparator.isMenuSeparator = (node: unknown): node is React.ReactElement<Record<string, unknown>> =>
  typeof node === 'object' && React.isValidElement<Record<string, unknown>>(node) && node.type === MenuSeparator;
