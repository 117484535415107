import * as React from 'react';

import styles from './Label.scss';

export type LabelProps = { children: React.ReactNode };

export const Label = ({ children }: LabelProps): JSX.Element => {
  return (
    <div className={styles.labelContainer}>
      <span className={styles.gridLabel}>{children}</span>
    </div>
  );
};

Label.displayName = 'DataGrid__Label';
