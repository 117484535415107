import * as React from 'react';

import { cx } from '@cobbler-io/utils/src/cx';

import scrollIntoView, { Options } from 'scroll-into-view-if-needed';

import { Item } from './types';

import styles from './DropdownButton.scss';

const scrollOptions: Options = {
  scrollMode: 'if-needed',
  block: 'nearest',
  inline: 'nearest',
};

type Props = {
  isFocused: boolean;
  item: Item;
} & React.HTMLProps<HTMLDivElement>

export const DropdownListItem = React.memo(
  React.forwardRef<HTMLDivElement, Props>(
    ({ item, isFocused, ...props }, ref: React.MutableRefObject<HTMLDivElement>) => {
      const { display, value } = item;

      React.useLayoutEffect(() => {
        if (ref && ref.current && isFocused) {
          scrollIntoView(ref.current, scrollOptions);
        }
      }, [isFocused]);

      return (
        <div
          {...props}
          key={value}
          ref={ref}
          className={cx('row col', styles.dropdownListItem, isFocused && styles.focused)}
          data-option-id={item.id}
          role="menuitem"
          tabIndex={0}
        >
          {display}
        </div>
      );
    },
  ),
  (a, b) => a === b,
);

DropdownListItem.displayName = 'DropdownListItem';

export default DropdownListItem;
