import { pad0 } from '@cobbler-io/utils/src/pad0';

/**
 * Formats a date to `YYYY-MM-DD` and adjusts for any timezone offset
 */

export const toDateToken = (date: Date | string): ISO8601String => {
  if (typeof date === 'string') {
    // If it looks like a date and quacks like a date...
    if (/^\d{4}-\d{2}-\d{2}$/.test(date)) {
      return date;
    }
  }

  const d = new Date(date);
  // Adjust for the timezone offset
  return [d.getFullYear(), d.getMonth() + 1, d.getDate()].map(pad0).join('-');
};
