import * as React from 'react';

import { MAX_SAFE_DATE, MIN_SAFE_DATE } from '@cobbler-io/dates/src';

import { DatePickerContext } from './DatePickerContext';
import { useDatePickerState } from './DatePickerReducer';

const DEFAULT_DECORATE: Set<ISO8601String> = new Set();

type DatePickerProviderProps<T = any> = {
  children: React.ReactNode;
  decorate?: Set<ISO8601String>;
  min?: Date;
  max?: Date;
  defaultStart?: Date | null;
  defaultEnd?: Date | null;
  range?: boolean;
  viewDate?: Date;
  incrementPage: (date: Date) => Date;
  decrementPage: (date: Date) => Date;
  incrementValue: (date: Date) => Date;
  decrementValue: (date: Date) => Date;
  formatDate: (date: Date) => T;
  onChange: (range: [start: T, end: T | null]) => any;
};

export const DatePickerProvider = (props: DatePickerProviderProps): JSX.Element => {
  const {
    children,
    decorate = DEFAULT_DECORATE,
    min = new Date(MIN_SAFE_DATE),
    max = new Date(MAX_SAFE_DATE),
    defaultStart = null,
    defaultEnd = null,
    range = false,
    viewDate = new Date(),
    incrementPage,
    decrementPage,
    incrementValue,
    decrementValue,
    formatDate,
    onChange,
  } = props;

  const { state, actions } = useDatePickerState({
    decrementPage,
    decrementValue,
    end: defaultEnd,
    formatDate,
    incrementPage,
    incrementValue,
    max,
    min,
    onChange,
    range,
    start: defaultStart,
    viewDate,
  });

  const context = React.useMemo(
    () => ({ ...state, ...actions, decorate, formatDate }),
    [state, actions, decorate, formatDate],
  );

  return <DatePickerContext.Provider value={context}>{children}</DatePickerContext.Provider>;
};

DatePickerProvider.displayName = 'DatePickerProvider';
