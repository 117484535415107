import * as React from 'react';

import useToggle from '@cobbler-io/hooks/src/useToggle';

import { useHydrateDepartments } from '@cobbler-io/app/src/api/graph';
import { Loading } from '@cobbler-io/app/src/components/Loading';

type DataHydrationGateProps = {
  children: React.ReactNode;
};

/**
 * Used to ensure that we have populated part of the graph before we allow access to the application
 */
export const DataHydrationGate = (props: DataHydrationGateProps): JSX.Element => {
  const { children } = props;
  const { active: isDepartmentDataHydrated, activate: setIsHydrated } = useToggle(false);
  useHydrateDepartments({ onComplete: setIsHydrated });

  if (isDepartmentDataHydrated) {
    return children as unknown as JSX.Element;
  }

  return (
    <div>
      <Loading />
    </div>
  );
};

DataHydrationGate.displayName = 'DataHydrationGate';
