import * as React from 'react';

import { isFunction } from '@cobbler-io/utils/src/isFunction';

import { ModalTreeNode } from './types';

export const renderModalTreeNode = (node: ModalTreeNode): JSX.Element | null => {
  const modalInjection = {
    key: node.id,
  };

  if (isFunction(node.modal)) {
    return node.modal(modalInjection);
  }

  if (React.isValidElement(node.modal)) {
    // Here we need to inject props
    return React.cloneElement(node.modal, modalInjection);
  }

  console.error('Cannot render modal', node.modal);
  return null;
};
