/* eslint-disable no-underscore-dangle, camelcase */
import { Reducer } from 'redux';

const ns = '@@auth';

const EXPIRE = `${ns}/expire`;
const SIGNOUT = `${ns}/signout`;
const SIGNIN = `${ns}/signin`;
const LOADING = `${ns}/loading`;
const SILENT_RENEW_ERROR = `${ns}/silent_renew_error`;

export type UserProfile = {
  auth_time: number;
  email: string;
  email_verified: boolean;
  family_name: string;
  given_name: string;
  locale: string;
  name: string;
  preferred_username: string;
  sub: string;
  updated_at: number;
  ver: number;
  zoneinfo: string;
};

export type User = {
  access_token: string;
  expires_at: number;
  id_token: string;
  profile: UserProfile;
  refresh_token?: string;
  scope: string;
  session_state?: any;
  state?: any;
  token_type: string;
};

export type AuthState = {
  user: User | null;
  loading: boolean;
};

const initialState: AuthState = {
  loading: false,
  user: null,
};

declare global {
  interface Window {
    heap: {
      [key: string]: any;
      identify: (s: string) => void;
    };
  }
}

/**
 * @deprecated
 */
export const reducer: Reducer<AuthState> = (state = initialState, action = { type: 'nil' }) => {
  switch (action.type) {
    case SIGNIN:
      return { ...state, loading: false, user: action.user };
    case SILENT_RENEW_ERROR:
    case EXPIRE:
    case SIGNOUT:
      return { ...state, loading: false, user: null };
    case LOADING:
      return { ...state, loading: true };
    default:
      return state;
  }
};

export const load = (user: User) => ({ type: SIGNIN, user });
export const signOut = () => ({ type: SIGNOUT });
export const expire = () => ({ type: EXPIRE });

export const actions = {
  expire,
  load,
  signOut,
};
