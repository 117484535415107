import { isNotFalsy } from '@cobbler-io/utils/src/isNotFalsy';

import { User } from '@cobbler-io/redux/src/modules/current-user';

import { Graph } from '@cobbler-io/collection/src/Graph';

import { UserVertex } from './graph';

type UpsertUserVertexParams = {
  user: User;
  graph: Graph<any, any>;
  isCurrentUser: boolean;
  isDeletedUser: boolean;
};

/**
 * Retrieves or adds a vertex representing the user
 */
export const upsertUserVertex = (params: UpsertUserVertexParams): UserVertex | null => {
  const { user, graph, isCurrentUser, isDeletedUser } = params;

  // This means we likely passed some hot garbage into the function
  if (!user?.id) {
    return null;
  }

  // Try to get the vertex before creating it
  const current = graph.getVertexById<UserVertex>(user.id);

  // If we found a vertex, then just return that one
  if (current) {
    return current;
  }

  // We haven't created this user yet, so we'll need to do so.

  // These are the properties on any user
  const properties = {
    deleted: false, // This works for the current user but not for other users
    email: user.email!,
    fullName: user.fullName!,
    preferredName: user.preferredName!,
    profileImageUrl: user.profileImageUrl!,
  };

  // Let's filter out the labels to find the ones that are appropriate
  const labels = [
    'USER', // Standard label
    isCurrentUser && 'ME', // This is the user who is viewing the application
    isDeletedUser && 'DELETED', // This user is deleted
  ].filter(isNotFalsy);

  // Return the newly created vertex
  return graph.addVertex({ id: user.id, labels, properties });
};
