import * as React from 'react';

import { cx } from '@cobbler-io/utils/src/cx';

import Fuse from 'fuse.js';

import Icon from '../Icon';
import { DropdownListItem } from './DropdownListItem';
import { Item } from './types';

import styles from './DropdownButton.scss';

type NullableItem = Item | null;

type Props = {
  options: Item[];
  defaultInput?: string;
  defaultSelected?: NullableItem;
  defaultFocused?: NullableItem;
  onSelect: (item: NullableItem) => void;

  /**
   * Whether or not to display the search box
   *
   * default: `true`
   */
  search?: boolean;

  style?: React.CSSProperties;
  /**
   * Injected by the Modal Manager
   */
  close?: () => void;
}

type State = {
  activeOptions: Item[];
  focused: NullableItem;
  searchText: string;
  selected: NullableItem;
}

const fuseOptions = {
  shouldSort: true,
  threshold: 0.45,
  location: 0,
  distance: 100,
  // maxPatternLength: 32,
  minMatchCharLength: 1,
  keys: ['text', 'search'],
};

/* eslint-disable react/no-unused-state */

export class DropdownList extends React.Component<Props, State> {
  static displayName = 'DropdownList';

  filter: Fuse<Item, typeof fuseOptions>;

  refMap: WeakMap<Item, React.MutableRefObject<HTMLDivElement | null>>;

  static defaultProps = {
    defaultInput: '',
    defaultSelected: null,
    defaultFocused: null,
  };

  constructor(props: Props) {
    super(props);

    this.filter = new Fuse(props.options, fuseOptions);

    this.refMap = props.options.reduce((map, item) => {
      map.set(item, React.createRef<HTMLDivElement>());
      return map;
    }, new WeakMap<Item, React.RefObject<HTMLDivElement>>());

    this.state = {
      activeOptions: props.defaultInput
        ? this.filter.search(props.defaultInput).map(({ item }) => item)
        : props.options,
      focused: props.defaultFocused || null,
      searchText: props.defaultInput || '',
      selected: props.defaultSelected || null,
    };
  }

  handleFilterOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // prettier-ignore
    const { currentTarget: { value: searchText } } = event;
    const { options } = this.props;
    this.setState({
      searchText,
      activeOptions: searchText ? this.filter.search(searchText).map(({ item }) => item) : options,
    });
  };

  handleKeyDown = (event: React.KeyboardEvent) => {
    const { close } = this.props;
    const { focused, selected } = this.state;

    let next = null;

    switch (event.key) {
      case 'ArrowDown':
        next = this.next();
        if (next !== focused) {
          this.focus(next);
        }
        break;

      case 'ArrowUp':
        next = this.prev();
        if (next !== focused) {
          this.focus(next);
        }
        break;

      case 'Escape':
        // eslint-disable-next-line no-unused-expressions
        close && close();

        break;

      case 'Home':
        next = this.first();
        if (next !== focused) {
          this.focus(next);
        }

        break;

      case 'End':
        next = this.last();
        if (next !== focused) {
          this.focus(next);
        }

        break;

      case 'PageUp':
        next = this.advanceActive(-4, true);
        if (next !== focused) {
          this.focus(next);
        }

        break;

      case 'PageDown':
        next = this.advanceActive(4, true);
        if (next !== focused) {
          this.focus(next);
        }

        break;

      case 'Enter':
        if (focused !== selected) {
          this.select(focused);
        }
        break;

      default:
      // noop
    }
  };

  handleOnClick: React.MouseEventHandler<HTMLDivElement> = event => {
    const { activeOptions } = this.state;
    // prettier-ignore
    const { currentTarget: { dataset: { optionId } } } = event;

    if (!optionId) {
      this.select(null);
    } else {
      this.select(activeOptions.find(i => i.id === optionId) || null);
    }
  };

  first() {
    const { activeOptions: items } = this.state;
    return items.length ? items[0] : null;
  }

  last() {
    const { activeOptions: items } = this.state;
    return items.length ? items[items.length - 1] : null;
  }

  advanceActive(num: number = 1, stopAtEdges: boolean = false) {
    const { activeOptions: items, focused } = this.state;

    if (items.length === 0) {
      return null;
    }

    if (!focused) {
      return items[0];
    }

    const current = items.indexOf(focused);
    const last = items.length - 1;

    let nextIndex = current + num;

    if (items[nextIndex]) {
      return items[nextIndex];
    }

    if (stopAtEdges) {
      if (nextIndex < 0) {
        return this.first();
      }

      if (nextIndex > last) {
        return this.last();
      }
    }

    while (last < nextIndex) {
      nextIndex -= last;

      if (items[nextIndex - 1]) {
        return items[nextIndex - 1];
      }

      if (nextIndex < 0) {
        return items[0];
      }
    }

    return this.last();
  }

  next() {
    return this.advanceActive(1);
  }

  prev() {
    return this.advanceActive(-1);
  }

  focus(item: NullableItem) {
    const { focused } = this.state;

    if (focused !== item) {
      this.setState({ focused: item });
    }
  }

  select(item: NullableItem) {
    const { onSelect, close } = this.props;
    const { selected } = this.state;

    if (selected !== item || !selected) {
      this.setState({ searchText: '', focused: null });
      onSelect(item);
    }

    // Strangley, this does not close unless it's wrapped in a setTimeout
    if (close) {
      setTimeout(close, 0);
    }
  }

  render() {
    const { activeOptions, searchText, focused } = this.state;
    const { search = true } = this.props;

    return (
      <>
        {search && (
          <div className="row">
            <div className="col small-10">
              <input
                className={styles.filter}
                role="searchbox"
                type="text"
                value={searchText}
                onChange={this.handleFilterOnChange}
                onKeyDown={this.handleKeyDown}
              />
            </div>
            <div className="col small-2">
              <Icon size={16} type="search" />
            </div>
          </div>
        )}
        <div className={cx('row col', styles.dropdownListItemContainer)}>
          {activeOptions.map((i: Item) => (
            <DropdownListItem
              key={i.value}
              ref={this.refMap.get(i)}
              isFocused={focused === i}
              item={i}
              onClick={this.handleOnClick}
            />
          ))}
        </div>
      </>
    );
  }
}

export default DropdownList;
