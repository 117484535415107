// Generally, when working with ReactTable/Data, we need to mutate a few things.
/* eslint-disable no-param-reassign, functional/immutable-data */
import * as React from 'react';

import { isString } from '@cobbler-io/utils/src/isString';

import { filter, reject } from 'ramda';

import { Column, ColumnProps, isNumericColumn } from './Column';
import { isDataGridTable } from './DataGridTable';
import { asDfsFilterType } from './filterTypes';
import { getRendererFromType } from './getRendererFromType';

const NullRenderer = () => null;

const setImpliedDefaults = (child: React.ReactElement<ColumnProps>) => {
  if (!child.props.type) {
    return child.props;
  }

  const props = { ...child.props };

  const renderer = getRendererFromType(props.type);

  if (renderer) {
    props.Aggregated ??= renderer;
    props.Cell ??= renderer;
  }

  props.Footer ??= NullRenderer;
  props.aggregate ??= isNumericColumn(props) ? 'sum' : '';

  return props;
};

const extractColumns = (
  children: React.ReactNode,
  defaults: Record<string, string[]> = {},
): readonly [ColumnProps[], Record<string, string[]>] => {
  const descendants: ColumnProps[] = React.Children.toArray(children)
    .filter(Column.isColumn)
    .map(setImpliedDefaults);

  const cols = descendants.map(props =>
    (Object.keys(props) as (keyof ColumnProps)[]).reduce<ColumnProps>(
      (acc: ColumnProps, name: keyof ColumnProps) => {
        const value = props[name];

        if ((name as string).startsWith('default') && props[name]) {
          defaults[name] ??= [];

          defaults[name].push(
            props.id ?? (isString(props.Header) ? (props.Header as string) : undefined),
          );

          // eslint-disable-next-line @typescript-eslint/prefer-ts-expect-error
          // @ts-ignore: this is computationally hard for TypeScript
          acc[name] = props[name];
          return acc;
        }

        /**
         * The react-table props are slightly different and not as consistent, so we'll map to them
         */
        switch (name) {
          case 'children':
            acc.columns = extractColumns(props.children, defaults)[0]; // eslint-disable-line
            break;

          case 'exportable':
            acc.canExport = Boolean(value);
            break;

          case 'filterable':
            // @ts-expect-error: Need to fix custom types
            acc.canFilter = Boolean(value);
            acc.disableFilters = !value;
            break;

          case 'groupable':
            // @ts-expect-error: Need to fix custom types
            acc.canGroupBy = Boolean(value);
            break;

          case 'sortable':
            // @ts-expect-error: Need to fix custom types
            acc.canSortBy = Boolean(value);
            break;

          case 'resizeable':
            // @ts-expect-error: Need to fix custom types
            acc.disableResizing = !value;
            break;

          case 'pinnable':
            // @ts-expect-error: Need to fix custom types
            acc.canPin = Boolean(value);
            break;

          case 'filter':
            // If a filter function is passed, we make sure it runs in a DFS
            // fashion (as opposed to the native BFS).
            // @ts-expect-error: Need to fix custom types
            acc.filter = typeof value === 'function' ? asDfsFilterType(value) : value;
            break;

          default:
            // @ts-expect-error: Need to fix custom types
            acc[name] = value;
        }

        return acc;
      },
      {},
    ),
  );

  return [cols, defaults] as const;
};

/**
 * - Parses a react children object into a usable column object
 * - Also pulls out global settings options needed to seed the global table state
 */
export const childrenToColumns = (
  children: React.ReactNode,
  defaults: Record<string, string[]> = {},
): readonly [ColumnProps[], Record<string, string[]>, React.ReactNode[]] => {
  const descendants = React.Children.toArray(children);
  const tables = filter(isDataGridTable, descendants);
  const otherChildren = reject(isDataGridTable, descendants);

  if (tables.length > 1) {
    throw new Error('There should be only one DataGridTable element defined');
  } else if (!tables.length) {
    throw new Error('There should be one DataGridTable element defined');
  }

  const [table] = tables;
  const filteredChildren = React.Children.toArray(table.props.children);

  // Do some more decorating here...
  // e.g. respond to type...
  const [cols, defs] = extractColumns(filteredChildren, defaults);

  return [cols, defs, otherChildren] as const;
};
