/* eslint-disable max-lines-per-function, react/no-array-index-key, react/prop-types */
import * as React from 'react';

import { isFunction } from '@cobbler-io/utils/src';
import { cx } from '@cobbler-io/utils/src/cx';
import { identity } from '@cobbler-io/utils/src/identity';

import { useForwardedRef } from '@cobbler-io/hooks/src/useForwardedRef';

import { execOrMapFn, FormContext } from '@swan-form/helpers';

import styles from './MaskedField.scss';

export type MaskedFieldProps = React.HTMLProps<HTMLInputElement> & {
  name: string;
  defaultValue?: string;
  value?: string;
  label?: string;
  id?: string;
  iconRight?: boolean;
  className?: string;
  required?: boolean;

  icon?: React.ReactNode;
  format?: (value: string) => React.ReactNode;
  sanitizer?: (value: string) => string;
  validate?:
    | ((value: string) => React.ReactNode | React.ReactNode[])
    | ((value: string) => React.ReactNode | React.ReactNode[])[];
  validateOnChange?: boolean;
  validateOnBlur?: boolean;
};

const useRecentRef = <T extends any = any>(value: T) => {
  const ref = React.useRef<T>(value);

  if (ref.current !== value) {
    ref.current = value;
  }

  return ref;
};

const ensureArray = <T extends any = any>(x: T | T[]) => (Array.isArray(x) ? x : [x]);

export const MaskedField = React.forwardRef<HTMLInputElement, MaskedFieldProps>(
  (props, passedRef) => {
    const {
      name,
      defaultValue,
      label,
      icon,
      className,
      sanitizer = identity,
      format = identity,
      onChange,
      onBlur,
      id = name,
      required = false,
      validate: userValidate,
      value: controlledValue,
      validateOnChange = false,
      validateOnBlur = false,
      iconRight = false,
      ...rest
    } = props;

    const ref = useForwardedRef(passedRef);
    const formContext = React.useContext(FormContext);
    const defaultFormValue = formContext.defaultFormValues[name];
    const [value, setValue] = React.useState<string>(
      controlledValue ?? defaultFormValue ?? defaultValue ?? '',
    );
    const [errors, setErrors] = React.useState<React.ReactNode[]>([]);
    const valueRef = useRecentRef(value);

    // We're storing the user validation function on a ref so that we can get the current,
    // otherwise, we sometimes run into problems with closures that have stale data
    const validateRef = useRecentRef(userValidate);
    const timeoutRef = React.useRef<any>();

    const formattedValue = format(value);

    const focus = React.useCallback(() => {
      ref.current?.focus();
    }, [ref]);

    const reset = React.useCallback(
      () => setValue(_ => controlledValue ?? defaultValue ?? ''),
      [controlledValue, defaultValue, setValue],
    );

    const getValue = React.useCallback(() => valueRef.current, [valueRef]);

    const getRef = React.useCallback(() => ref.current, [ref]);

    const validate = React.useCallback(
      (x: string, updateErrors: boolean): React.ReactNode[] => {
        const err = ensureArray(execOrMapFn(validateRef.current, x)).filter(Boolean);
        updateErrors && setErrors(() => err);
        return err;
      },
      [validateRef],
    );

    const handleOnChange: React.ChangeEventHandler<HTMLInputElement> = React.useCallback(
      event => {
        const next = sanitizer(event.currentTarget.value);

        if (validateOnChange) {
          clearTimeout(timeoutRef.current);
          timeoutRef.current = setTimeout(
            () => setErrors(_ => ensureArray(validate(next, true))),
            350,
          );
        }

        setValue(next);

        if (isFunction(onChange)) {
          event.persist();
          onChange(event);
        }
      },
      [sanitizer, setValue, validateOnChange, onChange, validate],
    );

    const handleOnBlur: React.FocusEventHandler<HTMLInputElement> = React.useCallback(
      event => {
        if (validate && validateOnBlur) {
          validate(sanitizer(event.currentTarget.value), true);

          if (onBlur) {
            event.persist();
          }
        }

        if (onBlur) {
          onBlur(event);
        }
      },
      [sanitizer, validateOnBlur, onBlur, validate],
    );

    // Integrate with our forms
    React.useEffect(() => {
      formContext.registerWithForm({
        focus,
        getRef,
        getValue,
        name,
        reset,
        setValue,
        validate,
      });

      return () => formContext.unregisterFromForm(name);
    }, [name, getValue, setValue, focus, validate, formContext, getRef, reset]);

    React.useEffect(() => {
      if (typeof controlledValue !== 'undefined' && controlledValue !== value) {
        setValue(controlledValue);
      }
    }, [controlledValue, value]);

    React.useImperativeHandle(ref, () => {
      // @todo fill this out better
      return {
        focus,
        value: getValue,
        validate,
      };
    });

    return (
      <label
        className={cx(
          styles.container,
          icon && styles.hasIcon,
          iconRight && styles.iconRight,
          required && 'sf--required',
          errors.length !== 0 && 'sf--has-errors',
          className,
        )}
        htmlFor={id}
      >
        <span>
          <span className={styles.wrapper}>
            <input
              {...rest}
              ref={ref}
              id={id}
              name={name}
              required={required}
              type="text"
              value={value}
              onBlur={handleOnBlur}
              onChange={handleOnChange}
            />
            <span className={styles.inputMask}>{formattedValue}</span>
          </span>
          <span className="sf--label">{label}</span>
          <span className="sf--icon">{icon}</span>
          <span className="sf--errors">
            {errors.map((err, index) => (
              <span key={index} className="sf--error">
                {err}
              </span>
            ))}
          </span>
        </span>
      </label>
    );
  },
);

MaskedField.displayName = 'MaskedField';
