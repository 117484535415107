/**
 * A higher order function that takes a typ and returns a unary fn to create a relay id of that type
 */
export const createRelayId = (type: string): UnaryFn<Guid | string, string> => {
  /**
   * Takes a GUID and returns a relay ID
   */
  const fn = (guid: string) => window.btoa([type, guid].join(':'));
  return fn;
};
