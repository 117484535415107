/**
 * ISO-4217 currency codes
 *
 * @see https://www.currency-iso.org/en/home/tables/table-a1.html
 */

import { bankersRound } from '@cobbler-io/utils/src/numbers/bankersRound';
import { prop } from '@cobbler-io/utils/src/prop';

import { getGroupingPoint, getRadixPoint } from './get-separator';
import { Locale } from './locales';

// prettier-ignore
export const currencyCodes = [
  'AED', 'AFN', 'ALL', 'AMD', 'ANG', 'AOA', 'ARS', 'AUD', 'AWG',
  'AZN', 'BAM', 'BBD', 'BDT', 'BGN', 'BHD', 'BIF', 'BMD', 'BND',
  'BOB', 'BOV', 'BRL', 'BSD', 'BTN', 'BWP', 'BYN', 'BZD', 'CAD',
  'CDF', 'CHE', 'CHF', 'CHW', 'CLF', 'CLP', 'CNY', 'COP', 'COU',
  'CRC', 'CUC', 'CUP', 'CVE', 'CZK', 'DJF', 'DKK', 'DOP', 'DZD',
  'EGP', 'ERN', 'ETB', 'EUR', 'FJD', 'FKP', 'GBP', 'GEL', 'GHS',
  'GIP', 'GMD', 'GNF', 'GTQ', 'GYD', 'HKD', 'HNL', 'HRK', 'HTG',
  'HUF', 'IDR', 'ILS', 'INR', 'IQD', 'IRR', 'ISK', 'JMD', 'JOD',
  'JPY', 'KES', 'KGS', 'KHR', 'KMF', 'KPW', 'KRW', 'KWD', 'KYD',
  'KZT', 'LAK', 'LBP', 'LKR', 'LRD', 'LSL', 'LYD', 'MAD', 'MDL',
  'MGA', 'MKD', 'MMK', 'MNT', 'MOP', 'MRU', 'MUR', 'MVR', 'MWK',
  'MXN', 'MXV', 'MYR', 'MZN', 'NAD', 'NGN', 'NIO', 'NOK', 'NPR',
  'NZD', 'OMR', 'PAB', 'PEN', 'PGK', 'PHP', 'PKR', 'PLN', 'PYG',
  'QAR', 'RON', 'RSD', 'RUB', 'RWF', 'SAR', 'SBD', 'SCR', 'SDG',
  'SEK', 'SGD', 'SHP', 'SLL', 'SOS', 'SRD', 'SSP', 'STN', 'SVC',
  'SYP', 'SZL', 'THB', 'TJS', 'TMT', 'TND', 'TOP', 'TRY', 'TTD',
  'TWD', 'TZS', 'UAH', 'UGX', 'USD', 'USN', 'UYI', 'UYU', 'UYW',
  'UZS', 'VES', 'VND', 'VUV', 'WST', 'XAF', 'XAG', 'XAU', 'XBA',
  'XBB', 'XBC', 'XBD', 'XCD', 'XDR', 'XOF', 'XPD', 'XPF', 'XPT',
  'XSU', 'XTS', 'XUA', 'XXX', 'YER', 'ZAR', 'ZMW', 'ZWL',
] as const;

/**
 * ISO-4217 currency codes
 *
 * @see https://www.currency-iso.org/en/home/tables/table-a1.html
 */
export type CurrencyCode = typeof currencyCodes[number];

const N = null; // This exists just to keep the table nice below

/**
 * A record of currencyCode => minorUnits (e.g. cents, etc...) supported by the currency
 *
 * @see https://www.currency-iso.org/en/home/tables/table-a1.html
 */
// prettier-ignore
const minorUnitMap: Record<CurrencyCode, number | null> = {
  AFN: 2, EUR: 2, ALL: 2, DZD: 2, USD: 2, AOA: 2, XCD: 2,
  ARS: 2, AMD: 2, AWG: 2, AUD: 2, AZN: 2, BSD: 2, BHD: 3,
  BDT: 2, BBD: 2, BYN: 2, BZD: 2, XOF: 0, BMD: 2, INR: 2,
  BTN: 2, BOB: 2, BOV: 2, BAM: 2, BWP: 2, NOK: 2, BRL: 2,
  BND: 2, BGN: 2, BIF: 0, CVE: 2, KHR: 2, XAF: 0, CAD: 2,
  KYD: 2, CLP: 0, CLF: 4, CNY: 2, COP: 2, COU: 2, KMF: 0,
  CDF: 2, NZD: 2, CRC: 2, HRK: 2, CUP: 2, CUC: 2, ANG: 2,
  CZK: 2, DKK: 2, DJF: 0, DOP: 2, EGP: 2, SVC: 2, ERN: 2,
  SZL: 2, ETB: 2, FKP: 2, FJD: 2, XPF: 0, GMD: 2, GEL: 2,
  GHS: 2, GIP: 2, GTQ: 2, GBP: 2, GNF: 0, GYD: 2, HTG: 2,
  HNL: 2, HKD: 2, HUF: 2, ISK: 0, IDR: 2, XDR: N, IRR: 2,
  IQD: 3, ILS: 2, JMD: 2, JPY: 0, JOD: 3, KZT: 2, KES: 2,
  KPW: 2, KRW: 0, KWD: 3, KGS: 2, LAK: 2, LBP: 2, LSL: 2,
  ZAR: 2, LRD: 2, LYD: 3, CHF: 2, MOP: 2, MKD: 2, MGA: 2,
  MWK: 2, MYR: 2, MVR: 2, MRU: 2, MUR: 2, XUA: N, MXN: 2,
  MXV: 2, MDL: 2, MNT: 2, MAD: 2, MZN: 2, MMK: 2, NAD: 2,
  NPR: 2, NIO: 2, NGN: 2, OMR: 3, PKR: 2, PAB: 2, PGK: 2,
  PYG: 0, PEN: 2, PHP: 2, PLN: 2, QAR: 2, RON: 2, RUB: 2,
  RWF: 0, SHP: 2, WST: 2, STN: 2, SAR: 2, RSD: 2, SCR: 2,
  SLL: 2, SGD: 2, XSU: N, SBD: 2, SOS: 2, SSP: 2, LKR: 2,
  SDG: 2, SRD: 2, SEK: 2, CHE: 2, CHW: 2, SYP: 2, TWD: 2,
  TJS: 2, TZS: 2, THB: 2, TOP: 2, TTD: 2, TND: 3, TRY: 2,
  TMT: 2, UGX: 0, UAH: 2, AED: 2, USN: 2, UYU: 2, UYI: 0,
  UYW: 4, UZS: 2, VUV: 0, VES: 2, VND: 0, YER: 2, ZMW: 2,
  ZWL: 2, XBA: N, XBB: N, XBC: N, XBD: N, XTS: N, XXX: N,
  XAU: N, XPD: N, XPT: N, XAG: N,
} as const;

export type NumberFormatter = (number: number | BigInt) => string;

export const getMinorUnit = (currencyCode: CurrencyCode): number => minorUnitMap[currencyCode] ?? 0;

/**
 * Creates a currency formatter for a specific ISO-4217 currency code
 */
export const createCurrencyFormatter = (
  code: CurrencyCode,
  locale?: string,
  integer: boolean = false,
): NumberFormatter => {
  // Creates a currency formatter
  const formatter = Intl.NumberFormat(locale, {
    style: 'currency',
    currency: code,
    minimumFractionDigits: integer ? 0 : getMinorUnit(code),
    maximumFractionDigits: integer ? 0 : getMinorUnit(code),
  });

  return formatter.format;
};

export const createSimpleCurrencyFormatters = (
  code: CurrencyCode,
  locale?: string,
): {
  full: NumberFormatter;
  rounded: NumberFormatter;
} => {
  const minorUnit = getMinorUnit(code);

  const fullFormatter = createCurrencyFormatter(code, locale);

  if (minorUnit === 0) {
    return { full: fullFormatter, rounded: fullFormatter };
  }

  const roundedFormatter = createCurrencyFormatter(code, locale, true);

  return { full: fullFormatter, rounded: roundedFormatter };
};

export const parseFloatLocale = (number: string | number, locale?: Locale): number => {
  if (typeof number === 'number') {
    return number;
  }

  return parseFloat(
    number
      .replace(getGroupingPoint(locale), '')
      .replace(getRadixPoint(locale), '.')
      .replace(/[^0-9.-]/g, ''),
  );
};

export const createSimpleCurrencyFormatter = (
  currency: CurrencyCode,
  locale?: Locale,
): ((number: number | string) => string) => {
  const { full, rounded } = createSimpleCurrencyFormatters(currency, locale);

  return (number: number | string): string => {
    const n = parseFloatLocale(number, locale);

    return n % 1 === 0 ? rounded(n) : full(n);
  };
};

export const getCurrencySymbol = (currencyCode: CurrencyCode, locale?: Locale): string => {
  const number = 1001.01;
  const parts = Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currencyCode,
  }).formatToParts(number);

  return parts.find(x => x.type === 'currency')!.value;
};

export const getCurrencySymbolPlacement = (
  currencyCode: CurrencyCode,
  locale?: Locale,
): 'head' | 'tail' => {
  const number = 1001.01;
  const parts = Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currencyCode,
  }).formatToParts(number);

  const index = parts.findIndex(x => x.type === 'currency');

  if (index === parts.length - 1) {
    return 'tail';
  }

  if (index === 0) {
    return 'head';
  }

  // We shouldn't get here, but I don't think that this happens
  return 'head';
};

export const getCurrencySymbolAndPlacement = (
  currency: CurrencyCode,
  locale?: Locale,
): readonly [string, 'tail' | 'head'] => {
  const number = 1001.01;
  const parts = Intl.NumberFormat(locale, { style: 'currency', currency }).formatToParts(number);
  const symbolIndex = parts.findIndex(x => x.type === 'currency');

  return [parts[symbolIndex]!.value, symbolIndex === parts.length - 1 ? 'tail' : 'head'] as const;
};

const parensInsteadOfMinus = (parts: Intl.NumberFormatPart[]) =>
  [{ value: '(' }, ...parts.filter(x => x.type !== 'minusSign'), { value: ')' }]
    .map(prop('value'))
    .join('');

/**
 * Creates Finanical Currency formatters
 *
 * For `en-US` it replaces the `-` with `()`
 * For all others, it uses the default
 *
 * @see https://docs.microsoft.com/en-us/globalization/locale/currency-formatting
 */
export const createFinancialCurrencyFormatters = (
  currency: CurrencyCode,
  locale?: Locale,
): {
  format: (x: number, displayCurrencySymbol?: boolean) => string;
  rounded: (x: number, displayCurrencySymbol?: boolean) => string;
} => {
  const formatterWithSymbol = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
    minimumFractionDigits: getMinorUnit(currency),
    maximumFractionDigits: getMinorUnit(currency),
  });

  const formatterWithoutSymbol = new Intl.NumberFormat(locale, {
    style: 'decimal',
    currency,
    minimumFractionDigits: getMinorUnit(currency),
    maximumFractionDigits: getMinorUnit(currency),
  });

  const formatterRoundedWithSymbol = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  const formatterRoundedWithoutSymbol = new Intl.NumberFormat(locale, {
    style: 'decimal',
    currency,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  const format = (number: number, displayCurrencySymbol = false) => {
    const formatter = displayCurrencySymbol ? formatterWithSymbol : formatterWithoutSymbol;

    const parts = formatter.formatToParts(number);
    const localeUsed = formatter.resolvedOptions();
    const isEnUs = localeUsed.locale === 'en-US';
    if (isEnUs && number < 0) {
      return parensInsteadOfMinus(parts);
    }

    return parts.map(prop('value')).join('');
  };

  const rounded = (number: number, displayCurrencySymbol = false) => {
    const formatter = displayCurrencySymbol
      ? formatterRoundedWithSymbol
      : formatterRoundedWithoutSymbol;

    const parts = formatter.formatToParts(number);
    const localeUsed = formatter.resolvedOptions();
    const isEnUs = localeUsed.locale === 'en-US';
    if (isEnUs && number < 0) {
      return parensInsteadOfMinus(parts);
    }

    return parts.map(prop('value')).join('');
  };

  return { format, rounded };
};

export const convertToMinorUnit = (
  code: CurrencyCode,
  locale?: Locale,
): ((x: number | string) => MinorCurrency) => x => {
  const minorUnit = getMinorUnit(code);
  const number = parseFloatLocale(x, locale);
  // guarding aginst floating point errors
  return bankersRound(number * 10 ** minorUnit, 0);
};

export const convertFromMinorUnit = (code: CurrencyCode): ((x: number) => MajorCurrency) => {
  const minorUnit = getMinorUnit(code);
  const formatter = new Intl.NumberFormat('en-US', { maximumFractionDigits: minorUnit });

  return x => {
    // guarding aginst floating point errors
    const number = x * 10 ** -minorUnit;

    return parseFloat(formatter.format(number).replace(/[^0-9.-]/g, ''));
  };
};
