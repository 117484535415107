/* eslint-disable prefer-object-spread */
import { MutableRefObject, RefObject, useLayoutEffect, useRef } from 'react';

import { Instance as Popper, Options as PopperOptions, VirtualElement } from '@popperjs/core';

import { useOnUnmount } from '../useOnUnmount';
import { makePopper } from './makePopper';

export type Options = PopperOptions & {
  /*
   * Sets the popper element to the same height as the reference element
   */
  sameHeight?: boolean;
  /*
   * Sets the popper element to the same width as the reference element
   */
  sameWidth?: boolean;
};

export type UsePopperParams = {
  /**
   * This is the reference object that the Popper is in relation to
   */
  target?: Element | RefObject<Element> | VirtualElement | RefObject<VirtualElement> | null;
  popper?: HTMLElement | RefObject<HTMLElement> | null;
  options?: Partial<Options>;
};

export const usePopper = (params: UsePopperParams): MutableRefObject<Popper | null> => {
  const ref = useRef<Popper | null>(null);
  const { target, popper, options } = params;

  // If possible, make the popper early
  if (target && popper && !ref.current) {
    makePopper({ target, popper, options, ref });
  }

  useLayoutEffect(() => {
    if (target && popper && !ref.current) {
      makePopper({ target, popper, options, ref });
    }

    ref.current?.update();
  }, [target, popper, options, ref]);

  useOnUnmount(() => ref.current?.destroy());

  return ref;
};
