// cSpell:disable
/* eslint-disable sort-keys-fix/sort-keys-fix, sort-keys, @typescript-eslint/no-magic-numbers */

import { isString } from '@cobbler-io/utils/src/isString';
import { lower } from '@cobbler-io/utils/src/string/lower';

import qs from 'qs';
import { useDispatch, useSelector } from 'react-redux';
import { ActionCreator, Reducer } from 'redux';

const FRONT_END_FEATURE_FLAGS: Record<string, string[]> = {};

const parseHostname = (hostname: string) => {
  const parts = hostname.split('.');
  switch (parts.length) {
    case 0:
      throw new Error('There is no hostname');
    case 1:
      if (parts[0] === 'localhost') {
        return ['', parts[0]];
      }
      throw new Error('Single segment hostname is not great');
    case 2:
      throw new Error('Missing subdomain');
    case 3:
      return parts;
    default:
      throw new Error('Unhandled domain name');
  }
};

const isNegated = (hostname: string) => {
  if (hostname.startsWith('!')) {
    return [hostname.slice(1), true] as const;
  }

  return [hostname, false] as const;
};

export const isFeatureActive = (target: string, current: string): boolean => {
  const [targetDomain, targetNegated] = isNegated(target);
  const [sub1, dom1, tld1] = parseHostname(current).map(lower);
  const [sub2, dom2, tld2] = parseHostname(targetDomain).map(lower);

  const tld = tld2 === '*' ? tld1 : tld2;

  if (targetNegated) {
    return sub1 !== sub2 || dom1 !== dom2 || tld !== tld1;
  }

  return sub1 === sub2 && dom1 === dom2 && tld === tld1;
};

const setFlags = (acc: Record<string, boolean>, flag: string) => ({
  ...acc,
  [lower(flag)]: true,
});

export const normalizeFeatures = (
  features?: string | string[] | qs.ParsedQs | qs.ParsedQs[],
): string[] => {
  if (!features) {
    return [];
  }

  if (Array.isArray(features)) {
    return (features as string[]).reduce<string[]>(
      (acc: string[], part: string | qs.ParsedQs) => [...acc, ...normalizeFeatures(part)],
      [],
    );
  }

  if (isString(features)) {
    return features.split(',').map(lower);
  }

  return Object.entries(features).reduce(
    (acc, [_, value]) => [...acc, ...normalizeFeatures(value)],
    [],
  );
};

export const getInitialFrontendFlags = (): Record<string, boolean> => {
  if (typeof window === 'undefined') {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return {} as Record<string, boolean>;
  }
  const { hostname, search } = window.location;
  const currentHostName = hostname.toLowerCase();
  const { __features } = qs.parse(search.slice(1));

  return Object.entries(FRONT_END_FEATURE_FLAGS).reduce<Record<string, boolean>>(
    (acc, [url, flags]) => {
      if (isFeatureActive(url, currentHostName)) {
        flags.forEach((flag: string) => {
          // eslint-disable-next-line functional/immutable-data
          acc[lower(flag)] = true;
        });
      }

      return acc;
    },
    normalizeFeatures(__features).reduce(setFlags, {}),
  );
};

const lowercaseKeys = <T extends Record<string, any> = Record<string, any>>(obj: T) => {
  return Object.entries(obj).reduce(
    (acc, [key, value]) => ({ ...acc, [lower(key) as keyof T]: value }),
    obj,
  );
};

type State = Record<string, boolean>;

const initialState: State = getInitialFrontendFlags();

const ENABLE_FLAG = 'FEATURE_FLAG/ENABLE_FLAG';
type EnableFeatureFlag = { type: typeof ENABLE_FLAG; payload: string };
export const enableFlag: ActionCreator<EnableFeatureFlag> = (payload: string) => ({
  payload,
  type: ENABLE_FLAG,
});

const DISABLE_FLAG = 'FEATURE_FLAG/DISABLE_FLAG';
type DisableFeatureFlag = { type: typeof DISABLE_FLAG; payload: string };

export const disableFlag: ActionCreator<DisableFeatureFlag> = (payload: string) => ({
  payload,
  type: DISABLE_FLAG,
});

const UPDATE_FLAGS = 'FEATURE_FLAG/UPDATE_FLAGS';
type UpdateFeatureFlags = { type: typeof UPDATE_FLAGS; payload: Record<string, boolean> };
export const updateFlags: ActionCreator<UpdateFeatureFlags> = (
  payload: Record<string, boolean>,
) => ({
  payload,
  type: UPDATE_FLAGS,
});

type Actions = EnableFeatureFlag | DisableFeatureFlag | UpdateFeatureFlags;

export const reducer: Reducer<typeof initialState, Actions> = (
  state = initialState,
  action = { type: '@@default' } as unknown as Actions,
) => {
  switch (action.type) {
    case ENABLE_FLAG:
      return { ...state, [lower(action.payload)]: true };
    case DISABLE_FLAG:
      return { ...state, [lower(action.payload)]: false };
    case UPDATE_FLAGS:
      return { ...state, ...lowercaseKeys(action.payload) };
    default:
      return state;
  }
};

export const actions = {
  disableFlag,
  enableFlag,
};

const featureFlagsSelector = (state: { featureFlags: State }): State => state.featureFlags;

/* eslint-disable func-style */
export function useFeatureFlag(name: string): boolean;
export function useFeatureFlag(names: string[]): boolean[];
export function useFeatureFlag(): State;
export function useFeatureFlag(name?: string | string[]): boolean | boolean[] | State {
  const flags = useSelector(featureFlagsSelector);

  if (!name) {
    return flags;
  }

  if (Array.isArray(name)) {
    return name.map(feature => Boolean(flags[lower(feature)]));
  }

  return Boolean(flags[lower(name)]);
}
/* eslint-enable func-style */

export const useUpdateFeatureFlags = (): {
  enable: (name: string) => EnableFeatureFlag;
  disable: (name: string) => DisableFeatureFlag;
  update: (flags: Record<string, boolean>) => UpdateFeatureFlags;
} => {
  const dispatch = useDispatch();

  return {
    disable: (name: string) => dispatch(disableFlag(name)),
    enable: (name: string) => dispatch(enableFlag(name)),
    update: (flags: Record<string, boolean>) => dispatch(updateFlags(flags)),
  };
};
