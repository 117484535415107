import React from 'react';

import { Modal, ModalTreeNode, useChildModal } from '@cobbler-io/core-ui/src/Modal';

import { TableInstance } from 'react-table';

import { ExportWizard } from './ExportWizard';

type UseExportDataGridParams = {
  /**
   * The name of the file to export to
   */
  filename: string;
  /**
   * Pass a getter for the TableInstance
   */
  getInstance: () => TableInstance;
  /**
   * Whether or not to include today's date in the filename
   */
  includeDate?: boolean;
};

export const useExportDataGrid = (params: UseExportDataGridParams): NullaryFn<ModalTreeNode> => {
  const create = useChildModal();
  const { filename, getInstance, includeDate } = params;

  return React.useCallback(
    () =>
      create(
        <Modal overlay className="floating-box centered">
          <ExportWizard file={filename} getInstance={getInstance} includeDate={includeDate} />
        </Modal>,
      ),
    [create, filename, getInstance, includeDate],
  );
};
