import * as React from 'react';

import { Button } from '@cobbler-io/core-ui/src/Button';
import { Icon } from '@cobbler-io/core-ui/src/Icon';

import { WebcamContext } from './WebcamContext';

type RejectButtonProps = {
  className?: string;
  style?: React.CSSProperties;
};

export const RejectButton = (props: RejectButtonProps): JSX.Element | null => {
  const { refs, actions, state } = React.useContext(WebcamContext);

  const clearCanvas = React.useCallback(() => {
    if (!refs.canvas.current) {
      return;
    }
    const { width, height } = refs.canvas.current;
    refs.canvas.current?.getContext('2d')?.clearRect(0, 0, width, height);
    actions.removeCapture();
  }, [refs.canvas]);

  if (!state.capture) {
    return null;
  }

  return (
    <Button {...props} name="reject" variant="svg" onClick={clearCanvas}>
      <Icon size={20} type="close" />
    </Button>
  );
};
