import * as React from 'react';

import { useIsMounted } from './useIsMounted';

/**
 * React's useState, but with a useIsMounted hook that only lets you set the state
 * if it's already mounted.
 */
export const useMountedState = <T>(
  defaultState: T,
): [T, React.Dispatch<React.SetStateAction<T>>] => {
  const isMounted = useIsMounted();
  const [state, setState] = React.useState<T>(defaultState);
  const safeSetState: React.Dispatch<React.SetStateAction<T>> = React.useCallback(
    (...args) => {
      if (isMounted.current) {
        setState(...args);
      }
    },
    [isMounted, setState],
  );

  return [state, safeSetState];
};
