import * as React from 'react';

import { identity } from '@cobbler-io/utils/src/identity';
import { isObjectEmpty } from '@cobbler-io/utils/src/isObjectEmpty';
import { localStorageFactory } from '@cobbler-io/utils/src/storage/local-storage-factory';

export type LocalCacheParams<T extends Record<string, unknown>, S extends Record<string, unknown> = T> = {
  /**
   * The name of the cache. This will be a key in localStore, so make it unique enough
   */
  name: string;
  /**
   * The default object. This will be set if a cache entry does not already exist
   */
  default?: T;
  /**
   * A serialization function to make the object live nicely in local storage
   *
   * Defaults to `identity`
   */
  serialize?: (arg: T) => S;
  /**
   * A function to hydrate the object coming out of local storage
   *
   * Defaults to `identity`
   */
  deserialize?: (arg: S) => T;
};

type Cache<T extends Record<string, unknown>> = {
  /**
   * Get the current cache
   */
  get: () => T;
  /**
   * Takes either an object that will be shallowly merged with the prev or an updater function
   * that should produce the exact object
   */
  set: (val: Partial<T> | ((prev: T) => T)) => void;
  /**
   * Resets the cache entry to an empty object
   */
  clear: () => void;
  /**
   * Returns an empty object when cache is not found, otherwise returns a deserialized cache state
   *
   * Note: this is mostly used internally as an indication to prefer the default state passed in
   */
  hydrate: () => T | Record<string, unknown>;
  /**
   * Returns the serialized value of the cache as it is saved in localStorage.
   * Useful for comparing state.
   */
  raw: () => string;
};

/**
 * A simpler hook to use LocalStorage with defaults.
 *
 * Supports serialization/deserialization to/from localStorage
 */
export const useLocalCache = <T extends Record<string, unknown>, S extends Record<string, unknown> = T>(
  params: LocalCacheParams<T, S>,
): Cache<T> => {
  const { name, serialize = identity, deserialize = identity } = params;

  const cache: Cache<T> = React.useMemo(() => {
    if (name === 'NOCACHE') {
      return {
        get: () => ({}),
        set: () => {},
        clear: () => {},
        hydrate: () => ({}),
        raw: () => '{}',
      } as unknown as Cache<T>;
    }

    const storage = localStorageFactory(name);
    const raw = () => storage.get() || '';

    // TODO: Looks like hydrate() ultimately acts exactly the same as get()
    const hydrate = () => {
      const cacheString = storage.get();
      return cacheString ? deserialize(JSON.parse(cacheString)) : {};
    };

    const get = (): T => deserialize(JSON.parse(storage.get() || '{}'));

    const set = (x: Partial<T> | ((prev: T) => T)): void => {
      const val = typeof x === 'function' ? x(get()) : Object.assign(get(), x);
      storage.set(JSON.stringify(serialize(val)));
    };

    const clear = (): void => storage.set('{}');

    return { get, hydrate, set, clear, raw };
  }, [name, serialize, deserialize]);

  if (isObjectEmpty(cache.hydrate())) {
    cache.set(params.default ?? {});
  }

  return cache;
};
