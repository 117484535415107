import { relativeTime } from './relativeTime';

/* eslint-disable @typescript-eslint/no-magic-numbers */
export const ONE_SECOND = 1000;
export const ONE_MINUTE = ONE_SECOND * 60;
export const ONE_HOUR = ONE_MINUTE * 60;
export const ONE_DAY = ONE_HOUR * 24;
export const ONE_WEEK = ONE_DAY * 7;
export const ONE_MONTH = ONE_DAY * 30;
export const ONE_YEAR = ONE_DAY * 365;
/* eslint-enable @typescript-eslint/no-magic-numbers */

const maybeRound = (num: number, base: number) => {
  const val = Math.floor(num / base);
  return Math.round((num / base) % 1) ? val + 1 : val;
};

export const timeAgo = (d: Date | string, threshold = 0): string => {
  const date = typeof d === 'string' ? new Date(d) : d;
  const now = new Date();

  const isPast = date.valueOf() - now.valueOf() < 0;
  const diff = Math.abs(date.valueOf() - now.valueOf());

  if (diff < threshold) {
    return 'Just now';
  }

  if (diff >= ONE_YEAR) {
    return relativeTime(maybeRound(diff, ONE_YEAR), 'year', isPast);
  }

  if (diff >= ONE_MONTH) {
    return relativeTime(maybeRound(diff, ONE_MONTH), 'month', isPast);
  }

  if (diff >= ONE_WEEK) {
    return relativeTime(maybeRound(diff, ONE_WEEK), 'week', isPast);
  }

  if (diff >= ONE_DAY) {
    return relativeTime(maybeRound(diff, ONE_DAY), 'day', isPast);
  }

  if (diff >= ONE_HOUR) {
    return relativeTime(maybeRound(diff, ONE_HOUR), 'hour', isPast);
  }

  if (diff >= ONE_MINUTE) {
    return relativeTime(maybeRound(diff, ONE_MINUTE), 'minute', isPast);
  }

  if (diff > ONE_SECOND) {
    return relativeTime(maybeRound(diff, ONE_SECOND), 'second', isPast);
  }

  return 'Just now';
};
