import { destructureRelayId } from './destructureRelayId';
import { isGuid } from './isGuid';
import { isNull } from './isNull';

export const isRelayId = <T extends string>(id?: T): id is T => {
  if (!id) {
    return false;
  }

  if (typeof id !== 'string') {
    return false;
  }

  const [, relayId] = destructureRelayId(id);

  if (isNull(relayId)) {
    return false;
  }

  return isGuid(relayId);
};
