/* eslint-disable max-lines-per-function */
import * as React from 'react';

import tokens from '@cobbler-io/design-tokens';

import { sumBy } from '@cobbler-io/utils/src/array';
import { isNotZero } from '@cobbler-io/utils/src/isNotZero';

import { useGetLatest } from '@cobbler-io/hooks/src/useGetLatest';
import { useSelectableList } from '@cobbler-io/hooks/src/useSelectableList';

import { Button } from '@cobbler-io/core-ui/src/Button';
import { Card } from '@cobbler-io/core-ui/src/Card';
import { Column, DataGrid, Toolbar, ToolbarMenu } from '@cobbler-io/core-ui/src/DataGrid';
import { DonutChartData } from '@cobbler-io/core-ui/src/DonutChartData';
import { useFeatureFlag } from '@cobbler-io/core-ui/src/FeatureFlag';
import { Field } from '@cobbler-io/core-ui/src/Field';
import { Heading } from '@cobbler-io/core-ui/src/Heading';
import { Icon } from '@cobbler-io/core-ui/src/Icon';
import { Menu, useFlyoutMenu } from '@cobbler-io/core-ui/src/Menu';

import { useCurrencyFormatter } from '@cobbler-io/redux/src/modules/currency';
import { useRevisions } from '@cobbler-io/redux/src/modules/current-budget';

import {
  BudgetLineEditorDataQueryVariables, useBudgetLineEditorDataQuery,
} from '@cobbler-io/app/src/api/graphql-types';
import { ErrorDisplay } from '@cobbler-io/app/src/components/ErrorDisplay';
import { SystemLineLock } from '@cobbler-io/app/src/ndm/components/SystemLineLock';
import { useCurrentBudgetLine } from '@cobbler-io/app/src/providers/CurrentBudgetLineProvider';
import {
  PeriodMenu, RangeMenu, useCurrentRange,
} from '@cobbler-io/app/src/providers/CurrentRangeProvider';
import { toDateToken } from '@cobbler-io/app/src/utils/toDateToken';

import { Link, navigate } from '@reach/router';
import {
  either, filter, flatten, map, path, pathOr, pipe, pluck, prop, propOr, reject, sort, sum,
} from 'ramda';
import { CellProps } from 'react-table';

import { useGraphRefetchHammer } from '../../../api/graph/useGraphRefetchHammer';
import { RevisionMenu } from '../RevisionMenu';
import {
  AccountsColumn, DepartmentColumn, NameColumn, OwnerColumn, VendorsColumn,
} from './columns';
import { PLACEHOLDER_ID } from './constants';
import { useEditAccounts } from './editors/useEditAccounts';
import { useEditDepartment } from './editors/useEditDepartment';
import { useEditLineName } from './editors/useEditLineName';
import { useEditOwner } from './editors/useEditOwner';
import { useEditPlannedSpend } from './editors/useEditPlannedSpend';
import { useEditVendors } from './editors/useEditVendors';
import { useReadonlyColumn } from './editors/useReadonlyColumn';
import { extractEditorUnallocatedLine } from './extractEditorUnallocatedLine';
import { BudgetLineType, EditorLine } from './types';
import { useAddBudgetLine } from './useAddBudgetLine';
import { useAddForecast } from './useAddForecast';
import { useDeleteBudgetLines } from './useDeleteBudgetLines';
import { useEditBudgetLines } from './useEditBudgetLines';
import { useUploadBudgetLines } from './useUploadBudgetLines';

import styles from './BudgetLineEditor.scss';

const getTotalInLines = (k: 'spent' | 'planned') =>
  pipe(pluck('plannedSpend'), flatten, pluck(k), sum);

const sortLineByWeight = (a: EditorLine, b: EditorLine) =>
  a.weight === b.weight ? a.id.localeCompare(b.id) : a.weight - b.weight;

export type BudgetLineEditorProps = {
  onDone?: () => void;
};

export const BudgetLineEditor = (props: BudgetLineEditorProps): JSX.Element => {
  const { onDone } = props;
  const { convertFromMinorUnit, formatFinancialRounded } = useCurrencyFormatter();
  const { original: originalRevisionId, selectedRevision } = useRevisions()!;
  const selectedRevisionName = selectedRevision!.name;
  const budgetLineContext = useCurrentBudgetLine();
  const rangeContext = useCurrentRange();
  const id = budgetLineContext?.id;
  const isOriginal = selectedRevision?.id === originalRevisionId;
  const isRoot = !!budgetLineContext?.isRoot;
  const isRevenue = !!budgetLineContext?.isRevenue;

  const defaultOnDone = React.useCallback(
    async () => navigate(budgetLineContext!.urls.overview({})),
    [budgetLineContext],
  );

  const sumRows = (key: string, rows: CellProps<EditorLine>['preGroupedRows']) =>
    formatFinancialRounded(sumBy(rows, ['values', key], 0));

  // If current line is root, ignore the revenue rows
  const totalValues = (key: string) => (cellProps: CellProps<EditorLine>) =>
    sumRows(
      key,
      isRoot
        ? reject(pathOr(false, ['original', 'isRevenue']), cellProps.preGroupedRows)
        : cellProps.preGroupedRows,
    );

  const handleOnDone = onDone ?? defaultOnDone;

  const queryVariables: BudgetLineEditorDataQueryVariables = {
    end: toDateToken(rangeContext.end),
    id: id!,
    revisionId: selectedRevision!.id,
    start: toDateToken(rangeContext.start),
  };

  const { data, loading, error } = useBudgetLineEditorDataQuery({
    fetchPolicy: 'cache-and-network',
    skip: !id,
    variables: queryVariables,
  });

  const placeholderNewLine = {
    childrenCount: 0,
    id: PLACEHOLDER_ID,
    name: '',
  };

  const lines = [...(data?.a_budgetLineChildrenEditorData.lines ?? [])].sort(sortLineByWeight);
  const maxWeight = lines.reduce((acc, line) => {
    if (line.linkedToDriver) {
      return acc;
    }

    return Math.max(acc, line.weight);
  }, 0);

  const getWeight = useGetLatest(maxWeight);
  const editorParams = { getWeight };

  const {
    toggleItem,
    noneSelected,
    isSelected,
    allSelected,
    selectNone,
    selectAll,
    selectedItems,
  } = useSelectableList(pluck('id', lines));

  const plannedEditor = useEditPlannedSpend(editorParams);
  const accountsEditor = useEditAccounts(editorParams);
  const vendorsEditor = useEditVendors(editorParams);
  const nameEditor = useEditLineName(editorParams);
  const departmentEditor = useEditDepartment(editorParams);
  const ownerEditor = useEditOwner(editorParams);
  const readonlyKeyHandlers = useReadonlyColumn();
  const deleteBudgetLines = useDeleteBudgetLines(selectedItems, queryVariables);
  const editBudgetLines = useEditBudgetLines(selectedItems);
  const addBudgetLine = useAddBudgetLine();
  const uploadBudgetLines = useUploadBudgetLines();
  const addForecast = useAddForecast();

  // Forecasts are disabled on sub-budgets in the original revision.
  const alwaysAllowBatchEditing = useFeatureFlag('AlwaysAllowBatchEditing');
  const allowForecasts = lines.length > 0 && (isRoot || !isOriginal);
  const canEditAllMonths = useFeatureFlag('EditAllMonths');
  const divRef = React.useRef<HTMLDivElement>(null);

  const rehydrateGraph = useGraphRefetchHammer();

  // TODO: This is temporary hack to update the overview after visiting the edit
  // page ideally we want to update the graph directly after every mutation.
  React.useEffect(() => () => void rehydrateGraph(), []);

  const showAddMenu = useFlyoutMenu(
    <Menu small id="line-editor-add-menu">
      <Menu.Item iconType="plus" label="Add line" onSelect={addBudgetLine} />
      <Menu.Item iconType="upload" label="Upload budget" onSelect={uploadBudgetLines} />

      {(allowForecasts || alwaysAllowBatchEditing) && (
        // If we're viewing a sub-budget there's no option to create a new
        // revision. This logic is handled in the ForecastUploader component but
        // we need to change the label of the menu item here to reflect that.
        <Menu.Item
          iconType={isRoot ? 'addLibrary' : 'upload'}
          label={isRoot ? 'Forecast' : 'Upload forecast'}
          onSelect={addForecast}
        />
      )}
    </Menu>,
  );

  if (error) {
    return <ErrorDisplay error={error} />;
  }

  if (loading && !lines.length) {
    return (
      <div className={styles.budgetLineEditor}>
        <div className={styles.toolbar}>
          <div>
            <Heading as="h2" size="title">
              Edit budget
            </Heading>
          </div>
        </div>
        <DataGrid.Loading columns={10} rows={10} />
      </div>
    );
  }

  if (selectedRevision?.isLocked) {
    return (
      <Card>
        This {selectedRevision?.isForecast ? 'forecast' : 'budget'} is locked and cannot be edited.
        To edit it, go to <Link to={budgetLineContext!.urls.settings({})}>Settings</Link> and unlock
        it.
      </Card>
    );
  }

  const getSpent = (interval: number) =>
    pipe(pathOr(0, ['plannedSpend', interval, 'spent']), convertFromMinorUnit);

  const getPlanned = (interval: number) =>
    pipe(pathOr(0, ['plannedSpend', interval, 'planned']), convertFromMinorUnit);

  // @ts-expect-error: This is fine.
  const shouldShowUnallocatedLine: UnaryFn<EditorLine, boolean> = either(
    pipe(map(path(['plannedSpend', 'spent'])), isNotZero),
    pipe(map(path(['plannedSpend', 'planned'])), isNotZero),
  );

  const unallocated = extractEditorUnallocatedLine(data!.a_budgetLineChildrenEditorData);
  const showUnallocated = shouldShowUnallocatedLine(unallocated);

  // TODO: Get this from server or add validation on server to prevent user from
  // editing past periods.
  const now = new Date();

  const revenueLines = sort(sortLineByWeight, filter(prop('isRevenue'), lines));
  const expenseLines = sort(sortLineByWeight, reject(prop('isRevenue'), lines));

  // Get the linked budget lines and add the prop isSytemLine.
  const linkedLines = sort(
    sortLineByWeight,
    filter(prop('linkedToDriver'), lines).map(line => ({
      ...line,
      systemLine: { isSystemLine: true },
    })),
  );

  const notExpenseLines = (() => {
    if (showUnallocated && isRoot) {
      return [...revenueLines, unallocated];
    }

    if (showUnallocated && !isRoot) {
      return [unallocated, ...revenueLines];
    }

    return revenueLines;
  })();

  const editorData = [...notExpenseLines, ...linkedLines, ...expenseLines, placeholderNewLine];

  const getExpenseDonutChart = () => {
    if (isRevenue) {
      return null;
    }
    const expenseData = reject(
      prop('isRevenue'),
      showUnallocated ? [...lines, unallocated] : lines,
    );
    const totalAllocated = convertFromMinorUnit(
      expenseData.length > 0
        ? getTotalInLines('planned')(expenseData)
        : sum(unallocated.plannedSpend.map(d => d.planned)),
    );
    const totalSpent = convertFromMinorUnit(
      expenseData.length > 0
        ? getTotalInLines('spent')(expenseData)
        : sum(unallocated.plannedSpend.map(d => d.spent)),
    );

    const percentageSpent = +((totalSpent * 100) / totalAllocated).toFixed(2);
    const expenseChart = {
      colors: { main: tokens.colorPaper, secondary: tokens.colorPaper },
      data: [
        {
          label: 'Actual',
          percentage: percentageSpent,
          value: formatFinancialRounded(totalSpent, true),
        },
        {
          label: isOriginal ? 'Budget' : selectedRevisionName,
          value: formatFinancialRounded(totalAllocated, true),
        },
      ],
    };
    return (
      <DonutChartData
        className={styles.donutChartContainer}
        colors={expenseChart.colors}
        data={expenseChart.data}
        percentage={percentageSpent}
      />
    );
  };

  const getRevenueDonutChart = () => {
    if (revenueLines.length <= 0 && notExpenseLines[0].type !== BudgetLineType.REVENUE) {
      return null;
    }
    const revenueLine = revenueLines.length > 0 ? revenueLines[0] : notExpenseLines[0];
    const totalRevenueForecasted = convertFromMinorUnit(
      sum(revenueLine.plannedSpend.map(d => d.planned)),
    );
    const totalRevenueEarned = convertFromMinorUnit(
      sum(revenueLine.plannedSpend.map(d => d.spent)),
    );
    const percentageRevenueSpent = +((totalRevenueEarned * 100) / totalRevenueForecasted).toFixed(
      2,
    );
    const revenueChart = {
      colors: { main: tokens.colorPaper, secondary: tokens.colorPaper },
      data: [
        {
          label: 'Earned',
          percentage: percentageRevenueSpent,
          value: formatFinancialRounded(totalRevenueEarned, true),
        },
        {
          label: isOriginal ? 'Target' : selectedRevisionName,
          value: formatFinancialRounded(totalRevenueForecasted, true),
        },
      ],
    };
    return (
      <DonutChartData
        className={styles.donutChartContainer}
        colors={revenueChart.colors}
        data={revenueChart.data}
        percentage={percentageRevenueSpent}
      />
    );
  };

  const Checkbox = ({
    cell: {
      value: checked,
      row: { original: line },
    },
  }: CellProps<EditorLine, boolean>): JSX.Element => {
    if (line.systemLine?.isSystemLine) {
      return <SystemLineLock tip={line.systemLine.tip} />;
    }

    return (
      <Field
        aria-label={`Select ${line.name}`}
        className={styles.checkbox}
        disabled={!line.canUpdate || line.id === PLACEHOLDER_ID}
        name={line.id}
        type="checkbox"
        value={checked}
        onChange={() => toggleItem(line.id)}
      />
    );
  };

  Checkbox.displayName = 'BudgetLineEditorCheckbox';

  return (
    <Card className={styles.fullWidthCard}>
      <div className={styles.pickers}>
        <RangeMenu />
        <PeriodMenu /> {/* We might need to disable this and set only to months */}
        <RevisionMenu showRollingForecast />
      </div>
      <div ref={divRef} className={styles.budgetLineEditor}>
        <DataGrid<EditorLine> key="budget-editor" showFooter data={editorData}>
          <Toolbar className={styles.toolbar}>
            <Toolbar.Left>
              <Heading as="h2" size="title">
                Edit budget
              </Heading>
            </Toolbar.Left>
            <Toolbar.Center>
              {/* Revenue/Expense Chart */}
              <div className={styles.donutCharts}>
                {getRevenueDonutChart()}
                {getExpenseDonutChart()}
              </div>
            </Toolbar.Center>
            <Toolbar.Right>
              {!noneSelected && (
                <>
                  <Button name="update-budget-lines" variant="text" onClick={editBudgetLines}>
                    <Icon type="editOutline" />
                    Edit {selectedItems.length > 1 ? `${selectedItems.length} lines` : `line`}
                  </Button>

                  <Button name="add-budget-lines" variant="text" onClick={deleteBudgetLines}>
                    <Icon type="deleteOutline" />
                    Delete {selectedItems.length > 1 ? `${selectedItems.length} lines` : `line`}
                  </Button>
                </>
              )}
              <Button name="add-budget-line" variant="text" onClick={showAddMenu}>
                <Icon type="add" />
                Add
              </Button>
              <Button name="add-budget-line" variant="text" onClick={handleOnDone}>
                <Icon type="check" />
                Done
              </Button>
              <ToolbarMenu autosize hideable pinnable sortable />
            </Toolbar.Right>
          </Toolbar>
          <DataGrid.Table>
            <Column<EditorLine> Header="" hideable={false} id="group-data">
              <Column<EditorLine>
                accessor={line => isSelected(line.id)}
                Cell={Checkbox}
                className="background-paper"
                Header={
                  <Field
                    aria-label="Select all"
                    className={styles.checkbox}
                    name="select-all"
                    title="Select all"
                    type="checkbox"
                    value={allSelected}
                    onChange={allSelected ? selectNone : selectAll}
                  />
                }
                hideable={false}
                id="select"
                resizeable={false}
              />
              <Column<EditorLine>
                accessor="name"
                Cell={NameColumn}
                className="background-paper"
                Header="Name"
                hideable={false}
                id="name"
                {...nameEditor}
              />
              <Column<EditorLine>
                accessor="vendors"
                Cell={VendorsColumn}
                className="background-paper"
                Header="Vendors"
                hideable={false}
                id="vendors"
                {...vendorsEditor}
              />
              <Column<EditorLine>
                accessor="owner"
                Cell={OwnerColumn}
                className="background-paper"
                Header="Owner"
                hideable={false}
                id="owner"
                {...ownerEditor}
              />
              <Column<EditorLine>
                accessor="departments"
                Cell={DepartmentColumn}
                className="background-paper"
                Header="Department"
                hideable={false}
                id="departments"
                {...departmentEditor}
              />
              <Column<EditorLine>
                accessor="accounts"
                Cell={AccountsColumn}
                className="background-paper"
                Header="Accounts"
                hideable={false}
                id="accounts"
                {...accountsEditor}
              />
            </Column>
            {rangeContext.periods.map(({ key, name, start, end }, i) => (
              <Column<EditorLine>
                key={key}
                Header={name}
                headerClassName={styles.periods}
                hideable={false}
                id={key}
              >
                {start < now && (
                  <Column<EditorLine>
                    accessor={getSpent(i)}
                    className={styles.mutedColumn}
                    Footer={totalValues([name, 'Spent'].join('-'))}
                    Header="Actual"
                    headerClassName={styles.financialHeader}
                    hideable={false}
                    id={[name, 'Spent'].join('-')}
                    type="FinancialRounded"
                    {...readonlyKeyHandlers}
                  />
                )}
                {(canEditAllMonths || end > now) && (
                  <Column<EditorLine>
                    accessor={getPlanned(i)}
                    className="background-paper"
                    Footer={totalValues([name, 'Planned'].join('-'))}
                    Header={selectedRevisionName}
                    headerClassName={styles.financialHeader}
                    hideable={false}
                    id={[name, 'Planned'].join('-')}
                    type="FinancialRounded"
                    {...plannedEditor}
                  />
                )}
              </Column>
            ))}
            <Column<EditorLine>
              className="background-paper"
              Header="Totals"
              hideable={false}
              id="totals"
            >
              <Column<EditorLine>
                accessor={pipe(
                  propOr([], 'plannedSpend'),
                  map(propOr(0, 'planned')),
                  sum,
                  convertFromMinorUnit,
                )}
                className="background-paper"
                Footer={totalValues('totals-planned')}
                Header={selectedRevisionName}
                headerClassName={styles.financialHeader}
                id="totals-planned"
                type="FinancialRounded"
                {...readonlyKeyHandlers}
              />
            </Column>
          </DataGrid.Table>
        </DataGrid>
      </div>
    </Card>
  );
};

BudgetLineEditor.displayName = 'BudgetLineEditor';

export default BudgetLineEditor;
