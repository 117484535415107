/* eslint-disable functional/no-this-expression, no-restricted-syntax, functional/no-class */
import * as React from 'react';

import { isNull } from '@cobbler-io/utils/src/isNull';

import * as Sentry from '@sentry/browser';

import { ErrorMessage } from '../ErrorMessage';

type ErrorBoundaryProps = {
  error: Error | null;
  info: Record<string, unknown> | null;
  eventId?: string;
};

const print = (obj: any): string | null => {
  if (isNull(obj)) {
    return obj;
  }

  if (typeof obj.componentStack === 'string') {
    return obj.componentStack;
  }

  if (typeof obj.toString === 'function') {
    return obj.toString();
  }

  return null;
};

export class ErrorBoundary extends React.Component<Record<string, unknown>, ErrorBoundaryProps> {
  static getDerivedStateFromError(): { hasError: boolean } {
    return { hasError: true };
  }

  static displayName = 'ErrorBoundary';

  state = {
    error: null,
    info: null,
    eventId: undefined,
  };

  componentDidCatch(error: Error | null, info: React.ErrorInfo): void {
    Sentry.withScope(scope => {
      scope.setExtras(info as { componentStack: string });
      const eventId = Sentry.captureException(error);
      this.setState({ eventId, error, info });
    });
  }

  clearState = (): void =>
    this.setState({
      error: null,
      info: null,
      eventId: undefined,
    });

  render(): React.ReactNode {
    const { children } = this.props;
    const { error, info, eventId } = this.state;
    const details = print(info);

    if (error) {
      // Fallback UI if an error occurs
      return (
        <ErrorMessage
          refresh
          className="col align-center"
          clear={this.clearState}
          description="Looks like something went wrong."
          details={details}
          title="Uh oh…"
          onFeedback={() => Sentry.showReportDialog({ eventId })}
        />
      );
    }
    // component normally just renders children
    return children;
  }
}

export default ErrorBoundary;
