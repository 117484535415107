/* eslint-disable array-callback-return */
import * as React from 'react';

import { cx } from '@cobbler-io/utils/src/cx';

import { useMediaQuery } from '@cobbler-io/hooks/src/useMediaQuery';

import { Avatar } from '@cobbler-io/core-ui/src/Avatar';
import { CobblerMark } from '@cobbler-io/core-ui/src/CobblerMark';
import { CobblerText } from '@cobbler-io/core-ui/src/CobblerText';

import { useProfile } from '@cobbler-io/redux/src/modules/Auth/hooks';
import { useCurrentUser } from '@cobbler-io/redux/src/modules/current-user';

import { Link } from '@reach/router';

import { Hamburger } from '../Hamburger';
import { IconTypes } from '../Icon';
import { VerticalNavItem } from './VerticalNavItem';

import styles from './VerticalNav.scss';

export type VerticalLink = {
  id: string;
  icon: IconTypes;
  label: string;
  showBadge?: boolean;
} & XOR<
  { uri: string; onClick?: never },
  { uri?: never; onClick: React.MouseEventHandler<HTMLAnchorElement> }
>;

type Props = {
  active: boolean;
  children?: React.ReactNode;
  toggle: () => void;
  pathname: string;
  links: VerticalLink[];
  onClickLogout?: () => void;
};

type AClickEvent = React.MouseEventHandler<HTMLAnchorElement>;

export const VerticalNav = ({
  active,
  toggle,
  links,
  onClickLogout,
  pathname,
  children,
}: Props): JSX.Element => {
  const userName = useProfile('preferred_username');
  const isSmall = useMediaQuery('(max-width: 600px)');
  const user = useCurrentUser() as UserType;

  const handleOnClick: (...extra: (AClickEvent | undefined)[]) => AClickEvent = React.useCallback(
    (...extra) =>
      event => {
        const handlers = extra.filter(Boolean) as AClickEvent[];
        handlers.length && event.persist();
        isSmall && setTimeout(toggle, 0);

        handlers.forEach(e => void e(event));
      },
    [isSmall, toggle],
  );

  const isLinkActive = React.useCallback(
    (uri?: string) => {
      if (pathname === '/') {
        return uri === pathname;
      }

      return uri ? pathname.startsWith(uri) : false;
    },
    [pathname],
  );

  return (
    <div className={cx(styles.verticalNav, !active && styles.hide)}>
      <div>
        <div className={cx(styles.item, styles.hamburger)}>
          <div className={styles.link}>
            <Hamburger
              active={active}
              className={cx(styles.icon, styles.hamburgerIcon)}
              onClick={toggle}
            />
            <div className={styles.label}>
              <Link className={styles.cobblerLogo} to="/">
                <CobblerMark className={styles.cobblerMark} height={24} width={24} />
                <CobblerText className={styles.cobblerText} color="gray" height={20} width={80} />
              </Link>
            </div>
          </div>
        </div>
        {links.map(({ icon, label, uri, onClick, id, showBadge = false }) => (
          <VerticalNavItem
            key={id ?? label}
            active={isLinkActive(uri)}
            icon={icon}
            id={id}
            label={label}
            showBadge={showBadge}
            uri={uri ?? pathname}
            onClick={handleOnClick(onClick)}
          />
        ))}
      </div>
      {children}
      <div className={styles.bottom}>
        <VerticalNavItem
          active={false}
          className={styles.bottomItem}
          icon="logout"
          id="logout"
          label="Logout"
          uri="#"
          onClick={onClickLogout}
        />
        <VerticalNavItem
          active={isLinkActive('/user')}
          className={styles.bottomItem}
          icon={user ? <Avatar size="headline" user={user} /> : 'face'}
          id="user"
          label={(user ? user.fullName ?? user.email : userName) as string}
          uri="/user"
        />
      </div>
    </div>
  );
};

VerticalNav.displayName = 'VerticalNav';

export default VerticalNav;
