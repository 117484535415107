/**
 * Copies over only selected keys of an object
 */
export const pick = <T extends Record<string, any>, K extends keyof T>(
  keys: K[],
  obj: T,
): Pick<T, K> =>
  keys.reduce((acc, key) => {
    if (key in obj) {
      acc[key] = obj[key];
    }
    return acc;
  }, Object.create(null));
