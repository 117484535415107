import { Revision } from '@cobbler-io/redux/src/modules/current-budget/types';

export type RevisionTag = BudgetRevisionId | 'latest' | 'original' | 'active';

type RevisionData = {
  originalId: BudgetRevisionId;
  activeId: BudgetRevisionId;
  latestId: BudgetRevisionId;
  revisions: Revision[];
};

/**
 * Given a RevisionId or a RevisionTag, it returns the RevisionId
 *
 * This is helpful for being able to use RevisionTags as (basically) Ids
 */
export const getRevisionId = (
  { activeId, originalId, latestId, revisions }: RevisionData,
  revisionIdOrTag?: RevisionTag,
): BudgetRevisionId | null => {
  if (revisionIdOrTag === 'active') {
    return activeId || latestId;
  }
  if (revisionIdOrTag === 'original') {
    return originalId;
  }
  if (revisionIdOrTag === 'latest') {
    return latestId;
  }
  if (revisionIdOrTag && revisions?.find(r => r.id === revisionIdOrTag)) {
    return revisionIdOrTag;
  }

  return null;
};
