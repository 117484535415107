import * as React from 'react';

import { execIfFunc } from '@cobbler-io/utils/src';
import { cx } from '@cobbler-io/utils/src/cx';

import { useToggle } from '@cobbler-io/hooks/src';

import { animated, useSpring } from '@react-spring/web';

import { CloseButton } from '../CloseButton';
import { Modal, ModalProps } from '../Modal';
import { useModalImplementation } from '../Modal/useModalImplementation';

import styles from './Drawer.scss';

type DrawerAnchor = 'top' | 'bottom' | 'left' | 'right';

type OwnProps = {
  anchor: DrawerAnchor;
  children: React.ReactNode;
};

type DrawerProps = Partial<ModalProps> & OwnProps;

const getAnchorStyle = (anchor: DrawerAnchor, isOpen: boolean) => {
  const positions = isOpen ? ['from', 'to'] : ['to', 'from'];
  const [start, end] = positions;

  /* eslint-disable sort-keys, sort-keys-fix/sort-keys-fix */
  switch (anchor) {
    case 'top':
      return {
        [start]: { transform: `translate(0, -100%)` },
        [end]: { transform: `translate(0, 0)` },
      };
    case 'left':
      return {
        [start]: { transform: `translate(-100%, 0)` },
        [end]: { transform: `translate(0, 0)` },
      };
    case 'bottom':
      return {
        [start]: { transform: `translate(0, 0)` },
        [end]: { transform: `translate(0, -100%)` },
      };
    case 'right':
      return {
        [start]: { transform: `translate(100%, 0)` },
        [end]: { transform: `translate(0, 0)` },
      };
    default:
      // Fallback to right
      return {
        [start]: { transform: `translate(100%, 0)` },
        [end]: { transform: `translate(0, 0)` },
      };
  }
  /* eslint-enable sort-keys, sort-keys-fix/sort-keys-fix */
};

export const Drawer = ({ anchor, children, ...props }: DrawerProps): JSX.Element => {
  const { active, deactivate } = useToggle(true);
  const { modal } = useModalImplementation();

  const drawerStyle = useSpring({
    ...getAnchorStyle(anchor, active),
    onRest: () => void (!active && execIfFunc(modal?.close)),
  });

  return (
    <Modal {...props} bare overlay className={cx(styles.drawer, styles[anchor])}>
      {(_, { className }) => (
        <animated.div className={cx(styles.drawer, styles[anchor], className)} style={drawerStyle}>
          <CloseButton className="margin-0" close={deactivate} />
          {children}
        </animated.div>
      )}
    </Modal>
  );
};

Drawer.displayName = 'Drawer';
