import { CurrentBudgetState, Revisions } from './types';

export const revisionsSelector = (state: {
  currentBudget: CurrentBudgetState;
}): Revisions | null => {
  if (!state) {
    return null;
  }
  return {
    active: state.currentBudget?.activeRevisionId ?? null,
    index: state.currentBudget?.revisionMap ?? {},
    latest: state.currentBudget?.latestRevisionId ?? null,
    original: state.currentBudget?.originalRevisionId ?? null,
    revisions: state.currentBudget?.revisions ?? [],
    selected: state.currentBudget?.selectedRevisionId ?? null,
    selectedRevision:
      state.currentBudget?.revisionMap && state.currentBudget.selectedRevisionId
        ? state.currentBudget.revisionMap[state.currentBudget.selectedRevisionId]
        : null,
    visible: state.currentBudget?.visibleRevisionIds ?? [],
  };
};
