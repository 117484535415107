import * as React from 'react';

import { pick } from '@cobbler-io/utils/src/pick';

const DefaultFallback = () => <div>Loading...</div>;

type LoaderProps = {
  Component: React.ComponentType;
  include?: string[];
  path?: string;
  default?: boolean;
  gated?: boolean;
  fallback?: React.ComponentType;
}

export const Load = ({
  Component,
  include,
  gated,
  fallback = DefaultFallback,
  ...rest
}: LoaderProps) => {
  return (
    <React.Suspense fallback={fallback}>
      <Component {...(include ? pick(include, rest) : {})} />
    </React.Suspense>
  );
};

Load.displayName = 'Load';
