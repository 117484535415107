import * as React from 'react';

import { parse } from 'qs';

import { uploadProfilePicture } from '@cobbler-io/app/src/api/uploadProfilePicture';

import { Login } from '../../pages/Login';
import { Finish } from '../../pages/Registration/Finish';
import { AuthContext } from '../AuthContext';
import { SignIn } from './SignIn';
import { SignOut } from './SignOut';
import { SilentSignIn } from './SilentSignIn';

type OIDCRouterProps = Record<string, unknown>;

const SIGN_IN_CALLBACK_PATH = '/signin-callback';
const SIGN_IN_CALLBACK_SILENT_PATH = '/signin-callback-silent';
const SIGN_OUT_CALLBACK_PATH = '/signout-callback';
const OKTA_SWA_PATH = '/login/swa';
// const LOGIN_PATH = '/login';

const FINISH_REGISTRATION_PATH = '/registration/finish';

/**
 * Gets the current path and search and turn it into a redirect
 */
const getRedirectPath = () => {
  const { pathname, search } = window.location;
  if (pathname.replace('/', '') === 'login') {
    return '/';
  }

  return `/login/?redirect=${encodeURIComponent([pathname, search].join(''))}`;
};

/**
 * Gets the redirect from the window
 */
const getRedirectFromWindow = () => {
  if (window.location.pathname.includes('/login')) {
    const { redirect } = parse(window.location.search);
    return { redirect: redirect ?? '/' };
  }

  return {
    redirect: [window.location.pathname, window.location.search].filter(Boolean).join('') || '/',
  };
};

/**
 * Does a hard refresh to the login path
 */
const navigateToLogin = () => {
  setTimeout(() => window.location.assign(getRedirectPath()), 16);
};

const getStateFromWindow = () => {
  const { redirect } = parse(window.location.search.slice(1));

  return redirect ? { redirect } : getRedirectFromWindow();
};

const useWindowPath = () => {
  const [path, setPath] = React.useState(window.location.pathname);
  const setNewPath = React.useCallback(() => setPath(window.location.pathname), [setPath]);

  // This needs to run on every signin otherwise it'll choke
  React.useEffect(setNewPath);

  React.useEffect(() => {
    // Pop state events don't really fire well cross-browser until they have something in the stack,
    // so we'll push the current location into it.
    window.history.pushState(null, '', window.location.href);

    window.addEventListener('popstate', setNewPath, false);

    return () => window.removeEventListener('popstate', setNewPath);
  }, [setNewPath]);

  return [path, setNewPath] as const;
};

export const OIDCRouter: React.FC<OIDCRouterProps> = props => {
  const { children } = props;
  const [path] = useWindowPath();
  const retrySilentRenew = React.useRef<boolean>(true);

  const {
    state: { manager, user },
    actions: { loadUser, unloadUser },
  } = React.useContext(AuthContext);

  React.useLayoutEffect(() => {
    if (
      path === SIGN_IN_CALLBACK_PATH ||
      path === SIGN_IN_CALLBACK_SILENT_PATH ||
      path === SIGN_OUT_CALLBACK_PATH
    ) {
      return;
    }

    if (path === OKTA_SWA_PATH && manager) {
      manager.signinRedirect({ state: getStateFromWindow() });

      return;
    }

    if (!user && manager) {
      // When the manager loads, try *once* to sign in automatically (e.g. check if we are already
      // signed in with a different tab, etc...). If we're logged in, then it should just rerender
      // directly where we need to go. If we're not logged in, then this should be a noop.
      manager.signinSilent().catch(err => {
        // This is a message that we're getting in sentry
        const oktaErrMessage = 'The+client+specified+not+to+prompt,+but+the+user+is+not+logged+in.';
        const devMessage = 'Frame window timed out';
        if (typeof err === 'object') {
          // https://github.com/okta/okta-oidc-js/issues/567 leads me to think it'll have an error code
          if ('errorCode' in err && err.errorCode === 'login_required') {
            return;
          }

          if (err instanceof Error) {
            if (err.message === oktaErrMessage || err.message === devMessage) {
              return;
            }
          }
        }

        if (typeof err === 'string' && err === oktaErrMessage) {
          return;
        }

        // Since this does not comport to the known failures, we'll log it
        // eslint-disable-next-line no-console
        console.error('Error trying to locate user.', err);
      });
    }
  }, [manager]);

  if (path === SIGN_IN_CALLBACK_PATH) {
    return <SignIn />;
  }

  if (path === SIGN_IN_CALLBACK_SILENT_PATH) {
    return <SilentSignIn />;
  }

  if (path === SIGN_OUT_CALLBACK_PATH) {
    return <SignOut />;
  }

  // Do we need any other callback paths?

  if (!user) {
    if (manager) {
      // We're putting this here instead of above so that logged in users don't
      if (path === FINISH_REGISTRATION_PATH) {
        return (
          <Finish
            afterSubmit={async (callbackArgs: any, file: File | null) =>
              manager.signinRedirect(callbackArgs).then(() => {
                manager.getUser().then(nextUser => {
                  if (!nextUser || !file) {
                    return;
                  }

                  const { token_type: tokenType, access_token: accessToken } = nextUser;
                  uploadProfilePicture({ tokenType, accessToken, file });
                });
              })
            }
          />
        );
      }

      // The user hasn't logged in, so just show the login page
      return <Login onClick={async () => manager?.signinRedirect({ state: getStateFromWindow() })} />;
    }

    // We shouldn't get to this component if the manager isn't found (e.g. blocked at a higher level)
    console.error('[oidc]', 'Manager cannot be found'); // eslint-disable-line no-console
    // But, we'll return something anyway.
    return <div>Cannot load user manager</div>;
  }

  // If the user is expired, then try to do a silent signin. If that fails, head back to login
  if (user.expired && !retrySilentRenew.current) {
    // We'll retry only once. We've now set the ref to `false` so that it won't try again
    retrySilentRenew.current = false;

    // If user is defined, then state is defined, so, we're good
    manager!
      .signinSilent()
      .then(loadedUser => {
        if (loadedUser) {
          return loadUser(loadedUser);
        }

        // We failed to login, so tear everything down
        unloadUser();
        return navigateToLogin();
      })
      .catch(error => {
        // Log the error for sentry's purposes
        console.error('[oidc] Silent renew error', error); // eslint-disable-line no-console

        if (manager) {
          manager.removeUser().then(() => {
            // Unload the user
            unloadUser();
            // Push the user back to the login page with a redirect param
            navigateToLogin();
          });
        }
      });

    // Show the login page while we try to do a silent renew
    return <Login onClick={() => manager?.signinRedirect()} />;
  }

  return <>{children}</>;
};
