import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { actions } from './current-budget';
import { Budget, BudgetState, CurrentBudgetState } from './types';

const currentBudgetSelector = (state: BudgetState): CurrentBudgetState => state.currentBudget;

type CurrentBudgetTuple = readonly [CurrentBudgetState | null, UnaryFn<Budget, void>];

export const useCurrentBudget = (): CurrentBudgetTuple => {
  const state = useSelector(currentBudgetSelector);
  const dispatch = useDispatch();
  const setState = React.useCallback(
    (payload: Budget | null) => dispatch(actions.updateCurrentBudget(payload)),
    [dispatch],
  );

  return [state, setState] as const;
};
