/* eslint-disable no-restricted-syntax, functional/no-let */
/**
 * Checks if two arrays are equal with `===` equality for items
 */
export const arraysAreEqual = (a: any[], b: any[]): boolean => {
  if (a.length !== b.length) {
    return false;
  }

  for (let i = 0; i < a.length; i++) {
    if (a[i] !== b[i]) {
      return false;
    }
  }

  return true;
};
