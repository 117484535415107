import { setCSSProperties } from './setCSSProperties';
import { windowExists } from './windowExists';

/**
 * @todo find a way to make this work with Macs. There's a setting so that only
 */
const createGetScrollBarSize = () => {
  let size: number;

  return (recalc?: boolean) => {
    if (!size || recalc) {
      if (!windowExists()) {
        return size;
      }

      const div = setCSSProperties(document.createElement('div'), {
        position: 'absolute',
        top: '-9999px',
        width: '50px',
        height: '50px',
        overflow: 'scroll',
      });

      document.body.appendChild(div);
      size = div.offsetWidth - div.clientWidth;
      document.body.removeChild(div);
    }
    return size; // maybe fallback to 17, which is a general size
  };
};

export const getScrollbarSize = createGetScrollBarSize();
