import * as React from 'react';

import { useForwardedRef } from '@cobbler-io/hooks/src/useForwardedRef';

import { Icon } from '../../Icon';
import { BreadCrumbContext } from '../BreadCrumbs';

import styles from './Segment.scss';

const Separator = () => <Icon data-separator type="chevronRight" />;
Separator.displayName = 'Separator';

type SegmentProps = {
  id: string;
  separator: boolean;
  children: React.ReactNode;
  /**
   * Internal, do not supply
   */
  isCloned?: boolean;
};

export const Segment = React.forwardRef<HTMLDivElement, SegmentProps>(
  ({ id, separator, children, isCloned = false }, forwardedRef) => {
    const ref = useForwardedRef(forwardedRef);
    const { subscribe } = React.useContext(BreadCrumbContext);

    React.useLayoutEffect(() => {
      if (!isCloned) {
        subscribe(id, ref);
      }
    }, []);

    return (
      <div ref={ref} className={styles.segment}>
        {children} {separator && <Separator />}
      </div>
    );
  },
);

Segment.displayName = 'Segment';
