import * as React from 'react';

import { noop } from '@cobbler-io/utils/src';

import { Button } from '../Button';
import { Heading } from '../Heading';
import { Icon } from '../Icon';
import noCobblerIllustration from './no-cobbler.svg';

import styles from './ErrorMessage.scss';

const reloadPage = () => {
  window.location.reload();
};

export type ErrorMessageProps = {
  className?: string;
  title?: string;
  description?: string;
  refresh?: boolean;
  details: string | null;
  onFeedback?: () => any;
  clear?: () => void;
};

export const ErrorMessage = (props: ErrorMessageProps): JSX.Element => {
  const {
    className,
    title = 'Error',
    description,
    refresh = false,
    details,
    onFeedback,
    clear,
  } = props;
  const handleClick = React.useCallback(() => onFeedback?.(), [onFeedback]);

  return (
    <div className={className}>
      <div className={styles.errorMessage}>
        <Heading size="headline">{title}</Heading>
        {description && <p className="font-body-large text-secondary">{description}</p>}
        {refresh && (
          <Button
            className={styles.refresh}
            name="refresh-error-page"
            type="button"
            variant="text"
            onClick={reloadPage ?? noop}
          >
            Refresh
          </Button>
        )}
        <img alt="" role="presentation" src={noCobblerIllustration} />
        {__DEV__ && Boolean(details) && (
          <details>
            <summary className="row center align-center">
              Details <Icon size={20} type="chevronDown" />
            </summary>
            <pre>{details}</pre>
          </details>
        )}
        {__DEV__ && (
          <Button name="clear error" onClick={clear ?? noop}>
            Clear Error
          </Button>
        )}
        {typeof onFeedback === 'function' && (
          <Button
            className={styles.feedback}
            name="send-error-feedback"
            type="button"
            onClick={handleClick}
          >
            Send feedback
          </Button>
        )}
      </div>
    </div>
  );
};

ErrorMessage.displayName = 'ErrorMessage';
