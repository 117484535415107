import { extractGuidFromRelayId } from '@cobbler-io/utils/src/destructureRelayId';

/* eslint-disable sort-keys, sort-keys-fix/sort-keys-fix */
import { Graph, Vertex } from '@cobbler-io/collection/src/Graph';

import { HydrateBudgetGraphQuery } from '../graphql-types';
import { UserVertex } from './';
import {
  getBudgetLineChildEdgeId, getBudgetLineOwnerEdgeId, getUserPermissionsEdgeId,
} from './utils';

type Data = NonNullable<HydrateBudgetGraphQuery['budgetLines']>;

type HydrateBudgetLinesParams = {
  graph: Graph<any, any>;
  data: Data;
};

const revenueLabels = ['BUDGET_LINE', 'REVENUE_LINE'];
const expenseLabels = ['BUDGET_LINE', 'EXPENSE_LINE'];
const rootLabels = ['BUDGET_LINE', 'ROOT_LINE'];
const permissionsEdgeLabels = ['HAS_PERMISSION_TO'];

const getLabels = (budgetLine: Data[number]) => {
  switch (budgetLine.type) {
    case 'EXPENSE':
      return expenseLabels;
    case 'REVENUE':
      return revenueLabels;
    case 'ROOT':
      return rootLabels;
    default:
      // This should never be reached
      return expenseLabels;
  }
};

const budgetLineEdgeLabels = ['HAS_CHILD'];
const emptyObject = {};

const budgetLineOwnerEdgeLabels = ['OWNS'];

/**
 * Hydrates Budget Lines
 *
 * Depends on:
 *
 * Users
 * CurrentUser
 *
 */
export const hydrateBudgetLines = (params: HydrateBudgetLinesParams): void => {
  const { data, graph } = params;

  // We need to store a queue to create the budget line vertices
  const queue = new Map<string, Set<Vertex>>();

  for (const budgetLine of data) {
    // Create the BudgetLine Vertex
    const line = graph.addVertex({
      id: budgetLine.id,
      labels: getLabels(budgetLine),
      properties: {
        name: budgetLine.name,
        openDiscussionCount: budgetLine.openDiscussionCount,
        weight: budgetLine.weight,
        permissions: budgetLine.permissions,
      },
    });

    // If the budget line has a parent, then add the parent and child to a queue in order to
    // create the edges that form the budget line tree later. We are deferring this
    // because we don't have a guarantee that the parents will come in before the children
    if (budgetLine.parentId) {
      if (!queue.has(budgetLine.parentId)) {
        queue.set(budgetLine.parentId, new Set());
      }

      queue.get(budgetLine.parentId)!.add(line);
    }

    // If we have a CurrentUser as a Vertex, then let's create the permissions to this budget line
    const me: UserVertex | undefined = graph.getVerticesByLabel('ME')?.values().next().value;

    if (me) {
      graph.addEdge({
        id: getUserPermissionsEdgeId(me.id, line.id),
        labels: permissionsEdgeLabels,
        from: me,
        to: line,
        properties: { permissions: budgetLine.permissions },
      });
    }

    /**
     * hashtag techdebt
     */
    const ownerId = budgetLine.ownerId ? extractGuidFromRelayId(budgetLine.ownerId) : null;

    // Connect the owner edge to the budget line
    if (ownerId && graph.hasVertex(ownerId)) {
      // We already checked that the vertex exists
      const owner = graph.getVertexById(ownerId)!;
      graph.addEdge({
        id: getBudgetLineOwnerEdgeId(ownerId, budgetLine.id),
        labels: budgetLineOwnerEdgeLabels,
        from: owner,
        to: line,

        properties: emptyObject,
      });
    }
  }

  // Now connect the parent and child vertices
  for (const [parentId, children] of queue) {
    const parent = graph.getVertexById(parentId);

    if (!parent) {
      console.error('Cannot find parent with id', parentId);
      continue; // eslint-disable-line no-continue
    }

    for (const child of children) {
      graph.addEdge({
        id: getBudgetLineChildEdgeId(parent.id, child.id),
        from: parent,
        to: child,
        labels: budgetLineEdgeLabels,
        properties: emptyObject, // A new object will be created during edge creation
      });
    }
  }
};
