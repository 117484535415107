import * as React from 'react';

import { Delay } from '@cobbler-io/core-ui/src/Delay';

import range from 'lodash/range';

import { Shimmer } from './Shimmer';

export type ShimmerListProps = React.HTMLProps<HTMLDivElement> & {
  className?: string;
  items?: number;
  gutter?: string | number;
  height?: string | number;
  delay?: number;
};

export const ShimmerList = ({
  className,
  items = 3,
  gutter = '1em',
  height = '1em',
  delay = 500,
}: ShimmerListProps): JSX.Element => {
  return (
    <div className={className}>
      {range(items).map(n => (
        <div
          key={n}
          style={{
            marginBottom: gutter,
          }}
        >
          <Delay ms={delay}>
            <Shimmer style={{ height }} />
          </Delay>
        </div>
      ))}
    </div>
  );
};

ShimmerList.displayName = 'ShimmerList';
