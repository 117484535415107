import { HydrateBudgetGraphQuery } from '@cobbler-io/app/src/api/graphql-types';
/* eslint-disable sort-keys, sort-keys-fix/sort-keys-fix */
import { Graph } from '@cobbler-io/collection/src/Graph';
import { getMonthId } from '@cobbler-io/dates/src/getPeriodsInRange';
import { tzAdjust } from '@cobbler-io/dates/src/tzAdjust';
import { isNotFalsy } from '@cobbler-io/utils/src/isNotFalsy';

import {
  getFiscalYearIdFromISO8601,
  getPlannedForLineEdgeId,
  getPlannedInMonthEdgeId,
  getPlannedSpendForRevisionId,
  getQuarterIdFromISO8601,
} from './utils';

type Data = NonNullable<HydrateBudgetGraphQuery['plannedSpendAtRevision']>;

type HydratePlannedSpendParams = {
  graph: Graph<any, any>;
  data: Data;
  startMonth: JSMonth;
};

const emptyObject = {};

// Make sure all dependencies are met before calling this function.
// The Graph should already have BudgetLine data
export const hydratePlannedSpend = (params: HydratePlannedSpendParams): void => {
  const { data, graph, startMonth } = params;

  for (const ps of data) {
    const labels = [
      'PLANNED_SPEND',
      ps.isOverplanned && 'OVERPLANNED',
      `BUDGET_REVISION_${ps.revisionId}`,
      `MONTH_${ps.start}`,
      `QUARTER_${getQuarterIdFromISO8601(ps.start, startMonth)}`,
      `YEAR_${getFiscalYearIdFromISO8601(ps.start, startMonth)}`,
      ps.type, // EXPENSE or REVENUE
      // add in half?
    ].filter(isNotFalsy);

    const line = graph.getVertexById(ps.budgetLineId)!;
    const isRevenue = ps.type === 'REVENUE';

    const properties = isRevenue
      ? {
          // Numbers
          allocated: ps.allocated === 0 ? 0 : -1 * ps.allocated,
          available: ps.available === 0 ? 0 : -1 * ps.available,
          planned: ps.planned === 0 ? 0 : -1 * ps.planned,

          // Status
          overplanned: ps.isOverplanned,

          // Coordinates
          budgetLineId: ps.budgetLineId,
          budgetRevisionId: ps.revisionId,
          start: ps.start,
        }
      : {
          // Numbers
          allocated: ps.allocated,
          available: ps.available,
          planned: ps.planned,

          // Status
          overplanned: ps.isOverplanned,

          // Coordinates
          budgetLineId: ps.budgetLineId,
          budgetRevisionId: ps.revisionId,
          start: ps.start,
        };

    const vertex = graph.addVertex({
      id: ps.id,
      labels,
      properties,
    });

    // Draw a line between the planned spend and the month
    const month = graph.getVertexById(getMonthId(tzAdjust(ps.start)));

    if (month) {
      graph.addEdge({
        id: getPlannedInMonthEdgeId(ps.id),
        labels: ['PLANNED_FOR_MONTH'],
        from: vertex,
        to: month,
        properties: emptyObject,
      });
    }

    // Draw a line between the planned spend and the revision
    const revision = graph.getVertexById(ps.revisionId);

    if (revision) {
      graph.addEdge({
        id: getPlannedSpendForRevisionId(ps.id),
        labels: ['PLANNED_FOR_REVISION'],
        from: vertex,
        to: revision,
        properties: emptyObject,
      });
    }

    // Draw a line between the planned spend and the budget line

    if (line) {
      graph.addEdge({
        id: getPlannedForLineEdgeId(ps.id),
        labels: ['PLANNED_FOR_LINE'],
        from: vertex,
        to: line,
        properties: emptyObject,
      });
    }
  }
};
