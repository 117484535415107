import * as React from 'react';

type ErrorDisplayProps = {
  error: any;
};

/**
 * Generic error display that does nothing special
 */
export const ErrorDisplay = ({ error }: ErrorDisplayProps): JSX.Element => {
  // eslint-disable-next-line no-console
  console.error(error);

  return <>Error</>;
};

ErrorDisplay.displayName = 'ErrorDisplay';
