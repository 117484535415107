import * as React from 'react';
import * as ReactDOM from 'react-dom';

import { cx } from '@cobbler-io/utils/src/cx';

import { useCurrentModal } from '../Modal';
import { usePopper, UsePopperArgs } from './usePopper';

import styles from './DropdownPopup.scss';

type DropdownPopupProps = {
  /**
   * Whether or not to display an arrow
   */
  arrow?: boolean;

  /**
   * The color of the arrow. This should usually be the background color of the container
   */
  arrowColor?: string;

  className?: string;

  style?: React.CSSProperties;

  children: React.ReactNode;

  reference: React.RefObject<HTMLDivElement>;
};

const popperOptions: UsePopperArgs['options'] = {
  placement: 'bottom-start',
  strategy: 'fixed',
  modifiers: [
    /* eslint-disable @typescript-eslint/no-magic-numbers */
    { name: 'preventOverflow', options: { padding: [5, 10] } },
    { name: 'offset', options: { offset: [0, 8] } },
    { name: 'arrow', options: { padding: 5 } },
    /* eslint-enable @typescript-eslint/no-magic-numbers */
  ],
};

/**
 * `#portal` is always defined on our html
 */
const getPortal = (): HTMLDivElement | null => document.querySelector('#portal');

export const DropdownPopup = React.forwardRef<HTMLDivElement, DropdownPopupProps>(
  (props, ref: React.MutableRefObject<HTMLDivElement>) => {
    const { arrow = true, arrowColor = 'white', className, children, reference, style } = props;
    // TODO: Make this smarter. If there's a rerender, for example, when we hover over one of the
    // items on the menu, the popper instance will be updated. Ideally we only want to do this maybe
    // when the number of items in the menu changes.
    usePopper({ reference, modal: ref, options: popperOptions }, reference, children);
    const styleRef = React.useRef<React.CSSProperties>({ color: arrowColor });
    const containerModal = useCurrentModal();
    const container = getPortal();

    if (!container) {
      return null;
    }

    const zIndex = containerModal?.ref?.current?.style.zIndex;

    return ReactDOM.createPortal(
      <div
        ref={ref}
        className={cx(styles.dropdownListContainer, className)}
        style={{ zIndex, ...style }}
      >
        {arrow && <div className={styles.backgroundMask} />}
        {arrow && <div data-popper-arrow className={styles.arrow} style={styleRef.current} />}
        {children}
      </div>,
      container,
    );
  },
);

DropdownPopup.displayName = 'DropdownPopup';
