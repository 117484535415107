let supportsPreventScrollCached: boolean | null = null;

/**
 * @see https://github.com/calvellido/focus-options-polyfill
 */
const supportsPreventScroll: NullaryFn<boolean> = () => {
  if (supportsPreventScrollCached === null) {
    supportsPreventScrollCached = false;
    try {
      const focusElem = document.createElement('div');
      focusElem.focus({
        get preventScroll() {
          supportsPreventScrollCached = true;
          return true;
        },
      });
    } catch (e) {
      // Ignore
    }
  }

  return supportsPreventScrollCached;
};

type ScrollableElement = {
  element: HTMLElement;
  scrollTop: number;
  scrollLeft: number;
};

const isScrollable: UnaryFn<HTMLElement, boolean> = el =>
  el.offsetHeight < el.scrollHeight || el.offsetWidth < el.scrollWidth;

const getScrollableElements: UnaryFn<HTMLElement, ScrollableElement[]> = element => {
  const scrollableElements: ScrollableElement[] = [];
  const rootScrollingElement = document.scrollingElement || document.documentElement;

  let parent = element.parentNode;

  while (parent instanceof HTMLElement && parent !== rootScrollingElement) {
    if (isScrollable(parent)) {
      scrollableElements.push({
        element: parent,
        scrollTop: parent.scrollTop,
        scrollLeft: parent.scrollLeft,
      });
    }
    parent = parent.parentNode;
  }

  if (rootScrollingElement instanceof HTMLElement) {
    scrollableElements.push({
      element: rootScrollingElement,
      scrollTop: rootScrollingElement.scrollTop,
      scrollLeft: rootScrollingElement.scrollLeft,
    });
  }

  return scrollableElements;
};

const restoreScrollPosition: UnaryFn<ScrollableElement[], void> = elements => {
  elements.forEach(({ element, scrollTop, scrollLeft }) => {
    Object.assign(element, { scrollTop, scrollLeft });
  });
};

export const focusWithoutScrolling: UnaryFn<HTMLElement, void> = el => {
  if (supportsPreventScroll()) {
    el.focus({ preventScroll: true });
    return;
  }
  const saved = getScrollableElements(el);
  el.focus();
  restoreScrollPosition(saved);
};
