import * as React from 'react';

import { useCurrentBudget } from '@cobbler-io/redux/src/modules/current-budget';
import { useCurrentUser } from '@cobbler-io/redux/src/modules/current-user';
import { useGraphStatus } from '@cobbler-io/redux/src/modules/graph-status';
import { useFiscalYearStart } from '@cobbler-io/redux/src/modules/tenant-settings';

import { fetchInitialGraphData } from '@cobbler-io/app/src/api/graph/graph-hydrator';
import { useCurrentBudgetLine, useCurrentRange } from '@cobbler-io/app/src/providers';

import { useGraphContext } from '@cobbler-io/collection/src/Graph/react';

import { useApolloClient } from '@apollo/react-hooks';

export const LoadBudgetData = (): null => {
  const budgetLine = useCurrentBudgetLine()!;
  const [budget] = useCurrentBudget()!;
  const range = useCurrentRange();
  const client = useApolloClient();
  const startMonth = useFiscalYearStart();
  const currentUser = useCurrentUser()!;
  const { graph } = useGraphContext();
  const { setStatuses } = useGraphStatus();

  const { startString: start, endString: end } = range;

  React.useEffect(() => {
    if (!budgetLine || !budget) {
      return;
    }

    void fetchInitialGraphData({
      budgetId: budget.id,
      client,
      currentUser,
      end,
      graph,
      revisionId: budget.selectedRevisionId,
      setStatuses,
      start,
      startMonth,
    });
    // We're intentionally keeping this to a single budget
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [budget?.id]);

  return null;
};

LoadBudgetData.displayName = 'LoadBudgetData';
