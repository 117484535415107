import * as React from 'react';

import { cx } from '@cobbler-io/utils/src/cx';

import { Icon } from '@cobbler-io/core-ui/src/Icon';

import scrollIntoView, { Options as ScrollOptions } from 'scroll-into-view-if-needed';

import styles from './ListboxOption.scss';

const scrollOptions: ScrollOptions = {
  scrollMode: 'if-needed',
  block: 'nearest',
  inline: 'nearest',
};

export type ListboxOptionProps<TValue extends any = any> = {
  'aria-label': string;
  'aria-selected': boolean;
  active: boolean;
  disabled?: boolean;
  id: string;
  key: string;
  label: string | JSX.Element;
  onClick: React.MouseEventHandler<HTMLLIElement>;
  onMouseDown: React.MouseEventHandler<HTMLLIElement>;
  onMouseOver: React.MouseEventHandler<HTMLLIElement>;
  role: 'option';
  selected: string;
  title?: string;
  value: TValue;
};

export const ListboxOption = <TValue extends any = any>(
  props: ListboxOptionProps<TValue>,
): JSX.Element => {
  const ref = React.useRef<HTMLLIElement>(null);
  const { active, value, label, selected, disabled, ...rest } = props;

  React.useLayoutEffect(() => {
    if (active && ref.current) {
      scrollIntoView(ref.current, scrollOptions);
    }
  }, [active]);

  const className = cx(styles.listboxOption, active && styles.active, disabled && styles.disabled);

  return (
    <li ref={ref} {...rest} className={className}>
      <Icon aria-hidden className={styles.icon} size={12} type={selected ? 'check' : 'blank'} />
      {label}
    </li>
  );
};

ListboxOption.displayName = 'ListboxOption';
