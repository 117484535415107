/**
 * Teikn v1.0.0-alpha.6
 * Generated Tue Apr 30 2024 21:16:37
 *
 * This file is generated and should be commited to source control
 *
 */
export const tokens = {
  /**
   *  Type: border-radius
   */
  borderRadiusSharp: '0.125rem',
  /**
   *  Type: border-radius
   */
  borderRadiusSubtle: '0.25rem',
  /**
   *  Type: border-radius
   */
  borderRadiusStandard: '0.5rem',
  /**
   *  Type: border-radius
   */
  borderRadiusMore: '1rem',
  /**
   *  Type: border-radius
   */
  borderRadiusRound: '100%',
  /**
   *  Type: color
   */
  colorPrimary1: 'rgba(249, 215, 121, 1)',
  /**
   *  Type: color
   */
  colorPrimary2: 'rgba(0, 224, 245, 1)',
  /**
   *  Type: color
   */
  colorSecondary: 'rgba(238, 109, 170, 1)',
  /**
   *  Type: color
   */
  colorAccent1: 'rgba(48, 131, 203, 1)',
  /**
   *  Type: color
   */
  colorAccent2: 'rgba(130, 129, 222, 1)',
  /**
   *  Type: color
   */
  colorAccent3: 'rgba(198, 134, 226, 1)',
  /**
   *  Type: color
   */
  colorError: 'rgba(176, 0, 32, 1)',
  /**
   *  Type: color
   */
  colorWarning: 'rgba(255, 241, 0, 1)',
  /**
   *  Type: color
   */
  colorPaper: 'rgba(255, 255, 255, 1)',
  /**
   *  Use for valid values
   *  Type: color
   */
  colorOk: 'rgba(27, 156, 143, 1)',
  /**
   *  Use for error display like things
   *  Type: color
   */
  colorNotOk: 'rgba(219, 46, 48, 1)',
  /**
   *  When you want something other than ok
   *  Type: color
   */
  colorSuccess: 'rgba(38, 182, 154, 1)',
  /**
   *  Type: color
   */
  colorSurface: 'rgba(252, 252, 252, 1)',
  /**
   *  Use for banner background on landing page
   *  Type: color
   */
  colorLanding: 'rgba(239, 250, 248, 1)',
  /**
   *  Type: color
   */
  colorOnPrimary1: 'rgba(40, 50, 56, 1)',
  /**
   *  Type: color
   */
  colorOnPrimary2: 'rgba(255, 255, 255, 1)',
  /**
   *  Type: color
   */
  colorOnAccent1: 'rgba(255, 255, 255, 1)',
  /**
   *  Type: color
   */
  colorOnError: 'rgba(255, 255, 255, 1)',
  /**
   *  Type: color
   */
  colorOnWarning: 'rgba(40, 50, 56, 1)',
  /**
   *  Type: color
   */
  colorOnPaper: 'rgba(40, 50, 56, 1)',
  /**
   *  Type: color
   */
  colorOnSurface: 'rgba(40, 50, 56, 1)',
  /**
   *  Use for graphs
   *  Type: color
   */
  colorGraph1: 'rgba(220, 25, 117, 1)',
  /**
   *  Use for graphs
   *  Type: color
   */
  colorGraph2: 'rgba(38, 182, 154, 1)',
  /**
   *  Use for graphs
   *  Type: color
   */
  colorGraph3: 'rgba(159, 52, 206, 1)',
  /**
   *  Use for graphs
   *  Type: color
   */
  colorGraph4: 'rgba(245, 186, 23, 1)',
  /**
   *  Use for graphs
   *  Type: color
   */
  colorGraph5: 'rgba(48, 131, 203, 1)',
  /**
   *  Use for graphs
   *  Type: color
   */
  colorGraph6: 'rgba(239, 118, 36, 1)',
  /**
   *  Use for graphs
   *  Type: color
   */
  colorGraph7: 'rgba(40, 156, 64, 1)',
  /**
   *  Use for graphs
   *  Type: color
   */
  colorGraph8: 'rgba(54, 51, 198, 1)',
  /**
   *  Use for graphs
   *  Type: color
   */
  colorGraph9: 'rgba(250, 222, 52, 1)',
  /**
   *  Use for graphs
   *  Type: color
   */
  colorGraph10: 'rgba(239, 75, 63, 1)',
  /**
   *  Use for graphs
   *  Type: color
   */
  colorGraph11: 'rgba(255, 48, 120, 1)',
  /**
   *  Use for graphs
   *  Type: color
   */
  colorGraph12: 'rgba(69, 85, 96, 1)',
  /**
   *  Use for graphs
   *  Type: color
   */
  colorGraph13: 'rgba(181, 0, 48, 1)',
  /**
   *  Use for graphs
   *  Type: color
   */
  colorGraph14: 'rgba(117, 182, 255, 1)',
  /**
   *  Use for graphs
   *  Type: color
   */
  colorGraph15: 'rgba(157, 105, 255, 1)',
  /**
   *  Use for graphs
   *  Type: color
   */
  colorGraph16: 'rgba(242, 150, 228, 1)',
  /**
   *  Use for graphs
   *  Type: color
   */
  colorGraph17: 'rgba(131, 227, 178, 1)',
  /**
   *  Use for graphs
   *  Type: color
   */
  colorGraph18: 'rgba(209, 145, 255, 1)',
  /**
   *  Use for graphs
   *  Type: color
   */
  colorGraph19: 'rgba(255, 167, 140, 1)',
  /**
   *  Use for graphs
   *  Type: color
   */
  colorGraph20: 'rgba(194, 208, 255, 1)',
  /**
   *  Use for graphs
   *  Type: color
   */
  colorGraph21: 'rgba(132, 194, 25, 1)',
  /**
   *  Use for graphs
   *  Type: color
   */
  colorGraph22: 'rgba(146, 215, 250, 1)',
  /**
   *  Use for graphs
   *  Type: color
   */
  colorGraph23: 'rgba(56, 207, 137, 1)',
  /**
   *  Use for graphs
   *  Type: color
   */
  colorGraph24: 'rgba(255, 82, 197, 1)',
  /**
   *  Type: color
   */
  colorTextDarkPrimary: 'rgba(40, 50, 56, 1)',
  /**
   *  Type: color
   */
  colorTextDarkSecondary: 'rgba(148, 153, 156, 1)',
  /**
   *  For small text when secondary is too light and primary is too dark
   *  Type: color
   */
  colorTextDarkSmall: 'rgba(105, 112, 116, 1)',
  /**
   *  Type: color
   */
  colorTextDarkHint: 'rgba(201, 204, 205, 1)',
  /**
   *  Type: color
   */
  colorTextDarkDisabled: 'rgba(201, 204, 205, 1)',
  /**
   *  Type: color
   */
  colorTextDarkIcon: 'rgba(201, 204, 205, 1)',
  /**
   *  Type: color
   */
  colorTextLightPrimary: 'rgba(255, 255, 255, 1)',
  /**
   *  Type: color
   */
  colorTextLightSecondary: 'rgba(128, 128, 128, 1)',
  /**
   *  For small text when secondary is too light and primary is too dark
   *  Type: color
   */
  colorTextLightSmall: 'rgba(105, 112, 116, 1)',
  /**
   *  Type: color
   */
  colorTextLightHint: 'rgba(64, 64, 64, 1)',
  /**
   *  Type: color
   */
  colorTextLightDisabled: 'rgba(64, 64, 64, 1)',
  /**
   *  Type: color
   */
  colorTextLightIcon: 'rgba(64, 64, 64, 1)',
  /**
   *  Type: font-size
   */
  fontSizeOverline: '0.875rem',
  /**
   *  Type: font-size
   */
  fontSize100: '0.625rem',
  /**
   *  Type: font-size
   */
  fontSize200: '0.75rem',
  /**
   *  Type: font-size
   */
  fontSize300: '0.875rem',
  /**
   *  Type: font-size
   */
  fontSize400: '1rem',
  /**
   *  Type: font-size
   */
  fontSize500: '1.125rem',
  /**
   *  Type: font-size
   */
  fontSize600: '1.25rem',
  /**
   *  Type: font-size
   */
  fontSize700: '1.5rem',
  /**
   *  Type: font-size
   */
  fontSize800: '2.25rem',
  /**
   *  Type: font-size
   */
  fontSize900: '2.5rem',
  /**
   *  Type: font-size
   */
  fontSize1000: '3rem',
  /**
   *  Type: font-family
   */
  fontFamilyOverline: 'Rubik, "Roboto Flex", Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
  /**
   *  Type: font-family
   */
  fontFamilyMono: '"Roboto Mono", "PT Mono", monospace',
  /**
   *  Type: font-family
   */
  fontFamilyHeadline: 'Rubik, "Roboto Flex", Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
  /**
   *  Type: font-family
   */
  fontFamilyBody: '"Roboto Flex", Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
  /**
   *  Type: font-family
   */
  fontFamilyButton: '"Roboto Flex", Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
  /**
   *  Type: font-weight
   */
  fontWeightOverline: 400,
  /**
   *  Normal weight for headline font families
   *  Type: font-weight
   */
  fontWeightHeadline: 500,
  /**
   *  Bold version to use with headline font families
   *  Type: font-weight
   */
  fontWeightHeadlineBold: 700,
  /**
   *  Normal weight for body font families
   *  Type: font-weight
   */
  fontWeightBody: 400,
  /**
   *  Bold version to use with body font families
   *  Type: font-weight
   */
  fontWeightBodyBold: 700,
  /**
   *  Type: font-spacing
   */
  fontSpacingButton: '0.00625rem',
  /**
   *  Type: font-spacing
   */
  fontSpacingCaption: '0.025rem',
  /**
   *  Type: font-spacing
   */
  fontSpacingOverline: '0.0125rem',
  /**
   *  Use this rarely
   *  Type: spacing
   */
  spacingFemto: '0.125rem',
  /**
   *  Just to let things breathe a bit
   *  Type: spacing
   */
  spacingPico: '0.25rem',
  /**
   *  Just to let things breathe a bit
   *  Type: spacing
   */
  spacingNano: '0.5rem',
  /**
   *  Standard spacing
   *  Type: spacing
   */
  spacingStandard: '1rem',
  /**
   *  Just to let things breathe a bit
   *  Type: spacing
   */
  spacingKilo: '1.25rem',
  /**
   *  Just to let things breathe a bit
   *  Type: spacing
   */
  spacingMega: '1.5rem',
  /**
   *  Just to let things breathe a bit
   *  Type: spacing
   */
  spacingGiga: '2rem',
  /**
   *  Use this rarely
   *  Type: spacing
   */
  spacingFemtoNegative: '-0.125rem',
  /**
   *  Just to let things breathe a bit
   *  Type: spacing
   */
  spacingPicoNegative: '-0.25rem',
  /**
   *  Just to let things breathe a bit
   *  Type: spacing
   */
  spacingNanoNegative: '-0.5rem',
  /**
   *  Standard spacing
   *  Type: spacing
   */
  spacingStandardNegative: '-1rem',
  /**
   *  Just to let things breathe a bit
   *  Type: spacing
   */
  spacingKiloNegative: '-1.25rem',
  /**
   *  Just to let things breathe a bit
   *  Type: spacing
   */
  spacingMegaNegative: '-1.5rem',
  /**
   *  Just to let things breathe a bit
   *  Type: spacing
   */
  spacingGigaNegative: '-2rem',
  /**
   *  Type: transparency
   */
  transparencyEmphasisDarkHigh: '0.87',
  /**
   *  Type: transparency
   */
  transparencyEmphasisDarkMedium: '0.54',
  /**
   *  Type: transparency
   */
  transparencyEmphasisDarkMuted: '0.38',
  /**
   *  Type: transparency
   */
  transparencyEmphasisLightHigh: '0.95',
  /**
   *  Type: transparency
   */
  transparencyEmphasisLightMedium: '0.7',
  /**
   *  Type: transparency
   */
  transparencyEmphasisLightMuted: '0.5',
  /**
   *  Type: duration
   */
  durationExpandSmall: '0.25s',
  /**
   *  Type: duration
   */
  durationExpandLarge: '0.3s',
  /**
   *  Type: duration
   */
  durationCollapseSmall: '0.2s',
  /**
   *  Type: duration
   */
  durationCollapseLarge: '0.25s',
  /**
   *  Type: duration
   */
  durationSimple: '0.1s',
  /**
   *  Type: duration
   */
  durationSimpleIn: '0.15s',
  /**
   *  Type: duration
   */
  durationSimpleOut: '0.075s',
  /**
   *  Type: duration
   */
  durationComplex: '0.2s',
  /**
   *  Type: duration
   */
  durationSpring: '0.33s',
  /**
   *  Type: duration
   */
  durationSpringLong: '0.55s',
  /**
   *  Type: timing
   */
  timingEase: 'cubic-bezier(0.4, 0, 0.2, 1)',
  /**
   *  Type: timing
   */
  timingAccelerate: 'cubic-bezier(0.4, 0, 1, 1)',
  /**
   *  Type: timing
   */
  timingDecelerate: 'cubic-bezier(0, 0, 0.2, 1)',
  /**
   *  Type: timing
   */
  timingSpring: 'cubic-bezier(.2, 1, .1, 1)',
  /**
   *  Type: z-layer
   */
  zLayerModal: 5000,
  /**
   *  For floating elements like the app bar and floating buttons
   *  Type: z-layer
   */
  zLayerFloating: 2000,
  /**
   *  for darkening the application and emphasizing visual focus
   *  Type: z-layer
   */
  zLayerOverlay: 4000,
};


export default tokens;