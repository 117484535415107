import * as React from 'react';

import { isFunction } from '@cobbler-io/utils/src/isFunction';

import { Item } from '../Item';

export const InlineTextValues = <TValue extends any = any>(props: {
  selected: Item<TValue>[];
}): JSX.Element => {
  const { selected } = props;

  return (
    <>
      {selected.map((item: Item<TValue>) => {
        if (item.selectedLabel) {
          return (
            <span key={item.id}>
              {isFunction(item.selectedLabel) ? item.selectedLabel(item.value) : item.selectedLabel}
            </span>
          );
        }

        if (isFunction(item.label)) {
          return <span key={item.id}>{item.label(item.value)}</span>;
        }

        if (typeof item.label === 'string' || React.isValidElement(item.label)) {
          return <span key={item.id}>{item.label}</span>;
        }

        // This is obviously not a good path
        return null;
      })}
    </>
  );
};

InlineTextValues.displayName = 'InlineTextValues';
