import * as React from 'react';

import { Button } from '@cobbler-io/core-ui/src/Button';
import { Card } from '@cobbler-io/core-ui/src/Card';
import { Field } from '@cobbler-io/core-ui/src/Field';
import { Logo } from '@cobbler-io/core-ui/src/Logo';

import { ACCEPT_TOS } from '@cobbler-io/app/src/gql-queries';
import { CurrentUserContext } from '@cobbler-io/app/src/providers';

import { useMutation } from '@apollo/react-hooks';
import loadable from '@loadable/component';
import { Form } from '@swan-form/form';
import { required } from '@swan-form/helpers';

import styles from './TOSGate.scss';

const TOS = loadable(
  async () => import(/* webpackChunkName: "terms-of-service" */ '@cobbler-io/core-ui/src/TOS'),
);

type Props = Record<string, unknown>

export const TOSGate: React.FC<Props> = () => {
  const { refetch } = React.useContext(CurrentUserContext);
  const [acceptTOS] = useMutation(ACCEPT_TOS);

  return (
    <div className={styles.container}>
      <Card>
        <div className={styles.logoContainer}>
          <Logo className={styles.logo} />
        </div>
        <div className={styles.textContainer}>
          <p>Welcome to Cobbler! Please read through and sign our Terms of Service.</p>
          <div className={styles.legalText}>
            <React.Suspense fallback={<div>Loading...</div>}>
              <TOS />
            </React.Suspense>
          </div>
        </div>

        <Form afterSubmit={refetch} name="sign terms of service" onSubmit={acceptTOS}>
          <div className="grid">
            <div className="row">
              <div className="col small-6">
                <Field
                  validateOnChange
                  defaultChecked={false}
                  label="I agree to the Terms of Service"
                  name="agree"
                  type="checkbox"
                  validate={required}
                />
              </div>
              <div className="col small-6">
                <Button name="submit terms of service" type="submit">
                  Submit
                </Button>
              </div>
            </div>
          </div>
        </Form>
      </Card>
    </div>
  );
};

TOSGate.displayName = 'TOSGate';

export default TOSGate;
