/* eslint-disable complexity */
/* eslint-disable functional/immutable-data, react/boolean-prop-naming */
/* eslint-disable max-lines */
/**
 * TODO add in controlled paging capability
 * TODO add in uncontrolled paging capability
 * TODO focus on performance for grid updates when props / data changes (or when you resize columns)
 */
import * as React from 'react';

import { execIfFunc, isFunction } from '@cobbler-io/utils/src';
import { arraysAreEqual } from '@cobbler-io/utils/src/array/arraysAreEqual';
import { cx } from '@cobbler-io/utils/src/cx';
import { getBy } from '@cobbler-io/utils/src/getBy';
import { isNotFalsy } from '@cobbler-io/utils/src/isNotFalsy';
import { isString } from '@cobbler-io/utils/src/isString';
import { prop } from '@cobbler-io/utils/src/prop';

/* eslint-disable max-lines-per-function */
import { getWidest } from '@cobbler-io/dom/src/getWidest';

import { useToggle } from '@cobbler-io/hooks/src';
import { useDragAutoScroll } from '@cobbler-io/hooks/src/useDragAutoScroll';
import { useForceUpdate } from '@cobbler-io/hooks/src/useForceUpdate';
import { useForwardedRef } from '@cobbler-io/hooks/src/useForwardedRef';
import { usePrevious } from '@cobbler-io/hooks/src/usePrevious';
import { useSafeState } from '@cobbler-io/hooks/src/useSafeState';
import { useURLState } from '@cobbler-io/hooks/src/useURLState';

import { WrapperFullScreenPortal } from '@cobbler-io/core-ui/src/FullScreenPortal';

import { isEqual } from 'lodash';
import {
  complement, difference, F as alwaysFalse, filter, isNil, pick, pipe, pluck, T as alwaysTrue,
} from 'ramda';
import {
  ColumnInstance, HeaderGroup, IdType, Meta, reduceHooks, Row as ReactTableRow, TableInstance,
  TableOptions, TableState, useExpanded, useFilters, useFlexLayout, useGetLatest, useGlobalFilter,
  useGroupBy, useSortBy, useTable,
} from 'react-table';

import { autosizeAll } from './autosizeAll';
import { childrenToColumns } from './childrenToColumns';
import { aggregations, Column, ColumnProps } from './Column';
import { createSetGroupByExclusive } from './createSetGroupByExclusive';
import { usePinnedColumns } from './custom-hooks/usePinnedColumns';
import { useResizeColumns } from './custom-hooks/useResizeColumns';
import { DataGridContext } from './DataGridContext';
import { DataGridTable } from './DataGridTable';
import { EmptyState, NoResultsState } from './EmptyStates';
import { DefaultFilter } from './Filter/DefaultFilter';
import { useFilterDrawer } from './Filter/FilterContainer';
import { filterTypes } from './filterTypes';
import { FooterRow } from './FooterRow';
import { HeaderRow } from './HeaderRow';
import { Label } from './Label';
import { Loading } from './Loading';
import { DropCollectedProps, Row } from './Row';
import { alphanumeric } from './sortTypes';
import { Toolbar } from './Toolbar';
import { useGridNavigationKeys } from './useGridNavigationKeys';
import { useHierarchy } from './useHierarchy';
import { useRowDragAndDrop } from './useRowDragAndDrop';
import { useRowSelect } from './useRowSelect';
import { useVirtualScroll } from './useVirtualScroll';

import styles from './DataGrid.scss';

/**
 * `#portal` is always defined on our html
 */
const getPortal = (): HTMLDivElement | null => document.querySelector('#portal');

export type ViewSettings<D extends UnknownObject> = Omit<
  Partial<TableState<D>>,
  'columnResizing'
> & {
  columnResizing: Partial<TableState<D>['columnResizing']>;
};

const getViewSettings = <D extends UnknownObject>(x: TableInstance<D>): ViewSettings<D> => ({
  // @ts-expect-error: TODO update the types everywhere
  columnPinning: x.state.columnPinning,
  columnResizing: x.state.columnResizing,
  expanded: x.state.expanded,
  groupBy: x.state.groupBy,
  hiddenColumns: x.state.hiddenColumns,
  sortBy: x.state.sortBy,
});

export type DataGridProps<T extends UnknownObject = UnknownObject> = {
  /**
   * An array of objects that can be transformed into a DataGrid
   */
  data: T[];

  /**
   * Attribute to identify the grid for testing purposes
   */
  testId?: string;

  /**
   * A unique ID for the data grid. If passed, there will be some side effects like
   * the view settings will be cached.
   */
  id?: string;

  defaultColumn?: Partial<ColumnProps<T>>;

  /**
   * Initially expanded rows. Should be Record<RowID, boolean> where RowID is something like:
   * `'1'` or `'2.3'`. Basically, you have to know ahead of time what row id react table will
   * assign a piece of data
   */
  defaultExpanded?: Record<string, boolean>;

  /**
   * Disables groupBy on a table-wide level
   *
   * Note: sending `groupable` on a column will override the default value for the table
   *
   * TODO see if we want to change this behavior
   */
  disableGroupBy?: boolean;

  disableFilter?: boolean;

  disableGlobalFilter?: boolean;

  /**
   * TODO implement
   */
  disableResizing?: boolean;

  /**
   * TODO implement
   */
  disableSorting?: boolean;

  /**
   * Disables pinning for the entire table (this overrides any settings on the columns)
   * default: `false`
   */
  disablePinning?: boolean;

  /**
   * Prepares the table to handle draggable rows
   */
  draggable?: boolean;

  getRowId?: (
    row: ReactTableRow<T>,
    relativeIndex: number,
    parent: ReactTableRow<T>,
  ) => string | number;

  /**
   * If we can export rows, can the row be exported?
   *
   * Defaults to `() => true`
   */
  isRowExportable?: (row: ReactTableRow<T>) => boolean;

  /**
   * A function that tells the grid which rows are draggable. Defaults to all rows.
   */
  isRowDraggable?: (row: ReactTableRow<T>) => boolean;

  /**
   * A function that tells the grid which rows are valid drop targets. Defaults to all rows.
   */
  isRowDropTarget?: (row: ReactTableRow<T>) => boolean;

  /**
   * A function that calculates if the rows being dragged can be dropped based on the target row.
   */
  isRowDroppable?: (targetRow: ReactTableRow<T>, draggedRows: ReactTableRow<T>[]) => boolean;

  /**
   * A function that tells the grid which rows are disabled. Defaults to false.
   * (This is primarily used on editing but it can be overridden)
   */
  isRowDisabled?: (row: ReactTableRow<T>) => boolean;

  // TODO: Add descriptions!
  onDraggableDrop?: (targetRow: ReactTableRow<T>, draggedRows: ReactTableRow<T>[]) => void;
  onDraggableHover?: (targetRow: ReactTableRow<T>, draggedRows: ReactTableRow<T>[]) => void;
  onDraggableEnd?: (targetRow: ReactTableRow<T>, draggedRows: ReactTableRow<T>[]) => void;
  onDraggableChange?: (
    targetRow: ReactTableRow<T>,
    draggedRows: ReactTableRow<T>[],
    isOver: boolean,
    isValid: boolean,
  ) => DropCollectedProps;

  // Row events
  onRowClick?: (event: React.MouseEvent<HTMLDivElement>, row: ReactTableRow<T>) => void;

  /**
   * Display this in the table body instead of the rows when the data set is empty
   */
  emptyState?: React.ReactNode | React.ComponentType;

  /**
   * Display this in the table body instead of the rows when the filter returns no results
   * (and the data set is not empty)
   */
  noResultsState?: React.ReactNode | React.ComponentType;

  /**
   * Any children will be rendered above the grid
   */
  children: React.ReactNode;

  /**
   * Shows the footer or not
   *
   * Default: `false`
   */
  showFooter?: boolean;

  /**
   * Sets the initial view state of the table
   *
   * Default: `{}`
   */
  initialViewSettings?: ViewSettings<T>;

  /**
   * A function that will be called when the component unmounts.
   * Provides access to the latest state of the component
   */
  onUnmount?: (viewSettings: ViewSettings<T>) => void;

  /**
   * A function that will be called when the component state changes.
   * Provides access to the latest state of the component
   */
  onChange?: (viewSettings: ViewSettings<T>) => void;

  /**
   * A label for the main "tbody"
   */
  tableLabel?: React.ReactNode;

  /**
   * A label for the summary "tbody"
   */
  summaryLabel?: React.ReactNode;

  /**
   * Data that will create a preceding "tbody"
   */
  summaryData?: Record<string, any>[];

  setRef?: React.RefObject<HTMLDivElement>;

  /**
   * A function that determines if a row has subRows
   */
  hasSubRows?: (row: ReactTableRow<T>) => boolean;

  /**
   * Tells each row how to load subRows when hasSubRows returns true
   * and the row's subRow array is empty.
   */
  onFetchSubRows?: (row: ReactTableRow<T>) => Promise<any>; // When we try to expand a parent with unloaded children

  /**
   * Manual (controlled) sorting. Setting this prop will cause the DataGrid to
   * skip sorting.
   */
  onSort?: (instance: TableInstance<T>) => void;

  /**
   * Manual (controlled) filtering. Setting this prop will cause the DataGrid to
   * skip filtering.
   */
  onFilter?: (instance: TableInstance<T>) => void;

  /**
   * Manual (controlled) global filtering. Setting this prop will cause the
   * DataGrid to skip global filtering.
   */
  onGlobalFilter?: (instance: TableInstance<T>) => void;

  /**
   * If set, this function will allow you to modify the state after every
   * re-render. Useful when the server response comes back with state.
   *
   * **NOTE:** Since this runs on every re-render, it's important to memoize it.
   */
  onStateChange?: (
    state: TableState<T>,
    prevState: TableState<T> | null,
    meta: Meta<T>,
  ) => TableState<T>;

  /**
   * Must be set to `true` if you're planning to use selectable rows.
   */
  selectable?: boolean;

  /**
   * Whether sub rows should be selected when selecting a parent row.
   */
  preventSelectSubRows?: boolean;

  /**
   * A function that tells the grid which rows are selectable. Defaults to all rows.
   */
  isRowSelectable?: (row: ReactTableRow<T>) => boolean;

  onSelectionChange?: (selectedRows: ReactTableRow<T>[]) => void;

  /**
   * Normally, the data-grid will start windowing itself after it reaches a certain number of rows.
   * Passing this as `true` will force virtualization with no minimum number of rows.
   */
  forceVirtualization?: boolean;

  className?: string;

  instanceRef?: React.MutableRefObject<TableInstance<T> | null>;
};

const getKeysGreaterThanX =
  (x: number) =>
  (acc: string[], [key, value]: [string, number]): string[] =>
    value > x ? acc : [...acc, key];

const COLUMN_WIDTH_THRESHOLD = 6;

type DataGridInternalProps<D extends Record<string, unknown> = Record<string, unknown>> =
  DataGridProps<D> & {
    columns: ColumnProps<D>[];
    isFullScreen: boolean;
    toggleFullScreen: () => void;
  };

const getHooks = <D extends Record<string, unknown>>(props: DataGridInternalProps<D>) => {
  const {
    disableGroupBy = false,
    disableSorting = false,
    disableFilter = false,
    disableGlobalFilter = false,
    disableResizing = false,
    disablePinning = false,
    selectable = false,
    draggable = false,
  } = props;

  const initialHooks: ((hooks: any) => void)[] = [
    !disableResizing && useResizeColumns,
    !disableFilter && useFilters,
    !disableGlobalFilter && useGlobalFilter,
    !disableGroupBy && useGroupBy,
    !disableSorting && useSortBy,
    !disablePinning && usePinnedColumns,
    useExpanded,
    useHierarchy,
    selectable && useRowSelect,
    draggable && useRowDragAndDrop,
    useFlexLayout,

    (hooks: any) => {
      // This will reorder the columns so that if there is a `groupByBoundary`, then those
      // will be placed first. I don't have access to an object that includes whether or not
      // the table is currently grouped, so we have to do this regardless. A side-effect is
      // that anything that has a groupByBoundary will always be placed at the start
      hooks.visibleColumns.push((columns: any) => {
        const groupByBoundary = columns.filter(prop('groupByBoundary'));
        const rest = columns.filter((x: any) => !groupByBoundary.includes(x));
        return [...groupByBoundary, ...rest];
      });
    },
  ].filter(isNotFalsy);

  return initialHooks;
};

type RowObject<D> = {
  id: IdType<D>;
  original: D;
  index: number;
  cells: any[];
  values: Record<IdType<D>, any>;
};

/**
 * This is modified code that is taken from react-table that calculates the rows
 */
const accessRow = <D extends Record<string, unknown>>(
  instance: TableInstance<D>,
  datum: any,
  index: number,
) => {
  const row: RowObject<D> = {
    cells: [{}],
    id: datum.id,
    index,
    original: datum,

    // Disabling lint rule since `values` will become the specified type
    // at the end. This also requires mutating `values`...
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    values: {} as Record<IdType<D>, any>,
  };

  instance.allColumns.forEach(column => {
    if (column.summaryAccessor) {
      row.values[column.id] = isString(column.summaryAccessor)
        ? getBy(datum, column.summaryAccessor, undefined)
        : column.summaryAccessor(datum, index, row);
    } else if (column.accessor) {
      row.values[column.id] = column.accessor(datum, index, row);
    }

    row.values[column.id] = reduceHooks(
      instance.getHooks().accessValue,
      row.values[column.id],
      { column, instance, row },
      true,
    );
  });

  return row;
};

const handlerRowName = /^onRow/u;
const isRowHandler = ([key, value]: [string, any]): boolean =>
  key.startsWith('onRow') && isFunction(value);

type CreateScrollHandlerParams<D extends Record<string, unknown>> = {
  getInstance: () => TableInstance<D>;
  containerRef: React.RefObject<HTMLDivElement>;
  tableRef: React.RefObject<HTMLDivElement>;
  getStuck: () => string[];
  setStuck: (state: string[]) => any;
};

const createScrollHandler = <D extends Record<string, unknown>>(
  params: CreateScrollHandlerParams<D>,
) => {
  const { getInstance, containerRef, tableRef, getStuck, setStuck } = params;

  const tableRect = tableRef.current?.getBoundingClientRect() ?? null;
  const lastTableScroll = {
    left: tableRect?.left ?? 0,
    top: tableRect?.top ?? 0,
  };

  // eslint-disable-next-line functional/no-let, no-restricted-syntax
  let raf = 0;

  const handler = () => {
    const { pinnedColumns } = getInstance().state;
    const { left: containerLeft = 0, top: containerTop = 0 } =
      containerRef.current?.getBoundingClientRect() ?? {};
    const { left: tableLeft = 0 } = tableRef.current?.getBoundingClientRect() ?? {};

    const isHorizontal = lastTableScroll.left !== tableLeft;
    lastTableScroll.top = containerTop;
    lastTableScroll.left = containerLeft;

    if (containerLeft === null || !isHorizontal) {
      // The ref didn't exist, so exit out
      // Or the table doesn't have a header, which is not really possible with this
      return;
    }

    const isElementPinned = (x: Element) =>
      x instanceof HTMLDivElement &&
      isString(x.dataset.col) &&
      pinnedColumns.includes(x.dataset.col);

    const isElementStuck = (x: HTMLDivElement) => {
      // I'd like to find a way not to have to grab its boundingClientRect. This is slow
      const { left } = x.getBoundingClientRect();
      // We know that the element is in a "stuck" position, if its left-side rect is
      // equal to or less than the left-side rect of the scrolling container plus
      // the relative left of its style prop. The `left` style prop should be in pixels.
      // We'll also add in some threshold in case things get rendered slightly weird
      const threshold = 5;
      return left <= containerLeft + parseInt(x.style.left) + threshold;
    };

    const getColId = (x: HTMLDivElement) => x.dataset.col;

    const pinnedElements = Array.from(
      tableRef.current?.querySelectorAll('div[role="columnheader"]') ?? [],
    )
      .filter(isElementPinned)
      .filter(isElementStuck)
      .map(getColId)
      .filter(isNotFalsy);

    !arraysAreEqual(getStuck(), pinnedElements) && setStuck(pinnedElements);
  };

  return () => {
    window.cancelAnimationFrame(raf);
    raf = window.requestAnimationFrame(handler);
  };
};

const oldGetRowId = <T extends UnknownObject>(
  row: ReactTableRow<T>,
  relativeIndex: number,
  parent: ReactTableRow<T>,
): string => (parent ? [parent.id, relativeIndex].join('.') : relativeIndex.toString());

const newGetRowId = <T extends UnknownObject>(
  row: ReactTableRow<T>,
  relativeIndex: number,
  parent: ReactTableRow<T>,
): string | number => row.id ?? oldGetRowId(row, relativeIndex, parent);

const defaultGetRowId = newGetRowId;
const defaultEmptyState = <EmptyState />;
const defaultNoResultsState = <NoResultsState />;

const DefaultColumn = {
  Filter: DefaultFilter,

  // eslint-disable-next-line max-params
  sortType: (a: ReactTableRow, b: ReactTableRow, column: string, desc: boolean) => {
    if (a.original?.systemLine?.isSystemLine || b.original?.systemLine?.isSystemLine) {
      return desc ? -1 : 1;
    }
    return alphanumeric(a, b, column);
  },
};

// eslint-disable-next-line complexity
export const DataGridInternal = <D extends UnknownObject>(
  props: DataGridInternalProps<D>,
): JSX.Element => {
  const {
    columns,
    data,
    testId,
    showFooter,
    emptyState = defaultEmptyState,
    noResultsState = defaultNoResultsState,
    disableGroupBy = false,
    forceVirtualization = false,
    initialViewSettings = {},
    getRowId = defaultGetRowId,
    defaultExpanded,
    defaultColumn = DefaultColumn,
    draggable = false,
    isRowExportable = alwaysTrue,
    isRowDraggable,
    isRowDropTarget,
    isRowDroppable,
    isRowDisabled = alwaysFalse,
    onDraggableDrop,
    onDraggableHover,
    onDraggableEnd,
    onDraggableChange,
    onChange,
    onUnmount,
    children,
    summaryData,
    summaryLabel,
    tableLabel,
    setRef,
    hasSubRows,
    preventSelectSubRows = false,
    isRowSelectable,
    onSelectionChange,
    onFetchSubRows,
    onSort,
    onFilter,
    onGlobalFilter,
    onStateChange,
    className,
    instanceRef,
    isFullScreen,
    toggleFullScreen,
  } = props;

  const ref = useForwardedRef(setRef);
  const forceUpdate = useForceUpdate();

  // Toggle to show/hide the filter drawer
  const { active: showFilterDrawer, toggle: toggleFilterDrawer } = useToggle(false);

  const [filterUrlState, setFilterUrlState] = useURLState({}, 'grid.filters');

  const prevStateRef = React.useRef<TableState<D> | null>(null);

  const handleStateChange = (state: TableState<D>, meta: Meta<D>) =>
    onStateChange!(state, prevStateRef.current, meta);

  // If we don't memoize this, then it'll always be different, and we'll get an infinite re-render.
  // By passing all of these params to useTable, we make sure they are available in the `instance`
  // so that plugins have access to them.
  // @ts-expect-error: sort out differences between ColumnProps<D> and ReactTable.Column<D>
  const tableConfiguration: TableOptions<D> = React.useMemo(
    () => ({
      aggregations,

      // This is useful when editing a nested grid. we can add a prop at some point to make
      // this configurable
      autoResetExpanded: false,

      autoResetFilters: false,

      // When this is set to true, the hiddenColumns state will automatically reset when the
      // `columns` props is changed. In our specific case, `columns` should no change, however
      // when clicking on "spent" to see the list of actuals, a refetch is triggered and
      // Apollo passes new `columns` with the same values, triggering a hiddenColumns reset.
      // Settings this to false fixes this side effect but we might have to find a better
      // workaround in the future.
      autoResetHiddenColumns: false,

      autoResetSelectedRows: false,

      columns,
      data,
      defaultColumn,
      disableGroupBy,
      filterTypes,
      getRowId,
      initialState: {
        ...(defaultExpanded ? { expanded: defaultExpanded } : {}),
        ...initialViewSettings,

        filters: Object.entries(filterUrlState).map(([id, value]) => ({
          id,
          value,
        })),
      }, // TODO: Check for valid view settings/state

      isRowSelectable,
      isRowDisabled,

      // Draggable options
      isRowDraggable,
      isRowDropTarget,
      isRowDroppable,
      onDraggableDrop,
      onDraggableHover,
      onDraggableEnd,
      onDraggableChange,

      // Full screen options
      isFullScreen,
      toggleFullScreen,

      manualFilters: isFunction(onFilter),
      manualGlobalFilters: isFunction(onGlobalFilter),
      manualSortBy: isFunction(onSort),
      selectSubRows: !preventSelectSubRows,
      toggleFilterDrawer,
      useControlledState: isFunction(onStateChange) ? handleStateChange : undefined,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [columns, data, aggregations],
  );

  const instance = useTable<D>(tableConfiguration, ...getHooks(props));

  instance.showFilterDrawer = showFilterDrawer;
  instance.isRowExportable = isRowExportable;
  instance.isRowDisabled = isRowDisabled;

  const oldColumns = usePrevious(columns);

  React.useEffect(() => {
    if (oldColumns && !isEqual(pluck('id', oldColumns ?? []), pluck('id', columns))) {
      const oldColumnIds = pluck('id', oldColumns ?? []);
      const newColumns = columns.filter(x => !oldColumnIds.includes(x.id));
      const shouldPin = (x: ColumnProps<D>): boolean =>
        Boolean(x.canPin) &&
        Boolean(x.defaultPinned) &&
        !instance.state.pinnedColumns.includes(x.id!);
      const getIdsToPin = pipe(filter(shouldPin), pluck('id'));
      getIdsToPin(newColumns).forEach(id => {
        instance.pinColumn(id);
      });
    }
  }, [columns, instance, oldColumns]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
    allColumns,
    toggleGroupBy,
    visibleColumns,
    setFilter: originalSetFilter,
    setAllFilters: originalSetAllFilters,
    state,
  } = instance;

  instance.forceUpdate = forceUpdate;

  prevStateRef.current = state;

  const { sortBy, filters, globalFilter } = state;

  React.useEffect(() => {
    execIfFunc(onSort, instance);
  }, [sortBy]);

  React.useEffect(() => {
    execIfFunc(onFilter, instance);
  }, [filters]);

  React.useEffect(() => {
    execIfFunc(onGlobalFilter, instance);
  }, [globalFilter]);

  // Wrap the global setFilter to also navigate
  instance.setFilter = (id, value) =>
    setFilterUrlState(prev => {
      // Check new value, if it's an empty array, send null
      const newValue = Array.isArray(value) && value.length === 0 ? null : value;
      originalSetFilter(id, newValue);
      return { ...prev, [id]: value };
    });

  // Wrap the global setAllFilters to also navigate
  instance.setAllFilters = allFilters => {
    originalSetAllFilters(allFilters);
    if (Array.isArray(allFilters)) {
      setFilterUrlState(
        allFilters.reduce(
          (acc, { id, value }) =>
            Array.isArray(value) && value.length === 0 ? acc : { ...acc, [id]: value },
          {},
        ),
      );
    }
  };

  /**
   * This is a crappy way of handling the stuck items and the unstuck items
   *
   * @todo do better than this
   */
  const [stuck, setStuck] = React.useState<string[]>([]);

  const getStuck = useGetLatest(stuck);

  const getInstance = useGetLatest(instance);

  React.useLayoutEffect(() => {
    // This is a forced reflow the first time we see it, but it'll size everything correctly
    if (Object.keys(initialViewSettings?.columnResizing?.columnWidths ?? {}).length < 1) {
      autosizeAll(getInstance());
    }

    // We expose the current view settings of the table when unmounting
    return () => {
      onUnmount?.(getViewSettings(getInstance()));
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(
    () => {
      onChange?.(getViewSettings(getInstance()));
    } /* no dep array is purposeful */,
  );

  // Create a ref to reference the table element itself
  const tableRef = React.useRef<HTMLDivElement>(null);

  const tbodyRef = React.useRef<HTMLDivElement>(null);

  const tableContainerRef = React.useRef<HTMLDivElement>(null);

  const contextValue = React.useMemo(
    () => ({ getInstance, stuck, tableRef }),

    // state will update the context more often, i.e. when the table state changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tableRef, stuck, getInstance, state],
  );

  // Additionally, attach the table instance to the provided instanceRef prop
  React.useEffect(() => {
    if (instanceRef) {
      instanceRef.current = instance;
    }
  }, [instanceRef, instance]);

  // TODO find a more stable way to decorate the instance
  // Create a handler that allows only one column to be grouped at once
  instance.setGroupByExclusive = createSetGroupByExclusive(
    Array.from(new Set(allColumns.filter(prop('canGroupBy')).map(prop('id')))),
    toggleGroupBy,
  );
  instance.tableRef = tableRef;

  // Inject nesting props into instance
  // TODO: We're never using these props directly but through row, so this isn't
  // really necessary. Consider if we should kep them.
  instance.hasSubRows = hasSubRows;
  instance.onFetchSubRows = onFetchSubRows;

  /* eslint-disable no-param-reassign */
  allColumns.forEach((col: ColumnInstance<any>) => {
    // eslint-disable-next-line no-param-reassign
    // Add the instance to all the columns (this might not be so great)
    col.instance = instance;

    col.autosize = () =>
      instance.tableRef.current &&
      getWidest(`[data-col="${col.id}"]`, instance.tableRef.current).then(widest => {
        instance.setColumnSize(col.id, widest + 4, col);
      });
  });
  /* eslint-enable no-param-reassign */

  const prevColumnIds = usePrevious<string[]>(instance.visibleColumns.map(prop('id')));
  // Run an effect to autosize a column if it is new in the id array
  React.useEffect(() => {
    const cols = getInstance().visibleColumns;
    const currentColumnIds = pluck('id', cols);

    // Exit early as we can't detect
    if (!prevColumnIds || arraysAreEqual(prevColumnIds, currentColumnIds)) {
      return;
    }

    const newColIds = difference(currentColumnIds, prevColumnIds);

    // Get the columns that we do not yet have a size for
    const colsToResize = Object.entries(
      pick(newColIds, getInstance().state.columnResizing.columnWidths),
    ).reduce<string[]>(getKeysGreaterThanX(COLUMN_WIDTH_THRESHOLD), []);

    // If there are no new columns, then we should just exit early
    if (colsToResize.length === 0) {
      return;
    }

    // If there is just a single new column, then we'll autosize only that one
    if (colsToResize.length < 2) {
      filter(
        pipe(prop('id'), x => colsToResize.includes(x)),
        cols,
      ).forEach(x => {
        x.autosize();
      });
      return;
    }

    // For two or more new columns, things can get a bit slow and lock-up-the-browser-y, so we'll
    // just run the optimized autosizeAll.
    autosizeAll(getInstance());
  }, [prevColumnIds, columns, getInstance]);

  // Track changes in selected rows and call onSelectionChange if provided
  const prevSelectedRowIds = usePrevious<string[]>(
    pluck('id', getInstance().selectedFlatRows ?? []),
  );
  React.useEffect(() => {
    const selectedRows = getInstance().selectedFlatRows;
    if (
      onSelectionChange &&
      prevSelectedRowIds &&
      !arraysAreEqual(prevSelectedRowIds, pluck('id', selectedRows))
    ) {
      onSelectionChange(selectedRows);
    }
  }, [prevSelectedRowIds, getInstance, onSelectionChange]);

  // TODO fix for when summary is present
  const onKeyDown = useGridNavigationKeys(pluck('id', visibleColumns), rows, tbodyRef.current);

  const scrollHandler = React.useMemo(
    () =>
      createScrollHandler<D>({
        containerRef: tableContainerRef,
        getInstance,
        getStuck,
        setStuck,
        tableRef,
      }),
    [getStuck, getInstance, tableContainerRef, tableRef, setStuck],
  );

  // TODO: These values should come from props
  const virtualizeThreshold = 34;
  const itemHeight = 44;

  const adjustment = [
    summaryLabel && itemHeight,
    summaryData && itemHeight,
    tableLabel && itemHeight,
  ]
    .filter(complement(isNil))
    .reduce<number>((total, x) => total + (x as number), 0);

  const outsideAdjustment =
    [
      ...Array.from({ length: headerGroups.length }, () => itemHeight),
      ...(footerGroups.length ? [itemHeight] : []),
    ]
      .filter(complement(isNil))
      .reduce<number>((total, x) => total + x, 0) + footerGroups.length;

  const MIN_GRID_SIZE = 300;
  const GLOBAL_HEADER_SIZE = 80;

  const maxInnerHeight = Math.max(itemHeight * rows.length + adjustment, MIN_GRID_SIZE);
  const containerHeight = Math.min(window.innerHeight - GLOBAL_HEADER_SIZE, maxInnerHeight); // do better!

  const shouldVirtualize = forceVirtualization || rows.length > virtualizeThreshold;

  // TODO bug in paging when the rows are not exactly divisible
  const { items } = useVirtualScroll({
    container: shouldVirtualize ? tableRef : null,
    containerHeight,
    itemHeight,
    items: rows,
    virtualRef: tbodyRef,
  });

  // Only make scrollable if virtualized and draggable
  useDragAutoScroll(shouldVirtualize && draggable ? tableRef : null);

  // This actually returns Row<D>, which is ReactTable.Row + plugins + injected props
  const getPreparedRow = (basicRow: RowObject<D>): ReactTableRow<D> => {
    const row = { ...basicRow } as ReactTableRow<D>;
    prepareRow(row);

    // TODO: Add these extra props with a plugin hook (there's not docs), see useHierarchy
    row.hasSubRows = hasSubRows ? hasSubRows(row) : row.canExpand;
    row.subRowsUnloaded = row.hasSubRows && !row.canExpand;
    row.fetchSubRows = async () =>
      onFetchSubRows && row.hasSubRows ? onFetchSubRows(row) : Promise.reject();

    return row;
  };

  // Handle the row events
  type RowEventHandler = Record<string, React.EventHandler<any>>;
  type RowEventWrapper = (
    original: (event: React.SyntheticEvent, row: ReactTableRow<D>) => void,
  ) => (event: React.SyntheticEvent) => void;

  const getRowHandlers = React.useCallback(
    (row: ReactTableRow<D>) => {
      const wrapHandler: RowEventWrapper = original => event => {
        if (event.defaultPrevented) {
          return;
        }
        return original(event, row);
      };
      const reduceHandlers = (acc: RowEventHandler, [key, value]: [string, any]) => {
        const newKey = key.replace(handlerRowName, 'on');
        acc[newKey] = wrapHandler(value);
        return acc;
      };
      const handlers = Object.entries(props)
        .filter(isRowHandler)
        .reduce<RowEventHandler>(reduceHandlers, {});

      return handlers;
    },
    [props],
  );

  // Filter Drawer
  useFilterDrawer({ allColumns, tableInstance: instance });

  const showHeaders = data.length > 0 || typeof summaryData !== 'undefined';
  const showEmpty = data.length < 1;
  const showNoResults = !showEmpty && rows.length < 1;
  const showRows = data.length > 0 && rows.length > 0;

  // TODO add key handler to virtualized grid.
  // TODO fix scroll listener to apply sticky / stuck to this as well.
  // TODO: Don't use DndProvider wrapper if `draggable` is set to false.
  if (shouldVirtualize) {
    return (
      <DataGridContext.Provider value={contextValue}>
        <div ref={ref} className={cx(styles.dataGrid, className)} data-testid={testId}>
          {children}
          <div
            {...getTableProps()}
            ref={tableRef}
            aria-colcount={allColumns.length}
            aria-rowcount={data.length}
            className={cx('tbody', styles.virtualTable)}
            role="grid"
            style={{ height: containerHeight + outsideAdjustment }}
            tabIndex={0}
            onKeyDown={onKeyDown}
          >
            <div style={{ width: instance.totalColumnsWidth }}>
              {showHeaders && (
                <div className={styles.virtualThead} role="rowgroup">
                  {headerGroups.map((group: HeaderGroup<D>) => (
                    <HeaderRow<D> key={group.getHeaderGroupProps().key} group={group} />
                  ))}
                </div>
              )}

              {summaryData && (
                <div
                  {...getTableBodyProps()}
                  className={cx(styles.tableBody, 'tbody')}
                  role="rowgroup"
                >
                  {summaryLabel && (
                    <div className={styles.fullRow} role="row">
                      <div className={styles.tableLabel} role="cell">
                        <Label>{summaryLabel}</Label>
                      </div>
                    </div>
                  )}
                  {summaryData.map((datum: any, index: number) => {
                    const basicRow = accessRow<D>(getInstance(), datum, index);
                    const row = getPreparedRow(basicRow);
                    const rowHandlers = getRowHandlers(row);
                    return (
                      <Row<D>
                        key={row.original?.key ?? row.getRowProps().key}
                        {...rowHandlers}
                        index={index}
                        instance={instance}
                        row={row}
                      />
                    );
                  })}
                </div>
              )}

              {showEmpty && (
                <div>{isFunction(emptyState) ? React.createElement(emptyState) : emptyState}</div>
              )}

              {showNoResults && (
                <div>
                  {isFunction(noResultsState)
                    ? React.createElement(noResultsState)
                    : noResultsState}
                </div>
              )}

              {showRows && (
                <>
                  <div
                    {...getTableBodyProps()}
                    ref={tbodyRef}
                    className={cx(styles.virtualBody, 'tbody')}
                    role="rowgroup"
                    style={{ height: maxInnerHeight }}
                  >
                    {tableLabel && Boolean(rows.length) && (
                      <div className={styles.fullRow} role="row">
                        <div className={styles.tableLabel} role="cell">
                          <Label>{tableLabel}</Label>
                        </div>
                      </div>
                    )}
                    {items.map(({ item, style, absoluteIndex }) => {
                      const row = getPreparedRow(item);
                      const rowHandlers = getRowHandlers(row);
                      return (
                        <Row
                          key={row.original?.key ?? row.getRowProps().key}
                          isWindowed
                          {...rowHandlers}
                          index={absoluteIndex}
                          instance={instance}
                          row={row}
                          style={style}
                        />
                      );
                    })}
                  </div>

                  {showFooter && Boolean(footerGroups.length) && Boolean(rows.length) && (
                    <div className={styles.virtualTfoot} role="rowgroup">
                      {footerGroups.map((group: HeaderGroup<D>) => (
                        <FooterRow<D> key={group.getFooterGroupProps().key} group={group} />
                      ))}
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </DataGridContext.Provider>
    );
  }
  return (
    <DataGridContext.Provider value={contextValue}>
      <div ref={ref} className={cx(styles.dataGrid, className)} data-testid={testId}>
        {children}

        <div
          ref={tableContainerRef}
          data-table-container
          className={styles.tableContainer}
          onScroll={scrollHandler}
        >
          <div
            {...getTableProps()}
            ref={tableRef}
            aria-colcount={allColumns.length}
            aria-rowcount={data.length}
            className="data-grid table"
            role="grid"
            tabIndex={0}
            onKeyDown={onKeyDown}
          >
            {showHeaders && (
              <div className={styles.thead} role="rowgroup">
                {headerGroups.map((group: HeaderGroup<D>) => (
                  <HeaderRow<D> key={group.getHeaderGroupProps().key} group={group} />
                ))}
              </div>
            )}

            {summaryData && (
              <div
                {...getTableBodyProps()}
                className={cx(styles.tableBody, 'tbody')}
                role="rowgroup"
              >
                {summaryLabel && (
                  <div className={styles.fullRow} role="row">
                    <div className={styles.tableLabel} role="cell">
                      <Label>{summaryLabel}</Label>
                    </div>
                  </div>
                )}
                {summaryData.map((datum: any, index: number) => {
                  const basicRow = accessRow<D>(getInstance(), datum, index);
                  const row = getPreparedRow(basicRow); // turns RowObject into ReactTable.Row
                  const rowHandlers = getRowHandlers(row);
                  return (
                    <Row<D>
                      key={row.original?.key ?? row.getRowProps().key}
                      {...rowHandlers}
                      index={index}
                      instance={instance}
                      row={row}
                    />
                  );
                })}
              </div>
            )}

            {showEmpty && (
              <div>{isFunction(emptyState) ? React.createElement(emptyState) : emptyState}</div>
            )}

            {showNoResults && (
              <div>
                {isFunction(noResultsState) ? React.createElement(noResultsState) : noResultsState}
              </div>
            )}

            {showRows && (
              <>
                <div
                  {...getTableBodyProps()}
                  ref={tbodyRef}
                  className={cx(styles.tableBody, 'tbody')}
                  role="rowgroup"
                >
                  {tableLabel && Boolean(rows.length) && (
                    <div className={styles.fullRow} role="row">
                      <div className={styles.tableLabel} role="cell">
                        <Label>{tableLabel}</Label>
                      </div>
                    </div>
                  )}

                  {Boolean(rows.length) &&
                    rows.map(getPreparedRow).map((row, index) => {
                      const rowHandlers = getRowHandlers(row);
                      return (
                        <Row
                          key={row.original?.key ?? row.getRowProps().key}
                          {...rowHandlers}
                          index={index}
                          instance={instance}
                          row={row}
                        />
                      );
                    })}
                </div>

                {showFooter && Boolean(footerGroups.length) && Boolean(rows.length) && (
                  <div className={styles.tfoot} role="rowgroup">
                    {footerGroups.map((group: HeaderGroup<D>) => (
                      <FooterRow<D> key={group.getFooterGroupProps().key} group={group} />
                    ))}
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </DataGridContext.Provider>
  );
};

DataGridInternal.displayName = 'DataGridInternal';

const changeDefaultToInitialState = (
  defaults: Record<string, string[]>,
  initial: Record<string, string[]>,
) => {
  const out: Record<string, string[]> = {};

  if (defaults.defaultGrouped) {
    out.groupBy = [...(initial.groupBy ?? []), ...defaults.defaultGrouped];
  }

  if (defaults.defaultPinned) {
    out.pinnedColumns = [...(initial?.pinnedColumns ?? []), ...defaults.defaultPinned];
  }

  if (defaults.defaultHidden) {
    out.hiddenColumns = [...(initial.hiddenColumns ?? []), ...defaults.defaultHidden];
  }

  return out;
};

const emptyObject = {};
export const DataGrid = <D extends UnknownObject>(props: DataGridProps<D>): JSX.Element => {
  const { children, initialViewSettings = emptyObject, ...rest } = props;

  // Toggle to open/close the grid in full screen mode
  const { active: isFullScreen, toggle: toggleFullScreen } = useToggle(false);

  const [[columns, defaults, otherChildren]] = useSafeState(childrenToColumns(children));

  const initialView = React.useMemo(
    () =>
      Object.entries({
        ...initialViewSettings,
        ...changeDefaultToInitialState(defaults, initialViewSettings),
      })
        .map(([key, values]) => [key, Array.isArray(values) ? Array.from(new Set(values)) : values])
        .reduce<ViewSettings>((acc, [key, values]) => ({ ...acc, [key as string]: values }), []),
    [initialViewSettings, defaults],
  );

  const portal = getPortal();

  return (
    <WrapperFullScreenPortal
      className={styles.gridContainer}
      isOpenInFullScreen={isFullScreen}
      portal={portal}
      toggleFullScreen={toggleFullScreen}
    >
      <DataGridInternal<D>
        {...rest}
        columns={columns}
        initialViewSettings={initialView}
        isFullScreen={isFullScreen}
        toggleFullScreen={toggleFullScreen}
      >
        {otherChildren}
      </DataGridInternal>
    </WrapperFullScreenPortal>
  );
};
DataGrid.displayName = 'DataGrid';

DataGrid.Table = DataGridTable;
DataGrid.Column = Column;
DataGrid.Toolbar = Toolbar;
DataGrid.Loading = Loading;
export default DataGrid;
