// Import the global styles first
import '@cobbler-io/core-ui/src/scss/global.scss';
import React from 'react';

import { ModalManager } from '@cobbler-io/core-ui/src/Modal';

import { LoadBudgetData } from '@cobbler-io/app/src/components/LoadBudgetData';
import { LoadDataSets } from '@cobbler-io/app/src/components/LoadDataSets';
import { OnboardingGate } from '@cobbler-io/app/src/gates/OnboardingGate';
import { CurrentBudgetLineProvider } from '@cobbler-io/app/src/providers/CurrentBudgetLineProvider';

import { useLocation } from '@reach/router';

import { App } from './App';

export const InnerApp = (): JSX.Element => {
  const location = useLocation();

  return (
    <CurrentBudgetLineProvider location={location}>
      <LoadBudgetData />
      <LoadDataSets />
      <ModalManager>
        <OnboardingGate>
          <App />
        </OnboardingGate>
      </ModalManager>
    </CurrentBudgetLineProvider>
  );
};

InnerApp.displayName = 'InnerApp';
