import { useContext } from 'react';

import { createNamedContext } from '@cobbler-io/utils/src/createNamedContext';
import { noop } from '@cobbler-io/utils/src/noop';
import { User, UserManager } from 'oidc-client';

export type AuthContextType = {
  state: {
    manager: UserManager | null;
    user: User | null;
  };
  actions: {
    loadUser: (user: User) => void;
    unloadUser: () => void;
    loadManager: (manager: UserManager) => void;
    handleError: (error: Error) => void;
  };
};

const defaultContextState = {
  state: {
    manager: null,
    user: null,
  },
  actions: {
    loadUser: noop,
    unloadUser: noop,
    loadManager: noop,
    handleError: noop,
  },
};

export const AuthContext = createNamedContext<AuthContextType>('AuthContext', defaultContextState);

export const useAuthToken = (): string | null => {
  return useContext(AuthContext).state.user?.access_token ?? null;
};
