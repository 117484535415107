import { clamp, isNil } from 'ramda';

import { MenuItemProps } from './MenuItem';

type Item = React.ReactElement<MenuItemProps>;

type MenuState = {
  activeIndex: number;
  activeItem: Item | null;
  items: Item[];
};

type FirstActiveIndex = {
  type: 'ActiveIndex/First';
};

type LastActiveIndex = {
  type: 'ActiveIndex/Last';
};

type IncrementActiveIndex = {
  type: 'ActiveIndex/Increment';
};

type DecrementActiveIndex = {
  type: 'ActiveIndex/Decrement';
};

type SetActiveIndex = {
  type: 'ActiveIndex/Set';
  payload: number;
};

type MenuAction =
  | IncrementActiveIndex
  | DecrementActiveIndex
  | SetActiveIndex
  | FirstActiveIndex
  | LastActiveIndex;

type ItemTuple = [Item | null, number];

const findNextItem = (state: MenuState): ItemTuple => {
  for (let i = state.activeIndex + 1; i < state.items.length; i++) {
    const item = state.items[i];
    if (item && !item.props.disabled) {
      return [item, i];
    }
  }

  return [null, -1];
};

const findPrevItem = (state: MenuState): ItemTuple => {
  for (let i = state.activeIndex - 1; i >= 0; i--) {
    const item = state.items[i];
    if (item && !item.props.disabled) {
      return [item, i];
    }
  }
  return [null, -1];
};

const findFirstItem = (state: MenuState): ItemTuple => {
  for (let i = 0; i < state.items.length; i++) {
    const item = state.items[i];

    if (item && !item.props.disabled) {
      return [item, i];
    }
  }
  return [null, -1];
};

const findLastItem = (state: MenuState): ItemTuple => {
  for (let i = state.items.length - 1; i >= 0; i--) {
    const item = state.items[i];

    if (item && !item.props.disabled) {
      return [item, i];
    }
  }
  return [null, -1];
};

export const menuReducer = (state: MenuState, action: MenuAction): MenuState => {
  const setNextItem = (params: ItemTuple): MenuState => {
    const [activeItem, activeIndex] = params;
    if (!isNil(activeItem) && activeIndex >= 0) {
      return { ...state, activeIndex, activeItem };
    }

    return state;
  };

  const updateIndex = (proposedIndex: number): MenuState => {
    const nextIndex = clamp(0, state.items.length - 1, proposedIndex);
    const nextItem = state.items[nextIndex] ?? null;
    return nextIndex === state.activeIndex
      ? state
      : { ...state, activeIndex: nextIndex, activeItem: nextItem };
  };

  switch (action.type) {
    case 'ActiveIndex/Increment':
      return setNextItem(findNextItem(state));

    case 'ActiveIndex/Decrement':
      return setNextItem(findPrevItem(state));

    case 'ActiveIndex/Last':
      return setNextItem(findLastItem(state));

    case 'ActiveIndex/First':
      return setNextItem(findFirstItem(state));

    case 'ActiveIndex/Set':
      return updateIndex(action.payload);

    default:
      return state;
  }
};
