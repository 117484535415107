import { createRelayId } from '@cobbler-io/utils/src/createRelayId';
import { hash } from '@cobbler-io/utils/src/string/hash';

import { getFiscalYearId, getQuarterId } from '@cobbler-io/dates/src/getPeriodsInRange';
import { tzAdjust } from '@cobbler-io/dates/src/tzAdjust';

export const createBudgetLineStubId = createRelayId('BudgetLineStub');
export const createRevisionStubId = createRelayId('BudgetRevisionType2');

const getEdgeId =
  (label: string): BinaryFn<string, string, string> =>
  (from: string, to: string): string =>
    [label, hash([from, to].join('-'))].join('-');

export const permissionsId = (userId: UserId, resourceId: string): string => {
  return ['HAS_PERMISSION_TO', hash([userId, resourceId].join('-'))].join('-');
};

export const hasAccountId = (accountId: AccountId, resourceId: string): string => {
  return ['HAS_ACCOUNT', hash([resourceId, accountId].join('-'))].join('-');
};

export const hasDepartmentId = (departmentId: DepartmentId, resourceId: string): string => {
  return ['HAS_DEPARTMENT', hash([resourceId, departmentId].join('-'))].join('-');
};

export const hasVendorId = (departmentId: VendorId, resourceId: string): string => {
  return ['HAS_VENDOR', hash([resourceId, departmentId].join('-'))].join('-');
};

export const hasChildId = (parentId: BudgetLineId, childId: BudgetLineId): string => {
  return ['HAS_CHILD', hash([parentId, childId].join('-'))].join('-');
};

export const hasPlanningVersionId = (planningVersionId: string, resourceId: string): string => {
  return ['HAS_PLANNING_VERSION', hash([resourceId, planningVersionId].join('-'))].join('-');
};

export const getAccountChildId = (parentId: string, childId: string): string => {
  const x = hash([parentId, childId].join('-'));
  return ['HAS_CHILD_ACCOUNT', x].join('-');
};

export const getDepartmentChildId: (parentId: string, childId: string) => string =
  getEdgeId('HAS_SUB_DEPARTMENT');

export const getBudgetLineChildEdgeId: (parentId: string, childId: string) => string =
  getEdgeId('BUDGET_LINE_HAS_CHILD');

export const getBudgetLineOwnerEdgeId: (userId: string, budgetLineId: string) => string =
  getEdgeId('OWNS');
export const getUserPermissionsEdgeId: (userId: string, resourceId: string) => string =
  getEdgeId('HAS_PERMISSION_TO');

export const getPlanningVersionLineEdgeId: (planningVersionId: string, lineId: string) => string =
  getEdgeId('HAS_PERMISSION_TO');

export const getSpentInMonthEdgeId = (spendId: string): string =>
  ['SPENT_IN_MONTH', hash(spendId)].join('-');

export const getSpentForLineEdgeId = (spendId: string): string =>
  ['SPENT_FOR_LINE', hash(spendId)].join('-');

export const getPlannedInMonthEdgeId = (plannedSpendId: string): string =>
  ['PLANNED_IN_MONTH', hash(plannedSpendId)].join('-');

export const getPlannedForLineEdgeId = (plannedSpendId: string): string =>
  ['PLANNED_FOR_LINE', hash(plannedSpendId)].join('-');

export const getPlannedSpendForRevisionId = (plannedSpendId: string): string =>
  ['PLANNED_FOR_REVISION', hash(plannedSpendId)].join('-');

export const getProposedChangeForPlannedSpendId = (
  changeId: string,
  plannedSpendId: string,
): string => ['PROPOSED_CHANGE_ON', hash(changeId), hash(plannedSpendId)].join('-');

const qMap = new Map<string, string>();

export const getQuarterIdFromISO8601 = (x0: ISO8601String, startMonth: JSMonth): string => {
  if (qMap.has(x0)) {
    return qMap.get(x0)!;
  }

  const id = getQuarterId(tzAdjust(x0), startMonth);
  qMap.set(x0, id);

  return id;
};

const yMap = new Map<string, string>();

export const getFiscalYearIdFromISO8601 = (x0: ISO8601String, startMonth: JSMonth): string => {
  if (yMap.has(x0)) {
    return yMap.get(x0)!;
  }

  const id = getFiscalYearId(tzAdjust(x0), startMonth);
  yMap.set(x0, id);

  return id;
};
