import { Predicate } from './types';

export function* skipWhile<T>(predicate: Predicate<T>, iterable: Iterable<T>): Iterable<T> {
  let found = false;

  for (const iteration of iterable) {
    found ||= !predicate(iteration);

    if (found) {
      yield iteration;
    }
  }
}
