import * as React from 'react';

type TabPanelProps = {
  fallback?: React.ReactNode;
  children: React.ReactNode;
  id: string;
};

export const TabRouterPanel = (props: TabPanelProps) => {
  const { children, fallback, id } = props;
  return (
    <section id={`panel-${id}`} role="tabpanel">
      {children || fallback}
    </section>
  );
};

TabRouterPanel.displayName = 'TabRouterPanel';
