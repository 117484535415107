import { RangeContextType } from './CurrentRangeContext';
import { CurrentRangeProviderProps } from './CurrentRangeProvider';

export const subRangePropsFromRange = (
  context: RangeContextType,
  overrides: Partial<Omit<CurrentRangeProviderProps, 'children'>> = {},
): Omit<CurrentRangeProviderProps, 'children'> => {
  const { getState } = context;
  const state = getState();

  return {
    afterware: overrides.afterware,
    cacheIdentifier: overrides.cacheIdentifier,
    defaultEnd: overrides.defaultEnd ?? state.end,
    defaultMax: overrides.defaultMax ?? state.max,
    defaultMin: overrides.defaultMin ?? state.min,
    defaultPeriodLength: overrides.defaultPeriodLength ?? state.periodLength,
    defaultStart: overrides.defaultStart ?? state.start,
    max: overrides.max,
    middleware: overrides.middleware,
    min: overrides.min,
  };
};
