import * as React from 'react';

import { HeaderGroup } from 'react-table';

import { FooterCell } from '../FooterCell';

type FooterRowProps<T extends Record<string, unknown> = Record<string, unknown>> = {
  group: HeaderGroup<T>;
};

export const FooterRow = <T extends Record<string, unknown> = Record<string, unknown>>({ group }: FooterRowProps<T>) => (
  <div {...group.getHeaderGroupProps()} {...group.getFooterGroupProps()}>
    {group.headers.map(column => (
      <FooterCell key={column.getFooterProps().key} column={column} />
    ))}
  </div>
);
FooterRow.displayName = 'DataGrid__FooterRow';
