import * as React from 'react';

import { noop } from '@cobbler-io/utils/src';

import { Button } from '../../Button';
import { Icon } from '../../Icon';
import { useCurrentDataGrid } from '../DataGridContext';

import styles from './ToggleExpandAllButton.scss';

export const ToggleExpandAllButton = (): JSX.Element => {
  const { toggleAllRowsExpanded = noop, isAllRowsExpanded = false } =
    useCurrentDataGrid().getInstance() ?? {};
  const label = isAllRowsExpanded ? 'Collapse All' : 'Expand All';
  const icon = isAllRowsExpanded ? 'unfoldLess' : 'panY';
  const toggleExpanded = React.useCallback(() => {
    toggleAllRowsExpanded(!isAllRowsExpanded);
  }, [isAllRowsExpanded, toggleAllRowsExpanded]);

  return (
    <Button
      small
      className={styles.iconButtonToolbar}
      name="toggle-expand-all"
      variant="text"
      onClick={toggleExpanded}
    >
      <Icon size={14} type={icon} /> {label}
    </Button>
  );
};
ToggleExpandAllButton.displayName = 'ToggleExpandAllButton';
