import * as React from 'react';

import { Afterware, Middleware, useExtendedReducer } from './useExtendedReducer';
import { useLocalCache } from './useLocalCache';

export type UseCachedReducerParams<
  State extends Record<string, unknown>,
  Action,
  Serialized extends Record<string, unknown> = State,
> = {
  reducer: React.Reducer<State, Action>;
  /**
   * The initial state. Note: this does not override a cache but will be there if the cache is empty
   */
  initialState: React.ReducerState<React.Reducer<State, Action>>;
  /**
   * The name will be used as a cache identifier in localStorage
   */
  name?: string;
  /**
   * A function to serialize to localStorage
   *
   * Defaults to `identity`
   */
  serialize: (x: State) => Serialized;
  /**
   * A deserialization function from localStorage
   *
   * Defaults to `identity`
   */
  deserialize: (x: Serialized) => State;

  /**
   * Whether or not to log the changes when in dev mode
   */
  log?: boolean;

  /**
   * Middleware to include
   */
  middleware?: Middleware<State, Action> | Middleware<State, Action>[];

  /**
   * More afteware to run
   */
  afterware?: Afterware<State, Action> | Afterware<State, Action>[];
};

export type { Afterware, Middleware };

export type CachedReducer<State extends Record<string, unknown>, Action> = {
  getState: () => State;
  dispatch: React.Dispatch<Action>;
};

const loggingAfterware: Afterware<any, any> = (action, prev, current) => {
  // eslint-disable-next-line
  console.info(action, prev, current);
};

const fnReturningEmptyObject = <Serialized>() => ({} as unknown as Serialized);

export const useCachedReducer = <
  State extends Record<string, unknown>,
  Action,
  Serialized extends Record<string, unknown> = State,
>(
  params: UseCachedReducerParams<State, Action, Serialized>,
): CachedReducer<State, Action> => {
  const {
    reducer,
    initialState,
    serialize,
    deserialize,
    name = 'NOCACHE',
    middleware,
    afterware: userAfterware,
    log = false,
  } = params;
  const cache = useLocalCache<State, Serialized>({
    name,
    serialize: name === 'NOCACHE' ? fnReturningEmptyObject : serialize,
    deserialize: name === 'NOCACHE' ? fnReturningEmptyObject : deserialize,
    default: initialState,
  });

  const afterware = React.useMemo(() => {
    const includeLogging = log && __DEV__;
    const cacheAfterWare: Afterware<State, Action> = (_, __, x) =>
      name !== 'NOCACHE' && cache.set(() => x);
    const ourAfterware = includeLogging ? [cacheAfterWare, loggingAfterware] : [cacheAfterWare];
    return userAfterware ? ourAfterware.concat(userAfterware) : ourAfterware;
  }, [log, cache, userAfterware, name]);

  return useExtendedReducer<State, Action>({
    reducer,
    initialState: name === 'NOCACHE' ? initialState : cache.get(),
    middleware,
    afterware,
  });
};
