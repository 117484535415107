import React from 'react';
import * as ReactDOM from 'react-dom';

import { isHTMLElement } from '@cobbler-io/utils/src/isHTMLElement';

import { Portal } from '@cobbler-io/core-ui/src/Portal';

/**
 * This could be a de-opt.
 *
 * We should always render into the #portal element at minimum...
 *
 * @see https://atfzl.com/don-t-attach-tooltips-to-document-body
 */
export const renderIntoPortal = (
  parentElement: HTMLElement | null,
  el: React.ReactElement,
): JSX.Element => {
  return isHTMLElement(parentElement) ? (
    ReactDOM.createPortal(el, parentElement)
  ) : (
    <Portal mountWithChildren={false}>{el}</Portal>
  );
};
