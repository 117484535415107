/* eslint-disable func-style */
import { isFunction } from '@cobbler-io/utils/src/isFunction';

import { isNil } from 'ramda';

import { CreateModal, ModalOptions, ModalTreeNode, ModalType } from './types';
import { useCurrentModal } from './useCurrentModal';
import { useModal } from './useModal';

/**
 * `useChildModal` safely returns a `createModal` function that prefers to create a child but will
 * create a root modal if it is called outside a CurrentModal context.
 */
export function useChildModal(): CreateModal;
export function useChildModal(modal: ModalType, options?: ModalOptions): () => ModalTreeNode;
export function useChildModal(
  modal?: ModalType,
  options?: ModalOptions,
): CreateModal | (() => ModalTreeNode) {
  const rootModal = useModal();
  const currentModal = useCurrentModal();

  const create =
    currentModal && isFunction(currentModal.createChild)
      ? currentModal.createChild
      : rootModal.create;

  if (isNil(modal)) {
    return create;
  }

  return () => create(modal, options);
}
