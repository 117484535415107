import * as React from 'react';

import { Button } from '@cobbler-io/core-ui/src/Button';
import { Card } from '@cobbler-io/core-ui/src/Card';
import { Field } from '@cobbler-io/core-ui/src/Field';
import { Heading } from '@cobbler-io/core-ui/src/Heading';
import { Logo } from '@cobbler-io/core-ui/src/Logo';

import { useCurrentUser } from '@cobbler-io/redux/src/modules/current-user';

import { UPDATE_SELF } from '@cobbler-io/app/src/gql-queries';
import { CurrentUserContext } from '@cobbler-io/app/src/providers/CurrentUserProvider';

import { useMutation } from '@apollo/react-hooks';
import { Form } from '@swan-form/form';
import { required } from '@swan-form/helpers';

import styles from './UsernameGate.scss';

export const UsernameGate = () => {
  const { refetch } = React.useContext(CurrentUserContext);
  const [updateSelf] = useMutation(UPDATE_SELF);
  const user = useCurrentUser();
  const onSubmit = React.useCallback(
    async values =>
      updateSelf({
        variables: {
          // eslint-disable-next-line prefer-object-spread
          input: Object.assign(
            {},
            {
              userId: user.id,
              fullName: { newValue: values.fullName },
            },
            values.preferredName && { preferredName: { newValue: values.preferredName } },
          ),
        },
      }),
    [updateSelf, user.id],
  );

  return (
    <div className={styles.container}>
      <Card>
        <div className={styles.logoContainer}>
          <Logo className={styles.logo} />
        </div>
        <div className={styles.textContainer}>
          <Heading as="h1" className={styles.headline} size="headline">
            What should we call you?
          </Heading>
        </div>

        <Form afterSubmit={refetch} name="provide username" onSubmit={onSubmit}>
          <div className="grid">
            <div className="row">
              <div className="col small-12">
                <Field
                  validateOnChange
                  defaultValue={user.fullName || ''}
                  label="Full Name"
                  name="fullName"
                  placeholder="John Doe"
                  type="text"
                  validate={required}
                />
              </div>
            </div>
            <div className="row">
              <div className="col small-12">
                <Field
                  defaultValue={user.preferredName || ''}
                  label="Preferred Name (optional)"
                  name="preferredName"
                  placeholder="Jack"
                  type="text"
                />
              </div>
            </div>
            <div className="row col">
              <Button name="submit name preferences" type="submit">
                Submit
              </Button>
            </div>
          </div>
        </Form>
      </Card>
    </div>
  );
};

UsernameGate.displayName = 'UsernameGate';

export default UsernameGate;
