/* eslint-disable functional/immutable-data, functional/no-try-statement */
export type XLSXModule = typeof import('xlsx');
export type WorkBook = import('xlsx').WorkBook;
export type ParsingOptions = import('xlsx').ParsingOptions;

export type Sheet = {
  header: string[];
  records: unknown[];
};

let xlsx: XLSXModule | null = null; // eslint-disable-line

export const loadXLSX = async (): Promise<XLSXModule> => {
  if (xlsx === null) {
    xlsx = await import('xlsx'); // eslint-disable-line
  }
  return xlsx;
};

export const getSheetByName =
  (sheetName: string) =>
  async (workbook: WorkBook): Promise<Sheet> => {
    const XLSX = await loadXLSX();
    const data = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], { header: 1 });
    const [header, ...records] = data;
    if (!Array.isArray(header)) {
      throw new Error(`No data available in the specified sheet: "${sheetName}"`);
    }
    return { header: header.map(h => String(h)), records };
  };

export const getSheetByIndex =
  (index: number) =>
  async (workbook: WorkBook): Promise<Sheet> => {
    const sheetName = workbook.SheetNames[index];
    return getSheetByName(sheetName)(workbook);
  };

export const getWorkBookFromFile = async (
  file: File,
  parsingOptions: ParsingOptions = {},
): Promise<WorkBook> => {
  const XLSX = await loadXLSX();
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = e => {
      const data = e?.target?.result;
      if (!data) {
        reject('Unable to read the provided file it might be corrupted or password protected.');
      }
      try {
        const workBook = XLSX.read(data, {
          FS: ',', // field separator, default to comma
          type: 'array', // default to array of 8-bit unsigned int
          ...parsingOptions,
        });
        resolve(workBook);
      } catch (err: unknown) {
        console.error(err);
        reject(
          'Unable to process the provided file. Please provide a file in csv, xls or xlsx format.',
        );
      }
    };
    reader.readAsArrayBuffer(file);
  });
};
