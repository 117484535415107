import * as React from 'react';

import { isGuid } from '@cobbler-io/utils/src/isGuid';
import { isRelayId } from '@cobbler-io/utils/src/isRelayId';

import { ComboBox, ComboBoxProps, FallbackFunction, Item } from '@cobbler-io/core-ui/src/Dropdown';

import { GetAllVendorsQuery, useGetAllVendorsQuery } from '@cobbler-io/app/src/api/graphql-types';

export type VendorPickerProps = Record<string, unknown> & Omit<ComboBoxProps<VendorId>, 'multiple' | 'items'>;

export type AccountItem = {
  id: VendorId;
  label: string;
  value: VendorId;
};

type APIVendor = NonNullable<
  NonNullable<NonNullable<GetAllVendorsQuery['a_vendors']>['items']>[number]
>;

const transformVendorItem = (item: APIVendor): Item<VendorId> => ({
  id: item.id,
  label: item.name,
  value: item.id,
});

const addFallback: FallbackFunction<string> = (input, items) => {
  // Don't add any Guid as a fallback
  if (isGuid(input)) {
    return null;
  }

  // Avoid adding a fallback if it is a Relay Id
  if (isRelayId(input)) {
    return null;
  }

  // Ensure that the fallback is not in the items array already
  if (items.some(({ value }) => value === input)) {
    return null;
  }

  return {
    id: input,
    label: input,
    value: input,
  };
};

const emptyArray: VendorId[] = [];

export const VendorPicker = React.forwardRef(
  (props: VendorPickerProps, ref: React.RefObject<HTMLDivElement>): JSX.Element => {
    const { id, name = 'vendor-picker', variant = 'inlineChip', defaultValue = emptyArray } = props;
    const { data, loading, error } = useGetAllVendorsQuery({ fetchPolicy: 'cache-first' });

    const items: Item<VendorId>[] = React.useMemo(
      () => data?.a_vendors?.items?.map(transformVendorItem) ?? [],
      [data],
    );

    if (error) {
      return <>Error</>;
    }

    if (loading && !data) {
      return <ComboBox.Loading multiple id={id} selected={defaultValue} variant="inlineChip" />;
    }

    return (
      <ComboBox
        clearButton
        multiple
        defaultValue={defaultValue}
        limitSuggestions={5}
        {...props}
        ref={ref}
        addFallback={addFallback}
        id={id}
        items={items}
        name={name}
        variant={variant}
      />
    );
  },
);
