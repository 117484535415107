export const isGeneratorFunction = <T>(x: unknown): x is () => Generator<T> => {
  if (typeof x !== 'function') {
    return false;
  }

  if (!x.constructor) {
    return false;
  }

  return x.constructor.name === 'GeneratorFunction';
};
