import { pluck } from 'ramda';

import { arraysAreEqual } from '@cobbler-io/utils/src/array/arraysAreEqual';
import { isString } from '@cobbler-io/utils/src/isString';

// eslint-disable-next-line @typescript-eslint/no-magic-numbers
const GUID_SIZES = [8, 4, 4, 4, 12];
// e.g. 889f268e-4c9d-4d59-af9a-15cbe7293980
export const isGuid = (x?: string): boolean => {
  if (!isString(x)) {
    return false;
  }

  const parts = x.split('-');

  return parts.length === GUID_SIZES.length && arraysAreEqual(pluck('length', parts), GUID_SIZES);
};

export default isGuid;
