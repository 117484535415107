import { useCurrencyFormatter } from '@cobbler-io/redux/src/modules/currency';

import { CellProps } from 'react-table';

/**
 * Displays numbers so that when
 */
export const DisplayFinancial = ({ cell: { value } }: CellProps<any>): string => {
  const { formatFinancial } = useCurrencyFormatter();
  return typeof value === 'number' ? formatFinancial(value) : '';
};
