import { useMemo } from 'react';

import { getEndOfDay } from '@cobbler-io/dates/src/getEndOfDay';
import { tzAdjust } from '@cobbler-io/dates/src/tzAdjust';

import { getGraphColor, getGraphColorFn, graphColors } from '@cobbler-io/core-ui/src/utils';

import { useDispatch, useSelector } from 'react-redux';
import { ActionCreator, Reducer } from 'redux';

/**
 * Note: this diverges from the backend in that we're keeping
 * the currency and locale information in a different reducer
 */
type TenantSettingsState = {
  accountingCycleClosed: ISO8601String | null;
  fiscalYearStartMonth: number;
  id: string | null;
  overplanningThreshold: MinorCurrency;
  chartColors: readonly string[];
  getChartColor: UnaryFn<number, string>;
  copilotDefaultQuestions: string[];
};

type PartialSettings = Partial<TenantSettingsState>;

const initialState: TenantSettingsState = {
  accountingCycleClosed: null,
  chartColors: graphColors,
  fiscalYearStartMonth: 1,
  getChartColor: getGraphColor,
  id: null,
  overplanningThreshold: 0,
  copilotDefaultQuestions: [
    'How did we do against the budget last month?',
    'How much did we spend on T&E last month?',
    'Show the most recent BVA for Marketing',
    'What were my top 10 vendors last month?',
    'How has my software spending trended over the last 6 months?',
  ],
};

const UPDATE_SETTINGS = 'TENANT_SETTINGS/UPDATE_SETTINGS';
type UpdateSettings = { type: typeof UPDATE_SETTINGS; payload: Partial<PartialSettings> };

export const updateTenantSettings: ActionCreator<UpdateSettings> = (
  settings: Partial<PartialSettings>,
) => ({
  payload: settings,
  type: UPDATE_SETTINGS,
});

type Actions = UpdateSettings;

export const reducer: Reducer<TenantSettingsState, Actions> = (
  state = initialState,
  action,
): TenantSettingsState => {
  switch (action.type) {
    case UPDATE_SETTINGS: {
      if (action.payload.chartColors) {
        const getChartColor = getGraphColorFn(action.payload.chartColors as string[]);
        return { ...state, ...action.payload, getChartColor };
      }
      return { ...state, ...action.payload };
    }
    default:
      return state;
  }
};

export const actions = { updateTenantSettings };

export const fiscalYearSelector = (state: { tenantSettings: TenantSettingsState }): ServerMonth =>
  state.tenantSettings.fiscalYearStartMonth;

export const overplanningThresholdSelector = (state: {
  tenantSettings: TenantSettingsState;
}): MinorCurrency => state.tenantSettings.overplanningThreshold;

export const useOverplanningThreshold = (): MinorCurrency =>
  useSelector(overplanningThresholdSelector);

export const chartColorsSelector = (state: {
  tenantSettings: TenantSettingsState;
}): readonly string[] => state.tenantSettings.chartColors;

export const useChartColors = (): readonly string[] => useSelector(chartColorsSelector);

export const getChartColorSelector = (state: {
  tenantSettings: TenantSettingsState;
}): UnaryFn<number, string> => state.tenantSettings.getChartColor;

export const useGetChartColor = (): UnaryFn<number, string> => useSelector(getChartColorSelector);

const accountingCycleSelector = (state: { tenantSettings: TenantSettingsState }): ISO8601String =>
  state.tenantSettings.accountingCycleClosed!;

type UseAccountingCycledClosed = {
  date: ISO8601String | null;
  isDateClosed: UnaryFn<LocalDate | ISO8601String, boolean>;
};

export const useAccountingCycledClosed = (): UseAccountingCycledClosed => {
  const date = useSelector(accountingCycleSelector);

  return useMemo(() => {
    const closedThrough = date ? getEndOfDay(tzAdjust(date)) : null;
    const isDateClosed = (x: LocalDate | ISO8601String): boolean => {
      if (!closedThrough) {
        return false;
      }

      const current = x && x instanceof Date ? x : tzAdjust(x);
      return current.valueOf() <= closedThrough.valueOf();
    };

    return { date, isDateClosed };
  }, [date]);
};

export const tenantSettingsSelector = (state: {
  tenantSettings: TenantSettingsState;
}): TenantSettingsState => state.tenantSettings;

export const useTenantFiscalYearStart = (): ServerMonth => useSelector(fiscalYearSelector);

export const useFiscalYearStart = (): JSMonth => useSelector(fiscalYearSelector) - 1;

const defaultCopilotQuestionsSelector = (state: {
  tenantSettings: TenantSettingsState;
}): string[] => state.tenantSettings.copilotDefaultQuestions;

export const useDefaultCopilotQuestions = (): string[] =>
  useSelector(defaultCopilotQuestionsSelector);

export const useTenantSettings = (): [
  TenantSettingsState,
  (settings: Partial<PartialSettings>) => void,
] => {
  const state = useSelector(tenantSettingsSelector);
  const dispatch = useDispatch();
  const setState = (settings: Partial<PartialSettings>) =>
    dispatch(actions.updateTenantSettings(settings));

  return [state, setState];
};
