/**
 * Gets the first item in an array
 */
export const head = <T extends any[] | readonly any[]>(
  arr: T,
): T extends [infer R, ...any[]]
  ? R
  : T extends readonly [infer R, ...any[]]
  ? R
  : T extends (infer U)[]
  ? U
  : T extends readonly (infer U)[]
  ? U
  : never => arr[0];
