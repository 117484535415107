/* eslint-disable react/button-has-type */
import * as React from 'react';

import { cx } from '@cobbler-io/utils/src/cx';
import { isReactElementOfType } from '@cobbler-io/utils/src/isReactElementOfType';

import invariant from 'tiny-invariant';

import styles from './Button.scss';

export type Props = {
  /**
   * The name of the button
   */
  name: string;
  /**
   * default `button`
   */
  type?: 'button' | 'submit' | 'reset';
  /**
   * Controls the set of base styles that are applied to the button
   */
  variant?: 'text' | 'outline' | 'contain' | 'toggle' | 'svg' | 'filled-tonal';
  /**
   * Whether or not the button is disabled
   */
  disabled?: boolean;
  /**
   * Makes a button full-width
   */
  full?: boolean;
  /**
   * Displays the button as `inline-flex` instead of `inline`
   */
  inline?: boolean;
  /**
   * Removes the margin and padding from a button
   */
  small?: boolean;
  /**
   * Evenly spaces the content of the button
   */
  spaced?: boolean;
  /**
   * Dims the button
   *
   * Only works with `variant='text'`
   */
  dim?: boolean;
  /**
   * Whether or not a toggle button is active
   *
   * Only works with `variant='toggle'`
   */
  active?: boolean;

  children: React.ReactNode;
} & React.HTMLProps<HTMLButtonElement>;

export type ButtonProps = Props;

export const Button = React.forwardRef<HTMLButtonElement, Props>(
  ({ className, dim, full, spaced, active, small, variant = 'contain', inline, ...props }, ref) => {
    const { type = 'button', onClick } = props;

    invariant(
      !(type === 'button' && !onClick),
      'You must supply an `onClick` prop to the button component',
    );

    if (variant === 'svg') {
      return (
        <button
          type="button"
          {...props}
          ref={ref}
          className={cx(
            styles.button,
            styles.svgButton,
            small && styles.small,
            inline && styles.inline,
            className,
          )}
        />
      );
    }

    return (
      <button
        {...props}
        ref={ref}
        className={cx(
          styles.button,
          variant === 'text' && styles.text,
          variant === 'outline' && styles.outline,
          variant === 'contain' && styles.contain,
          variant === 'filled-tonal' && styles.filledTonal,
          variant === 'toggle' && styles.toggle,
          variant === 'toggle' && active && styles.active,
          full && styles.full,
          small && styles.small,
          spaced && styles.spaced,
          dim && styles.dim,
          inline && styles.inline,
          className,
        )}
      />
    );
  },
);

Button.displayName = 'Button';

type ButtonElement = {
  type: (props: ButtonProps) => React.ReactElement<ButtonProps> | null;
  props: ButtonProps;
  key: React.Key | null;
};

// @ts-expect-error: the type is correct, but the React types mangle it a bit
export const isButton = isReactElementOfType<ButtonElement>(Button);

export default Button;
