import * as React from 'react';

import { cx } from '@cobbler-io/utils/src/cx';
import { maybeProps } from '@cobbler-io/utils/src/maybeProps';

import { Link } from '@reach/router';

import { Icon, IconTypes } from '../Icon';

import styles from './VerticalNavItem.scss';

type VerticalNavItemProps = {
  id: string;
  active: boolean;
  label: string;
  showBadge?: boolean;
  uri: string;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
  icon: IconTypes | React.ReactElement;
  className?: string;
};

export const VerticalNavItem = (props: VerticalNavItemProps): JSX.Element => {
  const { id, active, className, label, showBadge, uri, onClick, icon } = props;

  return (
    <div
      key={label}
      className={cx(styles.item, active && styles.active, showBadge && styles.withBadge, className)}
    >
      <Link {...maybeProps({ onClick })} className={styles.link} id={id} to={uri}>
        <div className={styles.icon}>{typeof icon === 'string' ? <Icon type={icon} /> : icon}</div>
        <div className={styles.label}>{label}</div>
      </Link>
    </div>
  );
};

VerticalNavItem.displayName = 'VerticalNavItem';
