import * as React from 'react';

import { cx } from '@cobbler-io/utils/src/cx';

import { Button } from '@cobbler-io/core-ui/src/Button';
import { Icon } from '@cobbler-io/core-ui/src/Icon';
import { useCurrentModal } from '@cobbler-io/core-ui/src/Modal';

import { animated, useSpring } from '@react-spring/web';

import { Control } from './Control';

import styles from './ControlBar.scss';

type ControlBarProps = {
  bounds: any;
  imageTransform: any;
  setState: (prev: any) => any;
  onConfirm: () => any;
};

type CropControlsProps = Omit<ControlBarProps, 'onConfirm'>;

type ControlBarOptions = 'crop' | 'adjustments';

const CropControls = (props: CropControlsProps) => {
  const { bounds, imageTransform, setState } = props;
  const handler = (prop: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event?.currentTarget?.value;
    setState((prev: Record<string, string>) => ({
      ...prev,
      [prop]: parseInt(value ?? `${prev[prop]}`),
    }));
  };

  return (
    <div className={styles.singleControlBar}>
      <Control
        icon="zoomIn"
        max={bounds.maxZoom}
        min={bounds.minZoom}
        name="Zoom"
        value={imageTransform.scale}
        onChange={handler('scale')}
      />
      <Control
        icon="panX"
        max={bounds.maxPanX}
        min={bounds.minPanX}
        name="Horizontal"
        value={imageTransform.translateX}
        onChange={handler('translateX')}
      />
      <Control
        icon="panY"
        max={bounds.maxPanY}
        min={bounds.minPanY}
        name="Vertical"
        value={imageTransform.translateY}
        onChange={handler('translateY')}
      />
    </div>
  );
};

CropControls.displayName = 'CropControls';

const AdjustmentControls = (props: CropControlsProps) => {
  const { bounds, imageTransform, setState } = props;
  const handler = (prop: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event?.currentTarget?.value;
    setState((prev: Record<string, string>) => ({
      ...prev,
      [prop]: parseInt(value ?? `${prev[prop]}`),
    }));
  };

  return (
    <div className={styles.singleControlBar}>
      <Control
        icon="brightness"
        max={bounds.maxBrightness ?? 200}
        min={bounds.minBrightness ?? 0}
        name="Brightness"
        value={imageTransform.brightness}
        onChange={handler('brightness')}
      />
      <Control
        icon="tonality"
        max={bounds.maxContrast ?? 200}
        min={bounds.minContrast ?? 0}
        name="Contrast"
        value={imageTransform.contrast}
        onChange={handler('contrast')}
      />
      <Control
        icon="eyeDropper"
        max={bounds.maxSaturation ?? 200}
        min={bounds.minSaturation ?? 0}
        name="Saturation"
        value={imageTransform.saturate}
        onChange={handler('saturate')}
      />
    </div>
  );
};

AdjustmentControls.displayName = 'AdjustmentControls';

export const ControlBar = (props: ControlBarProps): JSX.Element => {
  const { bounds, imageTransform, setState, onConfirm = () => {} } = props;
  const [selected, setSelected] = React.useState<ControlBarOptions>('crop');
  const { close } = useCurrentModal();
  const indicatorStyle = useSpring({ transform: `translateX(${selected === 'crop' ? 0 : 100}%)` });
  const controlStyle = useSpring({ transform: `translateX(${selected === 'crop' ? 0 : -100}%)` });

  return (
    <div className={styles.container}>
      <div className={styles.controlBarWrapper}>
        <animated.div className={styles.controlBarContainer} style={controlStyle}>
          <CropControls bounds={bounds} imageTransform={imageTransform} setState={setState} />
          <AdjustmentControls bounds={bounds} imageTransform={imageTransform} setState={setState} />
        </animated.div>
      </div>
      <div className={styles.metaControlBar}>
        <button
          className={cx(selected === 'crop' && styles.selected)}
          type="button"
          onClick={() => setSelected('crop')}
        >
          <Icon type="crop" />
          <span className={styles.metaLabel}>Crop</span>
        </button>
        <button
          className={cx(selected === 'adjustments' && styles.selected)}
          type="button"
          onClick={() => setSelected('adjustments')}
        >
          <Icon type="tune" />
          <span className={styles.metaLabel}>Adjustments</span>
        </button>
        <animated.div className={styles.metaIndicator} style={indicatorStyle} />
      </div>
      <div className={cx('button-container right', styles.buttons)}>
        <Button name="Cancel" variant="text" onClick={() => close()}>
          Cancel
        </Button>
        <Button
          name="Confirm"
          onClick={() => {
            close();
            onConfirm();
          }}
        >
          Confirm
        </Button>
      </div>
    </div>
  );
};

ControlBar.displayName = 'ControlBar';
