import * as React from 'react';

import { clamp } from '@cobbler-io/utils/src/numbers/clamp';

import { Button } from '@cobbler-io/core-ui/src/Button';
import { Icon } from '@cobbler-io/core-ui/src/Icon';

import { WebcamContext } from './WebcamContext';

type AcceptButtonProps = {
  className?: string;
  style?: React.CSSProperties;
};

export const AcceptButton = (props: AcceptButtonProps): JSX.Element | null => {
  const { refs, state } = React.useContext(WebcamContext);

  const acceptPicture = () => {
    refs.canvas.current?.toBlob(
      data => data && state.onAcceptPicture(data),
      state.mimeType,
      clamp({ min: 0.5, max: 1 }, state.quality ?? 1),
    );
  };

  if (!state.capture) {
    return null;
  }

  return (
    <Button {...props} name="accept" variant="svg" onClick={acceptPicture}>
      <Icon size={20} type="check" />
    </Button>
  );
};

AcceptButton.displayName = 'AcceptButton';
