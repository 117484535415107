import * as React from 'react';

import { cx } from '@cobbler-io/utils/src/cx';

import { Logo } from '@cobbler-io/core-ui/src/Logo';

import styles from './MinimalChrome.scss';

export type MinimalChromeProps = {
  // Add props here
  children: React.ReactNode;
  className?: string;
};

export const MinimalChrome: React.FC<MinimalChromeProps> = ({ children, className }) => {
  return (
    <div className={cx(styles.minimalChrome, className)}>
      <header className={styles.header}>
        <Logo />
      </header>
      <div className={cx(styles.body)}>{children}</div>
    </div>
  );
};

MinimalChrome.displayName = 'MinimalChrome';

export default MinimalChrome;
