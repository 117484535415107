import * as Sentry from '@sentry/react';
import {
  applyMiddleware, combineReducers, compose, createStore, Middleware, Reducer, Store,
} from 'redux';

import { thunk } from './middleware';
import { actions, reducer as rootReducer } from './modules';

const middleware: Middleware[] = [thunk];

declare global {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: (fn: Record<string, unknown>) => typeof compose;
  }
}

export const defaultRootReducer = combineReducers(rootReducer);

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  // Optionally pass options listed below
});

/* eslint-disable no-underscore-dangle */
const composeEnhancers =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
      })
    : compose;
/* eslint-enable no-underscore-dangle */

const enhancer = composeEnhancers(
  applyMiddleware(...middleware),
  // other store enhancers if any
  sentryReduxEnhancer,
);

export type Reducers = {
  [name: string]: Reducer;
};

export type StoreWithAsync = {
  async: Reducers;
} & Store<any, any>;

export const create = (reducers: Reducers): StoreWithAsync => {
  // @ts-expect-error: there is no async key yet as that is added on the next line
  const store: StoreWithAsync = createStore(combineReducers(reducers), enhancer);
  // eslint-disable-next-line functional/immutable-data
  store.async = reducers;

  return store;
};

export const store = create(rootReducer);

export const register = (reducer: Reducer, name: string): void => {
  // eslint-disable-next-line functional/immutable-data
  store.async[name] = reducer;
  store.replaceReducer(combineReducers(store.async));
};

export type ReduxState = StoreWithAsync;
export type RootState = typeof store;
// re-export all the actions
export { actions };
export default store;
