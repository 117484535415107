import * as React from 'react';

import { cx } from '@cobbler-io/utils/src/cx';

import styles from './Meter.scss';

export type MeterProps = {
  /**
   * What is considered `high`
   *
   * Note: this is not evaluative
   */
  high?: number;
  /**
   * What is considered `low`
   *
   * Note: this is not evaluative
   */
  low?: number;
  /**
   * What is the maximum value of the meter
   *
   * default: 100;
   */
  max?: number;
  /**
   * What is the minimum value for the meter
   *
   *
   * Default `0`
   */
  min?: number;
  /**
   * What is consider `optimum` for the meter
   *
   * Note: this is not evaluative
   *
   * default: `80`
   */
  optimum?: number;
  /**
   * The current value of the meter
   */
  value: number;
  /**
   * The size of a step. This will round the value to the nearest step
   *
   * e.g. a min of `0` and a max of `100` with a `step` of `10` allows for 10 valid options
   */
  step?: number | boolean;

  title?: string;

  className?: string;

  style?: React.CSSProperties;
};

const getStepClass = (params: MeterProps) => {
  const { value, high = 100, low = 0, optimum = 80 } = params;
  if (value >= high) {
    return styles.error;
  }

  if (value >= optimum) {
    return styles.ok;
  }

  if (value >= low) {
    return styles.warning;
  }

  return styles.error;
};

/* eslint-disable react/no-array-index-key */

export const Meter = (props: MeterProps) => {
  const { max = 100, min = 0, value, step, optimum, low, high, className, ...rest } = props;
  // @todo figure out a nice API to deal with continuous things
  // const isStepped = typeof step === 'number' || (typeof step === 'boolean' && step);
  const stepClass = getStepClass(props);

  return (
    <div {...rest} className={cx(styles.meter, className)} role="presentation">
      {Array.from({ length: max - min }).map((_, i) => (
        <div key={i} className={cx(styles.step, i + 1 <= value && stepClass)} />
      ))}
    </div>
  );
};

Meter.displayName = 'Meter';

export default Meter;
