import * as React from 'react';

import { execIfFunc } from '@cobbler-io/utils/src';

import { Button } from '@cobbler-io/core-ui/src/Button';
import { useCurrentModal } from '@cobbler-io/core-ui/src/Modal';

import { DatePickerContext } from './DatePickerContext';

export type DatePickerValue = [start: string, end: string | null];

export const SaveButton = (_: Record<string, unknown>): JSX.Element => {
  const { onChange, formatDate, isRange, start, end, range } = React.useContext(DatePickerContext);
  const { close } = useCurrentModal();

  const getValue = (): DatePickerValue | null => {
    if (!start) {
      return null;
    }

    if (isRange) {
      return end ? ([start, end].map(formatDate) as DatePickerValue) : null;
    }

    if (start) {
      return [formatDate(start), null] as DatePickerValue;
    }

    return ['', null] as DatePickerValue;
  };

  const isValid = isRange
    ? start && end && range.includesEvery(start, end)
    : start && range.includes(start);

  const onClick = () => {
    const value = getValue();
    value && onChange(value);
    execIfFunc(close);
  };

  return (
    <Button disabled={!isValid} name="save" variant="contain" onClick={onClick}>
      Save
    </Button>
  );
};

SaveButton.displayName = 'DatePicker__SaveButton';
