import * as React from 'react';

import { noop } from '@cobbler-io/utils/src/noop';

import { Button } from '@cobbler-io/core-ui/src/Button';
import { Icon } from '@cobbler-io/core-ui/src/Icon';
import { ToolTip } from '@cobbler-io/core-ui/src/ToolTip';

import styles from './SystemLineLock.scss';

type SystemLineLockProps = {
  tip?: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
};

const defaultTip = (
  <div style={{ lineHeight: 1.4, maxWidth: '300px', padding: '.5rem', textAlign: 'left' }}>
    This line is generated by Cobbler and cannot be edited.
  </div>
);
export const SystemLineLock = (props: SystemLineLockProps): JSX.Element => {
  const { onClick = noop, tip = defaultTip } = props;

  // Note: we should really be disabling the button, but that messes up the
  // firing of events for the tooltip, so we can't do that (disabled elements
  // do not fire all the regular events that a non-disabled element fires, which
  // is spec).

  return (
    <ToolTip direction="bottom-start" tip={tip}>
      <Button small className={styles.icon} name="placeholder" variant="svg" onClick={onClick}>
        <Icon size={18} type="lockFilled" />
      </Button>
    </ToolTip>
  );
};

SystemLineLock.displayName = 'SystemLineLock';
