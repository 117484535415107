import * as React from 'react';

import styles from './EmptyState.scss';

export const EmptyState = (): JSX.Element => {
  return (
    <div className={styles.container}>
      <span className={styles.title}>No data available</span>
    </div>
  );
};

EmptyState.displayName = 'EmptyState';
