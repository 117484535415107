/**
 * Omits keys from an object (shallow)
 */
export const omit = <T extends Record<string, unknown>, K extends keyof T>(obj: T, remove: K[]): Omit<T, K> => {
  return Object.keys(obj).reduce<Omit<T, K>>((o, k) => {
    if (remove.includes(k as K)) {
      return o;
    }

    // @ts-expect-error
    o[k] = obj[k]; // eslint-disable-line no-param-reassign
    return o;
  }, {});
};
