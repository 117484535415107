/* eslint-disable sort-keys, sort-keys-fix/sort-keys-fix */
import { isNotFalsy } from '@cobbler-io/utils/src/isNotFalsy';

import { getMonthId } from '@cobbler-io/dates/src/getPeriodsInRange';
import { tzAdjust } from '@cobbler-io/dates/src/tzAdjust';

import { Graph } from '@cobbler-io/collection/src/Graph';

import { HydrateBudgetGraphQuery } from '../graphql-types';
import {
  getFiscalYearIdFromISO8601, getQuarterIdFromISO8601, getSpentForLineEdgeId, getSpentInMonthEdgeId,
} from './utils';

type Data = NonNullable<HydrateBudgetGraphQuery['spend']>;

type HydratePlannedSpendParams = {
  graph: Graph<any, any>;
  data: Data;
  startMonth: JSMonth;
};

const emptyObject = {};

export const hydrateSpent = (params: HydratePlannedSpendParams): void => {
  const { data, graph, startMonth } = params;

  for (const spend of data) {
    const labels = [
      'SPEND',
      `MONTH_${spend.start}`,
      `QUARTER_${getQuarterIdFromISO8601(spend.start, startMonth)}`,
      `YEAR_${getFiscalYearIdFromISO8601(spend.start, startMonth)}`,
    ].filter(isNotFalsy);

    const line = graph.getVertexById(spend.budgetLineId);

    if (!line) {
      console.error(
        'HydateSpent: Missing budget line ID',
        spend.budgetLineId,
        'that is referenced in SpendStub',
      );
      continue; // eslint-disable-line no-continue
    }

    const isRevenue = line.hasLabel('REVENUE_LINE');

    const properties = isRevenue
      ? {
          // Numbers
          accrual: {
            // Likely accruals and reversals
            shallow: spend.accrualAmount * -1,
            deep: spend.accrualAmountDeep * -1,
          },
          normal: {
            // non-accrual data
            shallow: spend.normalAmount * -1,
            deep: spend.normalAmountDeep * -1,
          },
          summary: {
            // includes leftover accrual if the month is closed
            shallow: spend.summaryAmount * -1,
            deep: spend.summaryAmountDeep * -1,
          },

          // Coordinates
          budgetLineId: spend.budgetLineId,
          start: spend.start,
        }
      : {
          // Numbers
          accrual: {
            // Likely accruals and reversals
            shallow: spend.accrualAmount,
            deep: spend.accrualAmountDeep,
          },
          normal: {
            // non-accrual data
            shallow: spend.normalAmount,
            deep: spend.normalAmountDeep,
          },
          summary: {
            // includes leftover accrual if the month is closed
            shallow: spend.summaryAmount,
            deep: spend.summaryAmountDeep,
          },

          // Coordinates
          budgetLineId: spend.budgetLineId,
          start: spend.start,
        };

    const vertex = graph.addVertex({
      id: spend.id,
      labels,
      properties,
    });

    // Draw a line between the planned spend and the month
    const month = graph.getVertexById(getMonthId(tzAdjust(spend.start)));

    if (month) {
      graph.addEdge({
        id: getSpentInMonthEdgeId(spend.id),
        labels: ['SPENT_IN_MONTH'],
        from: vertex,
        to: month,
        properties: emptyObject,
      });
    }

    // Draw a line between the planned spend and the budget line

    if (line) {
      graph.addEdge({
        id: getSpentForLineEdgeId(spend.id),
        labels: ['SPENT_FOR_LINE'],
        from: vertex,
        to: line,
        properties: emptyObject,
      });
    }
  }
};
