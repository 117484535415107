import { isNotFalsy } from '@cobbler-io/utils/src/isNotFalsy';

import { ColumnInstance } from 'react-table';

const expandColumnLabel = (x: any) => (typeof x === 'string' ? x : null);

/**
 * Gets the column label with its string header
 *
 * TODO fix so that it allows for non-string headers in a way that doesn't inline functions
 */
export const getColumnLabel = (col?: ColumnInstance<any> | null): string | null => {
  if (!col) {
    return null;
  }

  if (col.label) {
    return col.label;
  }

  const headers = [col.label ?? col.Header, col.label ?? col.parent?.Header];

  if (headers.every(x => x && typeof x === 'string')) {
    return headers.join(':');
  }

  return headers.map(expandColumnLabel).filter(isNotFalsy).join(':');
};
