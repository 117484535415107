import { flipSign } from '@cobbler-io/utils/src/numbers/flipSign';
import { prop } from '@cobbler-io/utils/src/prop';

import { CurrentActualQueryQueryResult } from '@cobbler-io/app/src/api/graphql-types';

type SplitActual = NonNullable<NonNullable<CurrentActualQueryQueryResult['data']>['actual']>;
type BudgetLine = NonNullable<SplitActual['matchedBudgetLine']>;

const DEFAULT_SPLIT_TYPE = 'AMOUNT';

type BudgetLineMeta = {
  id: BudgetLineId;
  name: string;
  fullName: string;
  canRead: boolean;
  canUpdate: boolean;
};

export const budgetLineToMeta = (budgetLine: BudgetLine | null): BudgetLineMeta | null =>
  budgetLine && {
    id: budgetLine.id,
    name: budgetLine.name,
    fullName: budgetLine.ancestors.map(prop('name')).reverse().concat(budgetLine.name).join(' > '),
    canUpdate: budgetLine.permissions?.canUpdate ?? false,
    canRead: budgetLine.permissions?.canRead ?? false,
  };

export type NormalizedActualSlice = {
  id: ActualId;
  amount: MinorCurrency;
  originalAmount: MinorCurrency;
  budgetLine: BudgetLineMeta | null;
};

export type SplitActualData = {
  /**
   * The ID of the Split Origin
   */
  id: ActualId;
  budgetLine: BudgetLineMeta | null;
  amount: MinorCurrency;
  originalAmount: MinorCurrency;
  splitType: 'AMOUNT' | 'PERCENTAGE';
  modifiedAt: ISO8601String | null;
  isSplitOrigin: boolean;
  isSplitSlice: boolean;
  slices: NormalizedActualSlice[];
};

export const normalizeSplitActual = (
  actual: SplitActual,
  convertToMinorUnit: (x: MajorCurrency | string) => MinorCurrency,
  isRevenue: boolean,
): SplitActualData => {
  const formatAmount = (n: number) => convertToMinorUnit(flipSign(n, isRevenue));

  if (!actual.isSplitOrigin && !actual.isSplitSlice) {
    // This actual has yet to be split
    return {
      id: actual.id,
      budgetLine: budgetLineToMeta(actual.matchedBudgetLine),
      amount: formatAmount(actual.amount),
      originalAmount: formatAmount(actual.originalAmount ?? 0),
      splitType: 'PERCENTAGE',
      modifiedAt: null,
      isSplitOrigin: actual.isSplitOrigin,
      isSplitSlice: actual.isSplitSlice,
      slices: [
        {
          id: actual.id,
          budgetLine: budgetLineToMeta(actual.matchedBudgetLine),
          amount: formatAmount(actual.amount),
          originalAmount: formatAmount(actual.originalAmount ?? 0),
        },
      ],
    };
  }

  if (actual.isSplitOrigin) {
    return {
      id: actual.id,
      budgetLine: budgetLineToMeta(actual.matchedBudgetLine),
      /**
       * The current amount of the actual (if it was split, it is what is remaining)
       *
       * Note: the backend treats Actuals differently in that it stores and send MajorCurrency
       * instead of MinorCurrency. We're normalizing the Major->Minor currency in this mapping
       * layer to keep consistency in dealing with Money in display layers.
       */
      amount: formatAmount(actual.amount),
      /**
       * The original amount of the actual (if it was split, what is was before the split)
       *
       * Note: the backend treats Actuals differently in that it stores and send MajorCurrency
       * instead of MinorCurrency. We're normalizing the Major->Minor currency in this mapping
       * layer to keep consistency in dealing with Money in display layers.
       */
      originalAmount: formatAmount(actual.originalAmount ?? 0),
      splitType: actual.splitMetadata?.splitType || DEFAULT_SPLIT_TYPE, // TODO: Is this a reasonable default?
      modifiedAt: actual.splitMetadata?.modifiedAt ?? actual.splitMetadata?.createdAt ?? null,
      isSplitOrigin: actual.isSplitOrigin,
      isSplitSlice: actual.isSplitSlice,
      slices: [
        {
          id: actual.id,
          amount: formatAmount(actual.amount),
          originalAmount: formatAmount(actual.originalAmount ?? 0),
          budgetLine: budgetLineToMeta(actual.matchedBudgetLine),
        },
      ].concat(
        (actual.slices ?? []).map(slice => ({
          id: slice!.id,
          amount: formatAmount(slice!.amount),
          originalAmount: formatAmount(slice!.originalAmount ?? 0),
          budgetLine: budgetLineToMeta(slice!.matchedBudgetLine),
        })),
      ),
    };
  }

  const splitOrigin = actual.splitOrigin!; // TODO: Is it safe to assume it exists?

  return {
    id: splitOrigin.id,
    budgetLine: budgetLineToMeta(splitOrigin.matchedBudgetLine),
    amount: formatAmount(splitOrigin.amount),
    originalAmount: formatAmount(splitOrigin.amount),
    splitType: splitOrigin.splitMetadata?.splitType || DEFAULT_SPLIT_TYPE, // TODO: Is this a reasonable default?
    modifiedAt: actual.splitMetadata?.modifiedAt ?? actual.splitMetadata?.createdAt ?? null,
    isSplitOrigin: actual.isSplitOrigin,
    isSplitSlice: actual.isSplitSlice,
    slices: [
      {
        id: splitOrigin.id,
        amount: formatAmount(splitOrigin.amount),
        originalAmount: formatAmount(splitOrigin.originalAmount ?? 0),
        budgetLine: budgetLineToMeta(splitOrigin.matchedBudgetLine),
      },
    ].concat(
      splitOrigin.slices.map(slice => ({
        id: slice!.id,
        amount: formatAmount(slice!.amount),
        originalAmount: formatAmount(slice!.originalAmount ?? 0),
        budgetLine: budgetLineToMeta(slice!.matchedBudgetLine),
      })),
    ),
  };
};
