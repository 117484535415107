import { merge } from './merge';

export function append(el: string): UnaryFn<string, string>;
export function append(el: string, x: string): string;
export function append(el: string, x?: string): string | UnaryFn<string, string> {
  if (typeof x === 'undefined') {
    return (string: string) => merge(string, el);
  }
  return merge(x, el);
}
