/* eslint-disable no-bitwise */
import { getEndOfMonth } from './getEndOfMonth';
import { DateLike } from './isDateLike';

export type Quarter = { start: Date; end: Date; quarter: number };

const QUARTER_LENGTH = 3;

export const getQuarterDates = (date: DateLike, startMonth = 0): Quarter => {
  const d = new Date(date);
  const adjusted = d.getMonth() - startMonth;
  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  const q = adjusted < 0 ? 11 - ~adjusted : adjusted;
  const year = d.getMonth() < startMonth ? d.getFullYear() - 1 : d.getFullYear();
  const quarter = Math.floor(q / QUARTER_LENGTH) + 1;

  const start = new Date(year, (quarter - 1) * QUARTER_LENGTH + startMonth, 1, 0, 0, 0);
  const end = getEndOfMonth(new Date(start.getFullYear(), start.getMonth() + 2));

  return { end, quarter, start };
};
