import { noop } from '@cobbler-io/utils/src';
import { createNamedContext } from '@cobbler-io/utils/src/createNamedContext';

import { ISO8601 } from '@cobbler-io/formatters/src';

import { addDays, addMonths, DateRange, MAX_SAFE_DATE, MIN_SAFE_DATE } from '@cobbler-io/dates/src';

export type DatePickerSelection = 'START' | 'END';

export type DateRangeTuple = [start: string, end: string | null];

export type DatePickerContextType = {
  /**
   * The selected start date
   */
  start: Date | null;

  /**
   * The selected end date
   */
  end: Date | null;

  /**
   * Whether or not the picker is a range
   */
  isRange: boolean;

  range: DateRange;

  /**
   * A flag that determines if the user is likely trying to select a start or end date
   */
  selecting: DatePickerSelection;

  /**
   * A reference date to determine the calendar creation
   */
  viewDate: Date;

  incrementPage: (viewDate: Date) => Date;

  decrementPage: (viewDate: Date) => Date;

  incrementValue: (date: Date) => Date;

  decrementValue: (date: Date) => Date;

  nextPage: () => void;

  prevPage: () => void;

  setViewDate: (viewDate: Date) => void;

  select: (date: Date) => void;

  selectStart: (start: Date) => void;

  selectEnd: (end: Date) => void;

  reset: () => void;

  setSelecting: (selecting: DatePickerSelection) => void;

  hasNextPage: boolean;
  hasPrevPage: boolean;

  min: Date;
  max: Date;

  onChange: (range: DateRangeTuple) => any;

  formatDate: (date: Date) => string;

  decorate: Set<ISO8601String>;
};

export const DatePickerContext = createNamedContext<DatePickerContextType>('DatePickerContext', {
  decorate: new Set(),
  decrementPage: (date: Date) => addMonths(date, -1),
  decrementValue: (date: Date) => addDays(date, -1),
  end: null,
  formatDate: ISO8601,
  hasNextPage: false,
  hasPrevPage: false,
  incrementPage: (date: Date) => addMonths(date, 1),
  incrementValue: (date: Date) => addDays(date, 1),
  isRange: false,
  max: new Date(MAX_SAFE_DATE),
  min: new Date(MIN_SAFE_DATE),
  nextPage: noop,
  onChange: noop,
  prevPage: noop,
  range: new DateRange(MIN_SAFE_DATE, MAX_SAFE_DATE),
  reset: noop,
  select: noop,
  selectEnd: noop,
  selectStart: noop,
  selecting: 'START',
  setSelecting: noop,
  setViewDate: noop,
  start: null,
  viewDate: new Date(),
});
