import { addMonth } from './addMonth';
import { DateRangeObject } from './DateRange';
import { DateLike } from './isDateLike';

export type DateRangeWithName = DateRangeObject & { name: string };

export const getMonthDates = (
  date: DateLike,
  type: 'short' | 'long' = 'short',
): DateRangeWithName => {
  const start = new Date(date);
  start.setDate(1);
  start.setHours(0, 0, 0, 0);
  const end = addMonth(start);

  end.setMilliseconds(-1);

  const fmt = new Intl.DateTimeFormat(undefined, {
    month: type,
    year: 'numeric',
  });

  return {
    name: fmt.format(end),
    start,
    end,
  };
};
