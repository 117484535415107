import { addMonth } from '@cobbler-io/dates/src/addMonth';
import { getFiscalYear } from '@cobbler-io/dates/src/getFiscalYear';
import { getFiscalYearDates } from '@cobbler-io/dates/src/getFiscalYearDates';
import { DateLike } from '@cobbler-io/dates/src/isDateLike';

export type FiscalYearPeriod = {
  start: Date;
  end: Date;
  name: string;
  year: number;
};

/**
 * Gets fiscal years in a period.
 *
 * Currently, it'll return any fiscal year if there is partial overlap, so for a fourteen month
 * period, you'll get either two or three fiscal years back. We might consider adding an option
 * that will return only periods that fit squarely into the range (e.g. no partials).
 */
export const getFiscalYearsInPeriod = (
  start: DateLike,
  end: DateLike,
  startMonth = 0,
): FiscalYearPeriod[] => {
  // eslint-disable-next-line functional/no-let, no-restricted-syntax
  let reference = new Date(start);
  const endDate = new Date(end);

  const years = [];

  // This has some duplication in it, and it's likely doing three-plus times the work it should
  while (reference < endDate) {
    const fiscalYear = getFiscalYearDates(reference, startMonth);
    const year = getFiscalYear(reference, startMonth);

    // eslint-disable-next-line functional/immutable-data
    years.push({ ...fiscalYear, year });
    // We're stepping through months right now to make sure we don't miss a period.
    // We can probably change this step size to something larger
    reference = addMonth(reference);
  }

  return Object.values(
    years.reduce<Record<string, FiscalYearPeriod>>(
      (acc, year) => ({ ...acc, [year.name]: year }),
      {},
    ),
  );
};
