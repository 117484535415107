import * as React from 'react';

import { cx } from '@cobbler-io/utils/src/cx';

import styles from './ResizeHandle.scss';

/**
 * TODO add aria labels for the resizer
 */
export const ResizeHandle: React.FC<any> = ({ className, ...props }) => {
  return <span {...props} className={cx(className, styles.resizeHandle)} />;
};

ResizeHandle.displayName = 'ResizeHandle';

export default ResizeHandle;
