import * as React from 'react';

import { cx } from '@cobbler-io/utils/src/cx';

import { Button } from '@cobbler-io/core-ui/src/Button';
import { Icon, IconTypes } from '@cobbler-io/core-ui/src/Icon';

import { ExpandableTreeContext } from './ExpandableTreeContext';

import styles from './ExpandableTreeNode.scss';

export type ExpandableTreeNodeProps = {
  id: string;
  Header: string | React.FunctionComponent<Omit<ExpandableTreeNodeProps, 'Header'>>;
  children?: React.ReactNode;
  className?: string;
  expandedIcon?: IconTypes;
  collapsedIcon?: IconTypes;
  leafIcon?: IconTypes;
};

export const ExpandableTreeNode = (props: ExpandableTreeNodeProps) => {
  const treeContext = React.useContext(ExpandableTreeContext);
  const {
    id,
    children,
    Header,
    className,
    expandedIcon = treeContext.expandedIcon,
    collapsedIcon = treeContext.collapsedIcon,
    leafIcon = treeContext.leafIcon,
  } = props;
  const { toggle, isExpanded } = treeContext;
  const hasChildren = React.Children.toArray(children).length > 0;
  const icon = isExpanded(id) ? expandedIcon : collapsedIcon;
  const buttonName = isExpanded(id) ? 'Collapse item' : 'Expand item';

  return (
    <div className={cx(styles.expandableTreeNode, className)}>
      <div className={styles.header}>
        <Button
          small
          className={styles.button}
          disabled={!hasChildren}
          name={hasChildren ? buttonName : ''}
          variant="svg"
          onClick={() => toggle(id)}
        >
          <Icon className={styles.expandIcon} size={12} type={hasChildren ? icon : leafIcon} />
        </Button>

        {typeof Header === 'function' ? Header(props) : Header}
      </div>
      <div className={styles.contents}>{isExpanded(id) && children}</div>
    </div>
  );
};

ExpandableTreeNode.displayName = 'ExpandableTreeNode';

export const isExpandableTreeNode = (child: any) =>
  child?.type?.displayName === ExpandableTreeNode.displayName;
