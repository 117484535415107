export function* skip<T>(x: number, iterable: Iterable<T>): Iterable<T> {
  let count = 0;

  for (const iteration of iterable) {
    if (count < x) {
      count++;
    } else {
      yield iteration;
    }
  }
}
