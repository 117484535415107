import { isHTMLElement } from '@cobbler-io/utils/src/isHTMLElement';

// Popper Modifier that makes the element the same with as the Popper
import { Modifier, ModifierArguments } from '@popperjs/core';

/* eslint-disable no-param-reassign, @typescript-eslint/no-explicit-any */
export const sameHeight: Modifier<'sameHeight', any> = {
  effect: ({ state }) => {
    const offset = isHTMLElement(state.elements.reference)
      ? state.elements.reference.offsetHeight
      : state.elements.reference.getBoundingClientRect().height;

    state.elements.popper.style.height = `${offset}px`;
  },
  enabled: true,
  fn: ({ state }: ModifierArguments<any>) => {
    if (state.styles.popper) {
      state.styles.popper.height = `${state.rects.reference.height}px`;
    }
  },
  name: 'sameHeight',
  phase: 'beforeWrite',
  requires: ['computeStyles'],
};
/* eslint-enable no-param-reassign, @typescript-eslint/no-explicit-any */
