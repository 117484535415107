/* eslint-disable no-restricted-syntax */
const properties = ['width', 'position', 'display', 'whiteSpace', 'flexWrap'] as const;
const measureValues = ['auto', 'absolute', 'flex', 'nowrap', 'nowrap'];
export const measureElementWidth = (el: HTMLElement) => {
  // Save the initial values
  const saved = properties.map(property => el.style[property]);

  // Set the values to measure
  for (let i = 0; i < properties.length; i++) {
    // eslint-disable-next-line no-param-reassign
    el.style[properties[i]] = measureValues[i];
  }

  // Save the new width
  const width = el.scrollWidth;

  // Set the properties back
  for (let i = 0; i < properties.length; i++) {
    // eslint-disable-next-line no-param-reassign
    el.style[properties[i]] = saved[i];
  }

  return width;
};

export const getWidestElementWidth = async (elements: HTMLElement[]): Promise<number> => {
  const saved = new Map<HTMLElement, React.CSSProperties>();
  const measures: number[] = [];

  // Save the old properties
  const save = async () =>
    new Promise<void>(resolve => {
      elements.forEach(el => {
        const props: React.CSSProperties = properties.reduce((acc, name) => {
          acc[name] = el.style[name];
          return acc;
        }, {});

        saved.set(el, props);
      });

      resolve();
    });

  // Set everything to the values that will let us measure correctly
  const set = async () =>
    new Promise<void>(resolve => {
      elements.forEach(el => {
        // Set the values to measure
        for (let i = 0; i < properties.length; i++) {
          // eslint-disable-next-line no-param-reassign
          el.style[properties[i]] = measureValues[i];
        }
      });
      resolve();
    });

  const measure = async () =>
    new Promise<void>(resolve => {
      // Record all the widths
      elements.forEach(el => measures.push(el.scrollWidth));
      resolve();
    });

  const revert = async () =>
    new Promise<void>(resolve => {
      // Set everything back to normal
      elements.forEach(el => {
        const props: React.CSSProperties = saved.get(el)!;
        Object.entries(props).forEach(([name, value]) => {
          // eslint-disable-next-line no-param-reassign
          el.style[name] = value;
        });
      });
      resolve();
    });

  await new Promise(res =>
    requestAnimationFrame(async () => save().then(set).then(measure).then(revert).then(res)),
  );

  return measures.reduce((widest, measurement) => Math.max(widest, measurement), 0);
};

export const getWidest = async (selector: string, container: HTMLElement | Document = document) => {
  const nodes = Array.from(container.querySelectorAll(selector));
  return getWidestElementWidth(nodes as HTMLElement[]);
};

/**
 * Works like `getWidest` but reduces the amount of layout thrashing that would be caused
 * if `getWidest` were to be called multiple times
 */
export const getWidestBySelector = async (
  selectors: string[],
  container: HTMLElement | Document = document,
): Promise<number[]> => {
  const nodesArray = selectors.map(selector =>
    Array.from(container?.querySelectorAll(selector) ?? []),
  );

  // Create a map to save all of the properties
  const saved = new Map<HTMLElement, React.CSSProperties>();

  const save = async () =>
    new Promise<void>(resolve => {
      // Record the values of each element before. This should let us avoid some layout thrashing
      for (const elements of nodesArray) {
        for (const el of elements) {
          if (!(el instanceof HTMLElement)) {
            continue;
          }

          const props: Record<string, any> = {};
          for (let i = 0; i < properties.length; i++) {
            props[properties[i]] = el.style[properties[i]];
          }
          saved.set(el, props);
        }
      }
      resolve();
    });

  const set = async () =>
    new Promise<void>(resolve => {
      // Update all the nodes that we want to measure
      for (const elements of nodesArray) {
        for (const el of elements) {
          // Set the values to measure
          for (let i = 0; i < properties.length; i++) {
            // eslint-disable-next-line no-param-reassign
            el.style[properties[i]] = measureValues[i];
          }
        }
      }
      resolve();
    });

  // Create an array to return the results in
  const measurements: number[] = [];

  const measure = async () =>
    new Promise<void>(resolve => {
      // Measure each element here. The first time we run this, we should force the layout
      // but after that, should be free
      for (let i = 0; i < selectors.length; i++) {
        const elements = nodesArray[i];
        let max = 0;
        for (const el of elements) {
          max = Math.max(max, el.scrollWidth);
        }
        // Just save the widest
        measurements[i] = max;
      }
      resolve();
    });

  const revert = async () =>
    new Promise<void>(resolve => {
      // Set every element back to what it was
      for (const elements of nodesArray) {
        for (const el of elements) {
          const props = saved.get(el)!;
          for (let i = 0; i < properties.length; i++) {
            // @ts-expect-error: this follows a certain order, so it's fine
            el.style[properties[i]] = props[properties[i]]!;
          }
        }
      }
      resolve();
    });

  await new Promise(res =>
    requestAnimationFrame(async () => save().then(set).then(measure).then(revert).then(res)),
  );

  // Return the widest element per selector
  return measurements;
};
