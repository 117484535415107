import * as React from 'react';

import { AuthContext } from '../AuthContext';

export const SilentSignIn: React.FC = () => {
  const {
    state: { manager },
  } = React.useContext(AuthContext);
  React.useEffect(() => {
    manager && manager.signinSilentCallback();
  });

  return <div className="oidc--silent-signin" />;
};
