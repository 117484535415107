import * as React from 'react';

import { arraysAreEqual } from '@cobbler-io/utils/src/array/arraysAreEqual';

import { useSyncExternalStore } from 'use-sync-external-store/shim';

import { Traversal } from '../Traversal';
import { useGraphContext } from './GraphContext';

type Equality<T> = (a: T, b: T) => boolean;

const defaultIsEqual = <T>(a: T, b: T): boolean => {
  if (a instanceof Traversal && b instanceof Traversal) {
    return a.isEqual(b);
  }

  if (Array.isArray(a) && Array.isArray(b)) {
    return arraysAreEqual(a, b);
  }

  return a === b;
};

export const useGraphTraversal = <T>(
  traversal: (graph: Traversal<any, any>) => T,
  isEqual: Equality<T> = defaultIsEqual,
): T => {
  const { graph } = useGraphContext();
  const cache = React.useRef<T | null>(null);

  const snapshot = useSyncExternalStore(
    (onStoreChange: () => any) => graph.subscribe(onStoreChange),
    () => graph.snapshot(), // store.getState
    undefined,
  );

  const next = snapshot.traverse(traversal);

  if (cache.current === null) {
    cache.current = next;
  } else if (!isEqual(cache.current, next)) {
    // eslint-disable-next-line functional/immutable-data
    cache.current = next;
  }

  React.useDebugValue(cache.current);

  return cache.current!;
};
