import React, { isValidElement, ReactElement } from 'react';
import { flushSync, unstable_batchedUpdates as batch } from 'react-dom';

import { cx } from '@cobbler-io/utils/src/cx';
import { execIfFunc } from '@cobbler-io/utils/src/execIfFunc';
import { isFunction } from '@cobbler-io/utils/src/isFunction';
import { isHTMLElement } from '@cobbler-io/utils/src/isHTMLElement';

import { useDefaultOrRandom } from '@cobbler-io/hooks/src/useDefaultOrRandom';
import { useGetLatest } from '@cobbler-io/hooks/src/useGetLatest';

import { Modal, ModalProps, ModalTreeNode } from '@cobbler-io/core-ui/src/Modal';
import { useModalImplementation } from '@cobbler-io/core-ui/src/Modal/useModalImplementation';

import { isNil } from 'ramda';

import { MenuItem, MenuItemProps } from './MenuItem';
import { menuReducer } from './menuReducer';
import { MenuSeparator } from './MenuSeparator';

import styles from './Menu.scss';

const OPEN_SUBMENU_DELAY = 100;

// TODO https://www.w3.org/TR/wai-aria-practices/examples/menu-button/menu-button-links.html
// TODO https://www.w3.org/WAI/GL/wiki/Using_ARIA_menus

/**
 * Calls a nullary function on a passed object (if such a function exists)
 *
 * Note: this is helpful for calling focus on a DOM node because destructing focus or
 * calling `execIfFunc(el.focus)` will have an improper `this` arg on the DOM node
 */
const focus = (el?: any): void => void (el && isFunction(el.focus) && el.focus());

export type MenuChild = JSX.Element | boolean | null;

export type MenuProps = Partial<ModalProps> & {
  children: MenuChild | MenuChild[];
  className?: string;
  id?: string;
  small?: boolean;
};

type UseMenuParams = {
  items: React.ReactElement<MenuItemProps>[];
  modal?: ModalTreeNode | null;
  small?: boolean;
};

// eslint-disable-next-line max-lines-per-function
const useMenu = (params: UseMenuParams) => {
  const { items, modal, small = false } = params;
  const delayOpen = React.useRef<number | undefined>();
  const subMenuRefs = React.useRef<React.RefObject<HTMLLIElement>[]>([]);
  const openSubMenuRef = React.useRef<number>(-1);
  const [state, dispatch] = React.useReducer(menuReducer, {
    activeIndex: 0,
    activeItem: null,
    items,
  });
  const getItems = useGetLatest(items);
  const getActiveIndex = useGetLatest(state.activeIndex);

  const memoized = React.useMemo(() => {
    const isSubMenu = (item: unknown): item is React.ReactElement<MenuItemProps> =>
      Boolean(MenuItem.isMenuItem(item) && item.props.children);
    subMenuRefs.current = getItems().map(_ => React.createRef());
    const actions = {
      decrement: (): void => dispatch({ type: 'ActiveIndex/Decrement' }),
      focusActive: (): void =>
        focus(subMenuRefs.current[getActiveIndex()]?.current?.firstElementChild),
      increment: (): void => dispatch({ type: 'ActiveIndex/Increment' }),
      set: (x: number): void => dispatch({ type: 'ActiveIndex/Set', payload: x }),
    };

    const maybeOpenSubMenu = (subMenuIndex: number, delay = 0) => {
      const item = getItems()[subMenuIndex];
      const target = subMenuRefs.current[subMenuIndex];

      if (item?.props.disabled) {
        return;
      }

      window.clearTimeout(delayOpen.current);
      delayOpen.current = window.setTimeout(() => {
        if (!isSubMenu(item) || item.props.disabled) {
          // This is either not a MenuItem, it has no children (which means it terminates),
          // or it's disabled, and so we don't do any actions on it.
          return;
        }

        batch(() => {
          const subMenuChildren = ([] as JSX.Element[]).concat(item.props.children ?? []);
          const subMenuId = item.props.id!;

          modal?.closeChildren();

          modal?.createChild(
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            <Menu id={subMenuId} small={small}>
              {subMenuChildren}
            </Menu>,
            {
              onAfterClose: [
                () => setTimeout(actions.focusActive, 0),
                () => {
                  openSubMenuRef.current = -1;
                },
              ],
              onBeforeClose: [() => setTimeout(actions.focusActive, 0)],
              placement: 'right-start',
              target,
            },
          );
        });
      }, delay);
    };

    const openSubMenu = (event: React.KeyboardEvent<HTMLUListElement>): void => {
      const item = getItems()[getActiveIndex()];
      if (item?.props.disabled) {
        return;
      }

      if (isSubMenu(item)) {
        event.preventDefault();
        maybeOpenSubMenu(getActiveIndex());
      }
    };

    const actOnItem = (event: React.KeyboardEvent<HTMLUListElement>): void => {
      event.preventDefault();

      const item = getItems()[getActiveIndex()];
      if (!item || item.props.disabled) {
        return;
      }

      if (isSubMenu(item)) {
        return maybeOpenSubMenu(getActiveIndex());
      }

      flushSync(() => {
        modal?.closeToSentinel();
      });

      const cb = (item as React.ReactElement<MenuItemProps>).props.onSelect;
      setTimeout(() => execIfFunc(cb), 0);
    };

    // eslint-disable-next-line consistent-return
    const keyDownHandler: React.KeyboardEventHandler<HTMLUListElement> = event => {
      ['ArrowDown', 'ArrowUp', 'ArrowLeft', 'ArrowRight', 'Enter'].includes(event.key) &&
        event.preventDefault();

      switch (event.key) {
        case 'ArrowDown':
          return actions.increment();
        case 'ArrowUp':
          return actions.decrement();
        case 'ArrowLeft':
          return void (modal?.parent && modal?.close());
        case 'ArrowRight':
          return openSubMenu(event);
        case 'Enter':
          return actOnItem(event);
        default:
        // noop
      }
    };

    return {
      actions,
      keyDownHandler,
      maybeOpenSubMenu,
      subMenuRefs,
    };
  }, [getActiveIndex, getItems, modal, small]);

  return { ...memoized, state };
};

const renderItem = <C extends any>(
  renderSubMenu: UnaryFn<React.ReactElement<MenuItemProps>>,
): UnaryFn<C, JSX.Element | null> => {
  const fn = (child: C) => {
    if (isNil(child)) {
      return null;
    }

    if (MenuItem.isMenuItem(child)) {
      return renderSubMenu(child);
    }

    if (MenuSeparator.isMenuSeparator(child)) {
      return (
        <li aria-hidden className={styles.menuSeparator}>
          {child}
        </li>
      );
    }

    if (typeof child === 'boolean') {
      return null;
    }

    if (React.isValidElement(child as React.ReactElement<Record<string, unknown>>)) {
      return <li className={styles.menuItem}>{child as React.ReactElement}</li>;
    }

    return null;
  };
  fn.displayName = 'RenderMenuItem';
  return fn;
};

export const Menu = (props: MenuProps): JSX.Element | null => {
  const { children, className, id: defaultId, small = false } = props;
  const id = useDefaultOrRandom(defaultId);
  const { modal } = useModalImplementation();

  const items = React.useMemo(
    () => React.Children.toArray(children).filter(MenuItem.isMenuItem),
    [children],
  );

  const { state, actions, keyDownHandler, maybeOpenSubMenu, subMenuRefs } = useMenu({
    items,
    modal,
    small,
  });

  const getActiveIndex = useGetLatest(state.activeIndex);

  React.useEffect(() => {
    actions.focusActive();
  }, [actions, state.activeIndex]);

  if (!modal) {
    return null;
  }

  let counter = -1; // eslint-disable-line

  const renderSubMenu = (child: React.ReactElement<MenuItemProps>) => {
    const { toggle, disabled = false } = child.props;
    const index = ++counter; // eslint-disable-line no-plusplus
    const ref = subMenuRefs.current[index];
    const hasSubmenu = !toggle && child.props.children;
    const keepOpen = !!toggle || !!child.props.stayOpenAfterSelect || hasSubmenu;

    const onPointerEnter = () => {
      if (disabled) {
        return;
      }
      batch(() => {
        actions.set(index);
        modal.closeChildren();
        hasSubmenu && maybeOpenSubMenu(index, OPEN_SUBMENU_DELAY);
      });
    };
    const onClickListElement = () => {
      if (disabled) {
        return;
      }
      hasSubmenu && actions.set(index);
    };

    const onClickButton = () => {
      if (disabled || keepOpen) {
        return;
      }

      // Toggles are handled by the MenuItem component. It's sub-par because if you click on
      // space in the button rather than the items in the button, then the menu will close without
      // doing what it is supposed to do
      if (!child.props.toggle && typeof child.props.onSelect === 'function') {
        const cb = child.props.onSelect;
        setTimeout(cb, 0);
      }

      flushSync(() => {
        modal?.closeToSentinel();
      });
    };

    const isActive = index === getActiveIndex();

    return (
      <li
        key={`${modal.id as string}-${index}`}
        ref={ref}
        role="none"
        onClick={onClickListElement}
        onPointerEnter={onPointerEnter}
      >
        <button
          aria-haspopup="menu"
          className={cx(styles.menuItem, isActive && styles.active, small && styles.small)}
          disabled={disabled}
          role="menuitem"
          type="button"
          onClick={onClickButton}
        >
          {React.cloneElement(child, { small })}
        </button>
      </li>
    );
  };

  const render = renderItem(renderSubMenu);

  return (
    <Modal
      arrow={isHTMLElement(modal?.target)}
      className={cx(styles.menu, className)}
      clickAway="closest-sentinel"
      escape="closest-sentinel"
    >
      <ul
        className={cx(styles.menuItemContainer, small && styles.small, className)}
        id={id}
        role="menu"
        onKeyDown={keyDownHandler}
      >
        {React.Children.map(children, render)}
      </ul>
    </Modal>
  );
};

Menu.displayName = 'Menu';

/* eslint-disable functional/immutable-data */
Menu.Item = MenuItem;
Menu.Separator = MenuSeparator;
Menu.isMenu = (node: unknown): node is ReactElement<MenuProps> =>
  typeof node === 'object' && isValidElement<MenuProps>(node) && node.type === Menu;
