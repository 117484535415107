import { noop } from '@cobbler-io/utils/src';

import { useGraphContext } from '@cobbler-io/collection/src/Graph/react';

import { QueryResult } from '@apollo/react-common';

import { HydrateDepartmentsQuery, useHydrateDepartmentsQuery } from '../graphql-types';
import { hydrateDepartments } from './hydrateDepartments';

type UseHydrateDepartmentsParams = {
  onComplete?: () => void;
};

/**
 * Ensures that departments have been fetched and hydrated into the graph.
 */
export const useHydrateDepartments = (
  params?: UseHydrateDepartmentsParams,
): QueryResult<HydrateDepartmentsQuery> => {
  const { onComplete = noop } = params ?? {};
  const { graph } = useGraphContext();

  return useHydrateDepartmentsQuery({
    onCompleted: data => {
      if (!data.departments?.items) {
        return;
      }

      hydrateDepartments({ data: data.departments.items, graph });
      onComplete();
    },
  });
};
