import * as React from 'react';

import { ComboBox, ComboBoxProps, Item } from '@cobbler-io/core-ui/src/Dropdown';

import { GetAccountsQuery, useGetAccountsQuery } from '@cobbler-io/app/src/api/graphql-types';

export type AccountPickerProps = Record<string, unknown> & Omit<ComboBoxProps<AccountId>, 'multiple' | 'items'>;

export type AccountItem = {
  id: AccountId;
  label: string;
  value: AccountId;
  search: string;
};

type APIAccounts = NonNullable<GetAccountsQuery['a_accounts']>;
type APIAccountItem = NonNullable<NonNullable<APIAccounts['items']>[number]>;

const transformAccountItem = (item: APIAccountItem): Item<AccountId> => {
  return {
    id: item.id,
    label: <>{[item.displayCode, item.displayName].join(': ')}</>,
    value: item.id,
    search: [item.displayCode, item.displayName].join(' '),
  };
};

const emptyArray: AccountId[] = [];

const sortByCode = (a: APIAccountItem, b: APIAccountItem): number => {
  // Most account codes are integers, so we can sort that way
  if ((/^[0-9]+/.exec(a.displayCode)) && (/^[0-9]+/.exec(b.displayCode))) {
    return parseInt(a.displayCode, 10) - parseInt(b.displayCode, 10);
  }

  // Sometimes, they're strings, and those should come after the numbers
  return a.displayCode.localeCompare(b.displayCode);
};

export const AccountPicker = React.forwardRef(
  (props: AccountPickerProps, ref: React.RefObject<HTMLDivElement>): JSX.Element => {
    const {
      id,
      name = 'account-picker',
      variant = 'inlineChip',
      defaultValue = emptyArray,
    } = props;
    const { data, loading, error } = useGetAccountsQuery({ fetchPolicy: 'cache-first' });

    const items: Item<AccountId>[] = React.useMemo(() => {
      const rawItems = data?.a_accounts?.items;
      if (!rawItems || !Array.isArray(rawItems)) {
        return [];
      }
      return rawItems.sort(sortByCode).map(transformAccountItem);
    }, [data]);

    if (error) {
      // TODO use a better component
      return <>Error</>;
    }

    if (loading && !data) {
      return <ComboBox.Loading multiple id={id} selected={defaultValue} variant="inlineChip" />;
    }

    return (
      <ComboBox
        {...props}
        ref={ref}
        clearButton
        multiple
        defaultValue={defaultValue}
        id={id}
        items={items}
        name={name}
        variant={variant}
      />
    );
  },
);

AccountPicker.displayName = 'AccountPicker';
