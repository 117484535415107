import { destructureRelayId } from '@cobbler-io/utils/src/destructureRelayId';
import { isGuid } from '@cobbler-io/utils/src/isGuid';
import { isString } from '@cobbler-io/utils/src/isString';

type TypeGuard<T> = (x: unknown) => x is T;

export const isRelayIdTypeOf =
  <T extends string>(idType: string): TypeGuard<T> =>
  (relayId: unknown): relayId is T => {
    if (!isString(relayId)) {
      return false;
    }

    const [type, id] = destructureRelayId(relayId);

    if (!type || !id) {
      return false;
    }

    return isGuid(id) && type === idType;
  };

export const isVendorId = isRelayIdTypeOf<VendorId>('VendorType');

export const isAccountId = isRelayIdTypeOf<AccountId>('AccountType');
