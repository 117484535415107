import * as React from 'react';

import { useCurrentActualQuery } from '@cobbler-io/app/src/api/graphql-types';
import { actualBasePath } from '@cobbler-io/app/src/urls/urls';

import { useMatch } from '@reach/router';

import { CurrentActualContext } from './CurrentActualContext';

export type CurrentActualProviderProps = {
  children: JSX.Element;
};

export const CurrentActualProvider = (props: CurrentActualProviderProps) => {
  const match = useMatch(`${actualBasePath}/*`);
  const actualId = match?.actualId;

  const { data, error, loading, refetch } = useCurrentActualQuery({
    skip: !actualId,
    variables: { id: actualId! },
  });

  React.useEffect(() => {
    actualId && refetch({ id: actualId });
  }, [refetch, actualId]);

  const value = React.useMemo(
    () => ({
      actual: data?.actual ?? null,
      actualId,
      error: !actualId || Boolean(error) || !data?.actual,
      loading,
      refetch,
    }),
    [data?.actual, actualId, error, loading, refetch],
  );

  return actualId ? <CurrentActualContext.Provider value={value} {...props} /> : null;
};

CurrentActualProvider.displayName = 'CurrentActualProvider';
