import * as React from 'react';

import { ErrorCallout } from '@cobbler-io/core-ui/src/Callout';

import { WebcamContext } from './WebcamContext';

export type DisplayStreamErrorProps = {
  className?: string;
  style?: React.CSSProperties;
};

/**
 * @see https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/getUserMedia#Security
 */
const mediaStreamErrors = {
  // User and OS granted permission, but an unknown error occurred
  AbortError: {
    title: 'Error loading video',
    body: 'An unknown error occurred.',
  },
  // Either not https, no correct feature policy, or the user or OS denied permission
  NotAllowedError: {
    title: 'Permission error',
    body: 'Camera cannot be loaded due to access issues.',
  },
  // No suitable device was found
  NotFoundError: {
    title: 'Camera not found',
    body: 'No camera was found to take a picture.',
  },
  // User granted access, but another error (hardware, OS, or page) occurred
  NotReadableError: {
    title: 'Camera not readable',
    body: 'Could not obtain stream from camera.',
  },
  // Devices are present, but the request constraints are too narrow
  OverconstrainedError: {
    title: 'Camera not found',
    body: 'No suitable camera was found.',
  },
  // User media support is disabled on document
  SecurityError: {
    title: 'Security error',
    body: 'The camera could not be accessed due to security constraints.',
  },
  // A sort of catch-all error
  TypeError: {
    title: 'Unknown error',
    body: 'An unknown error occurred when trying to use the camera.',
  },
};

const defaultTitle = 'Unknown error';
const defaultBody = 'An unknown error occurred when trying to use the camera.';

export const DisplayStreamError = (props: DisplayStreamErrorProps): JSX.Element | null => {
  const {
    state: { error },
  } = React.useContext(WebcamContext);

  if (!error) {
    return null;
  }

  const { title = defaultTitle, body = defaultBody } = mediaStreamErrors[error.name];

  return (
    <ErrorCallout {...props} title={title}>
      {body}
    </ErrorCallout>
  );
};

DisplayStreamError.displayName = 'DisplayStreamError';
