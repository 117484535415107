/* eslint-disable functional/immutable-data, functional/no-let, no-restricted-syntax */
import * as React from 'react';

import tokens from '@cobbler-io/design-tokens';

import { getImageWithBadge } from '@cobbler-io/dom/src/getImageWithBadge';

import { ConnectDragPreview } from 'react-dnd';

export type DragPreviewImageWithBadgeProps = {
  connect: ConnectDragPreview;
  count: number;
  src: string;
  srcMulti?: string;
};

export const DragPreviewImageWithBadge = React.memo((props: DragPreviewImageWithBadgeProps) => {
  const { connect, src, srcMulti, count } = props;

  React.useEffect(() => {
    let connected = false;
    getImageWithBadge(count > 1 && srcMulti ? srcMulti : src, {
      background: tokens.colorSecondary,
      size: 12, // font size 12px
      text: count > 1 ? `${count}` : '', // If count = 0 -> text = '' -> no badge
    })
      .then(img => {
        connect(img);
        connected = true;
      })
      .catch(console.error);

    return () => {
      connected && connect(null);
    };
  });

  return null;
});
DragPreviewImageWithBadge.displayName = 'DragPreviewImageWithBadge';
