import { pad0 } from '@cobbler-io/utils/src/pad0';

const dateFormatter = new Intl.DateTimeFormat(undefined, {
  timeZone: 'utc',
});

const dateFormatterWithZeros = new Intl.DateTimeFormat(undefined, {
  timeZone: 'utc',
  month: '2-digit',
  year: '2-digit',
  day: '2-digit',
});

const fullDateFormatter = new Intl.DateTimeFormat(undefined, {
  timeZone: 'utc',
  month: 'short',
  year: 'numeric',
  day: 'numeric',
});

const fullDateAndTimeFormatter = new Intl.DateTimeFormat(undefined, {
  timeZone: 'utc',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  month: 'short',
  second: 'numeric',
});

const monthYearFormatter = new Intl.DateTimeFormat(undefined, {
  month: 'short',
  year: 'numeric',
  timeZone: 'utc',
});

/**
 * Formats a date
 *
 * @deprecated
 * @TODO rename
 */
export const date = (input: Date | number) => dateFormatter.format(input);

/**
 *
 * @deprecated
 */
export const dateWithZeros = (input: Date | number) => dateFormatterWithZeros.format(input);

/**
 *
 * @deprecated
 */
export const fullDate = (input: Date | number) => fullDateFormatter.format(input);

export const fullDateAndTime = (input: Date | number): string =>
  fullDateAndTimeFormatter.format(input);

/**
 *
 * @deprecated
 */
export const monthYear = (input: Date | number) => monthYearFormatter.format(input);

/**
 * Gets `MM-DD` and return `MonthName DD`
 */
export const monthDay = (input: string): string => {
  const [month, day] = input.split('-');

  if (!month || !day) {
    return '';
  }

  const d = new Date(new Date().getFullYear(), parseInt(month) - 1, parseInt(day));
  if (!d.getDate()) {
    return ''; // We couldn't parse the date
  }

  return new Intl.DateTimeFormat(undefined, { month: 'long', day: '2-digit' }).format(d);
};

/**
 * Standard ISO8601 dates
 */
export const ISO8601 = (input: Date): ISO8601String =>
  [input.getFullYear(), pad0(input.getMonth() + 1), pad0(input.getDate())].join('-');
