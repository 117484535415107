import * as React from 'react';

/**
 * Creates an object that holds mutable _instance_ variables that are preserved
 * between re-renders. This is just a wrapper around ref.current, for when it
 * makes sense to use _instance_ variables inside functional components.
 *
 * Usage:
 * ```ts
 * const self = useInstanceVars<MyInstanceVarsType>({
 *   var1 = "foo",
 *   var2 = "bar",
 * });
 * ...
 * self.var2 = "new val";
 * ```
 *
 * Note: Be careful when using primitive values inside closures as the value
 * of the instance variable might not get updated.
 * @param initial An object that holds the instance variables and their initial value.
 */
export const useInstanceVars = <T extends Record<string, unknown>>(initial: T): T => React.useRef(initial).current;
