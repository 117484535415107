/* eslint-disable react/no-multi-comp */
import * as React from 'react';

import { lockScroll, unlockScroll } from '@cobbler-io/utils/src/bodyScrollLock';
import { noop } from '@cobbler-io/utils/src/noop';
import { ternary } from '@cobbler-io/utils/src/ternary';

type Props = {
  increment: () => void;
  decrement: () => void;
}

const { Consumer, Provider } = React.createContext({ increment: noop, decrement: noop });

export class ScrollLockProvider extends React.Component<Record<string, unknown>, { count: number }> {
  static displayName = 'ScrollLockProvider';

  state = { count: 0 };

  componentDidUpdate() {
    const { count } = this.state;

    ternary(!!count, lockScroll, unlockScroll);
  }

  increment = () => this.setState(prev => ({ count: prev.count + 1 }));

  decrement = () => this.setState(prev => ({ count: prev.count - 1 }));

  getContext = () => ({ increment: this.increment, decrement: this.decrement });

  render() {
    const { children } = this.props;

    return <Provider value={this.getContext()}>{children}</Provider>;
  }
}

export const ScrollLockProto: React.FC<Props> = ({ increment, decrement }) => {
  React.useEffect(() => {
    increment();

    return decrement;
  }, [increment, decrement]);

  return null;
};

export const ScrollLock = () => <Consumer>{props => <ScrollLockProto {...props} />}</Consumer>;
ScrollLock.displayName = 'ScrollLock';

export default ScrollLock;
