import * as React from 'react';

import { APIClient } from '@cobbler-io/utils/src/api-client';

import { useSelector } from 'react-redux';

import { AuthState, UserProfile } from './auth';
import { AuthContext } from './AuthContext';

export type ProfileKey = keyof UserProfile;

/**
 * React Hook that returns a key of the user profile or the entire profile
 *
 * e.g. to get a the preferred_username, do:
 * ```
 * const name = useProfile('preferred_username')
 * ```
 *
 * To get the entire profile do:
 * ```
 * const profile = useProfile();
 * ```
 */
export const useProfile = (
  key?: ProfileKey,
): UserProfile[ProfileKey] | UserProfile | Record<string, unknown> => {
  const { profile } = React.useContext(AuthContext);

  return key ? profile[key] : profile;
};

/**
 * Gets the APIClient that lives in the AuthContext
 */
export const useAPIClient = (): APIClient => {
  const { client } = React.useContext(AuthContext);

  return client;
};

const getAuthUser = (state: { auth: AuthState }) => state.auth.user!;

/**
 * Returns an object with an `Authorization` request header
 *
 * This can be merged into other objects with more headers or used on its own in a fetch request
 */
export const useAuthHeader = (): HeadersInit => {
  const { token_type: tokenType, access_token: accessToken } = useSelector(getAuthUser);

  return { Authorization: `${tokenType} ${accessToken}` };
};
