/* eslint-disable functional/immutable-data, no-param-reassign */
import React from 'react';

import { T } from 'ramda';
import { ConnectDragSource } from 'react-dnd';
import { ensurePluginOrder, Hooks, Row, TableInstance, useGetLatest } from 'react-table';

import { DropCollectedProps } from './Row';

const useInstance = <D extends UnknownObject>(instance: TableInstance<D>) => {
  const { rows, plugins, isRowDraggable = T, isRowDropTarget = T } = instance;

  ensurePluginOrder(plugins, ['useSortBy', 'useExpanded'], 'useRowDragAndDrop');

  const rowsWithDragAndDropData = React.useMemo(() => {
    rows.forEach(row => {
      row.isDraggable = !!isRowDraggable(row);
      row.isDropTarget = !!isRowDropTarget(row);
      row.drag = null;
    });

    return rows;
  }, [rows, isRowDraggable, isRowDropTarget]);

  const getInstance = useGetLatest(instance);

  Object.assign(instance, {
    getInstance,
    rows: rowsWithDragAndDropData,
  });
};

export type UseRowDragAndDropOptions<D extends UnknownObject> = Partial<{
  isRowDraggable: (row: Row<D>) => boolean;
  isRowDropTarget: (row: Row<D>) => boolean;
  isRowDroppable?: (targetRow: Row<D>, draggedRows: Row<D>[]) => boolean;
  onDraggableDrop?: (targetRow: Row<D>, draggedRows: Row<D>[]) => void;
  onDraggableHover?: (targetRow: Row<D>, draggedRows: Row<D>[]) => void;
  onDraggableEnd?: (targetRow: Row<D>, draggedRows: Row<D>[]) => void;
  onDraggableChange?: (
    targetRow: Row<D>,
    draggedRows: Row<D>[],
    isOver: boolean,
    isValid: boolean,
  ) => DropCollectedProps;
}>;

export type UseRowDragAndDropRowProps<D extends UnknownObject> = {
  isDraggable: boolean;
  isDropTarget: boolean;
  drag: ConnectDragSource | null;
};

export const useRowDragAndDrop = (hooks: Hooks): void => {
  hooks.useInstance.push(useInstance);
};
useRowDragAndDrop.pluginName = 'useRowDragAndDrop';
