import * as React from 'react';

import { head, tail } from '@cobbler-io/utils/src/array';
import { cx } from '@cobbler-io/utils/src/cx';
import { hash } from '@cobbler-io/utils/src/string/hash';

import { upperFirst } from 'lodash';

import styles from './UserInitial.scss';

export type ProfileIconSize =
  | 'tiny' /* 10px */
  | 'caption' /* 12px */
  | 'button' /* 14px */
  | 'body' /* 14px */
  | 'bodyLarge' /* 16px */
  | 'title' /* 20px */
  | 'headline' /* 24px */
  | 'display' /* 36px */
  | 'jumbotron' /* 48px */
  | number;

export type UserInitialProps = {
  name?: string;
  identifier?: string | null;
  firstOnly?: boolean;
  size?: ProfileIconSize;
  className?: string;
};

const styleFromIdentifier = (identifier: string | null): string => {
  if (identifier === null) {
    return styles.notAUser;
  }

  const colorOptions = Object.keys(styles).filter(key => key.endsWith('Color'));
  const hashedIdentifierIndex = hash(identifier) % colorOptions.length;

  return styles[colorOptions[hashedIdentifierIndex]];
};

export const UserInitial = ({
  identifier,
  name,
  firstOnly,
  size,
  className,
}: UserInitialProps): JSX.Element => {
  const safeName = name || '?';
  const initials = safeName
    .split(/\s+/)
    .filter(Boolean)
    .map(x => String(x[0]).toUpperCase());
  const single = firstOnly || initials.length === 1;
  const text = single ? head(initials) : `${head(initials)}${tail(initials)}`;

  return (
    <span
      className={cx(
        styleFromIdentifier(identifier || null),
        styles.userInitial,
        typeof size === 'string' && styles[`size${upperFirst(size)}`],
        !single && styles.two,
        className,
      )}
      style={typeof size === 'number' ? { fontSize: size } : {}}
      title={safeName}
    >
      <strong>{text}</strong>
    </span>
  );
};

UserInitial.displayName = 'UserInitial';

export default UserInitial;
