import * as React from 'react';

import { Button } from '@cobbler-io/core-ui/src/Button';
import { Icon } from '@cobbler-io/core-ui/src/Icon';

import { useCurrentDataGrid } from '../DataGridContext';

import styles from './FullScreenButton.scss';

type FullScreenButtonProps = {
  short?: boolean;
};

export const FullScreenButton = (props: FullScreenButtonProps): JSX.Element => {
  const { short = false } = props;
  const { isFullScreen, toggleFullScreen } = useCurrentDataGrid().getInstance() ?? {};
  return (
    <Button
      small
      className={styles.iconButtonToolbar}
      name="open-full-screen"
      variant="text"
      onClick={toggleFullScreen}
    >
      <Icon size={14} type={isFullScreen ? 'fullscreenExit' : 'fullscreen'} />
      {!short && <span>Full screen</span>}
    </Button>
  );
};

FullScreenButton.displayName = 'FullScreenButton';
