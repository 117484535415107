/* eslint-disable react/no-array-index-key */
import * as React from 'react';

import { Shimmer } from '@cobbler-io/core-ui/src/Shimmer';

import styles from './Loading.scss';

export type LoadingProps = {
  rows: number;
  columns: number;
  header?: boolean;
  footer?: boolean;
};

export const Loading = (props: LoadingProps): JSX.Element => {
  const { rows, columns, header = false, footer = false } = props;
  const cols = Array.from({ length: columns });

  return (
    <table className={styles.table}>
      {header && (
        <thead>
          <tr>
            {cols.map((_, c) => (
              <th key={c} className={styles.th}>
                <Shimmer className={styles.shimmer} />
              </th>
            ))}
          </tr>
        </thead>
      )}
      <tbody>
        {Array.from({ length: rows }).map((_, r) => (
          <tr key={r}>
            {cols.map((__, c) => (
              <td key={c} className={styles.td}>
                <Shimmer className={styles.shimmer} />
              </td>
            ))}
          </tr>
        ))}
      </tbody>
      {footer && (
        <thead>
          <tr>
            {cols.map((_, c) => (
              <td key={c} className={styles.th}>
                <Shimmer className={styles.shimmer} />
              </td>
            ))}
          </tr>
        </thead>
      )}
    </table>
  );
};
Loading.displayName = 'DataGrid__Loading';
