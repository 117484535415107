import { AggregatorFn } from 'react-table';

const isNumber = (x: any): x is number => typeof x === 'number';

const sum = (arr: any[]) =>
  arr.reduce((acc: number, num: number) => acc + (isNumber(num) ? num : 0), 0);

const min = (arr: any[]) => arr.reduce((acc: number, num) => Math.min(acc, num), Infinity);

const max = (arr: any[]) => arr.reduce((acc: number, num) => Math.max(acc, num), -Infinity);

const minMax = (arr: any) => {
  let mmin = Infinity;
  let mmax = -Infinity;
  for (let i = 0; i < arr.length; i++) {
    const num = arr[i];
    if (isNumber(num)) {
      mmin = Math.min(mmin, num);
      mmax = Math.max(mmax, num);
    }
  }

  return `${mmin}..${mmax}`;
};

const count = (arr: any) => arr.length;

const countHasValue = (arr: any[]) => arr.filter(Boolean).length;

const average = (arr: any[]) => sum(arr) / arr.length;

const mode = <T extends any>(data: T[]): T => {
  // Create a map to count each record
  const records = new Map<any, number>();
  // Count each record
  data.forEach(x => {
    records.set(x, (records.get(x) ?? 0) + 1);
  });
  // Cycle through the map to get the record that appears the most times in the map
  const [result] = Array.from(records.entries()).reduce<[T, number]>(
    (largest, [key, value]) => (!largest || value > largest[1] ? [key, value] : largest),
    [null, 0],
  );

  return result as T;
};

export const labeledCount = (arr: any[]) => {
  const counts = arr.reduce<Record<string, number>>(
    (acc, key) => ({
      ...acc,
      [key]: typeof acc[key] === 'undefined' ? 1 : acc[key] + 1,
    }),
    {},
  );

  return Object.keys(counts)
    .reduce<string[]>((acc, key) => acc.concat(`${key} (${counts[key]})`), [])
    .join(', ');
};

export const aggregations: Record<string, AggregatorFn<any>> = {
  average,
  count,
  countHasValue,
  labeledCount,
  max,
  min,
  minMax,
  mode,
  sum,
};
