import * as React from 'react';

import { useCurrentUser } from '@cobbler-io/redux/src/modules/current-user';

import { MessageTemplateParameter } from '../ParameterizedMessage';

export const UserToken = (props: MessageTemplateParameter): JSX.Element => {
  const { displayValue, resourceId } = props;
  const { id: currentUserId } = useCurrentUser();
  return <strong>{resourceId === currentUserId ? 'you' : displayValue}</strong>;
};

UserToken.displayName = 'UserToken';
