// Import the global styles first
import '@cobbler-io/core-ui/src/scss/global.scss';
import React from 'react';
import { render } from 'react-dom';

import { ClickAwayProvider } from '@cobbler-io/core-ui/src/ClickAway';
import { ErrorBoundary } from '@cobbler-io/core-ui/src/ErrorBoundary';
import { EventHandlerRegistry } from '@cobbler-io/core-ui/src/EventHandler';
import { FeatureFlagProvider } from '@cobbler-io/core-ui/src/FeatureFlag/FeatureFlagProvider';
import { MeasureScrollbar } from '@cobbler-io/core-ui/src/MeasureScrollbar';
import { ModalManager } from '@cobbler-io/core-ui/src/Modal';
import { ScrollLockProvider } from '@cobbler-io/core-ui/src/ScrollLock';

import { store } from '@cobbler-io/redux/src';
import AuthManager from '@cobbler-io/redux/src/modules/Auth/AuthManager';

import { db } from '@cobbler-io/app/src/api/graph';
import { CurrentUserProvider } from '@cobbler-io/app/src/providers/CurrentUserProvider';

import { GraphProvider } from '@cobbler-io/collection/src/Graph/react';

import { LocationProvider } from '@reach/router';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Provider } from 'react-redux';

import { InnerApp } from './App';
import { BudgetGate } from './gates/BudgetGate';
import { DataHydrationGate } from './gates/DataHydrationGate';
import { TenantSettingsGate } from './gates/TenantSettingsGate';
import { AuthProvider } from './oidc/AuthProvider';
import { Login } from './pages/Login';

if (__PROD__) {
  /* eslint-disable */
  Sentry.init({
    dsn: 'https://3d8ffcf587164970bbfabcf67a1c8cd3@o239347.ingest.sentry.io/1519599',
    integrations: [
      new BrowserTracing(),
      new Sentry.Replay({
        // Additional SDK configuration goes in here, for example:
        blockAllMedia: true,
        maskAllText: true,
      }),
    ],

    // This sets the sample rate to be 0%, which means no regular sessions will be replayable
    replaysSessionSampleRate: 0.0,

    // Make all errors replayable
    replaysOnErrorSampleRate: 1.0,

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampler: samplingContext => {
      // Examine provided context data (including parent decision, if any) along
      // with anything in the global namespace to compute the sample rate or
      // sampling decision for this transaction

      return 0.1;
    },
  });
  /* eslint-enable */
}

const createRootNode = () => {
  const node = document.createElement('div');
  node.setAttribute('id', 'root');
  return node;
};

const el = document.getElementById('root') ?? document.body.appendChild(createRootNode());

if (typeof window !== 'undefined') {
  // @ts-expect-error: this is debug code
  window.__GRAPH__ = db; // eslint-disable-line
}

/* eslint-disable react/jsx-max-depth */
const Application = () => (
  <React.StrictMode>
    <GraphProvider graph={db}>
      <EventHandlerRegistry>
        <ClickAwayProvider>
          <ScrollLockProvider>
            {/* <CoreKitchenSinkProvider> */}
            <DndProvider backend={HTML5Backend}>
              <ModalManager>
                {/* Redux Provider */}
                <Provider store={store}>
                  <MeasureScrollbar />
                  <AuthProvider>
                    <ErrorBoundary>
                      {/* Authentication Provider (Contains Apollo) -- DEPRECATED */}
                      <AuthManager Login={Login}>
                        <TenantSettingsGate>
                          <LocationProvider>
                            <BudgetGate>
                              <CurrentUserProvider>
                                <ErrorBoundary>
                                  <FeatureFlagProvider>
                                    <DataHydrationGate>
                                      <InnerApp />
                                    </DataHydrationGate>
                                  </FeatureFlagProvider>
                                </ErrorBoundary>
                              </CurrentUserProvider>
                            </BudgetGate>
                          </LocationProvider>
                        </TenantSettingsGate>
                      </AuthManager>
                    </ErrorBoundary>
                  </AuthProvider>
                </Provider>
              </ModalManager>
            </DndProvider>
            {/* </CoreKitchenSinkProvider> */}
          </ScrollLockProvider>
        </ClickAwayProvider>
      </EventHandlerRegistry>
    </GraphProvider>
  </React.StrictMode>
);
/* eslint-enable react/jsx-max-depth */

Application.displayName = 'Application';

render(<Application />, el);
