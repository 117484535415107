import * as React from 'react';

import { isFunction } from '@cobbler-io/utils/src/isFunction';
import { isNull } from '@cobbler-io/utils/src/isNull';

export const getDocument = (ref?: React.RefObject<any>) => {
  if (ref && ref.current) {
    return ref.current.ownerDocument || document;
  }

  return document;
};

/**
 * Calls a `focus` function on a ref object if current exists and the function exists
 */
export const maybeFocusRef = (ref?: React.RefObject<any>) => {
  if (ref && ref.current && isFunction(ref.current.focus)) {
    ref.current.focus();
  }
};

export const useRefFocus = (ref: React.RefObject<any>) => {
  React.useLayoutEffect(() => {
    maybeFocusRef(ref);
  });
};

/*
// This is blowing up jest for some reason
// TODO Expand this to everything appropriate
const focusable = [HTMLButtonElement, HTMLTextAreaElement, HTMLSelectElement, HTMLInputElement];
*/

/**
 * Checks if a node is focusable
 */
/*
export const isNodeFocusable = (node: HTMLElement) => {
  // If it's a form element, then it's focusable
  if (focusable.some(type => node instanceof type) && !node.getAttribute('disabled')) {
    return true;
  }

  // It's focusable if it has a tab index
  if (!isNull(node.getAttribute('tabIndex'))) {
    return true;
  }

  // It's focusabble if it's a contenteditable
  if (!isNull(node.getAttribute('contentEditable'))) {
    return true;
  }

  return false;
};
*/

export const getPageTitle = (): string => (document ? document.title : '');
