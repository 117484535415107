import { OwnerUser, UserType } from '../shared-types/user';
import { isOwnerUser } from './isOwnerUser';

/**
 * Gets an owner from an `Owner` key.
 *
 * If the owner is an `OwnerLiteral`, then it returns the string value
 * If the owner is a `UserType`, then it returns the UserType
 * Else, returns `null`
 */
export const getOwner = (owner?: OwnerUser | null): string | UserType | null => {
  if (isOwnerUser(owner)) {
    return owner.item ?? owner.itemOrDeleted ?? null;
  }

  return null;
};
