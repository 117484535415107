/* eslint-disable max-lines-per-function, jsx-a11y/label-has-associated-control */
import * as React from 'react';
import { unstable_batchedUpdates as batch } from 'react-dom';

import { cx } from '@cobbler-io/utils/src';

import { useToggle } from '@cobbler-io/hooks/src';
import { useSaveAs } from '@cobbler-io/hooks/src/useSaveAs';

import { Button } from '@cobbler-io/core-ui/src/Button';
import { FileField } from '@cobbler-io/core-ui/src/FileField';
import { Icon } from '@cobbler-io/core-ui/src/Icon';
import { useCurrentModal } from '@cobbler-io/core-ui/src/Modal';
import { useNotification } from '@cobbler-io/core-ui/src/Notification';
import { ProgressBar } from '@cobbler-io/core-ui/src/ProgressBar';

import { useOverplanningThreshold } from '@cobbler-io/redux/src/modules/tenant-settings';

import { extractGraphQLErrors } from '@cobbler-io/app/src/api';
import {
  UpsertBudgetLineInputType, useUpsertChildBudgetLinesMutation,
} from '@cobbler-io/app/src/api/graphql-types';
import { BudgetLineAtRevision } from '@cobbler-io/app/src/ndm/components/BudgetLineEditor/types';

import { prop } from 'ramda';

import { useCurrentEditorData } from '../BudgetLineEditor/useCurrentEditorData';
import { BudgetLineUploaderStep } from './BudgetLineUploaderStep';
import { BudgetLineUploaderPreview } from './BudgetLineUploadPreview';
import { SkippedLine } from './SkippedLine';
import { SkippedBudgetLineValue } from './types';
import { useBudgetLineUploader } from './useBudgetLineUploader';

import styles from './BudgetLineUploader.scss';

export type BudgetLineUploaderProps = {
  parentBudgetLine: BudgetLineAtRevision;
};

export const BudgetLineUploader = (props: BudgetLineUploaderProps): JSX.Element => {
  const { parentBudgetLine } = props;
  const { refetch, variables } = useCurrentEditorData();
  const { close } = useCurrentModal();
  const [file, setFile] = React.useState<File | null>(null);
  const [lines, setLines] = React.useState<UpsertBudgetLineInputType[] | null>(null);
  const [error, setError] = React.useState<string | null>(null);
  const [skipped, setSkipped] = React.useState<readonly SkippedBudgetLineValue[]>([]);
  const [upsertLines] = useUpsertChildBudgetLinesMutation();
  const {
    active: isUploading,
    activate: startUploading,
    deactivate: stopUploading,
  } = useToggle(false);
  const {
    active: isDragging,
    activate: startDragging,
    deactivate: stopDragging,
  } = useToggle(false);
  const notify = useNotification();
  const saveAs = useSaveAs();
  const { getBudgetPlansFromFile, generateCSVTemplateBlob } =
    useBudgetLineUploader(parentBudgetLine);
  const resolution = 'MONTH';
  const threshold = useOverplanningThreshold();

  const handleDragIn = (e: React.DragEvent<HTMLDivElement>) => {
    if (!isUploading && !file) {
      e.preventDefault();
      e.stopPropagation();
      startDragging();
    }
  };

  const handleDragOut = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    stopDragging();
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    stopDragging();
    if (e.dataTransfer.files instanceof FileList) {
      batch(() => {
        setError(null);
        setFile(e.dataTransfer.files[0] || null);
      });
    }
  };

  const handleFileSelected = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.currentTarget.files instanceof FileList) {
      // Only grab the first file, in case multiple files were selected
      const firstFile = e.currentTarget.files[0];
      batch(() => {
        setError(null);
        setFile(firstFile);
      });
    }
  };

  React.useEffect(() => {
    if (file) {
      getBudgetPlansFromFile(file)
        .then(setLines)
        .catch(err => {
          console.error(err);
          batch(() => {
            setFile(null);
            stopUploading();
            setError(`We can't recognize the format of your budget. ${err}`);
          });
        });
    }
    // This is fine, getBudgetPlansFromFile shouldn't change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  const startTemplateDownload = () => {
    const filename = `cobbler-upload-template-${resolution.toLocaleLowerCase()}.csv`;
    const fallback = '/cobbler-upload-template-month.csv';
    saveAs(generateCSVTemplateBlob(), filename, fallback);
  };

  const onSubmit = async (childBudgetLines: UpsertBudgetLineInputType[]) => {
    startUploading();
    try {
      const { data } = await upsertLines({
        variables: {
          parentId: parentBudgetLine.id,
          revisionId: parentBudgetLine.revisionId,
          lines: childBudgetLines,
        },
      });

      const skippedValues = data?.a_upsertChildBudgetLines?.skippedBudgetLineValues ?? [];

      notify({
        title: 'Upload successful',
        body: `Your budget will be updated in a few seconds.`,
      });

      await refetch(variables);

      batch(() => {
        setSkipped(skippedValues);
        stopUploading();
      });

      if (!skippedValues?.length) {
        close();
      }
    } catch (err) {
      const gqlErrors = extractGraphQLErrors(err);
      console.error(gqlErrors);
      batch(() => {
        setFile(null);
        stopUploading();
        setError(
          `An error occurred while uploading your budget. ${gqlErrors
            .map(prop('message'))
            .join(' | ')}`,
        );
        setLines(null);
      });
      refetch(variables);
    }
  };

  const onRestart = () => {
    batch(() => {
      setFile(null);
      stopUploading();
      setError(null);
      setLines(null);
    });
  };

  // The UI will only show one of these states at once.
  const isError = !isUploading && error;
  const isReady = !isUploading && !error && !lines;
  const isPreview = !isUploading && !error && !!lines && !skipped.length;
  const isSummary = !isUploading && !error && skipped.length > 0;

  return (
    <div
      className={styles.budgetLineUploader}
      onDragEnter={handleDragIn}
      onDragOver={handleDragIn}
      onDrop={handleDrop}
    >
      {/* Dragging overlay: This shows on top of anything the uploader is displaying */}
      {isDragging && (
        <div className={styles.draggingOverlay} onDragLeave={handleDragOut}>
          <Icon type="reports" />
          <h2>Drop your file here</h2>
        </div>
      )}

      {isReady && (
        <BudgetLineUploaderStep title="Upload a Budget">
          <label className={styles.ready} htmlFor="budget-line-upload">
            <img alt="" src="/upload-illustration.svg" />
          </label>
        </BudgetLineUploaderStep>
      )}

      {isUploading && (
        <BudgetLineUploaderStep title="Uploading…">
          <div className={styles.uploading}>
            <img alt="" src="/uploading-illustration.svg" />
          </div>

          <div className={styles.fakeProgress}>
            <ProgressBar hideText max={100} value={1} />
          </div>
        </BudgetLineUploaderStep>
      )}

      {isPreview && (
        <BudgetLineUploaderStep
          subtitle="This is the data you are about to upload."
          title="Preview"
        >
          <BudgetLineUploaderPreview
            allowOverplanning
            budgetLineAtRevision={parentBudgetLine}
            lines={lines}
            overplanningThreshold={threshold}
            onCancel={onRestart}
            onSubmit={onSubmit}
          />
        </BudgetLineUploaderStep>
      )}

      {isError && (
        <BudgetLineUploaderStep subtitle={error} title="Uh oh…">
          <label htmlFor="budget-line-upload">
            <img alt="" src="/upload-error-illustration.svg" />
          </label>
        </BudgetLineUploaderStep>
      )}

      {isSummary && (
        <BudgetLineUploaderStep
          subtitle="Your budget was uploaded successfully but the following values were skipped:"
          title="Summary"
        >
          <div className={styles.summaryItems}>
            {skipped.map(
              ({ budgetLineId, budgetLineName, ownerEmail, accountCodes, departmentNames }) => (
                <SkippedLine
                  key={budgetLineId}
                  accounts={accountCodes}
                  departments={departmentNames}
                  name={budgetLineName}
                  owner={ownerEmail}
                />
              ),
            )}
          </div>

          <div className={styles.summaryActions}>
            <Button name="upload-summary-ok" onClick={close}>
              Ok
            </Button>
          </div>
        </BudgetLineUploaderStep>
      )}

      {/* Footer upload controls: This show at the bottom of anything if no file is selected */}
      {!file && (
        <div className="row">
          <div className={cx('col', 'small-12 medium-7', 'align-left', 'center', styles.template)}>
            <p>Your file must match the format of our template.</p>
            <p>
              Use <code>;</code> or <code>|</code> to separate multiple values in the “Account
              Codes” or “Vendors” columns.
            </p>
            <div>
              <Button
                small
                name="download-csv-template"
                variant="text"
                onClick={startTemplateDownload}
              >
                Download CSV template
              </Button>
            </div>
          </div>

          <div className="col small-12 medium-5 align-right center">
            <FileField
              hideFileName
              id="budget-upload"
              label="Upload csv file"
              name="upload-budget"
              onChange={handleFileSelected}
            />
          </div>
        </div>
      )}
    </div>
  );
};
BudgetLineUploader.displayName = 'BudgetLineUploader';

export default BudgetLineUploader;
