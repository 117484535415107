import * as React from 'react';

import { Row, TableInstance } from 'react-table';

import { useCurrentDataGrid } from './DataGridContext';

export type SelectedRowsProps<D extends UnknownObject> = {
  className?: string;
  singular?: string;
  plural?: string;
  // eslint-disable-next-line react/boolean-prop-naming
  hideWhenZero?: boolean;
  children?: (selectedRows: Row<D>[], instance: TableInstance<D>) => JSX.Element;
};

export const SelectedRows = <D extends UnknownObject>(props: SelectedRowsProps<D>): JSX.Element => {
  const { className, children, singular, plural, hideWhenZero = false } = props;
  const { getInstance } = useCurrentDataGrid();
  const instance = getInstance() as unknown as TableInstance<D>;
  const count = instance?.selectedFlatRows?.length ?? 0;

  // Render prop
  if (typeof children === 'function') {
    return children(instance?.selectedFlatRows, instance);
  }

  if (!count && hideWhenZero) {
    // @ts-expect-error: This is fine for React
    return null;
  }

  if (count === 1) {
    return (
      <span className={className}>
        {count}
        {!!singular && ` ${singular}`}
      </span>
    );
  }

  if (!singular) {
    return <span className={className}>{count}</span>;
  }

  return (
    <span className={className}>
      {count} {plural ? ` ${plural}` : ` ${singular}s`}
    </span>
  );
};
SelectedRows.displayName = 'SelectedRows';
