import { ModalTreeNode } from './types';

/**
 * Pre-order, depth first traversal
 */
export const getDescendantTreeNodes = (node: ModalTreeNode): ModalTreeNode[] =>
  Array.from(node.children.values()).reduce<ModalTreeNode[]>(
    (nodes, child) => nodes.concat(child, getDescendantTreeNodes(child)),
    [],
  );
