import { changeNativeValue } from '@cobbler-io/utils/src/changeNativeValue';

import { simpleMoney } from '@cobbler-io/formatters/src/numbers';

export const pasteCellsIntoValues = (ref: React.RefObject<HTMLDivElement>) => (
  event: React.ClipboardEvent<HTMLInputElement>,
) => {
  const source = event.clipboardData;
  if (!source) {
    return;
  }

  const [row] = source
    ?.getData('text') // what other formats might there be?
    .split('\n') // split into rows
    .map(x => x.split('\t')); // split into columns;

  if (ref.current) {
    const inputs = Array.from(ref.current.getElementsByTagName('INPUT')) as HTMLInputElement[];

    if (
      // Only paste if there is a value that can go into each "cell"
      inputs.length === row.length &&
      // Only paste if you're pasting in the first input
      event.target === inputs[0] &&
      inputs.every(i => i instanceof HTMLInputElement)
    ) {
      event.preventDefault();

      // This will also guard against pasting $3.21 as it will round to $3.
      const values = row.map(simpleMoney);

      inputs.forEach((input, index) => changeNativeValue(input, values[index]));
    }
  }
};
