/* eslint-disable max-lines-per-function */
import {
  getSheetByIndex, getWorkBookFromFile, Sheet,
} from '@cobbler-io/utils/src/parseSpreadsheet';
import { upperFirst } from '@cobbler-io/utils/src/string';

import { useCurrencyFormatter } from '@cobbler-io/redux/src/modules/currency';

import {
  PlannedSpendInputType, UpsertBudgetLineInputType,
} from '@cobbler-io/app/src/api/graphql-types';

import { BudgetLineAtRevision } from '../BudgetLineEditor/types';
import { BudgetLineAtRevisionInterval } from './types';
import {
  intervalToPeriod, isValidRecord, joinByKey, periodToIntervalStart, safeNumber, safeSplit,
  safeString, sanitizeColumns, splitColumns,
} from './utils';

const validColumns = [
  /* 0 */ /^(id)(\s|$)/i,
  /* 1 */ /^(name)(\s|$)/i,
  /* 2 */ /^(vendors)(\s|$)/i,
  /* 3 */ /^(owner email)(\s|$)/i,
  /* 4 */ /^(department)(\s|$)/i,
  /* 5 */ /^(account codes)(\s|$)/i,
];

// TODO: initialize with import rules (e.g. col map, etc).
export const useForecastUploader = (budgetLineAtRevision?: BudgetLineAtRevision) => {
  const { convertToMinorUnit, convertFromMinorUnit } = useCurrencyFormatter();

  if (!budgetLineAtRevision) {
    return null;
  }

  const createBudgetLine =
    (intervalStarts: BudgetLineAtRevisionInterval['start'][]) =>
    (record: unknown[]): UpsertBudgetLineInputType => {
      // TODO: Stop hardcoding record indexes and use a map (that later will be
      // provided by the user).

      // Note: When upserting:
      //  - [] & '' delete the current record
      //  - null keep current value/ignore
      const id = safeString(record[0]) || null;
      const name = String(record[1]);
      const vendorNames = safeSplit(record[2]);
      const ownerUserEmail = safeString(record[3]);
      const departmentNames = safeSplit(record[4]);
      const accountCodes = safeSplit(record[5]);
      const plannedSpends: PlannedSpendInputType[] = intervalStarts.map((start, i) => ({
        planned: convertToMinorUnit(safeNumber(record[i + validColumns.length])),
        start,
      }));

      return {
        accountCodes, // [String!]
        departmentNames, // [String!]
        id, // ID
        name, // String!
        ownerUserEmail, // String
        plannedSpends, // [PlannedSpendInputType!];
        vendorNames, // [String!]
      };
    };

  const createBudgetLines =
    () =>
    ({ header, records }: Sheet): UpsertBudgetLineInputType[] => {
      const { periods } = splitColumns(header, validColumns.length);
      const intervalStarts = periods.map(periodToIntervalStart);
      return records.filter(isValidRecord).map(createBudgetLine(intervalStarts));
    };

  const getBudgetLinesFromFile = async (file: File): Promise<UpsertBudgetLineInputType[]> =>
    getWorkBookFromFile(file, { raw: true })
      .then(getSheetByIndex(0))
      .then(sanitizeColumns(budgetLineAtRevision, validColumns))
      .then(createBudgetLines());

  const generateCSVTemplateBlob = () => {
    const columnTitles = ['Id', 'Name', 'Vendors', 'Owner Email', 'Department', 'Account Codes'];
    const intervalColumnTitles = budgetLineAtRevision.intervals.map(
      interval => `${upperFirst(intervalToPeriod(interval))} Planned`,
    );

    const header = [...columnTitles, ...intervalColumnTitles].join(',');

    const data = budgetLineAtRevision.lines.map(line => {
      const amounts = budgetLineAtRevision.intervals.map(interval =>
        convertFromMinorUnit(line.plannedByInterval[interval.start]?.allocated ?? 0),
      );

      return [
        line.id, // Id
        `"${line.name}"`, // Name
        `"${joinByKey('name', line.vendors)}"`, // Vendors
        `"${line.owner?.email ?? ''}"`, // Owner Email
        `"${joinByKey('name', line.departments)}"`, // Department
        `"${joinByKey('displayCode', line.accounts)}"`, // Account Codes
        ...amounts,
      ].join(',');
    });

    const csv = [header, ...data].join('\n');
    return new Blob([csv], { type: 'text/csv;charset=utf-8' });
  };

  return {
    generateCSVTemplateBlob,
    getBudgetLinesFromFile,
  };
};
