/**
 * Coerces a value to a number. Returns `0` if coercion leads to `NaN`
 */
export const toInteger = (num: string | number): Int => {
  if (typeof num === 'number') {
    return num;
  }

  const int = parseInt(num, 10);

  return Number.isNaN(int) ? 0 : int;
};
