/* eslint-disable sort-keys */
/* eslint-disable sort-keys-fix/sort-keys-fix */
/**
 * TODO Sort out how we store values... right now they are wrapped in Items
 */
import * as React from 'react';

import { clamp } from '@cobbler-io/utils/src';
import { arraysAreEqual } from '@cobbler-io/utils/src/array/arraysAreEqual';
import { head } from '@cobbler-io/utils/src/array/head';
import { cx } from '@cobbler-io/utils/src/cx';
import { isFunction } from '@cobbler-io/utils/src/isFunction';
import { prop } from '@cobbler-io/utils/src/prop';
import { rando } from '@cobbler-io/utils/src/rando';

import { useExtendedReducer } from '@cobbler-io/hooks/src/useExtendedReducer';

import { equals, includes } from 'ramda';

import {
  Action,
  createActions,
  DropdownActions,
  getInitialState,
  reducer,
  State,
} from './dropdownReducer';
import { Item } from './Item';

type UseDropdownParamsBase<TValue extends any = any> = {
  defaultActiveIndex?: number;
  activeIndex?: number;

  open?: boolean;
  defaultOpen?: boolean;

  preventClearFilterOnEscape?: boolean;
  preventClearFilterOnSelect?: boolean;
  preventCloseOnSelect?: boolean;
  preventOpenOnFocus?: boolean;
  preventFiltering?: boolean;

  readonly?: boolean;
  disabled?: boolean;
  required?: boolean;

  autoFocus?: boolean;
  id: string;

  multiple?: boolean;

  items: Item<TValue>[];

  filter?: string;
  defaultFilter?: string;

  selected?: Item<TValue>[];
  defaultSelected?: Item<TValue>[];

  placeholder?: string;

  /**
   * Used for `aria-roledescription`
   */
  description?: string;

  adjustActiveItems?: (input: string, items: Item<TValue>[]) => Item<TValue>[];

  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => any;
};

type UseDropdownParamsSingle<TValue extends any = any> = {
  multiple?: false;
  onChange?: (value: TValue) => any;
};

type UseDropdownParamsMultiple<TValue extends any = any> = {
  multiple: true;
  onChange?: (value: TValue[]) => any;
};

export type UseDropdownParams<TValue extends any = any> = UseDropdownParamsBase<TValue> &
  XOR<UseDropdownParamsSingle<TValue>, UseDropdownParamsMultiple<TValue>>;

const createOnChangeAfterware =
  <TValue>(params: UseDropdownParams<TValue>) =>
  (_: any, prev: State<TValue>, current: State<TValue>): any => {
    if (!params.onChange) {
      return null;
    }

    if (prev.selected === current.selected) {
      return null;
    }

    const prevValues: TValue[] = prev.selected.map(prop('value'));
    const nextValues: TValue[] = current.selected.map(prop('value'));

    if (arraysAreEqual(prevValues, nextValues)) {
      return null;
    }

    if (params.multiple) {
      return params.onChange(nextValues);
    }

    return params.onChange(head(nextValues));
  };

type DropdownRefs = {
  input: React.RefObject<HTMLInputElement>;
  comboBox: React.RefObject<HTMLDivElement>;
  listBox: React.RefObject<HTMLDivElement>;
};

type ComboBoxProps = {
  id: string;
  'aria-expanded': boolean;
  'aria-haspopup': 'listbox';
  'aria-owns': string;
  'aria-disabled': boolean;
  'aria-required': boolean;
  'aria-roledescription': string | undefined;
  ref: React.RefObject<HTMLDivElement>;
  role: string;
  tabIndex: number;
};

type ListBoxProps = {
  id: string;
  'aria-activedescendant': string;
  'aria-multiselectable': boolean;
  'aria-readonly': boolean;
  'data-ephemeral': boolean;
  role: string;
  tabIndex: number;
};

type InputProps = {
  id: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  autoFocus: boolean;
  autoComplete: string;
  placeholder: string | undefined;
  ref: React.RefObject<HTMLInputElement>;
  name: string;
  value: string;
  readOnly: boolean;
  disabled: boolean;
  onFocus: NullaryFn;
  onClick: NullaryFn;
  onBlur: NullaryFn;
  onKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  'aria-multiline': boolean;
  'aria-autocomplete': 'list';
  'aria-controls': string;
  'aria-disabled': boolean;

  // more...
};

type ToggleButtonProps = {
  id: string;
  type: string;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  open: boolean;
  'aria-label': string;
  tabIndex: number;
};

type ClearButtonProps = {
  id: string;
  type: string;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  'aria-label': string;
  tabIndex: number;
};

type ItemProps<TValue> = {
  id: string;
  key: string;
  label: string | JSX.Element;
  active: boolean;
  title: string;
  'aria-label': string;
  'aria-selected': boolean;
  role: 'option';
  selected: boolean;
  value: TValue;
  disabled: boolean;
  onMouseOver: React.MouseEventHandler<HTMLLIElement>;
  onMouseDown: React.MouseEventHandler<HTMLLIElement>;
  onClick: React.MouseEventHandler<HTMLLIElement>;
};

type UseDropdownHook<TValue extends any = any> = {
  getComboBoxProps: () => ComboBoxProps;
  getListboxProps: () => ListBoxProps;
  getInputProps: () => InputProps;
  getToggleButtonProps: () => ToggleButtonProps;
  getClearButtonProps: () => ClearButtonProps;
  getItemProps: (item: Item, index: number) => ItemProps<TValue>;
  refs: DropdownRefs;
} & State<TValue> &
  DropdownActions<TValue> &
  UseDropdownParams<TValue>;

/* eslint-disable max-lines-per-function */
export const useDropdown = <TValue extends any = any>(
  params: UseDropdownParams<TValue>,
): UseDropdownHook<TValue> => {
  const afterware = React.useMemo(() => createOnChangeAfterware(params), [params]);
  const { getState, dispatch } = useExtendedReducer<State<TValue>, Action<TValue>>({
    reducer,
    initialState: getInitialState(params),
    afterware,
  });
  const state = getState();
  const actions = React.useMemo(() => createActions(dispatch), []);

  const inputRef = React.useRef<HTMLInputElement>(null);
  const comboBoxRef = React.useRef<HTMLDivElement>(null);
  const listBoxRef = React.useRef<HTMLDivElement>(null);

  const {
    description,
    id,
    placeholder,
    autoFocus = false,
    preventClearFilterOnEscape = false,
    preventClearFilterOnSelect = false,
    preventCloseOnSelect = false,
    preventFiltering = false,
    preventOpenOnFocus = false,
    disabled = false,
    multiple = false,
    readonly = false,
    required = false,
    onKeyDown: userOnKeyDown,
  } = params;

  React.useEffect(() => {
    if (!multiple && state.selected.length > 1) {
      actions.setSelected([state.selected[0]]);
    }
  }, [multiple]);

  // This effect is used to convert the component into a controlled component
  React.useEffect(() => {
    if (params.selected && !equals(state.selected, params.selected)) {
      actions.setSelected(params.selected);
    }
  }, [params.selected]);

  React.useEffect(() => {
    if (params.items && !equals(state.items, params.items)) {
      actions.replaceItems(params.items);
    }
  }, [params.items]);

  const getComboBoxProps = (): ComboBoxProps => ({
    id,

    /* Aria props */
    'aria-expanded': state.open,
    'aria-haspopup': 'listbox' as const,
    'aria-owns': cx(`${id}-textbox`), // todo: fill out this list
    'aria-disabled': disabled,
    'aria-required': required,
    'aria-roledescription': description,
    ref: comboBoxRef,
    role: 'combobox',
    tabIndex: -1, // @todo check this
  });

  const getListboxProps = (): ListBoxProps => ({
    id: `${id}-listbox`,

    /* Aria props */
    'aria-activedescendant': state.open ? state.activeItems[state.activeIndex]?.id : '',
    'aria-multiselectable': multiple,
    'aria-readonly': readonly,
    'data-ephemeral': true, // See note in `ClickAway`
    role: 'listbox',
    tabIndex: -1, // @todo check this
  });

  const autoCompleteRef = React.useRef<string>(rando());

  const getInputProps = (): InputProps => ({
    id: `${id}-textbox`,
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
      actions.setFilter(event.currentTarget.value);
    },
    autoFocus,
    autoComplete: autoCompleteRef.current,
    placeholder,
    ref: inputRef,
    name: autoCompleteRef.current,
    value: preventFiltering ? '' : state.filter || '',
    readOnly: readonly || preventFiltering,
    disabled,
    onFocus: () => {
      !preventOpenOnFocus && !disabled && !readonly && actions.openList();
    },
    onClick: () => {
      !preventOpenOnFocus && !disabled && !readonly && actions.openList();
    },
    onBlur: () => actions.clearFilter(),
    // eslint-disable-next-line complexity
    onKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => {
      const { key, metaKey } = event;
      const { activeIndex, selected, filter, activeItems } = state;
      const {
        openList,
        closeList,
        setActiveIndex,
        clearFilter,
        removeSelected,
        toggleSelected,
        setSelected,
      } = actions;
      const bounds = { min: 0, max: activeItems.length - 1 };

      if (!state.open) {
        switch (key) {
          case 'ArrowDown':
            event.preventDefault();
            event.stopPropagation();
            openList();
            break;
          case 'ArrowUp':
            event.preventDefault();
            event.stopPropagation();
            setActiveIndex(activeItems.length - 1);
            openList();
            break;
          case 'Backspace':
            // Should we do this only for chip variants?
            if (!filter && selected.length && multiple) {
              removeSelected(selected[selected.length - 1]);
              event.preventDefault();
            }
            break;
          case 'Escape':
            if (!preventClearFilterOnEscape) {
              clearFilter();
            }
            break;
          default:
          // noop
        }

        if (key.length === 1 && /[a-z0-9]/iu.test(key)) {
          openList();
          setActiveIndex(0);
        }

        if (userOnKeyDown) {
          // Run any user provided onKeyDown handlers after we have done ours.
          // Note, user keydown handlers should also pay attention to `event.defaultPrevented`
          // to see if they should act on the event
          userOnKeyDown(event);
        }

        return;
      }

      switch (key) {
        case 'ArrowDown':
          event.preventDefault();
          event.stopPropagation();
          if (!disabled) {
            metaKey
              ? setActiveIndex(activeItems.length - 1)
              : setActiveIndex(clamp(bounds, activeIndex + 1));
          }

          break;
        case 'ArrowUp':
          event.preventDefault();
          event.stopPropagation();
          if (!disabled) {
            metaKey ? setActiveIndex(0) : setActiveIndex(clamp(bounds, activeIndex - 1));
          }

          break;
        // case ' ': // Space character: breaks multi-word entries
        case 'Enter':
          if (
            disabled ||
            readonly ||
            !activeItems[activeIndex] ||
            activeItems[activeIndex]?.disabled
          ) {
            return;
          }

          event.stopPropagation();
          event.preventDefault();

          multiple
            ? toggleSelected(activeItems[activeIndex])
            : setSelected([activeItems[activeIndex]]);
          !preventCloseOnSelect && closeList();
          !preventClearFilterOnSelect && clearFilter();

          if (!multiple) {
            inputRef.current?.focus();
          }

          break;
        case 'Escape':
          actions.closeList();
          !preventClearFilterOnEscape && clearFilter();
          event.stopPropagation();
          event.preventDefault();
          break;
        case 'Tab':
          // Native selects just stop tab from doing anything when the window is open
          event.stopPropagation();
          event.preventDefault();
          break;
        default:
        // noop
      }

      if (userOnKeyDown) {
        // Run any user provided onKeyDown handlers after we have done ours.
        // Note, user keydown handlers should also pay attention to `event.defaultPrevented`
        // to see if they should act on the event
        userOnKeyDown(event);
      }
    },
    /* Aria props */
    'aria-multiline': false,
    'aria-autocomplete': 'list' as const, // list | none
    'aria-controls': `${id}-listbox`,
    'aria-disabled': disabled,
  });

  const getToggleButtonProps = (): ToggleButtonProps => ({
    id: `${id}-toggle`,
    type: 'button',
    onClick: (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      event.stopPropagation();
      if (!disabled) {
        actions.toggleList();
        inputRef.current?.focus();
      }
    },
    open: state.open,
    /* Aria props */
    'aria-label': 'toggle listbox',
    /**
     * Excluded from tabbable interfaces
     *
     * @see https://www.w3.org/TR/wai-aria-practices/#keyboard-interaction-6
     */
    tabIndex: -1,
  });

  const getClearButtonProps = (): ClearButtonProps => ({
    id: `${id}-clear`,
    type: 'button',
    onClick: (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      event.stopPropagation();
      if (!disabled && !readonly) {
        actions.clearSelected();
        actions.clearFilter();
      }
    },

    /* Aria props */
    'aria-label': 'clear selected',
    /**
     * Excluded from tabbable interfaces
     *
     * @see https://www.w3.org/TR/wai-aria-practices/#keyboard-interaction-6
     */
    tabIndex: -1,
  });

  const getItemProps = (item: Item<TValue>, index: number): ItemProps<TValue> => {
    const renderedLabel = isFunction(item.label) ? item.label(item.value) : item.label;
    const accessibleLabel: string = ('search' in item ? item.search : renderedLabel) as string;
    const { toggleSelected, setSelected, closeList } = actions;

    const isSelected = includes(item, state.selected);

    return {
      id: item.id,
      key: item.id,
      label: renderedLabel,
      active: index === state.activeIndex, // TODO make this easier to spread into the component
      title: accessibleLabel,
      'aria-label': accessibleLabel,
      'aria-selected': isSelected,
      role: 'option' as const,
      selected: isSelected,
      value: item.value,
      disabled: Boolean(item.disabled),
      onMouseOver: () => actions.setActiveIndex(index),
      onMouseDown: (event: React.MouseEvent<HTMLLIElement>) => {
        event.stopPropagation();
        event.preventDefault();
      },
      onClick: (event: React.MouseEvent<HTMLLIElement>) => {
        event.stopPropagation();
        event.preventDefault();
        if (!readonly && !disabled && !item.disabled) {
          multiple ? toggleSelected(item) : setSelected([item]);
          !preventCloseOnSelect && closeList();
          !preventClearFilterOnSelect && actions.clearFilter();
        }
      },
    };
  };

  return {
    ...params,
    getComboBoxProps,
    getListboxProps,
    getInputProps,
    getToggleButtonProps,
    getClearButtonProps,
    getItemProps,
    refs: { input: inputRef, comboBox: comboBoxRef, listBox: listBoxRef },
    ...state,
    ...actions,
  };
};

/* eslint-enable max-lines-per-function */
