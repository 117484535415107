/* eslint-disable prefer-object-spread */
import { MutableRefObject, RefObject } from 'react';

import { getValueFromRef } from '@cobbler-io/utils/src/getValueFromRef';

// Popper Modifier that makes the element the same with as the Popper
import {
  createPopper, Instance as Popper, Options as PopperOptions, VirtualElement,
} from '@popperjs/core';
import { omit } from 'ramda';

import { sameHeight } from './sameHeight';
import { sameWidth } from './sameWidth';

type Options = PopperOptions & {
  /*
   * Sets the popper element to the same height as the reference element
   */
  sameHeight?: boolean;
  /*
   * Sets the popper element to the same width as the reference element
   */
  sameWidth?: boolean;
};

type PopperElement = Element | VirtualElement | null;

export type MakePopperParams = {
  /**
   * This is the reference object that the Popper is in relation to
   */
  target?: Element | RefObject<Element> | VirtualElement | RefObject<VirtualElement> | null;
  /**
   * This is the element that needs to be positioned
   */
  popper?: HTMLElement | RefObject<HTMLElement> | null;
  /**
   * Override the defaults or mix in with `DEFAULT_POPPER_OPTIONS`
   */
  options?: Partial<Options>;
  /**
   * We'll create the popper on this ref
   */
  ref: MutableRefObject<Popper | null>;
};

const OVERFLOW_PADDING_X = 5;
const OVERFLOW_PADDING_Y = 10;
const OFFSET_SKIDDING = 0;
const OFFSET_DISTANCE = 8;

export const DEFAULT_POPPER_MODIFIERS = [
  {
    name: 'preventOverflow',
    options: {
      padding: {
        top: OVERFLOW_PADDING_Y,
        right: OVERFLOW_PADDING_X,
        bottom: OVERFLOW_PADDING_Y,
        left: OVERFLOW_PADDING_X,
      },
    },
  },
  { name: 'offset', options: { offset: [OFFSET_SKIDDING, OFFSET_DISTANCE] } },
];

export const defaultOptions: PopperOptions = {
  placement: 'bottom-start',
  strategy: 'fixed',
  modifiers: DEFAULT_POPPER_MODIFIERS,
};

export const getDefaultOptions = (options: Partial<Options> = {}): PopperOptions => {
  const opts = Object.assign({}, defaultOptions, omit(['sameWidth', 'sameHeight'], options));
  options.sameWidth && opts.modifiers.push(sameWidth);
  options.sameHeight && opts.modifiers.push(sameHeight);
  return opts;
};

export const makePopper = (params: MakePopperParams): MutableRefObject<Popper | null> => {
  const { target, popper, options, ref } = params;

  if (ref.current) {
    // If it's already been made, then, well, return it
    return ref;
  }

  if (target && popper && !ref.current) {
    const actualTarget = getValueFromRef<PopperElement>(target);
    const actualPopper = getValueFromRef(popper);
    if (actualTarget && actualPopper) {
      ref.current = createPopper(actualTarget, actualPopper, getDefaultOptions(options));
      return ref;
    }
  }

  return ref;
};
