import * as React from 'react';

import { DateRange } from '@cobbler-io/dates/src/DateRange';

import { DatePickerSelection } from './DatePickerContext';

type DatePickerStateParams = {
  decrementPage: (date: Date) => Date;
  decrementValue: (date: Date) => Date;
  end: Date | null;
  formatDate: (date: Date | null) => any;
  incrementPage: (date: Date) => Date;
  incrementValue: (date: Date) => Date;
  max: Date;
  min: Date;
  onChange: (range: [start: string, end: string | null]) => any;
  range: boolean;
  start: Date | null;
  viewDate: Date;
};

type DatePickerState = {
  decrementPage: (date: Date) => Date;
  decrementValue: (date: Date) => Date;
  end: Date | null;
  formatDate: (date: Date | null) => any;
  hasNextPage: boolean;
  hasPrevPage: boolean;
  incrementPage: (date: Date) => Date;
  incrementValue: (date: Date) => Date;
  isRange: boolean;
  isValid: boolean;
  max: Date;
  min: Date;
  onChange: (range: [start: string, end: string | null]) => any;
  range: DateRange;
  selecting: DatePickerSelection;
  start: Date | null;
  touched: boolean;
  viewDate: Date;
};

const getInitialState = ({ range, ...params }: DatePickerStateParams): DatePickerState => {
  const dateRange = new DateRange(params.min, params.max);
  const hasPrevPage: boolean = dateRange.includes(params.decrementPage(params.viewDate));
  const hasNextPage: boolean = dateRange.includes(params.incrementPage(params.viewDate));

  const isValid =
    (range && params.start && params.end && dateRange.includesEvery(params.start, params.end)) ||
    (!range && params.start && dateRange.includes(params.start)) ||
    false;

  return {
    ...params,
    hasNextPage,
    hasPrevPage,
    isRange: range,
    isValid,
    range: dateRange,
    selecting: 'START',
    touched: false,
  };
};

type SelectStart = { type: 'SELECT_START'; payload: Date };

type SelectEnd = { type: 'SELECT_END'; payload: Date };

type SelectRange = { type: 'SELECT_START_AND_END'; payload: [Date, Date] };

type SetYear = { type: 'SET_PAGE'; payload: Date };

type GotoNextPage = { type: 'NEXT_PAGE' };

type GotoPrevPage = { type: 'PREV_PAGE' };

type SetSelecting = { type: 'SET_SELECTING'; payload: DatePickerSelection };

type Select = { type: 'SELECT'; payload: Date };

type Reset = { type: 'RESET'; payload: DatePickerStateParams };

type PickerAction =
  | GotoNextPage
  | GotoPrevPage
  | Reset
  | Select
  | SelectEnd
  | SelectRange
  | SelectStart
  | SetYear
  | SetSelecting;

const selectStart = (state: DatePickerState, proposed: Date): DatePickerState => {
  if (!state.range.includes(proposed)) {
    // Attempting to set out of range is a no-op
    return state;
  }

  if (!state.isRange) {
    return {
      ...state,
      end: null,
      isValid: true,
      selecting: 'START',
      start: proposed,
      touched: true,
    };
  }

  if (state.end && state.end <= proposed) {
    // If the start is after then "END," then we'll just null out the selected END
    return {
      ...state,
      end: null,
      isValid: false,
      selecting: 'END',
      start: proposed,
      touched: true,
    };
  }

  return { ...state, isValid: !!state.end, selecting: 'END', start: proposed, touched: true };
};

const selectEnd = (state: DatePickerState, proposed: Date): DatePickerState => {
  if (!state.range.includes(proposed)) {
    // Attempting to set out of range is a no-op
    return state;
  }

  // If no start has been selected, then we use this for `start`
  if (!state.start) {
    return {
      ...state,
      end: null,
      isValid: false,
      selecting: 'END',
      start: proposed,
      touched: true,
    };
  }

  // If the desired end is before the start, then we'll use this as the start
  if (proposed < state.start) {
    // If the payload is in bounds but before the end, then we'll use it as the start
    return {
      ...state,
      end: null,
      isValid: false,
      selecting: 'END',
      start: proposed,
      touched: true,
    };
  }

  // This is actually selecting an "END"
  return { ...state, end: proposed, isValid: true, selecting: 'START', touched: true };
};

const select = (state: DatePickerState, proposed: Date): DatePickerState =>
  state.selecting === 'START' ? selectStart(state, proposed) : selectEnd(state, proposed);

const setPage = (state: DatePickerState, proposed: Date): DatePickerState => {
  if (state.range.includes(proposed)) {
    return {
      ...state,
      hasNextPage: state.range.includes(state.incrementPage(proposed)),
      hasPrevPage: state.range.includes(state.decrementPage(proposed)),
      viewDate: proposed,
    };
  }

  return state;
};

const reducer = (state: DatePickerState, action: PickerAction): DatePickerState => {
  switch (action.type) {
    case 'SELECT_START':
      return selectStart(state, action.payload);

    case 'SELECT_END':
      return selectEnd(state, action.payload);

    case 'SELECT':
      return select(state, action.payload);

    case 'RESET':
      return getInitialState(action.payload);

    case 'SELECT_START_AND_END':
      return { ...state, start: action.payload[0], end: action.payload[1] };

    case 'SET_PAGE':
      return setPage(state, action.payload);

    case 'NEXT_PAGE':
      return setPage(state, state.incrementPage(state.viewDate));

    case 'PREV_PAGE':
      return setPage(state, state.decrementPage(state.viewDate));

    case 'SET_SELECTING':
      return { ...state, selecting: action.payload };

    default:
      return state;
  }
};

export const useDatePickerState = (params: DatePickerStateParams) => {
  const [state, dispatch] = React.useReducer(reducer, params, getInitialState);

  const actions = React.useMemo(
    () => ({
      nextPage: () => dispatch({ type: 'NEXT_PAGE' }),
      prevPage: () => dispatch({ type: 'PREV_PAGE' }),
      reset: () => dispatch({ type: 'RESET', payload: params }),
      select: (date: Date) => dispatch({ type: 'SELECT', payload: date }),
      selectEnd: (end: Date) => dispatch({ type: 'SELECT_END', payload: end }),
      selectStart: (start: Date) => dispatch({ type: 'SELECT_START', payload: start }),
      setSelecting: (selecting: DatePickerSelection) =>
        dispatch({ type: 'SET_SELECTING', payload: selecting }),
      setViewDate: (viewDate: Date) => dispatch({ type: 'SET_PAGE', payload: viewDate }),
    }),
    [dispatch],
  );

  React.useEffect(() => {
    if (!state.isRange && state.isValid && state.touched) {
      state.onChange([state.formatDate(state.start), null]);
    }
  }, [state.isRange, state.isValid, state.start, state.onChange]);

  return { state, actions };
};
