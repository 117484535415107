import * as React from 'react';

import { isHTMLElement } from '@cobbler-io/utils/src/isHTMLElement';

export const useReadonlyColumn = () => {
  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.defaultPrevented) {
      return;
    }

    if (event.key === 'Enter') {
      event.preventDefault();
      if (!event.currentTarget) {
        return;
      }
      const rowGroup = event.currentTarget.parentElement?.parentElement;
      if (!rowGroup) {
        return;
      }

      const { col, rowIndex } = event.currentTarget.dataset;
      const row = parseInt(rowIndex ?? '-1', 10) + 1;
      const nextCell = rowGroup.querySelector(
        `div[role="cell"][data-col="${col}"][data-row-index="${row}"]`,
      );

      if (isHTMLElement(nextCell)) {
        queueMicrotask(() => nextCell.focus());
      }
    }
  };

  return {
    onKeyDown: handleKeyDown,
  };
};
