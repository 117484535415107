/* eslint-disable max-lines-per-function */
import * as React from 'react';

import { useToggle } from '@cobbler-io/hooks/src/useToggle';

import { Button } from '@cobbler-io/core-ui/src/Button';
import { Icon } from '@cobbler-io/core-ui/src/Icon';
import { useNotification } from '@cobbler-io/core-ui/src/Notification';

import { useCurrentUser } from '@cobbler-io/redux/src/modules/current-user';

import {
  CommentType, useAddCommentToDiscussionMutation, useAssignUserToDiscussionMutation,
  useDeleteCommentFromDiscussionMutation, useResolveDiscussionMutation, UserType,
  useUnassignDiscussionMutation, useUnresolveDiscussionMutation,
  useUpdateCommentInDiscussionMutation,
} from '@cobbler-io/app/src/api/graphql-types';
import { DiscussionInput, UserDropdown, UserDropdownError } from '@cobbler-io/app/src/components';
import { DiscussionComment } from '@cobbler-io/app/src/components/DiscussionComment';

import { Form } from '@swan-form/form';

import { DiscussionStatus } from './DiscussionStatus';

import styles from './Discussion.scss';

const transformUserOptions = (items: { label: string; value: any }[]) => [
  { id: '@@__unassigned', label: 'Unassigned', value: '@@__unassigned' },
  ...items,
];

type Props = {
  id: string;
  usersWithAccess?: readonly UserType[] | null;
  showStatus?: boolean;
  resolved: boolean;
  canResolve?: boolean;
  assignedTo?: string | null;
  comments: readonly CommentType[];
  refetch?: () => void;
};

export const Discussion = (props: Props): JSX.Element => {
  const {
    id,
    usersWithAccess,
    resolved,
    canResolve = false,
    showStatus = false,
    comments,
    assignedTo,
    refetch,
  } = props;
  const { active: adding, activate: startAdding, deactivate: stopAdding } = useToggle(false);
  const { active: saving, activate: startSaving, deactivate: stopSaving } = useToggle(false);
  const {
    active: assigning,
    toggle: toggleAssigning,
    deactivate: stopAssigning,
  } = useToggle(false);
  const { id: currentUserId } = useCurrentUser();
  const notify = useNotification();

  // Discussion mutations
  const [resolveDiscussion] = useResolveDiscussionMutation();
  const [unresolveDiscussion] = useUnresolveDiscussionMutation();
  const [assignUserToDiscussion] = useAssignUserToDiscussionMutation();
  const [unassignDiscussion] = useUnassignDiscussionMutation();
  const [addCommentToDiscussion] = useAddCommentToDiscussionMutation();
  const [deleteCommentFromDiscussion] = useDeleteCommentFromDiscussionMutation();
  const [updateCommentInDiscussion] = useUpdateCommentInDiscussionMutation();

  const onError = (error: any) => {
    console.error(error);
    notify({ body: 'Something went wrong', title: 'Error' });
  };

  const handleResolve = async () => {
    startSaving();
    return resolveDiscussion({ variables: { input: { id } } })
      .then(refetch)
      .catch(onError)
      .finally(stopSaving);
  };

  const handleUnresolve = async () => {
    startSaving();
    return unresolveDiscussion({ variables: { input: { id } } })
      .then(refetch)
      .catch(onError)
      .finally(stopSaving);
  };

  const handleDeleteComment = async (discussionCommentId: string) => {
    startSaving();
    return deleteCommentFromDiscussion({
      variables: { input: { discussionCommentId, id } },
    })
      .then(refetch)
      .catch(onError)
      .finally(stopSaving);
  };

  const handleUpdateComment = async (discussionCommentId: string, commentTextContent: string) => {
    startSaving();
    return updateCommentInDiscussion({
      variables: { input: { commentTextContent, discussionCommentId, id } },
    })
      .then(refetch)
      .catch(onError)
      .finally(stopSaving);
  };

  const handleAddComment = async (commentTextContent: string) => {
    startSaving();
    return addCommentToDiscussion({ variables: { input: { commentTextContent, id } } })
      .then(refetch)
      .catch(onError)
      .finally(() => {
        stopAdding();
        stopSaving();
      });
  };

  const handleSubmit = ({ assignedToUserId }: Record<string, string>) => {
    startSaving();
    (assignedToUserId === '@@__unassigned' || !assignedToUserId
      ? unassignDiscussion({ variables: { input: { id } } })
      : assignUserToDiscussion({ variables: { input: { assignedToUserId, id } } })
    )
      .then(refetch)
      .catch(err => {
        console.error('Unable to assign discussion', err);
        notify({ body: 'Unable to assign. Please try again.', title: 'Error' });
      })
      .finally(() => {
        stopAssigning();
        stopSaving();
      });
  };

  return (
    <div className={styles.discussion}>
      <DiscussionStatus
        assignedTo={assignedTo}
        canResolve={canResolve}
        handleResolve={handleResolve}
        handleUnresolve={handleUnresolve}
        isDisabled={saving || adding}
        resolved={resolved}
        showStatus={showStatus}
        toggleAssigning={toggleAssigning}
      />

      {assigning && !resolved && (
        <div className={styles.assignTo}>
          <Form autoComplete={false} name="comment-form" onSubmit={handleSubmit}>
            {usersWithAccess ? (
              <UserDropdown
                fallbackFunction={null}
                id="assignedToUserId"
                name="assignedToUserId"
                placeholder="Unassigned"
                returnType="id"
                transformOptions={transformUserOptions}
                users={usersWithAccess}
              />
            ) : (
              <UserDropdownError />
            )}
            <Button name="cancel" type="button" variant="outline" onClick={stopAssigning}>
              Cancel
            </Button>
            <Button name="save" type="submit">
              Save
            </Button>
          </Form>
        </div>
      )}
      <div className={styles.comments}>
        {comments.map((comment, i) => (
          <DiscussionComment
            key={comment.id}
            author={comment.author || {}}
            createdAt={comment.createdAt}
            deletable={!resolved && i > 0}
            editable={!resolved && comment.author?.id === currentUserId}
            edited={comment.edited}
            id={comment.id}
            lastUpdatedAt={comment.lastUpdatedAt}
            textContent={comment.textContent}
            onDeleteComment={handleDeleteComment}
            onUpdateComment={handleUpdateComment}
          />
        ))}
      </div>

      {!resolved && (
        <div className={styles.reply}>
          {adding ? (
            <DiscussionInput disabled={saving} onCancel={stopAdding} onSave={handleAddComment} />
          ) : (
            <Button name="reply" type="button" variant="text" onClick={startAdding}>
              Reply
            </Button>
          )}
        </div>
      )}
    </div>
  );
};

Discussion.displayName = 'Discussion';

export default Discussion;
