/* eslint-disable no-bitwise */
import { BudgetResolution } from '../shared-types';

export const createHasResolution = (
  maxResolution: BudgetResolution,
): ((res: BudgetResolution) => boolean) => {
  const YEAR = 0b1;
  const HALF = 0b10;
  const QUARTER = 0b100;
  const MONTH = 0b1000;

  const resolutionMap = { YEAR, HALF, QUARTER, MONTH };

  const resolutions = {
    YEAR,
    HALF: YEAR | HALF,
    QUARTER: YEAR | HALF | QUARTER,
    MONTH: YEAR | HALF | QUARTER | MONTH,
  };
  const resolution = resolutions[maxResolution];

  return (res: BudgetResolution): boolean => Boolean(resolution & resolutionMap[res]);
};
