/* eslint-disable max-lines-per-function */

import * as React from 'react';

import { flatten } from '@cobbler-io/utils/src/array/flatten';
import { noop } from '@cobbler-io/utils/src/noop';

import { Menu, MenuItemProps } from '@cobbler-io/core-ui/src/Menu';

import { ColumnInstance } from 'react-table';

const getSortableMenuItems = (column: ColumnInstance<any>) => {
  const { isSorted, isSortedDesc, canSort, clearSortBy, toggleSortBy } = column;
  const isSortable = canSort;
  const sortDirection = isSortedDesc ? 'DESC' : 'ASC';
  const isSortedASC = isSorted && sortDirection === 'ASC';
  const isSortedDESC = isSorted && sortDirection === 'DESC';
  const sortAsc = () => toggleSortBy(false, false);
  const sortDesc = () => toggleSortBy(true, false);

  if (!isSortable) {
    return [];
  }

  // Actions to do with sortings
  return [
    // CLEAR COLUMN SORT
    isSorted && (
      <Menu.Item
        key="clear"
        label={<strong>Clear sort</strong>}
        onSelect={isSorted ? clearSortBy : noop}
      />
    ),
    !isSortedASC && (
      <Menu.Item key="sort-asc" iconType="arrowUp" label="Sort Ascending" onSelect={sortAsc} />
    ),
    !isSortedDESC && (
      <Menu.Item key="sort-dsc" iconType="arrowDown" label="Sort Descending" onSelect={sortDesc} />
    ),
  ];
};

type FilterMenuProps = ColumnInstance<any> & {
  filterKey: number;
};

export const getFilterMenuItems = (column: FilterMenuProps) => {
  const { canFilter: isFilterable, Filter, filterValue, instance, id } = column;
  const isFilterDrawerShowing = Boolean(instance?.showFilterDrawer);

  if (!isFilterable || !Filter) {
    return [];
  }

  const clearFilter = () => {
    if (column) {
      column.filterKey += 1;
    }
    instance?.setFilter(id, null);
  };
  return [
    filterValue && (
      <Menu.Item key="clear" label={<strong>Clear filter</strong>} onSelect={clearFilter} />
    ),
    <Menu.Item
      key={`${isFilterDrawerShowing ? 'hide' : 'show'} filters`}
      label={isFilterDrawerShowing ? 'Hide filters' : 'Show filters'}
      onSelect={instance.toggleFilterDrawer}
    />,
  ].filter(Boolean);
};

const getGroupableMenuItems = (column: ColumnInstance<any>) => {
  const { id, isGrouped, canGroupBy: isGroupable, instance, Header } = column;

  // We have some functions that we need, but they're not on header groups
  const toggleAllRowsExpanded = instance?.toggleAllRowsExpanded ?? noop;
  const isAllRowsExpanded = instance?.isAllRowsExpanded ?? noop;
  const someRowsExpanded = Boolean(Object.keys(instance?.state?.expanded ?? {}).length);
  const setGroupByExclusive = instance?.setGroupByExclusive ?? noop;
  const clearGroupBy = () => setGroupByExclusive();
  const groupBy = () => setGroupByExclusive(id);
  const expandAllRows = () => toggleAllRowsExpanded(true);
  const collapseAllRows = () => toggleAllRowsExpanded(false);

  if (!isGroupable) {
    return [];
  }

  return [
    // GROUPBY / CLEAR GROUPBY
    <Menu.Item
      key="group-by-main"
      iconType="bulletList"
      label={isGrouped ? <strong>Stop grouping by {Header}</strong> : `Group by ${Header}`}
      onSelect={isGrouped ? clearGroupBy : groupBy}
    />,
    // Actions dealing with global row expansion / collapse
    isGrouped && [
      // EXPAND ALL ROWS
      !isAllRowsExpanded && (
        <Menu.Item key="expand-all" label="Expand all rows" onSelect={expandAllRows} />
      ),

      // COLLAPSE ALL ROWS
      someRowsExpanded && (
        <Menu.Item key="collapse-all" label="Collapse all rows" onSelect={collapseAllRows} />
      ),
    ],
  ];
};

export const getPinnableMenuItems = (column: ColumnInstance<any>) => {
  const { canPin: isPinnable, isPinned, togglePinColumn } = column;

  if (!isPinnable) {
    return [];
  }

  return [
    <Menu.Item
      key="pin"
      iconType="pushPin"
      label={isPinned ? <strong>Unpin column</strong> : 'Pin column'}
      onSelect={togglePinColumn}
    />,
  ];
};

export const getMiscMenuItems = (column: ColumnInstance<any>) => {
  const { hideable: isHideable, isGrouped, Header, toggleHidden, autosize } = column;

  const tableRef = column?.instance?.tableRef;

  return [
    // HIDE COLUMN if it is not grouped
    isHideable && !isGrouped && (
      <Menu.Item key="hide" label={`Hide ${Header}`} onSelect={toggleHidden} />
    ),

    tableRef && <Menu.Item key="autosize" label="Autosize" onSelect={autosize} />,
  ];
};

export const getMenuItems = (column: ColumnInstance<any>): React.ReactElement<MenuItemProps>[] =>
  flatten([
    getPinnableMenuItems(column),
    getSortableMenuItems(column),
    getFilterMenuItems(column),
    getGroupableMenuItems(column),
    getMiscMenuItems(column),
  ]).filter(Boolean) as React.ReactElement<MenuItemProps>[];
