/* eslint-disable max-lines-per-function */
import * as React from 'react';

import { cx } from '@cobbler-io/utils/src/cx';

import { useCurrencyFormatter } from '@cobbler-io/redux/src/modules/currency';

import styles from './MaxHint.scss';

export type MaxHintProps<T extends any = number> = {
  className?: string;
  format?: (input: T) => string;
  style?: React.CSSProperties;
  value: T;
  label?: string;
};

export const MaxHint: React.FC<MaxHintProps> = props => {
  const { simple } = useCurrencyFormatter();
  const { className, format = simple, value, label = 'available', ...rest } = props;
  return (
    <div {...rest} className={cx(styles.maxHint, className)}>
      {format(value)} {label}
    </div>
  );
};

MaxHint.displayName = 'MaxHint';
