import * as React from 'react';

import { CloseButton } from '@cobbler-io/core-ui/src/CloseButton';
import { Modal, useModal } from '@cobbler-io/core-ui/src/Modal';
import { ShimmerList } from '@cobbler-io/core-ui/src/Shimmer';

import { ForecastUploader } from '@cobbler-io/app/src/ndm/components/BudgetLineUploader';

import { useCurrentEditorData } from './useCurrentEditorData';

const ErrorDisplay = () => (
  <div>
    <h3>Unable to load budget line data.</h3>
    <p>Please try again later.</p>
  </div>
);
ErrorDisplay.displayName = 'AddForecastErrorDisplay';

const Loading = () => (
  <div>
    <ShimmerList items={3} />
  </div>
);
Loading.displayName = 'AddForecastLoading';

export const useAddForecast = () => {
  const { create: createModal } = useModal();
  const { loading, error, mappedData: parentBudgetLine } = useCurrentEditorData();

  const addForecast = React.useCallback(() => {
    createModal(
      <Modal overlay className="centered box-shadow-standard" clickAway="none">
        <CloseButton />
        {error && <ErrorDisplay />}
        {loading && <Loading />}
        {parentBudgetLine && (
          <ForecastUploader budgetData={parentBudgetLine} onSuccess={() => {}} />
        )}
      </Modal>,
    );
  }, [createModal, error, loading, parentBudgetLine]);

  return addForecast;
};
