/* eslint-disable sort-keys, sort-keys-fix/sort-keys-fix */
import * as React from 'react';

import { useGraphTraversal } from '@cobbler-io/collection/src/Graph/react';
import { TreeNode } from '@cobbler-io/collection/src/Tree';

import { ApolloError } from 'apollo-client/errors/ApolloError';

import { HydrateDepartmentsQuery } from '../graphql-types';
import { DepartmentVertex } from './graph';
import { useHydrateDepartments } from './useHydrateDepartments';

type DepartmentTreeHook = {
  loading: boolean;
  loaded: boolean;
  error: ApolloError | undefined;
  root: TreeNode<DepartmentVertex> | null;
  index: Record<string, TreeNode<DepartmentVertex>> | null;
};

const selectDepartmentRoots = (g: any): (DepartmentVertex | undefined)[] =>
  g.V().hasLabel('DEPARTMENT_ROOT').toArray();

type GetValuesParams = {
  root: DepartmentVertex | undefined;
  data: HydrateDepartmentsQuery | undefined;
  departments: DepartmentVertex[];
  loading: boolean;
  loaded: boolean;
  error: any;
};

const getValues = (params: GetValuesParams) => {
  const { root, data, departments, loading, loaded, error } = params;

  if (!root || !data) {
    return { error, index: null, loaded, loading, root: null };
  }

  const rootNode = new TreeNode(null, root, root.id);

  const index: Record<string, TreeNode<DepartmentVertex>> = {
    [root.id]: rootNode,
  };

  const handleQueue = (queue: DepartmentVertex[], overflow: DepartmentVertex[]) => {
    for (const department of queue) {
      // We already have the department root, so we can just skip it
      if (department.hasLabel('DEPARTMENT_ROOT')) {
        continue; // eslint-disable-line no-continue
      }

      if (index[department.id]) {
        // We've already dealt with this one
        continue; // eslint-disable-line no-continue
      }

      const [parentEdge] = department.edgesToByLabel('HAS_SUB_DEPARTMENT');

      const parent = index[parentEdge.from.id];
      if (parent) {
        const next = parent.createChild(department, department.id);
        index[next.id] = next; // eslint-disable-line functional/immutable-data
      } else {
        overflow.push(department); // eslint-disable-line functional/immutable-data
      }
    }

    return overflow;
  };

  // eslint-disable-next-line functional/no-let, no-restricted-syntax
  let queue = Array.from(departments);
  while (queue.length) {
    queue = handleQueue(queue, []);
  }

  return { error, index, loaded, loading, root: rootNode };
};

export const useDepartmentTree = (): DepartmentTreeHook => {
  const { data, loading, error } = useHydrateDepartments();
  const departments = useGraphTraversal(g =>
    g.V().hasLabel('DEPARTMENT'),
  ).toArray() as DepartmentVertex[];

  const [root] = useGraphTraversal(selectDepartmentRoots);

  const loaded = departments.length > 0 || (!!data && !loading);

  const value = getValues({ root, departments, data, loading, error, loaded });

  React.useDebugValue(value);

  /* eslint-disable */
  // @ts-expect-error: this isn't officially part of "Window"
  window.__COBBLER_DEBUG__ ??= {};
  // @ts-expect-error: this isn't officially part of "Window"
  window.__COBBLER_DEBUG__.departmentTree = value;
  /* eslint-enable */

  return value;
};
