import * as React from 'react';

import { cx } from '@cobbler-io/utils/src/cx';

import { Button } from '@cobbler-io/core-ui/src/Button';
import { Chip } from '@cobbler-io/core-ui/src/Chip';
import { Icon } from '@cobbler-io/core-ui/src/Icon';
import { Menu, useFlyoutMenu } from '@cobbler-io/core-ui/src/Menu';

import {
  useBudgetRevisions, useGetRevisionTag,
} from '@cobbler-io/redux/src/modules/current-budget';

import { budgetUrls, RevisionTarget } from '@cobbler-io/app/src/urls';
import { useBudgetLineParams } from '@cobbler-io/app/src/utils/useBudgetLineParams';

import { navigate, useLocation } from '@reach/router';

import styles from './RevisionMenu.scss';

export type RevisionMenuProps = {
  showRollingForecast?: boolean;
  className?: string;
  style?: React.CSSProperties;
  disabled?: boolean;
};

const ActiveChip = () => (
  <Chip small checked={false} className={styles.activeChip}>
    Active
  </Chip>
);

ActiveChip.displayName = 'ActiveChip';

/* eslint-disable no-restricted-syntax */
const useActiveUrl = (): UnaryFn<RevisionTarget, string> => {
  const params = useBudgetLineParams();
  const location = useLocation();
  const getRevisionTag = useGetRevisionTag();

  for (const key in budgetUrls) {
    if (Object.prototype.hasOwnProperty.call(budgetUrls, key)) {
      const url = budgetUrls[key];
      if (
        location.pathname === url({ budgetId: params.budgetLineId, revisionId: params.revisionTag })
      ) {
        return (revisionId: RevisionTarget) =>
          url({ budgetId: params.budgetLineId, revisionId: getRevisionTag(revisionId) });
      }
    }
  }

  // If we can't find a url, then we'll send back the one for the overview
  return (revisionId: RevisionTarget) =>
    budgetUrls.overview({ budgetId: params.budgetLineId, revisionId });
};
/* eslint-enable no-restricted-syntax */

export const RevisionMenu = (props: RevisionMenuProps): JSX.Element => {
  const { showRollingForecast, className, style, disabled = false } = props;
  const [state, actions] = useBudgetRevisions();
  const { revisions = [], selectedRevision = null, active = null, selected = null } = state ?? {};

  const getUrl = useActiveUrl();

  const visibleRevisions = showRollingForecast
    ? revisions
    : revisions.filter(r => !r.isRollingForecast);

  const showOptions = useFlyoutMenu(
    <Menu small>
      {visibleRevisions.map(({ id, name, isLocked }) => (
        <Menu.Item
          key={id}
          iconType={id === selected ? 'check' : 'blank'}
          label={
            <span className={cx(styles.revisionItem)}>
              <span>{name}</span>
              {isLocked && <Icon className={styles.lock} size={12} type="lock" />}
              {id === active && <ActiveChip />}
            </span>
          }
          onSelect={() => {
            actions.select(id); // update in redux

            if (window.location.pathname.startsWith('/budgets/')) {
              navigate(getUrl(id)); // change the url
            }
          }}
        />
      ))}
    </Menu>,
  );

  return (
    <Button
      small
      className={className}
      disabled={disabled}
      name="revision-chooser"
      style={style}
      variant="text"
      onClick={showOptions}
    >
      {/* TODO: Spacing here is a hack... */}
      <Icon size={16} style={{ marginRight: '-0.5em' }} type="library" />
      <span>
        Version: <strong>{selectedRevision?.name ?? '---'}</strong>
      </span>
    </Button>
  );
};

RevisionMenu.displayName = 'RevisionMenu';
