import * as React from 'react';

import { head } from '@cobbler-io/utils/src/array';

import { CellProps } from 'react-table';

import { EditorLine } from '../../types';

export const AccountsColumn = ({
  cell: { value: accounts },
}: CellProps<EditorLine, EditorLine['accounts']>): string | null => {
  if (!accounts?.length) {
    return null;
  }

  const { length } = accounts;

  const account = head(accounts);
  const displayName = `${account.displayCode} ${account.displayName}`;

  return length === 1 ? displayName : [displayName, `(+${length - 1} more)`].join(' ');
};
