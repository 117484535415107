import { saveAs } from 'file-saver';

export const useSaveAs: () => (
  data: Blob,
  filename: string,
  fallbackURL: string | null,
) => void = () => (data, filename, fallbackURL = null) => {
  if (!new Blob()) {
    if (fallbackURL) {
      window.open(fallbackURL);
    }
  } else {
    saveAs(data, filename);
  }
};
