import * as React from 'react';

import { cx } from '@cobbler-io/utils/src';

import { CellProps } from 'react-table';

import { Icon } from '../../Icon';

import styles from './DragHandle.scss';

export const DragHandle = <D extends UnknownObject>({
  row,
  className,
}: CellProps<D>): JSX.Element =>
  // @ts-expect-error: This is fine for react
  !!row.isDraggable && (
    <div ref={row.drag} className={cx(styles.handle, className)}>
      <Icon size={26} type="dragHandle" />
    </div>
  );

DragHandle.displayName = 'DataGrid__DragHandle';
