/* eslint-disable max-lines-per-function */
import * as React from 'react';

import { isFunction } from '@cobbler-io/utils/src/isFunction';

import { Button } from '@cobbler-io/core-ui/src/Button';
import { Heading } from '@cobbler-io/core-ui/src/Heading';
import { useCurrentModal } from '@cobbler-io/core-ui/src/Modal';
import { useNotification } from '@cobbler-io/core-ui/src/Notification';

import { useCurrencyFormatter } from '@cobbler-io/redux/src/modules/currency';
import { useSelectedBudgetRevision } from '@cobbler-io/redux/src/modules/current-budget';
import { useTenantFiscalYearStart } from '@cobbler-io/redux/src/modules/tenant-settings';

import { extractGraphQLErrors } from '@cobbler-io/app/src/api/extractGraphQLErrors';
import {
  BudgetLineEditorDataDocument, BudgetLineEditorDataQuery, BudgetLineEditorDataQueryVariables,
  BudgetLineTypeEnum, PlannedSpendInputType, UpdateBudgetLineMutation, useBudgetLineEditorDataQuery,
  useUpdateBudgetLineMutation,
} from '@cobbler-io/app/src/api/graphql-types';
import { Loading } from '@cobbler-io/app/src/components';
import { useCurrentBudgetLine } from '@cobbler-io/app/src/providers/CurrentBudgetLineProvider';
import { useCurrentRange } from '@cobbler-io/app/src/providers/CurrentRangeProvider';
import { toDateToken } from '@cobbler-io/app/src/utils/toDateToken';

import { MutationUpdaterFn } from 'apollo-client';
import {
  find, findIndex, head, indexBy, lensPath, over, path, pluck, prop, propEq, update as updateList,
  view,
} from 'ramda';

import { BudgetLineForm } from '../BudgetLineForm';
import { mapDataForBudgetRevision } from '../mapDataForBudgetRevision';
import { BudgetLineType, EditorLine } from '../types';
import { extractSpendPlansFromExpenseSchedule, UpdateBudgetFormValues } from '../utils';

import styles from './UpdateChildBudgetLineForm.scss';

type UpdateChildBudgetPlanFormProps = {
  updateBudgetLineId: BudgetLineId;
  updateAnother?: Record<string, unknown>;
};

type UpdateFormValues = {
  id: BudgetLineId;
  type: BudgetLineTypeEnum;
  name: string;
  ownerUserId: UserId | null;
  accountIds: AccountId[];
  departmentIds: DepartmentId[];
  vendors: string[];
} & UpdateBudgetFormValues;

const FORM_NAME = 'update-child-budget-line';
const linesLens = lensPath<BudgetLineEditorDataQuery>(['a_budgetLineChildrenEditorData', 'lines']);

export const UpdateChildBudgetLineForm = (props: UpdateChildBudgetPlanFormProps): JSX.Element => {
  const notify = useNotification();
  const { close } = useCurrentModal();
  const { convertToMinorUnit } = useCurrencyFormatter();
  const fiscalYearStartMonth = useTenantFiscalYearStart();

  const { updateBudgetLineId, updateAnother } = props;
  const selectedRevision = useSelectedBudgetRevision();
  const budgetLineContext = useCurrentBudgetLine();
  const rangeContext = useCurrentRange();
  const id = budgetLineContext?.id;
  const start = toDateToken(rangeContext.start);
  const end = toDateToken(rangeContext.end);
  const parentQuery = useBudgetLineEditorDataQuery({
    fetchPolicy: 'cache-first',
    skip: !id || !selectedRevision,
    variables: { end, id: id!, revisionId: selectedRevision!.id, start },
  });
  const currentQuery = useBudgetLineEditorDataQuery({
    fetchPolicy: 'cache-first',
    skip: !updateBudgetLineId || !selectedRevision,
    variables: { end, id: updateBudgetLineId, revisionId: selectedRevision!.id, start },
  });
  const [mutate, { loading: isSubmitting }] = useUpdateBudgetLineMutation();

  if (currentQuery.loading || parentQuery.loading) {
    return <Loading />;
  }

  if (
    parentQuery.error ||
    currentQuery.error ||
    !id ||
    !selectedRevision ||
    !parentQuery.data?.a_budgetLineChildrenEditorData ||
    !currentQuery.data?.a_budgetLineChildrenEditorData
  ) {
    parentQuery.error && console.error('Error caught', parentQuery.error);
    currentQuery.error && console.error('Error caught', currentQuery.error);

    return <>Error loading data</>;
  }

  const [parent, current] = [parentQuery, currentQuery].map(({ data }) =>
    mapDataForBudgetRevision(data!.a_budgetLineChildrenEditorData, fiscalYearStartMonth),
  );

  const successNotification = (name: string): void => {
    notify({ body: `Successfully updated "${name}"`, title: 'Updated Budget Line' });
  };

  const errorNotification = (name: string): void => {
    notify({
      body: `An error occurred when updating "${name}"`,
      persist: true,
      title: 'Error updating budget line',
    });
  };

  const buildOptimisticResponse = (
    values: UpdateFormValues,
    plannedSpendInput: PlannedSpendInputType[],
  ): UpdateBudgetLineMutation => ({
    __typename: 'Mutation',
    updatedBudgetLine: {
      __typename: 'BudgetLinePayloadType',

      // TODO: Get this from cached GetAllUsersQuery
      accounts: [],

      // TODO: Get this from cached GetAllVendorsQuery
      departments: [],

      id: current.id,

      isRevenue: current.type === BudgetLineType.REVENUE,

      name: values.name,

      owner: null,

      // TODO: Get this from cached GetAccountsQuery
      plannedSpend: plannedSpendInput.map(p => ({ __typename: 'PlannedSpendType', ...p })),

      type: current.type,
      vendors: [],
      weight: current.weight,
    },
  });

  const update: MutationUpdaterFn<UpdateBudgetLineMutation> = (cache, mutationResponse) => {
    // Generate the same variables used in the editor data query (not ideal, we should pass them around instead)
    const variables: BudgetLineEditorDataQueryVariables = {
      end,
      id,
      revisionId: selectedRevision.id,
      start,
    };
    const query = BudgetLineEditorDataDocument;

    // We want to update the cache for the BudgetLineEditorData query, so we
    // get the cached data, modify it and write it back
    const cachedData = cache.readQuery<BudgetLineEditorDataQuery>({ query, variables });
    if (!cachedData) {
      // eslint-disable-next-line no-console
      console.warn('BudgetLineEditorDataQuery cache not found.');
      return;
    }

    const updatedBudgetLine = mutationResponse.data?.updatedBudgetLine;
    if (!updatedBudgetLine) {
      // eslint-disable-next-line no-console
      console.warn('Invalid response payload.');
      return;
    }

    const cachedLine = find(
      propEq('id', updatedBudgetLine.id),
      path<BudgetLineEditorDataQuery['a_budgetLineChildrenEditorData']['lines']>(
        ['a_budgetLineChildrenEditorData', 'lines'],
        cachedData,
      )!,
    )! as BudgetLineEditorDataQuery['a_budgetLineChildrenEditorData']['lines'][number];

    const cachedPlannedByInterval = indexBy(prop('start'), cachedLine?.plannedSpend ?? []);
    const plannedByInterval = indexBy(prop('start'), updatedBudgetLine.plannedSpend);

    const updatedLine: EditorLine = {
      __typename: 'BudgetEditorLineType',
      accounts: updatedBudgetLine.accounts,
      ancestors: [],
      canUpdate: true,
      childrenCount: 0,
      departments: updatedBudgetLine.departments,
      id: updatedBudgetLine.id,
      isRevenue: updatedBudgetLine.isRevenue,
      linkedToDriver: false,
      name: updatedBudgetLine.name,
      owner: {
        __typename: 'UserReference',
        id: updatedBudgetLine.owner?.id ?? null,
        item: updatedBudgetLine.owner,
      },
      permissions: {
        __typename: 'ResourcePermissionType',
        canDelete: false,
        canRead: false,
        canUpdate: false,
        canUpdatePermissions: false,
      },
      plannedSpend: parent.intervals.map(interval => ({
        ...cachedPlannedByInterval[interval.start],
        planned: plannedByInterval[interval.start]?.planned ?? 0,
      })),
      type: updatedBudgetLine.type,
      vendors: updatedBudgetLine.vendors,
      weight: updatedBudgetLine.weight,
    };

    const lineIndex = findIndex(propEq('id', updatedBudgetLine.id), view(linesLens, cachedData));
    const updateWithLine = updateList(lineIndex, updatedLine);

    cache.writeQuery({
      data: over(linesLens, updateWithLine, cachedData),
      query,
      variables,
    });
  };

  const handleSubmit = async (values: UpdateFormValues) => {
    const plannedSpend: PlannedSpendInputType[] = extractSpendPlansFromExpenseSchedule(
      parent,
      values,
    ).map(p => ({
      planned: convertToMinorUnit(p.amount),
      start: p.start,
    }));

    const variables = {
      accountIds: values.accountIds,
      dateRange: { end, start },
      departmentIds: values.departmentIds,
      id: current.id,
      name: values.name,
      ownerUserId: values.ownerUserId,
      plannedSpend,
      revisionId: selectedRevision.id,
      vendors: values.vendors,
    };

    const submission = mutate({
      optimisticResponse: buildOptimisticResponse(values, plannedSpend),
      update,
      variables,
    })
      .then(() => successNotification(values.name))
      .catch(err => {
        const error = head(extractGraphQLErrors(err));
        console.error(error);
        errorNotification(values.name);
      });

    if (isFunction(updateAnother)) {
      queueMicrotask(() => updateAnother());
    }

    close();

    return submission;
  };

  const defaultValues: UpdateFormValues = {
    accountIds: pluck('id', current.accounts) as AccountId[],
    departmentIds: pluck('id', current.departments),
    id: current.id,
    name: current.name,
    ownerUserId: current.owner?.id ?? null,
    type: current.type,
    vendors: pluck('id', current.vendors),
  };

  return (
    <div className={styles.updateBudgetLineModal}>
      <Heading size="title">Update Budget Line</Heading>

      <BudgetLineForm
        current={current}
        defaultValues={defaultValues}
        formErrors={null}
        name={FORM_NAME}
        parent={parent}
        onSubmit={handleSubmit}
      />

      <div className="button-container right">
        <Button name="cancel-update-line" variant="outline" onClick={close}>
          Cancel
        </Button>
        <Button disabled={isSubmitting} form={FORM_NAME} name="update-line" type="submit">
          {updateAnother ? 'Continue' : 'Save'}
        </Button>
      </div>
    </div>
  );
};

UpdateChildBudgetLineForm.displayName = 'UpdateChildBudgetLineForm';
