/* eslint-disable functional/immutable-data */
import { stringify } from 'qs';

export const ALL_DEPARTMENT_TAG = 'all';

export const headcountBasePath = '/headcount/:departmentId/:currentTab/*';

export const headcountBaseUrl = (departmentId: DepartmentId): string =>
  `/headcount/${departmentId}`;

type BuildUrlParams = {
  departmentId?: DepartmentId;
  subPath?: string;
  queryParams?: Record<string, any>;
};

const buildUrl = ({
  departmentId = ALL_DEPARTMENT_TAG,
  subPath = '',
  queryParams = {},
}: BuildUrlParams): string => {
  const params = stringify(queryParams);
  return [[headcountBaseUrl(departmentId), subPath].filter(Boolean).join(''), params]
    .filter(Boolean)
    .join('?');
};

export type HeadcountUrlParams = Omit<BuildUrlParams, 'subPath'>;

export type HeadcountUrl<T extends HeadcountUrlParams> = {
  (params: T): string;
};

export type HeadcountUrls = {
  dashboard: HeadcountUrl<HeadcountUrlParams>;
  overview: HeadcountUrl<HeadcountUrlParams>;
  plan: HeadcountUrl<HeadcountUrlParams>;
  recruiting: HeadcountUrl<HeadcountUrlParams>;
  settings: HeadcountUrl<HeadcountUrlParams>;
  setup: HeadcountUrl<HeadcountUrlParams>;
};

const setupUrl = (params: HeadcountUrlParams): string => buildUrl({ ...params, subPath: '/setup' });

const overviewUrl = (params: HeadcountUrlParams): string =>
  buildUrl({ ...params, subPath: '/overview' });

const dashboardUrl = (params: HeadcountUrlParams): string =>
  buildUrl({ ...params, subPath: '/dashboard' });

const recruitingUrl = (params: HeadcountUrlParams): string =>
  buildUrl({ ...params, subPath: '/recruiting' });

const planUrl = (params: HeadcountUrlParams): string => buildUrl({ ...params, subPath: '/plan' });

const settingsUrl = (params: HeadcountUrlParams): string =>
  buildUrl({ ...params, subPath: '/settings' });

export const headcountUrls: HeadcountUrls = {
  dashboard: dashboardUrl,
  overview: overviewUrl,
  plan: planUrl,
  recruiting: recruitingUrl,
  settings: settingsUrl,
  setup: setupUrl,
};
