export const isNotFalsy = <T>(value: T | null | undefined | false | 0 | ''): value is T => {
  // TODO: This is missing all the possible definitions of falsy values as described in
  // https://developer.mozilla.org/en-US/docs/Glossary/Falsy

  // First, constrain the type using control flow blocks
  if (value === null || value === undefined || value === false || value === 0 || value === '') {
    return false;
  }

  // Use a dummy variable to give the compiler something to check.
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const foo: T = value;

  return true;
};
