/* eslint-disable @typescript-eslint/no-magic-numbers */
import { useMemo } from 'react';

import { hasFlag } from '@cobbler-io/utils/src/hasFlag';

import { addMonth } from '@cobbler-io/dates/src/addMonth';
import { getMonthsInRange } from '@cobbler-io/dates/src/getMonthsInRange';

import { useDispatch, useSelector } from 'react-redux';
import { ActionCreator, Reducer } from 'redux';

// TODO: Move all these types to its own file
export type LoadingStatus = {
  loaded: boolean;
  loading: boolean;
};

type LoadingStatusBitMap =
  | 0 // 0b00 not loaded, not loading
  | 1 // 0b01 not loaded, loading
  | 2 // 0b10 loaded, not loading
  | 3; // 0b11 loaded, loading

// TODO: Move all these bit map helpers to its own file
const LOADED = 0b10;
const LOADING = 0b01;

const isLoaded = hasFlag(LOADED);
const isLoading = hasFlag(LOADING);

const toBitMap = ({ loaded, loading }: LoadingStatus): LoadingStatusBitMap =>
  (~~loaded * LOADED + ~~loading * LOADING) as LoadingStatusBitMap;

const toStatus = (s: LoadingStatusBitMap = 0): LoadingStatus => ({
  loaded: isLoaded(s),
  loading: isLoading(s),
});

const updateBitMap = (bitMap: LoadingStatusBitMap, partialStatus: Partial<LoadingStatus>) => {
  const current = toStatus(bitMap);
  const updated = { ...current, ...partialStatus };
  return toBitMap(updated);
};

export const generateRangeRevisionStatuses = (params: {
  revisionId: string;
  start: ISO8601String;
  end: ISO8601String;
  status: Partial<LoadingStatus>;
}): Record<ISO8601String, Partial<LoadingStatus>> => {
  const { revisionId, status } = params;
  const start = `${params.start.substring(0, 7)}-01`;
  const end = `${params.end.substring(0, 7)}-03`;
  const months: ISO8601String[] = getMonthsInRange(start, addMonth(end));

  return months.reduce<Record<ISO8601String, Partial<LoadingStatus>>>((acc, month) => {
    const revisionMonth = `${revisionId}--${month}`;
    acc[revisionMonth] = status; // eslint-disable-line functional/immutable-data
    return acc;
  }, {});
};

export type GraphStatus = Record<string, LoadingStatusBitMap>;

const initialState: GraphStatus = {
  budgetLines: 0,
};

const UPDATE_STATUS = 'GRAPH_STATE/UPDATE_STATUS';

type UpdateStatus = {
  type: typeof UPDATE_STATUS;
  payload: {
    key: string;
    loadingStatus: Partial<LoadingStatus>;
  };
};

export const updateGraphStatus: ActionCreator<UpdateStatus> = (
  key,
  loadingStatus: Partial<LoadingStatus>,
) => ({
  payload: { key, loadingStatus },
  type: UPDATE_STATUS,
});

const UPDATE_STATUSES = 'GRAPH_STATE/UPDATE_STATUSES';
type UpdateStatuses = {
  type: typeof UPDATE_STATUSES;
  payload: Record<string, LoadingStatus>;
};
export const updateGraphStatuses: ActionCreator<UpdateStatuses> = (
  payload: Record<string, LoadingStatus>,
) => ({
  payload,
  type: UPDATE_STATUSES,
});

const CLEAR_PLANNED_SPEND_STATUSES = 'GRAPH_STATE/CLEAR_PLANNED_SPEND_STATUSES';
type ClearPlannedSpendStatuses = {
  type: typeof CLEAR_PLANNED_SPEND_STATUSES;
};
export const clearPlannedSpendGraphStatuses: ActionCreator<ClearPlannedSpendStatuses> = () => ({
  type: CLEAR_PLANNED_SPEND_STATUSES,
});

const CLEAR_STATUSES = 'GRAPH_STATE/CLEAR_STATUSES';
type ClearStatuses = {
  type: typeof CLEAR_STATUSES;
};
export const clearGraphStatuses: ActionCreator<ClearStatuses> = () => ({
  type: CLEAR_STATUSES,
});

type Actions = UpdateStatus | UpdateStatuses | ClearPlannedSpendStatuses | ClearStatuses;

export const reducer: Reducer<GraphStatus, Actions> = (
  state = initialState, // eslint-disable-line @typescript-eslint/default-param-last
  action,
): GraphStatus => {
  switch (action.type) {
    case UPDATE_STATUS:
      return {
        ...state,
        [action.payload.key]: updateBitMap(state.budgetLines, action.payload.loadingStatus),
      };

    case UPDATE_STATUSES:
      return {
        ...state,
        ...Object.entries(action.payload).reduce<GraphStatus>((acc, [month, status]) => {
          acc[month] = updateBitMap(state[month] ?? 0, status); // eslint-disable-line functional/immutable-data
          return acc;
        }, {}),
      };

    case CLEAR_PLANNED_SPEND_STATUSES:
      return {
        ...Object.entries(state).reduce<GraphStatus>((acc, [key, bitMap]) => {
          // For now, this is the fastest check if our cache key is a "month at
          // revision" (revisionGuid--yyyy-mm-dd). There aren't any other keys
          // that start with a guid, and for instance that are longer than 40px.
          if (key.length < 40) {
            acc[key] = bitMap; // eslint-disable-line functional/immutable-data
          }
          return acc;
        }, {}),
      };

    case CLEAR_STATUSES:
      return { ...initialState };

    default:
      return state;
  }
};

export const actions = {
  clearGraphStatuses,
  clearPlannedSpendGraphStatuses,
  updateGraphStatus,
  updateGraphStatuses,
};

// Hooks
export const graphStatusSelector = (state: { graphStatus: GraphStatus }): GraphStatus =>
  state.graphStatus;

export const useBudgetLinesStatus = (): LoadingStatus => {
  const status = useSelector(graphStatusSelector).budgetLines;
  return useMemo(() => toStatus(status), [status]);
};

/**
 * Get all the months in the specified range & revision, mapped to the loading
 * status of its planned spend, spend and proposed changes data.
 * @returns `{ <guid>--<yyyy-mm-dd>: { loaded, loading } }`
 */
export const useRevisionStatus = (
  revisionId: string,
  start: ISO8601String,
  end: ISO8601String,
): Record<ISO8601String, LoadingStatus> => {
  const graphStatus = useSelector(graphStatusSelector);

  return useMemo(() => {
    const months: ISO8601String[] = getMonthsInRange(start, addMonth(end));
    return months.reduce<Record<ISO8601String, LoadingStatus>>((acc, month) => {
      const revisionMonth = `${revisionId}--${month}`;
      const statusBitMap = graphStatus[revisionMonth] ?? 0;
      acc[month] = toStatus(statusBitMap); // eslint-disable-line functional/immutable-data
      return acc;
    }, {});
  }, [start, end, revisionId, graphStatus]);
};

type UseGraphStatus = {
  getStatus: (key: string) => LoadingStatus;
  setStatus: (key: string, loadingStatus: Partial<LoadingStatus>) => void;
  setStatuses: (statuses: Record<string, Partial<LoadingStatus>>) => void;
  clearPlannedSpendStatuses: () => void;
  clearStatuses: () => void;
};

/**
 * Allows you to get a status by key, set an status by key or set multiple
 * statuses by passing an dictionary of key-status.
 */
export const useGraphStatus = (): UseGraphStatus => {
  const dispatch = useDispatch();
  const graphStatus = useSelector(graphStatusSelector);

  const getStatus = (key: string): LoadingStatus => {
    // const statusTimer = timer(`getStatus(${key})`);
    const statusBitMap = graphStatus[key] ?? 0;
    const status = toStatus(statusBitMap);
    // statusTimer();
    return status;
  };

  const setStatus = (key: string, loadingStatus: Partial<LoadingStatus>) => {
    dispatch(actions.updateGraphStatus(key, loadingStatus));
  };

  const setStatuses = (statuses: Record<string, Partial<LoadingStatus>>) => {
    dispatch(actions.updateGraphStatuses(statuses));
  };

  const clearPlannedSpendStatuses = () => {
    dispatch(actions.clearPlannedSpendGraphStatuses());
  };

  const clearStatuses = () => {
    dispatch(actions.clearGraphStatuses());
  };

  return { clearPlannedSpendStatuses, clearStatuses, getStatus, setStatus, setStatuses };
};
