import { unstable_batchedUpdates as batch } from 'react-dom';

import { getWidestBySelector } from '@cobbler-io/dom/src/getWidest';

import { TableInstance } from 'react-table';

export const autosizeAll = <D extends UnknownObject>(instance: TableInstance<D>): void => {
  const selectors = instance.visibleColumns.map(column => `[data-col="${column.id}"]`);
  void getWidestBySelector(selectors, instance.tableRef.current).then(measurements => {
    batch(() => {
      instance.visibleColumns.forEach((column, index) => {
        instance.setColumnSize(
          column.id,
          Math.max(
            measurements[index] + 4,
            instance.state.columnResizing.columnWidths[column.id] ?? 0,
          ),
          column,
        );
      });
    });
  });
};
