/* eslint-disable complexity */
import * as React from 'react';

import { toInteger } from '@cobbler-io/utils/src/numbers/toInteger';

import { isDIV } from '@cobbler-io/dom/src/isDIV';

import { clamp } from 'ramda';

const getActiveCell = (active: Element | null): HTMLDivElement | null => {
  if (isDIV(active) && active.dataset.row && active.dataset.col) {
    return active;
  }

  return null;
};

const STATIC_PAGE_SIZE = 4;

const getNextCoords = (params: {
  event: React.KeyboardEvent<HTMLDivElement>;
  col: number;
  row: number;
  maxCol: number;
  maxRow: number;
}): { row: number; col: number } => {
  const { event, col, row, maxCol, maxRow } = params;
  if (event.metaKey) {
    switch (event.key) {
      case 'ArrowUp':
        return { col, row: 0 };
      case 'ArrowDown':
        return { col, row: maxRow };
      case 'ArrowLeft':
        return { col: 0, row };
      case 'ArrowRight':
        return { col: maxCol, row };
      default:
      // None
    }
  }

  switch (event.key) {
    case 'ArrowUp':
      return { col, row: row - 1 };
    case 'ArrowRight':
      return { col: col + 1, row };
    case 'ArrowDown':
      return { col, row: row + 1 };
    case 'ArrowLeft':
      return { col: col - 1, row };
    case 'Home':
      return { col: 0, row };
    case 'End':
      return { col: maxCol, row };
    case 'PageDown':
      return { col, row: row + STATIC_PAGE_SIZE };
    case 'PageUp':
      return { col, row: row - STATIC_PAGE_SIZE };
    default:
    // None
  }

  return { col, row };
};

export const useGridNavigationKeys = (
  colIds: string[],
  rows: any[],
  container?: HTMLElement | null,
): React.KeyboardEventHandler<HTMLDivElement> => {
  // TODO fix this when we group by (aggregated rows get a different column id)
  const onKeyDown: React.KeyboardEventHandler<HTMLDivElement> = React.useCallback(
    event => {
      if (!container) {
        return;
      }

      if (!document.activeElement || !(document.activeElement instanceof HTMLElement)) {
        return;
      }

      const el = getActiveCell(document.activeElement);

      if (!el) {
        return;
      }

      const { dataset: { col: currentCol, rowIndex: currentRowIndex } = {} } = el;

      const colIndex = colIds.findIndex((id: string) => id === currentCol);
      const rowIndex = currentRowIndex ? toInteger(currentRowIndex) : 0;
      const maxCol = colIds.length - 1;
      const maxRow = rows.length - 1;

      const { col, row } = getNextCoords({ event, col: colIndex, row: rowIndex, maxCol, maxRow });
      const nextCol = clamp(0, maxCol, col);
      const nextRow = clamp(0, maxRow, row);

      if (nextCol === colIndex && nextRow === rowIndex) {
        // There is no change
        return;
      }

      event.preventDefault();

      const nextColumn = colIds[nextCol];
      if (nextColumn && (nextColumn !== currentCol || `${nextRow}` !== currentRowIndex)) {
        const next = container.querySelector(
          `div[data-col="${nextColumn}"][data-row-index="${nextRow}"]`,
        );
        if (next && next instanceof HTMLElement && typeof next.focus === 'function') {
          next.focus();
        }
      }
    },
    [colIds, container],
  );

  return onKeyDown;
};
