/* eslint-disable functional/immutable-data */
/**
 * TODO Fix the linting problems around having a slightly interactive tooltip
 */

import * as React from 'react';

import { cx } from '@cobbler-io/utils/src';
import { noop } from '@cobbler-io/utils/src/noop';

import { useDefaultOrRandom } from '@cobbler-io/hooks/src/useDefaultOrRandom';
import { useInstanceVars } from '@cobbler-io/hooks/src/useInstanceVars';

import { Options } from '@popperjs/core';

import { Modal, useModal } from '../Modal';

import styles from './ToolTip.scss';

type ToolTipRenderProp = {
  instance: {
    closeTooltip: NullaryFn;
    isOpen: boolean;
    isFocused: boolean;
  };
};

type ToolTipProps = {
  id?: string;
  className?: string;
  /**
   * The text to display
   */
  tip: React.ReactNode;
  /**
   * The direction for the pop-up to appear
   *
   * default: `top-start`
   */
  direction?: Options['placement'];

  as?: React.ElementType;

  children: React.ReactNode | UnaryFn<ToolTipRenderProp, React.ReactNode>;
} & React.HTMLProps<HTMLDivElement>;

export type UseToolTipParams = {
  ref: React.RefObject<any>;
  tip: React.ReactNode;
  direction: ToolTipProps['direction'];
  id?: string;
};

type UseToolTipPayload = {
  'aria-describedby': string;
  instance: { closeTooltip: NullaryFn; isFocused: boolean; isOpen: boolean };
  onBlur: (event: React.SyntheticEvent<HTMLDivElement>) => void;
  onFocus: (event: React.SyntheticEvent<HTMLDivElement>) => void;
  onMouseEnter: (event: React.SyntheticEvent<HTMLDivElement>) => void;
  onMouseLeave: (event: React.SyntheticEvent<HTMLDivElement>) => void;
  onPointerLeave: (event: React.SyntheticEvent<HTMLDivElement>) => void;
  role: 'presentation';
  tabIndex: number;
};

export const useToolTip = (params: UseToolTipParams): UseToolTipPayload => {
  const { create } = useModal();
  const { ref, tip, id: initialId, direction = 'top-start' } = params;
  const self = useInstanceVars({ closeTooltip: noop, isFocused: false, isOpen: false });
  const id = useDefaultOrRandom(initialId);

  const onMouseEnter = React.useCallback(
    (event: React.SyntheticEvent<HTMLDivElement>) => {
      const { currentTarget } = event;

      event.preventDefault();

      if (currentTarget === ref.current && !self.isOpen) {
        self.isOpen = true;

        const modalTreeNode = create(
          <Modal arrow deferFocus className={styles.toolTip} clickAway="none" escape="close">
            <div id={`${id}-tooltip`} role="tooltip">
              <div className={cx(typeof tip === 'string' && styles.text)}>{tip}</div>
            </div>
          </Modal>,
          {
            onAfterClose: () => {
              self.closeTooltip = noop;
              self.isOpen = false;
            },
            placement: direction,
            target: ref.current,
          },
        );

        self.closeTooltip = (...args) => {
          console.log('Calling close tooltip', ...args);
          modalTreeNode.close(...args);
        };
      }
    },
    [tip, ref], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const onMouseLeave = React.useCallback(
    (_: React.SyntheticEvent<HTMLDivElement>) => {
      !self.isFocused && self.closeTooltip();
    },
    [tip, ref], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const onPointerLeave = React.useCallback(
    (_: React.SyntheticEvent<HTMLDivElement>) => {
      !self.isFocused && self.closeTooltip();
    },
    [tip, ref], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const onFocus = React.useCallback(
    (event: React.SyntheticEvent<HTMLDivElement>) => {
      self.isFocused = true;
      event.preventDefault();
      onMouseEnter(event);
    },
    [tip, ref], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const onBlur = React.useCallback(
    (_: React.SyntheticEvent<HTMLDivElement>) => {
      // For this to work properly, we have to disable `initialFocus` on
      // the Modal component.
      self.isFocused = false;
      self.closeTooltip();
    },
    [tip, ref], // eslint-disable-line react-hooks/exhaustive-deps
  );

  // Makes sure the tooltip gets closed after the component is unmounted
  React.useEffect(() => () => self.closeTooltip(), [self]);

  return {
    'aria-describedby': `${id}-tooltip`,
    instance: self,
    onBlur,
    onFocus,
    onMouseEnter,
    onMouseLeave,
    onPointerLeave,
    role: 'presentation',
    tabIndex: -1,
  };
};

export const ToolTip = ({
  as = 'div',
  id,
  tip,
  children,
  className,
  direction = 'top-start',
  ...props
}: ToolTipProps): JSX.Element => {
  const ref = React.useRef<HTMLDivElement>(null);
  const { instance, ...toolTipContainerProps } = useToolTip({
    direction,
    id,
    ref,
    tip,
  });

  /**
   * So, there is a weird bug with Chrome (and Safari) in how it applies text-decoration. Basically,
   * text-decoration is not overrideable unless you change the display type, so we want to
   * wrap strings in spans so that we can give elements a way to opt-out of the default text-decoration
   * that this component applies.
   */
  const renderChildren = (): React.ReactNode => {
    if (typeof children === 'function') {
      return children({ instance });
    }

    if (typeof children === 'string' || typeof children === 'number') {
      return <span>{children}</span>;
    }

    return children;
  };

  return React.createElement(
    as,
    {
      ...props,
      ref,
      className: cx(styles.container, className),
      id,
      ...toolTipContainerProps,
      tabIndex: 0,
    },
    renderChildren(),
  );
};

ToolTip.displayName = 'ToolTip';

export default ToolTip;
