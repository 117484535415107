import * as React from 'react';

import { pick } from '@cobbler-io/utils/src/pick';

import { useDispatch, useSelector } from 'react-redux';
import { ActionCreator, Reducer } from 'redux';

type State = {
  id: string | null;
  fullName: string | null;
  preferredName: string | null;
  email: string | null;
  profileImageUrl: string | null;
  acceptedTermsOfService: boolean;
  blocked: boolean;
};

const initialState: State = {
  acceptedTermsOfService: false,
  blocked: false,
  email: null,
  fullName: null,
  id: null,
  preferredName: null,
  profileImageUrl: null,
};

const UPDATE_PARTIAL = 'CURRENT_USER/UPDATE_PARTIAL';
type UpdateUserPartial = { type: 'CURRENT_USER/UPDATE_PARTIAL'; payload: Partial<State> };
export const updateUser: ActionCreator<UpdateUserPartial> = (payload: Partial<State>) => ({
  payload,
  type: UPDATE_PARTIAL,
});

const WIPE_USER = 'CURRENT_USER/WIPE_USER';
type WipeUser = { type: 'CURRENT_USER/WIPE_USER' };
export const wipeUser: ActionCreator<WipeUser> = () => ({ type: WIPE_USER });

type Actions = UpdateUserPartial | WipeUser;

export const reducer: Reducer<typeof initialState, Actions> = (
  state = initialState,
  action = {} as unknown as Actions,
) => {
  switch (action.type) {
    case UPDATE_PARTIAL:
      return { ...state, ...action.payload };
    case WIPE_USER:
      return initialState;
    default:
      return state;
  }
};

export const actions = {
  updateUser,
  wipeUser,
};

const getUser = ({ currentUser }: { currentUser: State }) =>
  pick(['id', 'email', 'fullName', 'preferredName', 'profileImageUrl'], currentUser);

export type User = Expand<ReturnType<typeof getUser>>;

const getOnboarding = ({ currentUser }: { currentUser: State }) =>
  pick(['acceptedTermsOfService', 'blocked'], currentUser);

type Onboarding = Expand<ReturnType<typeof getOnboarding>>;

export const useCurrentUser = (): User => useSelector<{ currentUser: State }, User>(getUser);

export const useOnboardingValues = (): Onboarding =>
  useSelector<{ currentUser: State }, Onboarding>(getOnboarding);

export const useSetUser = (): UnaryFn<Partial<State>, void> => {
  const dispatch = useDispatch();
  return React.useCallback((user: Partial<State>) => dispatch(updateUser(user)), [dispatch]);
};
