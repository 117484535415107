import { useLocalCache } from './useLocalCache';
import useToggle, { UseTogglePayload } from './useToggle';

type UseCachedToggle = (cacheName: string, defaultOpen?: boolean) => UseTogglePayload;

/**
 * A hook that returns a toggle state and a function to toggle it.
 * The state is cached in local storage.
 */
export const useCachedToggle: UseCachedToggle = (cacheName, defaultOpen = true) => {
  const cache = useLocalCache({
    default: {
      open: defaultOpen,
    },
    name: cacheName,
  });
  const { open } = cache.get();

  return useToggle(open, active => {
    cache.set({
      open: active,
    });
  });
};
