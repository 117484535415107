import React from 'react';

import Heading from '@cobbler-io/core-ui/src/Heading';

export type BudgetLineUploaderStepProps = {
  title: string;
  subtitle?: string;
  children: React.ReactNode;
};
export const BudgetLineUploaderStep = ({
  title,
  subtitle,
  children,
}: BudgetLineUploaderStepProps) => (
  <>
    <header>
      <Heading size="title">{title}</Heading>
      {subtitle && (
        <Heading as="h3" size="body-large">
          {subtitle}
        </Heading>
      )}
    </header>
    <section>{children}</section>
  </>
);
BudgetLineUploaderStep.displayName = 'BudgetLineUploaderStep';
