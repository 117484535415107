/* eslint-disable no-bitwise, func-style */

/**
 * Truncates a number
 *
 * @param precision The precision round to
 * @param number The number to round
 *
 * #!IE11
 */
export function trunc(precision: number): UnaryFn<number>;
export function trunc(precision: number, number: number): number;
export function trunc(precision: number, number?: number): UnaryFn<number> | number {
  const modifier = 10 ** precision;

  if (typeof number === 'undefined') {
    return (x: number) => Math.floor(x * modifier) / modifier;
  }

  return Math.floor(number * modifier) / modifier;
}
