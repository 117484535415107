import * as ReactIs from 'react-is';

export const isReactElementPrimitive = <T extends string>(type: T, x: unknown): x is T => {
  if (typeof type !== 'string') {
    return false;
  }

  if (!ReactIs.isElement(x)) {
    return false;
  }

  if (typeof x.type !== 'string') {
    return false;
  }

  return x.type === type;
};

export const isReactFunctionalComponent = <T extends React.JSXElementConstructor<any>>(
  x: unknown,
  type: T,
): x is T => {
  if (!x) {
    return false;
  }

  if (!ReactIs.isElement(x)) {
    return false;
  }

  if (typeof x.type === 'string') {
    return false;
  }

  if (typeof x !== 'object' || !('type' in x) || typeof x.type !== 'function') {
    return false;
  }

  return x.type === type;
};

export const isReactElementOfType =
  <T extends string | React.JSXElementConstructor<any>>(type: T) =>
  (x: unknown): x is T => {
    if (typeof type === 'string') {
      return isReactElementPrimitive(type, x);
    }

    if (typeof type === 'function') {
      return isReactFunctionalComponent(x, type);
    }

    return false;
  };
