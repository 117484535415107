/* eslint-disable react/boolean-prop-naming */
import * as React from 'react';

import { cx } from '@cobbler-io/utils/src/cx';

import { Icon, IconTypes } from '@cobbler-io/core-ui/src/Icon';

import scrollIntoView, { Options as ScrollOptions } from 'scroll-into-view-if-needed';

import styles from './Callout.scss';

export type CalloutProps = {
  children: React.ReactNode;
  autoFocus?: boolean;
  title?: React.ReactNode;
  iconType?: IconTypes;
  className?: string;
  style?: React.CSSProperties;
};

const scrollOptions: ScrollOptions = {
  behavior: 'smooth',
  block: 'nearest',
  inline: 'nearest',
  scrollMode: 'if-needed',
};

export const Callout = (props: CalloutProps): JSX.Element => {
  const { children, title, iconType, className, autoFocus = false, ...rest } = props;
  const ref = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    autoFocus && ref.current && scrollIntoView(ref.current, scrollOptions);
  }, [ref, autoFocus]);

  if (title) {
    return (
      <div
        {...rest}
        ref={ref}
        className={cx(styles.callout, styles.withTitle, className)}
        tabIndex={-1}
      >
        <div className={styles.title}>
          {iconType && <Icon type={iconType} />}
          <span>{title}</span>
        </div>
        <div className={styles.body}>{children}</div>
      </div>
    );
  }

  return (
    <div {...rest} ref={ref} className={cx(styles.callout, className)} tabIndex={-1}>
      {iconType && <Icon type={iconType} />}
      <span>{children}</span>
    </div>
  );
};

Callout.displayName = 'Callout';

export const InfoCallout = ({ className, ...props }: CalloutProps): JSX.Element => (
  <Callout iconType="infoFilled" {...props} className={cx(styles.info, className)} />
);

InfoCallout.displayName = 'InfoCallout';

export const SuccessCallout = ({ className, ...props }: CalloutProps): JSX.Element => (
  <Callout iconType="checkCircleFilled" {...props} className={cx(styles.success, className)} />
);

SuccessCallout.displayName = 'SuccessCallout';

export const WarningCallout = ({ className, ...props }: CalloutProps): JSX.Element => (
  <Callout iconType="warning" {...props} className={cx(styles.warning, className)} />
);

WarningCallout.displayName = 'WarningCallout';

export const ErrorCallout = ({ className, ...props }: CalloutProps): JSX.Element => (
  <Callout iconType="closeCircleFilled" {...props} className={cx(styles.error, className)} />
);

ErrorCallout.displayName = 'ErrorCallout';

export default Callout;
