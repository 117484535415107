import * as React from 'react';

import { setIn } from '@cobbler-io/utils/src/setIn';

import { useSearchParams } from '@cobbler-io/hooks/src/useSearchParams';

import { useNavigate } from '@reach/router';
import qs from 'qs';
import { either, isEmpty, isNil, omit } from 'ramda';

type UnknownObject = Record<string, unknown>;

type ReactStateSetter = {
  <S extends UnknownObject = UnknownObject>(initialState?: S | (() => S), namespace?: string): [
    S,
    React.Dispatch<React.SetStateAction<S>>,
  ];
};

const emptyObject = {};

const getNestedValue = (params: UnknownObject, pathArr: string[]): UnknownObject => {
  return pathArr.reduce(
    (obj, key) => (obj && obj[key] !== 'undefined' ? obj[key] : undefined),
    params,
  ) as UnknownObject;
};

// Clean object of all keys that are undefined / null / empty strings / empty arrays / empty objects
const cleanEmptyKeys = (obj: UnknownObject): UnknownObject => {
  return Object.keys(obj).reduce((acc, key) => {
    const value = obj[key];
    const isNilOrEmpty = either(isNil, isEmpty);
    return isNilOrEmpty(value) ? acc : { ...acc, [key]: value };
  }, {});
};

export const useURLState: ReactStateSetter = <S extends UnknownObject>(
  defaultValue = emptyObject,
  namespace = 'global',
) => {
  const navigate = useNavigate();
  const params = useSearchParams();

  const segments = namespace.split('.');
  const initialValue = getNestedValue(params, segments) ?? {};

  // Check the query string for a namespace and if it exists, merge it with the default value.
  // If it doesn't exist, use the default value.
  const state = initialValue ? { ...defaultValue, ...initialValue } : defaultValue;

  const setState: React.Dispatch<React.SetStateAction<S | undefined>> = x => {
    if (!x) {
      return;
    }
    const { pathname } = window.location;
    const next = cleanEmptyKeys(typeof x === 'function' ? x(state) : x);
    const urlState = setIn(params, namespace, next);

    if (
      Object.keys(urlState).length === 0 ||
      Object.keys(getNestedValue(urlState, segments)).length === 0
    ) {
      // Remove the namespace from the URL if it is empty.
      const nextUrlState = omit([namespace], urlState);
      const searchParams = qs.stringify(nextUrlState);
      // If the searchParams are empty, then we'll just navigate to the pathname
      const url = searchParams ? `${pathname}?${searchParams}` : pathname;
      void navigate(url);
    } else {
      void navigate(`${pathname}?${qs.stringify(urlState)}`);
    }
  };

  return [state, setState];
};
