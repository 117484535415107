import * as React from 'react';

import { navigate } from '@reach/router';

type SignOutProps = Record<string, unknown>;

export const SignOut: React.FC<SignOutProps> = () => {
  React.useEffect(() => {
    navigate('/login', { replace: true });
  }, []);

  return <div />;
};
