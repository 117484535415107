import curry from 'lodash/curry';

const internalProp = <T extends Record<string, any>>(
  property: string,
  obj: T,
): T[typeof property] => obj[property];

/**
 * Gets a property from an object (supports currying)
 *
 * @todo make this a generic somehow
 */
export const prop = curry<string, Record<string, any>, any>(internalProp);
