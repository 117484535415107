import * as React from 'react';

import { getBy } from '@cobbler-io/utils/src';
import { head, tail } from '@cobbler-io/utils/src/array';
import { getHalf, getQuarter } from '@cobbler-io/utils/src/fiscal-year-dates';
import { prop } from '@cobbler-io/utils/src/prop';
import { propIs } from '@cobbler-io/utils/src/propIs';

import { ISO8601 } from '@cobbler-io/formatters/src';
import { floatString } from '@cobbler-io/formatters/src/numbers';

import { getFiscalYearDates } from '@cobbler-io/dates/src';
import { tzAdjust } from '@cobbler-io/dates/src/tzAdjust';

import { useCurrencyFormatter, useMinorUnit } from '@cobbler-io/redux/src/modules/currency';

import { BudgetResolution } from '@cobbler-io/app/src/api';

import { BudgetLineAtRevision } from '../types';
import { createDivisorMap } from './createDivisorMap';
import { DatePeriod, DatePeriodType, getDatePeriods } from './getDatePeriods';
import { getMaxAmounts } from './getMaxAmounts';
import { getRecurrenceOptions } from './getRecurrenceOptions';

type RecurringExpenseParams = {
  parent: BudgetLineAtRevision;
  current: BudgetLineAtRevision | null;
  isRevenueRootChild: boolean;
  overplannedThreshold?: MinorCurrency;
  skipAmountValidation?: boolean;
  maxHintLabel?: string;
};

type SetAmount = { type: 'SET_AMOUNT'; payload: number };
type SetStart = { type: 'SET_START'; payload: ISO8601String };
type SetEnd = { type: 'SET_END'; payload: ISO8601String };

type SetRecurrence = { type: 'SET_RECURRENCE'; payload: DatePeriodType };

type Action = SetAmount | SetStart | SetEnd | SetRecurrence;

type Item = { label: string; value: string };

type State = {
  /**
   * The amount for recurring / once expenses
   */
  amount: MinorCurrency;
  /**
   * The default for recurring / once expenses
   */
  defaultAmount: MinorCurrency;
  /**
   * The start of a repeating range
   */
  start: ISO8601String;
  /**
   * The minimum start of a repeating range
   */
  minStart: ISO8601String;
  /**
   * The maximum start of a repeating range
   */
  maxStart: ISO8601String;
  /**
   * The end of a repeating range
   */
  end: ISO8601String;
  /**
   * The minimum end of a repeating range
   */
  minEnd: ISO8601String;
  /**
   * The maximum end of a repeating range
   */
  maxEnd: ISO8601String;
  recurrence: DatePeriodType;
  recurrenceOptions: Item[];
  /**
   * The fiscal year start month
   *
   * This is needed internally in the reducer for determining different recurrence options
   */
  fysm: number;
  /**
   * The budget resolution
   *
   * This is needed internally in the reducer for determining different recurrence options
   */
  resolution: BudgetResolution;

  datePeriods: DatePeriod[];

  /**
   * These are the options for a
   */
  datePeriodStartOptions: (Item & { disabled?: boolean })[];
  datePeriodEndOptions: (Item & { disabled?: boolean })[];

  overplannedThreshold: MinorCurrency;
  max: MinorCurrency;
  getMax: (params: GetMaxParams) => MinorCurrency;
  maxHintLabel?: string;
};

const bindDispatchToActions = (dispatch: React.Dispatch<Action>) => {
  return {
    setAmount: (payload: MinorCurrency) => dispatch({ type: 'SET_AMOUNT', payload }),
    setStart: (payload: ISO8601String) => dispatch({ type: 'SET_START', payload }),
    setEnd: (payload: ISO8601String) => dispatch({ type: 'SET_END', payload }),
    setRecurrence: (payload: DatePeriodType) => dispatch({ type: 'SET_RECURRENCE', payload }),
  };
};

type Handlers = {
  handleChangeSetStart: React.ChangeEventHandler<HTMLSelectElement>;
  handleChangeSetEnd: React.ChangeEventHandler<HTMLSelectElement>;
  handleChangeAmount: React.ChangeEventHandler<HTMLInputElement>;
  handleChangeRecurrence: React.ChangeEventHandler<HTMLSelectElement>;
};

const createHandlers = (
  actions: ReturnType<typeof bindDispatchToActions>,
  conversions: { convertAmount: (val: string) => MinorCurrency },
): Handlers => ({
  handleChangeSetStart: (event: React.ChangeEvent<HTMLSelectElement>) =>
    actions.setStart(event.currentTarget.value),
  handleChangeSetEnd: (event: React.ChangeEvent<HTMLSelectElement>) =>
    actions.setEnd(event.currentTarget.value),
  handleChangeAmount: (event: React.ChangeEvent<HTMLInputElement>) =>
    actions.setAmount(conversions.convertAmount(event.currentTarget.value)),
  handleChangeRecurrence: (event: React.ChangeEvent<HTMLSelectElement>) =>
    actions.setRecurrence(event.currentTarget.value as DatePeriodType),
});

type ValidatorReturn = string[] | false;
type Validator = (val: string) => ValidatorReturn;
type DateValidators = {
  validateStart: Validator;
  validateEnd: Validator;
};

const createDateValidations = (state: State): DateValidators => {
  const find = (haystack: Item[], needle: string): number =>
    haystack.findIndex(propIs('value'), needle);

  return {
    validateStart: val => {
      const [start, end] = [
        find(state.datePeriodStartOptions, val),
        find(state.datePeriodEndOptions, state.end),
      ];
      return start > end ? ['The start must not be after the end'] : false;
    },
    validateEnd: val => {
      const [start, end] = [
        find(state.datePeriodStartOptions, state.start),
        find(state.datePeriodEndOptions, val),
      ];
      return start > end ? ['The end must be before the start'] : false;
    },
  };
};

const findRepeatingValue = <T extends number[]>(
  arr: T,
): T extends (infer U)[] ? { value: U; startIndex: number; endIndex: number } : never => {
  const value = arr.find(x => x) ?? 0;
  const startIndex = arr.indexOf(value) ?? 0;
  const endIndex = arr.lastIndexOf(value) ?? arr.length - 1;

  // @ts-expect-error this is fine. It chokes on handling the `infer U` part
  return { value, startIndex, endIndex };
};

const getStartDateOptions = (datePeriods: DatePeriod[], end: ISO8601String) => {
  const endDate = tzAdjust(end);
  return datePeriods.map(({ name, start }) => ({
    label: name,
    disabled: endDate < start,
    value: ISO8601(start),
  }));
};

const getEndDateOptions = (datePeriods: DatePeriod[], start: ISO8601String) => {
  const startDate = tzAdjust(start);
  return datePeriods.map(({ name, end }) => ({
    label: name,
    disabled: end < startDate,
    value: ISO8601(end),
  }));
};

const findEndOfStartPeriod = (
  datePeriods: DatePeriod[],
  start: ISO8601String,
): ISO8601String | undefined => {
  const endOfStart = datePeriods.find(x => ISO8601(x.start) === start)?.end;
  return endOfStart ? ISO8601(endOfStart) : undefined;
};

/**
 * Updates the recurrence freqeuncy
 *
 * E.g. monthly -> quarterly or quarterly -> yearly, or quarterly -> monthly
 *
 * If we do that, then we might also need to change the start/end dates. Say, my selected options
 * say to recur an expense from `Feb -> May` and my fiscal year start month is Jan. If I swtich from
 * from monthly -> quarterly, then my selection set goes from `[Feb, Mar, Apr, May]` to
 * `[Partial Q1, Paretial Q2]`, which doesn't make as much sense to repeat something quarterly. Hence,
 * we need to widen the start and end dates so that the selection set becomes `[Q1, Q1]` or
 * `[Jan, Feb, Mar, Apr, May, Jun].` Since we have widened the selection set, we now have different
 * budget periods that may constrain us. E.g. if we have $1000 / month available, but we have already
 * encumbered $500 in Jan, then the max repeating expense will change when we widen that selection
 * set. Instead of letting $1000 fit into each period, we can now only fit $500 into Q1 because of
 * Jan constraints, so we use the $500 figure instead and come out with $1500 / quarter.
 */
const updateRecurrence = (prev: State, recurrence: DatePeriodType) => {
  const datePeriods = getDatePeriods({
    ...prev,
    budgetResolution: prev.resolution,
    min: tzAdjust(prev.minStart),
    max: tzAdjust(prev.maxEnd),
    type: recurrence,
  });

  const divisorMap = createDivisorMap({
    resolution: prev.resolution,
    fysm: prev.fysm,
    min: prev.minStart,
    max: prev.maxEnd,
  });

  const isOnce = recurrence === 'ONCE';

  // If we go from monthly -> quarterly, then $100 / 3 months should still be $100 / 3 months,
  // so we need to change the amount that's available.
  const conversion = getBy(divisorMap, [prev.recurrence, recurrence], 1);
  const amount = Math.floor((conversion * (prev.amount * 1e3)) / 1e3);

  const prevStartDate = tzAdjust(prev.start);
  const startRange = datePeriods.filter(p => p.start <= prevStartDate && prevStartDate < p.end);
  const nextStart = ISO8601(head(startRange)?.start ?? prevStartDate);
  const prevEndDate = tzAdjust(prev.end);
  const endRange = datePeriods.filter(p => p.start < prevEndDate && prevEndDate <= p.end);

  const nextEnd = isOnce
    ? findEndOfStartPeriod(datePeriods, nextStart) ?? ISO8601(prevEndDate)
    : ISO8601(tail(endRange)?.end ?? prevEndDate);

  return {
    ...prev,
    recurrence,
    amount,
    datePeriods,
    start: nextStart,
    datePeriodStartOptions: getStartDateOptions(datePeriods, isOnce ? prev.maxEnd : nextEnd),
    end: nextEnd,
    datePeriodEndOptions: getEndDateOptions(datePeriods, isOnce ? prev.minStart : nextStart),
    max: prev.getMax({ start: nextStart, end: nextEnd, recurrence }),
  };
};

const updateStart = (prev: State, nextStart: ISO8601String) => ({
  ...prev,
  start: nextStart,
  max: prev.getMax({ start: nextStart, end: prev.end, recurrence: prev.recurrence }),
  datePeriodEndOptions: getEndDateOptions(prev.datePeriods, nextStart),
});

const updateEnd = (prev: State, nextEnd: ISO8601String) => ({
  ...prev,
  end: nextEnd,
  max: prev.getMax({ start: prev.start, end: nextEnd, recurrence: prev.recurrence }),
  datePeriodStartOptions: getStartDateOptions(prev.datePeriods, nextEnd),
});

const updateForOnce = (prev: State, nextStart: ISO8601String) => {
  const nextEnd = findEndOfStartPeriod(prev.datePeriods, nextStart) ?? prev.end;
  return {
    ...prev,
    start: nextStart,
    end: nextEnd,
    max: prev.getMax({ start: nextStart, end: nextEnd, recurrence: prev.recurrence }),
  };
};

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SET_AMOUNT':
      return { ...state, amount: action.payload };
    case 'SET_START':
      return state.recurrence === 'ONCE'
        ? updateForOnce(state, action.payload)
        : updateStart(state, action.payload);
    case 'SET_END':
      return updateEnd(state, action.payload);
    case 'SET_RECURRENCE':
      return updateRecurrence(state, action.payload);
    default:
      return state;
  }
};

const findPeriodsInParent =
  (periods: BudgetLineAtRevision['intervals']) => (start: ISO8601String, end: ISO8601String) => {
    const startDate = tzAdjust(start);
    const endDate = tzAdjust(end);
    return periods.filter(p => {
      const [pStart, pEnd] = [p.start, p.end].map(tzAdjust);
      return startDate <= pStart && pEnd <= endDate;
    });
  };

type GetMaxParams = {
  start: ISO8601String;
  end: ISO8601String;
  recurrence: DatePeriodType;
};

const createGetMax = (
  parent: BudgetLineAtRevision,
  current: BudgetLineAtRevision | null,
  isRevenueRootChild: boolean,
): ((params: GetMaxParams) => MinorCurrency) => {
  // To find the max of a LooselyBound budget amount, we need to find the number of periods that
  // would be between the start and end and divide the total available by that and then convert it
  // with the divisor map. (e.g. if we have a resolution of months and we're trying to schdedule something
  // to repeat quarterly)
  const [minInterval, maxInterval] = [head(parent.intervals), tail(parent.intervals)];
  const [minStart] = [minInterval].map(prop('start'));
  const [maxEnd] = [maxInterval].map(prop('end'));
  const [min, max] = [minStart, maxEnd];
  const { maxAmounts } = getMaxAmounts(parent, isRevenueRootChild);

  const findPeriodsInSelection = findPeriodsInParent(parent.intervals);

  const { budgetResolution: resolution, fiscalYearStartMonth } = parent;
  const fysm = fiscalYearStartMonth - 1;

  const divisorMap = createDivisorMap({ resolution, fysm, min, max });

  const getMaxWithCurrent = (start: ISO8601String) =>
    maxAmounts[start] + (current?.plannedByInterval[start]?.allocated ?? 0);

  const getMaxForPeriod = (
    getPeriod: (date: LocalDate, startMonth: number) => { name: string },
    periods: BudgetLineAtRevision['intervals'],
  ): MinorCurrency => {
    const byPeriod = periods.reduce<Record<ISO8601String, MinorCurrency[]>>((acc, { start }) => {
      // Find the period that this date belongs in
      const period = getPeriod(tzAdjust(start), fysm);
      // Key the accumulator by period for an array of amounts
      acc[period.name] = (acc[period.name] ?? []).concat(getMaxWithCurrent(start));
      return acc;
    }, {});
    // Just grab the max per period
    const maxPerPeriod: MinorCurrency[] = Object.values(byPeriod).map(amounts =>
      Math.min(...amounts),
    );
    // Just grab the absolute max that we can have
    return Math.min(...maxPerPeriod);
  };

  return (params: GetMaxParams): MinorCurrency => {
    const selectedPeriods = findPeriodsInSelection(params.start, params.end);

    switch (params.recurrence) {
      case 'ONCE':
        return getMaxWithCurrent(head(selectedPeriods).start);
      case 'MONTH':
        return Math.min(...selectedPeriods.map(({ start }) => getMaxWithCurrent(start)));
      case 'QUARTER':
        return getMaxForPeriod(getQuarter, selectedPeriods) * 3;
      case 'HALF':
        return getMaxForPeriod(getHalf, selectedPeriods) * 6;
      case 'YEAR':
        return getMaxForPeriod(getFiscalYearDates, selectedPeriods) * 12;
      case 'FULL':
        return (
          Math.min(...selectedPeriods.map(({ start }) => getMaxWithCurrent(start))) *
          divisorMap.FULL[resolution]
        );
      default:
        return 0;
    }
  };
};

const useInitialState = (params: RecurringExpenseParams): State => {
  const { parent, current, overplannedThreshold = 0, maxHintLabel, isRevenueRootChild } = params;
  const minorUnit = useMinorUnit();
  return React.useMemo(() => {
    const resolution = parent.budgetResolution;
    const [minInterval, maxInterval] = [head(parent.intervals), tail(parent.intervals)];
    const [minStart, maxStart] = [minInterval, maxInterval].map(prop('start'));
    const [minEnd, maxEnd] = [minInterval, maxInterval].map(prop('end'));
    const recurrenceOptions = getRecurrenceOptions(resolution);
    const fysm = parent.fiscalYearStartMonth - 1;

    const defaultSpendValues: MinorCurrency[] = parent.intervals.map(int => {
      // IntervalAllocations no longer have end dates so we'll compare only the start
      // const needle = current?.planned.find(p => dateRangesAreEqual(int, p));
      const needle = current?.planned.find(p => p.start === int.start);

      return needle?.allocated ?? 0;
    });

    const { value: defaultAmount, startIndex, endIndex } = findRepeatingValue(defaultSpendValues);
    const datePeriods = getDatePeriods({
      type: resolution,
      min: tzAdjust(minStart),
      max: tzAdjust(maxEnd),
      budgetResolution: resolution,
      fysm,
    });

    const { start } = parent.intervals[startIndex];
    const { end } = parent.intervals[endIndex];

    // So, if they did "$300 repeating quarterly from Q1 to Q4," then we're not going to be able to
    // figure that out from reading the spend plans, because that would be interpreted instead as
    // "$100 repeating monthly from Jan to Dec" because they're the same mathematically. Thus, our
    // fallback is to see if it's a ONCE expense, otherwise, it'll be the budgetResolution.
    const recurrence = startIndex === endIndex ? 'ONCE' : parent.budgetResolution;

    const getMax = createGetMax(parent, current, isRevenueRootChild);

    return {
      start,
      minStart,
      maxStart,
      end,
      minEnd,
      maxEnd,

      amount: defaultAmount,
      defaultAmount,

      recurrence,
      recurrenceOptions,
      resolution,
      fysm: parent.fiscalYearStartMonth - 1, // convert FYSM to a JS month

      datePeriods,
      datePeriodStartOptions: getStartDateOptions(datePeriods, end),
      datePeriodEndOptions: getEndDateOptions(datePeriods, start),

      overplannedThreshold,
      max: getMax({ start, end, recurrence }),
      getMax,
      maxHintLabel,
    };
  }, [parent, current, overplannedThreshold]);
};

export type RecurringExpenseType = State &
  Handlers &
  DateValidators & { validateAmount: Validator };

/**
 * Handles recurring expenses as well as `once` expenses
 */
export const useRecurringExpense = (params: RecurringExpenseParams): RecurringExpenseType => {
  const [state, dispatch] = React.useReducer(reducer, useInitialState(params));
  const { simpleFromMinorUnit, convertToMinorUnit } = useCurrencyFormatter();
  const fns = React.useMemo(() => {
    const convertAmount = (val: string) => convertToMinorUnit(floatString(val));
    const actions = bindDispatchToActions(dispatch);
    const handlers = createHandlers(actions, { convertAmount });
    return handlers;
  }, [dispatch, convertToMinorUnit]);

  const dateValidations = createDateValidations(state);

  const validateAmount: Validator = val =>
    !params.skipAmountValidation && state.max + state.overplannedThreshold < convertToMinorUnit(val)
      ? [
          `Available budget ${simpleFromMinorUnit(
            state.max,
          )}. This change will propose an increase.`,
        ]
      : false;

  return {
    ...state,
    ...fns,
    ...dateValidations,
    validateAmount,
    maxHintLabel: params.maxHintLabel,
  };
};
