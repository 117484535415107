import memoize from 'lodash/memoize';

type ValidateMaxParams = {
  max: number;
  threshold: number;
  simpleFromMinorUnit: (amount: number) => string;
  convertToMinorUnit: (amount: number) => number;
  skipAmountValidation: boolean;
};

export const createValidateMax = memoize(
  ({
      max,
      threshold = 0,
      simpleFromMinorUnit,
      convertToMinorUnit,
      skipAmountValidation,
    }: ValidateMaxParams) =>
    (val: string) => {
      const value = convertToMinorUnit(+val);

      if (!skipAmountValidation && max + threshold < value) {
        return `Available budget ${simpleFromMinorUnit(
          max,
        )}. This change will propose an increase.`;
      }

      return false;
    },

  // Since we're passing more than one parameter to our memoized function and
  // lodash's memoize function only takes into consideration the first param,
  // we need to pass a reolver function that will generate a key (think of it
  // as a cache key).
  ({ max, threshold, skipAmountValidation }) => `${max}_${threshold}_${skipAmountValidation}`,
);
