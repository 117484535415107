import { assoc, identity, ifElse, indexBy, last, map, pick, prop, propEq, reject } from 'ramda';
import { ActionCreator, Reducer } from 'redux';

import type { Budget, CurrentBudgetState, Revision } from './types';

const keyRevisionsById = (revisions: Revision[]): Record<string, Revision> =>
  indexBy(prop('id'), revisions);

/**
 * Grabs only the relevant keys that define a revision as a revision
 *
 * This is helpful for removing `__typename`, etc... from a query to turn it into a
 * "revision"
 */
const pickRevision: UnaryFn<Revision, Revision> = pick(['id', 'name', 'isForecast', 'isRollingForecast', 'isLocked']);

const UPDATE_REVISIONS = 'CURRENT_BUDGET/UPDATE/REVISIONS';
export type UpdateRevisionsPayload = {
  originalRevisionId: BudgetRevisionId;
  activeRevisionId: BudgetRevisionId;
  rollingForecastRevisionId: BudgetRevisionId;
  visibleRevisionIds: BudgetRevisionId[] | readonly BudgetRevisionId[];
  revisions: Revision[] | readonly Revision[];
};

type UpdateRevisions = {
  type: typeof UPDATE_REVISIONS;
  payload: UpdateRevisionsPayload;
};

export const updateRevisions: ActionCreator<UpdateRevisions> = (
  payload: UpdateRevisionsPayload,
) => ({
  payload,
  type: UPDATE_REVISIONS,
});

const updateStateForRevisions = (
  state: CurrentBudgetState,
  action: UpdateRevisions,
): CurrentBudgetState => {
  if (!state) {
    return state;
  }

  const revisions = map(pickRevision, action.payload.revisions);
  const revisionMap = keyRevisionsById(revisions);
  const {
    activeRevisionId,
    originalRevisionId,
    rollingForecastRevisionId,
    visibleRevisionIds,
  } = action.payload;
  const selectedRevisionId = revisionMap[state.selectedRevisionId]
    ? state.selectedRevisionId
    : activeRevisionId;

  return {
    ...state,
    activeRevisionId,
    latestRevisionId: last(revisions)?.id ?? activeRevisionId,
    originalRevisionId,
    revisionMap,
    revisions,
    rollingForecastRevisionId,
    selectedRevisionId,
    visibleRevisionIds,
  };
};

const DELETE_REVISION = 'CURRENT_BUDGET/DELETE_REVISION';
type DeleteRevision = {
  type: typeof DELETE_REVISION;
  payload: BudgetRevisionId;
};

export const deleteRevision: ActionCreator<DeleteRevision> = (revisionId: BudgetRevisionId) => ({
  payload: revisionId,
  type: DELETE_REVISION,
});

const updateStateAfterDeleteRevision = (
  state: CurrentBudgetState | null,
  action: DeleteRevision,
): CurrentBudgetState | null => {
  if (!state) {
    return state;
  }

  const revisions = reject<Revision>(propEq('id', action.payload), state.revisions);

  // TODO HANDLE DELETING ACTIVE, ORIGINAL, ETC...
  return {
    ...state,
    revisionMap: keyRevisionsById(revisions),
    revisions,
  };
};

const UPDATE_REVISION_NAME = 'CURRENT_BUDGET/UPDATE_REVISION_NAME';

export type UpdateRevisionNamePayload = {
  revisionId: BudgetRevisionId;
  name: string;
};

type UpdateRevisionName = {
  type: typeof UPDATE_REVISION_NAME;
  payload: UpdateRevisionNamePayload;
};

export const updateRevisionName: ActionCreator<UpdateRevisionName> = (
  payload: UpdateRevisionNamePayload,
) => ({
  payload,
  type: UPDATE_REVISION_NAME,
});

const UPDATE_LOCK_STATUS = 'CURRENT_BUDGET/UPDATE_LOCK_STATUS';
type UpdateLockStatus = {
  type: typeof UPDATE_LOCK_STATUS;
  payload: {
    locked: BudgetRevisionId[];
    unlocked: BudgetRevisionId[];
  };
};

const SET_CURRENT_BUDGET = 'CURRENT_BUDGET/SET_BUDGET';
type UpdateCurrentBudget = { type: typeof SET_CURRENT_BUDGET; payload: Budget | null };

export const updateCurrentBudget: ActionCreator<UpdateCurrentBudget> = (budget: Budget | null) => ({
  payload: budget,
  type: SET_CURRENT_BUDGET,
});

const SET_ACTIVE_REVISION_ID = 'CURRENT_BUDGET/SET_ACTIVE_REVISION_ID';
type SetActiveRevisionId = {
  type: typeof SET_ACTIVE_REVISION_ID;
  payload: { activeRevisionId: BudgetRevisionId };
};

export const setActiveRevisionId: ActionCreator<SetActiveRevisionId> = (
  activeRevisionId: BudgetRevisionId,
) => ({
  payload: { activeRevisionId },
  type: SET_ACTIVE_REVISION_ID,
});

type SetRevisionIdsPayload = {
  original: BudgetRevisionId;
  active: BudgetRevisionId;
  latest: BudgetRevisionId;
  selected?: BudgetRevisionId;
  visible: BudgetRevisionId[];
};

const SET_REVISION_IDS = 'CURRENT_BUDGET/SET_REVISION_IDS';
type SetRevisionIds = {
  type: typeof SET_REVISION_IDS;
  payload: SetRevisionIdsPayload;
};

export const setRevisionIds: ActionCreator<SetRevisionIds> = (payload: SetRevisionIdsPayload) => ({
  payload,
  type: SET_REVISION_IDS,
});

const SET_SELECTED_REVISION_ID = 'CURRENT_BUDGET/SET_SELECTED_REVISION_ID';
type SetSelectedRevisionId = {
  type: typeof SET_SELECTED_REVISION_ID;
  payload: { selectedRevisionId: string };
};

export const setSelectedRevisionId: ActionCreator<SetSelectedRevisionId> = (
  selectedRevisionId: string,
) => ({
  payload: { selectedRevisionId },
  type: SET_SELECTED_REVISION_ID,
});

export const updateRevisionsLockStatus: ActionCreator<UpdateLockStatus> = (payload: {
  locked: BudgetRevisionId[];
  unlocked: BudgetRevisionId[];
}) => ({
  payload,
  type: UPDATE_LOCK_STATUS,
});

type Actions =
  | SetActiveRevisionId
  | SetSelectedRevisionId
  | SetRevisionIds
  | UpdateCurrentBudget
  | UpdateLockStatus
  | UpdateRevisions
  | DeleteRevision
  | UpdateRevisionName;

const initialState: CurrentBudgetState | null = null;

// eslint-disable-next-line complexity
export const reducer: Reducer<CurrentBudgetState | null, Actions> = (
  state = initialState,
  action,
): CurrentBudgetState | null => {
  switch (action.type) {
    case SET_CURRENT_BUDGET:
      if (action.payload === null) {
        return null;
      }
      return {
        ...action.payload,
        revisionMap: keyRevisionsById(action.payload.revisions),
      };
    case SET_SELECTED_REVISION_ID:
    // intentional fallthrough
    case SET_ACTIVE_REVISION_ID:
      if (!state) {
        return state;
      }
      return { ...state, ...action.payload };
    case SET_REVISION_IDS:
      if (!state) {
        return state;
      }
      return {
        ...state,
        activeRevisionId: action.payload.active ?? state.activeRevisionId,
        latestRevisionId: action.payload.latest ?? state.latestRevisionId,
        originalRevisionId: action.payload.original ?? state.originalRevisionId,
        selectedRevisionId: action.payload.selected ?? state.selectedRevisionId,
        visibleRevisionIds: action.payload.visible ?? state.visibleRevisionIds,
      };
    case UPDATE_LOCK_STATUS: {
      if (!state) {
        return state;
      }
      const nextRevisions = state.revisions.map(r => {
        if (action.payload.locked.includes(r.id) && !r.isLocked) {
          return { ...r, isLocked: true };
        }

        if (action.payload.unlocked.includes(r.id) && r.isLocked) {
          return { ...r, isLocked: false };
        }

        return r;
      });
      return {
        ...state,
        revisionMap: keyRevisionsById(nextRevisions),
        revisions: nextRevisions,
      };
    }
    case UPDATE_REVISIONS:
      if (!state) {
        return state;
      }

      return updateStateForRevisions(state, action);

    case DELETE_REVISION:
      if (!state) {
        return state;
      }

      return updateStateAfterDeleteRevision(state, action);

    case UPDATE_REVISION_NAME:
      if (!state) {
        return state;
      }

      return {
        ...state,
        revisions: map(
          ifElse(
            propEq('id', action.payload.revisionId),
            assoc('name', action.payload.name),
            identity,
          ),
          state.revisions,
        ),
      };
    default:
      return state;
  }
};

export const actions = { updateCurrentBudget };
