/* eslint-disable @typescript-eslint/no-explicit-any */
import { createContext, useContext } from 'react';

import { ClickAwayTarget } from './types';

export type ClickAwayContextType<T extends HTMLElement> = {
  register: (ref: ClickAwayTarget<T>, callback: AnyFn) => void;
  unregister: (ref: ClickAwayTarget<T>, callback: AnyFn) => void;
};

export const ClickAwayContext = createContext<ClickAwayContextType<HTMLElement>>({
  register: () => {},
  unregister: () => {},
});

export const useClickAwayContext = <T extends HTMLElement>(): ClickAwayContextType<T> =>
  useContext(ClickAwayContext);

ClickAwayContext.displayName = 'ClickAwayContext';
