import { getRadixPoint } from './get-separator';

export const percentage = (n: number | string, d: number = 0): string =>
  `${(Number(n) * 100).toFixed(d)}%`;

const numberFormatter = new Intl.NumberFormat();
export const formattedNumber = (input: number) => numberFormatter.format(input);

export const integerFormatter = new Intl.NumberFormat(undefined, {
  notation: 'standard', // chrome only...
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

export const twoDecimalFormatter = new Intl.NumberFormat(undefined, {
  notation: 'standard', // chrome only...
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

export const integer = (n: number): string => integerFormatter.format(n);
export const twoDecimal = (n: number): string => twoDecimalFormatter.format(n);

export const floatString = (value: string): string => {
  // This gets the radixPoint for the user's locale as set in the browser.
  const radixPoint = getRadixPoint();
  // We'll escape the radix point in case it's a period
  const radixRegex = new RegExp(`\\${radixPoint}`);

  return value
    .replace(radixRegex, '┤')
    .replace(/^-/, '├') // placeholder on first sign & period
    .replace(/[^0-9├┤]/gi, '') // cleanup
    .replace('┤', radixPoint)
    .replace('├', '-'); // restore first sign & period
};

const numOrZero = (x: any): number => {
  if (typeof x === 'number') {
    return x;
  }

  if (typeof x === 'string') {
    const val = parseFloat(floatString(x) || '0');
    return Number.isNaN(val) ? 0 : val;
  }

  return 0;
};

export const createFloatString = (locale: string): UnaryFn<string> => {
  const radixPoint = getRadixPoint(locale);

  return (value: string) => {
    // We'll escape the radix point in case it's a period
    const radixRegex = new RegExp(`\\${radixPoint}`);

    return value
      .replace(radixRegex, '┤')
      .replace(/^-/, '├') // placeholder on first sign & period
      .replace(/[^0-9├┤]/gi, '') // cleanup
      .replace('┤', radixPoint)
      .replace('├', '-'); // restore first sign & period
  };
};

export const createSimpleMoneyFormatter = (
  minorUnit: number,
  radixPoint: string,
): UnaryFn<string | number, string> => {
  const standard = new Intl.NumberFormat(undefined, {
    notation: 'standard', // chrome only...
    minimumFractionDigits: minorUnit,
    maximumFractionDigits: minorUnit,
  }).format;

  const rounded = new Intl.NumberFormat(undefined, {
    notation: 'standard', // chrome only...
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format;

  return (value: string | number) => {
    const numberValue = numOrZero(value);

    if (numberValue % 1 === 0) {
      return rounded(numberValue);
    }

    const stringValue = standard(numberValue);

    const zeroEnd = [radixPoint, '0'.repeat(minorUnit)].join('');

    if (stringValue.endsWith(zeroEnd)) {
      return stringValue.slice(0, zeroEnd.length * -1);
    }

    return stringValue;
  };
};

export const simpleMoney: UnaryFn<string | number, string> = (value: string | number) => {
  const numberValue = numOrZero(value);

  if (numberValue % 1 === 0) {
    return integer(numberValue);
  }

  const stringValue = twoDecimal(numberValue);

  const radixPoint = getRadixPoint();
  const zeroEnd = `${radixPoint}00`;

  if (stringValue.endsWith(zeroEnd)) {
    return stringValue.slice(0, zeroEnd.length * -1);
  }

  return stringValue;
};
