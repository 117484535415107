import { BudgetResolution } from '@cobbler-io/app/src/api';

import { DatePeriodType, getDatePeriods } from './getDatePeriods';

type CreateDivisorMapParams = {
  resolution: BudgetResolution;
  min: ISO8601String;
  max: ISO8601String;
  fysm?: number;
};

type DivisorSubMap = Record<DatePeriodType, number>;

type DivisorMap = Record<DatePeriodType, DivisorSubMap>;

export const createDivisorMap = (params: CreateDivisorMapParams): DivisorMap => {
  const { resolution, min, max, fysm } = params;
  const budgetResolutions: DatePeriodType[] = ['MONTH', 'QUARTER', 'HALF', 'YEAR', 'FULL'];

  const periods = getDatePeriods({
    budgetResolution: resolution,
    fysm,
    min,
    max,
    type: 'MONTH',
  });

  const lengths = {
    MONTH: 1,
    QUARTER: 3,
    HALF: 6,
    YEAR: 12,
    FULL: periods.length,
  };

  return budgetResolutions.reduce<DivisorMap>(
    (acc, res) => {
      const length = lengths[res];

      return {
        ...acc,
        [res]: {
          ONCE: 1,
          MONTH: lengths.MONTH / length,
          QUARTER: lengths.QUARTER / length,
          HALF: lengths.HALF / length,
          YEAR: lengths.YEAR / length,
          FULL: lengths.FULL / length,
        },
      };
    },
    { ONCE: { MONTH: 1, QUARTER: 1, HALF: 1, YEAR: 1, FULL: 1 } },
  );
};
