import { Graph } from '@cobbler-io/collection/src/Graph';

import { HydrateBudgetGraphQuery } from '../graphql-types';

type Data = NonNullable<HydrateBudgetGraphQuery['users']>;

type HydrateUsersParams = {
  graph: Graph<any, any>;
  data: Data;
};

// Pull these to a higher scope so that we don't have to garbage collect as many of them
const regularUser = ['USER', 'NOT_DELETED'];
const deletedUser = ['USER', 'DELETED'];

export const hydrateUsers = (params: HydrateUsersParams): void => {
  const { graph, data } = params;

  // Cycle through the responses and create vertexes
  for (const user of data) {
    graph.addVertex({
      id: user.id,
      labels: user.deleted ? deletedUser : regularUser,
      properties: {
        deleted: user.deleted,
        email: user.email,
        fullName: user.fullName,
        preferredName: user.preferredName,
        profileImageUrl: user.profileImageUrl,
      },
    });
  }
};
