/* eslint-disable consistent-return */
import * as React from 'react';
import { unstable_batchedUpdates as batch } from 'react-dom';

import { execIfFunc } from '@cobbler-io/utils/src';

import debounce from 'lodash/debounce';

import { useIsMounted } from './useIsMounted';
import { useLatest } from './useLatest';

type Rect = {
  height: number | undefined;
  width: number | undefined;
};

type MeasureType = 'borderBoxSize' | 'contentBoxSize' | 'devicePixelContentBoxSize' | 'contentRect';

export type UseResizeObserverParams<T extends Element> = {
  /**
   * The ref of the thing to use. Can also just be an element
   */
  ref: React.RefObject<T> | T;
  /**
   * An optional callback that will be called when the observed DOM element resizes
   */
  onResize?: (rect: Rect) => any;
  /**
   * What exactly to observer.
   *
   * The default is `border-box`
   */
  observe?: ResizeObserverBoxOptions;

  /**
   * What to actually measure
   *
   * defaults depend on `observe`. Here is the default mapping:
   * ```
   *  'border-box' -> 'borderBoxSize'
   *  'content-box' -> 'contentBoxSize',
   *  'device-pixel-content-box' -> 'contentRect',
   * ```
   *
   * However, you can observe `border-box` and measure `contentRect`.
   *
   * Note: there are some browser constraints depending on the resize observer, so sometimes
   * if you need to measure `borderBoxSize`, we may instead measure `contentRect`
   */
  measure?: MeasureType;

  defaultHeight?: number;

  defaultWidth?: number;

  /**
   * Makes the resize observer only call updates when a single dimension changes.
   *
   * E.g. we care about the width but not the height, so we can just report on width
   */
  only?: 'height' | 'width';

  /**
   * The debounce timeout used
   *
   * Default: `16`
   */
  timeout?: number;
};

const getDimension =
  (measure: MeasureType, dimension: 'inlineSize' | 'blockSize') =>
  (entry: ResizeObserverEntry): number => {
    if (measure === 'contentRect') {
      return entry.contentRect[dimension === 'inlineSize' ? 'width' : 'height'];
    }

    if (measure in entry) {
      // Chrome 84 turned this into an array
      if (Array.isArray(entry[measure])) {
        // @ts-expect-error: the if statement above should typeguard this
        const [e] = entry[measure];
        return e[dimension];
      }

      return entry[measure][dimension];
    }

    // @ts-expect-error: this is a fallback for an older implementation of the ResizeObserver
    return entry.contentRect[dimension === 'inlineSize' ? 'width' : 'height'];
  };

type UseResizeObserverReturn<T extends Element> = {
  ref: React.RefObject<T> | T;
  /**
   * Forces an update to destroy / create a resize observer
   *
   * This is helpful when the rendering does not immediately give you a useable ref, but it
   * will cause more re-renders, so avoid it if possible
   */
  reattach: () => void;
} & Rect;

const getElement = <T extends Element>(ref: React.RefObject<T> | T): T | null => {
  if (ref && 'current' in ref) {
    return ref.current;
  }

  return ref;
};

const getDefaultMeasure = (observe: ResizeObserverBoxOptions) => {
  const defaults = {
    'border-box': 'borderBoxSize',
    'content-box': 'contentBoxSize',
    'device-pixel-content-box': 'devicePixelContentBoxSize',
  } as const;

  return defaults[observe];
};

/**
 * Observes a React.Ref for size changes
 */
export const useResizeObserver = <T extends Element>(
  params: UseResizeObserverParams<T>,
): UseResizeObserverReturn<T> => {
  const {
    onResize,
    ref,
    defaultHeight,
    defaultWidth,
    only,
    observe = 'border-box',
    measure = getDefaultMeasure(observe),
    timeout = 16,
  } = params;
  const callback = useLatest(onResize);
  const observer = React.useRef<ResizeObserver | null>(null);
  const [size, setSize] = React.useState<Rect>({ width: defaultWidth, height: defaultHeight });
  const isMounted = useIsMounted();
  const [updateCount, setUpdateCount] = React.useState<number>(0);
  const forceUpdate = React.useCallback(() => setUpdateCount(prev => prev + 1), [setUpdateCount]);
  const prevRef = React.useRef<Rect>({ width: defaultWidth, height: defaultHeight });
  const element = getElement(ref);

  const observerCallback: ResizeObserverCallback = React.useMemo(() => {
    const update = (height: number, width: number) => {
      if (!isMounted.current) {
        return;
      }
      // A user supplied callback might have some `useState` logic in it, so we'll batch
      // everything as a single update to ensure a more synchronized UI
      batch(() => {
        const rect = { height, width };
        // Copy the rect into the `prev` value
        prevRef.current = { ...rect };
        setSize(rect);
        // If the user supplied a callback, then call it but copy the rect in case it mutates
        execIfFunc(callback.current, { ...rect });
      });
    };

    const cb: ResizeObserverCallback = entries => {
      if (!Array.isArray(entries)) {
        // Something is wrong with the observer, so do nothing
        return;
      }

      if (!isMounted.current) {
        return;
      }

      const [entry] = entries;

      const nextWidth = Math.round(getDimension(measure, 'inlineSize')(entry));
      const nextHeight = Math.round(getDimension(measure, 'blockSize')(entry));

      if (only === 'height') {
        return prevRef.current.height === nextHeight
          ? void 0
          : update(nextHeight, prevRef.current.width ?? nextWidth);
      }

      if (only === 'width') {
        return prevRef.current.width === nextWidth
          ? void 0
          : update(prevRef.current.height ?? nextHeight, nextWidth);
      }

      if (prevRef.current.height === nextHeight && prevRef.current.width === nextWidth) {
        // This are the same so do nothing
        return;
      }

      return update(nextHeight, nextWidth);
    };

    return debounce(cb, timeout, { trailing: true });
  }, [timeout, isMounted, callback, measure, only]);

  React.useEffect(() => {
    if (!observer.current && element) {
      observer.current = new ResizeObserver(observerCallback);
      observer.current.observe(element, { box: observe });
    }

    return () => {
      if (observer.current && element) {
        observer.current?.unobserve(element);
      }

      observer.current = null;
    };
  }, [element, observerCallback, observe, updateCount]);

  return React.useMemo(
    () => ({ ref, width: size.width, height: size.height, reattach: forceUpdate }),
    [ref, size, forceUpdate],
  );
};
