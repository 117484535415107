/* eslint-disable max-lines-per-function, jsx-a11y/label-has-associated-control */
import * as React from 'react';
import { unstable_batchedUpdates as batch } from 'react-dom';

import { cx, execIfFunc } from '@cobbler-io/utils/src';

import { useToggle } from '@cobbler-io/hooks/src';
import { useDragAndDropFile } from '@cobbler-io/hooks/src/useDragAndDropFile';
import { useSaveAs } from '@cobbler-io/hooks/src/useSaveAs';

import { Button } from '@cobbler-io/core-ui/src/Button';
import { useFeatureFlag } from '@cobbler-io/core-ui/src/FeatureFlag';
import { Field } from '@cobbler-io/core-ui/src/Field';
import { FileField } from '@cobbler-io/core-ui/src/FileField';
import { Heading } from '@cobbler-io/core-ui/src/Heading';
import { Icon } from '@cobbler-io/core-ui/src/Icon';
import { useCurrentModal } from '@cobbler-io/core-ui/src/Modal';
import { useNotification } from '@cobbler-io/core-ui/src/Notification';
import { ProgressBar } from '@cobbler-io/core-ui/src/ProgressBar';
import { ShimmerList } from '@cobbler-io/core-ui/src/Shimmer';

import {
  useBudgetRevisions, useCurrentBudgetId, useRevisions,
} from '@cobbler-io/redux/src/modules/current-budget';
import { useOverplanningThreshold } from '@cobbler-io/redux/src/modules/tenant-settings';

import { extractGraphQLErrors } from '@cobbler-io/app/src/api';
import {
  GetBudgetsQuery, UpsertBudgetLineInputType, useAddRevisionMutation,
  useUpsertChildBudgetLinesMutation,
} from '@cobbler-io/app/src/api/graphql-types';
import {
  useCreateRevisionStatus,
} from '@cobbler-io/app/src/ndm/screens/Settings/ForecastSettings/useCreateRevisionStatus';
import {
  useRefreshRevisions,
} from '@cobbler-io/app/src/ndm/screens/Settings/ForecastSettings/useRefreshRevisions';
import { useCurrentBudgetLine } from '@cobbler-io/app/src/providers/CurrentBudgetLineProvider'; // Current line
import {
  useNormalizedNavigate as useNavigate,
} from '@cobbler-io/app/src/utils/useNormalizedNavigate';

import { map, prop } from 'ramda';

import { useCurrentEditorData } from '../BudgetLineEditor/useCurrentEditorData';
import { ForecastUploaderPreview } from './ForecastUploadPreview';
import { SkippedLine } from './SkippedLine';
import { SkippedBudgetLineValue } from './types';
import { useForecastUploader } from './useForecastUploader';

import styles from './ForecastUploader.scss';

const ErrorDisplay = () => (
  <p>An error occurred while loading this window, please close it and try again.</p>
);
ErrorDisplay.displayName = 'ForecastUploaderError';

export type ForecastUploaderProps = Record<string, unknown>;

const getId = prop('id');
const getIds = map(getId);

// eslint-disable-next-line complexity
export const ForecastUploader = (): JSX.Element => {
  // Context data
  const budgetId = useCurrentBudgetId();
  const budgetLineContext = useCurrentBudgetLine();
  const threshold = useOverplanningThreshold();
  const createRevisionStatus = useCreateRevisionStatus();

  const alwaysAllowBatchEditing = useFeatureFlag('AlwaysAllowBatchEditing');

  const [revisionData, { select: setRevision }] = useBudgetRevisions();
  const {
    active: activeRevisionId,
    selected: selectedRevisionId,
    // original: originalRevisionId,
    revisions: allRevisions,
  } = revisionData!;

  const currentRevisionsIds = [...getIds(useRevisions()?.revisions ?? [])];

  // This will make sure that we bypass all original revision checks when the
  // AlwaysAllowBatchEditing flag is on.
  const originalRevisionId = alwaysAllowBatchEditing
    ? ('no-original-set' as BudgetRevisionId)
    : revisionData?.original ?? null;

  const revisions = React.useMemo(() => allRevisions.filter(r => !r.isLocked), [allRevisions]);
  const refreshRevisions = useRefreshRevisions();

  // Mutations
  const [addRevision] = useAddRevisionMutation();
  const [upsertLines] = useUpsertChildBudgetLinesMutation();

  // State
  // TODO: Once we manage to integrate the forecast and budget line uploaders
  // into a single component, manage all this state with useReducer.
  const [name, setName] = React.useState('');
  const [file, setFile] = React.useState<File | null>(null);
  const [lines, setLines] = React.useState<UpsertBudgetLineInputType[] | null>(null);
  const [error, setError] = React.useState<string | null>(null);
  const [skipped, setSkipped] = React.useState<readonly SkippedBudgetLineValue[]>([]);

  const {
    active: isUploading,
    activate: startUploading,
    deactivate: stopUploading,
  } = useToggle(false);

  // UI helpers
  const notify = useNotification();
  const saveAs = useSaveAs();
  const navigate = useNavigate();
  const modalContainer = useCurrentModal();

  const accept = [
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' /* xlsx */,
    'application/vnd.ms-excel' /* xls */,
    'text/csv' /* csv */,
    '.csv',
  ];
  const { status, ...handlers } = useDragAndDropFile(accept, false, {
    add: (_: string, f: File) => setFile(f),
    clobber: (_: string, f: File) => setFile(f),
  });

  // General logic
  // - Only root budgets are allowed to create new revisions.
  // - Sub-budgets show the current revision automatically selected.
  // - This modal should be disabled in sub-budgets in the original revision (handled by the budget editor).
  // - Uploading a forecast for the original revision is disallowed (we show "create new" instead).

  // Initially selected revision. Since the original revision cannot be the target,
  // we set this to null so that "create new" is automatically selected.
  const [targetRevisionId, setTargetRevisionId] = React.useState<BudgetRevisionId | null>(
    selectedRevisionId === originalRevisionId ? null : selectedRevisionId,
  );

  // Get the line info at the provided revision for comparison, if no revision
  // is provided, compare to the active revision
  const {
    mappedData: targetLineAtRevision,
    loading: loadingTarget,
    error: errorTarget,
    refetch,
    variables,
  } = useCurrentEditorData(targetRevisionId ?? activeRevisionId!);

  const allowCreateRevision = !!targetLineAtRevision?.isRoot;

  const uploader = useForecastUploader(targetLineAtRevision);
  const processFile = React.useCallback(() => {
    if (file && uploader) {
      uploader
        .getBudgetLinesFromFile(file)
        .then(setLines)
        .catch(err => {
          console.error(err);
          setFile(null);
          stopUploading();
          setError(`Uh oh...\nWe can't recognize the format of your file.\n${err}`);
        });
    }
  }, [file]);

  const revisionNames = React.useMemo(
    () => revisions.map(r => r.name.trim().toLowerCase()),
    [revisions],
  );
  const validateName = React.useCallback(
    (val: string): string[] | false => {
      const newName = val.trim();
      if (revisionNames.includes(newName.toLowerCase())) {
        return [`Forecast name "${newName}" is already being used.`];
      }
      return false;
    },
    [revisionNames],
  );

  // When adding a file, if we already have all the necessary data to move to
  // the next step, then we automatically do it.
  React.useEffect(() => {
    if (file && (targetRevisionId || (name && !validateName(name)))) {
      processFile();
    }
  }, [file, name, processFile, targetRevisionId, validateName]);

  // Error handling
  if (!budgetId || !budgetLineContext) {
    console.error('Unable to load required context for forecast uploader.');
    return <ErrorDisplay />;
  }

  if (!activeRevisionId || !selectedRevisionId || !originalRevisionId) {
    console.error('Unable to get revision information for forecast uploader.');
    return <ErrorDisplay />;
  }

  if (errorTarget) {
    console.error('Unable to get line information for forecast uploader.', errorTarget);
    return <ErrorDisplay />;
  }

  if (!uploader || !targetLineAtRevision) {
    console.error('Unable to load uploader parser.');
    return <ErrorDisplay />;
  }

  // Loading state
  if (loadingTarget) {
    return (
      <div className={styles.forecastUploader}>
        <ShimmerList className={styles.loading} items={4} />
      </div>
    );
  }

  const revision = revisions.find(r => r.id === targetLineAtRevision.revisionId)!;

  if (selectedRevisionId === originalRevisionId && !allowCreateRevision) {
    // This is a sub-line (non-root) budget in the original revision.
    // Creating a revision or uploading a forecast is not allowed.
    // The container component should've prevented the user from ending up here.
    return (
      <div className={styles.forecastUploader}>
        <p style={{ padding: '4em 0 8em 0' }}>
          Please switch to a forecast or go to the root budget line to be able to upload a data.
        </p>
      </div>
    );
  }

  const startTemplateDownload = () => {
    const filename = `cobbler-forecast-template.csv`;
    const fallback = '/cobbler-template-month.csv'; // @TODO Create a better fallback
    saveAs(uploader.generateCSVTemplateBlob(), filename, fallback);
  };

  const notifyError = (err: any) => {
    const gqlErrors = extractGraphQLErrors(err);
    console.error(gqlErrors);
    batch(() => {
      setFile(null);
      stopUploading();
      setError(
        `An error occurred while uploading your budget. ${gqlErrors
          .map(prop('message'))
          .join(' | ')}`,
      );
      setLines(null);
    });
    execIfFunc(refetch, variables);
  };

  const onSubmit = async ({
    makeActive,
    lines: budgetLines,
  }: {
    makeActive: boolean;
    lines: UpsertBudgetLineInputType[];
  }) => {
    startUploading();

    const isNewRevision = !targetRevisionId;

    const upsertBudgetLines = (budgetRevisionId: BudgetRevisionId) => {
      upsertLines({
        variables: {
          lines: budgetLines,
          parentId: targetLineAtRevision.id,
          revisionId: budgetRevisionId,
        },
      })
      .then(async ({ data }) => {
        notify({
          body: `Your budget will be updated in a few seconds.`,
          title: 'Upload successful',
        });

        const skippedValues = data?.a_upsertChildBudgetLines?.skippedBudgetLineValues ?? [];
        await refetch(variables);

        batch(() => {
          setSkipped(skippedValues);
          stopUploading();
        });

        // If the target revision is either new or different to the originally
        // selected revision, we navigate to that revision
        if (budgetRevisionId !== selectedRevisionId) {
          if (isNewRevision) {
            await refreshRevisions();
          }
          // TODO: navigating to a new url should set the revision automatically
          navigate(budgetLineContext.urls.editor({ revisionId: budgetRevisionId }));
          setRevision(budgetRevisionId);
        }

        if (!skippedValues?.length) {
          modalContainer?.close();
        }
      })

      // There's a chance this might fail and the user might end up with a new
      // revision that doesn't have an updated spend plan. Not much we can do
      // in the frontend, so for now we just show an error. In the future we're
      // going to do this in a single transaction in the backend.
      .catch(notifyError);
    } 

    if (targetRevisionId) {
       // Start updating the planned values for the target revision.
       return upsertBudgetLines(targetRevisionId);
    }

    // Create a new budget revision if there is no targetRevisionId.
    addRevision({
      variables: {
        budgetId,
        locked: false,
        makeActiveRevision: makeActive,
        name,
      },
    })
    .then(() => {
      const onSuccess = (data: GetBudgetsQuery) => {
        const updatedRevisionsIds = getIds(data.a_budgets[0].revisions);
        const newRevisionsIds = updatedRevisionsIds.filter((r: BudgetRevisionId) => !currentRevisionsIds.includes(r));

        if (newRevisionsIds.length === 1) {
          const [newRevisionId] = newRevisionsIds;
          // Start updating the planned values for the new revision.
          return upsertBudgetLines(newRevisionId);
        } 
        
        setError("Unable to insert budget lines. Please try again later.");
      }
      
      createRevisionStatus({ onSuccess });
    })
    .catch(notifyError);
  };

  const onCancel = () => {
    batch(() => {
      setFile(null);
      setLines(null);
      setError(null);
      stopUploading();
    });
  };

  const revisionOptions = [
    {
      label: 'Create new',
      value: '',
    },
    ...revisions
      .filter(r => r.id !== originalRevisionId)
      .map(r => ({ label: r.name, value: r.id })),
  ];

  const reject = status === 'reject';
  const isSummary = !isUploading && skipped.length > 0;
  const isPreview = lines !== null && !isSummary;

  // Show loading animation if "loading"
  if (isUploading) {
    return (
      <div className={styles.forecastUploader}>
        <div className="row col">
          <Heading size="title">Uploading...</Heading>
        </div>

        <div className={cx(styles.uploading, 'row col align-center center')}>
          <img alt="" src="/uploading-illustration.svg" />
        </div>

        <div className={cx(styles.fakeProgress, 'row col align-center center')}>
          <ProgressBar hideText max={100} value={1} />
        </div>
      </div>
    );
  }

  // Show previewer if "lines" exist, i.e. a file has been processed
  if (isPreview) {
    return (
      <div className={styles.forecastUploader}>
        <div className="row col">
          <Heading size="title">Forecast</Heading>
          <Heading as="h3" size="body-large">
            These are the changes you are about to make.
          </Heading>
        </div>

        <ForecastUploaderPreview
          budgetRevision={targetLineAtRevision}
          lines={lines}
          overplanningThreshold={threshold}
          showSettings={!targetRevisionId}
          onCancel={onCancel}
          onSubmit={onSubmit}
        />
      </div>
    );
  }

  // If there are skipped values we show a summary of all uploaded data.
  if (isSummary) {
    return (
      <div className={styles.forecastUploader}>
        <div className="row col">
          <Heading size="title">Summary</Heading>
          <Heading as="div" size="body-large">
            Your forecast was uploaded successfully but the following values were skipped:
          </Heading>
        </div>
        <div className={styles.summaryItems}>
          {skipped.map(
            ({ budgetLineId, budgetLineName, ownerEmail, accountCodes, departmentNames }) => (
              <SkippedLine
                key={budgetLineId}
                accounts={accountCodes}
                departments={departmentNames}
                name={budgetLineName}
                owner={ownerEmail}
              />
            ),
          )}
        </div>

        <div className="row right">
          <Button name="upload-forecast-summary-ok" onClick={modalContainer?.close}>
            Ok
          </Button>
        </div>
      </div>
    );
  }

  // Render initial (upload) screen
  return (
    <div className={styles.forecastUploader}>
      <Heading size="headline">
        {allowCreateRevision ? 'Forecast' : `Upload to “${revision.name}”`}
      </Heading>

      {/* Field select */}
      {allowCreateRevision && (
        <div>
          <Field
            label="Select forecast version"
            name="target-revision-id"
            options={revisionOptions}
            type="select"
            value={targetRevisionId ?? ''}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setTargetRevisionId(e.target.value);
            }}
          />
        </div>
      )}

      {/* Forecast name */}
      {!targetRevisionId && (
        <div>
          <Field
            validateOnBlur
            label="Name"
            name="name"
            placeholder="Enter a name"
            type="text"
            validate={[validateName]}
            value={name}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setName(e.target.value.trim());
            }}
          />
        </div>
      )}

      {/* Upload area */}
      <div>
        <p className="text-secondary">Attach a file</p>
        <section className={styles.file}>
          {/* No file selected, render drop down area */}
          {!file && (
            <>
              <div
                {...handlers}
                className={cx(
                  styles.uploadArea,
                  status === 'accept' && styles.accept,
                  status === 'reject' && styles.reject,
                )}
              >
                <Icon type={reject ? 'slashCircle' : 'upload'} />
                <div className="row center align-center">
                  {/* Unsupported file */}
                  {reject && <span>Unsupported file</span>}

                  {/* Supported file */}
                  {!reject && (
                    <>
                      <span>Drag and drop, or </span>
                      <FileField
                        hideFileName
                        accept={accept}
                        className={styles.selectFile}
                        id="forecast-upload-file"
                        label="Select a file"
                        name="forecast-upload-file"
                        variant="text"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          if (e.currentTarget.files instanceof FileList) {
                            setError(null);
                            setFile(e.currentTarget.files[0]);
                          }
                        }}
                      />
                    </>
                  )}
                </div>
              </div>

              {Boolean(error) && <div className={styles.error}>{error}</div>}

              <div className={styles.template}>
                <p className="text-secondary">Your file must match the format of our template</p>
                <Button
                  small
                  name="download-csv-template"
                  variant="text"
                  onClick={startTemplateDownload}
                >
                  Download CSV template
                </Button>
              </div>
            </>
          )}

          {/* A file has been selected, render file pill */}
          {file && (
            <div className={styles.filePill}>
              <Icon size={22} type="tableChart" />
              <span>{file.name || 'Forecast.csv'}</span>
              <Button small name="remove-forecast-file" variant="svg" onClick={() => setFile(null)}>
                <Icon size={12} type="close" />
              </Button>
            </div>
          )}
        </section>
      </div>

      <div className="row right">
        <Button
          disabled={!file || (!targetRevisionId && !name)}
          name="process-upload"
          onClick={processFile}
        >
          Next
        </Button>
      </div>
    </div>
  );
};
ForecastUploader.displayName = 'ForecastUploader';
