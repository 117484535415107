import { execIfFunc, isFunction } from './index';

export const mapEvent = <T = Event>(
  ...handlers: (((event?: T | undefined) => void) | undefined)[]
) => (event?: T | undefined) => {
  // @ts-expect-error: synthetic events, and this is a fine type guard
  if (event && isFunction(event.persist)) {
    // @ts-expect-error: synthetic events, and this is a fine type guard
    event.persist();
  }

  for (let i = 0; i < handlers.length; i++) {
    execIfFunc(handlers[i], event);
  }
};
