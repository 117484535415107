/* eslint-disable jsx-a11y/media-has-caption */
import * as React from 'react';

import { WebcamContext } from './WebcamContext';

import styles from './VideoAndPreview.scss';

type VideoAndPreviewProps = {
  width: number;
  height: number;
  onClickCanvas?: React.MouseEventHandler;
};

export const VideoAndPreview = (props: VideoAndPreviewProps): JSX.Element => {
  const { width, height, onClickCanvas } = props;
  const { refs, actions } = React.useContext(WebcamContext);

  const onLoadedMetadata: React.EventHandler<React.SyntheticEvent<HTMLVideoElement>> = event => {
    event.currentTarget.play().then(actions.loaded);
  };

  return (
    <>
      <video
        ref={refs.video}
        playsInline
        className={styles.videoStream}
        height={height}
        width={width}
        onLoadedMetadata={onLoadedMetadata}
      />
      <canvas
        ref={refs.canvas}
        className={styles.preview}
        height={height}
        width={width}
        onClick={onClickCanvas}
      />
    </>
  );
};
