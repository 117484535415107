import { createNamedContext } from '@cobbler-io/utils/src/createNamedContext';
import { noop } from '@cobbler-io/utils/src/noop';

import { ISO8601 } from '@cobbler-io/formatters/src/dates';

import { getEndOfMonth } from '@cobbler-io/dates/src/getEndOfMonth';

import { Period, PeriodLength, periodsInRange } from './periodsInRange';

export type { PeriodLength };

export type SetParamsPayload = Partial<{
  start: LocalDate;
  name: string;
  end: LocalDate;
  min: LocalDate;
  max: LocalDate;
  periodLength: PeriodLength;
}>;

export type SetMinAndMaxPayload = { min: LocalDate; max: LocalDate };

export type RangeContextValues = {
  /**
   * A human readable name for the range selected
   *
   * Examples: 'Last Month', 'All Time', 'Custom', etc...
   */
  name: string;
  start: LocalDate;
  startString: ISO8601String;
  end: LocalDate;
  endString: ISO8601String;
  min: LocalDate;
  max: LocalDate;
  periodLength: PeriodLength;
  periods: Period[];
};

export type RangeContextActions = {
  setEnd: (end: LocalDate) => any;
  setMax: (max: LocalDate) => any;
  setMin: (min: LocalDate) => any;
  setMinAndMax: (params: SetMinAndMaxPayload) => any;
  setName: (name: string) => any;
  setParams: (params: SetParamsPayload) => any;
  setPeriodLength: (periodLength: PeriodLength) => any;
  setStart: (start: LocalDate) => any;
};

export type RangeContextType = RangeContextValues &
  RangeContextActions & { getState: () => RangeContextValues };

const MIN_SAFE_DATE = new Date(new Date().getFullYear(), 0, 1, 0, 0, 0, 0);
const MAX_SAFE_DATE = getEndOfMonth(new Date(MIN_SAFE_DATE.getFullYear(), 11, 1));
const DEFAULT_STATE = {
  name: 'default',
  start: MIN_SAFE_DATE,
  startString: ISO8601(MIN_SAFE_DATE),
  end: MAX_SAFE_DATE,
  endString: ISO8601(MAX_SAFE_DATE),
  min: MIN_SAFE_DATE,
  max: MAX_SAFE_DATE,
  periodLength: 'MONTH' as const,
  periods: periodsInRange({
    start: MIN_SAFE_DATE,
    end: MAX_SAFE_DATE,
    fysm: 0,
    periodLength: 'MONTH' as const,
  }),
};

const getState = () => DEFAULT_STATE;

/**
 * A Context for handling ranges
 */
export const CurrentRangeContext = createNamedContext<RangeContextType>('CurrentRangeContext', {
  ...DEFAULT_STATE,
  getState,
  setPeriodLength: noop,
  setName: noop,
  setStart: noop,
  setEnd: noop,
  setMax: noop,
  setMin: noop,
  setMinAndMax: noop,
  setParams: noop,
});
