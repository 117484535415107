import * as React from 'react';

import { Discussion } from '@cobbler-io/app/src/ndm/components/Discussion';
import { useCurrentActual } from '@cobbler-io/app/src/providers/CurrentActualProvider';

const ENTITY_TYPE = 'ACTUAL';
export const ActualDetailsResolvedDiscussions = (): JSX.Element | null => {
  const { actual } = useCurrentActual();

  if (!actual) {
    return null;
  }

  return (
    <>
      {actual.discussions
        .filter(d => d?.isResolved)
        .map(discussion => (
          <Discussion
            key={discussion!.id}
            showStatus
            comments={discussion!.comments}
            entityType={ENTITY_TYPE}
            id={discussion!.id}
            resolved={discussion!.isResolved}
          />
        ))}
    </>
  );
};

ActualDetailsResolvedDiscussions.displayName = 'ActualDetailsResolvedDiscussions';

export default ActualDetailsResolvedDiscussions;
