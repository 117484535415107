import * as React from 'react';

import { useToggle } from '@cobbler-io/hooks/src/useToggle';

import { Button } from '@cobbler-io/core-ui/src/Button';
import { Icon } from '@cobbler-io/core-ui/src/Icon';

import {
  useCreateDiscussionMutation, useUsersForActualQuery,
} from '@cobbler-io/app/src/api/graphql-types';
import { DiscussionInput } from '@cobbler-io/app/src/components/DiscussionInput';
import { Discussion } from '@cobbler-io/app/src/ndm/components/Discussion';
import { useCurrentActual } from '@cobbler-io/app/src/providers/CurrentActualProvider';

import styles from './ActualDetailsDiscussions.scss';

const ENTITY_TYPE = 'ACTUAL';
export const ActualDetailsDiscussions = (): JSX.Element | null => {
  const { active: isAdding, activate: startAdding, deactivate: stopAdding } = useToggle(false);
  const { active: isSaving, activate: startSaving, deactivate: stopSaving } = useToggle(false);
  const { actualId, actual, refetch } = useCurrentActual();
  const [createDiscussion] = useCreateDiscussionMutation();

  const { data, error } = useUsersForActualQuery({
    fetchPolicy: 'cache-first',
    skip: !actualId,
    variables: { actualId: actualId as string },
  });
  const actualUsers = error ? null : data?.usersWithReadAccessToActual ?? [];

  if (error) {
    // eslint-disable-next-line no-console
    console.error('Could not fetch actual users', error);
  }

  const handleAddDiscussion = (textContent: string, assignedToUserId?: string) => {
    const userId = assignedToUserId === '@@__unassigned' ? null : assignedToUserId;
    startSaving();
    createDiscussion({
      variables: {
        input: {
          assignedToUserId: userId,
          entityId: actualId!,
          entityType: ENTITY_TYPE,
          textContent,
        },
      },
    })
      .then(() => {
        stopAdding();
        stopSaving();
        void refetch();
      })
      .catch(console.error);
  };

  if (!actual) {
    return null;
  }

  return (
    <>
      {(actual.discussions || [])
        .filter(d => d && !d.isResolved)
        .map(discussion => (
          <Discussion
            key={discussion!.id}
            canResolve
            showStatus
            assignedTo={discussion!.assignedTo?.fullName ?? discussion!.assignedTo?.email}
            comments={discussion!.comments}
            entityType={ENTITY_TYPE}
            id={discussion!.id}
            refetch={refetch}
            resolved={discussion!.isResolved}
            usersWithAccess={actualUsers}
          />
        ))}

      <div className={styles.newDiscussion}>
        {isAdding ? (
          <DiscussionInput
            assignableToUsers={actualUsers}
            disabled={isSaving}
            placeholder="Enter a comment..."
            onCancel={stopAdding}
            onSave={handleAddDiscussion}
          />
        ) : (
          <Button name="new-discussion" type="button" variant="text" onClick={startAdding}>
            <Icon size={16} style={{ marginRight: '1ex' }} type="add" /> Start a new discussion
          </Button>
        )}
      </div>
    </>
  );
};

ActualDetailsDiscussions.displayName = 'ActualDetailsDiscussions';

export default ActualDetailsDiscussions;
