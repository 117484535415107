import * as React from 'react';

import { ComboBox, ComboBoxProps } from '@cobbler-io/core-ui/src/Dropdown';
import { Persona } from '@cobbler-io/core-ui/src/Persona';

import { GetAllUsersQuery, useGetAllUsersQuery } from '@cobbler-io/app/src/api/graphql-types';

import { filter, head, isNil, map, not, pipe, split } from 'ramda';

export type UserPickerProps = {
  returnType?: 'id' | 'user';
} & Omit<ComboBoxProps<UserId>, 'items' | 'multiple'>;

export type UserItem = {
  id: UserId;
  label: React.ReactElement;
  value: UserId;
  search: string;
};

/**
 * We want just the first part of the email to be in the search string
 */
const extractEmailUser: UnaryFn<string> = pipe<string, string[], string>(split('@'), head);

const toUserItem =
  (type: 'id' | 'user') => (x: NonNullable<GetAllUsersQuery>['usersForUserPicker'][number]) => {
    if (!x) {
      return null;
    }

    return {
      id: x.id,
      label: <Persona user={x} />,
      value: type === 'user' ? x : x.id,
      search: [x.fullName, x.preferredName, extractEmailUser(x.email)],
    };
  };

const extractUserData = (
  data: GetAllUsersQuery | undefined,
  returnType: 'id' | 'user',
): UserItem[] => {
  if (!data) {
    return [];
  }

  return filter(pipe(isNil, not), map(toUserItem(returnType), data.usersForUserPicker));
};

export const UserPicker = React.forwardRef(
  (props: UserPickerProps, forwardedRef: any): JSX.Element => {
    const { data, loading, error } = useGetAllUsersQuery({ fetchPolicy: 'cache-first' });
    const { returnType = 'id' } = props;
    const items = React.useMemo(() => extractUserData(data, returnType), [data, returnType]);

    const defaultValue =
      returnType === 'id'
        ? props.defaultValue
        : items.find(item => item.id === props.defaultValue)?.value || props.defaultValue;

    if (error) {
      console.error(error);
      return <div>Error fetching users for UserPicker</div>;
    }

    if (loading && !data) {
      return <ComboBox.Loading {...props} />;
    }

    return <ComboBox {...props} ref={forwardedRef} defaultValue={defaultValue} items={items} />;
  },
);

UserPicker.displayName = 'UserPicker';
