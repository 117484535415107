import React, { RefObject } from 'react';

import { isFocusLost } from './isFocusLost';

type FocusListenerContainer = RefObject<HTMLElement | RefObject<HTMLElement>[]>;

type HandleFocusOutParams = {
  lastFocusedElement: EventTarget | null;
};
type HandleFocusOut = (params: HandleFocusOutParams) => void;

export const useFocusOut = (
  container: FocusListenerContainer,
  onFocusOut: HandleFocusOut,
  disable?: boolean,
): void => {
  const onFocusIn = React.useCallback(
    (event: FocusEvent): void => {
      if (!container.current) {
        // No container means that we can't do anything
        return;
      }

      if (!event.target) {
        // No target means that we can't test
        // It also likely means that the user switched tabs or navigated to a different document
        return;
      }

      const focusedElement = event.target;
      const lastFocusedElement = event.relatedTarget;

      if (isFocusLost(container, focusedElement as HTMLElement)) {
        onFocusOut({ lastFocusedElement });
      }
    },
    [container, onFocusOut],
  );
  React.useEffect(() => {
    if (disable) {
      return;
    }

    document.addEventListener('focusin', onFocusIn);
    // eslint-disable-next-line consistent-return
    return () => document.removeEventListener('focusin', onFocusIn);
  }, [disable, onFocusIn]);
};
