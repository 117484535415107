import React from 'react';

import { CurrentRangeProvider, CurrentRangeProviderProps } from './CurrentRangeProvider';
import { subRangePropsFromRange } from './subRangePropsFromRange';
import { useCurrentRange } from './useCurrentRange';

export const SubRangeProvider = (params: Partial<CurrentRangeProviderProps>): JSX.Element => {
  const { children } = params;
  const ctx = useCurrentRange();

  const next = subRangePropsFromRange(ctx, params);

  return <CurrentRangeProvider {...next}>{children}</CurrentRangeProvider>;
};

SubRangeProvider.displayName = 'SubRangeProvider';
