/* eslint-disable functional/immutable-data */
/* eslint-disable array-callback-return */
import React, { useReducer } from 'react';

import {
  __, complement, concat, filter, flatten, has, includes, isNil, map, omit, pipe, pluck, prop, uniq,
} from 'ramda';

import { getDescendantTreeNodes } from './getDescendantTreeNodes';
import { ModalId, ModalTreeNode } from './types';

const getId = prop('id');
const getIds = map(getId);
const getDescendants = map(getDescendantTreeNodes);
const idIn = (arr: string[]) => pipe(getId, includes(__, arr));
const idNotIn = (arr: string[]) => complement(idIn(arr));
const removeFromParent = (x: ModalTreeNode) => x.parent?.children.delete(x.id);

type ModalState = {
  flatModals: ModalTreeNode[];
  modals: ModalTreeNode[];
  modalsById: Record<ModalId, ModalTreeNode>;
  modalsByType: Map<string, Set<ModalTreeNode>>;
};

type CreateModalAction = { type: 'Modal/Create'; payload: ModalTreeNode };

type CloseModalAction = { type: 'Modal/Close'; payload: ModalId };

type CloseModalManyAction = { type: 'Modal/CloseMany'; payload: ModalId[] };

type ModalAction = CreateModalAction | CloseModalAction | CloseModalManyAction;

const closeModal = (state: ModalState, action: CloseModalAction): ModalState => {
  const { flatModals, modals, modalsById, modalsByType } = state;

  const modalId = action.payload;
  const modal = modalsById[modalId];

  if (!modal) {
    // There is no modal, so this is a no-op
    return state;
  }

  // Get all the modals that need to be closed (post order)
  const closingQueue: ModalTreeNode[] = [modal].concat(getDescendantTreeNodes(modal));
  // Just get the ids
  const closingQueueIds: ModalId[] = getIds(closingQueue);

  const { parent } = modal;

  // Remove the modal from its parent
  parent?.children.delete(modalId);

  if (parent) {
    // If there is a parent, then we need to also remove them from the treeRefs. If there is no
    // parent, then the treeRefs will just disappear.
    const refsToRemove = new Set(closingQueue.map(prop('ref')));
    parent.treeRefs.current = parent.treeRefs.current.filter(x => !refsToRemove.has(x));
  }

  closingQueue.forEach(m => modalsByType.get(m.type ?? 'default')?.delete(m));

  const filterId = filter<ModalTreeNode>(idNotIn(closingQueueIds));

  return {
    flatModals: filterId(flatModals),
    modals: filterId(modals),
    modalsById: omit(closingQueueIds, modalsById),
    modalsByType: new Map(modalsByType),
  };
};

const closeManyModals = (state: ModalState, action: CloseModalManyAction): ModalState => {
  const { flatModals, modalsById, modalsByType } = state;
  // This really just tests if an id is in an object, but what matters is how we use it
  const isModalTreeNode = (x: unknown): x is ModalTreeNode => {
    if (typeof x !== 'object' || x === null) {
      return false;
    }

    if (isNil(x) || !has('id', x) || isNil(x.id)) {
      return false;
    }

    return true;
  };

  const currentModals: ModalTreeNode[] = filter<ModalTreeNode>(
    isModalTreeNode,
    map(id => modalsById[id], action.payload),
  );
  const closingQueue: ModalTreeNode[] = uniq(
    concat(currentModals, flatten(getDescendants(currentModals))),
  );
  currentModals.forEach(removeFromParent);
  const closingQueueIds: ModalId[] = getIds(closingQueue);
  const filterFromClosingQueue = filter<ModalTreeNode>(idNotIn(closingQueueIds));

  closingQueue.forEach(modal => modalsByType.get(modal.type ?? 'default')?.delete(modal));

  const parents = pluck('parent', currentModals).filter(x => !isNil(x)) as ModalTreeNode[];
  if (parents.length) {
    const closingRefs = new Set(pluck('ref', closingQueue));
    parents.forEach(parent => {
      if (parent) {
        // eslint-disable-next-line no-param-reassign
        parent.treeRefs.current = parent.treeRefs.current.filter(ref => !closingRefs.has(ref));
      }
    });
  }

  return {
    flatModals: filterFromClosingQueue(flatModals),
    modals: filterFromClosingQueue(currentModals),
    modalsById: omit(closingQueueIds, modalsById),
    modalsByType: new Map(modalsByType),
  };
};

const createModal = (state: ModalState, action: CreateModalAction): ModalState => {
  const { flatModals, modals, modalsById, modalsByType } = state;
  const type = action.payload.type ?? 'default';
  const typeSet = modalsByType.get(type) ?? new Set();
  modalsByType.set(type, typeSet.add(action.payload));
  return {
    flatModals: flatModals.concat(action.payload),
    modals: action.payload.parent ? modals : modals.concat(action.payload),
    modalsById: { ...modalsById, [action.payload.id]: action.payload },
    modalsByType: new Map(modalsByType),
  };
};

const reducer = (state: ModalState, action: ModalAction): ModalState => {
  switch (action.type) {
    case 'Modal/Close':
      return closeModal(state, action);
    case 'Modal/CloseMany':
      return closeManyModals(state, action);
    case 'Modal/Create':
      return createModal(state, action);
    default:
      return state;
  }
};

const initialState: ModalState = {
  flatModals: [],
  modals: [],
  modalsById: {},
  modalsByType: new Map(),
};

export const useModalReducer = (): [ModalState, React.Dispatch<ModalAction>] => {
  return useReducer(reducer, initialState);
};
