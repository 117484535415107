import * as React from 'react';

import { Persona } from '@cobbler-io/core-ui/src/Persona';

import { CellProps } from 'react-table';

import { EditorLine } from '../../types';

export const OwnerColumn = ({
  cell: { value: owner },
}: CellProps<EditorLine, EditorLine['owner']>): JSX.Element | null => {
  if (!owner?.item) {
    return null;
  }

  return <Persona user={owner.item} />;
};

OwnerColumn.displayName = 'OwnerColumn';
