import * as React from 'react';

import { execIfFunc } from '@cobbler-io/utils/src';

import { Button } from '@cobbler-io/core-ui/src/Button';
import { ExpandableTree, ExpandableTreeNode } from '@cobbler-io/core-ui/src/ExpandableTree';
import { Heading } from '@cobbler-io/core-ui/src/Heading';
import { useCurrentModal } from '@cobbler-io/core-ui/src/Modal';
import { ShimmerList } from '@cobbler-io/core-ui/src/Shimmer';

import {
  BudgetLineDescendantsQuery, useBudgetLineDescendantsQuery,
} from '@cobbler-io/app/src/api/graphql-types';

import { pluck } from 'ramda';

import styles from './DeleteBudgetLinePrompt.scss';

type LineWithDescendants = BudgetLineDescendantsQuery['a_budgetLineDescendants'][number];

export type DeleteBudgetLinePromptProps = {
  ids: BudgetLineId[];
  onConfirm: (ids: string[]) => void;
  onCancel?: () => void;
};

export const DeleteBudgetLinePrompt = (props: DeleteBudgetLinePromptProps): JSX.Element => {
  const { ids, onCancel, onConfirm } = props;
  const { close } = useCurrentModal();
  const { data, loading, error } = useBudgetLineDescendantsQuery({
    variables: { ids },
    skip: !ids.length,
  });

  const lines = data?.a_budgetLineDescendants || [];
  const one = lines.length === 1;

  if (!ids.length || (!error && !loading && !lines.length)) {
    // @ts-expect-error: This is fine in React
    return null;
  }

  const renderDescendants = (line: LineWithDescendants, parentId: string) => {
    const immediate = line.descendants.filter(d => d.parentId === parentId);
    return !immediate.length
      ? null
      : immediate.map(d => (
          <ExpandableTreeNode key={d.id} Header={d.name} id={d.id}>
            {renderDescendants(line, d.id)}
          </ExpandableTreeNode>
        ));
  };

  const handleConfirm = () => {
    onConfirm(pluck('id', lines));
    close();
  };

  const handleCancel = () => {
    execIfFunc(onCancel);
    close();
  };

  return (
    <div className={styles.deleteBudgetLinePrompt}>
      <Heading>Delete line {one ? 'item' : 'items'}</Heading>
      <p>
        Are you sure you want to delete {one ? 'this item' : `these items (${lines.length})`} along
        with all of {one ? 'its' : 'their'} sub-budgets? This cannot be undone.
      </p>

      {!error && (
        <div className={styles.treeWrapper}>
          {loading && <ShimmerList className={styles.loader} items={3} />}
          {!loading &&
            lines.map(line => {
              const descendants = renderDescendants(line, line.id);
              const count = descendants?.length ? `(${descendants.length})` : '';
              return (
                <ExpandableTree
                  key={line.id}
                  collapsedIcon="chevronFilledRight"
                  expandedIcon="chevronFilledDown"
                  leafIcon="chevronFilledRight"
                >
                  <ExpandableTreeNode
                    className={styles.tree}
                    Header={`${line.name} ${count}`}
                    id={line.id}
                  >
                    {descendants}
                  </ExpandableTreeNode>
                </ExpandableTree>
              );
            })}
        </div>
      )}
      <div className="button-container right">
        <Button name="cancel-delete-lines" variant="text" onClick={handleCancel}>
          Cancel
        </Button>

        <Button className={styles.buttonDanger} name="delete-lines" onClick={handleConfirm}>
          Delete {lines.length > 1 && `(${lines.length})`}
        </Button>
      </div>
    </div>
  );
};

DeleteBudgetLinePrompt.displayName = 'DeleteBudgetLinePrompt';
