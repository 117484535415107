import tokens from '@cobbler-io/design-tokens';

import { isDefined } from '@cobbler-io/utils/src/isDefined';
import { hash } from '@cobbler-io/utils/src/string/hash';

export const graphColors = Object.entries(tokens)
  .filter(([name]) => name.startsWith('colorGraph'))
  .map(([, value]) => value) as string[];

export const getGraphColor = (n: number, colors: string[] = graphColors): string => {
  if (n < 0) {
    return colors[0];
  }

  if (isDefined(colors[n])) {
    return colors[n];
  }

  return getGraphColor(n - colors.length, colors);
};

export const getGraphColorFn =
  (colors: string[] = graphColors) =>
  (n: number): string =>
    getGraphColor(n, colors);

// This will center `hash('Others') as `0`
const cycle = graphColors.length - (hash('Others') % graphColors.length);
const getKey = (x: string) => ((hash(x) % graphColors.length) + cycle) % graphColors.length;

/**
 * Hashes a string and gets the graph color along with that
 *
 * If you want to avoid duplicate colors, then you can pass a list of colors that are already used
 * and the function will try to get a different color for that.
 */
export const getGraphColorFromString = (x: string, used: string[] = []): string => {
  const proposed = graphColors[getKey(x)];
  // If the color has been used, but there are other colors, then we can try again for one of those
  if (used.includes(proposed) && used.length < graphColors.length) {
    const next = getGraphColorFromString(`${x}${x.length}`, used);
    // This can avoid an infinite loop
    return next;
  }

  return proposed;
};
