import * as React from 'react';

import { isFunction } from './isFunction';

export type ModifiedEventHandler<E, T> = (event: React.SyntheticEvent<E, T>) => void | false;
export type ModifiedEventHandlerWithArg<E, T, A> = (
  event: React.SyntheticEvent<E, T>,
  ...args: A[]
) => void | false;

export const composeEventHandlers = <E = Element, T = Event>(
  ...handlers: (ModifiedEventHandler<E, T> | undefined)[]
) => (event: React.SyntheticEvent<E, T>) =>
  handlers.some(handler => {
    isFunction(handler) && handler(event);

    return event && event.defaultPrevented;
  });

export const composeEventHandlersWithArg = <E = Element, T = Event, A = any>(
  ...handlers: ModifiedEventHandlerWithArg<E, T, A>[]
) => (event: React.SyntheticEvent<E, T>, ...args: A[]) =>
  handlers.some(handler => {
    isFunction(handler) && handler(event, ...args);

    return event && event.defaultPrevented;
  });
