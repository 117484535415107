import * as React from 'react';

import { actions } from '@cobbler-io/redux/src/index';

import { useDispatch, useSelector } from 'react-redux';

/**
 * Sets the title for the page (not for the document). Currently consumed by the `AppBar`
 */
export const usePageTitle = (title?: string): string => {
  const dispatch = useDispatch();
  React.useEffect(() => {
    if (title) {
      dispatch(actions.page.setTitle(title));
    }
  }, [title]);

  return useSelector(state => state.page.title);
};

export default usePageTitle;
