import * as React from 'react';

export const createNamedContext = <ContextType>(
  name: string,
  defaultValue: ContextType,
): React.Context<ContextType> => {
  const Context = React.createContext<ContextType>(defaultValue);
  Context.displayName = name;
  return Context;
};
