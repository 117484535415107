import { ndmUrls as urls } from '@cobbler-io/app/src/urls';
import { RevisionTag } from '@cobbler-io/app/src/utils/revisions';

import { useMatch } from '@reach/router';

export type BudgetLineParams = {
  budgetLineId?: BudgetLineId;
  revisionTag?: RevisionTag;
  budgetLineChildId?: BudgetLineId;
};

export const useBudgetLineParams = (): BudgetLineParams => {
  const match = {
    ...useMatch(`${urls.lineBasePath}/*`),
    ...useMatch(`${urls.budgetBasePath}/*`),
  };
  return {
    budgetLineId: match.budgetId,
    revisionTag: match.revisionId,
    budgetLineChildId: match.lineId,
  };
};
