import * as React from 'react';

import { ComboBox, ComboBoxProps, Item } from '@cobbler-io/core-ui/src/Dropdown';

import {
  BudgetLineAncestorType, BudgetLinesTreeQuery, useBudgetLinesTreeQuery,
} from '@cobbler-io/app/src/api/graphql-types';

import { T } from 'ramda';

import styles from './BudgetLineDropdown.scss';

export type BudgetLinesTreeItem = NonNullable<BudgetLinesTreeQuery['budgetLinesInTree']>[number];
export type BudgetLinesTreeFilterFunction = (item: BudgetLinesTreeItem) => boolean;

const getParentsName = (line: BudgetLinesTreeItem): string => {
  if (line.ancestors) {
    return [...line.ancestors]
      .sort((a: BudgetLineAncestorType, b: BudgetLineAncestorType) => a.depth - b.depth)
      .map((a: BudgetLineAncestorType) => a.name)
      .concat(line.name)
      .join(' > ');
  }
  return '';
};

const extractBudgetLinesData = (
  data: BudgetLinesTreeQuery | undefined,
  filter: BudgetLinesTreeFilterFunction,
): Item<BudgetLineId>[] => {
  if (!data?.budgetLinesInTree) {
    return [];
  }

  const items: Item<BudgetLineId>[] = data.budgetLinesInTree
    .filter(filter)
    .map((l: BudgetLinesTreeItem) => ({
      id: l.id,
      value: l.id,
      label: (
        <div className={styles.item}>
          <div className={styles.header}>{l.name}</div>
          <div className={styles.subHeader}>{getParentsName(l )}</div>
        </div>
      ),
      selectedLabel: (
        <div className={styles.selectedItem}>
          <div className={styles.header}>{l.name}</div>
          <div className={styles.subHeader}>{getParentsName(l )}</div>
        </div>
      ),
      search: l.name,
    }));

  return items;
};

type DropDownComponentProps = Omit<ComboBoxProps<BudgetLineId>, 'id' | 'items'>;

export type BudgetLineDropdownProps = DropDownComponentProps & {
  /**
   * Component id. Defaults to `budget-line-dropdown`.
   */
  id?: string;
  /**
   * Any line id in the tree we want to get the list of budget lines from.
   */
  budgetLineId: BudgetLineId;
  /**
   * A function to filter the list of budget lines before rendering (e.g. show only lines with update access).
   */
  filterBudgetLines?: BudgetLinesTreeFilterFunction;
};

export const BudgetLineDropdown = (props: BudgetLineDropdownProps): JSX.Element => {
  const { id = 'budget-line-dropdown', budgetLineId, filterBudgetLines = T, ...rest } = props;

  const { data, loading, error } = useBudgetLinesTreeQuery({
    variables: { budgetLineId },
    fetchPolicy: 'cache-first',
  });

  const items = React.useMemo(
    () => extractBudgetLinesData(data, filterBudgetLines),
    [data, filterBudgetLines],
  );

  if (error) {
    console.error(error);
    return <div>Error fetching budget lines</div>;
  }

  if (loading && !data) {
    return (
      <ComboBox.Loading id={id} variant="inlineText" {...rest} multiple={false} selected={['']} />
    );
  }

  return <ComboBox id={id} variant="inlineText" {...rest} items={items} multiple={false} />;
};

BudgetLineDropdown.displayName = 'BudgetLineDropdown';
