import { unstable_batchedUpdates as batch } from 'react-dom';

export const createSetGroupByExclusive = (
  groupable: string[],
  toggleGroupBy: (id: string, set: boolean) => void,
) => (columnId?: string) => {
  batch(() => {
    const off = groupable.filter(id => id !== columnId);
    // Ensure that each column group is off
    off.forEach(id => toggleGroupBy(id, false));

    if (columnId) {
      // Ensure that the intended column is on
      toggleGroupBy(columnId, true);
    }
  });
};
