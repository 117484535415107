import * as React from 'react';

import { Button } from '@cobbler-io/core-ui/src/Button';
import { Icon } from '@cobbler-io/core-ui/src/Icon';
import { Portal } from '@cobbler-io/core-ui/src/Portal';

import styles from './FullScreenPortal.scss';

export type FullScreenPortalProps = {
  children?: React.ReactNode;
  container: HTMLElement | null;

  onClose: () => void;
};

export const FullScreenPortal = (props: FullScreenPortalProps): JSX.Element => {
  const { children, container, onClose } = props;

  // If the user presses the escape key, close the portal
  React.useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent): void => {
      if (event.key === 'Escape') {
        onClose();
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return (): void => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [onClose]);

  // If there is no container, just render the children
  if (!container) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
  }

  return (
    <Portal>
      <div data-full-screen-portal className={styles.fullScreen} id="full-screen-portal">
        <div className={styles.actions}>
          <Button
            small
            className={styles.closeButton}
            id="close-full-screen"
            name="close-full-screen"
            type="button"
            variant="svg"
            onClick={onClose}
          >
            <Icon size={16} type="close" />
          </Button>
        </div>
        <div className={styles.container}>{children}</div>
      </div>
    </Portal>
  );
};
FullScreenPortal.displayName = 'FullScreenPortal';

type WrapperProps = {
  children: React.ReactNode;
  portal: HTMLDivElement | null;
  toggleFullScreen: () => void;
  isOpenInFullScreen: boolean;
  classNameFull?: string;
  className?: string;
  style?: React.CSSProperties;
  styleFull?: React.CSSProperties;
  containerRef?: React.Ref<HTMLDivElement>;
};
export const WrapperFullScreenPortal = (props: WrapperProps): JSX.Element => {
  const {
    children,
    portal,
    toggleFullScreen,
    isOpenInFullScreen,
    className,
    classNameFull,
    containerRef,
    style,
    styleFull,
  } = props;
  if (isOpenInFullScreen) {
    if (!containerRef && !classNameFull && !styleFull) {
      return (
        <FullScreenPortal container={portal} onClose={toggleFullScreen}>
          {children}
        </FullScreenPortal>
      );
    }

    return (
      <FullScreenPortal container={portal} onClose={toggleFullScreen}>
        <div ref={containerRef} className={classNameFull} style={styleFull}>
          {children}
        </div>
      </FullScreenPortal>
    );
  }

  return (
    <div ref={containerRef} className={className} style={style}>
      {children}
    </div>
  );
};
WrapperFullScreenPortal.displayName = 'WrapperFullScreenPortal';

export default FullScreenPortal;
