import { isRef } from './isRef';

export type MaybeRef<T extends any> = T | React.MutableRefObject<T>;

/**
 * If the object is a MutableRefObject, then it'll return the `current` value from it, otherwise,
 * it'll return the object passed in.
 *
 * This is useful for scenarios when you want to support passing something like an HTMLElement or
 * a ref to an HTMLElement, in which case, you'd be able to get the element with
 * `getValueFromRef(valueOrRef)`
 */
export const getValueFromRef = <T extends any>(x: MaybeRef<T>): T => (isRef<T>(x) ? x.current : x);
