import * as React from 'react';

import { execOrMapFuncs } from '@cobbler-io/utils/src/execOrMapFuncs';

// this should be coming from @cobbler-io/types/index.d.ts, but it isn't...
type NullaryFn<T> = () => T;

export const useOnUnmount = (...fns: NullaryFn<any>[]) => {
  React.useEffect(() => {
    return () => void execOrMapFuncs(fns);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
