/* eslint-disable max-lines-per-function */
import * as React from 'react';

import { noop } from '@cobbler-io/utils/src';
import { timeAgo } from '@cobbler-io/utils/src/time-ago';

import { useToggle } from '@cobbler-io/hooks/src';

import { Button } from '@cobbler-io/core-ui/src/Button';
import { Icon } from '@cobbler-io/core-ui/src/Icon';
import { Menu, useFlyoutMenu } from '@cobbler-io/core-ui/src/Menu';
import { Persona } from '@cobbler-io/core-ui/src/Persona';

import { UserType } from '@cobbler-io/app/src/gql-queries';

import { DiscussionInput } from '../DiscussionInput';

import styles from './DiscussionComment.scss';

type Props = {
  id: string;
  author: UserType;
  createdAt: string;
  // lastUpdatedAt: string;
  // edited?: boolean;
  textContent: string;
  editable?: boolean;
  deletable?: boolean;
  onUpdateComment: (commentId: string, textContent: string) => Promise<any>;
  onDeleteComment: (commentId: string) => Promise<any>;
};

export const DiscussionComment = (props: Props) => {
  const {
    id,
    author,
    createdAt,
    // lastUpdatedAt,
    // edited,
    textContent,
    editable,
    deletable,
    onUpdateComment,
    onDeleteComment,
  } = props;
  const { active: editing, activate: startEditing, deactivate: stopEditing } = useToggle(false);
  const { active: saving, activate: startSaving, deactivate: stopSaving } = useToggle(false);
  const flyOutRef = React.useRef<HTMLButtonElement>(null);
  const handleDeleteComment = () => {
    onDeleteComment(id);
  };
  const handleUpdateComment = (newTextContent: string) => {
    startSaving();
    onUpdateComment(id, newTextContent).then(() => {
      stopSaving();
      stopEditing();
    });
  };

  const openMenu = useFlyoutMenu(
    <Menu small id="discussion-comment-menu">
      {editable && <Menu.Item iconType="edit" label="Edit" onSelect={startEditing} />}
      {deletable && <Menu.Item iconType="delete" label="Delete" onSelect={handleDeleteComment} />}
      {!editable && !deletable && <Menu.Item disabled label="No actions..." onSelect={noop} />}
    </Menu>,
  );

  return (
    <div className={styles.comment}>
      <div className={styles.bar}>
        <Persona inactive={Boolean(author.deleted)} user={author} />
        <span className={styles.time} title={createdAt}>
          {timeAgo(createdAt)}
        </span>
        {(editable || deletable) && (
          <Button
            ref={flyOutRef}
            className={styles.more}
            name="more comment options..."
            type="button"
            variant="svg"
            onClick={openMenu}
          >
            <Icon size={16} type="more" />
          </Button>
        )}
      </div>
      <div className={styles.body}>
        {editable && editing ? (
          <DiscussionInput
            defaultValue={textContent}
            disabled={saving}
            onCancel={stopEditing}
            onSave={handleUpdateComment}
          />
        ) : (
          textContent
        )}
      </div>
    </div>
  );
};

DiscussionComment.displayName = 'DiscussionComment';

export default DiscussionComment;
