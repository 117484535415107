import { setNativeValue } from './setNativeValue';

export const changeNativeValue = <T extends HTMLElement = HTMLInputElement>(
  element: T,
  value: string,
) => {
  // Get the current value of the input
  const lastValue = element instanceof HTMLInputElement ? element.value : '';

  // Manually change the value of the native input
  setNativeValue<T>(element, value);

  // @ts-expect-error: This is a hack to trigger an update in React 16+
  element._valueTracker?.setValue(lastValue); // eslint-disable-line no-underscore-dangle
  element.dispatchEvent(new Event('input', { bubbles: true }));
};
