/* eslint-disable max-lines-per-function */
import * as React from 'react';

import { clamp } from '@cobbler-io/utils/src';
import { head, sum, sumBy } from '@cobbler-io/utils/src/array';
import { isGuid } from '@cobbler-io/utils/src/isGuid';
import { not } from '@cobbler-io/utils/src/not';
import { bankersRound } from '@cobbler-io/utils/src/numbers/bankersRound';
import { trunc } from '@cobbler-io/utils/src/numbers/trunc';
import { prop } from '@cobbler-io/utils/src/prop';
import { propIs } from '@cobbler-io/utils/src/propIs';
import { rando } from '@cobbler-io/utils/src/rando';

import { percentage } from '@cobbler-io/formatters/src/numbers';

import { useToggle } from '@cobbler-io/hooks/src/useToggle';

import { Button } from '@cobbler-io/core-ui/src/Button';
import { CloseButton } from '@cobbler-io/core-ui/src/CloseButton';
import {
  CurrencyField, DisplayFormErrors, Field, PercentField,
} from '@cobbler-io/core-ui/src/Field';
import { Heading } from '@cobbler-io/core-ui/src/Heading';
import { Icon } from '@cobbler-io/core-ui/src/Icon';
import { useCurrentModal } from '@cobbler-io/core-ui/src/Modal';
import { useNotification } from '@cobbler-io/core-ui/src/Notification';

import { useCurrencyFormatter } from '@cobbler-io/redux/src/modules/currency';

import { extractGraphQLErrors } from '@cobbler-io/app/src/api';
import {
  UpsertSliceInput, UpsertSplitInput, UpsertSplitTypeEnum, useUpsertActualSplitMutation,
} from '@cobbler-io/app/src/api/graphql-types';
import { BudgetLineDropdown } from '@cobbler-io/app/src/ndm/components/BudgetLineDropdown';

import Form from '@swan-form/form';
import { required } from '@swan-form/helpers';

import { SplitActualData } from '../normalizeSplitActual';

import styles from './SplitTransactionModal.scss';

const splitOptions: { label: string; value: UpsertSplitTypeEnum }[] = [
  { label: 'Amount', value: 'AMOUNT' },
  { label: 'Percent', value: 'PERCENTAGE' },
];

type SplitBase = { id: ActualId | string; budgetLineId: BudgetLineId | null };
type AmountSplit = { type: 'AMOUNT'; value: MajorCurrency };
type PercentSplit = { type: 'PERCENTAGE'; value: Float };
type Split = SplitBase & XOR<AmountSplit, PercentSplit>;

type SplitState = {
  type: UpsertSplitTypeEnum;
  total: MajorCurrency;
  splits: Split[];
};

type ConvertToPercent = { type: 'Convert/Percent' };
type ConvertToAmount = { type: 'Convert/Amount' };
type SetAmountValue = {
  type: 'Set/Amount';
  payload: { id: ActualId | string; value: MajorCurrency };
};
type SetPercentValue = {
  type: 'Set/Percent';
  payload: { id: ActualId | string; value: Float };
};

type SetBudget = {
  type: 'Set/BudgetLine';
  payload: { id: ActualId | string; value: BudgetLineId | null };
};

type AddSplit = { type: 'Add/Split' };
type DeleteSplit = { type: 'Delete/Split'; payload: { value: ActualId | string } };

type Action =
  | ConvertToPercent
  | ConvertToAmount
  | SetAmountValue
  | SetPercentValue
  | SetBudget
  | AddSplit
  | DeleteSplit;

const updateSplitValue = (
  state: SplitState,
  id: ActualId | string,
  nextValue: number,
): SplitState['splits'] => {
  const [original, ...splits] = state.splits;
  const value = Number.isNaN(nextValue) ? 0 : nextValue;

  const clampValue = (type: UpsertSplitTypeEnum, x: number): number =>
    clamp({ min: 0, max: type === 'PERCENTAGE' ? 100 : state.total }, x);

  if (original.id === id) {
    return [{ ...original, value: clampValue(original.type, value) }].concat(splits);
  }

  const split = splits.find(propIs('id', id));

  if (!split) {
    console.error('Attempted to update a split with ID', id, ', but was not found');
    return state.splits;
  }

  const nextSplitValue = clampValue(split.type, value);
  const delta = split.value - nextSplitValue;
  original.value = clampValue(original.type, original.value + delta);
  split.value = nextSplitValue;

  return [original].concat(splits);
};

const reducer = (state: SplitState, action: Action): SplitState => {
  switch (action.type) {
    case 'Convert/Percent':
      return {
        ...state,
        type: 'PERCENTAGE',
        splits: state.splits.map(split =>
          split.type === 'PERCENTAGE'
            ? split
            : { ...split, type: 'PERCENTAGE', value: (split.value / state.total) * 100 },
        ),
      };
    case 'Convert/Amount':
      return {
        ...state,
        type: 'AMOUNT',
        splits: state.splits.map(split =>
          split.type === 'AMOUNT'
            ? split
            : { ...split, type: 'AMOUNT', value: (split.value / 100) * state.total },
        ),
      };
    case 'Set/Amount':
    case 'Set/Percent':
      return {
        ...state,
        splits: updateSplitValue(state, action.payload.id, action.payload.value),
      };
    case 'Set/BudgetLine':
      return {
        ...state,
        splits: state.splits.map(split =>
          split.id === action.payload.id ? { ...split, budgetLineId: action.payload.value } : split,
        ),
      };
    case 'Add/Split':
      return {
        ...state,
        splits: state.splits.concat({
          id: rando(),
          type: state.type,
          budgetLineId: null,
          value: 0,
        }),
      };
    case 'Delete/Split':
      return {
        ...state,
        splits: state.splits.filter(not(propIs('id', action.payload.value))),
      };
    default:
      return state;
  }
};

const getInitialState = (
  params: SplitActualData,
  convertFromMinorUnit: (x: MinorCurrency) => MajorCurrency,
): SplitState => {
  return {
    type: params.splitType ?? 'AMOUNT',
    total: convertFromMinorUnit(params.originalAmount),
    splits: params.slices.map(slice => ({
      id: slice.id,
      budgetLineId: slice.budgetLine?.id ?? null,
      type: params.splitType,
      value:
        params.splitType === 'PERCENTAGE'
          ? (slice.amount / params.originalAmount) * 100
          : convertFromMinorUnit(slice.amount),
    })),
  };
};

const useManageSplit = (params: SplitActualData) => {
  const { convertFromMinorUnit } = useCurrencyFormatter();
  const [state, dispatch] = React.useReducer(
    reducer,
    getInitialState(params, convertFromMinorUnit),
  );

  const actions = React.useMemo(
    () => ({
      setBudgetLine: ({ id, value }: SetBudget['payload']) =>
        dispatch({ type: 'Set/BudgetLine', payload: { id, value } }),
      setPercentage: ({ id, value }: SetPercentValue['payload']) =>
        dispatch({ type: 'Set/Percent', payload: { id, value } }),
      setAmount: ({ id, value }: SetAmountValue['payload']) =>
        dispatch({ type: 'Set/Amount', payload: { id, value } }),
      convertToPercent: () => dispatch({ type: 'Convert/Percent' }),
      convertToAmount: () => dispatch({ type: 'Convert/Amount' }),
      addSplit: () => dispatch({ type: 'Add/Split' }),
      deleteSplit: ({ value }: DeleteSplit['payload']) =>
        dispatch({ type: 'Delete/Split', payload: { value } }),
    }),
    [dispatch],
  );

  return { ...state, ...actions };
};

type SplitTransactionModalProps = {
  refetch: () => Promise<any>;
  actual: SplitActualData;
};

type CreateUpsertSplitInputParams = {
  original: SplitTransactionModalProps['actual'];
  splitType: UpsertSplitTypeEnum;
  splits: Split[];
  convertToMinorUnit: (x: MajorCurrency) => MinorCurrency;
  minorUnit: number;
};

const createUpsertSplitInput = (params: CreateUpsertSplitInputParams): UpsertSplitInput => {
  const { original, splitType, splits, convertToMinorUnit, minorUnit } = params;
  const [_, ...nextSlices] = splits;

  const slices: UpsertSliceInput[] = nextSlices.map(s => {
    const cents =
      s.type === 'PERCENTAGE'
        ? bankersRound((s.value / 100) * original.originalAmount)
        : convertToMinorUnit(s.value);

    if (isGuid(s.id)) {
      return {
        sliceId: s.id,
        amount: { cents },
        budgetLineId: s.budgetLineId!,
      };
    }

    return {
      sliceId: null,
      amount: { cents },
      budgetLineId: s.budgetLineId!,
    };
  });

  const remainingAmount = {
    cents: trunc(minorUnit, original.originalAmount - sumBy(slices, 'amount.cents')),
  };

  return {
    originId: original.id,
    splitType,
    remainingAmount,
    slices,
  };
};

const prefix = (p: string, val: any): string => [p, val].join('-');

export const SplitTransactionModal = (props: SplitTransactionModalProps) => {
  const { actual, refetch } = props;
  const [formErrors, setFormErrors] = React.useState<string | null>(null);
  const { active: isSubmitting, set: setSubmitting } = useToggle(false);
  const { close } = useCurrentModal();
  const { currency, convertToMinorUnit, parseFloatLocale, minorUnit } = useCurrencyFormatter();
  const notify = useNotification();

  const [upsertSplitActual] = useUpsertActualSplitMutation();

  const {
    type,
    splits: [original, ...splits],
    total,
    ...actions
  } = useManageSplit(actual);

  const totalValue = sum(
    [original]
      .concat(splits)
      .map(prop('value'))
      .map(x => (type === 'PERCENTAGE' ? (x / 100) * total : x))
      .map(x => trunc(minorUnit, x)),
  );

  const roundingEpsilon = 0.1; // We won't block submit if it doesn't quite add up
  const originalIsSplit = actual.isSplitOrigin || actual.isSplitSlice;
  const remaining = total - totalValue;

  return (
    <div className={styles.container}>
      <Form
        autoComplete={false}
        name="split"
        validate={_ => {
          const input = createUpsertSplitInput({
            original: props.actual,
            splits: [original].concat(splits),
            splitType: type,
            convertToMinorUnit,
            minorUnit,
          });

          if (input.remainingAmount.cents && input.remainingAmount.cents < 0) {
            return 'Amount left on the original actual cannot be less than 0';
          }

          const totalAllocated =
            (input.remainingAmount.cents ?? 0) +
            sumBy(input.slices as UpsertSliceInput[], 'amount.cents');
          const totalUnallocated = trunc(minorUnit, props.actual.originalAmount - totalAllocated);

          if (totalUnallocated !== 0) {
            // TODO better error message
            return 'Splits must add up';
          }

          return false;
        }}
        onError={error => {
          if (typeof error === 'string') {
            setFormErrors(error);
          } else {
            setFormErrors('An unknown error occurred');
          }
        }}
        onSubmit={async _ => {
          setSubmitting(true);
          return upsertSplitActual({
            variables: {
              input: createUpsertSplitInput({
                original: props.actual,
                splits: [original].concat(splits),
                splitType: type,
                convertToMinorUnit,
                minorUnit,
              }),
            },
          })
            .then(() => {
              notify({
                title: 'Success',
                body: `Transaction ${originalIsSplit ? 'split' : 'edited'} successfully`,
              });
            })
            .then(refetch)
            .then(() => {
              setSubmitting(false);
              close();
            })
            .catch((err: any) => {
              const gqlError =
                head(extractGraphQLErrors(err))?.message ??
                'An error occurred when trying to save the split transaction.';
              console.error(gqlError, err);
              setSubmitting(false);
              setFormErrors(gqlError);
            });
        }}
      >
        <Heading>Split transaction</Heading>
        <Field
          label="Split by"
          name="splitType"
          options={splitOptions}
          type="select"
          value={type}
          onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
            if (event.currentTarget.value === 'AMOUNT') {
              actions.convertToAmount();
            } else {
              actions.convertToPercent();
            }
          }}
        />

        <div key={original.id} className={styles.fieldRow}>
          <BudgetLineDropdown
            readonly
            budgetLineId={original.budgetLineId!}
            className={styles.combobox}
            defaultValue={original.budgetLineId!}
            id={original.id}
            label="Assign to"
            name={prefix('budgetLineId', original.id)}
            validate={required}
          />
          {original.type === 'AMOUNT' && (
            <CurrencyField
              label="Amount"
              name={prefix('value', original.id)}
              value={original.value}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const val = parseFloatLocale(event.currentTarget.value) as MajorCurrency;
                actions.setAmount({
                  id: original.id,
                  value: Number.isNaN(val) ? 0 : val,
                });
              }}
            />
          )}
          {original.type === 'PERCENTAGE' && (
            <PercentField
              label="Percentage"
              name={prefix('value', original.id)}
              value={original.value}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const val = parseFloatLocale(event.currentTarget.value);
                actions.setPercentage({
                  id: original.id,
                  value: Number.isNaN(val) ? 0 : val,
                });
              }}
            />
          )}
        </div>

        {splits.map(split => (
          <div key={split.id} className={styles.fieldRow}>
            <BudgetLineDropdown
              clearButton
              budgetLineId={original.budgetLineId!}
              className={styles.combobox}
              defaultValue={split.budgetLineId ?? undefined}
              id={split.id}
              label="Assign to"
              name={prefix('budgetLineId', original.id)}
              validate={required}
              onChange={value =>
                actions.setBudgetLine({ id: split.id, value: value as BudgetLineId | null })
              }
            />
            {type === 'AMOUNT' && (
              <CurrencyField
                defaultValue={split.value}
                label="Amount"
                name={prefix('value', split.id)}
                validate={required}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  const val = parseFloatLocale(event.currentTarget.value) as MajorCurrency;
                  actions.setAmount({
                    id: split.id,
                    value: Number.isNaN(val) ? 0 : val,
                  });
                }}
              />
            )}
            {type === 'PERCENTAGE' && (
              <PercentField
                defaultValue={split.value}
                label="Percentage"
                name={prefix('value', split.id)}
                validate={required}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  actions.setPercentage({
                    id: split.id,
                    value: parseFloatLocale(event.currentTarget.value),
                  });
                }}
              />
            )}
            <Button
              small
              name={`remove ${split.id}`}
              variant="svg"
              onClick={() => actions.deleteSplit({ value: split.id })}
            >
              <Icon size={14} type="close" />
            </Button>
          </div>
        ))}

        <div className={styles.footer}>
          <Button name="add split" variant="text" onClick={actions.addSplit}>
            <Icon size={16} type="plus" /> Add Split
          </Button>

          <span className={styles.hint}>
            {percentage(remaining / total, 2)} ({currency(remaining)} of {currency(total)})
            remaining.
          </span>
        </div>

        {formErrors && (
          <div className={styles.formErrors}>
            <DisplayFormErrors errors={formErrors} />
          </div>
        )}

        <CloseButton />
        <div className="button-container right">
          <Button name="cancel split" variant="text" onClick={() => close()}>
            Cancel
          </Button>
          <Button
            disabled={remaining > roundingEpsilon || isSubmitting}
            name="create split"
            type="submit"
          >
            Save
          </Button>
        </div>
      </Form>
    </div>
  );
};

SplitTransactionModal.displayName = 'SplitTransactionModal';
