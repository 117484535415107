import { Graph } from '@cobbler-io/collection/src/Graph';

import { HydrateBudgetGraphQuery } from '../graphql-types';

type Data = NonNullable<NonNullable<HydrateBudgetGraphQuery['vendors']>['items']>;

type HydrateVendorsParams = {
  graph: Graph<any, any>;
  data: Data;
};

// Pull these to a higher scope so that we don't have to garbage collect as many of them
const labels = ['VENDOR'];

export const hydrateVendors = (params: HydrateVendorsParams): void => {
  const { graph, data } = params;

  // Cycle through the responses and create vertexes
  for (const vendor of data) {
    graph.addVertex({
      id: vendor.id,
      labels,
      properties: { name: vendor.name },
    });
  }
};
