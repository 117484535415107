import { pick } from '@cobbler-io/utils/src/pick';

import { isImage } from './mimeTypes';

export type ImageMeasurements = {
  height: number;
  width: number;
  naturalHeight: number;
  naturalWidth: number;
};

/**
 * Measures the dimensions of an image that is a File or Blob
 */
export const getImageDimensionsFromFile = async (
  file: File | Blob,
): Promise<ImageMeasurements | null> => {
  if (!isImage(file)) {
    return Promise.resolve(null);
  }

  return new Promise<ImageMeasurements | null>(res => {
    const img = new Image();
    const url = URL.createObjectURL(file);

    img.onload = event => {
      const { currentTarget } = event;

      if (!currentTarget || !(currentTarget instanceof HTMLImageElement)) {
        URL.revokeObjectURL(url);
        return res(null);
      }
      const dimensions = pick(['height', 'width', 'naturalHeight', 'naturalWidth'], currentTarget);

      URL.revokeObjectURL(url);
      return res(dimensions);
    };

    img.src = url;
  });
};
