import { isGuid } from '@cobbler-io/utils/src/isGuid';

import { UserType } from '../shared-types/user';

export const isUserType = (x: unknown): x is UserType => {
  if (typeof x !== 'object') {
    return false;
  }

  if (!x) {
    return false;
  }

  if (!('email' in x && 'id' in x)) {
    return false;
  }

  if (!isGuid((x as { id: string }).id)) {
    return false;
  }

  if (typeof (x as { email: string }).email !== 'string') {
    return false;
  }

  return true;
};
