import * as React from 'react';

import { Link, LinkProps } from '@reach/router';

export type LinkSegmentProps = LinkProps<null>;

export const LinkSegment = React.forwardRef<HTMLAnchorElement, LinkSegmentProps>(
  ({ children, ...props }, ref) => (
    <Link {...props} ref={ref}>
      {children}
    </Link>
  ),
);

LinkSegment.displayName = 'LinkSegment';
