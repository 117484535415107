import { useContext } from 'react';

import { createNamedContext } from '@cobbler-io/utils/src/createNamedContext';

import { TableInstance } from 'react-table';

export type DataGridContextType = {
  tableRef: React.RefObject<HTMLTableElement>;
  stuck: string[];
  getInstance: () => TableInstance;
};

export const DataGridContext = createNamedContext<DataGridContextType>('DataGridContext', {
  getInstance: () => null,
  stuck: [],
  tableRef: { current: null },
});

export const useCurrentDataGrid = (): DataGridContextType => useContext(DataGridContext);
