import { isGeneratorFunction } from '@cobbler-io/utils/src/isGeneratorFunction';

import { List } from '../List';

export function from<T>(iterable: Iterable<T> & { length: number }): List<T> {
  if (isGeneratorFunction(iterable)) {
    return new List<T>(iterable);
  }

  const innerFrom = function* () {
    yield* iterable;
  };

  return new List<T>(innerFrom);
}
