import * as React from 'react';

import { cx } from '@cobbler-io/utils/src/cx';

import { useDefaultOrRandom } from '@cobbler-io/hooks/src/useDefaultOrRandom';

import { Button, ButtonProps } from '@cobbler-io/core-ui/src/Button';
import { Icon } from '@cobbler-io/core-ui/src/Icon';
import { useCurrentModal } from '@cobbler-io/core-ui/src/Modal';

import styles from './CloseButton.scss';

type Props = {
  /**
   * The close function
   */
  close?: () => void;

  /**
   * An optional name for the button.
   *
   * Default: `close`
   */
  name?: string;

  /**
   * A unique id for the button
   *
   * If not provided, one will be generated
   */
  id?: string;

  size?: number;
} & Omit<ButtonProps, 'type' | 'variant' | 'name' | 'children'>

export const CloseButton = (props: Props) => {
  const { close, className, style, small = true, name, size = 16 } = props;
  const { close: closeModal } = useCurrentModal();

  /* eslint-disable react/destructuring-assignment */
  const ariaLabel = props['aria-label'] ?? 'close modal';
  const id = useDefaultOrRandom(props.id);
  /* eslint-enable react/destructuring-assignment */

  return (
    <Button
      aria-label={ariaLabel}
      className={cx(styles.closeButton, className)}
      id={id}
      name={name || 'close'}
      small={small}
      style={style}
      type="button"
      variant="svg"
      onClick={close || closeModal}
    >
      <Icon size={size} type="close" />
    </Button>
  );
};

export default CloseButton;

CloseButton.displayName = 'CloseButton';
