import * as React from 'react';

import { cx } from '@cobbler-io/utils/src/cx';

/* eslint-disable complexity */
import { Button } from '@cobbler-io/core-ui/src/Button';
import { Icon } from '@cobbler-io/core-ui/src/Icon';
import { Menu, useFlyoutMenu } from '@cobbler-io/core-ui/src/Menu';
import { useModal } from '@cobbler-io/core-ui/src/Modal';

import { ColumnInstance } from 'react-table';

import { isNumericColumn } from '../Column';
import { DataGridContext } from '../DataGridContext';
import { ResizeHandle } from '../ResizeHandle';
import { getMenuItems } from './getColumnActionMenuItems';

import styles from './HeaderCell.scss';

type HeaderCellProps<T extends Record<string, unknown> = Record<string, unknown>> = {
  column: ColumnInstance<T>;
};

export const HeaderCell = <T extends Record<string, unknown> = Record<string, unknown>>({
  column,
}: HeaderCellProps<T>): JSX.Element => {
  const { canSort, canGroupBy, hideable, isSorted, isGrouped, filterValue, autosize } = column;
  const { stuck, getInstance } = React.useContext(DataGridContext);
  const headerCellRef = React.useRef<HTMLTableCellElement>(null);
  const instance = getInstance();
  const disablePointerEvents = Boolean(instance.state.columnResizing?.isResizingColumn);

  const isFiltered = Boolean(filterValue);

  const sortDir = column.isSortedDesc ? 'desc' : 'asc';
  const sortIcon = (
    <Button
      small
      name="alter sort"
      variant="svg"
      onClick={() => column.toggleSortBy(!column.isSortedDesc, false)}
    >
      <Icon className="inline" size={13} type={sortDir === 'asc' ? 'arrowUp' : 'arrowDown'} />
    </Button>
  );
  const isSortable = canSort;
  const isResizeable = !column.disableResizing && typeof column.getResizerProps === 'function';
  const isHeaderGroup = Array.isArray(column.columns);

  const headerProps = column.getHeaderProps();

  // If the cell is in a column group, is it the first column?
  const isFirst = column.Header && column.Header === column.parent?.columns?.[0]?.Header;
  // If the cell is in a column group, is it the last column?
  const isLast =
    column.Header &&
    column.Header === column.parent?.columns?.[column.parent.columns.length - 1]?.Header;

  const showActionButton = [canSort, canGroupBy, hideable, isSorted, isGrouped].some(Boolean);
  const { create: createModal } = useModal();

  const actionMenu = useFlyoutMenu(<Menu small>{getMenuItems(column, createModal)}</Menu>);

  return (
    <div
      {...headerProps}
      ref={headerCellRef}
      className={cx(
        'header th',
        styles.headerCell,
        column.id.includes('placeholder') && styles.blankHeader,
        isHeaderGroup && styles.headerGroupCell,
        typeof column.Header === 'string' && styles.stringHeaderGroup,
        isFirst && styles.isFirst,
        isLast && styles.isLast,
        isNumericColumn(column) && styles.alignRight,
        column.isPinned && stuck.includes(column.id) && styles.pinned,
        column.className,
        column.headerClassName,
        disablePointerEvents && styles.disablePointerEvents,
      )}
      data-col={column.id}
    >
      <div className={styles.title}>
        {column.render('Header')}
        {!column.hideIndicators && (
          <>
            {isResizeable && !column.placeholderOf && (
              <ResizeHandle {...column.getResizerProps()} onDoubleClick={autosize} />
            )}
            {typeof column.Header === 'string' && (
              <>
                {showActionButton && (
                  <Button
                    small
                    className={styles.actionButton}
                    name="action button"
                    variant="svg"
                    onClick={actionMenu}
                  >
                    <Icon size={14} type="chevronDown" />
                  </Button>
                )}
                {isSortable && (
                  <span className={styles.indicatorIcon}>
                    {column.isSorted ? sortIcon : <Icon size={13} type="blank" />}
                  </span>
                )}
                {isFiltered && (
                  <span className={styles.indicatorIcon}>
                    <Icon size={13} type="filterList" />
                  </span>
                )}
              </>
            )}
            {!isHeaderGroup && (
              <span
                className={styles.indicatorIcon}
                title={column.isPinned ? 'Column is pinned' : 'Column is not pinned'}
              >
                <Icon size={13} type={column.isPinned ? 'pushPin' : 'blank'} />
              </span>
            )}
          </>
        )}
      </div>
    </div>
  );
};
HeaderCell.displayName = 'DataGrid__HeaderCell';
