/* eslint-disable max-lines-per-function, sort-keys, sort-keys-fix/sort-keys-fix */
import * as React from 'react';

import { cx } from '@cobbler-io/utils/src/cx';

import previewSingle from '@cobbler-io/app/src/assets/doc.png';
import previewMulti from '@cobbler-io/app/src/assets/docs.png';

import { either, pathOr } from 'ramda';
import { useDrag, useDrop } from 'react-dnd';
import { Row as ReactTableRow, TableInstance } from 'react-table';

import {
  DragPreviewImageWithBadge,
} from '../../DragPreviewImageWithBadge/DragPreviewImageWithBadge';
import { Cell } from '../Cell';

import styles from './Row.scss';

export const DRAGGABLE_ROW = 'draggableRow' as const;
export const NOTHING = 'nothing' as const;

const getDraggedRows = <T extends UnknownObject>(
  draggedItem: ReactTableRow<T>,
  selectedRows: ReactTableRow<T>[],
): ReactTableRow<T>[] => {
  if (!draggedItem) {
    return [];
  }
  return draggedItem.isSelected ? selectedRows : [draggedItem];
};

/**
 * When a compatible item is dropped, it calls drop(). Whatever drop() returns
 * is the DropResult, which will be available in endDrag() and getDropResults().
 */
type DropResult<T extends UnknownObject> = {
  draggedRows: ReactTableRow<T>[];
  targetRow: ReactTableRow<T>;
};

/**
 * Props to be injected when drag'n'drop state changes. Basically, stuff that's
 * returned by the useDrag or useDrop hooks, that we can use when rendering.
 */
type DragCollectedProps = {
  isDragging: boolean;
};

export type DropCollectedProps = {
  isOver: boolean;
  isValid: boolean;
};

type RowProps<T extends Record<string, unknown> = Record<string, unknown>> = {
  row: ReactTableRow<T>;
  index: number;
  style?: React.CSSProperties;
  isWindowed?: boolean;
  instance: TableInstance<T>;
};

type MinimalRecord = {
  id?: string;
  isSystemLine?: boolean;
  systemLine?: { isSystemLine?: boolean };
};

export const Row = <T extends MinimalRecord = MinimalRecord>({
  row,
  index,
  style = {},
  isWindowed = false,
  instance,
  ...rest
}: RowProps<T>): JSX.Element => {
  // This means that the rows are grouped and that this is a row group
  const isAggregateRow = row.isGrouped;
  const id = row.original?.id ?? null;
  const isSystemLine = either(
    pathOr(false, ['original', 'systemLine', 'isSystemLine']),
    pathOr(false, ['original', 'isSystemLine']),
  )(row);

  const selectedRows = instance?.selectedFlatRows ?? [];
  const selectedSerialized = selectedRows.map(r => r.id).join('|');

  const isRowDisabled = instance?.isRowDisabled?.(row) ?? false;

  const [{ isDragging }, drag, preview] = useDrag<
    ReactTableRow<T>,
    DropResult<T>,
    DragCollectedProps
  >(
    {
      type: DRAGGABLE_ROW,
      item: row,
      options: { dropEffect: 'move' },

      end: (_, monitor) => {
        const dropResult = monitor.getDropResult();
        instance?.onDraggableEnd?.(dropResult?.targetRow, dropResult?.draggedRows ?? []);
      },

      canDrag: () => row.isDraggable,

      isDragging: monitor => {
        const curr = monitor.getItem();
        return curr.isSelected ? row.isSelected : row === curr;
      },

      collect: monitor => ({ isDragging: monitor.isDragging() }),
    },
    [selectedSerialized, row.isDraggable],
  );

  const [{ isOver, isValid }, drop] = useDrop<ReactTableRow<T>, DropResult<T>, DropCollectedProps>(
    {
      accept: row.isDropTarget ? DRAGGABLE_ROW : NOTHING,
      canDrop: instance?.isRowDroppable
        ? draggedItem => instance.isRowDroppable(row, getDraggedRows(draggedItem, selectedRows))
        : undefined,
      drop: draggedItem => {
        const draggedRows = getDraggedRows(draggedItem, selectedRows);
        instance?.onDraggableDrop?.(row, draggedRows);
        return { draggedRows, targetRow: row };
      },
      hover: draggedItem => {
        instance?.onDraggableHover?.(row, getDraggedRows(draggedItem, selectedRows));
      },
      collect: monitor => {
        const dropProps = instance?.onDraggableChange?.(
          row,
          getDraggedRows(monitor.getItem<ReactTableRow<T>>(), selectedRows),
          monitor.isOver(),
          monitor.canDrop(),
        );

        return (
          dropProps ?? {
            isOver: monitor.isOver(),
            isValid: monitor.canDrop(),
          }
        );
      },
    },
    [selectedSerialized],
  );

  const eventHandlers = Object.fromEntries(
    Object.entries(rest).filter(
      ([key, value]) => key.startsWith('on') && typeof value === 'function',
    ),
  );

  const getCursor = () => {
    if (isDragging) {
      return 'grabbing';
    }
    if (isRowDisabled) {
      return 'not-allowed';
    }
    if (eventHandlers.onClick) {
      return 'pointer';
    }
    return 'auto';
  };

  const appliedStyle = {
    ...(row.getRowProps().style ?? {}),
    // TODO: Find a way to display a custom cursor (grabbing) while dragging
    cursor: getCursor(),
    ...style,
  };

  // TODO: I don't love this. There's got to be a better way to pass `drag` to a Row's child.
  // eslint-disable-next-line no-param-reassign, functional/immutable-data, functional/prefer-tacit
  row.drag = (el: HTMLElement | null) => drag(el);

  const dragCount = row.isSelected ? selectedRows.length : 1;
  return (
    <div
      {...row.getRowProps()}
      {...eventHandlers}
      ref={drop}
      className={cx(
        'tr',
        row.depth > 0 && 'sub-row',
        styles.row,
        row.depth > 0 && styles.subRow,
        isAggregateRow && (isWindowed ? styles.virtualGroupedRow : styles.groupedRow),
        isSystemLine && styles.muted,
        isDragging && 'is-dragging',
        isRowDisabled && styles.disabled,
        isOver && 'is-over',
        isOver && (isValid ? 'is-valid' : 'is-invalid'),
      )}
      data-depth={row.depth ?? 0}
      data-row-id={id}
      style={appliedStyle}
    >
      {row.cells.map(cell => (
        <Cell<T> key={cell.getCellProps().key} cell={cell} row={row} rowIndex={index} />
      ))}

      <DragPreviewImageWithBadge
        connect={preview}
        count={dragCount}
        src={previewSingle}
        srcMulti={previewMulti}
      />
    </div>
  );
};
Row.displayName = 'DataGrid__Row';
