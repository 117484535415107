import * as React from 'react';

import { Edge, Graph, Vertex } from '../';

export type GraphState<V extends Vertex = Vertex, E extends Edge = Edge> = { graph: Graph<V, E> };

export const GraphContext = React.createContext<GraphState<any, any>>({ graph: new Graph() });
GraphContext.displayName = 'GraphContext';

export const useGraphContext = <V extends Vertex = Vertex, E extends Edge = Edge>(): GraphState<
  V,
  E
> => React.useContext(GraphContext);
