import * as React from 'react';

import { HeaderGroup } from 'react-table';

import { HeaderCell } from '../HeaderCell';

import styles from './HeaderRow.scss';

type HeaderRowProps<T extends Record<string, unknown> = Record<string, unknown>> = {
  group: HeaderGroup<T>;
};

export const HeaderRow = <T extends Record<string, unknown> = Record<string, unknown>>({
  group,
}: HeaderRowProps<T>): JSX.Element => (
  <div
    key={group.getHeaderGroupProps().key}
    {...group.getHeaderGroupProps()}
    className={styles.headerRow}
  >
    {group.headers.map(col => (
      <HeaderCell<T> key={col.getHeaderProps().key} column={col} />
    ))}
  </div>
);

HeaderRow.displayName = 'DataGrid__HeaderRow';
