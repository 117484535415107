import { propEq } from 'ramda';
import { useSelector } from 'react-redux';

import { revisionsSelector } from './revisionsSelector';
import { Revision } from './types';

export const useSelectedRevision = (): Revision | null => {
  const { revisions, selected } = useSelector(revisionsSelector) ?? {};

  if (!revisions || !selected) {
    return null;
  }

  return revisions.find(propEq('id', selected)) ?? null;
};
