export type HandledEvent =
  | 'auxclick'
  | 'click'
  | 'contextmenu'
  | 'dblclick'
  | 'mousedown'
  | 'mouseenter'
  | 'mouseleave'
  | 'mousemove'
  | 'mouseout'
  | 'mouseover'
  | 'mouseup'
  | 'select'
  | 'wheel'

  // Touch Events
  | 'touchcancel'
  | 'touchend'
  | 'touchenter'
  | 'touchleave'
  | 'touchmove'
  | 'touchstart'

  // Pointer Events
  | 'pointerover'
  | 'gotpointercapture'
  | 'lostpointercapture'
  | 'pointercancel'
  | 'pointerdown'
  | 'pointerenter'
  | 'pointerleave'
  | 'pointermove'
  | 'pointerout'
  | 'pointerup'

  // View events
  | 'fullscreenchange'
  | 'fullscreenerror'
  | 'resize'
  | 'scroll'

  // Page Events
  | 'pagehide'
  | 'pageshow'
  | 'popstate'

  // Keybboard events
  | 'keydown'
  | 'keypress'
  | 'keyup'

  // Tab events
  | 'visibilitychange';

/**
 * A list of events that we can bind to on `document`
 *
 * I don't aim for this to be comprehensive, but it should be added to as the list of events
 * used grows
 */
export const handledEvents: Readonly<HandledEvent[]> = [
  // Mouse events
  'auxclick',
  'click',
  'contextmenu',
  'dblclick',
  'mousedown',
  'mouseenter',
  'mouseleave',
  'mousemove',
  'mouseout',
  'mouseover',
  'mouseup',
  'select',
  'wheel',

  // Touch Events
  'touchcancel',
  'touchend',
  'touchenter',
  'touchleave',
  'touchmove',
  'touchstart',

  // Pointer Events
  'pointerover',
  'gotpointercapture',
  'lostpointercapture',
  'pointercancel',
  'pointerdown',
  'pointerenter',
  'pointerleave',
  'pointermove',
  'pointerout',
  'pointerup',

  // View events
  'fullscreenchange',
  'fullscreenerror',
  'resize',
  'scroll',

  // Page Events
  'pagehide',
  'pageshow',
  'popstate',

  // Keybboard events
  'keydown',
  'keypress',
  'keyup',

  // Tab events
  'visibilitychange',
];

export default handledEvents;
