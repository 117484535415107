import * as React from 'react';

import { createNamedContext } from '@cobbler-io/utils/src/createNamedContext';
import { isString } from '@cobbler-io/utils/src/isString';
import { noop } from '@cobbler-io/utils/src/noop';

import { useResizeObserver } from '@cobbler-io/hooks/src/useResizeObserver';

import { Button } from '../Button';
import { Icon } from '../Icon';
import { Modal, useModal } from '../Modal';
import { DropdownSegment, DropdownSegmentProps } from './Segments/DropdownSegment';
import { LinkSegment } from './Segments/LinkSegment';
import { Segment } from './Segments/Segment';
import { StringSegment } from './Segments/StringSegment';

import styles from './BreadCrumbs.scss';

export type StringSegmentType = string;
export type LinkSegmentType = { to: string; key?: string; children: string };
export type DropdownSegmentType = DropdownSegmentProps & { type: 'dropdown' };

export type SegmentType = StringSegmentType | LinkSegmentType | DropdownSegmentType;

export type BreadCrumbsProps = {
  // Add props here
  segments: SegmentType[];
};

const mapSegment = (segment: SegmentType, index: number, arr: SegmentType[]) => {
  const separator = index !== arr.length - 1;

  if (typeof segment === 'string') {
    return (
      <Segment key={segment} id={segment} separator={separator}>
        <StringSegment>{segment}</StringSegment>
      </Segment>
    );
  }

  if (React.isValidElement(segment)) {
    return (
      <Segment key={segment.key} id={segment.key} separator={separator}>
        {segment}
      </Segment>
    );
  }

  if ('to' in segment) {
    const { to, key, children, ...rest } = segment;
    const inner = children || to;
    return (
      <Segment key={key || to} id={key || to} separator={separator}>
        <LinkSegment {...rest} key={to} to={to}>
          {isString(inner) ? decodeURIComponent(inner) : inner}
        </LinkSegment>
      </Segment>
    );
  }

  if ('type' in segment && segment.type === 'dropdown') {
    const { name, key, items, children } = segment;
    return (
      <Segment key={key || name} id={key || name} separator={separator}>
        <DropdownSegment items={items}>
          {isString(children) ? decodeURIComponent(children) : children}
        </DropdownSegment>
      </Segment>
    );
  }

  const fallback = JSON.stringify(segment);
  return (
    <Segment key={fallback} id={fallback} separator={separator}>
      <span>{fallback}</span>
    </Segment>
  );
};

type BreadCrumbContextType = {
  subscribe: (id: string, ref: React.RefObject<HTMLDivElement>) => void;
};
export const BreadCrumbContext = createNamedContext<BreadCrumbContextType>('BreadCrumbContext', {
  subscribe: noop,
});

type OverflowDropdownProps = { children: React.ReactNode };

export const OverflowDropdown = ({ children }: OverflowDropdownProps) => {
  const { create } = useModal();
  const ref = React.useRef<HTMLButtonElement>(null);

  const openModal = React.useCallback(() => {
    create(
      <Modal arrow className={styles.overflow} overlay="transparent">
        {children}
      </Modal>,
      { target: ref.current! },
    );
  }, [children, ref]);

  return (
    <>
      <Button
        ref={ref}
        small
        className={styles.overflowButton}
        name="breadcrumb-overflow"
        variant="text"
        onClick={openModal}
      >
        <Icon type="moreHorizontal" />
      </Button>
      <Icon data-separator type="chevronRight" />
    </>
  );
};

OverflowDropdown.displayName = 'OverflowDropdown';

export const BreadCrumbs: React.FC<BreadCrumbsProps> = props => {
  const { segments = [] } = props;
  const ref = React.useRef<HTMLDivElement>(null);
  const { width = Number.MAX_SAFE_INTEGER } = useResizeObserver({ ref });
  const refMap = React.useRef<Map<string, React.RefObject<HTMLDivElement>>>(new Map());
  const sizeMap = React.useRef<Map<string, number>>(new Map());
  const subscribe = React.useCallback(
    (id: string, reference: React.RefObject<HTMLDivElement>) => {
      refMap.current.set(id, reference);
      sizeMap.current.set(id, reference.current?.clientWidth ?? 0);
    },
    [refMap, sizeMap],
  );
  const [visible, setVisible] = React.useState<null | any>(null);

  const ctx = React.useMemo(() => ({ subscribe }), [subscribe]);

  const children = React.useMemo(() => segments.map(mapSegment), [segments]);

  React.useEffect(() => {
    if (!ref.current) {
      return;
    }

    const arrChildren = React.Children.toArray(children);
    const left: any[] = [];
    const right: any[] = [];
    // We need two overflows here because we're walking from both sides. Otherwise, the overflow
    // will be out of order
    const overflowLeft: any[] = [];
    const overflowRight: any[] = [];
    let availableWidth = width - 40;

    // We want to get the first and last visible elements and cut out the middle ones.
    while (arrChildren.length) {
      // Grab the last element
      const lastChild = arrChildren.pop();
      // Grab the first element
      const firstChild = arrChildren.shift();

      // If we have a lastChild, then measure it. If it can fit, push it into `right`, if not
      // push it into overflowRight
      if (lastChild) {
        const lastChildSize = sizeMap.current.get(lastChild.props.id);
        if ((lastChildSize ?? 0) < availableWidth) {
          right.unshift(lastChild);
          availableWidth -= lastChildSize ?? 0;
        } else {
          overflowRight.unshift(lastChild);
        }
      }

      // If there is a first child, then see if it'll fit. If so, push it into `left`, if not
      // push it into `overflowLeft`
      if (firstChild) {
        const firstChildSize = sizeMap.current.get(firstChild.props.id);
        if ((firstChildSize ?? 0) < availableWidth) {
          left.push(firstChild);
          availableWidth -= firstChildSize ?? 0;
        } else {
          overflowLeft.push(firstChild);
        }
      }
    }

    // Combine both overflow arrays to get a single ordered one
    const overflow = overflowLeft.concat(overflowRight);
    // If there is overflow, then set it
    const vis = overflow.length
      ? left.concat([<OverflowDropdown key="overflow">{overflow}</OverflowDropdown>]).concat(right)
      : null;

    setVisible(vis);
  }, [width, children]);

  // We need to deal with overflow, so render that instead

  return (
    <BreadCrumbContext.Provider value={ctx}>
      <div ref={ref} className={styles.breadCrumbs} data-test="breadcrumbs">
        {visible ?? children}
        <div className={styles.spacer} />
      </div>
    </BreadCrumbContext.Provider>
  );
};

BreadCrumbs.displayName = 'BreadCrumbs';

export default BreadCrumbs;
