import * as React from 'react';

import { useClickAway } from '@cobbler-io/core-ui/src/ClickAway';

import { createPopper, Instance as PopperInstance, Options } from '@popperjs/core';

import styles from './OverflowDropdown.scss';

export type OverflowDropdownProps = {
  reference: React.RefObject<HTMLButtonElement>;
  children: React.ReactNode;
  close: () => void;
};

const defaultPopperOptions: Options = {
  placement: 'bottom-start' as const,
  strategy: 'absolute',
  modifiers: [
    { name: 'arrow', options: { padding: 5 } },
    { name: 'offset', options: { offset: [0, 5] } },
  ],
};

export const OverflowDropdown = (props: OverflowDropdownProps) => {
  const { children, reference, close } = props;
  const flyoutRef = React.useRef<HTMLDivElement>(null);
  const popperRef = React.useRef<PopperInstance | null>(null);
  useClickAway(flyoutRef, close);

  React.useLayoutEffect(() => {
    if (flyoutRef.current && reference.current) {
      popperRef.current = createPopper(reference.current, flyoutRef.current, defaultPopperOptions);
    }
    if (popperRef?.current) {
      setTimeout(() => popperRef.current?.update(), 0);
    }
  }, [reference, flyoutRef]);

  return (
    <div ref={flyoutRef} className={styles.moreFlyout}>
      <div data-popper-arrow className={styles.arrow} />
      <div className={styles.flyoutBackground}>{children}</div>
    </div>
  );
};

OverflowDropdown.displayName = 'OverflowDropdown';
