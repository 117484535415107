import * as React from 'react';

import { noop } from '@cobbler-io/utils/src/noop';

type StreamDimensions = {
  height?: number;
  width?: number;
  aspectRatio?: number;
};

// TODO: Should be the below types but most aren't recognized. Falling back to DOMException
// https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/getUserMedia#exceptions
type MediaStreamError = DOMException;
// | AbortError
// | NotAllowedError
// | NotFoundError
// | NotReadableError
// | OverconstrainedError
// | SecurityError
// | TypeError;

export type WebcamState = {
  canPlay: boolean;
  loaded: boolean;
  capture: boolean;
  error: MediaStreamError | null;
  device: string | null;
  dimensions: StreamDimensions;
  height: number;
  mimeType?: 'image/png' | 'image/jpeg' | 'image/webp';
  onAcceptPicture: (picture: Blob) => any;
  quality?: number;
  width: number;
};

export const initialState: WebcamState = {
  canPlay: false,
  loaded: false,
  capture: false,
  error: null,
  device: null,
  dimensions: {},
  height: 480,
  mimeType: 'image/jpeg',
  onAcceptPicture: noop,
  quality: 1,
  width: 640,
};

const CAN_PLAY = 'WEBCAM/CAN_PLAY';
const CANNOT_PLAY = 'WEBCAM/CANNOT_PLAY';
const LOADED = 'WEBCAM/LOADED';
const UNLOADED = 'WEBCAM/UNLOADED';
const STREAM_ERROR = 'WEBCAM/STREAM_ERROR';
const SELECT_DEVICE = 'WEBCAM/SELECT_DEVICE';
const SET_DIMENSIONS = 'WEBCAM/SET_DIMENSIONS';
const STREAM_LOADED = 'WEBCAM/STREAM_LOADED';

type CanPlay = { type: typeof CAN_PLAY };
const canPlay = (dispatch: React.Dispatch<CanPlay>) => () => dispatch({ type: CAN_PLAY });
type CannotPlay = { type: typeof CANNOT_PLAY };
const cannotPlay = (dispatch: React.Dispatch<CannotPlay>) => () => dispatch({ type: CANNOT_PLAY });
type Loaded = { type: typeof LOADED };
const loaded = (dispatch: React.Dispatch<Loaded>) => () => dispatch({ type: LOADED });
type Unloaded = { type: typeof UNLOADED };
const unloaded = (dispatch: React.Dispatch<Unloaded>) => () => dispatch({ type: UNLOADED });
type StreamError = { type: typeof STREAM_ERROR; payload: MediaStreamError };
const streamError = (dispatch: React.Dispatch<StreamError>) => (error: MediaStreamError) =>
  dispatch({ type: STREAM_ERROR, payload: error });

type SelectDevice = { type: typeof SELECT_DEVICE; payload: string | null };
const selectDevice = (dispatch: React.Dispatch<SelectDevice>) => (id: string | null) =>
  dispatch({ type: SELECT_DEVICE, payload: id });

type SetDimensions = { type: typeof SET_DIMENSIONS; payload: StreamDimensions };
const setDimensions = (dispatch: React.Dispatch<SetDimensions>) => (dimensions: StreamDimensions) =>
  dispatch({ type: SET_DIMENSIONS, payload: dimensions });

type StreamLoaded = {
  type: typeof STREAM_LOADED;
  payload: {
    dimensions: StreamDimensions;
    device: string | null;
  };
};
const streamLoaded =
  (dispatch: React.Dispatch<StreamLoaded>) =>
  (device: string | null, dimensions: StreamDimensions) =>
    dispatch({ type: STREAM_LOADED, payload: { device, dimensions } });

const SET_CAPTURE = 'WEBCAM/SET_CAPTURE';
type SetCapture = { type: typeof SET_CAPTURE };
const setCapture = (dispatch: React.Dispatch<SetCapture>) => () => dispatch({ type: SET_CAPTURE });

const REMOVE_CAPTURE = 'WEBCAM/REMOVE_CAPTURE';
type RemoveCapture = { type: typeof REMOVE_CAPTURE };
const removeCapture = (dispatch: React.Dispatch<RemoveCapture>) => () =>
  dispatch({ type: REMOVE_CAPTURE });

export type WebcamAction =
  | CanPlay
  | CannotPlay
  | Loaded
  | Unloaded
  | StreamLoaded
  | StreamError
  | SelectDevice
  | SetDimensions
  | SetCapture
  | RemoveCapture;

export const reducer = (state: WebcamState, action: WebcamAction): WebcamState => {
  switch (action.type) {
    case CAN_PLAY:
      return { ...state, canPlay: true };
    case CANNOT_PLAY:
      return { ...state, canPlay: false };
    case LOADED:
      return { ...state, loaded: true };
    case UNLOADED:
      return { ...state, loaded: false };
    case STREAM_LOADED:
      return { ...state, device: action.payload.device, dimensions: action.payload.dimensions };
    case STREAM_ERROR:
      return { ...state, error: action.payload };
    case SELECT_DEVICE:
      return { ...state, device: action.payload };
    case SET_DIMENSIONS:
      return { ...state, dimensions: action.payload };
    case SET_CAPTURE:
      return { ...state, capture: true };
    case REMOVE_CAPTURE:
      return { ...state, capture: false };

    default:
      return state;
  }
};

export type WebcamActions = {
  canPlay: () => void;
  cannotPlay: () => void;
  loaded: () => void;
  unloaded: () => void;
  streamError: (error: MediaStreamError) => void;
  streamLoaded: (device: string | null, dimensions: StreamDimensions) => void;
  selectDevice: (id: string | null) => void;
  setDimensions: (dimensions: StreamDimensions) => void;
  setCapture: () => void;
  removeCapture: () => void;
};

export const useWebcamActions = (dispatch: React.Dispatch<WebcamAction>) => {
  return React.useMemo(
    () => ({
      canPlay: canPlay(dispatch),
      cannotPlay: cannotPlay(dispatch),
      loaded: loaded(dispatch),
      unloaded: unloaded(dispatch),
      streamError: streamError(dispatch),
      streamLoaded: streamLoaded(dispatch),
      selectDevice: selectDevice(dispatch),
      setDimensions: setDimensions(dispatch),
      setCapture: setCapture(dispatch),
      removeCapture: removeCapture(dispatch),
    }),
    [],
  );
};
