import * as React from 'react';

import { PermissionTracker } from '@cobbler-io/dom/src/PermissionTracker';

// We'll keep track of all the permissions and change events in here
const tracker = new PermissionTracker();

/**
 * Checks the Permissions API for the status of a permission
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Permissions_API
 */
export const usePermission = (name: PermissionName): PermissionState | 'unknown' => {
  // Optimisitcally get the permission status
  const initial = tracker.query(name);
  // Track the permission status locally
  const [state, setState] = React.useState<PermissionState | 'unknown'>(
    typeof initial === 'string' ? initial : 'unknown',
  );

  // Listen for changes on the permission
  React.useEffect(() => tracker.listen(name, setState), []);

  return state;
};
