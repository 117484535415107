import { createNamedContext } from '@cobbler-io/utils/src/createNamedContext';

import { BudgetLineTypeEnum } from '@cobbler-io/app/src/api/graphql-types';
import { ActualUrlParams, BudgetUrlParams, LineUrlParams } from '@cobbler-io/app/src/urls/urls';

export type PartialBudgetUrl<T extends PartialBy<BudgetUrlParams, 'budgetId' | 'revisionId'>> = {
  (params: T): string;
  path: string;
};
export type BoundBudgetUrls = {
  overview: PartialBudgetUrl<PartialBy<BudgetUrlParams, 'budgetId' | 'revisionId'>>;
  editor: PartialBudgetUrl<PartialBy<BudgetUrlParams, 'budgetId' | 'revisionId'>>;
  actuals: PartialBudgetUrl<PartialBy<BudgetUrlParams, 'budgetId' | 'revisionId'>>;
  dashboard: PartialBudgetUrl<PartialBy<BudgetUrlParams, 'budgetId' | 'revisionId'>>;
  team: PartialBudgetUrl<PartialBy<BudgetUrlParams, 'budgetId' | 'revisionId'>>;
  settings: PartialBudgetUrl<PartialBy<BudgetUrlParams, 'budgetId' | 'revisionId'>>;

  actualDetails: PartialBudgetUrl<PartialBy<ActualUrlParams, 'budgetId' | 'revisionId'>>;
  actualDiscussions: PartialBudgetUrl<PartialBy<ActualUrlParams, 'budgetId' | 'revisionId'>>;
  actualDiscussionsArchive: PartialBudgetUrl<PartialBy<ActualUrlParams, 'budgetId' | 'revisionId'>>;

  lineDetails: PartialBudgetUrl<PartialBy<LineUrlParams, 'budgetId' | 'revisionId'>>;
  lineDiscussions: PartialBudgetUrl<PartialBy<LineUrlParams, 'budgetId' | 'revisionId'>>;
  lineDiscussionsArchive: PartialBudgetUrl<PartialBy<LineUrlParams, 'budgetId' | 'revisionId'>>;
};

export type Permissions = {
  read: boolean;
  edit: boolean;
  manage: boolean;
};

export type BudgetLineStub = {
  id: BudgetLineId;
  name: string;
  permissions: Permissions;
};

export type CurrentBudgetLineContextType = {
  /**
   * The budget line's ancestors in reverse order
   *
   * e.g.: [Parent, GrandParent, ..., Root]
   */
  ancestors: BudgetLineStub[];
  /**
   * A unique ID for the budget line
   */
  id: BudgetLineId;
  /**
   * Whether or not THIS budget line is the root of the tree
   */
  isRoot: boolean;
  /**
   * The name of the budget line
   */
  name: string;
  /**
   * The type of the budget line
   */
  type: BudgetLineTypeEnum;
  /**
   * Whether or not THIS budget line is a revenue line
   */
  isRevenue: boolean;
  /**
   * Permissions as to what THIS user can do on THIS budget line
   */
  permissions: Permissions;
  /**
   * The root budget line in this tree. This could be the current budget line
   */
  root: BudgetLineStub;
  /**
   * Bound urls for navigating within the budget line
   */
  urls: BoundBudgetUrls;
  /**
   * Legacy object.
   *
   * @deprecated
   */
  budget: BudgetLineStub;
};

export const CurrentBudgetLineContext = createNamedContext<CurrentBudgetLineContextType | null>(
  'CurrentBudgetLineContext',
  null,
);
