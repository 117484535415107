/* eslint-disable sort-keys, sort-keys-fix/sort-keys-fix */
import { getFiscalYear } from './getFiscalYear';
import { getQuarter } from './getQuarter';

const fmt = new Intl.DateTimeFormat(undefined, {
  month: 'long',
  year: '2-digit',
});

export type MonthPeriod = {
  name: string;
  start: Date;
  end: Date;
  quarter: number;
  year: number;
};

export const getMonthsInPeriod = (start: Date, end: Date, startMonth = 0): MonthPeriod[] => {
  const months: MonthPeriod[] = [];
  const d = new Date(start);

  // eslint-disable-next-line no-unmodified-loop-condition
  while (d < end) {
    const name = fmt.format(d);
    const s = new Date(d);
    const e = new Date(d);
    const quarter = getQuarter(s, startMonth);
    const year = getFiscalYear(s, startMonth);
    e.setMonth(e.getMonth() + 1, 0);
    e.setHours(23, 59, 59, 999); // eslint-disable-line @typescript-eslint/no-magic-numbers
    months.push({ name, start: s, end: e, quarter, year }); // eslint-disable-line functional/immutable-data
    d.setMonth(d.getMonth() + 1);
  }

  return months;
};
