import { isHTMLElement } from '@cobbler-io/utils/src/isHTMLElement';
import { append } from '@cobbler-io/utils/src/string/append';

import { focusableElements } from './focusableElements';

const FOCUSABLE_ELEMENT_SELECTOR: string = [
  ...focusableElements.map(append(':not([hidden])')),
  '[tabindex]:not([disabled]):not([hidden])',
].join(',');

export const getFocusableNodes = <T extends HTMLElement>(
  ref: React.RefObject<T | null>,
): HTMLElement[] => {
  return ref && isHTMLElement(ref.current)
    ? Array.from(ref.current.querySelectorAll(FOCUSABLE_ELEMENT_SELECTOR))
    : [];
};
