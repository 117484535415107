import * as React from 'react';

import { propIn } from '@cobbler-io/utils/src/ramda-utils/propIn';

import { CloseButton } from '@cobbler-io/core-ui/src/CloseButton';
import { Modal, useModal } from '@cobbler-io/core-ui/src/Modal';
import { useNotification } from '@cobbler-io/core-ui/src/Notification';

import {
  BudgetLineEditorDataDocument,
  BudgetLineEditorDataQuery,
  BudgetLineEditorDataQueryVariables,
  useDeleteBudgetLinesMutation,
} from '@cobbler-io/app/src/api/graphql-types';

import { lensPath, over, reject } from 'ramda';

import { DeleteBudgetLinePrompt } from './DeleteBudgetLinePrompt/DeleteBudgetLinePrompt';

const linesLens = lensPath<BudgetLineEditorDataQuery>(['a_budgetLineChildrenEditorData', 'lines']);

export const useDeleteBudgetLines = (
  ids: BudgetLineId[],
  editorDataQueryVars: BudgetLineEditorDataQueryVariables,
) => {
  // TODO: Memoize! Maybe we can avoid passing the ids as a param of the hook
  // and just have them as params of the function.

  const { create: createModal } = useModal();
  const notify = useNotification();
  const [applyDeleteMutation] = useDeleteBudgetLinesMutation({
    optimisticResponse: {
      __typename: 'Mutation',
      a_deleteBudgetLines: {
        __typename: 'DeleteBudgetLinesPayloadType',
        deletedBudgetLineIds: ids,
      },
    },
    update: (cache, mutationResponse) => {
      const deletedIds = mutationResponse.data?.a_deleteBudgetLines?.deletedBudgetLineIds || [];
      if (!deletedIds.length) {
        return;
      }

      // We want to update the cache for the BudgetLineEditorData query, so we
      // get the cached data, modify it and write it back
      const cachedEditorData = cache.readQuery<BudgetLineEditorDataQuery>({
        query: BudgetLineEditorDataDocument, // gql`query BudgetLineEditorData(...){...}`
        variables: editorDataQueryVars,
      });

      if (!cachedEditorData) {
        console.warn('BudgetLineEditorDataQuery cache not found.');
        return;
      }

      cache.writeQuery({
        query: BudgetLineEditorDataDocument,
        variables: editorDataQueryVars,
        data: over(
          linesLens, // target a_budgetLineChildrenEditorData.lines
          reject(propIn('id', ids)), // remove the lines with the specified ids
          cachedEditorData, // on cachedEditorData
        ),
      });
    },
  });

  const handleConfirm = () => {
    applyDeleteMutation({ variables: { ids } }).catch(err => {
      console.error('Unable to delete line(s)', err);
      notify({
        title: 'Error deleting',
        body: `An error occurred while trying to delete the selected budget lines. Please, try again.`,
        persist: true,
      });
    });
  };

  const deleteBudgetLines = () => {
    createModal(
      <Modal overlay className="centered box-shadow-standard">
        <CloseButton />
        <DeleteBudgetLinePrompt ids={ids} onConfirm={handleConfirm} />
      </Modal>,
    );
  };

  return deleteBudgetLines;
};
