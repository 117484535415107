/**
 * Sets the native value of an input element
 *
 * This is a hack
 *
 * @see https://github.com/facebook/react/issues/10135#issuecomment-314441175
 */
export const setNativeValue = <T extends HTMLElement = HTMLInputElement>(
  element: T,
  value: string,
) => {
  const valueSetter = Object.getOwnPropertyDescriptor(element, 'value')!.set;
  const prototype = Object.getPrototypeOf(element);
  const prototypeValueSetter = Object.getOwnPropertyDescriptor(prototype, 'value')!.set;

  if (prototypeValueSetter && valueSetter && valueSetter !== prototypeValueSetter) {
    prototypeValueSetter.call(element, value);
  } else if (valueSetter) {
    valueSetter.call(element, value);
  }
};
