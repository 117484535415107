/* eslint-disable max-lines-per-function */
import {
  getSheetByIndex, getWorkBookFromFile, Sheet,
} from '@cobbler-io/utils/src/parseSpreadsheet';
import { upperFirst } from '@cobbler-io/utils/src/string';

import { useCurrencyFormatter } from '@cobbler-io/redux/src/modules/currency';

import {
  PlannedSpendInputType, UpsertBudgetLineInputType,
} from '@cobbler-io/app/src/api/graphql-types';
import { BudgetLineAtRevision } from '@cobbler-io/app/src/ndm/components/BudgetLineEditor/types';

import { BudgetLineAtRevisionInterval } from './types';
import {
  intervalToPeriod, isValidRecord, periodToIntervalStart, safeNumber, safeSplit, safeString,
  sanitizeColumns, splitColumns,
} from './utils';

const validColumns = [
  /* 0 */ /^(name)(\s|$)/i,
  /* 1 */ /^(vendors)(\s|$)/i,
  /* 2 */ /^(owner email)(\s|$)/i,
  /* 3 */ /^(department)(\s|$)/i,
  /* 4 */ /^(account codes)(\s|$)/i,
];

// TODO: initialize with import rules (e.g. col map, etc).
export const useBudgetLineUploader = (budgetLineAtRevision: BudgetLineAtRevision) => {
  const { convertToMinorUnit } = useCurrencyFormatter();

  const createBudgetPlan =
    (intervalStarts: BudgetLineAtRevisionInterval['start'][]) =>
    (record: unknown[]): UpsertBudgetLineInputType => {
      // TODO: Stop hardcoding record indexes and use a map (that later will be
      // provided by the user).

      // Note: When upserting:
      //  - [] & '' delete the current record
      //  - null keep current value/ignore
      const name = String(record[0]);
      const vendorNames = safeSplit(record[1]);
      const ownerUserEmail = safeString(record[2]);
      const departmentNames = safeSplit(record[3]);
      const accountCodes = safeSplit(record[4]);
      const plannedSpends: PlannedSpendInputType[] = intervalStarts.map((start, i) => ({
        planned: convertToMinorUnit(safeNumber(record[i + validColumns.length])),
        start,
      }));

      return {
        accountCodes, // [String!]
        departmentNames, // [String!]
        id: null, // ID
        name, // String!
        ownerUserEmail, // [String!]
        plannedSpends, // [PlannedSpendInputType!];
        vendorNames, // String
      };
    };

  const createBudgetPlans =
    () =>
    ({ header, records }: Sheet): UpsertBudgetLineInputType[] => {
      const { periods } = splitColumns(header, validColumns.length);
      const intervalStarts = periods.map(periodToIntervalStart);
      return records.filter(isValidRecord).map(createBudgetPlan(intervalStarts));
    };

  const getBudgetPlansFromFile = async (file: File): Promise<UpsertBudgetLineInputType[]> =>
    getWorkBookFromFile(file, { raw: true })
      .then(getSheetByIndex(0))
      .then(sanitizeColumns(budgetLineAtRevision, validColumns))
      .then(createBudgetPlans());

  const generateCSVTemplateBlob = () => {
    const columnTitles = ['Name', 'Vendors', 'Owner Email', 'Department', 'Account Codes'];
    const columnMockData = [
      'Something',
      'VendorOne; VendorTwo | VendorThree',
      'joe@company.test',
      'Some department',
      '5000; 5010 | 5020',
    ];

    const intervalColumnTitles = budgetLineAtRevision.intervals.map(
      interval => `${upperFirst(intervalToPeriod(interval))} Planned`,
    );
    const intervalMockData = intervalColumnTitles.map(() => '$0.00');

    const header = [...columnTitles, ...intervalColumnTitles].join(',');
    const mockData = [...columnMockData, ...intervalMockData].join(',');
    return new Blob([[header, mockData].join('\n')], { type: 'text/csv;charset=utf-8' });
  };

  return {
    generateCSVTemplateBlob,
    getBudgetPlansFromFile,
  };
};
