import { useLocation, useNavigate } from '@reach/router';

// Merge the queryParams in case we call navigate with a new queryParams object
const mergeSearchParams = (
  prev: URLSearchParams,
  next?: URLSearchParams | Record<string, any> | UnaryFn<URLSearchParams>,
): URLSearchParams => {
  if (!next) {
    return prev;
  }
  if (next instanceof URLSearchParams) {
    return new URLSearchParams({ ...Object.fromEntries(prev), ...Object.fromEntries(next) });
  }
  if (typeof next === 'function') {
    return next(prev);
  }

  const sanitizedNext = Object.fromEntries(
    Object.entries(next).map(([key, value]) => {
      if (typeof value === 'string') {
        return [key, value];
      }
      return [key, JSON.stringify(value)];
    }),
  );
  return new URLSearchParams({ ...Object.fromEntries(prev), ...sanitizedNext });
};

const searchParamsToSearchString = (queryParams: URLSearchParams) => {
  const params = queryParams.toString();
  return params.startsWith('?') ? params : `?${params}`;
};

export type NormalizedNavigateOptions<TState> = {
  state?: TState;
  replace?: boolean;
  preserveQueryString?: boolean;
  search?: URLSearchParams | Record<string, any> | UnaryFn<URLSearchParams>;
};

type NormalizedNavigate = (
  to: string | number,
  options?: NormalizedNavigateOptions<Record<string, unknown>>,
) => Promise<void>;

export const useNormalizedNavigate: NullaryFn<NormalizedNavigate> = () => {
  const navigate = useNavigate();
  const { search: prevSearch, pathname } = useLocation();
  const normalizedNavigate: NormalizedNavigate = async (to, options) => {
    if (typeof to === 'string') {
      const { preserveQueryString, search, ...restOptions } = options ?? {};
      // eslint-disable-next-line functional/immutable-data
      const last = pathname.split('/').pop();
      const prepend = last && !to.startsWith('/') ? `${last}/` : '';
      if (preserveQueryString) {
        const prevSearchParams = new URLSearchParams(prevSearch);
        const params = mergeSearchParams(prevSearchParams, search);
        const append = searchParamsToSearchString(params);
        return navigate(`${prepend}${to}${append}`, restOptions);
      }
      return navigate(`${prepend}${to}`, restOptions);
    }
    return navigate(to);
  };
  return normalizedNavigate;
};
