import * as React from 'react';

import { execIfFunc } from '@cobbler-io/utils/src';

import { Drawer } from '@cobbler-io/core-ui/src/Drawer/Drawer';

import { ColumnInstance, TableInstance } from 'react-table';

import { ModalTreeNode, useModal } from '../../../Modal';
import { FiltersSelector } from './FiltersSelector';

type FilterDrawerProps<D extends UnknownObject> = {
  allColumns: ColumnInstance<D>[];
  tableInstance: TableInstance<D>;
};
export const useFilterDrawer = <D extends UnknownObject>(props: FilterDrawerProps<D>): void => {
  const { allColumns, tableInstance } = props;
  const { showFilterDrawer, toggleFilterDrawer } = tableInstance;
  const { create: createModal } = useModal();
  const ref = React.useRef<ModalTreeNode | null>(null);

  React.useEffect(() => {
    if (showFilterDrawer && !ref.current) {
      // eslint-disable-next-line functional/immutable-data
      ref.current = createModal(
        <Drawer anchor="right">
          <FiltersSelector allColumns={allColumns} instance={tableInstance} />
        </Drawer>,
        {
          onAfterClose: () => {
            ref.current = null;
            execIfFunc(toggleFilterDrawer);
          },
        },
      );
    }
  }, [allColumns, createModal, showFilterDrawer, tableInstance, toggleFilterDrawer]);
};
