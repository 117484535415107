/* eslint-disable react/no-array-index-key */
import * as React from 'react';

import { noop } from '@cobbler-io/utils/src';
import { cx } from '@cobbler-io/utils/src/cx';

import { Shimmer } from '@cobbler-io/core-ui/src/Shimmer';

import Chip from '../Chip';
import { ToggleButton } from './ToggleButton';

import styles from './ComboBoxLoading.scss';

type InlineDisplayVariant = 'inlineText' | 'inlineChip';
type BlockDisplayVariant = 'text' | 'chip';

type DisplayVariant = InlineDisplayVariant | BlockDisplayVariant;

export type ComboBoxLoadingProps = {
  id: string;
  label?: string;
  className?: string;
  variant: DisplayVariant;
  multiple: boolean;
  selected: any;
};

const isInlineVariant = (variant: DisplayVariant): variant is InlineDisplayVariant =>
  variant === 'inlineChip' || variant === 'inlineText';

type VariantProps = {
  type: 'inlineText' | 'inlineChip';
  selected: any[];
};

const Variant = (props: VariantProps) => {
  const { type, selected } = props;

  if (type === 'inlineChip') {
    return (
      <div className={styles.chips}>
        {selected?.map((_, i) => (
          <Chip key={i} small variant="display" onRemove={noop}>
            ...
          </Chip>
        ))}
      </div>
    );
  }

  return (
    <div>
      {selected?.map((_, i) => (
        <span key={i}>...</span>
      ))}
    </div>
  );
};

Variant.displayName = 'ComboBoxLoading__Variant';

/**
 * First attempt at a generic loading component for a combo box
 *
 * Really, it would be better if we shimmered the border and the values than shimmering the background
 */
export const ComboBoxLoading = (props: ComboBoxLoadingProps) => {
  const { id, label, className, variant, multiple, selected } = props;

  return (
    <div className={cx(styles.container, className)}>
      {label && (
        <label className={styles.label} htmlFor={id}>
          {label}
        </label>
      )}
      <div className={cx(styles.combobox, styles[multiple ? 'multiple' : 'single'])}>
        <Shimmer className={styles.shimmer} />
        <div className={styles.values}>
          {isInlineVariant(variant) && <Variant selected={selected} type={variant} />}
          <input readOnly className={styles.textbox} type="search" />
        </div>
        <div className={styles.controls}>
          <ToggleButton readOnly open={false} onClick={noop} />
        </div>
      </div>
    </div>
  );
};

ComboBoxLoading.displayName = 'ComboBoxLoading';
