import * as React from 'react';
import isEqual from 'react-fast-compare';

/**
 * `useSafeState` differs from `useState` in that the "defaultState" will update the state if a
 * new value is passed.
 *
 * A second argument can be passed as a comparator function, which defaults to `deepEqual`
 */
export const useSafeState = (value: any, comparator = isEqual) => {
  const previous = React.useRef<any>(value);
  const [state, setState] = React.useState<any>(value);

  if (!comparator(value, state)) {
    previous.current = value;
    setState(value);
  }

  return [previous.current, setState];
};
