import { DateLike } from './isDateLike';

export const getEndOfYear = (date: DateLike): Date => {
  const d = new Date(date);
  d.setMonth(0, 1);
  d.setFullYear(d.getFullYear() + 1);
  d.setHours(0, 0, 0, -1);

  return d;
};
