import { isDefined } from './isDefined';

export const createCssGetterSetter = (el: HTMLElement) => (
  prop: keyof React.CSSProperties,
  val?: string | null,
) => {
  if (!isDefined(val)) {
    return el.style[prop];
  }

  // eslint-disable-next-line no-param-reassign
  el.style[prop] = val;

  return val;
};
