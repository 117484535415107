import * as React from 'react';

import { Chip } from '@cobbler-io/core-ui/src/Chip';

import { Item } from '../Item';

export const ChipValues = <TValue extends any = any>(props: {
  selected: Item<TValue>[];
  removeSelected: (item: Item<TValue>) => void;
}): JSX.Element => {
  const { selected, removeSelected } = props;
  return (
    <>
      {selected.map((item: Item) => (
        <Chip key={item.id} small onRemove={() => removeSelected(item)}>
          {item.selectedLabel ?? item.label}
        </Chip>
      ))}
    </>
  );
};

ChipValues.displayName = 'ChipValues';
