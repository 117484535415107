/* eslint-disable sort-keys, sort-keys-fix/sort-keys-fix, @typescript-eslint/no-magic-numbers */
import { timer } from '@cobbler-io/utils/src/timer';

import { addMonth } from '@cobbler-io/dates/src';
import { getEndOfMonth } from '@cobbler-io/dates/src/getEndOfMonth';
import { getMonthsInRange } from '@cobbler-io/dates/src/getMonthsInRange';
import { tzAdjust } from '@cobbler-io/dates/src/tzAdjust';

import {
  generateRangeRevisionStatuses, LoadingStatus,
} from '@cobbler-io/redux/src/modules/graph-status';

import {
  GetRevisionDataDocument, GetRevisionDataQuery, GetRevisionDataQueryVariables, GetSpentDocument,
  GetSpentQuery, GetSpentQueryVariables,
} from '@cobbler-io/app/src/api/graphql-types';

import { Graph } from '@cobbler-io/collection/src/Graph';

import { ApolloClient } from 'apollo-client';

import {
  HydrateBudgetGraphDocument, HydrateBudgetGraphQuery, HydrateBudgetGraphQueryVariables, UserType,
} from '../graphql-types';
import { hydrateBudgetGraph } from './hydrateBudgetGraph';
import { hydratePlannedSpend } from './hydratePlannedSpend';
import { hydrateProposedChanges } from './hydrateProposedChanges';
import { hydrateSpent } from './hydrateSpent';

type GetOptimizedRangeParams = {
  getStatus: UnaryFn<string, LoadingStatus>;
  statusKeyId: string; // 'xyz' in 'xyc--2022-12-25'
  start: ISO8601String;
  end: ISO8601String;
};
type Range = { start: ISO8601String; end: ISO8601String };

/**
 * Given a range and a graph status, returns a new range with optimized start and end to avoid
 * fetching months that are already cached.
 */
const getOptimizedRange = (params: GetOptimizedRangeParams): Range | null => {
  const { start, end, getStatus, statusKeyId } = params;
  const months: ISO8601String[] = getMonthsInRange(
    `${start.substring(0, 7)}-01`,
    addMonth(`${end.substring(0, 7)}-03`),
  );
  const isNotLoaded = (m: ISO8601String) => !getStatus(`${statusKeyId}--${m}`).loaded;
  const l = months.findIndex(isNotLoaded);
  if (l === -1) {
    return null; // Great! Couldn't find any elements that aren't loaded, return null.
  }
  const r = months.slice(l).reverse().findIndex(isNotLoaded);
  const lastMonth = months[months.length - 1 - r];
  const lastDay = getEndOfMonth(tzAdjust(lastMonth)).getDate();

  return {
    start: months[l],
    end: `${lastMonth.substring(0, 7)}-${lastDay}`,
  };
};

type FetchInitialGraphDataParams = {
  client: ApolloClient<UnknownObject>;
  setStatuses: UnaryFn<Record<string, Partial<LoadingStatus>>, void>;
  graph: Graph<any, any>;

  budgetId: BudgetId;
  revisionId: BudgetRevisionId;
  start: ISO8601String;
  end: ISO8601String;
  currentUser: UserType;
  /**
   * The 0 indexed start month of the fiscal year
   */
  startMonth: JSMonth;
};

/**
 * Expected to be called once to do the initial hydration of the budget graph
 */
export const fetchInitialGraphData = async (params: FetchInitialGraphDataParams): Promise<void> => {
  const { client, revisionId, budgetId, start, end, currentUser, graph, startMonth, setStatuses } =
    params;

  setStatuses({ budgetLines: { loading: true } });

  const request = await client.query<HydrateBudgetGraphQuery, HydrateBudgetGraphQueryVariables>({
    query: HydrateBudgetGraphDocument,
    variables: { revisionId, budgetId, start, end },
    fetchPolicy: 'no-cache',
  });

  const { data } = request;
  const graphHydrationTimer = timer('Hydrating the graph');

  hydrateBudgetGraph({ currentUser, data, end, graph, start, startMonth });
  graphHydrationTimer();

  const revisionStatuses = generateRangeRevisionStatuses({
    revisionId,
    start,
    end,
    status: { loaded: true, loading: false },
  });
  const spentStatuses = generateRangeRevisionStatuses({
    revisionId: 'spent',
    start,
    end,
    status: { loaded: true, loading: false },
  });
  setStatuses({
    ...revisionStatuses,
    ...spentStatuses,
    budgetLines: { loaded: true, loading: false },
  });
};

type FetchRevisionDataParams = {
  client: ApolloClient<UnknownObject>;
  getStatus: UnaryFn<string, LoadingStatus>;
  setStatuses: UnaryFn<Record<string, Partial<LoadingStatus>>, void>;
  graph: Graph<any, any>;
  skipPlannedCache?: boolean;
  skipSpendCache?: boolean;

  budgetId: BudgetId;
  revisionId: BudgetRevisionId;
  start: ISO8601String;
  end: ISO8601String;
  startMonth: JSMonth;
};

export const fetchRevisionData = async (params: FetchRevisionDataParams): Promise<void> => {
  const {
    graph,
    budgetId,
    revisionId,
    client,
    start,
    end,
    startMonth,
    setStatuses,
    getStatus,
    skipSpendCache = false,
    skipPlannedCache = false,
  } = params;

  const setRangeStatuses = (statusKeyId: string, status: Partial<LoadingStatus>) => {
    const statuses = generateRangeRevisionStatuses({ revisionId: statusKeyId, start, end, status });
    setStatuses(statuses);
  };

  // Get the minimum range we need to get
  const spentRange = { start, end }; //skipSpendCache
    //? { start, end }
    //: getOptimizedRange({ getStatus, statusKeyId: 'spent', start, end });

  const revisionRange = { start, end }; //skipPlannedCache
    //? { start, end }
    //: getOptimizedRange({ getStatus, statusKeyId: revisionId, start, end });

  // eslint-disable-next-line functional/no-let, no-restricted-syntax, @typescript-eslint/init-declarations, one-var
  let revisionRequest, spentRequest;

  // TODO: Parallelize these requests with Promise.all()
  if (revisionRange) {
    setRangeStatuses(revisionId, { loading: true });

    revisionRequest = await client.query<GetRevisionDataQuery, GetRevisionDataQueryVariables>({
      query: GetRevisionDataDocument,
      variables: { revisionId, ...revisionRange },
      fetchPolicy: 'no-cache',
    });

    spentRequest = await client.query<GetSpentQuery, GetSpentQueryVariables>({
      query: GetSpentDocument,
      variables: { budgetId, revisionId, ...spentRange },
      fetchPolicy: 'no-cache',
    });
  }

  // Set status to "loading"
  if (revisionRequest) {
    hydratePlannedSpend({ graph, startMonth, data: revisionRequest.data.plannedSpendAtRevision });
    hydrateProposedChanges({ graph, data: revisionRequest.data.proposedChanges });
  }
  spentRequest && hydrateSpent({ graph, startMonth, data: spentRequest.data.spend });

  // All at once instead of two calls to the helper, might prevent rerenders
  // setRangeStatuses(revisionId, { loaded: true, loading: false }); setRangeStatuses('spent', { loaded: true, loading: false });
  setStatuses({
    ...generateRangeRevisionStatuses({
      revisionId,
      start,
      end,
      status: { loaded: true, loading: false },
    }),
  });
};

type RefetchGraphHammerParams = {
  client: ApolloClient<UnknownObject>;
  setStatuses: UnaryFn<Record<string, Partial<LoadingStatus>>, void>;
  clearStatuses: NullaryFn;
  graph: Graph<any, any>;

  budgetId: BudgetId;
  revisionId: BudgetRevisionId;
  start: ISO8601String;
  end: ISO8601String;
  currentUser: UserType;
  /**
   * The 0 indexed start month of the fiscal year
   */
  startMonth: JSMonth;
};

/**
 * Expected to be called once to do the initial hydration of the budget graph
 */
export const graphRefetchHammer = async (params: RefetchGraphHammerParams): Promise<void> => {
  const { clearStatuses, ...refetchParams } = params;

  // Clear everything
  clearStatuses();
  refetchParams.graph.truncate();

  // Refetch
  return fetchInitialGraphData(refetchParams);
};
