import * as React from 'react';

import { fullDate } from '@cobbler-io/formatters/src';

import { MessageTemplateParameter } from '../ParameterizedMessage';

export const LocalDateToken = (props: MessageTemplateParameter): JSX.Element => {
  const { displayValue } = props;

  return <span>{fullDate(new Date(displayValue))}</span>;
};

LocalDateToken.displayName = 'LocalDateToken';
