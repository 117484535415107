import { getOwnProperty } from '@cobbler-io/utils/src/getOwnProperty';

import { CellProps, Renderer } from 'react-table';

import {
  DisplayAccounting, DisplayCurrency, DisplayCurrencyRounded, DisplayFinancial,
  DisplayFinancialRounded, DisplayPercent,
} from './cell-renderers';
import { ColumnProps, ColumnTypes } from './Column';

const rendererMap: { [key in ColumnTypes]?: Renderer<CellProps<any>> } = {
  Currency: DisplayCurrency,
  CurrencyRounded: DisplayCurrencyRounded,
  Percent: DisplayPercent,
  Financial: DisplayFinancial,
  FinancialRounded: DisplayFinancialRounded,
  Accounting: DisplayAccounting,
};

type Renderers = typeof rendererMap;

export const getRendererFromType = <T extends Record<string, unknown> = Record<string, unknown>>(type: ColumnProps<T>['type']) =>
  getOwnProperty<Renderers, ColumnTypes>(rendererMap, type);
