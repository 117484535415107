export const clearCanvas = (canvas: HTMLCanvasElement) => {
  const ctx = canvas.getContext('2d');
  ctx?.clearRect(0, 0, canvas.width, canvas.height);
};

export const createCanvasFrameOverlay = (canvas: HTMLCanvasElement) => {
  const ctx = canvas.getContext('2d');
  if (!ctx) {
    return;
  }

  clearCanvas(canvas);
  ctx.beginPath();
  // draw a rect the size of the canvas
  ctx.rect(0, 0, canvas.width, canvas.height);
  ctx.save();
  ctx.restore();
  // draw a circle in the middle of the canvas
  ctx.arc(canvas.width / 2, canvas.height / 2, 150, 0, 2 * Math.PI);
  // fill the part that's not the circle with a semi-transparent black
  ctx.fillStyle = 'rgba(0, 0, 0, .25)';
  ctx.fill('evenodd');
  ctx.save();

  ctx.beginPath();
  ctx.arc(canvas.width / 2, canvas.height / 2, 150 - 2, 0, 2 * Math.PI);
  ctx.strokeStyle = 'rgba(0, 0, 0, .25)';
  ctx.stroke();
};

/* eslint-disable max-params */

export const canvasToFile = async (
  canvas: HTMLCanvasElement,
  fileName: string,
  type = 'image/jpeg',
  quality = 1,
) =>
  new Promise<File>((res, rej) => {
    const callback: BlobCallback = blob =>
      blob ? res(new File([blob], fileName, { type })) : rej('Error converting canvas to file');

    canvas.toBlob(callback, type, quality);
  });

/* eslint-enable max-params */

type DrawImageValues = [number, number, number, number, number, number, number, number];

export const applyAdjustments = (
  image: HTMLImageElement,
  container: HTMLDivElement,
  transform: Record<string, number>,
) => {
  const canvas = document.createElement('canvas');
  canvas.height = container.clientHeight;
  canvas.width = container.clientWidth;
  const ctx = canvas.getContext('2d');

  if (!ctx || !image || !image.parentElement) {
    throw new Error('Could not convert image data');
  }

  clearCanvas(canvas);

  const cRect = container.getBoundingClientRect();
  const iRect = image.getBoundingClientRect();

  const xScale = iRect.width / image.width;
  const yScale = iRect.height / image.height;

  const scale = 1 / Math.max(xScale, yScale);

  /**
   * The x-pixel in the image source to draw from
   *
   * If the image is left-bound is left of the container left bound, then we need to crop,
   * which means that we need to painting from
   */
  const sx = iRect.left < cRect.left ? (cRect.left - iRect.left) * scale : 0;

  /**
   * The y-pixel in the image source to draw from
   */
  const sy = iRect.top < cRect.top ? (cRect.top - iRect.top) * scale : 0;

  /**
   * This is the ideal width of the sub-rect of the image to grab
   */
  const sw =
    (cRect.right < iRect.right ? cRect.right - iRect.left : iRect.right - iRect.left) * scale - sx;

  /**
   * This is the ideal height of the sub-rect of the image to grab
   */
  const sh =
    (cRect.bottom < iRect.bottom ? cRect.bottom - iRect.top : iRect.bottom - iRect.top) * scale -
    sy;

  /**
   * This is the size of the source square that is to be dawnl. Since we want this to be a square,
   * we want to grab the max of each.
   *
   * Consider this the source size
   */
  const ss = Math.max(sw, sh);

  /**
   * The x-pixel in the canvas destination to draw to
   */
  const dx = cRect.left < iRect.left ? iRect.left - cRect.left : 0;

  /**
   * The y-pixel in the canvas destination to draw to
   */
  const dy = iRect.top > cRect.top ? iRect.top - cRect.top : 0;

  /**
   * The width of the square to draw on the canvas
   *
   * Note: this is slightly bugged in that if only part of the source image isn't meant to be drawn
   * to the end of the canvas, then it might adjust the aspect ratio slightly. Huge edge case that
   * doesn't really matter
   */
  const dw =
    (iRect.right < cRect.right ? canvas.width - (cRect.right - iRect.right) : canvas.width) - dx;

  /**
   * The height of the square to draw on the canvas
   *
   * Note: this is slightly bugged in that if only part of the source image isn't meant to be drawn
   * to the end of the canvas, then it might adjust the aspect ratio slightly. Huge edge case that
   * doesn't really matter
   */
  const dh =
    (iRect.bottom < cRect.bottom ? canvas.height - (cRect.bottom - iRect.bottom) : canvas.height) -
    dy;

  const values = [sx, sy, ss, ss, dx, dy, dw, dh].map(x => Math.round(x)) as DrawImageValues;

  ctx.imageSmoothingEnabled = true;
  ctx.imageSmoothingQuality = 'medium';

  /**
   * It looks like this is not supported in Safari.
   *
   * We have two options:
   *   (1) Remove the controls
   *   (2) Manually adjust the pixels ourselves
   */
  ctx.filter = [
    `contrast(${transform.contrast ?? 100}%)`,
    `saturate(${transform.saturate ?? 100}%)`,
    `brightness(${transform.brightness ?? 100}%)`,
  ].join(' ');

  ctx.drawImage(image, ...values);

  return canvas;
};
