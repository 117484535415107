import { RefObject } from 'react';

import { isNil, not, pipe } from 'ramda';

type FocusListenerContainer = RefObject<HTMLElement | RefObject<HTMLElement>[]>;

const refContainsTarget = (container: RefObject<HTMLElement>, target: HTMLElement): boolean => {
  return isNil(container.current) ? true : container.current.contains(target);
};

const isNotNil = pipe(isNil, not);

const refIsNotNil = <T extends any>(ref: RefObject<T>): boolean => isNotNil(ref.current);

const filterRef = <T extends any>(
  ref: RefObject<T[]>,
  callback: (item: T) => boolean,
): RefObject<T[]> => {
  return {
    current: ref.current?.filter(callback) ?? [],
  };
};

export const isFocusLost = (container: FocusListenerContainer, target: HTMLElement): boolean => {
  if (isNil(container.current)) {
    return false;
  }

  if (Array.isArray(container.current)) {
    return filterRef(container as RefObject<RefObject<HTMLElement>[]>, refIsNotNil).current!.some(
      cont => !refContainsTarget(cont, target),
    );
  }

  return !refContainsTarget(container as RefObject<HTMLElement>, target);
};
