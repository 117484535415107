import * as React from 'react';

import { identity } from "@cobbler-io/utils/src/identity";
import Pie from '@visx/shape/lib/shapes/Pie';


type SingleDonutChartParams = {
  percentage: number;
  donutThickness: number;
  radius: number;
  colors: { main: string; secondary: string };
};

export const SingleDonutChart = (props: SingleDonutChartParams) => {
  const { donutThickness, radius, colors, percentage } = props;
  const innerRadius = radius - donutThickness;
  const outerSecondaryRadius = radius - donutThickness / 2;
  const dataMain = [percentage, 100 - percentage];
  const dataSecondary = [100 - percentage, percentage];
  return (
    <Pie
      cornerRadius={1}
      data={dataMain}
      innerRadius={innerRadius}
      outerRadius={radius}
      pieSortValues={() => 1}
      pieValue={identity}
    >
      {mainPie => {
        const mainArc = mainPie.arcs;
        const main = mainPie.path(mainArc[0])!;
        return (
          <Pie
            cornerRadius={1}
            data={dataSecondary}
            innerRadius={innerRadius}
            outerRadius={outerSecondaryRadius}
            pieSortValues={() => -1}
            pieValue={identity}
          >
            {secondaryPie => {
              const secondaryArc = secondaryPie.arcs;
              const secondary = secondaryPie.path(secondaryArc[0])!;
              return (
                <g key="donut-chart">
                  <path d={main} fill={colors.main} />
                  <path d={secondary} fill={colors.secondary} />
                </g>
              );
            }}
          </Pie>
        );
      }}
    </Pie>
  );
};

SingleDonutChart.displayName = 'SingleDonutChart';
