const pathToSegments = (path: string | string[]) =>
  Array.isArray(path) ? path : path.replace(/\]/g, '').replace(/\[/g, '.').split('.');

export const setIn = (obj: Record<string, any>, path: string | string[], value: any) => {
  const parts = pathToSegments(path);

  let out = obj;
  if (obj === null) {
    out = {};
  }

  let segment = out;
  for (let i = 0; i < parts.length - 1; i++) {
    const part = parts[i];
    segment[part] ??= {};
    segment = segment[part];
  }
  segment[parts[parts.length - 1]] = value;

  return out;
};
