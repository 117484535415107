/* eslint-disable react/no-array-index-key, no-underscore-dangle */
import * as React from 'react';

import { ModalLayerContext } from './ModalLayerContext';
import { renderModalTreeNode } from './renderModalTreeNode';
import { ModalRenderer, ModalTreeNode } from './types';

const createLimitRenderer = (limit: number): ModalRenderer => {
  let count = 0;
  return modal => {
    // eslint-disable-next-line no-plusplus
    if (++count <= limit) {
      return renderModalTreeNode(modal);
    }
    return null;
  };
};

type ModalLayerProps = {
  flatModals: ModalTreeNode[];
};

export const ModalLayer = ({ flatModals }: ModalLayerProps): JSX.Element => {
  const renderers: Record<string, ModalRenderer> = {
    __default: renderModalTreeNode,
    Notification: createLimitRenderer(5),
  };
  const getRenderer = (type: string) => renderers[type] ?? renderers.__default;
  const topModal = flatModals[flatModals.length - 1];
  const positionCounter = Object.keys(renderers).reduce<Record<string, number>>((acc, key) => {
    acc[key] = 0;
    return acc;
  }, {});
  return flatModals.map((modal, index) => {
    const render = getRenderer(modal.type);
    const rendererName = renderers[modal.type] ? modal.type : '__default';

    const layerContextValue = {
      modal,
      isTop: modal === topModal,
      position: {
        absolute: index,
        relative: positionCounter[rendererName]++, // eslint-disable-line no-plusplus
      },
    };
    return (
      <ModalLayerContext.Provider key={modal.id} value={layerContextValue}>
        {render(modal, topModal, positionCounter[rendererName])}
      </ModalLayerContext.Provider>
    );
  }) as unknown as JSX.Element;
};

ModalLayer.displayName = 'ModalLayer';
