import * as React from 'react';

import { cx } from '@cobbler-io/utils/src/cx';

import styles from './Spinner.scss';

export type SpinnerProps = {
  /**
   * How big should the spinner be
   *
   * Note: this gets set as `width` and `height`
   */
  size?: string;
  /**
   * Regular CSS classes
   */
  className?: string;
  /**
   * A color
   *
   * This can also be set with a class or a style object
   */
  color?: string;
  /**
   * A regular CSS style object
   */
  style?: React.CSSProperties;
};

export const Spinner = ({
  size,
  className,
  color,
  style = {},
  ...props
}: SpinnerProps): JSX.Element => {
  const propStyles = Object.entries({ height: size, width: size, color }).reduce(
    (acc, [key, val]) => {
      if (typeof val !== 'undefined') {
        acc[key] = val;
      }
      return acc;
    },
    {},
  );

  return (
    <div
      {...props}
      className={cx(styles.spinner, styles.default, className)}
      style={{ ...propStyles, ...style }}
    >
      <div />
      <div />
      <div />
      <div />
    </div>
  );
};

Spinner.displayName = 'Spinner';

export default Spinner;
