import * as React from 'react';

type Props = {
  /**
   * Delay in miliseconds
   */
  ms?: number;
}

// @ts-expect-error: the typings on this are screwed at the package level
export const Delay: React.FC<Props> = ({ children, ms = 2000 }) => {
  const [done, setDone] = React.useState(false);

  React.useEffect(() => {
    const timer = window.setTimeout(() => setDone(true), ms);

    return () => window.clearTimeout(timer);
  });

  if (!done) {
    return null;
  }

  // If we ditch the fragment, then we get some type errors because of how React.ReactNode is defined
  return done ? React.Children.only(children) : null;
};

export default Delay;
