import * as React from 'react';

import styles from './Listbox.scss';

type ListboxProps = {
  children: React.ReactNode;
};

export const Listbox = ({ children, ...props }: ListboxProps) => {
  return (
    <ul className={styles.listbox} {...props}>
      {children}
    </ul>
  );
};

Listbox.displayName = 'Listbox';
