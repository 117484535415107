/* eslint-disable sort-keys, sort-keys-fix/sort-keys-fix */
import { Graph } from '@cobbler-io/collection/src/Graph';

import { HydrateBudgetGraphQuery } from '../graphql-types';
import { SpendPlanVertex } from './graph';
import { getProposedChangeForPlannedSpendId } from './utils';

type Data = NonNullable<HydrateBudgetGraphQuery['proposedChanges']>;

type HydratePlanningVersionsParams = {
  graph: Graph<any, any>;
  data: Data;
};

const proposedChangeLabels = ['PROPOSED_CHANGE'];

export const hydrateProposedChanges = (params: HydratePlanningVersionsParams): void => {
  const { data, graph } = params;

  for (const change of data) {
    const pc = graph.addVertex({
      id: change.id,
      labels: proposedChangeLabels,
      properties: {
        amount: change.proposedAmount,
        diff: change.diff,
      },
    });

    const ps: SpendPlanVertex | null = graph.getVertexById(change.plannedSpendId);
    if (ps) {
      graph.addEdge({
        id: getProposedChangeForPlannedSpendId(change.plannedSpendId, change.id),
        labels: ['PROPOSED_CHANGE_ON'],
        from: pc,
        to: ps,
        properties: {
          budgetLineId: ps.properties.budgetLineId,
          budgetRevisionId: ps.properties.budgetRevisionId,
          month: ps.properties.start,
        },
      });
    } else {
      console.log('No planned spend...');
    }
  }
};
