/**
 * This should be the base chip that is mostly a display chip. Functionality to turn them into input
 * chips of any sort should be decorated onto this. Hence, we can pipe in a lot of props, but this
 * should have no knowledge.
 *
 * Also, since they're divs, we're going to have to add some accessibility features to them.
 */

import * as React from 'react';

import { cx } from '@cobbler-io/utils/src/cx';
import { isFunction } from '@cobbler-io/utils/src/isFunction';

import { Button } from '../Button';
import { Icon } from '../Icon';

import styles from './Chip.scss';

type ChipVariants = 'action' | 'choice' | 'display' | 'input' | 'filter';

type Props = {
  className?: string;
  Left?: string | React.ReactNode | React.ComponentType<any>;
  Right?: string | React.ReactNode | React.ComponentType<any>;

  onClick?: React.MouseEventHandler<HTMLDivElement>;

  title?: string;

  variant?: ChipVariants;

  small?: boolean;

  outline?: boolean;
} & React.HTMLProps<HTMLDivElement>;

export type BaseChipProps = {
  variant?: ChipVariants;

  small?: boolean;

  className?: string;
  children: React.ReactNode;
};

export type CheckableChip = {
  checked: boolean;
  toggleChecked: () => void;
} & BaseChipProps;

export type RemoveableChip = {
  onRemove: () => void;
} & BaseChipProps;

export type ChoiceChip = {
  checked: boolean;
  toggleChecked?: never;
} & BaseChipProps;

export type ChipProps = CheckableChip | RemoveableChip | ChoiceChip;

const isRemoveableChip = (props: any): props is RemoveableChip => isFunction(props.onRemove);
const isCheckableChip = (props: any): props is CheckableChip =>
  isFunction(props.toggleChecked) && typeof props.checked === 'boolean';
const isChoiceChip = (props: any): props is ChoiceChip =>
  typeof props.toggleChecked === 'undefined' && typeof props.checked === 'boolean';

const BaseChip: React.FC<Props> = ({
  className,
  children,
  small,
  Left,
  Right,
  variant,
  outline,
  ...props
}) => {
  return (
    <div
      {...props}
      data-chip
      data-variant={variant}
      className={cx(styles.chip, small && styles.small, outline && styles.outline, className)}
    >
      {Left && (
        <div className={cx(styles.side, styles.left)}>{isFunction(Left) ? <Left /> : Left}</div>
      )}
      <div className={cx(styles.center, Left && styles.noLeft, Right && styles.noRight)}>
        {children}
      </div>
      {Right && (
        <div className={cx(styles.side, styles.right)}>{isFunction(Right) ? <Right /> : Right}</div>
      )}
    </div>
  );
};

BaseChip.displayName = 'BaseChip';

type RemoveProps = {
  remove: React.MouseEventHandler<HTMLButtonElement>;
  label?: string;

  small?: boolean;
};

const Remove = React.memo<RemoveProps>(
  ({ remove, small, label = 'Remove chip' }) => (
    <Button
      aria-label={label}
      className={styles.removeButton}
      name="remove"
      small={small}
      variant="svg"
      onClick={remove}
    >
      <Icon aria-hidden size={small ? 10 : 16} type="close" />
    </Button>
  ),
  (x, y) => x.remove === y.remove,
);

Remove.displayName = 'Remove';

export const Chip: React.FC<ChipProps> = props => {
  if (isRemoveableChip(props)) {
    const { onRemove, ...spread } = props;
    return <BaseChip {...spread} Right={<Remove remove={onRemove} small={spread.small} />} />;
  }

  if (isCheckableChip(props)) {
    const { toggleChecked, checked, className, ...spread } = props;

    return (
      <BaseChip
        {...spread}
        aria-checked={checked}
        className={cx(styles.checkable, className)}
        Left={checked && <Icon size={16} type="checkCircle" />}
        role="checkbox"
        onClick={toggleChecked}
      />
    );
  }

  if (isChoiceChip(props)) {
    const { checked, className, ...spread } = props;
    return (
      <BaseChip
        {...spread}
        className={cx(styles.choiceChip, checked && styles.checked, className)}
      />
    );
  }

  return <BaseChip {...props} />;
};

Chip.displayName = 'Chip';

Chip.defaultProps = {
  variant: 'display',
};

export default Chip;
