import * as React from 'react';

import { useToggle } from '@cobbler-io/hooks/src/useToggle';

import { Button } from '@cobbler-io/core-ui/src/Button';
import { Icon } from '@cobbler-io/core-ui/src/Icon';

import {
  useCreateDiscussionMutation, useUsersForBudgetLineQuery,
} from '@cobbler-io/app/src/api/graphql-types';
import { DiscussionInput } from '@cobbler-io/app/src/components/DiscussionInput';
import { Discussion } from '@cobbler-io/app/src/ndm/components/Discussion';
import { useBudgetLineDetails } from '@cobbler-io/app/src/providers';

import styles from './BudgetLineDetailsDiscussions.scss';

const ENTITY_TYPE = 'BUDGET_LINE';
export const BudgetLineDetailsDiscussions = (): JSX.Element | null => {
  const { active: isAdding, activate: startAdding, deactivate: stopAdding } = useToggle(false);
  const { active: isSaving, activate: startSaving, deactivate: stopSaving } = useToggle(false);

  const [createDiscussion] = useCreateDiscussionMutation();
  const { budgetLineId, budgetLineName, discussions, refetch } = useBudgetLineDetails();

  const { data, error } = useUsersForBudgetLineQuery({
    fetchPolicy: 'cache-first',
    skip: !budgetLineId,
    variables: { budgetLineId },
  });
  const budgetLineUsers = error ? null : data?.usersWithReadAccessToBudgetLine;

  if (error) {
    // eslint-disable-next-line no-console
    console.error('Could not fetch budgetLine users', error);
  }

  const handleAddDiscussion = (textContent: string, assignedToUserId: string | null = null) => {
    const userId = assignedToUserId === '@@__unassigned' ? null : assignedToUserId;
    startSaving();
    createDiscussion({
      variables: {
        input: {
          assignedToUserId: userId,
          entityId: budgetLineId,
          entityType: ENTITY_TYPE,
          textContent,
        },
      },
    })
      .then(() => {
        stopAdding();
        stopSaving();
        void refetch();
      })
      .catch(console.error);
  };

  if (!discussions) {
    return null;
  }

  return (
    <div>
      <h2 className="font-title">{budgetLineName || 'Discussions'}</h2>

      {discussions
        .filter(discussion => !discussion.isResolved)
        .map(discussion => (
          <Discussion
            key={discussion.id}
            canResolve
            showStatus
            assignedTo={discussion.assignedTo?.fullName ?? discussion.assignedTo?.email}
            comments={discussion.comments}
            entityType={ENTITY_TYPE}
            id={discussion.id}
            resolved={discussion.isResolved}
            usersWithAccess={budgetLineUsers}
          />
        ))}

      <div className={styles.newDiscussion}>
        {isAdding ? (
          <DiscussionInput
            assignableToUsers={budgetLineUsers}
            disabled={isSaving}
            placeholder="Enter a comment..."
            onCancel={stopAdding}
            onSave={handleAddDiscussion}
          />
        ) : (
          <Button name="new-discussion" type="button" variant="text" onClick={startAdding}>
            <Icon size={16} style={{ marginRight: '1ex' }} type="add" /> Start a new discussion
          </Button>
        )}
      </div>
    </div>
  );
};

BudgetLineDetailsDiscussions.displayName = 'BudgetLineDetailsDiscussions';
