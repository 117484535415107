export const BMP = 'image/bmp' as const;
export const GIF = 'image/gif' as const;
export const JPEG = 'image/jpeg' as const;
export const PNG = 'image/png' as const;
export const WEBP = 'image/webp' as const;

export const IMAGE_MIME_TYPES = { BMP, GIF, JPEG, PNG, WEBP };

export const isImage = (file: File | Blob) =>
  Object.values(IMAGE_MIME_TYPES).some(i => file.type.toLowerCase() === i);

export const imageMimeTypes = [BMP, GIF, JPEG, PNG, WEBP] as const;

export type ImageMimeType = typeof imageMimeTypes[number];
