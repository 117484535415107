import * as React from 'react';

export const useDragAutoScroll = (
  containerRef: React.MutableRefObject<Element> | React.RefObject<Element> | null,
): void => {
  React.useEffect(() => {
    const handleDragOver = (e: DragEvent) => {
      const scrollAreaSize = 100; // We can pass this as a prop, with a better name, of course...
      const parent = containerRef?.current;

      if (!parent) {
        return;
      }

      const rect = parent.getBoundingClientRect();

      const bottom = rect.bottom - e.clientY;
      if (bottom < scrollAreaSize && bottom > 0) {
        parent.scrollTo(0, parent.scrollTop + 1);
        // Scroll "areas" shouldn't collapse, but if that ever happens
        // scrolling down has preference.
        return;
      }

      const top = e.clientY - rect.top;
      if (top < scrollAreaSize && top > 0) {
        parent.scrollTo(0, parent.scrollTop - 1);
      }
    };
    window.addEventListener('dragover', handleDragOver);

    return () => {
      window.removeEventListener('dragover', handleDragOver);
    };
  }, [containerRef]);
};
