import { actions as authActions, reducer as auth } from './Auth';
import { createCounterReducer } from './create-counter-reducer';
import { createToggleReducer } from './create-toggle-reducer';
import { actions as currencyActions, reducer as currency } from './currency';
import { actions as currentBudgetActions, reducer as currentBudget } from './current-budget';
import { actions as currentUserActions, reducer as currentUser } from './current-user';
import { actions as featureFlagActions, reducer as featureFlags } from './feature-flags';
import { actions as graphStatusActions, reducer as graphStatus } from './graph-status';
import { actions as pageActions, reducer as page } from './page';
import { actions as tenantSettingsActions, reducer as tenantSettings } from './tenant-settings';

const { reducer: scrollLock, ...scrollLockActions } = createCounterReducer('@@counter/scroll-lock');

const { reducer: verticalNav, ...verticalNavActions } = createToggleReducer(
  'verticalNav',
  { active: true },
  'isNavOpen',
);

// Needs to be combined elsewhere
export const reducer = {
  '@@counter/scroll-lock': scrollLock,
  auth,
  currency,
  currentBudget,
  currentUser,
  featureFlags,
  graphStatus,
  page,
  tenantSettings,
  verticalNav,
};

export type RootState = {
  '@@counter/scroll-lock': ReturnType<typeof scrollLock>;
  auth: ReturnType<typeof auth>;
  currency: ReturnType<typeof currency>;
  currentBudget: ReturnType<typeof currentBudget>;
  currentUser: ReturnType<typeof currentUser>;
  featureFlags: ReturnType<typeof featureFlags>;
  graphStatus: ReturnType<typeof graphStatus>;
  page: ReturnType<typeof page>;
  verticalNav: ReturnType<typeof verticalNav>;
};

export const actions = {
  auth: authActions,
  currency: currencyActions,
  currentBudget: currentBudgetActions,
  currentUser: currentUserActions,
  featureFlags: featureFlagActions,
  graphStatus: graphStatusActions,
  page: pageActions,
  scrollLock: scrollLockActions,
  tenantSettings: tenantSettingsActions,
  verticalNav: verticalNavActions,
};

export { scrollLock, verticalNav };

export default reducer;
