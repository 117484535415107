import * as React from 'react';

import { head } from '@cobbler-io/utils/src/array';

import { CellProps } from 'react-table';

import { EditorLine } from '../../types';

export const VendorsColumn = ({
  cell: { value: vendors },
}: CellProps<EditorLine, EditorLine['vendors']>): string | null => {
  if (!vendors) {
    return null;
  }

  if (vendors.length === 1) {
    return head(vendors).name;
  }

  if (vendors.length > 1) {
    return [head(vendors).name, `(+${vendors.length - 1} more)`].join(' ');
  }

  return null;
};

VendorsColumn.displayName = 'VendorsColumn';
