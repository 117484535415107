/* eslint-disable functional/immutable-data */
/* eslint-disable no-param-reassign */
import React from 'react';

import { last } from 'ramda';
import { ensurePluginOrder, Hooks, Row, TableInstance, useGetLatest } from 'react-table';

const useInstance = (instance: TableInstance) => {
  const { rows, plugins } = instance;

  ensurePluginOrder(plugins, ['useSortBy', 'useExpanded'], 'useHierarchy');

  const rowsWithHierarchyData = React.useMemo(() => {
    const stack: typeof rows = [];

    const tree = new Map<Row, Row>(); // { [child]: parent }

    const getAncestorsForTree = (id: Row, ancestors: Row[] = []): Row[] => {
      const parent = tree.get(id);
      return parent ? getAncestorsForTree(parent, [parent, ...ancestors]) : ancestors;
    };

    // The rows array is already sorted and flattened. The challenge here
    // is to figure out if a row is first or last relative to its depth.
    rows.forEach((row, index) => {
      row.subRows.forEach(x => {
        tree.set(x, row);
      });

      const ancestors = getAncestorsForTree(row);
      const parent = last(ancestors);
      const prev = index ? rows[index - 1] : null;

      // This variable tells us how many levels we moved from the prev
      // row to the current one.
      const delta = row.depth - (prev?.depth ?? 0);

      row.parentRow = parent ?? null;
      row.ancestorRows = ancestors;

      // The rows array is mutated every time a plugin runs, so first,
      // we have to reset isLast to false and isFirst to false, except
      // when it is the very first item in the rows array. In that case,
      // it is for sure the first one at its depth (isFirst = true).
      row.isLast = false;
      row.isFirst = !index;

      // If delta is more than 0 it means we've moved one level deeper,
      // i.e. we're now pointing at a child row.
      // This means that the current row is the first at its depth.
      // We also add the current row's parent to an array to keep track
      // of the current row's ancestors.
      if (delta > 0) {
        row.isFirst = true;
        stack.push(parent!);
      }

      // If delta is < -1 it means we've moved two or more levels up. This
      // means that the previous row was the last and that all the ancestors
      // of the previous row, up to the level of the current row are also
      // the last at their depths. Array.splice removes said  from the stack
      // and returns them, so we can iterate over the removed items and set
      // isLast to true on them. Lastly we pop the last element from the stack
      // which is a sibling of the current row, to make sure the stack only
      // has ancestors.
      // If delta is === -1, it means we only moved one level up, so there's
      // nothing to set as isLast in between the current depth and the prev
      // depth (splice(0, 0) will do nothing). In this case we just pop the
      // last item in the stack.
      if (delta < 0) {
        prev!.isLast = true;
        stack.splice(delta + 1, -(delta + 1)).forEach(ancestor => {
          ancestor.isLast = true;
        });
        stack.pop();
      }

      // Last, if we're at the very end of the rows array, we know for sure
      // that the current row is the last one (isLast = true). We can also
      // assume that all of its ancestors are the last at their depths, so
      // we set each element in the stack as isLast = true.
      if (index === rows.length - 1) {
        row.isLast = true;
        stack.forEach(ancestor => {
          ancestor.isLast = true;
        });
      }
    });

    return rows;
  }, [rows]);

  const getInstance = useGetLatest(instance);

  Object.assign(instance, {
    rows: rowsWithHierarchyData,
    getInstance,
  });
};

export type UseHierarchyRowProps<D extends UnknownObject> = {
  isFirst: boolean;
  isLast: boolean;
  parentRow: Row<D> | null;
  ancestorRows: Row<D>[];
};

export const useHierarchy = (hooks: Hooks): void => {
  hooks.useInstance.push(useInstance);
};
useHierarchy.pluginName = 'useHierarchy';
