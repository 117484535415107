export const getLocation = (location?: Location) => {
  if (location) {
    return location;
  }

  if (typeof window !== 'undefined') {
    return window.location;
  }

  return null;
};
