/**
 * Clamps a number
 */
export const clamp = (
  { min = -Infinity, max = Infinity }: { min: number; max: number },
  x: number,
): number => Math.max(Math.min(max, x), min);

/**
 * Clamps a number between 0 and Number.MAX_SAFE_INTEGER
 */
export const clamp0 = (x: number): number => clamp({ min: 0, max: Number.MAX_SAFE_INTEGER }, x);

export default clamp;
