import * as React from 'react';

export const useLatest = <T extends any>(latest: T): React.MutableRefObject<T> => {
  const ref = React.useRef<T>(latest);

  if (ref.current !== latest) {
    ref.current = latest;
  }

  return ref;
};
