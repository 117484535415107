import * as React from 'react';

import {
  ActualDiscussionToken, ActualToken, BudgetLineDiscussionToken, BudgetLineToken,
  BudgetRevisionToken, DefaultToken, LocalDateToken, TaskUserToken, UserToken,
} from './tokens';

export type MessageTemplateParameter = {
  displayValue: string;
  type: string;
  resourceId?: string;
  revisionId?: string;
};

export type MessageTemplateAction = {
  label: string;
  uri: string;
};

const tokenRegex = /({{(\d+)}})/g;

const splitTokens = (template: string): string[] => template.replace(tokenRegex, '⎟$1⎟').split('⎟');

const getIndex = (token: string): number => {
  const matches = tokenRegex.exec(token) || [];
  const index = matches[2] ? +matches[2] : -1;
  return index;
};

const supportedTokenTypes = {
  actual: ActualToken,
  actualDiscussion: ActualDiscussionToken,
  budgetLine: BudgetLineToken,
  budgetLineDiscussion: BudgetLineDiscussionToken,
  budgetRevision: BudgetRevisionToken,
  localDate: LocalDateToken,
  taskUser: TaskUserToken,
  user: UserToken,
};

export type MessageTemplate = {
  /**
   * The template message in this format:
   *
   * ```
   * "Hello {{0}}, good {{1}}!"
   * ```
   */
  message: string;
  /**
   * The template parameters have the signature:
   *
   *
   * ```
   * {
   *   displayValue: string;
   *   type: string;
   *   resourceId?: string;
   *   revisionId?: string;
   * }
   * ```
   */
  parameters?: MessageTemplateParameter[] | null;

  /**
   * Optional action with the following signature:
   *
   * ```
   * {
   *   label: string;
   *   uri: string;
   * }
   * ```
   */
  action?: MessageTemplateAction | null;
};

export const ParameterizedMessage = (props: MessageTemplate): JSX.Element => {
  const { message, parameters } = props;
  const tokens = splitTokens(message); // Hello {{1}}

  // This is a workaround while we improve our parameterized messaging system
  // If there's a revision and a budget token present in the message, we can
  // assume that they are related...
  const revisionId = parameters?.find(p => p.type === 'budgetRevision')?.resourceId;

  const renderToken = (token: string, i: number) => {
    const key = getIndex(token);
    const data = parameters ? parameters[key] : null;
    const MappedComponent = supportedTokenTypes[data?.type ?? ''] || DefaultToken;
    return data ? (
      <MappedComponent key={i} {...data} revisionId={revisionId} />
    ) : (
      <React.Fragment key={i}>{token}</React.Fragment>
    );
  };

  return <span className="parsed-message">{tokens.map(renderToken)}</span>;
};

ParameterizedMessage.displayName = 'ParameterizedMessage';

export default ParameterizedMessage;
