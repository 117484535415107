import { createContext } from 'react';

import { ModalLayerContextType } from './types';

export const ModalLayerContext = createContext<ModalLayerContextType>({
  modal: null,
  isTop: false,
  position: {
    absolute: -1,
    relative: -1,
  },
});
