import React, { ComponentType, isValidElement, ReactElement } from 'react';

import { execIfFunc } from '@cobbler-io/utils/src';
import { cx } from '@cobbler-io/utils/src/cx';

import { useToggle } from '@cobbler-io/hooks/src';

import { Icon, IconTypes } from '@cobbler-io/core-ui/src/Icon';

import { useGetLatest } from 'react-table';

import styles from './MenuItem.scss';

type BaseMenuItemProps<T extends unknown = unknown> = {
  id?: string;
  className?: string;
  disabled?: boolean;
  label: string | ComponentType<T> | ReactElement<T>;
  small?: boolean;
};

type RegularMenuItemProps = {
  checked?: never;
  children?: JSX.Element | JSX.Element[];
  iconType?: IconTypes;
  onSelect?: () => void;
  stayOpenAfterSelect?: boolean;
  isToggle?: never;
  toggle?: never;
};

type ToggleMenuItemProps = {
  checked: boolean;
  children?: never;
  iconType?: never;
  onSelect?: (checked: boolean) => void;
  isToggle: true;
  toggle: true;
};

// Maybe add the ability to toggle something here? Or no...
export type MenuItemProps<T extends unknown = unknown> =
  | (BaseMenuItemProps<T> & RegularMenuItemProps)
  | (BaseMenuItemProps<T> & ToggleMenuItemProps);

export const MenuItem = <T extends unknown>(props: MenuItemProps<T>): JSX.Element => {
  const {
    checked = false,
    children,
    className,
    disabled,
    iconType,
    label,
    small = false,
    toggle: isToggle,
    onSelect,
  } = props;

  const hasRightChevron = !isToggle && children;
  const getDisabled = useGetLatest(disabled);

  const { active: selected, toggle } = useToggle(
    checked,
    active => {
      if (!getDisabled()) {
        setTimeout(() => execIfFunc(onSelect, active), 0);
      }
    }
  );
  const defaultIcon = iconType ?? 'blank';
  const toggleIcon = selected ? 'checkbox' : 'checkboxOutline';
  const icon = isToggle ? toggleIcon : defaultIcon;

  const handler = () => {
    // Regular clicks are handled by the parent component Menu, here we only
    // handle toggles because we have some internal state to manage.
    if (getDisabled() || !isToggle) {
      return;
    }

    toggle();
  };

  return (
    <>
      <div className={cx(className, small && styles.small, styles.iconContainer)} onClick={handler}>
        <Icon
          className={cx(styles.svg, styles.icon, small && styles.small)}
          role="presentation"
          size={20}
          type={icon}
          onClick={handler}
        />
      </div>
      <div className={cx(styles.label, small && styles.small, className)} onClick={handler}>
        {label}
      </div>

      {hasRightChevron && (
        <Icon
          className={cx(styles.svg, styles.chevron, small && styles.small)}
          role="presentation"
          size={18}
          type="chevronRight"
        />
      )}
    </>
  );
};

// eslint-disable-next-line functional/immutable-data
MenuItem.isMenuItem = (node: unknown): node is ReactElement<MenuItemProps> =>
  typeof node === 'object' && isValidElement<MenuItemProps>(node) && node.type === MenuItem;

MenuItem.displayName = 'MenuItem';
