import * as React from 'react';

import { isString } from '@cobbler-io/utils/src/isString';

import { ErrorCallout } from '@cobbler-io/core-ui/src/Callout';
import { Card } from '@cobbler-io/core-ui/src/Card';
import { Field } from '@cobbler-io/core-ui/src/Field';
import { Heading } from '@cobbler-io/core-ui/src/Heading';
import { MinimalChrome } from '@cobbler-io/core-ui/src/MinimalChrome';
import { Step, Wizard } from '@cobbler-io/core-ui/src/Wizard';

import { Loading } from '@cobbler-io/app/src/components/Loading';

import loadable from '@loadable/component';
import { required } from '@swan-form/helpers';
import * as qs from 'qs';

import { BasicInformation } from './BasicInformation';
import { SetPassword } from './SetPassword';

import styles from './Finish.scss';

const MANUAL_HELP_URL =
  'https://docs.google.com/forms/d/e/1FAIpQLSfvBR7Z2JA_M0KDTzHEd0BQGv62CumvbzAL_a9NP0URMwaWgw/viewform';

const TOS = loadable(
  async () => import(/* webpackChunkName: "terms-of-service" */ '@cobbler-io/core-ui/src/TOS'),
);

type ContinuationResponse = {
  continuationToken: string;
  passwordComplexity: {
    minimumLength: number;
    minimumLowercase: number;
    minimumUpperCase: number;
    minimumNumeric: number;
    minimumSymbols: number;
  };
  requiresPassword: boolean;
  requiresFullName: boolean;
};

type State<TData> = {
  loading: boolean;
  error: Error | null;
  data: TData | null;
};

type Action<TData> =
  | { type: 'LOADING' }
  | { type: 'ERROR'; error: Error }
  | { type: 'SUCCESS'; data: TData };

const fetchReducer = <TData extends any>(state: State<TData>, action: Action<TData>) => {
  switch (action.type) {
    case 'LOADING':
      return { ...state, loading: true };
    case 'ERROR':
      return { ...state, loading: false, error: action.error };
    case 'SUCCESS':
      return { ...state, loading: false, data: action.data };
    default:
      return state;
  }
};

const setLoading =
  <TData extends any>(dispatch: React.Dispatch<Action<TData>>) =>
  () =>
    dispatch({ type: 'LOADING' });

const setError =
  <TData extends any>(dispatch: React.Dispatch<Action<TData>>) =>
  (error: Error) =>
    dispatch({ type: 'ERROR', error });

const setData =
  <TData extends any>(dispatch: React.Dispatch<Action<TData>>) =>
  (data: TData) =>
    dispatch({ type: 'SUCCESS', data });

const throwTextIfNotOkay = async (res: Response) => {
  if (!res.ok) {
    // Sometimes the errors are in plain text, and sometimes, they're in json
    if ((res.headers.get('Content-Type') || '').startsWith('text/')) {
      // throw something here
      const message = await res.text();
      throw new Error(message);
    } else {
      // mixing async/await and regular promises is icky
      const { message } = await res.json();
      throw new Error(message);
    }
  }

  return res.json();
};

// This is poorly named
const useFetchReducer = <TData extends any>(url: string | null) => {
  const [state, dispatch] = React.useReducer<React.Reducer<State<TData>, Action<TData>>>(
    fetchReducer,
    { loading: false, error: null, data: null },
  );

  const actions = React.useMemo(
    () => ({
      setLoading: setLoading(dispatch),
      setError: setError(dispatch),
      setData: setData(dispatch),
    }),
    [dispatch],
  );

  React.useEffect(() => {
    url && actions.setLoading();

    // this should be a get
    if (url) {
      fetch(url).then(throwTextIfNotOkay).then(actions.setData).catch(actions.setError);
    }
  }, [url]);

  return { ...state, ...actions };
};

const GetHelp = () => (
  <p>
    If you need to obtain another registration token or reset your password, please fill out{' '}
    <a href={MANUAL_HELP_URL} rel="noopener noreferrer" target="_blank">
      this form
    </a>
    .
  </p>
);

GetHelp.displayName = 'GetHelp';

/* eslint-disable max-lines-per-function */

type FinishProps = {
  afterSubmit: (...args: any[]) => any;
};

/**
 * Page for a user to finish registration (setting password, fullname, etc...)
 */
export const Finish = (props: FinishProps) => {
  // Grab the activation token from the query string
  const { afterSubmit } = props;
  const { at } = qs.parse(window.location.search.slice(1));
  const { loading, data, error } = useFetchReducer<ContinuationResponse>(
    at ? `/api/registration/finish?at=${at}` : null,
  );
  const [submitting, setSubmitting] = React.useState<boolean>(false);
  const { requiresFullName = true, requiresPassword = true } = data || {};
  const [formError, setFormError] = React.useState<null | string>(null);

  const [imageFile, setImageFile] = React.useState<File | null>(null);
  const imageUrlRef = React.useRef<string | null>(null);

  /**
   * Creates an object URL for the picture so that we can display it (if we have one) and
   * calls the setState to save the image file in memory. We need to do these in the same render
   * loop, otherwise we might not be able to display the picture
   *
   * @param file A picture
   */
  const createImages = (file: File) => {
    if (file) {
      imageUrlRef.current = URL.createObjectURL(file);
    }
    setImageFile(_ => file);
  };

  React.useEffect(() => {
    return () => {
      isString(imageUrlRef.current) && URL.revokeObjectURL(imageUrlRef.current);
    };
  }, [imageUrlRef]);

  const onSubmit = async (fieldValues: any) => {
    const { password, preferredName, fullName } = fieldValues;
    const activationToken = at;
    const continuationToken = data?.continuationToken;
    setSubmitting(true);
    /* eslint-disable prefer-object-spread */
    const body = requiresFullName
      ? Object.assign(
          {
            activationToken,
            continuationToken,
            password,
            fullName,
          },
          preferredName && { preferredName },
        )
      : { activationToken, continuationToken, password };
    /* eslint-enable prefer-object-spread */

    return fetch('/api/registration/finish', {
      method: 'POST',
      body: JSON.stringify(body),
      credentials: 'same-origin',
      cache: 'no-cache',
      mode: 'cors',
      headers: new Headers([['Content-Type', 'application/json']]),
    })
      .then(throwTextIfNotOkay)
      .then(extraQueryParams => {
        // On success, the backend will send { sessionToken: "some session token" }, which
        // we get from Okta, but we can pass to okta for the normal signin.
        // @see https://developer.okta.com/docs/reference/api/oidc/
        // The `afterSubmit` should be a SignInRedirect request from the oidc provider, so
        // we theoretically can pipe the extraQueryParams directly into the request.
        // This should work on production, but it will always fail locally with our fake
        // identity server.
        afterSubmit({ extraQueryParams }, imageFile);
        // So, this should unmount itself.
      })
      .catch(err => {
        // If there is an error (e.g. weak password), then it would throw it.
        if (err instanceof Error || (typeof err === 'object' && 'message' in err)) {
          setFormError(err.message);
        } else if (typeof err === 'string') {
          setFormError(err);
        } else {
          // Fallback which will probbably be very unhelpful
          setFormError(JSON.stringify(err));
        }

        setSubmitting(false);
      });
  };

  if (loading || submitting) {
    return (
      <MinimalChrome className={styles.backgroundSplash}>
        <Card className={styles.container}>
          <Loading />
        </Card>
      </MinimalChrome>
    );
  }

  if (error instanceof Error) {
    //  Some error messages:
    //  NO_ERROR_CODE / 400 "The user is not pending activation."
    //      This happens if the user has been activated. It can also happen if the user comes to the
    //      URL with the activation token, and then presses refresh (leading to user purgatory).
    //
    //      Solution: point user to google form:
    // https://docs.google.com/forms/d/e/1FAIpQLSfvBR7Z2JA_M0KDTzHEd0BQGv62CumvbzAL_a9NP0URMwaWgw/viewform

    //  NO_ERROR_CODE / 400 "The activation token is not valid."
    //      Happens when an activation token is, well, not valid (e.g. we don't have a record of it).
    //      The user likely entered garbage or there might have been an encoding problem with the email
    //
    //      Solution: point user to google form:
    // https://docs.google.com/forms/d/e/1FAIpQLSfvBR7Z2JA_M0KDTzHEd0BQGv62CumvbzAL_a9NP0URMwaWgw/viewform

    return (
      <MinimalChrome className={styles.backgroundSplash}>
        <ErrorCallout className={styles.wideErrorCallout}>{error.message}</ErrorCallout>
        <Card className={styles.container}>
          <p>Please click on the finish registration link from your email.</p>
          <GetHelp />
        </Card>
      </MinimalChrome>
    );
  }

  if (!at) {
    // there is no valid at token
    return (
      <MinimalChrome className={styles.backgroundSplash}>
        {/* Add a better error message for this */}
        <ErrorCallout className={styles.wideErrorCallout}>No activation token found.</ErrorCallout>

        <Card className={styles.container}>
          <p>Please click on the finish registration link from your email.</p>
          <GetHelp />
        </Card>
      </MinimalChrome>
    );
  }

  return (
    <MinimalChrome className={styles.backgroundSplash}>
      {formError && <ErrorCallout className={styles.wideErrorCallout}>{formError}</ErrorCallout>}
      <Card className={styles.container}>
        <Wizard className={styles.wizard} onSubmit={onSubmit}>
          <Step>
            <Heading>Welcome to Cobbler!</Heading>
            <p>Please read through and sign our Terms of Service.</p>
            <div className={styles.legalText}>
              <React.Suspense fallback={<div>Loading...</div>}>
                <TOS />
              </React.Suspense>
            </div>
            <Field
              validateOnChange
              defaultChecked={false}
              label="I agree to the Terms of Service"
              name="agree"
              type="checkbox"
              validate={required}
            />
          </Step>
          {requiresFullName && (
            <Step className={styles.fullSlide}>
              <BasicInformation imageUrl={imageUrlRef.current} setImageFile={createImages} />
            </Step>
          )}
          {requiresPassword && (
            <Step>
              <Heading>Password</Heading>
              <SetPassword />
            </Step>
          )}
        </Wizard>
      </Card>
    </MinimalChrome>
  );
};

Finish.displayName = 'Finish';

export default Finish;
