/* eslint-disable react/prop-types */
import * as React from 'react';

import { cx } from '@cobbler-io/utils/src/cx';

import { useDefaultOrRandom } from '@cobbler-io/hooks/src/useDefaultOrRandom';

import { animated, useSpring } from '@react-spring/web';

import { Button } from '../Button';
import { Icon } from '../Icon';
import { Modal, useModal } from '../Modal';
import { DropdownList } from './DropdownList';
import { Item } from './types';

import styles from './DropdownButton.scss';

export type DropdownButtonProps = {
  // Add props here
  label: string;
  options: Item[];
  onSelect: (item: Item | null) => void;

  /**
   * Whether or not to display the search box
   *
   * default: `true`
   */
  search?: boolean;

  /**
   * Whether or not to use a small button
   */
  small?: boolean;

  /**
   * Whether or not the button is disabled
   */
  disabled?: boolean;

  variant?: React.ComponentProps<typeof Button>['variant'];

  showIcon?: boolean;

  className?: string;
} & Omit<React.HTMLProps<HTMLDivElement>, 'onSelect'>;

export const DropdownButton = React.forwardRef<HTMLButtonElement, DropdownButtonProps>(
  (
    {
      children,
      className,
      label,
      name,
      onSelect,
      options,
      small = false,
      disabled = false,
      search = true,
      showIcon = true,
      variant = 'contain',
      style,
    },
    ref,
  ) => {
    const active = false; // TODO hook up the icon moving again
    const { create } = useModal();
    const { opacity, transform } = useSpring({
      delay: 200,
      from: { opacity: 0, transform: 'translateY(-30)' },
      reset: false,
      to: { opacity: 1, transform: 'translateY(0)' },
    });

    const internalName = useDefaultOrRandom(name);

    const onClick = React.useCallback(
      ({ currentTarget }: React.MouseEvent<HTMLButtonElement>) => {
        const { top, left, height } = currentTarget.getBoundingClientRect();

        create(
          <Modal bare lock overlay="transparent">
            {({ close }, { className: internalClassName }) => (
              <animated.div
                key={Math.random().toString(36)}
                className={cx(styles.dropdownList, internalClassName)}
                style={{
                  left,
                  opacity,
                  top: top + height + 8,
                  transform,
                  width: 240,
                }}
              >
                <DropdownList
                  close={close}
                  options={options}
                  search={search}
                  style={{ left, top: top + height + 8, width: 240 }}
                  onSelect={onSelect}
                />
              </animated.div>
            )}
          </Modal>,
        );
      },
      [onSelect, options, create],
    );

    return (
      <Button
        ref={ref}
        aria-label={label}
        className={cx(styles.dropdownButton, className)}
        disabled={disabled}
        name={internalName}
        small={small}
        style={style}
        variant={variant}
        onClick={onClick}
      >
        {children ?? null}
        {showIcon && <Icon type={active ? 'up' : 'down'} />}
      </Button>
    );
  },
);

DropdownButton.displayName = 'DropdownButton';

export default DropdownButton;
