import * as React from 'react';

import { isNullish } from '@cobbler-io/utils/src/isNullish';

import styles from './ActualProperty.scss';

export const ActualProperty = ({
  label,
  value,
}: {
  label: string;
  value?: string | null | false;
}): JSX.Element => {
  if (isNullish(value) || value === false) {
    // @ts-expect-error: this is fine for react
    return null;
  }

  return (
    <div className={styles.group}>
      <div className={styles.label}>{label}</div>
      <div className={styles.value}>{value}</div>
    </div>
  );
};

ActualProperty.displayName = 'ActualProperty';
