import * as React from 'react';

import { cx } from '@cobbler-io/utils/src/cx';

import styles from './NotificationBadge.scss';

type NotificationBadgeProps = {
  // Add props here
  children?: React.ReactNode;
  count: number;
  className?: string;
  small?: boolean;
  shadow?: boolean;
};

const MAX_COUNT = 99;

export const NotificationBadge = (props: NotificationBadgeProps): JSX.Element => {
  const { count, className, shadow = false, small = false, children } = props;
  if (count === 0) {
    // @ts-expect-error: if there is no children, we want to render a null element
    return children ?? null;
  }

  return (
    <div className={cx(children && styles.hasChildren, className)}>
      <div
        className={cx(
          styles.notificationBadge,
          shadow && styles.shadow,
          small && styles.small,
          className,
        )}
      >
        {count > MAX_COUNT ? '99+' : count}
      </div>
      {children}
    </div>
  );
};

NotificationBadge.displayName = 'NotificationBadge';

export default NotificationBadge;
