import * as React from 'react';

import { arraysAreEqual } from '@cobbler-io/utils/src/array/arraysAreEqual';

export const usePrevious = <T>(currentValue: T): T | null => {
  const history = React.useRef<{ current: T; previous: T | null }>({
    current: currentValue,
    previous: null,
  });

  if (Array.isArray(history.current.current) && Array.isArray(currentValue)) {
    if (!arraysAreEqual(history.current.current, currentValue)) {
      history.current.previous = history.current.current;
      history.current.current = currentValue;
    }
  } else if (history.current.current !== currentValue) {
    history.current.previous = history.current.current;
    history.current.current = currentValue;
  }

  return history.current.previous;
};
