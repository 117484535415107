import * as React from 'react';
import { createPortal } from 'react-dom';

import { execIfFunc } from '@cobbler-io/utils/src/execIfFunc';

export type PortalProps = {
  children: any;

  /**
   * The host node, or an ID of a host node
   */
  host?: HTMLElement | string;

  /**
   * Whether or not to mount with children
   */
  mountWithChildren?: boolean;

  /**
   * Creates a `div` wrapper that the portal is mounted into (inside the `host`)
   */
  createWrapper?: boolean;

  onMount?: (...args: any[]) => any;
  onUnmount?: (...args: any[]) => any;
}

export type PortalState = {
  isMounted: boolean;
  mountWithChildren?: boolean;
}

const getHostNode = (element: HTMLElement | string = 'portal') => {
  // If we were passed an element, then we'll use that
  if (element instanceof HTMLElement) {
    return element;
  }

  // Try to query it
  const el = document.getElementById(element);
  if (el) {
    return el;
  }

  // Try to query it with a static name
  const fallback = document.getElementById('root');
  if (fallback) {
    return fallback;
  }

  // If we have not found one, then we'll use the body
  return document.body;
};

/**
 * Renders a Portal
 */
export class Portal extends React.Component<PortalProps, PortalState> {
  static displayName = 'Portal';

  static defaultProps = {
    mountWithChildren: true,
  };

  entrance: HTMLDivElement;

  hostNode: HTMLElement | null;

  constructor(props: PortalProps) {
    super(props);

    this.state = {
      isMounted: false,
      mountWithChildren: props.mountWithChildren,
    };

    this.hostNode = getHostNode(props.host);

    if (this.hostNode) {
      if (this.hostNode.getAttribute('data-portal') !== 'true') {
        this.hostNode.setAttribute('data-portal', 'true');
      }
    }

    if (props.createWrapper) {
      this.entrance = document.createElement('div');
    }
  }

  componentDidMount() {
    const { createWrapper, onMount } = this.props;

    if (this.hostNode && createWrapper) {
      this.hostNode.appendChild(this.entrance);
    }

    this.setState({ isMounted: true });
    execIfFunc(onMount);
  }

  componentWillUnmount() {
    const { createWrapper, onUnmount } = this.props;

    if (this.hostNode && createWrapper) {
      // We might have to toy around with this and make sure everything unmounts as needed
      this.hostNode.removeChild(this.entrance);
    }

    execIfFunc(onUnmount);
  }

  render() {
    const { createWrapper, children } = this.props;

    if (!this.hostNode) {
      return null;
    }

    const mountPoint = createWrapper ? this.entrance : this.hostNode;

    const { isMounted, mountWithChildren } = this.state;

    if (mountWithChildren) {
      if (!isMounted) {
        return createPortal(null, mountPoint);
      }
    }

    return createPortal(children, mountPoint);
  }
}

export default Portal;
