/**
 * TODO add back in keyboard handling for accessibility
 * TODO ensure that nested router components get correct focus
 * TODO add overflow / scrolling behavior
 * TODO fix id shared between tab header and tab panel
 */
import * as React from 'react';

import { head } from '@cobbler-io/utils/src/array/head';
import { prop } from '@cobbler-io/utils/src/prop';

import { Load } from '@cobbler-io/app/src/components/Load';

import { Router, useMatch } from '@reach/router';
import { isNil, reject } from 'ramda';

import { TabRouterHeader } from './TabRouterHeader';
import { TabRouterList } from './TabRouterList';
import { TabRouterPanel } from './TabRouterPanel';

type ReactElementWithPath = React.ReactElement<
  {
    path: string;
    label: string;
    fallback?: React.ComponentType;
    id: string;
    children: React.ReactNode;
    Component?: React.ComponentType<any>;
    disabled?: boolean;
  },
  any
>;

type TabProps = {
  path: string;
  label: string | React.ComponentType<any> | React.ReactElement;
  id: string;
  disabled?: boolean;
} & XOR<
  { children: ReactElementWithPath; Component?: never },
  { Component: React.ComponentType<any>; children?: never }
>;

type TabComponent = (props: TabProps) => any;
export const Tab: TabComponent = () => null;

const replaceTokens = (values: Record<string, string>) => (link: string) =>
  Object.entries(values).reduce(
    (prepared, [key, value]) =>
      prepared.includes(`:${key}`) ? prepared.replace(`:${key}`, value) : prepared,
    link,
  );

// remove query params from the path
const removeQueryParams = (path: string) => path.split('?')[0];

const usePossibleParams = (children: ReactElementWithPath[]) => {
  return children.reduce((acc: Record<string, string>, child: ReactElementWithPath) => {
    const matches = useMatch(removeQueryParams(child.props.path));

    if (matches) {
      const { path, uri, ...rest } = matches;
      return { ...acc, ...rest, path: acc.path || path };
    }

    return acc;
  }, {});
};

/* eslint-disable max-params */
const prepareTabs = (
  children: ReactElementWithPath[],
  params: Record<string, string>,
  activePath: string,
  tabRefs: React.MutableRefObject<Map<string, React.RefObject<HTMLAnchorElement>>>,
) => {
  const prepareLink = replaceTokens(params);

  return children.map(child => {
    const { path, label, Component, id, disabled } = child.props;
    const link = prepareLink(path).replace('/*', '');
    // reuse the existing ref that we have or create another one
    const ref = tabRefs.current.get(link) || React.createRef<HTMLAnchorElement>();

    // Product decision:
    //    if there are tokens left in the link after we've prepared it, then we'll filter it out to
    //    avoid bad URLs. This also means that we need to not put anything that needs multiple
    //    tokens if it always needs to appear.
    const regex = /^:[a-zA-Z]{1,}/u;
    if (link.split('/').reduce((acc, part) => acc || regex.test(part), false)) {
      return null;
    }
    // remove the query params from the path to match the active path if it exists
    const active = removeQueryParams(path) === activePath;
    return { Component, active, disabled, id, label, link, path, ref };
  });
};
/* eslint-enable max-params */

const mapRoute = (child: ReactElementWithPath) => {
  const {
    path,
    label,
    fallback,
    disabled,
    children: routeChildren,
    Component = routeChildren,
    ...rest
  } = child.props;

  return (
    <Load
      key={path}
      Component={Component}
      fallback={fallback}
      path={removeQueryParams(path)}
      {...rest}
    />
  );
};

const mapTabLabel = (tab: ReturnType<typeof prepareTabs>[number]) => {
  if (!tab) {
    return null;
  }
  const { label, link, path, Component, id, active, ref, disabled } = tab;

  return (
    <TabRouterHeader
      key={path}
      ref={ref}
      active={active}
      Component={Component}
      disabled={disabled}
      id={id}
      link={link}
    >
      {label}
    </TabRouterHeader>
  );
};

export type TabRouterProps = {
  basepath?: string;
  children: (ReactElementWithPath | boolean)[] | ReactElementWithPath;
};

export const TabRouter = (props: TabRouterProps): JSX.Element => {
  const { children, ...restProps } = props;
  const tabRefs = React.useRef<Map<string, React.RefObject<HTMLAnchorElement>>>(new Map());
  const arrayChildren = React.Children.toArray(children).filter(Boolean) as ReactElementWithPath[];
  const { path: activePath, ...params } = usePossibleParams(arrayChildren);
  const tabs = reject(isNil, prepareTabs(arrayChildren, params, activePath, tabRefs));
  const activeTab = head(tabs.filter(prop('active')));

  return (
    <div>
      <TabRouterList>{tabs.map(mapTabLabel)}</TabRouterList>

      <TabRouterPanel id={activeTab?.id ?? 'none'}>
        <Router {...restProps}>{arrayChildren.map(mapRoute)}</Router>
      </TabRouterPanel>
    </div>
  );
};

TabRouter.displayName = 'TabRouter';

export default TabRouter;
