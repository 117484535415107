import { useCurrencyFormatter } from '@cobbler-io/redux/src/modules/currency';

import { CellProps } from 'react-table';

/**
 * Displays numbers so that when
 */
export const DisplayFinancialRounded = ({ cell: { value } }: CellProps<any>): string => {
  const { formatFinancialRounded } = useCurrencyFormatter();
  return typeof value === 'number' ? formatFinancialRounded(value, false) : '';
};
