import * as React from 'react';

import { MinimalChrome } from '@cobbler-io/core-ui/src/MinimalChrome';

import { useCurrentUser, useOnboardingValues } from '@cobbler-io/redux/src/modules/current-user';

import { TOSGate } from './TOSGate';
import { UsernameGate } from './UsernameGate';

import styles from './OnboardingGate.scss';

export const OnboardingGate: React.FC<{ children: React.ReactElement }> = ({ children }) => {
  const user = useCurrentUser();
  const { acceptedTermsOfService } = useOnboardingValues();

  // Check if the user is logged in and has accepted the TOS
  if (user.id) {
    if (!acceptedTermsOfService) {
      return (
        <MinimalChrome className={styles.backgroundSplash}>
          <TOSGate />
        </MinimalChrome>
      );
    }

    if (!user.fullName) {
      return (
        <MinimalChrome className={styles.backgroundSplash}>
          <UsernameGate />
        </MinimalChrome>
      );
    }
  }

  return React.Children.only(children);
};

OnboardingGate.displayName = 'OnboardingGate';

export default OnboardingGate;
