import * as React from 'react';

import { createNamedContext } from '@cobbler-io/utils/src/createNamedContext';
import { noop } from '@cobbler-io/utils/src/noop';
import { pick } from '@cobbler-io/utils/src/pick';

import { usePermission } from '@cobbler-io/hooks/src/usePermission';

import {
  initialState, reducer, useWebcamActions, WebcamActions, WebcamState,
} from './webcamReducer';

export type WebcamContextType = {
  state: WebcamState;
  actions: WebcamActions;
  refs: {
    video: React.RefObject<HTMLVideoElement>;
    stream: React.MutableRefObject<MediaStream | null>;
    canvas: React.RefObject<HTMLCanvasElement>;
  };
  stream: {
    start: (constraints?: MediaStreamConstraints) => void;
    stop: () => void;
    load: (stream: MediaStream) => void;
  };
  cameraPermission: 'denied' | 'prompt' | 'granted' | 'unknown';
};

export const WebcamContext = createNamedContext<WebcamContextType>('Webcam', {
  state: initialState,
  actions: {
    canPlay: noop,
    cannotPlay: noop,
    loaded: noop,
    unloaded: noop,
    streamError: noop,
    streamLoaded: noop,
    selectDevice: noop,
    setDimensions: noop,
    setCapture: noop,
    removeCapture: noop,
  },
  refs: {
    canvas: React.createRef<HTMLCanvasElement>(),
    stream: React.createRef<MediaStream | null>(),
    video: React.createRef<HTMLVideoElement>(),
  },
  stream: {
    load: noop,
    start: noop,
    stop: noop,
  },
  cameraPermission: 'prompt',
});

type WebcamProviderProps = {
  children: React.ReactNode;
  height: number;
  mimeType?: 'image/png' | 'image/jpeg' | 'image/webp';
  onAcceptPicture: (picture: Blob) => any;
  quality?: number;
  width: number;
};

const INITIAL_VIDEO_CONSTRAINTS: MediaStreamConstraints = {
  // Select a camera with a good frame rate, preferably facing the user
  video: { frameRate: { ideal: 60 }, facingMode: 'user' },
  audio: false,
};

const getDimensions = (settings: MediaTrackSettings) =>
  pick(['width', 'height', 'aspectRatio'], settings);

export const WebcamProvider = (props: WebcamProviderProps): JSX.Element => {
  const { children, ...rest } = props;
  const cameraPermission = usePermission('camera');
  const [state, dispatch] = React.useReducer(reducer, { ...initialState, ...rest });
  const actions = useWebcamActions(dispatch);

  const refs = {
    canvas: React.useRef<HTMLCanvasElement>(null),
    stream: React.useRef<MediaStream | null>(null),
    video: React.useRef<HTMLVideoElement>(null),
  };

  const stream = {
    load: (s: MediaStream) => {
      if (!(refs.video.current instanceof HTMLVideoElement)) {
        // Or throw an error
        return;
      }
      refs.video.current.srcObject = s;
      refs.stream.current = s;
      refs.video.current.addEventListener('load', refs.video.current.play);
      actions.streamLoaded(
        s.getTracks()[0].getSettings().deviceId ?? null,
        getDimensions(s.getVideoTracks()[0].getSettings()),
      );
    },
    stop: () => {
      refs.stream.current?.getTracks().forEach(track => track.stop());
      if (refs.video.current) {
        refs.video.current.srcObject = null;
      }
      refs.stream.current = null;
    },
    start: (constraints: MediaStreamConstraints = INITIAL_VIDEO_CONSTRAINTS) => {
      stream.stop();
      navigator.mediaDevices.getUserMedia(constraints).then(stream.load).catch(actions.streamError);
    },
  };

  const onDeviceChange = React.useCallback(() => {
    navigator.mediaDevices.enumerateDevices().then(devices => {
      if (!devices.some(device => device.deviceId === state.device)) {
        stream.stop();
        actions.selectDevice(null);
        setTimeout(stream.start, 0);
      }
    });
  }, [state.device]);

  React.useEffect(() => {
    if (!('mediaDevices' in navigator) || typeof navigator.mediaDevices === 'undefined') {
      // Whelp, this is not a browser, and something is going terribly wrong, so we'll throw up
      // an error display message instead. There is no recovering from this.
      console.error('NO MEDIA DEVICES');
      return () => void 0;
    }

    navigator.mediaDevices.addEventListener('devicechange', onDeviceChange);

    return () => navigator.mediaDevices.removeEventListener('devicechange', onDeviceChange);
  }, []);

  // Stop any active video streams that are currently playing when we unMount
  React.useEffect(() => stream.stop, []);

  React.useEffect(() => {
    switch (cameraPermission) {
      case 'granted':
      case 'UNSUPPORTED':
      case 'unknown':
        // Automatically start the video stream if we already have permission to use the device
        stream.start();
        break;
      case 'denied':
      case 'prompt':
      default:
      // This is a noop
    }
  }, [cameraPermission]);

  const value = { actions, refs, state, stream, cameraPermission };

  return <WebcamContext.Provider value={value}>{children}</WebcamContext.Provider>;
};

WebcamProvider.displayName = 'WebcamProvider';
