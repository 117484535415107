export const trimLeadingZeroes = (arr: number[]): number[] => {
  for (let i = 0; i < arr.length; i++) {
    if (arr[i]) {
      return arr.slice(i);
    }
  }
  return [];
};

export const trimTrailingZeroes = (arr: number[]): number[] => {
  for (let i = arr.length; i > 0; i--) {
    if (arr[i - 1]) {
      return arr.slice(0, i);
    }
  }
  return [];
};

export const trimZeroes = (arr: number[]): number[] => {
  return trimTrailingZeroes(trimLeadingZeroes(arr));
};
