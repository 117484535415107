import * as React from 'react';

export type Middleware<S, A> = {
  (action: A, state: S): any;
};

export type Afterware<S, A> = {
  (action: A, prevState: S, nextState: S): any;
};

type ExtendedReducerParams<S, A> = {
  reducer: React.Reducer<S, A>;
  initialState: React.ReducerState<React.Reducer<S, A>>;
  middleware?: Middleware<S, A> | Middleware<S, A>[];
  afterware?: Afterware<S, A> | Afterware<S, A>[];
};

/**
 * React's useReducer but with middleware and afterware
 */
export const useExtendedReducer = <S, A>(params: ExtendedReducerParams<S, A>) => {
  const { reducer, initialState, middleware, afterware } = params;
  const [state, dispatch] = React.useReducer<React.Reducer<S, A>>(reducer, initialState);
  const currentState = React.useRef<S>(state);
  const prevState = React.useRef<S>(state);
  const lastAction = React.useRef<A | null>(null);
  currentState.current = state;

  const dispatchWithMiddleWare: React.Dispatch<A> = (action: A) => {
    lastAction.current = action;
    middleware && ([] as Middleware<S, A>[]).concat(middleware).forEach(m => m(action, state));
    dispatch(action);
  };

  React.useEffect(() => {
    if (afterware && lastAction.current) {
      const [a, p, c] = [lastAction.current, prevState.current, currentState.current];
      afterware && ([] as Afterware<S, A>[]).concat(afterware).forEach(m => m(a, p, c));
    }

    prevState.current = currentState.current;
  }, [state, afterware]);

  return {
    getState: () => currentState.current,
    dispatch: dispatchWithMiddleWare,
  };
};
