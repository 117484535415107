import * as React from 'react';

import { MAX_SAFE_DATE, MIN_SAFE_DATE } from '@cobbler-io/dates/src';
import { addMonths } from '@cobbler-io/dates/src/addMonths';
import { addYears } from '@cobbler-io/dates/src/addYears';
import { getEndOfMonth } from '@cobbler-io/dates/src/getEndOfMonth';
import { getEndOfYear } from '@cobbler-io/dates/src/getEndOfYear';
import { getStartOfMonth } from '@cobbler-io/dates/src/getStartOfMonth';
import { getStartOfYear } from '@cobbler-io/dates/src/getStartOfYear';
import { tzAdjust } from '@cobbler-io/dates/src/tzAdjust';

import {
  CancelButton, DatePickerField, DatePickerProvider, DateRangeTuple, SaveButton,
} from '@cobbler-io/core-ui/src/DatePickerProvider';

import { MonthCalendar } from './MonthCalendar';
import { dateFromYYYYMM, dateToYYYYMM } from './utils';

import styles from './MonthPicker.scss';

const incrementValue = (date: Date): Date => addMonths(date, 1);
const decrementValue = (date: Date): Date => addMonths(date, -1);
const incrementPage = (date: Date): Date => getStartOfYear(addYears(date, 1));
const decrementPage = (date: Date): Date => getEndOfYear(addYears(date, -1));

const staticProps = {
  incrementValue,
  decrementValue,
  incrementPage,
  decrementPage,
};

export type MonthPickerProps = {
  /**
   * Whether or not to select a range
   */
  range?: boolean;

  /**
   * The defaultValue of the date picker
   */
  defaultValue?: DateRangeTuple;

  /**
   * Called when a good value is selected
   */
  onChange: (value: DateRangeTuple) => void;

  /**
   * The year to default the view to
   */
  viewYear?: number;

  /**
   * Earliest month that can be chosen. Format: `YYYY-MM`.
   */
  min?: string;

  /**
   * Latest month that can be chosen. Format: `YYYY-MM`.
   */
  max?: string;

  /**
   * Dates to decorate
   */
  decorate?: Set<ISO8601String>;

  /**
   * Wether to display two years side by side
   */
  double?: boolean;

  defaultDisplayYear?: string | number;
};

const getDisplayDate = (year: string | number): Date => {
  const d = typeof year === 'string' ? parseInt(year, 10) : year;
  const date = new Date();
  date.setMonth(5);
  date.setFullYear(d);
  return date;
};

const DEFAULT_DECORATE: Set<ISO8601String> = new Set();

export const MonthPicker = (props: MonthPickerProps): JSX.Element => {
  const {
    min,
    max,
    defaultValue,
    onChange,
    decorate = DEFAULT_DECORATE,
    defaultDisplayYear = new Date().getFullYear(),
    double = true,
    range = false,
  } = props;
  const [defaultStart = null, defaultEnd = null] = defaultValue ?? [];

  const minDate = min ? getStartOfMonth(dateFromYYYYMM(min)) : new Date(MIN_SAFE_DATE);
  const maxDate = max ? getEndOfMonth(dateFromYYYYMM(max)) : new Date(MAX_SAFE_DATE);

  const providerProps = {
    ...staticProps,
    decorate,
    min: minDate,
    max: maxDate,
    viewDate: getDisplayDate(defaultDisplayYear),
    defaultStart: defaultStart ? tzAdjust(dateFromYYYYMM(defaultStart)) : null,
    defaultEnd: defaultEnd ? getEndOfMonth(tzAdjust(dateFromYYYYMM(defaultEnd))) : null,
    range,
    onChange,
    formatDate: dateToYYYYMM,
  };

  return (
    <DatePickerProvider {...providerProps}>
      <div className={styles.container}>
        {range && (
          <div className={styles.fields}>
            <DatePickerField type="START" />
            <DatePickerField className={styles.pickerEndField} type="END" />
          </div>
        )}
        <div className={styles.calendars}>
          <MonthCalendar first isDouble={double} />
          {double && <MonthCalendar first={false} isDouble={double} />}
        </div>
        <div className="button-container right">
          <CancelButton />
          <SaveButton />
        </div>
      </div>
    </DatePickerProvider>
  );
};

MonthPicker.displayName = 'MonthPicker';

export default MonthPicker;
