/* eslint-disable no-bitwise */
import * as React from 'react';

import { cx } from '@cobbler-io/utils/src/cx';
import { lower } from '@cobbler-io/utils/src/string/lower';
import { upperFirst } from '@cobbler-io/utils/src/string/upperFirst';

import { Button } from '@cobbler-io/core-ui/src/Button';
import { Icon } from '@cobbler-io/core-ui/src/Icon';
import { Menu, useFlyoutMenu } from '@cobbler-io/core-ui/src/Menu';
import { Modal, useModal } from '@cobbler-io/core-ui/src/Modal';
import { MonthPicker } from '@cobbler-io/core-ui/src/MonthPicker';

import { useFiscalYearStart } from '@cobbler-io/redux/src/modules/tenant-settings';

import { getDateRange } from '@cobbler-io/app/src/utils/getDateRange';
import { toMonthToken } from '@cobbler-io/app/src/utils/toMonthToken';
import { useNamedDateRange } from '@cobbler-io/app/src/utils/useNamedDateRange';

import { useCurrentRange } from './useCurrentRange';

import styles from './RangeMenu.scss';

type RangeMenuProps = {
  className?: string;
};

/**
 * Converts a month token `12-2020` to a local JS Date
 */
export const monthToDate = (date: string, end: boolean = false): LocalDate => {
  const [year, month] = date.split('-').map(Number);
  return end ? new Date(year, month, 1, 0, 0, 0, -1) : new Date(year, month - 1, 1, 0, 0, 0, 0);
};

type GetDisplayNameParams = {
  name: string;
  start: LocalDate;
  end: LocalDate;
};

const shortMonthYearFormatter = new Intl.DateTimeFormat(undefined, {
  month: 'short',
  year: 'numeric',
}).format;
const getDisplayName = (params: GetDisplayNameParams): string => {
  const { name, start, end } = params;
  return lower(name) === 'custom'
    ? [start, end].map(shortMonthYearFormatter).join('-')
    : upperFirst(name);
};

export const RangeMenu = (props: RangeMenuProps): JSX.Element => {
  const { className } = props;
  const { create: createModal } = useModal();
  const buttonRef = React.useRef<HTMLButtonElement>(null);
  const fysm = useFiscalYearStart();
  const { min, max, start, end, name, setParams } = useCurrentRange();

  const { dateRangeNames } = useNamedDateRange({
    maxResolution: 'MONTH', // TODO fix this
    min,
    max,
    fysm: fysm + 1,
  });

  const rangeMenu = React.useMemo(
    () => (
      <Menu small>
        {dateRangeNames.map(rangeName => (
          <Menu.Item
            key={rangeName}
            label={upperFirst(rangeName)}
            onSelect={() => {
              if (rangeName !== 'custom') {
                const dRange = getDateRange({ name: rangeName, min, max, fysm: fysm + 1 });
                dRange && setParams({ name: rangeName, start: dRange.start, end: dRange.end });
                return void 0;
              }

              const defaultValue = ([start, end] as const).map(toMonthToken) as [string, string];

              // Now we're dealing with custom
              createModal(
                <Modal arrow overlay="transparent">
                  <div className={cx('floating-box', styles.customPicker)}>
                    <MonthPicker
                      range
                      defaultDisplayYear={start.getFullYear()}
                      defaultValue={defaultValue}
                      max={toMonthToken(max)}
                      min={toMonthToken(min)}
                      onChange={([s, e]) => {
                        const nextStart = monthToDate(s);
                        const nextEnd = monthToDate(e!, true);
                        setParams({ name: 'Custom', start: nextStart, end: nextEnd });
                      }}
                    />
                  </div>
                </Modal>,
                { target: buttonRef.current! },
              );

              return void 0;
            }}
          />
        ))}
      </Menu>
    ),
    [createModal, dateRangeNames, end, fysm, max, min, setParams, start],
  );

  const onRangeClick = useFlyoutMenu(rangeMenu);

  return (
    <Button
      ref={buttonRef}
      small
      className={className}
      label="Choose date range"
      name="choose date range"
      variant="text"
      onClick={onRangeClick}
    >
      <Icon className={styles.buttonIcon} size={16} type="calendar" />
      {['Range', getDisplayName({ name, start, end })].join(': ')}
    </Button>
  );
};

RangeMenu.displayName = 'RangeMenu';
