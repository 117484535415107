import * as React from 'react';

import { useToggle } from '@cobbler-io/hooks/src';

import { UserType } from '@cobbler-io/core-ui/src/UserDisplay';
import { ProfileIconSize, UserInitial } from '@cobbler-io/core-ui/src/UserInitial';
import { UserPicture } from '@cobbler-io/core-ui/src/UserPicture';

export type AvatarProps = {
  /**
   * A user object to display
   */
  user: UserType;
  /**
   * The size of the UserInitial / Profile Picture
   */
  size?: ProfileIconSize;

  className?: string;
};

export const Avatar = (props: AvatarProps): JSX.Element => {
  const { size, user, className } = props;
  const { activate: setMissingImage, active: hasMissingImage } = useToggle(false);

  return user.profileImageUrl && !hasMissingImage ? (
    <UserPicture
      className={className}
      shape="round"
      size={size}
      user={user}
      onError={setMissingImage}
    />
  ) : (
    <UserInitial
      firstOnly
      className={className}
      identifier={user.id}
      name={user.fullName ?? user.email}
      size={size}
    />
  );
};

Avatar.displayName = 'Avatar';

export default Avatar;
