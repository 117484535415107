import * as React from 'react';

import { Link } from '@reach/router';

import { MessageTemplateParameter } from '../ParameterizedMessage';

export const ActualToken = ({ resourceId, displayValue }: MessageTemplateParameter): JSX.Element =>
  !resourceId ? (
    <>{displayValue}</>
  ) : (
    <Link to={`/actuals/${resourceId}/details`}>{displayValue}</Link>
  );

ActualToken.displayName = 'ActualToken';
