/* eslint-disable no-param-reassign, @typescript-eslint/no-explicit-any */
import { isHTMLElement } from '@cobbler-io/utils/src/isHTMLElement';

// Popper Modifier that makes the element the same with as the Popper
import { Modifier, ModifierArguments } from '@popperjs/core';

/**
 * Covers the element at the top left. Can overflow in width
 */
export const coverElement: Modifier<'coverElement', any> = {
  name: 'coverElement',
  enabled: true,
  phase: 'main',
  requires: ['sameHeight', 'computeStyles', 'popperOffsets'],
  effect: ({ state }) => {
    const offset = isHTMLElement(state.elements.reference)
      ? state.elements.reference.offsetWidth
      : state.elements.reference.getBoundingClientRect().width;

    // Set the min width of the component
    state.elements.popper.style.minWidth = `${offset}px`;
  },
  fn({ state }: ModifierArguments<any>) {
    // @ts-expect-error: this is fine
    state.modifiersData.popperOffsets = {
      ...state.modifiersData.popperOffsets,
      y: (state.modifiersData?.popperOffsets?.y ?? 0) + state.rects.reference.height,
    };

    if (state.styles.popper) {
      state.styles.popper.minWidth = `${state.rects.reference.width}px`;
    }
  },
};
