import * as React from 'react';

import { Button, Props as ButtonProps } from '@cobbler-io/core-ui/src/Button';

import { AuthContext } from '@cobbler-io/app/src/oidc/AuthContext';

type Props = Exclude<ButtonProps, 'onClick' | 'children'>;

export const LogoutButton = ({ name = 'log out', children = 'Logout', ...props }: Props) => {
  const {
    state: { manager },
  } = React.useContext(AuthContext);

  const handleLogout = React.useCallback(() => {
    manager?.signoutRedirect().catch(error => {
      console.error('[oidc]', 'Error thrown while logging out', error);
    });
  }, [manager]);

  return (
    <Button {...props} name={name} variant="text" onClick={handleLogout}>
      {children}
    </Button>
  );
};

LogoutButton.displayName = 'LogoutButton';

export default LogoutButton;
