/* eslint-disable sort-keys, sort-keys-fix/sort-keys-fix, camelcase */
import { head } from '@cobbler-io/utils/src/array/head';
import { noop } from '@cobbler-io/utils/src/noop';

import { useDefaultOrRandom } from '@cobbler-io/hooks/src/useDefaultOrRandom';

import { useSelectedBudgetRevisionId } from '@cobbler-io/redux/src/modules/current-budget';

import {
  AddBudgetLineMutation, AddChildBudgetLineInputType, BudgetLineEditorDataDocument,
  BudgetLineEditorDataQuery, BudgetLineEditorDataQueryVariables, BudgetLineTypeEnum,
  useAddBudgetLineMutation,
} from '@cobbler-io/app/src/api/graphql-types';
import { Period } from '@cobbler-io/app/src/providers';
import { useCurrentBudgetLine } from '@cobbler-io/app/src/providers/CurrentBudgetLineProvider';
import { useCurrentRange } from '@cobbler-io/app/src/providers/CurrentRangeProvider';
import { toDateToken } from '@cobbler-io/app/src/utils/toDateToken';

import { ExecutionResult } from '@apollo/react-common';
import { MutationUpdaterFn } from 'apollo-client';
import { append, lensPath, over, pathEq } from 'ramda';

import { BudgetLineType, EditorLine } from '../types';

type CreateOptimisticResponseParams = {
  name: string;
  id: string;
  type: BudgetLineTypeEnum;
  weight: number;
};

const createOptimisticResponse = ({ name, id, type, weight }: CreateOptimisticResponseParams) => ({
  __typename: 'Mutation' as const,
  createdBudgetLine: {
    id,
    name,
    type,
    isRevenue: type === BudgetLineType.REVENUE,
    owner: null,
    vendors: [],
    accounts: [],
    departments: [],
    plannedSpend: [],
    weight,
    __typename: 'BudgetLinePayloadType' as const,
  },
});

const useQueryVars = (): BudgetLineEditorDataQueryVariables => {
  const currentBudgetLine = useCurrentBudgetLine()!;
  const currentRange = useCurrentRange();
  const revisionId = useSelectedBudgetRevisionId()!;

  const queryVars: BudgetLineEditorDataQueryVariables = {
    id: currentBudgetLine.id,
    start: toDateToken(currentRange.start),
    end: toDateToken(currentRange.end),
    revisionId,
  };

  return queryVars;
};

const linesLens = lensPath<BudgetLineEditorDataQuery>(['a_budgetLineChildrenEditorData', 'lines']);

const shouldRefocus = (tempId: string): NullaryFn => {
  const maybeRow = document.activeElement?.parentElement;

  if (maybeRow?.dataset.rowId !== tempId) {
    return noop;
  }

  const { col, row } = document.activeElement?.dataset ?? {};
  if (!col || !row) {
    return noop;
  }

  return () =>
    queueMicrotask(() => {
      const el: HTMLElement | null = document.querySelector(
        `div[data-row="${row as string}"][data-col="${col as string}"]`,
      );
      el?.focus();
    });
};

const createUpdate: UnaryFn<
  { tempId: string; variables: BudgetLineEditorDataQueryVariables },
  MutationUpdaterFn<AddBudgetLineMutation>
> = ({ tempId, variables }) => {
  return (cache, response) => {
    const query = BudgetLineEditorDataDocument;
    const isOptimistic = pathEq(['data', 'createdBudgetLine', 'id'], tempId, response);

    const cachedData = cache.readQuery<BudgetLineEditorDataQuery>({
      query,
      variables,
    });

    if (!isOptimistic) {
      // On a regular update, the temporary row's dom nodes will be removed and replaced. A side
      // effect of that operation is that the current focus will be lost, so, we need to refocus
      // after the update. This does a double-queuemicrotask, but that seems to be necessary
      // to get the timing correct
      queueMicrotask(shouldRefocus(tempId));
    }

    const created = response.data!.createdBudgetLine;
    const firstRow = head(cachedData?.a_budgetLineChildrenEditorData?.lines ?? []);
    const newLine: EditorLine = {
      __typename: 'BudgetEditorLineType',
      id: created.id,
      type: created.type,
      isRevenue: created.isRevenue,
      linkedToDriver: false,
      canUpdate: !isOptimistic,
      accounts: created.accounts,
      vendors: created.vendors,
      departments: created.department ? [created.department] : [],
      name: created.name,
      owner: {
        __typename: 'UserReference' as const,
        id: created.owner?.id ?? null,
        item: created.owner,
      },
      plannedSpend: created.plannedSpend.map(ps => ({
        __typename: 'BudgetEditorPlannedSpendType',
        budgetLineId: created.id,
        revisionId: variables.revisionId,
        start: ps.start,
        spent: 0,
        spentShallow: 0,
        planned: 0,
        plannedRevenue: 0,
      })),
      ancestors: firstRow?.ancestors ?? [],
      childrenCount: 0,
      permissions: {
        __typename: 'ResourcePermissionType' as const,
        canRead: false,
        canUpdate: false,
        canDelete: false,
        canUpdatePermissions: false,
      },
      weight: created.weight,
    };

    cache.writeQuery({
      query,
      variables,
      data: over(linesLens, append(newLine), cachedData),
    });
  };
};

type CreateMutationVarsParams = {
  name: string;
  parentId: BudgetLineId;
  revisionId: BudgetRevisionId;
  type: BudgetLineTypeEnum;
  periods: Period[];
  weight?: number;
};

const createMutationVars = ({
  name,
  parentId,
  revisionId,
  type,
  periods,
  weight,
}: CreateMutationVarsParams): AddChildBudgetLineInputType => ({
  revisionId,
  parentId,
  name,
  type,
  accountIds: [],
  departmentIds: [],
  ownerUserId: null,
  vendors: [],
  plannedSpend: periods.map(p => ({
    start: p.startString,
    planned: 0,
  })),
  weight: weight ?? 100,
});

export const useAddLine = (): BinaryFn<
  string,
  number,
  Promise<ExecutionResult<AddBudgetLineMutation>>
> => {
  const [mutate] = useAddBudgetLineMutation();
  const tempId = useDefaultOrRandom();
  const queryVars = useQueryVars();
  const currentBudgetLine = useCurrentBudgetLine()!;
  const revisionId = useSelectedBudgetRevisionId()!;
  const range = useCurrentRange();

  return async (name: string, weight: number) => {
    const type = currentBudgetLine.isRoot ? BudgetLineType.EXPENSE : currentBudgetLine.type;
    const mutationVars = createMutationVars({
      name,
      revisionId,
      parentId: currentBudgetLine.id,
      type,
      periods: range.periods,
      weight: weight ?? 100,
    });

    return mutate({
      variables: mutationVars,
      optimisticResponse: createOptimisticResponse({ name, id: tempId, type, weight }),
      update: createUpdate({ tempId, variables: queryVars }),
    });
  };
};
