export * from './addDays';
export * from './addMonth';
export * from './addMonths';
export * from './addYears';
export * from './DateRange';
export * from './daysInMonth';
export * from './firstDayOfMonth';
export * from './getEndOfDay';
export * from './getFiscalYear';
export * from './getFiscalYearDates';
export * from './getStartOfDay';
export * from './getNameOfDay';
export * from './isDate';
export * from './isDateLike';
export * from './isLeapYear';
export * from './lastDayOfMonth';
export * from './valueOf';

/**
 * Minimum number that can be used with `new Date()`
 *
 * @see http://www.ecma-international.org/ecma-262/#sec-time-values-and-time-range §20.4.1.1
 */
export const MIN_SAFE_DATE: number = -8.64e15;

/**
 * Maximum number that can be used with `new Date()`
 *
 * @see http://www.ecma-international.org/ecma-262/#sec-time-values-and-time-range §20.4.1.1
 */
export const MAX_SAFE_DATE: number = 8.64e15;
