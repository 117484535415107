/* eslint-disable no-param-reassign, functional/immutable-data */
export type DrawCanvasBadgeOptions = {
  text: string;
  size?: number;
  x?: number;
  y?: number;
  vAlign?: 'top' | 'middle' | 'bottom';
  hAlign?: 'left' | 'center' | 'right';
  padding?: number;
  background?: string;
  foreground?: string;
};

export const drawCanvasBadge = (
  ctx: CanvasRenderingContext2D,
  options: DrawCanvasBadgeOptions,
): CanvasRenderingContext2D => {
  const {
    text,
    size = 16,
    vAlign = 'top',
    hAlign = 'left',
    background = '#d04',
    foreground = '#fff',
  } = options;

  ctx.font = `bold ${size}px serif`;
  ctx.textBaseline = 'middle';
  ctx.textAlign = 'center';

  // Default padding 40% of height
  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  const pad = options.padding ?? size * 0.4;
  const h = size + pad * 2;
  const w = Math.max(ctx.measureText(text).width + pad * 2, h);
  const r = h / 2;

  const offset = { x: 0, y: 0 }; // top, left

  if (hAlign === 'center') {
    offset.x = w / 2;
  }
  if (hAlign === 'right') {
    offset.x = w;
  }
  if (vAlign === 'middle') {
    offset.y = h / 2;
  }
  if (vAlign === 'bottom') {
    offset.y = h;
  }

  const x = (options.x ?? 0) - offset.x;
  const y = (options.y ?? 0) - offset.y;

  ctx.fillStyle = background;
  ctx.beginPath();
  ctx.moveTo(x + r, y);
  ctx.arcTo(x + w, y, x + w, y + h, r);
  ctx.arcTo(x + w, y + h, x, y + h, r);
  ctx.arcTo(x, y + h, x, y, r);
  ctx.arcTo(x, y, x + w, y, r);
  ctx.closePath();
  ctx.fill();

  ctx.fillStyle = foreground;
  ctx.fillText(text, x + w / 2, y + h / 2);

  return ctx;
};
