import * as React from 'react';

import { sum } from '@cobbler-io/utils/src/array/sum';
import { clamp0 } from '@cobbler-io/utils/src/numbers/clamp';

import { floatString } from '@cobbler-io/formatters/src/numbers';

import { useCurrencyFormatter } from '@cobbler-io/redux/src/modules/currency';

import { BudgetLineAtRevision } from '../../types';
import { createValidateMax } from '../../validators';
import { getMaxAmounts } from '../getMaxAmounts';
import { createFieldValue, FieldValue } from './createFieldValue';

type UseCustomExpenseParams = {
  parent: BudgetLineAtRevision;
  current: BudgetLineAtRevision | null;
  isRevenueRootChild: boolean;
  overplannedThreshold?: MinorCurrency;
  skipAmountValidation?: boolean;
  maxHintLabel?: string;
};

const getInitialState = (
  parent: BudgetLineAtRevision,
  current: BudgetLineAtRevision | null,
): Map<ISO8601String, FieldValue> => {
  const existingSpendPlans = current ? current.plannedByInterval : {};

  return new Map<ISO8601String, FieldValue>(
    parent.intervals.map(({ start, name }) => [
      start,
      createFieldValue({ initialValue: existingSpendPlans[start]?.allocated ?? 0, name, start }),
    ]),
  );
};

export type CustomExpenseType = {
  name: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  available: MinorCurrency;
  currentValue: MinorCurrency;
  defaultValue: MinorCurrency;
  difference: MinorCurrency;
  label: string;
  validateMax: (val: string) => string | false;
  maxHintLabel?: string;
};

export const useCustomExpense = (params: UseCustomExpenseParams): CustomExpenseType[] => {
  const {
    parent,
    current,
    overplannedThreshold = 0,
    skipAmountValidation = false,
    maxHintLabel,
    isRevenueRootChild,
  } = params;
  const { simpleFromMinorUnit, convertToMinorUnit } = useCurrencyFormatter();
  const { isTightlyBound, maxAmounts: maxAllowed } = getMaxAmounts(parent, isRevenueRootChild);
  const initialState = React.useMemo(() => getInitialState(parent, current), [parent, current]);

  // Next, we're going to create a map that will keep track of the default, current, and delta of
  // each field that has been set
  const [values, setValues] = React.useState(initialState);

  // This is the total change of all the fields. It's recalculated on each render
  const totalDelta = [...values.values()].reduce((acc, item) => acc + item.delta(), 0);

  // We need to keep track of the change in all the values to update the hints on each field, so
  // we'll need a callback to set those. This function will update the key on the values map by
  // changing the current (the delta automatically gets set)
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      currentTarget: { name, value },
    } = event;

    // We'll doublecheck that the name exists in the map already. If it has, then we'll change
    // update the map; otherwise, we'll ignore it. Nothing changes the name, but it's good to
    // guard against it in case someone futzes with our stuff. Also, using the functional update
    // form of setState (setValues), makes it so we can memoize the function well
    setValues(prev =>
      prev.has(name)
        ? new Map<ISO8601String, FieldValue>(
            prev.set(name, {
              ...prev.get(name)!,
              current: convertToMinorUnit(+floatString(value)),
            }),
          )
        : prev,
    );
  };

  return parent.intervals.map(({ start }) => {
    const field = values.get(start)!;
    const defaultValue = field.default;
    const max = isTightlyBound
      ? sum([maxAllowed[start], defaultValue])
      : sum([maxAllowed[start], defaultValue, totalDelta - field.delta()]);

    const validateMax = createValidateMax({
      convertToMinorUnit,
      max,
      simpleFromMinorUnit,
      skipAmountValidation,
      threshold: overplannedThreshold,
    });

    return {
      available: clamp0(max),
      currentValue: field.current,
      defaultValue,
      difference: clamp0(max - field.current),
      label: field.label,
      maxHintLabel,
      name: start,
      onChange,
      validateMax,
    };
  });
};
