import * as React from 'react';

import { execIfFunc } from '@cobbler-io/utils/src';
import { cx } from '@cobbler-io/utils/src/cx';
import { isFunction } from '@cobbler-io/utils/src/isFunction';
import { isNotFalsy } from '@cobbler-io/utils/src/isNotFalsy';

import { CellProps } from 'react-table';

import Shimmer from '../../Shimmer';
import { isNumericColumn } from '../Column';
import { DataGridContext } from '../DataGridContext';
import { CellEventHandler } from '../types';

import styles from './Cell.scss';

const handlerName = /^on[A-Z]./u;

type EventHandlerRecord = Record<string, React.EventHandler<any>>;

const wrapOnFocus = <T extends UnknownObject = UnknownObject>({
  onFocus,
  ...rest
}: Record<string, CellEventHandler<React.FocusEvent, T>>): Record<
  string,
  CellEventHandler<React.FocusEvent, T>
> => ({
  ...rest,
  onFocus: (event: React.FocusEvent, cellProps: CellProps<T>): void => {
    const el = event.currentTarget;
    window.getSelection()?.setBaseAndExtent(el, 0, el, 0);
    execIfFunc(onFocus, event, cellProps);
  },
});

const isHandler = ([key, value]: [string, any]): [string, any] | false => {
  if (handlerName.test(key) && isFunction(value)) {
    return [key, value];
  }

  return false;
};

const getHandlers = <T extends UnknownObject = UnknownObject>(
  obj: CellProps<T>,
): Record<string, React.EventHandler<any>> => {
  const wrapHandler: UnaryFn<CellEventHandler<React.SyntheticEvent, T>, React.EventHandler<any>> =
    original => event =>
      original(event, obj);

  const reduceHandlers = (acc: EventHandlerRecord, [key, value]: [string, any]) => {
    acc[key] = wrapHandler(value); // eslint-disable-line
    return acc;
  };

  const handlers = Object.entries(obj.cell.column)
    .map(isHandler)
    .filter(isNotFalsy)
    .reduce<EventHandlerRecord>(reduceHandlers, {});

  // Clipboard Events fire only if the current selection is located within
  // `document.activeElement`. Calling `focus` on a DOMNode moves the activeElement,
  // but it does not move the selection. To do that, you need to use a mouse. Or,
  // we can programmatically manipulate it by creating an empty selection set in the
  // DOMNode. The following ensures that an `onFocus` handler is created that will
  // also move the selection set. This works for arrow navigation keys as well as tab
  // @ts-expect-error: onFocus is there. We always need a focus handler in order to deal
  // with copy/paste when using tab to navigate
  return wrapOnFocus(handlers);
};

export const Cell = <T extends UnknownObject = UnknownObject>(
  props: CellProps<T> & { rowIndex: number },
): JSX.Element => {
  const { cell, row, rowIndex } = props;
  const { isRepeatedValue, isPlaceholder, isAggregated } = cell;
  const { classNameFn, className, editable, focusedCells, title, isPinned } = cell.column;
  const { stuck } = React.useContext(DataGridContext);
  const handlers = getHandlers(props);

  const appliedClasses = cx(
    styles.cell,
    isNumericColumn(cell.column) && styles.alignRight,
    focusedCells?.[cell.row.id]?.includes(cell.column.id) && styles.focused,
    editable && styles.editable,
    isPinned && stuck.includes(cell.column.id) && styles.pinned,
    classNameFn?.(props),
    cell.getCellProps().className,
    className,
  );

  const common: Record<string, any> = {
    ...cell.getCellProps(),
    className: appliedClasses,
    'data-col': cell.column.id,
    'data-row': cell.row.id,
    'data-row-index': rowIndex,
    tabIndex: 0,
    title: title ? execIfFunc(title, row.original) : undefined,
  };

  if (props.loading) {
    return (
      <div {...common}>
        <Shimmer />
      </div>
    );
  }

  if (isAggregated) {
    return (
      <div {...common} {...handlers}>
        {cell.render('Aggregated')}
      </div>
    );
  }

  if (isRepeatedValue || isPlaceholder) {
    return <div {...common}>&nbsp;</div>;
  }

  return (
    <div {...common} {...handlers}>
      {cell.render(editable && cell.column.Edit ? 'Edit' : 'Cell')}
    </div>
  );
};

Cell.displayName = 'DataGrid__Cell';
