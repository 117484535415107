import * as React from 'react';

import { cx } from '@cobbler-io/utils/src/cx';
import { execIfFunc } from '@cobbler-io/utils/src/execIfFunc';

import { useToggle } from '@cobbler-io/hooks/src';

import { CloseButton } from '@cobbler-io/core-ui/src/CloseButton';
import {
  Modal, ModalProps, ModalTreeNode, useModal, useModalImplementation,
} from '@cobbler-io/core-ui/src/Modal';

import { animated, useChain, useSpring, useSpringRef } from '@react-spring/web';

import styles from './Notification.scss';

export type NotificationParams = {
  /**
   * The title of the notification (strings only for now)
   */
  title?: string;
  /**
   * The body of the notification (strings only for now)
   */
  body?: React.ReactNode;
  /**
   * Whether or not to require user interaction to dismiss
   */
  persist?: boolean;
};

export type NotificationProps = ModalProps & React.HTMLProps<HTMLDivElement> & NotificationParams;

type TimerProps = {
  style: any;
};

const Timer = ({ style }: TimerProps): JSX.Element => (
  <div className={styles.timer}>
    <animated.div className={styles.timerInner} style={style} />
  </div>
);

Timer.displayName = 'Timer';

const FIVE_SECONDS = 5_000;

export const Notification = ({
  title,
  body,
  persist = false,
  ...props
}: NotificationProps): JSX.Element => {
  const { modal, position } = useModalImplementation();
  const notificationRef = React.useRef<HTMLDivElement>(null);
  const springRef = useSpringRef(); // React.useRef<ReactSpringHook>(null);
  const timerRef = useSpringRef(); // React.useRef<ReactSpringHook>(null);
  const { active: hasEntered, activate: onEnter, deactivate: onLeave } = useToggle(false);

  const init = position.relative * 100;
  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  const adjust = position.relative * 5;

  const notificationStyle = useSpring({
    from: { transform: `translate(100%, calc(${init}% + ${adjust}px))` },
    ref: springRef,
    to: { transform: `translate(0, calc(${init}% + ${adjust}px))` },
  });

  const timerStyle = useSpring({
    config: { duration: FIVE_SECONDS },
    from: { transform: 'translate(-100%, 0)' },
    onRest: () => !hasEntered && execIfFunc(modal?.close),
    ref: timerRef,
    to: { transform: 'translate(0, 0)' },
  });

  const chain = [springRef, !persist && timerRef].filter(Boolean) as typeof springRef[];
  // Make the notification appear, then make the timer start
  useChain(chain);

  return (
    <Modal
      {...props}
      bare
      deferFocus
      className={styles.notification}
      clickAway="none"
      escape="none"
    >
      {(_, { className }) => (
        <animated.div
          ref={notificationRef}
          className={cx(styles.notification, className)}
          style={notificationStyle}
          onMouseEnter={onEnter}
          onMouseLeave={onLeave}
        >
          <div className={styles.content}>
            {title && <div className={styles.title}>{title}</div>}
            {body && <div className={styles.body}>{body}</div>}
          </div>
          <div className="margin-0">
            <CloseButton className="margin-0" name="close-notification" />
          </div>
          <Timer style={timerStyle} />
        </animated.div>
      )}
    </Modal>
  );
};

Notification.displayName = 'Notification';

export const useNotification = (): UnaryFn<NotificationParams, ModalTreeNode> => {
  const { create } = useModal();

  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  return (params: NotificationParams = {} as NotificationParams) =>
    create(<Notification {...(params as NotificationProps)} />, { type: 'Notification' });
};

export default Notification;
