import * as React from 'react';

import { Icon, IconTypes } from '@cobbler-io/core-ui/src/Icon';

import styles from './Control.scss';

type ControlProps = {
  name: string;
  icon: IconTypes;
  value: number;
  min: number;
  max: number;
  step?: number;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
};

export const Control = (props: ControlProps): JSX.Element => {
  const { name, icon, value, min, max, step = 1, onChange, ...rest } = props;

  return (
    <div className={styles.control}>
      <div className={styles.label}>
        <Icon size={18} type={icon} />
        <span>{name}</span>
      </div>
      <div>
        <input
          {...rest}
          className={styles.range}
          max={max}
          min={min}
          name={`range-${name}`}
          step={step}
          type="range"
          value={value}
          onChange={onChange}
        />
      </div>
    </div>
  );
};

Control.displayName = 'Control';
