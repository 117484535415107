import { curryN, flip, includes, propSatisfies } from 'ramda';

/**
 * Returns `true` if the specified object property is equal to one of the values
 * of the supplied list of values; `false` otherwise.
 * This function is very similar to `propEq` but can take multiple values to
 * compare against.
 *
 * ```ts
 * const abby = {name: 'Abby', age: 7, hair: 'blond'};
 * const fred = {name: 'Fred', age: 12, hair: 'brown'};
 * const rusty = {name: 'Rusty', age: 10, hair: 'brown'};
 * const alois = {name: 'Alois', age: 15, disposition: 'surly'};
 * const kids = [abby, fred, rusty, alois];
 * const isTenOrTwelve = R.propIn('age', [10, 12]);
 * R.filter(isTenOrTwelve, kids); //=> [fred, rusty]
 * ```
 */
export const propIn = curryN(3, <T>(name: string, values: any[] | readonly any[], obj: T) =>
  propSatisfies<any[], T>(flip(includes)(values), name, obj),
);
