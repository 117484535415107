import * as React from 'react';

import { ComboBox, ComboBoxProps, Item } from '@cobbler-io/core-ui/src/Dropdown';
import { useFeatureFlag } from '@cobbler-io/core-ui/src/FeatureFlag';

import { useDepartmentTree } from '@cobbler-io/app/src/api/graph';

import { DepartmentLabel } from './DepartmentLabel';

export type DepartmentPickerProps = Omit<ComboBoxProps<DepartmentId>, 'items'> & {
  subTreeOf?: DepartmentId;
};

export type DepartmentItem = {
  id: DepartmentId;
  label: string | React.ReactElement;
  value: DepartmentId;
  search: string;
};

export const DepartmentPicker = React.forwardRef(
  (props: DepartmentPickerProps, ref: React.RefObject<HTMLDivElement>): JSX.Element => {
    const { variant = 'inlineChip', multiple = true, subTreeOf } = props;

    const FULL_NAME = useFeatureFlag('DepartmentFullName');

    const { root, loaded, loading, error } = useDepartmentTree();

    const { items } = React.useMemo(() => {
      const base = (subTreeOf ? root?.getDescendantById(subTreeOf) : root) ?? null;

      if (!FULL_NAME) {
        return {
          items: base?.castDepthFirst().map(x => ({
            id: x.id,
            label: x.value.properties.name,
            value: x.id,
          })),
        };
      }

      const valid = base?.castDepthFirst().map(
        (node): DepartmentItem => ({
          id: node.id,
          label: <DepartmentLabel base={base} node={node} />,
          search: node
            .getAncestors(base)
            .map(x => x.value.properties.name)
            .join(' > '),
          value: node.id,
        }),
      );

      return { items: valid };
    }, [FULL_NAME, root, subTreeOf]);

    if (error) {
      console.error(error);
      return <div>Error fetching departments for DepartmentPicker</div>;
    }

    if (loading && !loaded) {
      return <ComboBox.Loading {...props} variant={variant} />;
    }

    return (
      <ComboBox
        {...props}
        ref={ref}
        items={items as Item<DepartmentId>[]}
        multiple={multiple}
        preventClearFilterOnSelect={FULL_NAME && multiple}
        preventCloseOnSelect={FULL_NAME && multiple}
        variant={variant}
      />
    );
  },
);

DepartmentPicker.displayName = 'DepartmentPicker';
