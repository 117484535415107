import * as React from 'react';

import { date } from '@cobbler-io/formatters/src/dates';

import { Button } from '@cobbler-io/core-ui/src/Button';
import { Dialog } from '@cobbler-io/core-ui/src/Dialog';
import { Heading } from '@cobbler-io/core-ui/src/Heading';
import { Icon } from '@cobbler-io/core-ui/src/Icon';
import { Menu, useFlyoutMenu } from '@cobbler-io/core-ui/src/Menu';
import { Modal, useModal } from '@cobbler-io/core-ui/src/Modal';

import { useCurrencyFormatter } from '@cobbler-io/redux/src/modules/currency';

import {
  CurrentActualQueryQueryResult, useDeleteActualSplitMutation,
} from '@cobbler-io/app/src/api/graphql-types';
import { ActualSlice, OriginalActual } from '@cobbler-io/app/src/ndm/components/ActualSlice';
import { useCurrentActual } from '@cobbler-io/app/src/providers/CurrentActualProvider';

import { ActualProperty } from '../ActualProperty';
import { normalizeSplitActual } from '../normalizeSplitActual';
import { SplitTransactionModal } from '../SplitTransactionModal';

import styles from './SplitTransactionDetails.scss';

type SplitActual = NonNullable<NonNullable<CurrentActualQueryQueryResult['data']>['actual']>;

type SplitTransactionDetailsProps = {
  actual: SplitActual;
  isRevenue: boolean;
};

export const SplitTransactionDetails = (props: SplitTransactionDetailsProps): JSX.Element => {
  const { actual, isRevenue } = props;
  const { create: createModal } = useModal();
  const { refetch } = useCurrentActual();
  const [undoSplit] = useDeleteActualSplitMutation({
    variables: { input: { splitOriginId: actual.id } },
  });
  const { convertToMinorUnit } = useCurrencyFormatter();

  const normalizedActual = normalizeSplitActual(actual, convertToMinorUnit, isRevenue);

  // To be able to edit the split actual, the user needs to be able to see
  // every one of those lines. If one is null, then they cannot edit it.
  const canEditSplitActual =
    normalizedActual.slices.every(s => s.budgetLine !== null) &&
    normalizedActual.slices.every(s => s.budgetLine?.canUpdate);

  const editTransaction = () =>
    createModal(
      <Modal className="floating-box absolute-center" overlay="transparent">
        <SplitTransactionModal actual={normalizedActual} refetch={refetch} />
      </Modal>,
    );

  const undoSplitTransaction = () =>
    createModal(
      <Dialog title="Undo split" onConfirm={async () => undoSplit().then(refetch)}>
        Are you sure you want to undo this split?
      </Dialog>,
    );

  const onClickMenu = useFlyoutMenu(
    <Menu id="splitOriginEdit">
      <Menu.Item iconType="editOutline" label="Edit split" onSelect={editTransaction} />
      <Menu.Item iconType="undo" label="Undo split" onSelect={undoSplitTransaction} />
    </Menu>,
  );

  if (!actual) {
    // @ts-expect-error: this is fine for React
    return null;
  }

  const [original, ...slices] = normalizedActual.slices;

  return (
    <div>
      <div className={styles.header}>
        <Heading>Transaction Split</Heading>
        {canEditSplitActual && (
          <Button small name="" variant="svg" onClick={onClickMenu}>
            <Icon type="more" />
          </Button>
        )}
      </div>
      <ActualProperty
        label="Date Split"
        value={normalizedActual.modifiedAt ? date(new Date(normalizedActual.modifiedAt)) : null}
      />

      <div className={styles.group}>
        <div className={styles.label}>Original Amount</div>
        <OriginalActual {...original} />
      </div>

      <div className={styles.group}>
        <div className={styles.label}>Assigned to</div>
        <ActualSlice {...original} />

        {slices.map(slice => (
          <ActualSlice key={slice.id} {...slice} originalAmount={original.originalAmount} />
        ))}
      </div>
    </div>
  );
};

SplitTransactionDetails.displayName = 'SplitTransactionDetails';
