import * as React from 'react';

import range from 'lodash/range';

import { Shimmer } from './Shimmer';

export type ShimmerTableRowProps = React.HTMLProps<HTMLDivElement> & {
  className?: string;
  rows?: number;
  cols: number;
};

export const ShimmerTableRow = ({
  className,
  cols,
  rows = 1,
}: ShimmerTableRowProps): JSX.Element => {
  return (
    <>
      {range(rows).map(r => (
        <tr key={r} className={className}>
          {range(cols).map(c => (
            <td key={c}>
              <Shimmer />
            </td>
          ))}
        </tr>
      ))}
    </>
  );
};
ShimmerTableRow.displayName = 'ShimmerTableRow';
