import React from 'react';

import {
  MetaBase, Row, TableToggleAllRowsSelectedProps, TableToggleRowsSelectedProps,
} from 'react-table';

export const getToggleRowSelectedProps = <D extends UnknownObject>(
  props: Partial<TableToggleRowsSelectedProps>,
  { instance, row }: MetaBase<D> & { row: Row<D> },
): Partial<TableToggleRowsSelectedProps>[] => {
  const { manualRowSelectedKey = 'isSelected' } = instance;
  const checked = !!row?.original[manualRowSelectedKey] || row.isSelected;
  const checkboxProps: Partial<TableToggleRowsSelectedProps>[] = [
    props,
    {
      checked,
      // @ts-expect-error: This is a valid prop of input, but not part of TableToggleRowsSelectedProps
      disabled: !row?.isSelectable,
      indeterminate: row.isSomeSelected,
      onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
        row.toggleRowSelected(row.isSelectable && e.target.checked);
      },
      title: 'Toggle row selected',
    },
  ];
  return checkboxProps;
};

export const getToggleAllRowsSelectedProps = <D extends UnknownObject>(
  props: Partial<TableToggleAllRowsSelectedProps>,
  { instance }: MetaBase<D>,
): Partial<TableToggleAllRowsSelectedProps>[] => [
  props,
  {
    checked: instance.isAllRowsSelected,
    indeterminate:
      !instance.isAllRowsSelected && Object.keys(instance.state.selectedRowIds).length > 0,
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
      instance.toggleAllRowsSelected(e.target.checked);
    },
    title: 'Toggle all rows selected',
  },
];

export const getToggleAllPageRowsSelectedProps = <D extends UnknownObject>(
  props: Partial<TableToggleAllRowsSelectedProps>,
  { instance }: MetaBase<D>,
): Partial<TableToggleAllRowsSelectedProps>[] => [
  props,
  {
    checked: instance.isAllPageRowsSelected,
    indeterminate:
      !instance.isAllPageRowsSelected &&
      instance.page.some(({ id }) => instance.state.selectedRowIds[id]),
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
      instance.toggleAllPageRowsSelected(e.target.checked);
    },
    title: 'Toggle all current page rows selected',
  },
];
