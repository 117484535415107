/* eslint-disable no-bitwise */
import { getEndOfMonth } from './getEndOfMonth';
import { DateLike } from './isDateLike';

export type Half = { start: Date; end: Date; half: number };

export const getHalfDates = (date: DateLike, startMonth = 0): Half => {
  const d = new Date(date);
  const adjusted = d.getMonth() - startMonth;
  const h = adjusted < 0 ? 11 - ~adjusted : adjusted;
  const year = d.getMonth() < startMonth ? d.getFullYear() - 1 : d.getFullYear();
  const half = Math.floor(h / 6) + 1;

  const start = new Date(year, (half - 1) * 6 + startMonth, 1, 0, 0, 0);
  const end = getEndOfMonth(new Date(start.getFullYear(), start.getMonth() + 5));

  return { start, end, half };
};
