import * as React from 'react';

import tokens from '@cobbler-io/design-tokens';

import styles from './LoadingDots.scss';

export type LoadingDotsProps = {
  /**
   * The color of the dots
   */
  color?: string;
  /**
   * The size of the dots
   */
  size?: number;
};

export const LoadingDots = ({
  color = tokens.colorOnPaper,
  size = 80,
}: LoadingDotsProps): JSX.Element => {
  return (
    <div className={styles.loadingDots} style={{ color, height: size, width: size }}>
      <div />
      <div />
      <div />
      <div />
    </div>
  );
};

LoadingDots.displayName = 'LoadingDots';

export default LoadingDots;
