const UPLOAD_ENDPOINT = '/api/file/user/profileimage/upload';
// const DOWNLOAD_ENDPOINT = '/api/file/user/profileimage/download';

export type UploadProfilePictureParams = {
  tokenType: string;
  accessToken: string;
  file: File;
};

export const uploadProfilePicture = async ({
  tokenType,
  accessToken,
  file,
}: UploadProfilePictureParams): Promise<string> => {
  const body = new FormData();
  body.append('file', file, file.name);

  return fetch(UPLOAD_ENDPOINT, {
    method: 'POST',
    mode: 'same-origin',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: { Authorization: `${tokenType} ${accessToken}` },
    body,
  })
    .then(async res => (res.ok ? res.text() : Promise.reject(res)))
    .catch(async err => {
      console.error(err); // eslint-disable-line
      return Promise.reject(err);
    });
};
