import { ISO8601 } from '@cobbler-io/formatters/src/dates';

import { getFiscalYearDates } from '@cobbler-io/dates/src';
import { getEndOfMonth } from '@cobbler-io/dates/src/getEndOfMonth';
import { getFiscalYearsInPeriod } from '@cobbler-io/dates/src/getFiscalYearsInPeriod';
import { getHalfDates } from '@cobbler-io/dates/src/getHalfDates';
import { getHalvesInPeriod } from '@cobbler-io/dates/src/getHalvesInPeriod';
import { getMonthDates } from '@cobbler-io/dates/src/getMonthDates';
import { getMonthsInPeriod } from '@cobbler-io/dates/src/getMonthsInPeriod';
import { getQuarterDates } from '@cobbler-io/dates/src/getQuarterDates';
import { getQuartersInPeriod } from '@cobbler-io/dates/src/getQuartersInPeriod';
import { tzAdjust } from '@cobbler-io/dates/src/tzAdjust';

import { createDivisorMap } from '../ExpenseSchedule/createDivisorMap';
import { DatePeriodType } from '../ExpenseSchedule/getDatePeriods';
import { BudgetLineAtRevision } from '../types';

type RecurringExpenseValues = {
  'expense-is-the-same': true;
  'expense-value': MajorCurrency;
  frequency: DatePeriodType;
  'expense-start': ISO8601String;
  'expense-end': ISO8601String;
};

type CustomExpenseValues = { 'expense-is-the-same': false } & {
  [start: ISO8601String]: MajorCurrency;
};

export type UpdateBudgetFormValues = XOR<RecurringExpenseValues, CustomExpenseValues>;

export type ProposedSpendPlan = {
  start: ISO8601String;
  end: ISO8601String;
  amount: MajorCurrency;
};

export const extractSpendPlansFromExpenseSchedule = (
  parent: BudgetLineAtRevision,
  values: UpdateBudgetFormValues,
): ProposedSpendPlan[] => {
  if (values['expense-is-the-same']) {
    const { budgetResolution: resolution } = parent; // always MONTH
    const { frequency } = values;
    const start = values['expense-start'];
    const end = values['expense-end'];
    const value = values['expense-value'];
    const fysm = parent.fiscalYearStartMonth - 1;

    const divisorMap = createDivisorMap({
      resolution,
      fysm,
      min: parent.start,
      max: ISO8601(getEndOfMonth(tzAdjust(parent.end))),
    });

    const coefficient = divisorMap[resolution][frequency];

    // This is still a major currency and needs to be converted
    const amount = value / coefficient;

    const findEndForOnce = () => {
      const fn = {
        MONTH: getMonthDates,
        QUARTER: getQuarterDates,
        HALF: getHalfDates,
        YEAR: getFiscalYearDates,
      }[resolution];

      return fn(tzAdjust(start)).end;
    };

    const fn = {
      MONTH: getMonthsInPeriod,
      QUARTER: getQuartersInPeriod,
      HALF: getHalvesInPeriod,
      YEAR: getFiscalYearsInPeriod,
    }[resolution];

    return fn(tzAdjust(start), frequency === 'ONCE' ? findEndForOnce() : tzAdjust(end), fysm).map(
      p => ({
        start: ISO8601(p.start),
        end: ISO8601(p.end),
        amount,
      }),
    );
  }

  return parent.intervals
    .map(({ start, end }) => (values[start] ? { start, end, amount: values[start] } : null))
    .filter((p): p is ProposedSpendPlan => Boolean(p));
};
