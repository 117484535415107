import { useSelectedBudgetRevisionId } from '@cobbler-io/redux/src/modules/current-budget';
import { useTenantFiscalYearStart } from '@cobbler-io/redux/src/modules/tenant-settings';

import {
  BudgetLineEditorDataQueryVariables, useBudgetLineEditorDataQuery,
} from '@cobbler-io/app/src/api/graphql-types';
import {
  mapDataForBudgetRevision,
} from '@cobbler-io/app/src/ndm/components/BudgetLineEditor/mapDataForBudgetRevision';
import { useCurrentBudgetLine } from '@cobbler-io/app/src/providers/CurrentBudgetLineProvider';
import { useCurrentRange } from '@cobbler-io/app/src/providers/CurrentRangeProvider';
import { toDateToken } from '@cobbler-io/app/src/utils/toDateToken';

/**
 * Gets the currently cached editor data or fetches it if needed.
 * It takes an optional `revisionId` or falls back to the currently selected one.
 * It returns the Apollo query's `loading`, `error` & `data`, as well as the
 * variables used to construct the query (`variables`) and mapped version of the
 * data (`mappedData`, passed through `mapDataForBudgetRevision()`).
 */
export const useCurrentEditorData = (revisionId?: BudgetRevisionId) => {
  const fiscalYearStartMonth = useTenantFiscalYearStart();
  const selectedRevisionId = useSelectedBudgetRevisionId();
  const budgetLineContext = useCurrentBudgetLine();
  const rangeContext = useCurrentRange();
  const id = budgetLineContext?.id;
  const start = toDateToken(rangeContext.start);
  const end = toDateToken(rangeContext.end);
  const variables: BudgetLineEditorDataQueryVariables = {
    id: id!,
    revisionId: revisionId || selectedRevisionId!,
    start,
    end,
  };
  const { data, loading, error, refetch } = useBudgetLineEditorDataQuery({
    variables,
    fetchPolicy: 'cache-first',
    skip: !id || !(revisionId || selectedRevisionId),
  });
  const mappedData =
    data && mapDataForBudgetRevision(data.a_budgetLineChildrenEditorData, fiscalYearStartMonth);

  // TODO: In the future we could also return some helpers to update Apollo's cache
  // TODO: Maybe add users/vendors/departments
  // TODO: Consider using context instead
  return {
    data,
    loading,
    error,
    variables,
    mappedData,
    refetch,
  };
};
