import * as React from 'react';

import { lower } from '@cobbler-io/utils/src/string/lower';
import { upperFirst } from '@cobbler-io/utils/src/string/upperFirst';

import { Button } from '@cobbler-io/core-ui/src/Button';
import { Icon } from '@cobbler-io/core-ui/src/Icon';
import { Menu, useFlyoutMenu } from '@cobbler-io/core-ui/src/Menu';

import { useTenantFiscalYearStart } from '@cobbler-io/redux/src/modules/tenant-settings';

import { useNamedDateRange } from '@cobbler-io/app/src/utils/useNamedDateRange';

import { useCurrentRange } from './useCurrentRange';

import styles from './PeriodMenu.scss';

type PeriodMenuProps = {
  className?: string;
};

const datePeriodNames = ['MONTH', 'QUARTER', 'HALF', 'YEAR'] as const;

export const PeriodMenu = (props: PeriodMenuProps) => {
  const { className } = props;
  const fysm = useTenantFiscalYearStart() - 1;
  const { min, max, setPeriodLength, periodLength } = useCurrentRange();
  /**
   * @TODO get this from the API somewhere
   */
  const budgetResolution = 'MONTH';

  const { resolutionFilter } = useNamedDateRange({
    maxResolution: budgetResolution,
    min,
    max,
    fysm,
  });

  const periodMenu = (
    <Menu small>
      {datePeriodNames.filter(resolutionFilter).map(l => (
        <Menu.Item key={l} label={upperFirst(lower(l))} onSelect={() => setPeriodLength(l)} />
      ))}
    </Menu>
  );

  const periodMenuClick = useFlyoutMenu(periodMenu);

  return (
    <Button
      small
      className={className}
      label="Choose range unit"
      name="period menu chooser"
      variant="text"
      onClick={periodMenuClick}
    >
      <Icon className={styles.buttonIcon} size={16} type="calendarList" />
      {['View by', upperFirst(lower(periodLength))].join(': ')}
    </Button>
  );
};
