import * as React from 'react';

import { Button } from '@cobbler-io/core-ui/src/Button';
import { Icon } from '@cobbler-io/core-ui/src/Icon';

import styles from './DiscussionStatus.scss';

type ResolvedStatusProps = {
  handleUnresolve: () => void;
};
// Header for resolved discussions
const ResolvedStatus = (props: ResolvedStatusProps): JSX.Element => {
  const { handleUnresolve } = props;
  return (
    <div className={styles.resolved}>
      <span>
        <Icon color="currentColor" size={20} type="checkCircleFilled" /> Resolved
      </span>
      <Button
        small
        name="unresolve discussion"
        type="button"
        variant="text"
        onClick={handleUnresolve}
      >
        Re-open
      </Button>
    </div>
  );
};
ResolvedStatus.displayName = 'ResolvedStatus';

type AssignedProps = {
  assignedTo?: string | null;
};
// Name of the user assigned to the discussion or "Unassigned"
const Assigned = (props: AssignedProps): JSX.Element => {
  const { assignedTo } = props;

  if (!assignedTo) {
    return (
      <span>
        <em>Unassigned</em>
      </span>
    );
  }

  return (
    <span>
      Assigned to <strong>{assignedTo}</strong>
    </span>
  );
};

Assigned.displayName = 'Assigned';

type ActiveStatusProps = {
  assignedTo?: string | null;
  toggleAssigning: () => void;
  canResolve: boolean;
  isDisabled: boolean;
  handleResolve: () => void;
};
// Header for active discussions
const ActiveStatus = (props: ActiveStatusProps): JSX.Element => {
  const { assignedTo, toggleAssigning, canResolve, isDisabled, handleResolve } = props;
  return (
    <div className={styles.unresolved}>
      <Assigned assignedTo={assignedTo} />
      <Button
        small
        className={styles.assigneeEdit}
        name="edit discussion assignee"
        type="button"
        variant="svg"
        onClick={toggleAssigning}
      >
        <Icon size={16} type="edit" />
      </Button>
      {canResolve && (
        <Button
          small
          disabled={isDisabled}
          name="resolve discussion"
          type="button"
          variant="text"
          onClick={handleResolve}
        >
          Resolve
        </Button>
      )}
    </div>
  );
};
ActiveStatus.displayName = 'ActiveStatus';

type DiscussionStatusProps = ActiveStatusProps &
  ResolvedStatusProps & { resolved: boolean; showStatus: boolean };
// Discussion status component
export const DiscussionStatus = (props: DiscussionStatusProps): JSX.Element => {
  const {
    showStatus,
    isDisabled,
    assignedTo,
    toggleAssigning,
    canResolve,
    resolved,
    handleResolve,
    handleUnresolve,
  } = props;

  if (!showStatus) {
    // @ts-expect-error This is fine for react.
    return null;
  }

  return (
    <div className={styles.status}>
      {resolved ? (
        <ResolvedStatus handleUnresolve={handleUnresolve} />
      ) : (
        <ActiveStatus
          assignedTo={assignedTo}
          canResolve={canResolve}
          handleResolve={handleResolve}
          isDisabled={isDisabled}
          toggleAssigning={toggleAssigning}
        />
      )}
    </div>
  );
};

DiscussionStatus.displayName = 'DiscussionStatus';
