import { head } from '@cobbler-io/utils/src/array';
import { getLocation } from '@cobbler-io/utils/src/getLocation';
import { isGuid } from '@cobbler-io/utils/src/isGuid';

import { BudgetRevisionTag } from '@cobbler-io/redux/src/modules/current-budget/types';

// Rename this
export const getBudgetLineIdFromLocation = (
  location?: Location,
): { budgetLineId?: BudgetLineId; revisionTag?: BudgetRevisionTag } => {
  const loc = getLocation(location);

  if (!loc) {
    return {};
  }

  const [path] = loc.pathname.split('?');
  const parts = path.split('/').filter(Boolean);

  const [ns, budgetLineId, revisionTag] = head(parts) === 'ndm' ? parts.slice(1) : parts;

  return ns === 'budgets' && isGuid(budgetLineId) ? { budgetLineId, revisionTag } : {};
};
