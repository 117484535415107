/* eslint-disable max-lines-per-function */
import * as React from 'react';

import { noop } from '@cobbler-io/utils/src';
import { head } from '@cobbler-io/utils/src/array/head';
import { cx } from '@cobbler-io/utils/src/cx';
import { identity } from '@cobbler-io/utils/src/identity';
import { isHTMLElement } from '@cobbler-io/utils/src/isHTMLElement';
import { rando } from '@cobbler-io/utils/src/rando';

import { useDefaultOrRandom } from '@cobbler-io/hooks/src/useDefaultOrRandom';
import { useGetLatest } from '@cobbler-io/hooks/src/useGetLatest';
import { coverElement } from '@cobbler-io/hooks/src/usePopper/coverElement';
import { disableModifiers } from '@cobbler-io/hooks/src/usePopper/disableModifiers';
import { sameHeight } from '@cobbler-io/hooks/src/usePopper/sameHeight';

import { Modal, ModalTreeNode, useChildModal } from '@cobbler-io/core-ui/src/Modal';
import { useNotification } from '@cobbler-io/core-ui/src/Notification';

import { useSelectedBudgetRevisionId } from '@cobbler-io/redux/src/modules/current-budget';

import { Exact } from '@cobbler-io/app/src/api/graphql-types';

import { MutationHookOptions, MutationTuple } from '@apollo/react-hooks';
import { Placement } from '@popperjs/core';
import { Form, RegisterType as FieldType } from '@swan-form/form';
import { MutationUpdaterFn } from 'apollo-client';
import isEqual from 'lodash/isEqual';
import { always, complement, equals, F as returnFalse } from 'ramda';
import { CellProps } from 'react-table';

import { PLACEHOLDER_ID } from '../constants';
import { EditorLine } from '../types';
import { useAddLine } from './useAddLine';

import styles from './OverrideField.scss';

export type InputParams<V = any, T = any> = {
  budgetLineId: BudgetLineId;
  revisionId: BudgetRevisionId;
  /**
   * This is the value from the form
   */
  value: T;
  /**
   * These are the props that the cell was rendered with. It can give access to the initial
   * row (or pretty much the entire table)
   */
  cellProps: CellProps<EditorLine, V>;
};

// eslint-disable-next-line @typescript-eslint/no-magic-numbers
const isTempId = (id: BudgetLineId) => id.length < 12; // Super simple but effective...

const advanceToNextRow = (event: React.MouseEvent | React.KeyboardEvent): NullaryFn => {
  if (!event.currentTarget) {
    return noop;
  }

  const rowGroup = event.currentTarget.parentElement?.parentElement;
  if (!rowGroup) {
    return noop;
  }

  const { col, rowIndex } = event.currentTarget.dataset as { col: string; rowIndex: string };
  const row = parseInt(rowIndex ?? '-1') + 1;
  const nextCell: HTMLElement | null = rowGroup.querySelector(
    `div[role="cell"][data-col="${col}"][data-row-index="${row}"]`,
  );

  // This gets called on close when the user submits by pressing enter. We need a double
  // `queueMicrotask` here because we're calling it as a `close` callback. But that runs before the
  // afterClose callbacks that will re-focus the previous cell, so instead, we need to push past
  // the afterware in order to get the correct close focus
  return () =>
    queueMicrotask(() => {
      queueMicrotask(() => nextCell?.focus());
    });
};

const ignoreModifierKeys = (event: React.KeyboardEvent): boolean => {
  // We could make this more OS specific, but we want to ensure that
  // we're not overriding hotkeys. This is a quick pass to make it
  // so that we allow `shift + R` but not `cmd + R` to start editing.

  // Ctrl is usually a hotkey on Windows
  if (event.ctrlKey) {
    return false;
  }

  // Cmd is usually a hotkey on MacOS
  if (event.metaKey) {
    return false;
  }

  // Alt is often a hotkey on Windows
  if (event.altKey) {
    return false;
  }

  // This indicates a composition event, which means we're often composing a combination character
  // something like an emoji or with diacritics (e.g. à), but it does mean that we're not done
  if (event.nativeEvent.isComposing) {
    return false;
  }

  return true;
};

export const acceptedTextKeys = (event: React.KeyboardEvent): boolean =>
  ignoreModifierKeys(event) && event.key.length === 1 && /^[a-z0-9+,.-]+$/giu.test(event.key);
export const acceptedNumericKeys = (event: React.KeyboardEvent): boolean =>
  ignoreModifierKeys(event) && event.key.length === 1 && /^[0-9+=,.-]+$/giu.test(event.key);

export type OptimisticResponse<Input, Mutation> = (vars: Exact<{ input: Input }>) => Mutation;

export type EditorInput = {
  defaultValue: any;
  id: string;
  name: string;
  autoFocus?: boolean;
  validate?: (x: string | string[]) => false | string;
  onBlur?: React.FocusEventHandler;
  onChange?: React.ChangeEventHandler;
  onFocus?: React.FocusEventHandler;
  onKeyDown?: React.KeyboardEventHandler;
};

export type UseModalEditorParams<Input, Mutation, F extends EditorInput> = {
  /**
   * This creates the optimistic response for the mutation. It needs to
   * return the same shape as the mutation's payload
   */
  optimisticResponse: (cellProps: CellProps<EditorLine>) => OptimisticResponse<Input, Mutation>;
  /**
   * The Apollo Cache Updater function
   */
  update: (cellProps: CellProps<EditorLine>) => MutationUpdaterFn<Mutation>;
  /**
   * The particular mutation hook to use
   */
  useMutationHook: (
    options?: MutationHookOptions<Mutation, Exact<{ input: Input }>>,
  ) => MutationTuple<Mutation, { input: Input }>;
  /**
   * This is the field to render in the Editor
   *
   * Note: we apply some css overrides to hide the label and the errors
   */
  Field: React.ComponentType<F> | React.ForwardRefExoticComponent<F>;
  /**
   * Gets the default value from the cell
   */
  getDefaultValue?: (cellValue: any) => any;
  /**
   * TODO rename this.
   *
   * Basically, it's a way to coerce the form value to the appropriate API value
   */
  toValue: (x: any) => any;
  /**
   * Creates the input for the mutation
   */
  createInput: UnaryFn<InputParams, Input>;
  /**
   * This is run on the regular input when typing. If the user types any valid keys, then
   * it'll start the editing process
   */
  acceptedKeysHandler?: (event: React.KeyboardEvent<HTMLDivElement>) => boolean;

  validate?: (cellProps: CellProps<EditorLine>) => (x: string | string[]) => false | string;

  /**
   * Comparison function to determine if a value has changed. Defaults to lodash's `isEqual`, which
   * is deep-equality
   */
  comparator?: (a: any, b: any) => boolean;

  /**
   * If the default value differs from the current value (e.g. the case for planned spend)
   * Then this can be used in its place
   */
  getCurrentValue?: (cellProps: CellProps<EditorLine>) => any;

  /**
   * If the editor cell should be disabled or not
   */
  shouldDisable?: (cellProps: CellProps<EditorLine>) => boolean;

  /**
   * A function that gets the current highest weight. If a line is to be added, then we'll add
   * `10` to the weight to ensure that it sticks to the bottom.
   */
  getWeight: NullaryFn<number>;
};

const popperModifiers = [
  ...disableModifiers(['arrow', 'flip', 'hide', 'offset']),
  sameHeight,
  coverElement,
];

const popperOptions = {
  placement: 'top-start' as Placement, // top-start is needed for the `coverElement` modifier to work
  popperModifiers,
};

const normalizeId = (x: string) => {
  return x
    .toLowerCase()
    .replace(/[^a-z0-9-]+/gu, '-')
    .replace(/[-]+/gu, '-');
};

export type ModalEditorHandlers = {
  onClick: React.MouseEventHandler<HTMLDivElement>;
  onKeyDown: React.KeyboardEventHandler<HTMLDivElement>;
};

const isPlaceHolder = equals(PLACEHOLDER_ID);
const isNotPlaceholder = complement(isPlaceHolder);
const always100 = always(100);

export const useModalEditor = <Input, Mutation, F extends EditorInput, Value>(
  params: UseModalEditorParams<Input, Mutation, F>,
): ModalEditorHandlers => {
  const {
    createInput,
    Field,
    optimisticResponse,
    update,
    useMutationHook,
    validate,
    getDefaultValue = identity,
    getCurrentValue,
    toValue = identity,
    acceptedKeysHandler = returnFalse,
    comparator = isEqual,
    shouldDisable = returnFalse,
    getWeight = always100,
  } = params;

  const [mutate, { error }] = useMutationHook();
  const addLine = useAddLine();
  const revisionId = useSelectedBudgetRevisionId()!;
  const editingCell = React.useRef<CellProps<EditorLine, Value> | null>(null);
  const errorModalTreeNode = React.useRef<ModalTreeNode | null>(null);
  const formRef = React.useRef<Form | null>(null);
  const modalTreeNode = React.useRef<ModalTreeNode | null>(null);
  const fieldRef = React.useRef<{ setFilter: (filter: string) => void } | null>(null);
  const queuedChanges = React.useRef<AnyFn[]>([]);
  const afterCloseRef = React.useRef<NullaryFn | null>(null);
  const autoSaveRef = React.useRef<boolean>(true);
  const alreadyNotified = React.useRef<boolean>(false);
  const formName = useDefaultOrRandom(rando());
  const createModal = useChildModal();
  const notify = useNotification();
  const getErrors = useGetLatest(error);
  const refreshPopperPlacement = React.useCallback(
    () => queueMicrotask(() => void modalTreeNode.current?.popper.current?.update()),
    [modalTreeNode],
  );

  /**
   * Always null check the form ref before calling this
   */
  const getInstantiatedField = (): FieldType => head(Object.values(formRef.current!.fields));
  /**
   * Always null check the form ref before calling this
   */
  const getFieldValue = () => getInstantiatedField().getValue();

  // TODO: Also, special case to handle arrays and objects
  const isFieldDirty = (): boolean => {
    if (!formRef.current) {
      return false;
    }

    const currentValue = getCurrentValue
      ? getCurrentValue(editingCell.current!)
      : getDefaultValue(editingCell.current!.cell.value);

    return !comparator(currentValue, toValue(getFieldValue()));
  };

  const saveValue = (): boolean => {
    if (!formRef.current) {
      // We can't save because we don't have a ref to the form to get the values, etc...
      console.error('Not saving from modal editor because we cannot find the form');
      return false;
    }

    if (Object.keys(formRef.current.fields).length !== 1) {
      // This is going to be unexpected behavior as there should be only one field in this editor
      console.error('Not saving from modal editor because an ambiguous number of fields exists');
      return false;
    }

    if (!editingCell.current) {
      // We should have reference to the individual cell...
      console.error('Not saving from modal editor because we do not have reference to the cell');
      return false;
    }

    if (!isFieldDirty()) {
      // The field hasn't changed, so we can just exit without doing anything
      return true;
    }

    const field: FieldType = getInstantiatedField();
    // Check to make sure that the field is valid....
    // @ts-expect-error: field.getValue() is typed incorrectly in the package
    const errors = field.validate(field.getValue(), true);

    if (errors.length) {
      // We have errors, so we should bail, and then we should set some errors.
      // 1 - Change the border of the cell to an error state
      // Actually, this *should* happen because of the second argument in field.validate()
      // 2 - Create a modal with the errors
      errorModalTreeNode.current?.close(); // First, close any previous error modals
      modalTreeNode.current?.closeChildren();
      modalTreeNode.current?.createChild(
        <Modal arrow className={cx('floating-box', styles.errorModal)}>
          <div className={styles.innerModal}>{errors}</div>
        </Modal>,
        {
          placement: 'top-start',

          popperModifiers: [{ name: 'offset', options: { offset: [0, 16] } }],
          // Position this above the current modal
          target: modalTreeNode.current.ref,
        },
      );
      // 3 - Bail on the update
      return false;
    }

    // So, if there previously was an error, then we'd have an error modal open. So, let's close that.
    errorModalTreeNode.current?.close();

    const cellProps = editingCell.current;
    const currentColumn = cellProps.cell.column.id;
    const currentRow = cellProps.cell.row.id;

    if (cellProps.cell.column.id === 'name' && cellProps.row.original.id === PLACEHOLDER_ID) {
      const name = toValue(field.getValue()) as string;
      addLine(name, getWeight() + 10).catch(err => {
        console.error(err);
        notify({
          body: `There was an error adding new budget line "${name}"`,
          persist: true,
          title: 'Could not add new line',
        });
        alreadyNotified.current = true;
      });

      const nextSelector = `div[data-row="${currentRow}"][data-col="${currentColumn}"]`;

      // The current dom node will be replaced, so we need to refocus when that happens...
      queueMicrotask(() => {
        const el: HTMLElement | null = document.querySelector(nextSelector);
        el?.focus();
      });
      // Close the modal optimistically
      return true;
    }

    const input = createInput({
      budgetLineId: cellProps.row.original.id,
      cellProps,
      revisionId,
      value: toValue(field.getValue()),
    });

    // Call the actual mutation
    mutate({
      // This is the cache update
      optimisticResponse: optimisticResponse(cellProps),

      // This is a query to get the optimistic response
      update: update(cellProps),

      variables: { input },
    }).catch(err => {
      console.error(err);
      const body = cellProps
        ? `There was an error saving ${cellProps.cell.column.id} on ${cellProps.row.original.name}. The value has been reverted`
        : 'There was an error and the last edit could not be saved';

      notify({
        // Give a more specific name from editingCellProps
        body,
        persist: true,
        title: 'Could not update cell',
      });
      alreadyNotified.current = true;
    });
    // Close the modal optimistically
    return true;
  };

  const onBeforeClose = () => {
    // If the field isn't direct, then we're just going to close
    if (!isFieldDirty) {
      return;
    }

    // Submit the if we have the queue to auto-save
    if (autoSaveRef.current) {
      saveValue();
    }

    const err = getErrors();
    const cellProps = editingCell.current;
    if (err && !alreadyNotified.current) {
      console.error(err);
      const body = cellProps
        ? `There was an error saving ${cellProps.cell.column.id} on ${cellProps.row.original.name}. The value has been reverted`
        : 'There was an error and the last edit could not be saved';

      notify({
        // Give a more specific name from editingCellProps
        body,
        persist: true,
        title: 'Could not update cell',
      });
    }

    // Reset all the refs
    editingCell.current = null;
    formRef.current = null;
    fieldRef.current = null;
    modalTreeNode.current = null;
    errorModalTreeNode.current = null;
    alreadyNotified.current = false;
  };

  const fieldKeyHandler = (event: React.KeyboardEvent): void => {
    if (event.key === 'Enter') {
      // So, we want to make sure that the combo box enter doesn't submit when selecting an item.
      // Originally, I wanted to evaluate this based only on `event.defaultPrevented`, but
      // the underlying form library (swan-form) handles the enter key and calls
      // `event.preventDefault` on inputs in order to avoid a form submission. We can, however,
      // detect is with an extra `isPropagationStopped`. While this solution works, it doesn't
      // feel the best
      if (event.defaultPrevented && event.isPropagationStopped()) {
        return;
      }

      event.preventDefault();

      // We're going to have to figure out a way to still use `Enter` in the other places
      if (saveValue()) {
        // This is a manual save, so turn off auto-save to avoid a double-mutation
        autoSaveRef.current = false;
        const afterCloseCallback = afterCloseRef.current ?? noop;
        modalTreeNode.current?.close(afterCloseCallback);
      }
    }

    if (event.key === 'Tab') {
      if (event.defaultPrevented) {
        return;
      }

      // Auto save will happen on close
      modalTreeNode.current?.close();
    }

    if (event.key === 'Escape') {
      if (!event.defaultPrevented) {
        autoSaveRef.current = false;
      }
    }
  };

  const enqueueChange = (event: React.KeyboardEvent<HTMLDivElement>) => {
    const change = event.key;
    queuedChanges.current.push(() => {
      queueMicrotask(() => {
        if (!fieldRef.current) {
          return;
        }

        if ('setFilter' in fieldRef.current) {
          // Handle comboboxes
          fieldRef.current.setFilter(change);
        } else {
          // Handle all other fields
          const field = getInstantiatedField();
          field.setValue(change);
        }
      });
    });
  };

  const createModalEditor = ({
    cellProps,
    target,
    restore,
  }: {
    cellProps: CellProps<EditorLine, Value>;
    target: HTMLElement;
    restore: HTMLElement;
    initialValue?: string; // An initial value to set
  }) => {
    autoSaveRef.current = true; // turn on auto-saving
    editingCell.current = cellProps;
    const { cell, row } = cellProps;
    const { column } = cell;
    const id = normalizeId([column.id, row.id].join('-'));
    const budgetLineId = cell.row.original.id;

    if (isNotPlaceholder(budgetLineId) && isTempId(budgetLineId)) {
      // Temp ID. Do not try to edit yet...
      return;
    }

    if (shouldDisable(cellProps)) {
      return;
    }

    if (isPlaceHolder(budgetLineId)) {
      // This is a placeholder, which means that if it isn't the
      // name column, then we need to focus on the name column....
      if (column.id !== 'name') {
        const el = document.querySelector(
          `div[data-row-id="${PLACEHOLDER_ID}"] div[data-col="name"]`,
        );

        el?.dispatchEvent(
          new MouseEvent('click', {
            bubbles: true,
            cancelable: true,
            view: window,
          }),
        );
        return;
      }
    }

    // This is mocked because we're don't have a submit button for these
    const onSubmit = async (values: Record<string, any>) => Promise.resolve(values);

    const onError = (err: any) => console.error(err);

    const modalOptions = {
      onAfterClose: () => isHTMLElement(restore) && restore.focus(),
      onAfterOpen: [
        refreshPopperPlacement,
        ...queuedChanges.current,
        () => {
          queuedChanges.current = [];
        },
      ],
      onBeforeClose,
      target,
      ...popperOptions,
    };

    const validateFn = validate ? validate(cellProps) : undefined;

    modalTreeNode.current = createModal(
      <Modal clickAway="close" escape="close" overlay="transparent">
        <Form
          ref={formRef}
          autoComplete={false}
          className={styles.form}
          name={formName}
          onError={onError}
          onSubmit={onSubmit}
        >
          <Field
            ref={fieldRef}
            autoFocus
            className={styles.overrideField}
            defaultValue={getDefaultValue(cell.value)}
            id={id}
            name={id}
            validate={validateFn}
            onKeyDown={fieldKeyHandler}
          />
        </Form>
      </Modal>,
      modalOptions,
    );
  };

  // So, with this, we should accept a renderer and abstract away all the error handling
  // and all the render logic...
  const handleClick = (event: React.MouseEvent, cellProps: CellProps<EditorLine, Value>) => {
    editingCell.current = cellProps;
    afterCloseRef.current = advanceToNextRow(event);
    const { currentTarget } = event;
    createModalEditor({
      cellProps,
      restore: currentTarget as HTMLElement,
      target: currentTarget as HTMLElement,
    });
  };

  const handleKeyDown = (
    event: React.KeyboardEvent<HTMLDivElement>,
    cellProps: CellProps<EditorLine, Value>,
  ) => {
    if (event.defaultPrevented) {
      return;
    }

    if (event.key === 'Enter' || event.key === 'F2') {
      afterCloseRef.current = advanceToNextRow(event);
      event.preventDefault();
      editingCell.current = cellProps;
      const { currentTarget } = event;
      createModalEditor({
        cellProps,
        restore: currentTarget,
        target: currentTarget as HTMLElement,
      });
    }

    if (acceptedKeysHandler(event)) {
      afterCloseRef.current = advanceToNextRow(event);
      event.preventDefault();
      enqueueChange(event);
      editingCell.current = cellProps;
      const { currentTarget } = event;
      createModalEditor({
        cellProps,
        restore: currentTarget,
        target: currentTarget as HTMLElement,
      });
    }
  };

  return {
    onClick: handleClick,
    onKeyDown: handleKeyDown,
  };
};
