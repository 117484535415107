/* eslint-disable max-lines-per-function */
import * as React from 'react';

import { cx } from '@cobbler-io/utils/src/cx';
import { noop } from '@cobbler-io/utils/src/noop';

import { tzAdjust } from '@cobbler-io/dates/src/tzAdjust';

import { Button } from '@cobbler-io/core-ui/src/Button';
import { DatePickerContext } from '@cobbler-io/core-ui/src/DatePickerProvider';
import { Icon } from '@cobbler-io/core-ui/src/Icon';

import { dateFromYYYYMM, dateToYYYYMM, YYYYMMAreEqual } from './utils';

import styles from './MonthCalendar.scss';

export type MonthCalendarProps = {
  first?: boolean;
  isDouble: boolean;
};

const shortMonthName = (date: Date) =>
  new Intl.DateTimeFormat(undefined, { month: 'short' }).format(date);

const longMonthName = (date: Date) =>
  new Intl.DateTimeFormat(undefined, { month: 'long' }).format(date);

type GetCellStylesParams = {
  start: Date | null;
  end: Date | null;
  current: Date;
  chooseable: boolean;
  isRange: boolean;
  isDecorated: boolean;
};
// eslint-disable-next-line max-params
const getCellStyles = ({
  start,
  end,
  current,
  chooseable,
  isRange,
  isDecorated,
}: GetCellStylesParams): string => {
  const isStart = start && YYYYMMAreEqual(start, current) && styles.start;
  const isEnd = end && YYYYMMAreEqual(end, current) && styles.end;
  const isBetween = start && end && start < current && current < end && styles.between;

  return cx(
    // Base class
    styles.cell,
    // For a selected start date
    isStart && styles.start,
    // Between selected start and end dates
    isRange && !(isStart || isEnd) && isBetween && styles.between,
    // Select end date
    (isRange ? isEnd : isStart) && styles.end,
    // For a decorated date
    isDecorated && styles.decorated,

    !chooseable && styles.disabled,
  );
};

/**
 * Just the calendar portion of the MonthPicker
 *
 * Note: not designed to be used outside of the MonthPicker
 */
export const MonthCalendar = (props: MonthCalendarProps): JSX.Element => {
  const { first = true, isDouble } = props;
  const {
    end,
    hasNextPage,
    hasPrevPage,
    nextPage,
    prevPage,
    range,
    select,
    start,
    viewDate,
    isRange,
    decorate,
  } = React.useContext(DatePickerContext);
  const decorateDates = Array.from(decorate).map(d => {
    const date = tzAdjust(d);
    return {
      month: date.getMonth(),
      year: date.getFullYear(),
    };
  });

  const current = new Date(viewDate.getFullYear(), 0, 15);

  if (!first) {
    current.setFullYear(current.getFullYear() + 1);
  }

  const year = current.getFullYear();

  const onClick: React.MouseEventHandler<HTMLButtonElement> = event => {
    const { name } = event?.currentTarget;
    select(dateFromYYYYMM(name));
  };

  const prevButton = (
    <Button
      small
      aria-label={`see ${year - 1}`}
      disabled={!hasPrevPage}
      name="prev-year"
      tabIndex={hasPrevPage ? 0 : -1}
      variant="svg"
      onClick={prevPage ?? noop}
    >
      <Icon type={hasPrevPage ? 'chevronFilledLeft' : 'blank'} />
    </Button>
  );

  const nextButton = (
    <Button
      small
      aria-label={`see ${year - 1}`}
      disabled={!hasNextPage}
      name="next-year"
      tabIndex={hasNextPage ? 0 : -1}
      variant="svg"
      onClick={nextPage ?? noop}
    >
      <Icon type={hasNextPage ? 'chevronFilledRight' : 'blank'} />
    </Button>
  );

  return (
    <div className={cx(styles.calendar, isDouble ? styles.double : styles.single)}>
      {isDouble ? (
        <div className={styles.header}>
          {first && prevButton}
          <span>{year}</span>
          {!first && nextButton}
        </div>
      ) : (
        <div className={styles.header}>
          {prevButton}
          <span>{year}</span>
          {nextButton}
        </div>
      )}

      <div className={styles.body}>
        {Array.from({ length: 12 }).map((_, month) => {
          const isDecorated = decorateDates.some(
            date => date.year === year && date.month === month,
          );
          // Change the month so we can use the date formatter to get the month name
          current.setMonth(month);
          const name = shortMonthName(current);
          const isValid = range.includes(current);

          const appliedStyles = getCellStyles({
            chooseable: isValid,
            current,
            end,
            isDecorated,
            isRange,
            start,
          });

          return (
            <Button
              key={name}
              small
              aria-label={`select ${longMonthName(new Date(year, month))} ${year}`}
              className={appliedStyles}
              disabled={!isValid}
              name={dateToYYYYMM(current)}
              variant="text"
              onClick={onClick}
            >
              {name}
            </Button>
          );
        })}
      </div>
    </div>
  );
};

MonthCalendar.displayName = 'MonthCalendar';
