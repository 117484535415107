import * as React from 'react';

import Button from '@cobbler-io/core-ui/src/Button';
import { Field } from '@cobbler-io/core-ui/src/Field';

import { UserType } from '@cobbler-io/app/src/gql-queries';

import { Form } from '@swan-form/form';

import { UserDropdown, UserDropdownError } from '../UserDropdown';

import styles from './DiscussionInput.scss';

type Props = {
  disabled?: boolean;
  defaultValue?: string | null;
  placeholder?: string | null;
  assignableToUsers?: readonly UserType[] | null;
  onCancel?: () => void;
  onSave: (textContent: string, assignedToUserId?: string) => any;
};

export const DiscussionInput = (props: Props) => {
  const {
    placeholder = '',
    assignableToUsers,
    disabled = false,
    onCancel,
    onSave,
    defaultValue,
  } = props;

  const transformUserOptions = (items: { label: string; value: any }[]) => [
    { label: 'Unassigned', value: '@@__unassigned', id: '@@__unassigned' },
    ...items,
  ];

  const [formErrors, setFormErrors] = React.useState('');

  return (
    <div className={styles.discussionInput}>
      <Form
        autoComplete={false}
        name="comment-form"
        onSubmit={(values: Record<string, string>) => {
          if (values.textContent.trim()) {
            return onSave(values.textContent, values.assignedTo);
          }
          return setFormErrors('Please provide a comment to start a discussion.');
        }}
      >
        <Field
          className={styles.textInput}
          defaultValue={defaultValue}
          disabled={disabled}
          name="textContent"
          placeholder={placeholder}
          rows="4"
          type="textarea"
        />

        {Boolean(formErrors) && <span className={styles.errors}>{formErrors}</span>}

        {assignableToUsers === null ? (
          <UserDropdownError />
        ) : (
          assignableToUsers && (
            <UserDropdown
              fallbackFunction={null}
              id="assignedTo"
              label="Assign to"
              name="assignedTo"
              placeholder="Unassigned"
              returnType="id"
              transformOptions={transformUserOptions}
              users={assignableToUsers}
            />
          )
        )}
        <div className={styles.actions}>
          {onCancel && (
            <Button
              disabled={disabled}
              name="cancel"
              type="button"
              variant="outline"
              onClick={onCancel}
            >
              Cancel
            </Button>
          )}
          <Button disabled={disabled} name="save" type="submit">
            Save
          </Button>
        </div>
      </Form>
    </div>
  );
};

DiscussionInput.displayName = 'DiscussionInput';

export default DiscussionInput;
