import React from 'react';

import {
  BudgetLineEditorDataQuery, BudgetLineEditorDataQueryResult, BudgetLineTypeEnum,
} from '@cobbler-io/app/src/api/graphql-types';

export type EditorLine = NonNullable<
  BudgetLineEditorDataQueryResult['data']
>['a_budgetLineChildrenEditorData']['lines'][number] & {
  systemLine?: {
    isSystemLine: boolean;
    actualsModalBehavior?: 'deep' | 'shallow';
    tip: React.ReactNode;
  };
};

// Nomenclature:
// Available is how much is available in the parent.
// Spent is how much is available spent aganist the parent.
// Allocated is how much is planned in children.
// Unallocated is the Planned - Allocated.

export type Totals = {
  /**
   * How big is the amount of potential money to be allocated?
   * Planned in parent
   */
  available: MinorCurrency;
  /**
   * How much money has been spoken for
   * Planned in children
   */
  allocated: MinorCurrency;
  /**
   * How much money has not been spoken for
   * available - allocated.
   * For normal (not root) budget lines, `unallocated` will be used by expenses and revenues.
   * Root budget lines are a special case: `unallocated` will contain the unallocated expense amount,
   * and `revenueUnallocated` will contain the unallocated revenue amount
   */
  unallocated: MinorCurrency;
  /**
   * How much revenue money is unallocated.
   * Only used by root budget lines.
   */
  revenueUnallocated?: MinorCurrency;
  /**
   * How much money has been spent (deep)
   */
  spent: MinorCurrency;
};

export type Interval = {
  /**
   * @deprecated Use `start`
   */
  key: string; // TODO: Prob not needed
  name: string; // TODO: Prob not needed
  start: ISO8601String;
  end: ISO8601String;
};

export type IntervalAllocation = {
  start: ISO8601String;
  allocated: MinorCurrency;
};

export type EditorData = NonNullable<BudgetLineEditorDataQuery['a_budgetLineChildrenEditorData']>;
export type EditorDataLine = EditorData['selfLine'];
export type Ancestor = EditorDataLine['ancestors'][number];
export type Owner = NonNullable<EditorDataLine['owner']>['item'];
export type Account = EditorDataLine['accounts'][number];
export type Department = EditorDataLine['departments'][number];
export type Vendor = EditorDataLine['vendors'][number];
export type Permissions = EditorDataLine['permissions'];
export type IntervalPlannedSpend = EditorDataLine['plannedSpend'][number];

// TODO: Replace planned with allocated
export type ChildBudgetLineAtRevision = {
  id: BudgetLineId;
  name: string;
  type: BudgetLineTypeEnum;
  isRevenue: boolean;
  ancestors: readonly Ancestor[]; // TODO: Prob not needed (or maybe permissions not needed)
  childrenCount: number;
  owner?: Owner;
  accounts: Account[];
  departments: Department[];
  vendors: Vendor[];
  parentId: BudgetLineId;
  intervalsByKey: Record<ISO8601String, Interval>;
  plannedByInterval: Record<ISO8601String, IntervalAllocation>;
  revisionId: BudgetRevisionId;
  totalAllocated: MinorCurrency;
};

export type BudgetLineAtRevision = {
  id: BudgetLineId;
  type: BudgetLineTypeEnum;
  isRevenue: boolean;
  name: string;
  ancestors: readonly Ancestor[]; // TODO: Prob not needed (or maybe permissions not needed)
  budgetResolution: 'MONTH'; // TODO: Left for compatibility. Delete when it stops being referenced
  // revisions: { name: string; id: string }[]; // TODO: Prob not needed
  // originalRevisionId: BudgetRevisionId;
  isForecast: boolean;
  permissions: Permissions;
  revisionId: BudgetRevisionId;
  owner: Owner | null;
  accounts: Account[];
  departments: Department[];
  vendors: Vendor[];
  planned: IntervalAllocation[];
  plannedByInterval: Record<ISO8601String, IntervalAllocation>;
  start: ISO8601String;
  end: ISO8601String;
  parentId: BudgetLineId | undefined;
  parentName: string | undefined;
  fiscalYearStartMonth: ServerMonth;
  // parent
  rootId: BudgetLineId;
  isRoot: boolean;
  intervals: Interval[];
  totalsByInterval: Record<ISO8601String, Totals>;
  totals: Totals;
  lines: ChildBudgetLineAtRevision[];
  weight: number;
};

export enum BudgetLineType {
  ROOT = 'ROOT',
  EXPENSE = 'EXPENSE',
  REVENUE = 'REVENUE',
}
