import * as React from 'react';

import { cx } from '@cobbler-io/utils/src/cx';

import { Avatar } from '@cobbler-io/core-ui/src/Avatar';
import { UserType } from '@cobbler-io/core-ui/src/UserDisplay';

import styles from './Persona.scss';

type PersonaProps = {
  /**
   * The User object to display
   */
  user: UserType;
  /**
   * Optional: if `currentUserId === user.id`, then the fullname is appended with `(You)`
   */
  currentUserId?: string;

  inline?: boolean;

  inactive?: boolean;
};

export const Persona = (props: PersonaProps & React.HTMLProps<HTMLDivElement>): JSX.Element => {
  const { id, user, currentUserId, inline = false, inactive = false, className, style } = props;
  const userName = user.fullName ?? user.email;
  const name = currentUserId && currentUserId === id ? `${userName} (You)` : userName;
  const Tag = inline ? 'span' : 'div';
  return (
    <Tag className={cx(styles.persona, className)} style={style}>
      <Avatar user={user} />
      <span className={cx(styles.name, inactive && styles.inactive)}>{name}</span>{' '}
      {
        // TODO: Make this a component
        inactive && <span className={styles.badge}>Inactive</span>
      }
    </Tag>
  );
};
Persona.displayName = 'Persona';

export default Persona;
