import { isHalf } from './isHalf';
import { isOdd } from './isOdd';

/**
 * Rounding to the nearest even number. Also known as Banker's rounding
 * or Gaussian rounding.
 *
 * https://en.wikipedia.org/wiki/Rounding#Round_half_to_even
 *
 * @param number The number to round
 * @param decimals The number of decimal places to include
 *
 */
export const bankersRound = (value: number, decimals: number = 0): number => {
  const modifier = 10 ** decimals;
  const shifted = value * modifier;
  const rounded = Math.round(shifted);
  const multiplier = rounded < 0 ? 1 : -1;
  const adjustment = isHalf(shifted) && isOdd(rounded) ? 1 : 0;
  return (rounded + adjustment * multiplier) / modifier;
};
