import * as React from 'react';

import { useInstanceVars } from '@cobbler-io/hooks/src/useInstanceVars';

import { CloseButton } from '@cobbler-io/core-ui/src/CloseButton';
import { Modal, useModal } from '@cobbler-io/core-ui/src/Modal';

import {
  CurrentRangeProvider, useCurrentRange,
} from '@cobbler-io/app/src/providers/CurrentRangeProvider';

import { equals } from 'ramda';

import { UpdateChildBudgetLineForm } from './UpdateChildBudgetLineForm';

export const useEditBudgetLines = (ids: BudgetLineId[]) => {
  const { create: createModal } = useModal();
  const self = useInstanceVars({ ids, idQueue: ids });
  const rangeCtx = useCurrentRange();

  React.useEffect(() => {
    if (!equals(ids, self.ids)) {
      self.idQueue = ids;
      self.ids = ids;
    }
  }, [ids, self]);

  const editBudgetLine = React.useCallback(() => {
    const [id, ...queue] = self.idQueue;

    createModal(
      <Modal overlay className="centered box-shadow-standard" clickAway="none">
        <CloseButton />
        <CurrentRangeProvider
          defaultEnd={rangeCtx.end}
          defaultMax={rangeCtx.max}
          defaultMin={rangeCtx.min}
          defaultPeriodLength="MONTH"
          defaultStart={rangeCtx.start}
        >
          <UpdateChildBudgetLineForm
            updateAnother={queue.length ? editBudgetLine : undefined}
            updateBudgetLineId={id}
          />
        </CurrentRangeProvider>
      </Modal>,
    );
    self.idQueue = queue;

    if (!queue.length) {
      self.idQueue = self.ids;
    }
  }, [createModal, self, rangeCtx]);

  return editBudgetLine;
};
