/* eslint-disable @typescript-eslint/unbound-method */
import { pad0 } from '@cobbler-io/utils/src/pad0';

// None of these are used yet, but we should transition to using these ones instead on the
// ones in `@cobbler-io/formatters/src/index.ts`. Part of that transition will also include
// treating all dates as UTC and removing the need to do tzAdjust on dates.

export const dateFormatter: Intl.DateTimeFormat =
  new Intl.DateTimeFormat(undefined, {
    timeZone: 'utc',
  });

// export const dateFormatterWithZeros = new Intl.DateTimeFormat(undefined, {
//   timeZone: 'utc',
//   month: '2-digit',
//   year: '2-digit',
//   day: '2-digit',
// });

export const fullDateFormatter: Intl.DateTimeFormat =
  new Intl.DateTimeFormat(undefined, {
    day: 'numeric',
    month: 'short',
    timeZone: 'utc',
    year: 'numeric',
  });

export const shortMonthFormatter: Intl.DateTimeFormat =
  new Intl.DateTimeFormat(undefined, {
    month: 'short',
    timeZone: 'utc',
  });

export const shortMonthYearFormatter: Intl.DateTimeFormat =
  new Intl.DateTimeFormat(undefined, {
    month: 'short',
    timeZone: 'utc',
    year: 'numeric',
  });

export const shortLocalMonthYearFormatter: Intl.DateTimeFormat =
  new Intl.DateTimeFormat(undefined, {
    month: 'short',
    year: 'numeric',
  });

export const monthDateFormatter: Intl.DateTimeFormat =
  new Intl.DateTimeFormat(undefined, {
    day: '2-digit',
    month: 'short',
    timeZone: 'utc',
  });

export const prettyLocalDateFormatter: Intl.DateTimeFormat =
  new Intl.DateTimeFormat(undefined, {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
  });

export const prettyDateFormatter: Intl.DateTimeFormat =
  new Intl.DateTimeFormat(undefined, {
    day: '2-digit',
    month: 'short',
    timeZone: 'utc',
    year: 'numeric',
  });

/**
 * Depending on locale: `1/20/2020`, `2/18/1992`, `3/21/2021` (for en-US)...
 */
export const date = dateFormatter.format;

/**
 * `Jan`, `Feb`, `Mar`, `Apr`...
 */
export const shortMonth = shortMonthFormatter.format;

/**
 * `Jan 2022`, `Feb 2022`, `Mar 2022`...
 *
 * Takes a UTC date
 */
export const shortMonthYear = shortMonthYearFormatter.format;

/**
 * `Jan 2022`, `Feb 2022`, `Mar 2022`...
 *
 * Takes a LocalDate
 */
export const shortLocalMonthYear = shortLocalMonthYearFormatter.format;

/**
 * `Jan 2022`
 */
export const shortMonthDate = monthDateFormatter.format;

/**
 * `Jan 01`
 */
export const fullDate = fullDateFormatter.format;

/**
 * Standard ISO8601 dates
 *
 * `2020-11-03` (YYYY-MM-DD)
 *
 * Date is assumed to be in UTC
 */
export const ISO8601 = (input: Date): ISO8601String =>
  [input.getFullYear(), pad0(input.getMonth() + 1), pad0(input.getDate())].join('-');

/**
 * `Jan 01, 2022`
*/
export const prettyLocalDate = (x: Date): string => prettyLocalDateFormatter.format(x);

/**
 * `Jan 01, 2022'
 */
export const prettyDate = (x: Date): string => prettyDateFormatter.format(x);
