/* eslint-disable sort-keys, sort-keys-fix/sort-keys-fix */
import { Graph, Vertex } from '@cobbler-io/collection/src/Graph';

import { HydrateBudgetGraphQuery } from '../graphql-types';
import { getDepartmentChildId } from './utils';

type Data = NonNullable<NonNullable<HydrateBudgetGraphQuery['departments']>['items']>;

type HydrateDepartmentsParams = {
  graph: Graph<any, any>;
  data: Data;
};

// Pull these to a higher scope so that we don't have to garbage collect as many of them
const childDepartmentLabels = ['DEPARTMENT'];
const rootDepartmentLabels = ['DEPARTMENT', 'DEPARTMENT_ROOT'];

export const hydrateDepartments = (params: HydrateDepartmentsParams): void => {
  // We're going to do a single pass to create all the vertices, and then we'll do another
  // pass to connect them in a tree. So, we'll store the eventual connections here
  const queue = new Map<string, Set<Vertex>>();

  const { graph, data } = params;

  // Cycle through the responses and create vertexes
  for (const department of data) {
    const labels = department.parentId ? childDepartmentLabels : rootDepartmentLabels;
    const { id, parentId = null } = department;

    const vertex = graph.addVertex({
      id,
      labels,
      properties: { name: department.name },
    });

    // If there is a parent Id, then we want to store it in the queue
    if (parentId) {
      if (!queue.has(parentId)) {
        // A parent can have multiple children, so we need to instantiate it with a set to store all
        queue.set(parentId, new Set());
      }

      queue.get(parentId)!.add(vertex);
    }
  }

  for (const [parentId, children] of queue) {
    const parent = graph.getVertexById(parentId);

    if (!parent) {
      console.error('Could not find parent department vertex', parentId);
      continue; // eslint-disable-line no-continue
    }

    for (const child of children) {
      graph.addEdge({
        id: getDepartmentChildId(parent.id, child.id),
        from: parent,
        to: child,
        labels: ['HAS_SUB_DEPARTMENT'],
        properties: {},
      });
    }
  }
};
