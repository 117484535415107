import { DateRange } from '@cobbler-io/dates/src';

import { CsvMetadataDataType } from '@cobbler-io/app/src/api/graphql-types';

import { Edge, Graph, Vertex } from '@cobbler-io/collection/src/Graph';

import { RevisionId } from '../';

/* Vertex Types */

type TimeVertex = 'month' | 'quarter' | 'fiscal-year';

export type TimeProperties = {
  name: string;
  shortName: string;
  end: string;
  endDate: LocalDate;
  range: DateRange;
  start: string;
  startDate: LocalDate;
  type: TimeVertex;
};

export type MonthVertex = Vertex<TimeProperties>;

export type QuarterVertex = Vertex<TimeProperties>;

export type FiscalYearVertex = Vertex<TimeProperties>;

export type BudgetLineVertex = Vertex<{
  name: string;
  openDiscussionCount: number;
  permissions: number;
  weight: number;
}>;

/**
 * Represents what is spent in a month
 *
 * This is a computed field from the server
 */
export type ActualAggregateVertex = Vertex<{
  accrual: { shallow: MinorCurrency; deep: MinorCurrency };
  normal: { shallow: MinorCurrency; deep: MinorCurrency };
  summary: { shallow: MinorCurrency; deep: MinorCurrency };

  budgetLineId: BudgetLineId;

  start: ISO8601Month;
}>;

export type ActualVertex = Vertex<any>;

export type DepartmentVertex = Vertex<{ name: string }>;

export type AccountVertex = Vertex<{ name: string; code: string }>;

export type VendorVertex = Vertex<{ name: string }>;

export type RevisionVertex = Vertex<any>;

export type SpendPlanVertex = Vertex<{
  allocated: MinorCurrency;
  available: MinorCurrency;
  budgetLineId: BudgetLineId;
  budgetRevisionId: RevisionId;
  overplanned: boolean;
  planned: MinorCurrency;
  start: ISO8601String;
}>;

export type PlanningVersionVertex = Vertex<{ start: ISO8601String; end: ISO8601String }>;

export type BudgetVertex = Vertex<any>;

export type UserVertex = Vertex<{
  deleted: boolean;
  email: string;
  fullName: string | null;
  preferredName: string | null;
  profileImageUrl: string | null;
}>;

export type ProposedChangeVertex = Vertex<{
  amount: MinorCurrency;
  diff: MinorCurrency;
}>;

export type PascalCase<T extends string> = string extends T
  ? string
  : T extends `${infer F1}${infer R}`
  ? `${Uppercase<F1>}${Lowercase<R>}`
  : '';

type ColumnType = PascalCase<CsvMetadataDataType>;

export type DataSetColumn = {
  id: string;
  displayName: string;
  index: number;
  name: string;
  type: ColumnType;
};

export type DataSetVertexProperties = {
  name: string;
  description: string;
  columns: DataSetColumn[];
};

export type DataSetVertex = Vertex<DataSetVertexProperties>;

export type DataSetColumnVertexProperties = {
  displayName: string;
  index: number;
  name: string;
  type: PascalCase<CsvMetadataDataType>;
};

export type DataSetColumnVertex = Vertex<DataSetColumnVertexProperties>;

/**
 * The Edge drawn from a Proposed Change to a Planned Spend
 */
export type ProposedChangeEdge = Edge<
  ProposedChangeVertex,
  SpendPlanVertex,
  {
    budgetLineId: BudgetLineId;
    budgetRevisionId: BudgetRevisionId;
    month: ISO8601Month;
  }
>;

/* Edge Types */

export type MonthInQuarterEdge = Edge<QuarterVertex, MonthVertex, UnknownObject>;

export type QuarterInYearEdge = Edge<FiscalYearVertex, QuarterVertex, UnknownObject>;

export type MonthInYearEdge = Edge<FiscalYearVertex, MonthVertex, UnknownObject>;

/**
 * @label HAS_CHILD_LINE
 */
export type BudgetLineChildEdge = Edge<BudgetLineVertex, BudgetLineVertex, UnknownObject>;

/**
 * @label HAS_CHILD_ACCOUNT
 */
export type AccountChildEdge = Edge<AccountVertex, AccountVertex, UnknownObject>;

/**
 * @label HAS_DEPARTMENT
 */
export type SpendPlanDeptEdge = Edge<SpendPlanVertex, DepartmentVertex, UnknownObject>;

/**
 * @label HAS_ACCOUNT
 */
export type SpendPlanAcctEdge = Edge<SpendPlanVertex, AccountVertex, UnknownObject>;

/**
 * @label HAS_VENDOR
 */
export type SpendPlanVendorEdge = Edge<SpendPlanVertex, VendorVertex, UnknownObject>;

/**
 * @label IN_REVISION
 */
export type SpendPlanRevEdge = Edge<SpendPlanVertex, RevisionVertex, UnknownObject>;

/**
 * @label OWNS_PLAN
 */
export type SpendPlanOwnerEdge = Edge<UserVertex, SpendPlanVertex, UnknownObject>;

/* Combined types */

export type UberGraphVertex =
  | AccountVertex
  | ActualVertex
  | ActualAggregateVertex
  | BudgetVertex
  | BudgetLineVertex
  | DepartmentVertex
  | FiscalYearVertex
  | MonthVertex
  | PlanningVersionVertex
  | QuarterVertex
  | RevisionVertex
  | SpendPlanVertex
  | VendorVertex
  | UserVertex;

export type UberGraphEdge =
  | AccountChildEdge
  | BudgetLineChildEdge
  | MonthInQuarterEdge
  | MonthInYearEdge
  | QuarterInYearEdge
  | SpendPlanAcctEdge
  | SpendPlanDeptEdge
  | SpendPlanOwnerEdge
  | SpendPlanRevEdge
  | SpendPlanVendorEdge;

/**
 * The main graph that acts as our memory
 */
export const db = new Graph<UberGraphVertex, UberGraphEdge>();
