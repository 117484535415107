import { createNamedContext } from '@cobbler-io/utils/src/createNamedContext';

import { IconTypes } from '@cobbler-io/core-ui/src/Icon';

export type ExpandableTreeContextType = {
  expand: (id: string | string[]) => void;
  collapse: (id: string | string[]) => void;
  toggle: (id: string | string[]) => void;
  isExpanded: (id: string) => boolean;
  expandedIcon: IconTypes;
  collapsedIcon: IconTypes;
  leafIcon: IconTypes;
};

export const ExpandableTreeContext = createNamedContext<ExpandableTreeContextType>(
  'ExpandableTreeContext',
  {
    // register: () => () => void,
    expand: () => {},
    collapse: () => {},
    toggle: () => {},
    isExpanded: () => false,
    expandedIcon: 'minus',
    collapsedIcon: 'plus',
    leafIcon: 'blank',
  },
);

export default ExpandableTreeContext;
