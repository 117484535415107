import * as React from 'react';

import { cx } from '@cobbler-io/utils/src';
import { truncate } from '@cobbler-io/utils/src/string';

import { asField } from '@swan-form/field';

import { Icon } from '../Icon';

import styles from './FileField.scss';

const MAX_FILE_NAME_LENGTH = 23;

type FileFieldRenderProps = {
  className?: string;
  label: React.ReactNode;
  name: string;
  id?: string;
  accept?: string[];
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  setRef?: (el: HTMLInputElement | null) => void;
  value?: string | FileList;
  hasFormSubmitted?: boolean;
  isFormSubmitting?: boolean;
  setValue?: (value: any) => void;
  hideFileName?: boolean;
  hideIcon?: boolean;
  variant?: 'outline' | 'text'; // Add more variants here as needed...
};

const getFileName = (fileList: FileList | string) => {
  if (fileList === '') {
    return 'No file chosen';
  }

  if (fileList instanceof FileList) {
    const len = fileList.length;
    return len === 1 ? fileList[0].name : 'Multiple files selected';
  }

  if (typeof fileList === 'string') {
    return fileList.replace('C:\\fakepath\\', '');
  }

  return '';
};

export const FileFieldRender = (props: FileFieldRenderProps): JSX.Element => {
  const {
    label,
    name,
    id,
    onChange,
    value = '',
    setRef,
    accept,
    hideFileName = false,
    hasFormSubmitted, // remove from spread props
    isFormSubmitting, // remove from spread props
    setValue, // remove from spread props
    hideIcon = false,
    variant = 'outline',
    className,
    ...rest
  } = props;

  const outline = variant === 'outline';

  return (
    <div className={cx(styles.container, className)}>
      <label className={cx(outline && styles.outline)} htmlFor={id ?? name}>
        {!hideIcon && outline && (
          <>
            <Icon className={styles.icon} size={16} type="upload" />{' '}
          </>
        )}
        <span className={styles.inputField}>
          <input
            {...rest}
            ref={setRef}
            accept={accept?.join(',')}
            id={id ?? name}
            name={name}
            type="file"
            onChange={onChange}
          />
        </span>
        {label ?? 'Choose File'}
      </label>
      {!hideFileName && outline && (
        <span>{truncate(MAX_FILE_NAME_LENGTH, getFileName(value))}</span>
      )}
    </div>
  );
};

FileFieldRender.displayName = 'FileFieldRender';

export const FileField = asField(FileFieldRender);
FileField.displayName = 'FileField';

export default FileField;
