import * as React from 'react';

import { takeWhile } from '@cobbler-io/utils/src/takeWhile';

import { Radios } from '@cobbler-io/core-ui/src/Field';
import { Heading } from '@cobbler-io/core-ui/src/Heading';

import { Row, TableInstance } from 'react-table';

import styles from './PreviewExport.scss';

const PREVIEW_SIZE = 7;

export type PreviewExportProps = {
  count: number;
  getData: (columns: Record<string, boolean>, rows: Row[]) => (string | number | null)[][];
  getInstance: NullaryFn<TableInstance>;
  getFormValues: NullaryFn<Record<string, boolean>>;
};

const fileOptions = ['xlsx', 'csv', 'ods'].map(x => ({ label: x, value: x }));

export const PreviewExport = (props: PreviewExportProps): JSX.Element => {
  const { count, getData, getInstance, getFormValues } = props;
  const [val, setValue] = React.useState(fileOptions[0].value); // womp. I have to make this controlled
  const { prepareRow, isRowExportable } = getInstance();

  const forPreview = takeWhile(
    PREVIEW_SIZE,
    row => {
      prepareRow(row);
      return isRowExportable(row);
    },
    getInstance().rows,
  );

  const [headers, ...data] = getData(getFormValues(), forPreview);

  return (
    <>
      <Heading>Exporting {count} rows</Heading>
      <Heading as="h3" size="body-large" weight="bold">
        Preview
      </Heading>
      <table className={styles.table}>
        <thead>
          <tr>
            {headers.map(x => (
              <th key={x}>{x}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map(x => (
            <tr key={x[0]}>
              {x.map((c, i) => (
                <td key={headers[i]}>{c}</td>
              ))}
            </tr>
          ))}
        </tbody>
        {count - data.length > 0 && (
          <tfoot>
            <tr>
              <td colSpan={headers.length}>...and {count - data.length} more</td>
            </tr>
          </tfoot>
        )}
      </table>
      <br />
      <Radios
        inline
        label="Export as..."
        name="__filetype"
        options={fileOptions}
        value={val}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setValue(event.target.value)}
      />
    </>
  );
};

PreviewExport.displayName = 'PreviewExport';
