import gql from 'graphql-tag';

export const UPDATE_SELF = gql`
  mutation UserUpdateSelf($input: UpdateSelfUserInput!) {
    updateSelf(input: $input) {
      id
      email
      fullName
      preferredName
      profileImageUrl
    }
  }
`;

export const ACCEPT_TOS = gql`
  mutation AcceptTOS {
    acceptTermsOfService
  }
`;
