/* eslint-disable no-bitwise */
import { DateLike } from './isDateLike';

export const getQuarter = (date: DateLike, startMonth = 0): number => {
  const d = new Date(date);
  const adjusted = d.getMonth() - startMonth;
  const q = adjusted < 0 ? 11 - ~adjusted : adjusted;
  const quarter = Math.floor(q / 3) + 1;

  return quarter;
};
