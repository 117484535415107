import { getMonthsInPeriod } from '@cobbler-io/utils/src/fiscal-year-dates';

import { ISO8601, shortLocalMonthYear } from '@cobbler-io/formatters/src/dates';

import { DateRange } from '@cobbler-io/dates/src';
import { getFiscalYearsInPeriod } from '@cobbler-io/dates/src/getFiscalYearsInPeriod';
import { getHalvesInPeriod } from '@cobbler-io/dates/src/getHalvesInPeriod';
import { getQuartersInPeriod } from '@cobbler-io/dates/src/getQuartersInPeriod';

export type PeriodLength = 'MONTH' | 'QUARTER' | 'HALF' | 'YEAR';

export type Period = {
  /**
   * An ISO-8601 string of the start date
   */
  key: ISO8601String;
  name: string;
  length: PeriodLength;
  start: LocalDate;
  end: LocalDate;
  startString: ISO8601String;
  endString: ISO8601String;
  range: DateRange;
  /**
   * The keys of all the months in the period
   */
  months: ISO8601String[];
};

type PeriodsInRangeParams = {
  start: LocalDate;
  end: LocalDate;
  periodLength: PeriodLength;
  /**
   * Fiscal Year Start Month. Use 0 for Jan
   */
  fysm: number;
};

const createCacheKey = (params: PeriodsInRangeParams) => {
  const { periodLength = 'MONTH', start, end } = params;

  return [periodLength, ISO8601(start), ISO8601(end)].join('-');
};

const cache = new Map<string, Period[]>();

/**
 * Gets the periods in a particular range.
 *
 * Expands the period for partial results (e.g. Jan 15 - Feb 22 on Month will return all of Jan and Feb)
 */
export const periodsInRange = (params: PeriodsInRangeParams): Period[] => {
  const { periodLength = 'MONTH' } = params;
  const key = createCacheKey(params);

  if (cache.has(key)) {
    return cache.get(key)!;
  }

  switch (periodLength) {
    case 'MONTH': {
      const periods = getMonthsInPeriod({
        end: params.end,
        formatter: shortLocalMonthYear,
        start: params.start,
      }).map(({ start, end, name }) => ({
        end,
        endString: ISO8601(end),
        key: ISO8601(start),
        length: 'MONTH',
        months: [ISO8601(start)],
        name,
        range: new DateRange(start, end),
        start,
        startString: ISO8601(start),
      })) as Period[];

      cache.set(key, periods);

      return periods;
    }

    case 'QUARTER': {
      const periods = getQuartersInPeriod(params.start, params.end, params.fysm).map(
        ({ start, end, name }) => ({
          end,
          endString: ISO8601(end),
          key: ISO8601(start),
          length: 'QUARTER',
          months: periodsInRange({ end, fysm: params.fysm, periodLength: 'MONTH', start }).map(
            x => x.key,
          ),
          name,
          range: new DateRange(start, end),
          start,
          startString: ISO8601(start),
        }),
      ) as Period[];

      cache.set(key, periods);

      return periods;
    }

    case 'HALF': {
      const periods = getHalvesInPeriod(params.start, params.end, params.fysm).map(
        ({ start, end, name }) => ({
          end,
          endString: ISO8601(end),
          key: ISO8601(start),
          length: 'HALF',
          months: periodsInRange({ end, fysm: params.fysm, periodLength: 'MONTH', start }).map(
            x => x.key,
          ),
          name,
          range: new DateRange(start, end),
          start,
          startString: ISO8601(start),
        }),
      ) as Period[];

      cache.set(key, periods);

      return periods;
    }

    case 'YEAR': {
      const periods = getFiscalYearsInPeriod(params.start, params.end, params.fysm).map(
        ({ start, end, name }) => ({
          end,
          endString: ISO8601(end),
          key: ISO8601(start),
          length: 'YEAR',
          months: periodsInRange({ end, fysm: params.fysm, periodLength: 'MONTH', start }).map(
            x => x.key,
          ),
          name,
          range: new DateRange(start, end),
          start,
          startString: ISO8601(start),
        }),
      ) as Period[];

      cache.set(key, periods);

      return periods;
    }

    default:
      throw new TypeError('Unknown PeriodLength');
  }
};
