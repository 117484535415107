import * as React from 'react';

import { cx } from '@cobbler-io/utils/src/cx';

import { useSearchParams } from '@cobbler-io/hooks/src/useSearchParams';

import { ErrorBoundary } from '@cobbler-io/core-ui/src/ErrorBoundary';
import { useFeatureFlag } from '@cobbler-io/core-ui/src/FeatureFlag';

import { useCurrentUser } from '@cobbler-io/redux/src/modules/current-user';

import loadable from '@loadable/component';
import { navigate, Redirect, Router } from '@reach/router';
import qs from 'qs';

import { Load } from './components/Load';
import { Budgets as NewDataModelBudgets } from './ndm/Budgets';
import { RedirectToActualDetails } from './ndm/screens/ActualsList/ActualDetails';
import { RedirectToBudgetLineDetails } from './ndm/screens/BudgetLineOverview/BudgetLineDetails';
import { ALL_DEPARTMENT_TAG } from './ndm/screens/Headcount/urls';
import { headcountUrls } from './ndm/screens/Headcount/urls/urls';
import { DEFAULT_REVISION_TAG } from './urls';

const Copilot = loadable(
  async () => (await import(/* webpackChunkName: "copilot" */ './ndm/screens/Copilot')).Copilot,
);

const RoutinesRoutes = loadable(
  async () =>
    (await import(/* webpackChunkName: "routines-routes" */ './screens/Routines/Routes')).Routes,
);

const DataSet = loadable(
  async () => (await import(/* webpackChunkName: "data-set" */ './screens/DataSet')).DataSet,
);

const DataSets = loadable(
  async () => (await import(/* webpackChunkName: "data-sets" */ './screens/DataSets')).DataSets,
);

const Headcount = loadable(
  async () => import(/* webpackChunkName: "headcount" */ './ndm/screens/Headcount'),
);

const Drivers = loadable(
  async () => import(/* webpackChunkName: "drivers" */ './ndm/screens/Drivers'),
);

const TaskList = loadable(
  async () => import(/* webpackChunkName: "task-list" */ './ndm/screens/TaskList'),
);

const Inbox = loadable(async () => import(/* webpackChunkName: "inbox" */ './pages/Inbox'));

const GlobalSettings = loadable(
  async () => import(/* webpackChunkName: "global-settings" */ './pages/GlobalSettings'),
);

const NotFound = loadable(async () => import('./pages/NotFound'));

const NoBudget = loadable(
  async () => import(/* webpackChunkName: "no-budget" */ './ndm/screens/BudgetChooser/NoBudget'),
);

const UserProfile = loadable(
  async () => import(/* webpackChunkName: "user-profile" */ './pages/UserProfile'),
);

const Reports = loadable(async () => import(/* webpackChunkName: "reports" */ './pages/Reports'));

const Loading = <div>Loading...</div>;

type RouteProps = {
  Component: React.ComponentType<any>;
  fallback?: React.ComponentType<any>;
  path: string; // eslint-disable-line react/no-unused-prop-types
};

const Route = (props: RouteProps): JSX.Element => {
  const { Component, fallback = Loading } = props;

  return (
    <React.Suspense fallback={fallback}>
      <Component />
    </React.Suspense>
  );
};

Route.displayName = 'LazyRoute';

const Anomalies = React.lazy(
  async () => import(/* webpackChunkName: "anomalies" */ './ndm/screens/Anomalies'),
);

const Anomaly = React.lazy(
  async () => import(/* webpackChunkName: "anomaly" */ './ndm/screens/Anomaly'),
);

const Dashboard = React.lazy(async () =>
  import('./ndm/screens/Dashboard').then(x => ({ default: x.Dashboard })),
);

const DashboardsList = React.lazy(async () =>
  import('./ndm/screens/Dashboard').then(x => ({ default: x.DashboardsList })),
);

const NewDataModelBudgetChooser = React.lazy(
  async () => import(/* webpackChunkName: "ndm-budget-chooser" */ './ndm/screens/BudgetChooser'),
);
const RedirectToRoot = () => <Redirect noThrow to="/" />;
RedirectToRoot.displayName = 'RedirectToRoot';

const RedirectToLogin = () => <Redirect noThrow to="/login" />;
RedirectToLogin.displayName = 'RedirectToLogin';

const RedirectToDefaultRevision = () => <Redirect noThrow to={DEFAULT_REVISION_TAG} />;
RedirectToDefaultRevision.displayName = 'RedirectToDefaultRevision';

const RedirectToDefaultDepartment = () => (
  <Redirect noThrow to={headcountUrls.plan({ departmentId: ALL_DEPARTMENT_TAG })} />
);
RedirectToDefaultDepartment.displayName = 'RedirectToDefaultDepartment';

const RedirectToMyTasks = () => {
  const { id: currentUserId } = useCurrentUser();
  const myTasksUrl = [
    '/tasks/list',
    qs.stringify({ grid: { filters: { assignedTo: [currentUserId] } } }),
  ].join('?');

  return <Redirect noThrow to={myTasksUrl} />;
};
RedirectToMyTasks.displayName = 'RedirectToMyTasks';

const RedirectFromSearchParam = () => {
  // This is basically what reach-router's <Redirect /> does internally but in
  // this case we're explicitly removing the query params by calling navigate
  // directly without them.
  const { redirect } = useSearchParams();
  setTimeout(() => void navigate(redirect ?? '/', { replace: true }), 0);
  return null;
};

const getIndexRoute = (isMainCopilotUser: boolean, showCopilot: boolean, showRoutines: boolean) => {
  if (!isMainCopilotUser) {
    // Default case for non-copilot users
    return NewDataModelBudgetChooser;
  }

  if (showCopilot) {
    // Typical case for copilot users
    return Copilot;
  }

  if (showRoutines) {
    // Copilot is hidden, but the user can view Routines. So, redirect there.
    return () => <Redirect noThrow to="/routines" />;
  }

  // Copilot and Routines are both hidden.
  // This is common in onboarding when the customer is not live yet,
  // but they need access to Settings to set up integrations.
  return () => <Redirect noThrow to="/settings" />;
}

type Props = {
  className?: string;
};

export const Routes = ({ className }: Props): JSX.Element => {
  const SHOW_COPILOT = !useFeatureFlag('HideCopilot');
  const SHOW_ROUTINES = useFeatureFlag('routines');
  const SHOW_DATASETS = useFeatureFlag('UploadAnyData');

  const IS_MAIN_COPILOT_USER = useFeatureFlag('CopilotView');

  const MainIndexRoute = getIndexRoute(IS_MAIN_COPILOT_USER, SHOW_COPILOT, SHOW_ROUTINES);

  return (
    <ErrorBoundary>
      <Router className={cx('fade-in', className)}>
        <Route Component={DashboardsList} path="/dashboard" />
        <Route Component={Dashboard} path="/dashboard/:dashboardId" />

        {/* These paths deal with budget data, so, we gate them until onboarding is done */}
        <Route Component={MainIndexRoute} path="/" />
        <Route Component={NewDataModelBudgetChooser} path="budgets" />
        <Route Component={NewDataModelBudgets} path="budgets/*" />
        <Load Component={RedirectToDefaultRevision} path="budgets/:id" />

        <Route Component={Copilot} path="copilot" />
        <Route Component={Copilot} path="copilot/:conversationId" />

        {SHOW_ROUTINES && <Route Component={RoutinesRoutes} path="routines" />}
        {SHOW_ROUTINES && <Route Component={RoutinesRoutes} path="routines/*" />}

        {SHOW_DATASETS && <Route Component={DataSets} path="datasets" />}
        {SHOW_DATASETS && <Route Component={DataSet} path="datasets/:id" />}
        {SHOW_DATASETS && <Route Component={DataSets} path="datasets/*" />}

        <Route Component={Anomalies} path="anomalies" />
        <Route Component={Anomaly} path="anomalies/:anomalyId" />

        {/*
          Actual and line details get loaded under its parent budget line.
          If this info is not available in the current URL, we fetch the missing data and immediately redirect
        */}
        <Load Component={RedirectToActualDetails} path="/actuals/:id/*" />
        <Load Component={RedirectToBudgetLineDetails} path="/lines/:id/*" />

        {/* Headcount */}
        <Load Component={RedirectToDefaultDepartment} path="headcount/" />
        <Load Component={RedirectToDefaultDepartment} path="headcount/:tab" />
        <Load Component={Headcount} path="headcount/:departmentId/*" />

        {/* Drivers */}
        <Load Component={Drivers} path="drivers/*" />

        {/* Task List */}
        <Load Component={RedirectToMyTasks} path="tasks" />
        <Load Component={TaskList} path="tasks/*" />

        {/* Inbox */}
        <Load Component={Inbox} path="inbox" />

        <Load Component={GlobalSettings} path="settings" />

        {/* Onboarding thingies */}
        <Load Component={NoBudget} path="no-budget" />

        {/* These should always be available */}
        <Load Component={UserProfile} path="user" />

        {/* Reports */}
        <Load Component={Reports} path="reports" />
        <Load Component={Reports} path="reports/*" />

        {/* The user is already signed in, so they should not hit this. For the actual path, see the OIDCRouter */}
        <Load Component={RedirectToRoot} path="/registration/finish" />

        {/* Authentication Routes */}
        <Load Component={RedirectFromSearchParam} path="login" />
        {/* These are redundant as they're all handled at a higher level */}
        <Load Component={RedirectToRoot} path="signin-callback" />
        <Load Component={RedirectToLogin} path="signout-callback" />

        {/* 404 */}
        <Load default Component={NotFound} />
      </Router>
    </ErrorBoundary>
  );
};

Routes.displayName = 'Routes';

export default Routes;
