import * as React from 'react';

import { CloseButton } from '@cobbler-io/core-ui/src/CloseButton';
import { Modal, useModal } from '@cobbler-io/core-ui/src/Modal';

import {
  CurrentRangeProvider, useCurrentRange,
} from '@cobbler-io/app/src/providers/CurrentRangeProvider';

import { AddChildBudgetLineForm } from './AddChildBudgetLineForm';

export const useAddBudgetLine = () => {
  const { create: createModal } = useModal();
  const rangeCtx = useCurrentRange();

  const addBudgetLine = React.useCallback(() => {
    createModal(
      <Modal overlay className="centered box-shadow-standard" clickAway="none">
        <CloseButton />
        <CurrentRangeProvider
          defaultEnd={rangeCtx.end}
          defaultMax={rangeCtx.max}
          defaultMin={rangeCtx.min}
          defaultPeriodLength="MONTH"
          defaultStart={rangeCtx.start}
        >
          <AddChildBudgetLineForm addAnother={addBudgetLine} />
        </CurrentRangeProvider>
      </Modal>,
    );
  }, [createModal, rangeCtx.end, rangeCtx.max, rangeCtx.min, rangeCtx.start]);

  return addBudgetLine;
};
