import { debounce } from 'lodash';
import { Meta, TableState } from 'react-table';
import { autosizeAll } from './autosizeAll';

const debouncedAutoSizeAll = debounce(
  autosizeAll,
  50, // ms
);

/**
 * This can be directly passed to the DataGrid `onStateChange` callback to
 * adjust the size of _all_ the columns in the grid.
 */
export const autosizeOnExpand = <T extends UnknownObject>(
  state: TableState<T>,
  prevState: TableState<T> | null,
  meta: Meta<T>,
): TableState<T> => {
  if (Object.keys(state.expanded).length > Object.keys(prevState?.expanded ?? {}).length) {
    debouncedAutoSizeAll(meta.instance);
  }
  return state;
};
