/* eslint-disable no-restricted-syntax, func-style, functional/no-let, functional/immutable-data */

import { DateRange } from './DateRange';
import { getEndOfDay } from './getEndOfDay';
import { getMonthDates } from './getMonthDates';
import { DateLike } from './isDateLike';
import { tzAdjust } from './tzAdjust';

const getArg = (x: DateLike): Date => (x instanceof Date ? x : tzAdjust(x));

const head = <T extends any>(arr: T[]): T => arr[0];

const tzUnAdjust = (x: Date) => {
  const date = new Date(x);
  date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
  return date;
};

const format = (x: Date): string => head(tzUnAdjust(x).toISOString().split('T'));

/**
 * Gets an array of ISO8601 dates of the start of each month when the full month falls between the
 * start and end.
 *
 * Note: this expected everything to be a local date, so some timezone issues might creep up
 * if you mix UTC and LocalDates
 */
export function getMonthsInRange(range: DateRange): any[];
export function getMonthsInRange(start: DateLike, end: DateLike): any[];
export function getMonthsInRange(start: DateRange | DateLike, end?: DateLike): any[] {
  const range =
    start instanceof DateRange ? start : new DateRange(getArg(start), getEndOfDay(getArg(end)));

  const reference = new Date(range.start);
  const months: string[] = [];

  while (range.includes(reference)) {
    const month = getMonthDates(reference);

    const r = new DateRange(month.start, month.end);

    if (range.includes(r)) {
      months.push(format(month.start));
    }

    reference.setMonth(reference.getMonth() + 1);
  }

  return months;
}
