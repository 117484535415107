import * as React from 'react';

import { useLocation } from '@reach/router';
import { parse } from 'qs';

export const useSearchParams = (): Record<string, any> => {
  const { search } = useLocation();
  const base = typeof search === 'string' && search.startsWith('?') ? search.slice(1) : search;

  return React.useMemo(() => parse(base), [base]);
};
