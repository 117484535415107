import * as React from 'react';

import { cx } from '@cobbler-io/utils/src/cx';

import Icon from '@cobbler-io/core-ui/src/Icon';

import { CellProps } from 'react-table';

import { EditorLine } from '../../types';

import styles from './NameColumn.scss';

export const NameColumn = ({
  cell: { value: name },
  row: { original: line },
}: CellProps<EditorLine, EditorLine['name']>): JSX.Element => {
  const hasChildren = false; // TODO implement in the backend (line.hasChildren)
  const Wrapper = hasChildren ? 'strong' : 'span';

  return (
    <Wrapper className={cx(!name && styles.placeholder)}>
      {name || 'Add a new line...'}
      {line.isRevenue && <Icon className={styles.trendingIcon} size={16} type="trendingUp" />}
    </Wrapper>
  );
};

NameColumn.displayName = 'NameColumn';
