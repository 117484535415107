/* eslint-disable max-lines-per-function */

import { indexBy, last, map, pathOr, pipe, pluck, prop, propEq, propOr, sum } from 'ramda';

import {
  BudgetLineAtRevision, ChildBudgetLineAtRevision, EditorData, EditorDataLine, Interval,
  IntervalAllocation, IntervalPlannedSpend, Totals,
} from './types';

export const mapDataForBudgetRevision = (
  editorData: EditorData,
  fiscalYearStartMonth: ServerMonth = 1,
): BudgetLineAtRevision => {
  const { id, ancestors } = editorData.selfLine;
  const rootId: BudgetLineId = last(ancestors)?.id ?? id;
  const isRoot = id === rootId;
  const children = editorData.lines;

  // {key, name, start, end}[]
  const intervals: Interval[] = map(
    interval => ({
      end: interval.dateRange.end,
      key: interval.nameKey,
      name: interval.displayName,
      start: interval.dateRange.start,
    }),
    editorData.intervals,
  );

  // { [start]: {key, name, start, end} }
  const intervalsByKey: Record<ISO8601String, Interval> = indexBy(prop('start'), intervals);

  // (EditorDataLine) => { [start]: { start, allocated } }
  const getPlannedByInterval: (line: EditorDataLine) => Record<ISO8601String, IntervalAllocation> =
    pipe<
      EditorDataLine,
      readonly IntervalPlannedSpend[],
      IntervalAllocation[],
      Record<ISO8601String, IntervalAllocation>
    >(
      prop('plannedSpend'),
      map(interval => ({
        allocated: prop('planned', interval),
        start: prop('start', interval),
      })),
      indexBy(prop('start')),
    );

  const lines: ChildBudgetLineAtRevision[] = children.map(line => {
    const plannedByInterval = getPlannedByInterval(line);

    // Total (sum of all interval planned) in this line
    // TODO: Rename to totalPlanned to to reflect "plannedByInterval"
    const totalAllocated = sum(pluck('allocated', Object.values(plannedByInterval)));

    return {
      accounts: line.accounts,
      ancestors: line.ancestors,
      childrenCount: line.childrenCount,
      departments: line.departments,
      id: line.id,
      intervalsByKey,
      isRevenue: line.isRevenue,
      name: line.name,
      owner: line.owner?.item,
      parentId: line.ancestors[0]?.id,
      plannedByInterval,
      revisionId: editorData.revisionId,
      totalAllocated,
      type: line.type,
      vendors: line.vendors,
    };
  });

  const getAllocated = (interval: Interval, childLines: ChildBudgetLineAtRevision[]) =>
    sum(
      // Sum of planned in all children in an interval
      map(pathOr(0, ['plannedByInterval', interval.start, 'allocated']), childLines),
    );

  // { [start]: {planned, available, allocated, unallocated, spent } }
  const totalsByInterval = intervals.reduce<Record<ISO8601String, Totals>>(
    (byInterval, interval) => {
      const intervalData = editorData.selfLine.plannedSpend.find(propEq('start', interval.start));
      const planned = (intervalData?.planned ?? 0) as unknown as MinorCurrency; // Planned in parent in an interval
      const allocated = getAllocated(interval, isRoot ? lines.filter(l => !l.isRevenue) : lines);
      const intervalTotals: Totals = {
        allocated,
        available: planned,
        // TODO: Check if this is really needed. Deep. Used to be shallow [!!!]
        spent: (intervalData?.spent ?? 0) as unknown as MinorCurrency,

        unallocated: planned - allocated,
      };

      if (isRoot) {
        const plannedRevenue = intervalData?.plannedRevenue ?? 0;
        const revenueAllocated = getAllocated(
          interval,
          lines.filter(l => l.isRevenue),
        );
        intervalTotals.revenueUnallocated = plannedRevenue - revenueAllocated;
      }

      return { ...byInterval, [interval.start]: intervalTotals };
    },
    {},
  );

  // TODO: There's probably a ramda method that can aggregate multiple object
  const totalPlanned: MinorCurrency = sum(
    map<Totals, number>(propOr(0, 'available'), Object.values(totalsByInterval)),
  );
  const totalAllocated: MinorCurrency = sum(
    map<Totals, number>(propOr(0, 'allocated'), Object.values(totalsByInterval)),
  );
  const totalSpent: MinorCurrency = sum(
    map<Totals, number>(propOr(0, 'spent'), Object.values(totalsByInterval)),
  );

  const totals: Totals = {
    allocated: totalAllocated,
    available: totalPlanned,
    // TODO: Check if this is really needed. Deep. Used to be shallow [!!!]
    spent: totalSpent,

    unallocated: totalPlanned - totalAllocated,
  };

  // We use this query in a specific way so that the frontend only lets you create
  // one spend plan per interval, and we only let this work at the most granular interval
  // so, we can just use the first spend plan and ignore the rest of the array (which
  // should be empty).
  const plannedByInterval = getPlannedByInterval(editorData.selfLine);
  const planned: IntervalAllocation[] = Object.values(plannedByInterval);

  // const revisions = []; // TODO: Probably not needed

  // This (a revision with isForecast == false) is guaranteed in the API, hence the "!"
  // const originalRevisionId = find<Revision>(propEq('isForecast', false), revisions)!.id;

  const enhancedBudgetDetails: BudgetLineAtRevision = {
    accounts: [...editorData.selfLine.accounts],
    ancestors: editorData.selfLine.ancestors,
    budgetResolution: 'MONTH',
    departments: [...editorData.selfLine.departments],

    end: editorData.dateRange.end,

    fiscalYearStartMonth,

    id: editorData.selfLine.id,

    intervals,
    // revisions,
    // originalRevisionId,
    isForecast: editorData.isForecast,
    isRevenue: editorData.selfLine.isRevenue,
    isRoot,
    lines,
    name: editorData.selfLine.name,
    owner: editorData.selfLine.owner?.item,
    parentId: editorData.selfLine.ancestors[0]?.id,
    parentName: editorData.selfLine.ancestors[0]?.name,
    permissions: editorData.selfLine.permissions,
    planned,
    plannedByInterval,
    revisionId: editorData.revisionId,

    // parent
    rootId,

    start: editorData.dateRange.start,

    totals,

    type: editorData.selfLine.type,
    totalsByInterval,
    vendors: [...editorData.selfLine.vendors],

    weight: editorData.selfLine.weight,
  };

  return enhancedBudgetDetails;
};
