import { createNamedContext } from '@cobbler-io/utils/src/createNamedContext';

import { ActualId } from '@cobbler-io/app/src/api';
import { CurrentActualQueryQueryResult } from '@cobbler-io/app/src/api/graphql-types';

type Actual = NonNullable<CurrentActualQueryQueryResult['data']>['actual'];

export type CurrentActualContextType = {
  actualId?: ActualId;
  loading: boolean;
  error: boolean;
  actual: Actual;
  refetch: () => Promise<any>;
};

const noopResolve = async () => Promise.resolve();

export const CurrentActualContext = createNamedContext<CurrentActualContextType>(
  'CurrentActualContext',
  {
    actual: null,
    error: false,
    // Default context state
    loading: true,
    refetch: noopResolve,
  },
);
