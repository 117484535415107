import * as React from 'react';

import { ErrorMessage } from '@cobbler-io/core-ui/src/ErrorMessage';

import styles from './NotFound.scss';

type Props = Record<string, unknown>

export const NotFound: React.FC<Props> = _ => (
  <div className={styles.container}>
    <ErrorMessage className="col align-center" description="There's nothing here" title="Whoops!" />
  </div>
);

NotFound.displayName = 'NotFound';
export default NotFound;
