import * as React from 'react';

import { Button } from '@cobbler-io/core-ui/src/Button';
import { DataGridContext } from '@cobbler-io/core-ui/src/DataGrid/DataGridContext';
import { Icon } from '@cobbler-io/core-ui/src/Icon';

import { useExportDataGrid } from './useExportDataGrid';

import styles from './ExportTo.scss';

export type ExportToProps = {
  filename: string;
  includeDate?: boolean;
  short?: boolean;
};

export const ExportTo = (props: ExportToProps): JSX.Element => {
  const ctx = React.useContext(DataGridContext);
  const { filename, includeDate, short = false } = props;
  const onClick = useExportDataGrid({ filename, includeDate, getInstance: ctx.getInstance });

  return (
    <Button
      small
      className={styles.iconToolbar}
      name="export-grid-to"
      variant="text"
      onClick={onClick}
    >
      <Icon aria-hidden size={14} type="export" /> {!short && <span>Export to...</span>}
    </Button>
  );
};

ExportTo.displayName = 'ExportTo';
