import { useCurrencyFormatter } from '@cobbler-io/redux/src/modules/currency';

import { CellProps } from 'react-table';

/**
 * Formats numbers into dollars (no cents)
 */
export const DisplayCurrencyRounded = ({ cell: { value } }: CellProps<any>): string => {
  const { currencyRounded } = useCurrencyFormatter();
  return currencyRounded(value);
};
