/* eslint-disable camelcase */
import { identity } from '@cobbler-io/utils/src/identity';

import {
  EditDepartmentMutation, EditLineDepartmentInputType, EditLineDepartmentPayloadType, Exact,
  useEditDepartmentMutation, useGetAllDepartmentsQuery,
} from '@cobbler-io/app/src/api/graphql-types';
import { DepartmentPicker } from '@cobbler-io/app/src/ndm/components/DepartmentPicker';
import { useCurrentRange } from '@cobbler-io/app/src/providers/CurrentRangeProvider';
import { toDateToken } from '@cobbler-io/app/src/utils/toDateToken';

import { MutationUpdaterFn } from 'apollo-client';
import gql from 'graphql-tag';
import { pluck } from 'ramda';
import { CellProps } from 'react-table';

import { EditorLine } from '../types';
import {
  acceptedTextKeys, EditorInput, ModalEditorHandlers, useModalEditor,
} from './useModalEditor';

type OptResp<Input, Payload> = (vars: Exact<{ input: Input }>) => Payload;

type DepartmentType = {
  __typename: 'DepartmentType';
  id: DepartmentId;
  parent: DepartmentId | null;
  name: string;
};

type IntermediateArgs = {
  departments: DepartmentType[];
};

// todo: we need this in the initial query
type CellValue = DeepWriteable<EditorLine['departments']>;

type Updater<T> = (params: IntermediateArgs) => (cellProps: CellProps<EditorLine, CellValue>) => T;

/**
 * Creates an optimistic response for the mutation, which is basically the return
 * of the mutation
 * @see https://www.apollographql.com/docs/react/v2/performance/optimistic-ui/
 */
const optimisticResponse: Updater<
  OptResp<EditLineDepartmentInputType, EditLineDepartmentPayloadType>
> =
  ({ departments }) =>
  cellProps =>
  ({ input }): EditDepartmentMutation => {
    const { departmentIds } = input;
    const nextDepartments: DepartmentType[] = departments
      .filter(x => departmentIds.includes(x.id))
      .sort((a, b) => a.name.localeCompare(b.name));
    const response: EditDepartmentMutation = {
      __typename: 'Mutation' as const,
      a_editLineDepartments: {
        __typename: 'EditLineDepartmentPayloadType',
        // This should be the budgetLineId
        departments: nextDepartments,
        id: cellProps.row.original.id,
        planningVersions: [], // not sure how to make this one work
      },
    };
    return response;
  };

const BUDGET_LINE_DEPARTMENT_FRAGMENT = gql`
  fragment readBudgetLineDepartment on BudgetEditorLineType {
    __typename
    id
    # The below won't work until we update the query
    departments {
      ... on DepartmentType {
        __typename
        id
        name
      }
    }
  }
`;

/**
 * The update receives the input, and returns a function that will update the cache.
 *
 * It's weird, but I need the budgetLineId, which is not in the response
 *
 * This seems to work, but every once in awhile it seems to do nothing. There might have
 * been some cache evictions.
 */
const update: Updater<MutationUpdaterFn<EditDepartmentMutation>> =
  () => cellProps => (cache, result) => {
    const budgetLineId = cellProps.row.original.id;
    /**
     * Updates the columns on the BudgetEditorTable
     */
    const updateBudgetEditor = () => {
      const { a_editLineDepartments } = result.data!;
      const { departments: nextDepartments } = a_editLineDepartments;

      const fragmentId = ['BudgetEditorLineType', budgetLineId].join(':');
      const lineFragment = cache.readFragment({
        fragment: BUDGET_LINE_DEPARTMENT_FRAGMENT,
        id: fragmentId,
      });

      cache.writeFragment({
        data: {
          ...(lineFragment as Record<string, unknown>),
          departments: nextDepartments,
        },
        fragment: BUDGET_LINE_DEPARTMENT_FRAGMENT,
        id: fragmentId,
      });
    };

    /**
     * Updates the columns on the BudgetOverviewTable
     */
    const updateBudgetOverview = () => {};

    updateBudgetEditor();
    updateBudgetOverview();
  };

const getIds = pluck('id');

type RelativeEditorParams = {
  getWeight: NullaryFn<number>;
};

export const useEditDepartment = (params: RelativeEditorParams): ModalEditorHandlers => {
  const { data } = useGetAllDepartmentsQuery({ fetchPolicy: 'cache-first' });
  const { getWeight } = params;
  // We're just removing the readonly here
  const departments = (data?.a_departments?.items ?? []) as unknown as DepartmentType[];
  const toValue = identity;
  const range = useCurrentRange();

  const getDefaultValue = (cellValue: CellValue): DepartmentId[] =>
    Array.isArray(cellValue) ? getIds(cellValue) : [];

  const createInput = (p: {
    value: DepartmentId[] | null;
    cellProps: CellProps<EditorLine, CellValue>;
  }): EditLineDepartmentInputType => ({
    departmentIds: (p.value ?? []) as readonly string[],
    end: toDateToken(range.end),
    id: p.cellProps.row.original.id,
    start: toDateToken(range.start),
  });

  return useModalEditor<
    EditLineDepartmentInputType,
    EditDepartmentMutation,
    EditorInput,
    CellValue
  >({
    Field: DepartmentPicker,
    acceptedKeysHandler: acceptedTextKeys,
    createInput,
    getDefaultValue,
    getWeight,
    optimisticResponse: optimisticResponse({ departments }),
    shouldDisable: cell => cell.row.original.linkedToDriver,
    toValue,
    update: update({ departments }),
    useMutationHook: useEditDepartmentMutation,
  });
};
