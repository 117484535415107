import * as React from 'react';

import { Button } from '@cobbler-io/core-ui/src/Button';
import { Icon } from '@cobbler-io/core-ui/src/Icon';

type ClearButtonProps = Omit<React.HTMLProps<HTMLButtonElement>, 'type'>;

export const ClearButton = (props: ClearButtonProps) => (
  <Button small name="clear-button" variant="svg" {...props}>
    <Icon aria-hidden size={16} type="close" />
  </Button>
);

ClearButton.displayName = 'ClearButton';
