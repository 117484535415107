import * as React from 'react';

import { isFunction } from '@cobbler-io/utils/src/isFunction';

import { FallbackFunction, FilterFunction, Item } from './types';

export const useFallback = <TValue extends any = any>(
  addFallback?: FallbackFunction<TValue> | null,
  limit = Infinity,
): FilterFunction<TValue> =>
  React.useCallback(
    (input: string, items: Item<TValue>[]) => {
      // This code will run only if there is a filter.
      const fallback = isFunction(addFallback) ? addFallback(input, items) : null;
      const shownItems = items.slice(0, limit);

      if (fallback) {
        return shownItems.length < limit
          ? shownItems.concat(fallback)
          : shownItems.slice(0, -1).concat(fallback);
      }

      return shownItems;
    },
    [addFallback, limit],
  );
