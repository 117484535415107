import { noop } from '@cobbler-io/utils/src';

import { CloseBehavior, ModalTreeNode } from './types';

const findClosestSentinel = (modal: ModalTreeNode): ModalTreeNode => {
  if (modal.root === modal) {
    return modal;
  }

  if (modal.isSentinel) {
    return modal;
  }

  if (!modal.parent) {
    return modal;
  }

  return findClosestSentinel(modal.parent);
};

export const getCloseBehavior = (behavior: CloseBehavior, modal: ModalTreeNode): NullaryFn => {
  if (!modal) {
    return noop;
  }

  switch (behavior) {
    case 'close':
    case 'close-self-and-children':
      return modal.close;
    case 'close-children':
      return modal.closeChildren;
    case 'close-tree':
      return modal.closeTree;
    case 'close-self-without-children':
      return modal.closeSelfWithoutChildren;
    case 'closest-sentinel':
      return findClosestSentinel(modal).close;
    case 'none':
    default:
      return noop;
  }
};
