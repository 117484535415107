import * as React from 'react';

import { Field } from '@cobbler-io/core-ui/src/Field';

import debounce from 'lodash/debounce';
import { ColumnInstance } from 'react-table';

type FilterProps = {
  column: ColumnInstance<any>;
  setFilter: (columnId: string, value: string) => void;
};

const SEARCH_DELAY = 300;
export const DefaultFilter = ({ column, setFilter }: FilterProps): JSX.Element => {
  const { id: columnId, filterValue } = column;
  const handleChange = React.useMemo(
    () =>
      debounce(
        (e: React.ChangeEvent<HTMLInputElement>) => setFilter(columnId, e.target.value),
        SEARCH_DELAY,
      ),
    [columnId, setFilter],
  );
  return (
    <Field
      defaultValue={filterValue}
      id={columnId}
      label={column.Header}
      name={columnId}
      onChange={handleChange}
    />
  );
};

DefaultFilter.displayName = 'DataGrid__DefaultFilter';
export default DefaultFilter;
