import * as React from 'react';

import { useMediaQuery } from '@cobbler-io/hooks/src/useMediaQuery';

export type SmallOnlyProps = {
  children?: React.ReactNode;
};

export const SmallOnly = (props: SmallOnlyProps): JSX.Element => {
  const { children } = props;

  const isSmall = useMediaQuery('(max-width: 600px)');

  if (!isSmall) {
    // @ts-expect-error: this is fine for react
    return null;
  }

  if (React.isValidElement(children)) {
    return children;
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};

SmallOnly.displayName = 'SmallOnly';

export default SmallOnly;
