import * as React from 'react';

import { Item } from '@cobbler-io/core-ui/src/Dropdown';
import { ComboBox } from '@cobbler-io/core-ui/src/Dropdown/ComboBox';

import { ColumnInstance } from 'react-table';

import styles from './FilterDropdown.scss';

type FilterDropdownProps<D extends UnknownObject> = {
  columnsToShow: ColumnInstance<D>[];
  allColumns: ColumnInstance<D>[];
  handleColumnsToShow: (columnsToShow: string[]) => void;
};

const convertColumnToItem = <D extends UnknownObject>(column: ColumnInstance<D>): Item => ({
  id: column.id,
  // @ts-expect-error - column.Header can be a component not just a string, but it will render ok.
  label: column.Header,
  value: column.id,
});

export const FilterDropdown = <D extends UnknownObject>(
  props: FilterDropdownProps<D>,
): JSX.Element => {
  const { columnsToShow, allColumns, handleColumnsToShow } = props;

  const options: Item[] = allColumns
    .filter(column => column.canFilter && column.Filter)
    .map(convertColumnToItem);

  const selected: Item[] = columnsToShow.map(convertColumnToItem);

  return (
    <div className={styles.container}>
      <ComboBox
        clearButton
        multiple
        className={styles.dropdown}
        defaultSelected={selected}
        id="FilterDropdown"
        items={options}
        label="Add Filter"
        name="FilterDropdown"
        selected={selected}
        variant="chip"
        onChange={handleColumnsToShow}
      />
    </div>
  );
};

FilterDropdown.displayName = 'FilterDropdown';
