import * as React from 'react';

import { createNamedContext } from '@cobbler-io/utils/src/createNamedContext';

import { useSetUser } from '@cobbler-io/redux/src/modules/current-user';

import { useGetCurrentUserQuery } from '@cobbler-io/app/src/api/graphql-types';
import { Loading } from '@cobbler-io/app/src/components/Loading';

import gql from 'graphql-tag';

type CurrentUserProviderProps = {
  children: JSX.Element;
};

export const GET_CURRENT_USER = gql`
  query GetCurrentUser {
    currentUser {
      ... on UserType {
        email
        deleted
        fullName
        id
        preferredName
        profileImageUrl
      }
    }
    userOnboarding {
      acceptedTermsOfService
      blocked
      done
    }
  }
`;

export const CurrentUserContext = createNamedContext<{ refetch: () => Promise<any> }>(
  'CurrentUser',
  { refetch: async () => Promise.resolve() },
);

export const CurrentUserProvider = ({ children }: CurrentUserProviderProps) => {
  const { data, loading, error, refetch } = useGetCurrentUserQuery();
  const setUser = useSetUser();

  const ctx = React.useMemo(() => ({ refetch }), [refetch]);

  const user = React.useMemo(() => {
    if (!data || !data.currentUser || !data.userOnboarding) {
      return null;
    }

    return {
      id: data.currentUser.id,
      email: data.currentUser.email,
      fullName: data.currentUser.fullName ?? null,
      preferredName: data.currentUser.preferredName ?? null,
      profileImageUrl: data.currentUser.profileImageUrl,
      acceptedTermsOfService: data.userOnboarding.acceptedTermsOfService,
      blocked: data.userOnboarding.blocked,
    };
  }, [data]);

  React.useEffect(() => {
    if (user) {
      setUser(user);
    }
  }, [user, setUser]);

  if (loading) {
    return <Loading />;
  }

  if (error) {
    // eslint-disable-next-line no-console
    console.error(error);

    return <>Error fetching current user</>;
  }

  return <CurrentUserContext.Provider value={ctx}>{children}</CurrentUserContext.Provider>;
};
