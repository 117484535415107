/* eslint-disable sort-keys */
/* eslint-disable sort-keys-fix/sort-keys-fix */
import React from 'react';

import { pick } from '@cobbler-io/utils/src/pick';

import { ErrorCallout } from '@cobbler-io/core-ui/src/Callout';

import {
  BudgetLineAncestorType,
  CurrentBudgetLineQuery,
  ResourcePermissionType,
  useCurrentBudgetLineQuery,
} from '@cobbler-io/app/src/api/graphql-types';
import { Loading } from '@cobbler-io/app/src/components/Loading';
import { BudgetUrlParams, budgetUrls } from '@cobbler-io/app/src/urls/urls';

import { last } from 'ramda';

import {
  BoundBudgetUrls,
  BudgetLineStub,
  CurrentBudgetLineContext,
  CurrentBudgetLineContextType,
  Permissions,
} from './CurrentBudgetLineContext';
import { getBudgetLineIdFromLocation } from './getBudgetLineIdFromLocation';

type BudgetLine = NonNullable<CurrentBudgetLineQuery['a_budgetLine']>;

type CurrentBudgetLineProviderProps = {
  children: React.ReactNode;
};

const noPermissions: Permissions = {
  read: false,
  edit: false,
  manage: false,
};

const extractPermissions = (permissions?: ResourcePermissionType | null): Permissions => {
  if (!permissions) {
    return noPermissions;
  }

  return {
    read: permissions.canRead || false,
    edit: permissions.canUpdate && permissions.canDelete,
    manage: permissions.canUpdatePermissions || false,
  };
};

const toBudgetStub = (x: Omit<BudgetLineAncestorType, 'depth'> | BudgetLine): BudgetLineStub => ({
  ...pick(['id', 'name'], x),
  permissions: extractPermissions(x.permissions),
});

export const CurrentBudgetLineProvider = (props: CurrentBudgetLineProviderProps): JSX.Element => {
  const { children } = props;
  const { budgetLineId, revisionTag } = getBudgetLineIdFromLocation();

  const { data, loading, error } = useCurrentBudgetLineQuery({
    variables: { id: budgetLineId! },
    fetchPolicy: 'cache-first',
    skip: !budgetLineId,
  });

  const urls: BoundBudgetUrls = React.useMemo(() => {
    const wrapUrlDefaults = <T extends BudgetUrlParams>(
      params: PartialBy<T, 'budgetId' | 'revisionId'>,
      // @ts-expect-error: this is fine
    ): T => ({ budgetId: budgetLineId, revisionId: revisionTag, ...params });

    return Object.entries(budgetUrls).reduce<BoundBudgetUrls>(
      (acc, [key, obj]) => ({ ...acc, [key]: (params: any) => obj(wrapUrlDefaults(params)) }),
      {},
    );
  }, [budgetLineId, revisionTag]);

  const ctx = React.useMemo(() => {
    if (!data || !data.a_budgetLine) {
      return null;
    }

    const permissions = extractPermissions(data.a_budgetLine.permissions);
    const root = toBudgetStub(last(data.a_budgetLine.ancestors) ?? data.a_budgetLine);

    // eslint-disable-next-line
    return {
      id: budgetLineId,
      name: data.a_budgetLine.name,
      type: data.a_budgetLine.type,
      isRevenue: data.a_budgetLine.isRevenue,
      isRoot: data.a_budgetLine.isRoot,
      root,
      ancestors: data.a_budgetLine.ancestors.map(toBudgetStub),
      permissions,
      urls,
      // This matches the legacy object
      budget: {
        id: budgetLineId,
        name: data.a_budgetLine.name,
        permissions,
      },
    } as unknown as CurrentBudgetLineContextType;
  }, [budgetLineId, data, urls]);

  if (error) {
    console.error(error);
    return <ErrorCallout>Error fetching current budget line data</ErrorCallout>;
  }

  if (loading && !data) {
    return <Loading />;
  }

  if (!ctx || !ctx.id || !ctx.name) {
    // Passthrough when there is no id...
    <CurrentBudgetLineContext.Provider value={null}>{children}</CurrentBudgetLineContext.Provider>;
  }

  // Type narrowing isn't working correctly above, so this is a TS error for now.
  return (
    <CurrentBudgetLineContext.Provider value={ctx}>
      {loading && <Loading />}
      {children}
    </CurrentBudgetLineContext.Provider>
  );
};

CurrentBudgetLineProvider.displayName = 'CurrentBudgetLineProvider';
