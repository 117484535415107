import * as React from 'react';

import { cx } from '@cobbler-io/utils/src/cx';

import { Slide, Slider } from '@swan-form/slider';

import styles from './Wizard.scss';

export type WizardProps = React.ComponentProps<typeof Slider>;
export type WizardStepProps = React.ComponentProps<typeof Slide>;

export const Wizard = React.forwardRef(
  ({ className, ...props }: WizardProps, ref: React.LegacyRef<Slider>) => (
    <Slider ref={ref} {...props} className={cx(styles.slider, className)} />
  ),
);

Wizard.displayName = 'Wizard';

export const Step = ({ className, ...props }: WizardStepProps): JSX.Element => (
  <Slide {...props} className={cx(styles.slide, 'fade-in', className)} />
);

Step.displayName = 'Step';

export default Wizard;
