import { Sheet } from '@cobbler-io/utils/src/parseSpreadsheet';
import { upperFirst } from '@cobbler-io/utils/src/string';

import { BudgetLineAtRevision } from '@cobbler-io/app/src/ndm/components/BudgetLineEditor/types';

import { map, pipe, prop, propOr } from 'ramda';

import { BudgetLineAtRevisionInterval } from './types';

// prettier-ignore
const shortMonths = ['jan','feb','mar','apr','may','jun','jul','aug','sep','oct','nov','dec'];

/**
 * Converts an API interval start (`2020-12-01`) to a sanitized period (`dec 2020`).
 */
export const intervalStartToPeriod = (intervalStart: ISO8601String): string => {
  const [yyyy, mm] = intervalStart.split('-').map(x => parseInt(x, 10));
  const mmm = shortMonths[mm - 1];
  return `${mmm} ${yyyy}`;
};

/**
 * Converts an API interval (`{start: '2020-12-01'}`) to a sanitized period (`dec 2020`).
 */
export const intervalToPeriod = pipe<BudgetLineAtRevisionInterval, ISO8601String, string>(
  prop('start'),
  intervalStartToPeriod,
);

/**
 * Converts a period (`dec 2020`) to an interval start date (`2020-12-01`).
 */
export const periodToIntervalStart = (period: string): ISO8601String => {
  const [mmm, yyyy] = period.split(' ');
  const mm = `0${shortMonths.indexOf(mmm) + 1}`.substr(-2);
  return `${yyyy}-${mm}-01`;
};

/**
 * Splits header info columns (name, vendor, etc) and period columns (Jan 2020, etc).
 */
export const splitColumns = (header: string[], infoColumnsLength: number) => {
  const cols = header.slice(0, infoColumnsLength); // The first few info cols (name, vendor, etc)
  const periods = header.slice(infoColumnsLength); // The period cols (months, quarters, etc)
  return { cols, periods };
};

/**
 * Makes sure all info columns are present, also cleans up and validate all period columns.
 */
export const sanitizeColumns =
  (budgetLineAtRevision: BudgetLineAtRevision, validColumns: RegExp[]) =>
  async (sheet: Sheet): Promise<Sheet> =>
    new Promise((resolve, reject) => {
      // This is very dumb, but the expected input format is very specific at
      // this point. In the future we'll be able to map columns and validate
      // in a smarter way
      if (!sheet) {
        return reject('Invalid sheet');
      }
      const { header, records } = sheet;
      const { cols, periods } = splitColumns(header, validColumns.length);

      // Make sure all cols start with the same text as the expected template
      const invalidColumns = cols.filter((col, i) => !validColumns[i].test(col));
      if (invalidColumns.length) {
        return reject(
          `Some of the provided columns are invalid: ${invalidColumns.map(upperFirst).join(', ')}`,
        );
      }

      if (!periods.length) {
        return reject('No periods were provided');
      }

      // " Jan 2020 Planned  " -> "jan 2020" (8 chars long)
      const sanitizedPeriods = periods.map(p => p.toLowerCase().trim().substr(0, 8));

      // Check that all periods pass validation (mmm yyyy)
      const invalidPeriods = sanitizedPeriods.filter(
        p => !/^(jan|feb|mar|apr|may|jun|jul|aug|sep|oct|nov|dec) \d{4}$/i.test(p),
      );
      if (invalidPeriods.length) {
        return reject(
          `Some of the provided periods are invalid: ${invalidPeriods
            .map((_, i) => periods[i])
            .join(', ')}`,
        );
      }

      // Check that there are no repeating periods
      const duplicatedPeriods = [
        ...new Set(sanitizedPeriods.filter((p, i) => sanitizedPeriods.indexOf(p) !== i)),
      ];
      if (duplicatedPeriods.length) {
        return reject(
          `Some of the provided periods are duplicated: ${duplicatedPeriods
            .map(upperFirst)
            .join(', ')}`,
        );
      }

      // Check that the provided periods are within the current range
      const periodsInBudgetRevision = budgetLineAtRevision.intervals.map(intervalToPeriod);
      const unallocatedPeriods = sanitizedPeriods.filter(p => !periodsInBudgetRevision.includes(p));
      if (unallocatedPeriods.length) {
        return reject(
          `Some of the provided periods are not in the current view: ${unallocatedPeriods
            .map(upperFirst)
            .join(', ')}`,
        );
      }

      return resolve({
        header: [...cols, ...sanitizedPeriods],
        records,
      });
    });

/**
 * Very permissive validator. Only fails if all cols are undefined or null.
 */
export const isValidRecord = (record: unknown): record is unknown[] => {
  return (
    Array.isArray(record) &&
    record.length > 0 &&
    record.some(val => typeof val !== 'undefined' && val !== null)
  );
};

/**
 * Joins an array of objects by key. Defaults to '' if the given key does not exist.
 */
export const joinByKey = <T extends Record<string, unknown>>(key: string, arr: T[]) =>
  map(propOr('', key), arr).join('; ');

/**
 * Splits a value by `;` or `|`.
 *
 * `'  one; two |  ; three|four ; five|;six '` → `['one', 'two', ..., 'six']`
 */
export const safeSplit = (value: unknown): string[] =>
  // TODO: Allow passing a parameter with the accepted delimeters. Currently ; & | are hardcoded
  value === null || typeof value === 'undefined'
    ? []
    : [
        ...new Set(
          String(value)
            .trim()
            .split(/\s*[;|]+\s*/) // handles 'one; two | three|four ; five|;six'
            .filter(Boolean),
        ),
      ];

/**
 * Returns the string representation of the passed value or `''` if `null` or `undefined`.
 */
export const safeString = (value: unknown): string => {
  return value === null || typeof value === 'undefined' ? '' : String(value).trim();
};

/* eslint-disable @typescript-eslint/no-magic-numbers, @typescript-eslint/prefer-regexp-exec, no-useless-escape, functional/no-let, no-restricted-syntax, prefer-named-capture-group, require-unicode-regexp */

/**
 * Tries to convert any value into a number. If the provided value cannot be converted
 * into a number, it returns the provided fallback value or `0` if no fallback value is
 * provided.
 *
 * This function handles currency, percentage and accounting negative values.
 */
export const safeNumber = <T = 0>(value: unknown, fallback: T | 0 = 0): number | T => {
  // Based on https://github.com/SheetJS/sheetjs/blob/542636ba8f150d59bf7d460e92676b156f39d103/bits/20_jsutils.js#L122
  let n = Number(value);
  if (!Number.isNaN(n)) {
    return Number.isFinite(n) ? n : fallback;
  }

  let s = String(value);
  if (!/\d/.test(s)) {
    return fallback;
  }

  let wt = 1;

  // Handle currencies and percentages
  s = s
    .replace(/([\d]),([\d])/g, '$1$2')
    .replace(/[$]/g, '')
    .replace(/[%]/g, () => {
      wt *= 100;
      return '';
    });
  n = Number(s);
  if (!Number.isNaN(n)) {
    return n / wt;
  }

  // Handle accounting negative format
  s = s.replace(/[(](.*)[)]/, (_, $1) => {
    wt = -wt;
    return $1;
  });
  n = Number(s);
  if (!Number.isNaN(n)) {
    return n / wt;
  }

  return fallback;
};

export const safeDate = (s: string): Date => {
  const o = new Date(s);
  const n = new Date(NaN);
  const y = o.getFullYear();
  const m = o.getMonth();
  const d = o.getDate();

  if (isNaN(d)) {
    return n;
  }

  if (y < 0 || y > 8099) {
    return n;
  }

  if ((m > 0 || d > 1) && y !== 101) {
    return o;
  }

  if (s.toLowerCase().match(/jan|feb|mar|apr|may|jun|jul|aug|sep|oct|nov|dec/u)) {
    return o;
  }

  if (s.match(/[^-0-9:,\/\\]/u)) {
    return n;
  }

  return o;
};
