/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';

import { execIfFunc } from '@cobbler-io/utils/src/execIfFunc';

import { getDocument } from '@cobbler-io/dom/src';

import { isNil, pipe, pluck, reject, uniq } from 'ramda';

import { ClickAwayContext } from './ClickAwayContext';
import { ClickAwayTarget } from './types';

const prepareRef: UnaryFn<React.RefObject<HTMLElement | null>[], HTMLElement[]> = pipe(
  reject(isNil),
  pluck('current'),
  reject(isNil),
);

const unfurlRef = (ref: ClickAwayTarget<HTMLElement>): HTMLElement[] => {
  if (typeof ref.current === 'function') {
    return unfurlRef(ref.current());
  }

  if (Array.isArray(ref.current)) {
    return uniq(
      ref.current.reduce<readonly HTMLElement[]>(
        (acc, x) => (Array.isArray(x.current) ? acc.concat(unfurlRef(x)) : acc.concat(x)),
        [],
      ),
    );
  }

  return [ref];
};

const refContainsEventTarget = (
  ref: ClickAwayTarget<HTMLElement>,
  event: PointerEvent,
): boolean => {
  if (!ref.current) {
    return false;
  }

  const elements = prepareRef(unfurlRef(ref));

  return elements.some(
    (x: HTMLElement) =>
      x.contains(event.target as Node) &&
      x.ownerDocument.documentElement.contains(event.target as Node),
  );
};

export type ClickAwayProviderProps = {
  children: React.ReactNode;
};

type ClickAwayCache = Map<ClickAwayTarget<HTMLElement>, Set<AnyFn>>;

export const ClickAwayProvider = ({ children }: ClickAwayProviderProps): JSX.Element => {
  const cache = React.useRef<ClickAwayCache>(new Map());
  React.useDebugValue(cache);

  const [handlers, listen] = React.useMemo(
    () => [
      {
        register: (ref: React.RefObject<any>, cb: AnyFn) => {
          if (cache.current.has(ref)) {
            cache.current.get(ref)!.add(cb);
          } else {
            cache.current.set(ref, new Set<AnyFn>([cb]));
          }
        },
        unregister: (ref: React.RefObject<any>, cb: AnyFn) => {
          const callbacks = cache.current.get(ref);
          if (!callbacks) {
            return;
          }

          callbacks.delete(cb);

          if (callbacks.size < 1) {
            cache.current.delete(ref);
          }
        },
      },
      (event: PointerEvent) => {
        cache.current.forEach((callbacks, ref) => {
          if (!ref.current || !event || !event.target || event.defaultPrevented) {
            return;
          }

          if (Array.isArray(ref.current) && !ref.current.length) {
            // This is an empty array, so we can't really check for those
            return;
          }

          if (!refContainsEventTarget(ref, event)) {
            callbacks.forEach(x => {
              execIfFunc(x, event);
            });
          }
        });
      },
    ],
    [cache],
  );

  React.useEffect(() => {
    getDocument().addEventListener('pointerdown', listen);
    return () => getDocument().removeEventListener('pointerdown', listen);
  }, [listen]);

  return <ClickAwayContext.Provider value={handlers}>{children}</ClickAwayContext.Provider>;
};

ClickAwayProvider.displayName = 'ClickAwayProvider';
