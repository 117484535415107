import * as React from 'react';

import { useURLState } from '@cobbler-io/hooks/src/useURLState';

import { Button } from '@cobbler-io/core-ui/src/Button';

import { both, complement, concat, either, filter, isEmpty, isNil, omit, take } from 'ramda';
import { ColumnInstance, FilterValue, TableInstance } from 'react-table';

import { useCurrentModal } from '../../../Modal';
import { FilterContent } from './FilterContent';
import { FilterDropdown } from './FilterDropdown';

import styles from './FiltersSelector.scss';

type FiltersSelectorProps<D extends UnknownObject> = {
  allColumns: ColumnInstance<D>[];
  instance: TableInstance<D>;
};

const isColumnFilterable = <D extends UnknownObject>(x: ColumnInstance<D>): boolean =>
  Boolean(x.canFilter && x.Filter);

// Number of filters to show the first time the user opens the drawer.
const NUM_FILTERS_TO_SHOW = 5;
const getColumnsToShow = <D extends UnknownObject>(
  allColumns: ColumnInstance<D>[],
  filterUrlState: { [key: string]: FilterValue },
): ColumnInstance<D>[] => {
  // Get the columns that are filtered by the URL state
  const alreadyFiltered = allColumns.filter(({ id }) => id in filterUrlState);
  const isColumnFiltered = (x: ColumnInstance<D>): boolean => alreadyFiltered.includes(x);
  const filterable = filter(both(isColumnFilterable, complement(isColumnFiltered)), allColumns);
  // Filter out the columns that are not visible
  const visible = filter(x => x.isVisible, filterable);
  return take(NUM_FILTERS_TO_SHOW, concat(alreadyFiltered, visible));
};

const isNilOrEmpty = either(isNil, isEmpty);

export const FiltersSelector = <D extends UnknownObject>(
  props: FiltersSelectorProps<D>,
): JSX.Element => {
  const { allColumns, instance } = props;
  const { close: closeDrawer } = useCurrentModal();

  // Get initial state from URL
  const [filterUrlState] = useURLState({}, 'grid.filters');
  const [filters, setFilters] = React.useState(filterUrlState);

  // We are limiting the number of filters to show on the UI.
  const [columnsToShow, setColumnsToShow] = React.useState<ColumnInstance<D>[]>(
    getColumnsToShow(allColumns, filterUrlState),
  );

  const handleUpdateFilter = React.useCallback(
    ({ id, value }: { id: string; value: FilterValue }): void => {
      setFilters(isNilOrEmpty(value) ? omit([id], filters) : { ...filters, [id]: value });
    },
    [filters],
  );

  // Apply all the filters to the grid
  const applyFilters = React.useCallback((): void => {
    closeDrawer();
    const filtersToApply = Object.entries(filters).reduce(
      (acc, [id, value]) => [...acc, { id, value }],
      [],
    );
    instance?.setAllFilters(filtersToApply ?? []);
  }, [closeDrawer, filters, instance]);

  // Clear all the filters
  const clearFilters = (): void => {
    closeDrawer();
    instance?.setAllFilters([]);
  };

  // Hide filter on the FilterDropdown
  const hideFilter = React.useCallback(
    (id: string): void => {
      setColumnsToShow(columnsToShow.filter(column => column.id !== id));
    },
    [columnsToShow],
  );

  // Order the filters based on if they are filtered.
  // We will show the filters that are filtered first and then the filters that are not filtered.
  const handleColumnsToShow = React.useCallback(
    (columns: string[]): void => {
      const sortedColumns = allColumns
        .filter(column => columns.includes(column.id))
        .sort((a, b) => {
          if (a.id in filters && b.id in filters) {
            return 0;
          }
          if (a.id in filters) {
            return -1;
          }
          if (b.id in filters) {
            return 1;
          }
          return 0;
        });
      setColumnsToShow(sortedColumns);
    },
    [allColumns, filters],
  );

  // Number of filters applied
  const numFilters = Object.keys(filters).length;

  return (
    <div className={styles.container}>
      <FilterDropdown
        allColumns={allColumns}
        columnsToShow={columnsToShow}
        handleColumnsToShow={handleColumnsToShow}
      />
      <div className={styles.content}>
        <div className={styles.filtersList}>
          {columnsToShow.map(column => (
            <FilterContent
              key={column.id}
              column={column}
              hideFilter={hideFilter}
              isFiltered={column.id in filters}
              setFilter={handleUpdateFilter}
            />
          ))}
        </div>
      </div>
      <div className={styles.buttonsContainer}>
        <Button name="clear" type="button" variant="outline" onClick={clearFilters}>
          Clear
        </Button>

        <Button
          disabled={isEmpty(filters)}
          name="applyFilters"
          type="button"
          onClick={applyFilters}
        >
          Apply {numFilters} filter{numFilters === 1 ? '' : 's'}
        </Button>
      </div>
    </div>
  );
};

FiltersSelector.displayName = 'DataGrid__FiltersSelector';
