import { RefObject } from 'react';

import { getDocument } from './dom';
import { focusWithoutScrolling } from './focusWithoutScrolling';
import { isHTMLElement } from './isHTMLElement';

export const focusElement = <T extends HTMLElement>(el: T | RefObject<T>): void => {
  const element = typeof el === 'object' && 'current' in el ? el.current : el;

  if (!element) {
    return;
  }

  if (isHTMLElement(element) && getDocument().activeElement !== element) {
    focusWithoutScrolling(element);
  }
};
