import * as React from 'react';

import { useToggle } from '@cobbler-io/hooks/src/useToggle';

import { Button } from '@cobbler-io/core-ui/src/Button';
import { Icon } from '@cobbler-io/core-ui/src/Icon';
import { Portal } from '@cobbler-io/core-ui/src/Portal';

import { OverflowDropdown } from './OverflowDropdown';

export type OverflowTabProps<T extends { isOverflow: boolean }> = {
  children: React.ReactElement<T>[];
  className?: string;
};

export const OverflowTab = <T extends { isOverflow: boolean }>(props: OverflowTabProps<T>) => {
  const { children, ...rest } = props;
  const ref = React.useRef<HTMLButtonElement>(null);
  const { active, deactivate, toggle } = useToggle(false);

  return (
    <Button ref={ref} name="open-tab-overflow" {...rest} small variant="svg" onClick={toggle}>
      <Icon type="moreHorizontal" />
      {active && (
        <Portal mountWithChildren>
          <OverflowDropdown close={deactivate} reference={ref}>
            {React.Children.map(children, child =>
              React.cloneElement(
                child,
                // @ts-expect-error: this should be fine
                { isOverflow: true },
              ),
            )}
          </OverflowDropdown>
        </Portal>
      )}
    </Button>
  );
};

OverflowTab.displayName = 'OverflowTab';
