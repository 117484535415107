import { useCurrentBudget } from '@cobbler-io/redux/src/modules/current-budget';
import { useCurrentUser } from '@cobbler-io/redux/src/modules/current-user';
import { useGraphStatus } from '@cobbler-io/redux/src/modules/graph-status';
import { useFiscalYearStart } from '@cobbler-io/redux/src/modules/tenant-settings';

import { graphRefetchHammer } from '@cobbler-io/app/src/api/graph/graph-hydrator';
import { useCurrentRange } from '@cobbler-io/app/src/providers';

import { useGraphContext } from '@cobbler-io/collection/src/Graph/react';

import { useApolloClient } from '@apollo/react-hooks';

/**
 * Re-fetches and re-hydrates the whole graph. This is VERY expensive, so use wisely
 */
export const useGraphRefetchHammer = (): NullaryFn<Promise<void>> => {
  const [budget] = useCurrentBudget();
  const range = useCurrentRange();
  const client = useApolloClient();
  const startMonth = useFiscalYearStart();
  const currentUser = useCurrentUser();
  const { graph } = useGraphContext();
  const { setStatuses, clearStatuses } = useGraphStatus();
  const { startString: start, endString: end } = range;

  return () =>
    !budget
      ? Promise.reject()
      : graphRefetchHammer({
          budgetId: budget.id,
          client,
          clearStatuses,
          currentUser,
          end,
          graph,
          revisionId: budget.selectedRevisionId,
          setStatuses,
          start,
          startMonth,
        });
};
