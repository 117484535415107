import { equals, filter, head, pipe, prop } from 'ramda';

type Revision = {
  budgetRevisionId: BudgetRevisionId;
  amount: MinorCurrency;
};

export const getPlannedAtRevision = (
  id: BudgetRevisionId,
): UnaryFn<Revision[] | readonly Revision[], MinorCurrency> => {
  const isThisRevision = pipe(prop('budgetRevisionId'), equals(id));
  return pipe(filter(isThisRevision), head, prop('amount')) as UnaryFn<Revision[], MinorCurrency>;
};
