import * as React from 'react';

import { ISO8601 } from '@cobbler-io/formatters/src/dates';

import { getStartOfDay, MAX_SAFE_DATE, MIN_SAFE_DATE } from '@cobbler-io/dates/src';
import { addYears } from '@cobbler-io/dates/src/addYears';
import { getEndOfDay } from '@cobbler-io/dates/src/getEndOfDay';
import { getEndOfMonth } from '@cobbler-io/dates/src/getEndOfMonth';
import { getStartOfMonth } from '@cobbler-io/dates/src/getStartOfMonth';
import { tzAdjust } from '@cobbler-io/dates/src/tzAdjust';

import { Afterware, Middleware, useCachedReducer } from '@cobbler-io/hooks/src/useCachedReducer';

import { useFiscalYearStart } from '@cobbler-io/redux/src/modules/tenant-settings';

import { toDateToken } from '@cobbler-io/app/src/utils/toDateToken';

import {
  CurrentRangeContext, PeriodLength, RangeContextActions, SetMinAndMaxPayload, SetParamsPayload,
} from './CurrentRangeContext';
import { periodsInRange } from './periodsInRange';
import {
  RangeAction as Action, rangeActions, rangeReducer, RangeState as State,
} from './rangeReducer';

/* eslint-disable react/no-unused-prop-types */
export type CurrentRangeProviderProps = {
  defaultMin: LocalDate;
  defaultMax: LocalDate;
  defaultStart: LocalDate;
  defaultEnd: LocalDate;
  min?: LocalDate; // Control the min
  max?: LocalDate; // Control the max
  defaultPeriodLength: PeriodLength;
  cacheIdentifier?: string;
  children: React.ReactNode;
  afterware?: Afterware<State, Action>;
  middleware?: Middleware<State, Action>;
};
/* eslint-enable react/no-unused-prop-types */

const getInitialState = (params: CurrentRangeProviderProps & { fysm: number }): State => {
  const { defaultPeriodLength: periodLength, fysm } = params;

  const proposedMin =
    params.min || params.defaultMin
      ? getStartOfMonth(params.min ?? params.defaultMin)
      : new Date(MIN_SAFE_DATE);
  const proposedMax =
    params.max || params.defaultMax
      ? getEndOfMonth(params.max ?? params.defaultMax)
      : new Date(MAX_SAFE_DATE);

  // If the end is before the start, then switch it around
  const [defaultStart, defaultEnd] =
    getStartOfDay(params.defaultStart) > getEndOfDay(params.defaultEnd)
      ? [params.defaultEnd, params.defaultStart]
      : [params.defaultStart, params.defaultEnd];

  // If the max is less than the min, then switch those around
  const [defaultMin, defaultMax] =
    proposedMin > proposedMax ? [proposedMax, proposedMin] : [proposedMin, proposedMax];

  const [min, maybeStart] = [defaultMin, defaultStart].map(getStartOfMonth);
  const [max, maybeEnd] = [defaultMax, defaultEnd].map(getEndOfMonth);

  // Do not let start / end exceed min / max
  const start0 = maybeStart < min ? min : maybeStart;
  const end0 = maybeEnd > max ? max : maybeEnd;

  const [start, end] = start0 >= end0 ? [start0, addYears(start0, 1)] : [start0, end0];

  return {
    end,
    endString: ISO8601(end),
    fysm,
    max,
    min,
    name: 'Range', // TODO find the real name here
    periodLength,
    periods: periodsInRange({ end, fysm, periodLength, start }),
    start,
    startString: ISO8601(start),
  };
};

type SerializedState = {
  end: ISO8601String;
  max: ISO8601String;
  min: ISO8601String;
  name: string;
  periodLength: PeriodLength;
  start: ISO8601String;
};

const serializeState = (period: State): SerializedState => {
  return {
    end: toDateToken(period.end),
    max: toDateToken(period.max),
    min: toDateToken(period.min),
    name: period.name,
    periodLength: period.periodLength,
    start: toDateToken(period.start),
  };
};

const deserializeState =
  (fysm: number) =>
  (serialized: SerializedState): State => {
    const [min, start] = [serialized.min, serialized.start].map(tzAdjust).map(getStartOfDay);
    const [max, end] = [serialized.max, serialized.end].map(tzAdjust).map(getEndOfDay);
    return {
      end,
      endString: ISO8601(end),
      fysm,
      max,
      min,
      name: serialized.name,
      periodLength: serialized.periodLength,
      periods: periodsInRange({ end, fysm, periodLength: serialized.periodLength, start }),
      start,
      startString: ISO8601(start),
    };
  };

export const CurrentRangeProvider = (props: CurrentRangeProviderProps): JSX.Element => {
  const fysm = useFiscalYearStart();
  const { children, cacheIdentifier, afterware, middleware, min, max } = props;
  const deserialize = React.useMemo(() => deserializeState(fysm), [fysm]);
  const { getState, dispatch } = useCachedReducer<State, Action, SerializedState>({
    afterware,
    deserialize,
    initialState: getInitialState({ ...props, fysm }),
    middleware,
    name: cacheIdentifier ? ['CurrentRangeProvider2023', cacheIdentifier].join('|') : undefined,
    reducer: rangeReducer,
    serialize: serializeState,
  });

  const actions: RangeContextActions & { getState: () => State } = React.useMemo(() => {
    return {
      getState,
      setEnd: (end: LocalDate) => dispatch(rangeActions.setEnd(end)),
      setMax: (x: LocalDate) => dispatch(rangeActions.setMax(x)),
      setMin: (x: LocalDate) => dispatch(rangeActions.setMin(x)),
      setMinAndMax: (params: SetMinAndMaxPayload) => dispatch(rangeActions.setMinAndMax(params)),
      setName: (name: string) => dispatch(rangeActions.setName(name)),
      setParams: (params: SetParamsPayload) => dispatch(rangeActions.setParams(params)),
      setPeriodLength: (periodLength: PeriodLength) =>
        dispatch(rangeActions.setPeriodLength(periodLength)),
      setStart: (start: LocalDate) => dispatch(rangeActions.setStart(start)),
    };
  }, [dispatch, getState]);

  const state = getState();

  React.useEffect(() => {
    if (!min || !max) {
      // We will override if we explicitly set the min and max
      return;
    }
    if (ISO8601(min) !== ISO8601(state.min) || ISO8601(max) !== ISO8601(state.max)) {
      actions.setMinAndMax({ max, min });
    }
  }, [min, state.min, max, state.max, actions, getState]);

  const ctx = React.useMemo(() => ({ ...state, ...actions }), [state, actions]);

  return <CurrentRangeContext.Provider value={ctx}>{children}</CurrentRangeContext.Provider>;
};

CurrentRangeProvider.displayName = 'CurrentRangeProvider';
