import * as React from 'react';

import { noop } from '@cobbler-io/utils/src';
import { head } from '@cobbler-io/utils/src/array';
import { pick } from '@cobbler-io/utils/src/pick';
import { prop } from '@cobbler-io/utils/src/prop';

import { useIsMounted } from '@cobbler-io/hooks/src/useIsMounted';
import { useMountedState } from '@cobbler-io/hooks/src/useMountedState';
import { useResizeObserver } from '@cobbler-io/hooks/src/useResizeObserver';

import { OverflowTab } from './OverflowTab';
import { TabRouterHeaderProps } from './TabRouterHeader';

import styles from './TabRouterList.scss';

type ComponentWithRef<P = Record<string, unknown>, R = any> = React.ReactElement<P> & { ref: React.RefObject<R> };
type TabHeaderType = ComponentWithRef<TabRouterHeaderProps, HTMLAnchorElement>;

type TabListProps = {
  children: React.ReactNode[];
  onKeyDown?: React.KeyboardEventHandler<HTMLDivElement>;
};

// It should be 50ish, 40px for the button + 8px margin, + room for error
const OVERFLOW_BUTTON_SIZE = 50;

type SortedTabs = { left: TabHeaderType[]; right: TabHeaderType[] };

/**
 * Sorts tabs into a `left` and `right` buckets where left can be rendered normally, and right
 * can be rendered in an overflow container.
 *
 * Ensures that the active tab is in the `left` bucket
 */
const sortTabs = (
  container: React.RefObject<HTMLDivElement>,
  tabs: TabHeaderType[],
  sizes: React.MutableRefObject<Record<string, number>>,
): SortedTabs => {
  const containerWidth = container.current?.clientWidth;

  if (!containerWidth) {
    return { left: tabs, right: [] };
  }
  // Sometimes there's more than one active tab. This is due to just using `useMatch`
  // TODO find a different way to get the active tab correctly. (@reach/router doesn't
  // really give us any tools to help us out here)
  const activeTab = tabs.find(tab => tab.props.active) || head(tabs);
  const getSize = (el: TabHeaderType): number =>
    el.ref?.current?.clientWidth ?? sizes.current[el.props.link];

  const result = tabs.reduce(
    (acc: SortedTabs & { total: number }, tab) => {
      const tabWidth = getSize(tab);
      acc.total = acc.total + tabWidth + 10;

      if (tab === activeTab) {
        // The active tab should always be in the main render area
        acc.left.push(tab);
        return acc;
      }

      if (acc.total < containerWidth) {
        // We have space in the container to push more things
        if (acc.left.includes(activeTab) || getSize(activeTab) + acc.total + 10 < containerWidth) {
          // We want to make sure that we can push the active tab into the main area, so, we check
          // that it's already in left or that we still have room to push it
          acc.left.push(tab);
        } else {
          // We don't have room for the activeTab in the main area, so just push this into the right
          acc.right.push(tab);
        }
        return acc;
      }

      // We don't have room for the tab in the main area, so push it into the right
      acc.right.push(tab);

      return acc;
    },
    { left: [], right: [], total: OVERFLOW_BUTTON_SIZE },
  );

  return pick(['left', 'right'], result);
};

type TabList = { left: TabHeaderType[]; right: TabHeaderType[] };

export const TabRouterList = (props: TabListProps) => {
  const { children, onKeyDown = noop } = props;
  const ref = React.useRef<HTMLDivElement>(null);
  const sizeMap = React.useRef<Record<string, number>>({});
  const { width } = useResizeObserver({ ref });
  const [state, setState] = useMountedState<TabList>({ left: [], right: [] });
  const listenerMap = React.useRef<Map<string, any>>(new Map());
  const isMounted = useIsMounted();
  const subscribe = React.useCallback(payload => {
    const id = payload.props.link;
    listenerMap.current.set(id, payload);

    if (!payload.props.isOverflow && payload.ref.current) {
      sizeMap.current[id] = payload.ref.current.getBoundingClientRect().width;
    }

    const unsubscribe = () => {
      listenerMap.current.delete(id);
    };

    return unsubscribe;
  }, []);

  const arrayChildren = React.useMemo(
    () =>
      (React.Children.toArray(children) as TabHeaderType[]).map(child =>
        React.cloneElement(child, { key: child.props.id, subscribe }),
      ),
    [children, subscribe],
  ) as TabHeaderType[];

  const activeTab = head(arrayChildren.filter(prop('active')));
  const activeLink = activeTab ? activeTab.props.link : '';

  React.useLayoutEffect(() => {
    const { left, right } = sortTabs(ref, arrayChildren, sizeMap);
    if (isMounted.current) {
      setState({ left, right });
    }
  }, [activeLink, arrayChildren, isMounted, setState, width]);

  if (state && state.right && state.right.length) {
    return (
      <div ref={ref} className={styles.tabList} role="tablist" tabIndex={0} onKeyDown={onKeyDown}>
        {state.left}
        <OverflowTab>{state.right}</OverflowTab>
      </div>
    );
  }

  return (
    <div ref={ref} className={styles.tabList} role="tablist" tabIndex={0} onKeyDown={onKeyDown}>
      {arrayChildren}
    </div>
  );
};

TabRouterList.displayName = 'TabRouterList';
