import { map, prop } from 'ramda';

import { BudgetLineAtRevision } from '../types';

type GetMaxAmounts = {
  maxAmounts: Record<ISO8601String, MinorCurrency>;
  isTightlyBound: boolean; // TODO: No longer needed. Left for compatibility.
};

export const getMaxAmounts = (parent: BudgetLineAtRevision, isRevenueRootChild: boolean): GetMaxAmounts => ({
  maxAmounts: map(isRevenueRootChild ? prop('revenueUnallocated') : prop('unallocated'), parent.totalsByInterval),
  isTightlyBound: true,
});
