import React from 'react';

import { arraysAreEqual } from '@cobbler-io/utils/src/array/arraysAreEqual';
import { createRelayId } from '@cobbler-io/utils/src/createRelayId';
import { extractGuidFromRelayId } from '@cobbler-io/utils/src/destructureRelayId';

import { useDispatch, useSelector } from 'react-redux';

import {
  deleteRevision, setSelectedRevisionId, updateRevisionName, UpdateRevisionNamePayload,
  updateRevisions, updateRevisionsLockStatus, UpdateRevisionsPayload,
} from './current-budget';
import { revisionsSelector } from './revisionsSelector';
import { CurrentBudgetState, Revisions } from './types';

type BudgetRevisionTuple = [
  data: Revisions | null,
  actions: {
    select: (revisionId: BudgetRevisionId) => void;
    deleteRevision: UnaryFn<BudgetRevisionId, void>;
    updateLockStatus: (params: {
      locked: BudgetRevisionId[];
      unlocked: BudgetRevisionId[];
    }) => void;
    updateRevisionName: UnaryFn<UpdateRevisionNamePayload, void>;
    update: UnaryFn<UpdateRevisionsPayload, void>;
  },
];

export const useBudgetRevisions = (): BudgetRevisionTuple => {
  const dispatch = useDispatch();
  const actions = React.useMemo(
    () => ({
      deleteRevision: (revisionId: BudgetRevisionId) => dispatch(deleteRevision(revisionId)),
      select: (revisionId: BudgetRevisionId) => dispatch(setSelectedRevisionId(revisionId)),
      update: (payload: UpdateRevisionsPayload) => dispatch(updateRevisions(payload)),
      updateLockStatus: (params: { locked: BudgetRevisionId[]; unlocked: BudgetRevisionId[] }) =>
        dispatch(updateRevisionsLockStatus(params)),
      updateRevisionName: (payload: UpdateRevisionNamePayload) =>
        dispatch(updateRevisionName(payload)),
    }),
    [dispatch],
  );

  return [useSelector(revisionsSelector), actions];
};

const createRevisionStubId = createRelayId('BudgetRevisionType2');

type RelayRevisions = {
  /**
   * RelayType BudgetRevisionType2
   */
  selected: BudgetRevisionId;
  /**
   * RelayType BudgetReivsionType2
   */
  visible: [selected: BudgetRevisionId] | [original: BudgetRevisionId, selected: BudgetRevisionId];
  /**
   * RelayType BudgetReivsionType2[]
   */
  revisions: BudgetRevisionId[];
};

const selector = (state: { currentBudget: CurrentBudgetState }): RelayRevisions | null => {
  if (!state || !state.currentBudget) {
    return null;
  }

  const selected = createRevisionStubId(state.currentBudget.selectedRevisionId);
  const original = createRevisionStubId(state.currentBudget.originalRevisionId);

  const multipleVisible = state.currentBudget.visibleRevisionIds.length > 1;
  const visible = multipleVisible ? [original, selected] : [selected];

  const revisions = state.currentBudget.revisions.map(r => createRevisionStubId(r.id));

  return { selected, visible, revisions };
};

export const useRelayRevisions = (): RelayRevisions | null => {
  const val = useSelector(selector);
  const ref = React.useRef(val);

  if (!val) {
    return val;
  }

  if (!ref.current) {
    ref.current = val;
    return ref.current;
  }

  if (ref.current.selected !== val.selected || !arraysAreEqual(ref.current.visible, val.visible)) {
    ref.current = val;
  }

  return ref.current;
};

const rollingForecastSelector = (state: { currentBudget: CurrentBudgetState }): BudgetRevisionId => state.currentBudget.rollingForecastRevisionId;
export const useRollingForecastId = (): BudgetRevisionId => useSelector(rollingForecastSelector);

export const useRelayRevisionNames = (): Record<BudgetRevisionId, string> => {
  const [revisions] = useBudgetRevisions();
  const { revisions: relayRevisions } = useRelayRevisions()!;

  const revisionNames = relayRevisions.reduce<Record<BudgetRevisionId, string>>((acc, revId) => {
    const guid = extractGuidFromRelayId(revId);
    const name = revisions?.index[guid!]?.name ?? '';
    // eslint-disable-next-line functional/immutable-data
    acc[revId] = name;
    return acc;
  }, {});

  return revisionNames;
};
