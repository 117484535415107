import * as React from 'react';

import { add, head, map, merge, mergeWith, path, pipe, prop, reduce, reject, tail } from 'ramda';

import { EditorData, EditorDataLine, EditorLine, IntervalPlannedSpend } from './types';

type PlannedByInterval = Record<ISO8601String, MinorCurrency>;

export const extractEditorUnallocatedLine = (editorData: EditorData): EditorLine => {
  // If is root, unallocated line should ignore revenues
  const isRevenueProp = prop('isRevenue');
  const children =
    editorData.selfLine.ancestors.length === 0
      ? reject(isRevenueProp, editorData.lines)
      : editorData.lines;

  // (EditorDataLine) => { [start]: planned }
  const getPlannedByInterval: (line: EditorDataLine) => PlannedByInterval = pipe<
    EditorDataLine,
    readonly IntervalPlannedSpend[],
    PlannedByInterval
  >(
    prop('plannedSpend'),
    reduce((acc, el) => merge(acc, { [el.start]: el.planned }), {} as PlannedByInterval),
  );

  const emptyIntervals: Record<string, MinorCurrency> = reduce(
    (acc: Record<string, MinorCurrency>, start: string) => ({ ...acc, [start]: 0 }),
    {},
    map(path<string>(['dateRange', 'start']), editorData.intervals),
  );

  const plannedByInterval = map(getPlannedByInterval, children);

  const totalPlannedInChildrenByInterval: PlannedByInterval =
    plannedByInterval.length < 1
      ? emptyIntervals
      : reduce(
          (acc, planned) => mergeWith(add, acc, planned),
          head(plannedByInterval)!,
          tail(plannedByInterval),
        );

  // The main difference about the unallocated line and a regular line
  // is that the `spent` is shallow and that the planned is the planned - all the other planned.

  const plannedSpend = editorData.selfLine.plannedSpend.map(x => ({
    ...x,
    spent: x.spentShallow,
    planned: x.planned - (totalPlannedInChildrenByInterval?.[x.start] ?? 0),
  }));

  return {
    ...editorData.selfLine,
    accounts: [],
    childrenCount: 0,
    departments: [],
    id: 'unallocated',
    isRevenue: false, // not show revenue icon
    name: 'Unallocated',
    owner: {
      ...editorData.selfLine.owner,
      id: null,
      item: null,
    },
    plannedSpend,
    systemLine: {
      isSystemLine: true,
      tip: (
        <div style={{ lineHeight: 1.4, maxWidth: '300px', padding: '.5rem', textAlign: 'left' }}>
          This line is generated and cannot be edited. It represents everything on the current
          budget that has not been re-allocated to sub-budgets.
        </div>
      ),
    },
    vendors: [],
  };
};
