import * as React from 'react';

import { isReactElementOfType } from '@cobbler-io/utils/src/isReactElementOfType';

import { Column, ColumnElement } from './Column';

type DataGridTableProps = {
  /**
   * Only send `Column` elements as children (or arrays of columns)
   */
  children: React.ReactNode;
};

/**
 * A wrapper for Column components when defining a DataGrid
 */
export const DataGridTable = ({ children }: DataGridTableProps): JSX.Element => {
  const childArray = React.Children.toArray(children);
  const columns = childArray.filter<ColumnElement<any>>(
    // @ts-expect-error: this is correct
    x => Column.isColumn(x),
  );

  if (__DEV__) {
    if (childArray.length !== columns.length) {
      throw new Error('DataGridTable can accept only Columns. Do not pass other children');
    }
  }

  // @ts-expect-error We have a JSX type clash here. It's fine though
  return columns;
};

DataGridTable.displayName = 'DataGridTable';

export type DataGridTableElement = {
  type: (props: DataGridTableProps) => React.ReactElement<DataGridTableProps> | null;
  props: DataGridTableProps;
  key: React.Key | null;
};

// @ts-expect-error: the type is correct, but the React types mangle it a bit
export const isDataGridTable = isReactElementOfType<DataGridTableElement>(DataGridTable);

DataGridTable.isDataGridTable = isDataGridTable;
