import { ISO8601 } from '@cobbler-io/formatters/src/dates';

/* eslint-disable sort-keys, sort-keys-fix/sort-keys-fix */
import { getStartOfDay } from '@cobbler-io/dates/src';
import { getEndOfDay } from '@cobbler-io/dates/src/getEndOfDay';

import {
  PeriodLength, RangeContextValues, SetMinAndMaxPayload, SetParamsPayload,
} from './CurrentRangeContext';
import { periodsInRange } from './periodsInRange';

const SET_MIN = 'CURRENT_RANGE_CONTEXT/SET_MIN';
const SET_MAX = 'CURRENT_RANGE_CONTEXT/SET_MAX';
const SET_MIN_AND_MAX = 'CURRENT_RANGE_CONTEXT/SET_MIN_AND_MAX';
const SET_START = 'CURRENT_RANGE_CONTEXT/SET_START';
const SET_END = 'CURRENT_RANGE_CONTEXT/SET_END';
const SET_PARAMS = 'CURRENT_RANGE_CONTEXT/SET_PARAMS';
const SET_PERIOD_LENGTH = 'CURRENT_RANGE_CONTEXT/SET_PERIOD_LENGTH';
const SET_NAME = 'CURRENT_RANGE_CONTEXT/SET_NAME';

type SetMin = { type: 'CURRENT_RANGE_CONTEXT/SET_MIN'; payload: { min: LocalDate } };
type SetMax = { type: 'CURRENT_RANGE_CONTEXT/SET_MAX'; payload: { max: LocalDate } };
type SetStart = { type: 'CURRENT_RANGE_CONTEXT/SET_START'; payload: { start: LocalDate } };
type SetEnd = { type: 'CURRENT_RANGE_CONTEXT/SET_END'; payload: { end: LocalDate } };

type SetMinAndMax = {
  type: 'CURRENT_RANGE_CONTEXT/SET_MIN_AND_MAX';
  payload: { min: LocalDate; max: LocalDate };
};

type SetPeriodLength = {
  type: 'CURRENT_RANGE_CONTEXT/SET_PERIOD_LENGTH';
  payload: { periodLength: PeriodLength };
};

type SetName = { type: 'CURRENT_RANGE_CONTEXT/SET_NAME'; payload: { name: string } };

type SetParams = { type: 'CURRENT_RANGE_CONTEXT/SET_PARAMS'; payload: SetParamsPayload };

export type RangeAction =
  | SetMin
  | SetMax
  | SetMinAndMax
  | SetStart
  | SetEnd
  | SetPeriodLength
  | SetParams
  | SetName;

export type RangeState = RangeContextValues & { fysm: number };

const withDateStrings = (x: RangeState) => ({
  ...x,
  endString: ISO8601(x.end),
  startString: ISO8601(x.start),
});

export const rangeReducer = (state: RangeState, action: RangeAction): RangeState => {
  switch (action.type) {
    case SET_START:
      return withDateStrings({
        ...state,
        ...action.payload,
        periods: periodsInRange({
          start: getStartOfDay(action.payload.start),
          end: state.end,
          periodLength: state.periodLength,
          fysm: state.fysm,
        }),
      });
    case SET_END:
      return withDateStrings({
        ...state,
        ...action.payload,
        periods: periodsInRange({
          start: state.start,
          end: getEndOfDay(action.payload.end),
          periodLength: state.periodLength,
          fysm: state.fysm,
        }),
      });
    case SET_PARAMS:
      return withDateStrings({
        ...state,
        ...action.payload,
        periods: periodsInRange({
          start: getStartOfDay(action.payload.start ?? state.start),
          end: getEndOfDay(action.payload.end ?? state.end),
          periodLength: action.payload.periodLength ?? state.periodLength,
          fysm: state.fysm,
        }),
      });
    case SET_MIN:
      // Here we have the case the the min is now going to be before the start, so it implies that
      // the start must be moved up in order to not exist before the min
      if (action.payload.min > state.start) {
        return withDateStrings({
          ...state,
          start: action.payload.min,
          min: action.payload.min,
          periods: periodsInRange({
            start: getStartOfDay(action.payload.min),
            end: state.end,
            periodLength: state.periodLength,
            fysm: state.fysm,
          }),
        });
      }
      return { ...state, ...action.payload };
    case SET_MAX:
      // Here we have the case that the max is greater than the end, so it implies that the end must
      // be moved back in order not to exceed the bound of the max
      if (action.payload.max < state.end) {
        return withDateStrings({
          ...state,
          end: action.payload.max,
          max: action.payload.max,
          periods: periodsInRange({
            start: state.min,
            end: getEndOfDay(action.payload.max),
            periodLength: state.periodLength,
            fysm: state.fysm,
          }),
        });
      }
      return { ...state, ...action.payload };
    case SET_MIN_AND_MAX: {
      const min = getStartOfDay(action.payload.min);
      const max = getEndOfDay(action.payload.max);
      const start = state.start < min ? min : state.start;
      const end = state.end > max ? max : state.end;
      const periods = periodsInRange({
        start,
        end,
        periodLength: state.periodLength,
        fysm: state.fysm,
      });
      return withDateStrings({ ...state, min, start, max, end, periods });
    }
    case SET_PERIOD_LENGTH:
      return withDateStrings({
        ...state,
        periodLength: action.payload.periodLength,
        periods: periodsInRange({
          start: state.start,
          end: state.end,
          periodLength: action.payload.periodLength,
          fysm: state.fysm,
        }),
      });
    default:
      return state;
  }
};

export const rangeActions = {
  setMin: (min: LocalDate): SetMin => ({ type: SET_MIN, payload: { min } }),
  setMax: (max: LocalDate): SetMax => ({ type: SET_MAX, payload: { max } }),
  setMinAndMax: (payload: SetMinAndMaxPayload): SetMinAndMax => ({
    type: SET_MIN_AND_MAX,
    payload,
  }),
  setStart: (start: LocalDate): SetStart => ({ type: SET_START, payload: { start } }),
  setEnd: (end: LocalDate): SetEnd => ({ type: SET_END, payload: { end } }),
  setParams: (payload: SetParamsPayload): SetParams => ({ type: SET_PARAMS, payload }),
  setPeriodLength: (periodLength: PeriodLength): SetPeriodLength => ({
    type: SET_PERIOD_LENGTH,
    payload: { periodLength },
  }),
  setName: (name: string): SetName => ({ type: SET_NAME, payload: { name } }),
};
