/* eslint-disable no-bitwise, no-plusplus, no-restricted-syntax, @typescript-eslint/no-magic-numbers, functional/no-let */

export const hash = (str: string): number => {
  let value = 5381;
  let i = str.length;
  while (i) {
    value = (value * 33) ^ str.charCodeAt(--i);
  }

  return value >>> 0;
};
