import * as React from 'react';

import { execIfFunc } from '@cobbler-io/utils/src';

import { Button } from '@cobbler-io/core-ui/src/Button';
import { useCurrentModal } from '@cobbler-io/core-ui/src/Modal';

export const CancelButton = () => {
  const { close } = useCurrentModal();

  return (
    <Button name="cancel" variant="text" onClick={() => execIfFunc(close)}>
      Cancel
    </Button>
  );
};
