/* eslint-disable camelcase */
import { identity } from '@cobbler-io/utils/src/identity';

import { Field } from '@cobbler-io/core-ui/src/Field';

import {
  EditLineNameInput, EditLineNameMutation, Exact, useEditLineNameMutation,
} from '@cobbler-io/app/src/api/graphql-types';

import { MutationUpdaterFn } from 'apollo-client';
import gql from 'graphql-tag';
import { equals, map, pathOr, pipe, prop, reject, trim } from 'ramda';
import { CellProps } from 'react-table';

import { EditorLine } from '../types';
import {
  acceptedTextKeys, EditorInput, InputParams, ModalEditorHandlers, useModalEditor,
} from './useModalEditor';

type OptResp = (
  vars: Exact<{
    input: EditLineNameInput;
  }>,
) => EditLineNameMutation;

/**
 * Creates an optimistic response for the mutation, which is basically the return
 * of the mutation
 * @see https://www.apollographql.com/docs/react/v2/performance/optimistic-ui/
 */
const optimisticResponse: OptResp = ({ input }) => {
  const { id, name } = input;
  return {
    __typename: 'Mutation',
    a_editLineName: {
      __typename: 'EditLineNamePayload',
      id,
      name,
    },
  };
};

const WRITE_BUDGET_LINE_NAME_FRAGMENT = gql`
  fragment writeBudgetLineName on BudgetEditorLineType {
    __typename
    id
    name
  }
`;

/**
 * The update receives the input, and returns a function that will update the cache.
 *
 * It's weird, but I need the budgetLineId, which is not in the response
 *
 * This seems to work, but every once in awhile it seems to do nothing. There might have
 * been some cache evictions.
 */
const update: MutationUpdaterFn<EditLineNameMutation> = (cache, result) => {
  /**
   * Updates the columns on the BudgetEditorTable
   */
  const updateBudgetEditor = () => {
    const { a_editLineName } = result.data!;
    const { id, name } = a_editLineName;
    const fragmentId = ['BudgetEditorLineType', id].join(':');
    const lineFragment = cache.readFragment({
      fragment: WRITE_BUDGET_LINE_NAME_FRAGMENT,
      id: fragmentId,
    });
    cache.writeFragment({
      data: { ...(lineFragment as Record<string, unknown>), name },
      fragment: WRITE_BUDGET_LINE_NAME_FRAGMENT,
      id: fragmentId,
    });
  };

  /**
   * Updates the columns on the BudgetOverviewTable
   */
  const updateBudgetOverviewLineTable = () => {};

  updateBudgetEditor();
  updateBudgetOverviewLineTable();
};

const toLocaleLowerCase = (x: string): string => x.toLocaleLowerCase();
const normalize = pipe(trim, toLocaleLowerCase);
const isEmptyString = pipe(trim, equals(''));

const getAllRows: UnaryFn<CellProps<EditorLine>, string[]> = pipe(
  pathOr<EditorLine[]>([], ['cell', 'column', 'preFilteredRows']),
  map(pathOr<string>('', ['original', 'name'])),
  reject(equals(pathOr<string>('', ['cell', 'row', 'original', 'name']))),
);

const validate = (cellProps: CellProps<EditorLine>) => {
  const allRowNames = getAllRows(cellProps);

  return (value: string | string[]) => {
    if (typeof value !== 'string') {
      // This is just to appease TS. `value` will always be a string
      return false;
    }

    if (isEmptyString(value)) {
      // Error message: we need a name
      return 'Cannot be blank';
    }

    const alreadyExists = pipe(normalize, equals(normalize(value)));

    return allRowNames.some(alreadyExists)
      ? `Another budget line already has the name "${value}"`
      : false;
  };
};

const getName = pipe(prop('value'), trim);

type RelativeEditorParams = {
  getWeight: NullaryFn<number>;
};

export const useEditLineName = (params: RelativeEditorParams): ModalEditorHandlers => {
  const { getWeight } = params;
  const toValue = identity;
  // const budgetLine = useBudgetLineContext()
  // const revision = useSelectedRevisionId();

  const getDefaultValue = identity;

  const createInput = (p: InputParams<EditorLine['name'], string>): EditLineNameInput => ({
    id: p.cellProps.row.original.id,
    name: getName(p),
  });

  // So I need to find a way to switch this thing out so that we use
  // the `add` mutation if it is the placeholder line, and we use the `edit`
  // mutation if it's the other one...

  return useModalEditor<EditLineNameInput, EditLineNameMutation, EditorInput, EditorLine['name']>({
    Field,
    acceptedKeysHandler: acceptedTextKeys,
    createInput,
    getDefaultValue,
    getWeight,
    optimisticResponse: () => optimisticResponse,
    shouldDisable: cell => cell.row.original.linkedToDriver,
    toValue,
    update: () => update,
    useMutationHook: useEditLineNameMutation,
    validate,
  });

  // Basically, this should be the inner cell. We need to make it so that when the focus lands on
  // the outer cell, we check for keys. If it's valid to start editing, then we automatically switch
  // to the inner cell and start editing. If it's not, then, well, we do nothing.
};
