import { DateRangeObject } from './DateRange';
import { getFiscalYear } from './getFiscalYear';
import { DateLike } from './isDateLike';

export type DateRangeWithName = DateRangeObject & { name: string };

export const getFiscalYearDates = (date: DateLike, startMonth = 0): DateRangeWithName => {
  const year = getFiscalYear(date, startMonth);
  const start = new Date(year, startMonth, 1, 0, 0, 0, 0);
  const end = new Date(start);
  end.setFullYear(end.getFullYear() + 1);
  end.setMilliseconds(-1);

  return {
    name: `FY${year}`,
    start,
    end,
  };
};
