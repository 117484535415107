/**
 * Flip the sign of a number when n !== 0 and shouldFlip is true 
 */
export const flipSign = (n: number, shouldFlip = true): number => {
  if (!shouldFlip || n === 0) {
    return n;
  }
  
  return n * -1;
};
