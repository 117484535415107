/* eslint-disable no-param-reassign, no-restricted-syntax, functional/no-let, functional/immutable-data */
import React from 'react';

import { T } from 'ramda';
import {
  actions, ensurePluginOrder, IdType, makePropGetter, Row, TableInstance, useGetLatest,
  useMountedLayoutEffect,
} from 'react-table';

type SelectionState = 'indeterminate' | 'unselected' | 'selected';

const getRowSelectedState = <D extends UnknownObject>(
  row: Row<D>,
  selectedRowIds: Record<IdType<D>, boolean>,
  getSubRows?: (subRow: Row<D>) => Row<D>[],
): SelectionState => {
  if (selectedRowIds[row.id]) {
    return 'selected';
  }

  const subRows = getSubRows && row.isExpanded ? getSubRows(row) : [];

  if (subRows?.length) {
    let allChildrenSelected = true;
    let someSelected = false;

    subRows.forEach(subRow => {
      if (someSelected && !allChildrenSelected) {
        return;
      }

      if (getRowSelectedState(subRow, selectedRowIds, getSubRows) === 'selected') {
        someSelected = true;
      } else {
        allChildrenSelected = false;
      }
    });

    if (allChildrenSelected) {
      return 'selected';
    }

    if (someSelected) {
      return 'indeterminate';
    }
  }

  return 'unselected';
};

export const useInstance = <D extends UnknownObject>(instance: TableInstance<D>): void => {
  const {
    data,
    rows,
    getHooks,
    plugins,
    autoResetSelectedRows = true,
    state: { selectedRowIds },
    selectSubRows = true,
    dispatch,
    page,
    getSubRows,
    isRowSelectable = T,
  } = instance;

  ensurePluginOrder(
    plugins,
    ['useFilters', 'useGroupBy', 'useSortBy', 'useExpanded', 'usePagination'],
    'useRowSelect',
  );

  // Inject isSelectable prop to all rows based on the table's isRowSelectable prop
  rows.forEach(row => {
    row.isSelectable = isRowSelectable(row);
  });

  // Calculate and memoize selectedFlatRows. This only stores what's visible
  const selectedFlatRows: Row<D>[] = React.useMemo(() => {
    const rowsSelected = [] as Row<D>[];

    rows.forEach(row => {
      if (row.isSelectable) {
        const selectState = getRowSelectedState(
          row,
          selectedRowIds,
          selectSubRows ? getSubRows : undefined,
        );

        row.isSelected = selectState === 'selected';
        row.isSomeSelected = selectState === 'indeterminate';

        if (row.isSelected) {
          rowsSelected.push(row);
        }
      }
    });

    return rowsSelected;
  }, [rows, selectSubRows, selectedRowIds, getSubRows]);

  const visibleSelectable: Row<D>[] = rows.filter(row => row.isSelectable);

  // TODO: Optimize these! Are these the fastest ways to calculate all/page selected?
  const isAllRowsSelected =
    visibleSelectable?.length > 0 && !visibleSelectable.some(({ id }) => !selectedRowIds[id]);
  const isAllPageRowsSelected =
    page?.length > 0 && !page?.some(row => row.isSelectable && !selectedRowIds[row.id]);

  const getAutoResetSelectedRows = useGetLatest(autoResetSelectedRows);

  useMountedLayoutEffect(() => {
    if (getAutoResetSelectedRows()) {
      dispatch({ type: actions.resetSelectedRows });
    }
  }, [dispatch, data]);

  const toggleAllRowsSelected = React.useCallback(
    value => dispatch({ type: actions.toggleAllRowsSelected, value }),
    [dispatch],
  );

  const toggleAllPageRowsSelected = React.useCallback(
    value => dispatch({ type: actions.toggleAllPageRowsSelected, value }),
    [dispatch],
  );

  const toggleRowSelected = React.useCallback(
    (id, value) => dispatch({ id, type: actions.toggleRowSelected, value }),
    [dispatch],
  );

  const getInstance = useGetLatest(instance);

  const getToggleAllRowsSelectedProps = makePropGetter(
    // @ts-expect-error: Looks like `makePropGetter` is incorrectly typed.
    getHooks().getToggleAllRowsSelectedProps,
    { instance: getInstance() },
  );

  const getToggleAllPageRowsSelectedProps = makePropGetter(
    // @ts-expect-error: Looks like `makePropGetter` is incorrectly typed.
    getHooks().getToggleAllPageRowsSelectedProps,
    { instance: getInstance() },
  );

  // Attach new props to the instance
  Object.assign(instance, {
    getToggleAllPageRowsSelectedProps,
    getToggleAllRowsSelectedProps,
    isAllPageRowsSelected,
    isAllRowsSelected,
    selectedFlatRows,
    toggleAllPageRowsSelected,
    toggleAllRowsSelected,
    toggleRowSelected,
  });
};
