import * as React from 'react';

import { Field } from '@cobbler-io/core-ui/src/Field';
import { Heading } from '@cobbler-io/core-ui/src/Heading';

import { ColumnInstance, TableInstance } from 'react-table';

import styles from './ColumnSelection.scss';

type ColumnSelectionProps = {
  count: number;
  getInstance: NullaryFn<TableInstance>;
};

export const ColumnSelection = (props: ColumnSelectionProps): JSX.Element => {
  const { count, getInstance } = props;

  const fields = getInstance()
    .allColumns.filter((x: ColumnInstance) => x.canExport)
    .sort((a: ColumnInstance, b: ColumnInstance) => {
      const aa: string = a.label ?? typeof a.Header === 'string' ? a.Header : a.id;
      const bb: string = b.label ?? typeof b.Header === 'string' ? b.Header : b.id;

      return aa.localeCompare(bb);
    })
    .map(col => {
      const { id, Header, parent, label } = col;
      if (typeof label === 'string') {
        return { label, value: id };
      }
      const l = [parent?.Header, Header].filter(x => typeof x === 'string').filter(Boolean);

      if (l.length > 0) {
        return { label: l.join(' > '), value: id };
      }

      return { label: id, value: id };
    });

  return (
    <>
      <Heading>Exporting {count} rows</Heading>
      <div>Please select the columns you want to export</div>
      <div className={styles.columnSelection}>
        {fields.map(({ label, value }) => (
          <Field key={value} defaultChecked id={value} label={label} name={value} type="checkbox" />
        ))}
      </div>
    </>
  );
};

ColumnSelection.displayName = 'ColumnSelection';
