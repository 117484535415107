import { BudgetRevisionTag, Revisions } from './types';

export const getRevisionTag = (revisions: Revisions, x: BudgetRevisionId): BudgetRevisionTag => {
  if (!x) {
    // If we get bad data, then we want to default to `active` for most things
    return 'active';
  }

  if (x === revisions.active) {
    return 'active';
  }

  if (x === revisions.latest) {
    return 'latest';
  }

  if (x === revisions.original) {
    return 'original';
  }

  return x;
};
