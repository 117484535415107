import * as React from 'react';

import { cx } from '@cobbler-io/utils/src/cx';
import { capitalize } from '@cobbler-io/utils/src/string/capitalize';

import { Button } from '@cobbler-io/core-ui/src/Button';

import { DatePickerContext } from './DatePickerContext';

import styles from './DatePickerField.scss';

export type PickerFieldProps = {
  className?: string;
  style?: React.CSSProperties;
  type: 'START' | 'END';
};

export const DatePickerField = (props: PickerFieldProps): JSX.Element => {
  const { type, className, ...rest } = props;

  const { setSelecting, selecting, start, end, formatDate } = React.useContext(DatePickerContext);

  const value = type === 'START' ? start : end;

  return (
    <div {...rest} className={cx(className)}>
      <Button
        className={cx(styles.button, selecting === type && styles.active)}
        name="select start"
        variant="text"
        onClick={() => setSelecting(type)}
      >
        <span className={styles.label}>{capitalize(type)}</span>
        <span>{value ? formatDate(value) : 'Not selected'}</span>
      </Button>
    </div>
  );
};

DatePickerField.displayName = 'DatePickerField';
