import * as React from 'react';

import { trunc } from '@cobbler-io/utils/src/numbers/trunc';

import { CellProps } from 'react-table';

const containerStyle = {
  display: 'flex',
  justifyContent: 'space-between',
};

/**
 * Displays numbers with a dollar sign left aligned while numbers are right-aligned
 */
export const DisplayAccounting = ({ cell: { value } }: CellProps<any>): JSX.Element => (
  <div style={containerStyle}>
    <span>$</span>
    <span>{typeof value === 'number' ? trunc(2, value) : value}</span>
  </div>
);

DisplayAccounting.displayName = 'DisplayAccounting';
