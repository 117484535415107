import * as React from 'react';

import { Button } from '@cobbler-io/core-ui/src/Button';
import { Dialog } from '@cobbler-io/core-ui/src/Dialog';
import { Icon } from '@cobbler-io/core-ui/src/Icon';
import { useModal } from '@cobbler-io/core-ui/src/Modal';

import { WebcamContext } from './WebcamContext';

type MainButtonProps = {
  className?: string;
  style?: React.CSSProperties;
};

export const MainButton = (props: MainButtonProps): JSX.Element | null => {
  const { state, stream, cameraPermission, actions, refs } = React.useContext(WebcamContext);
  const { create: createModal } = useModal();

  const clearCanvas = React.useCallback(() => {
    if (!refs.canvas.current) {
      return;
    }
    const { width, height } = refs.canvas.current;
    refs.canvas.current?.getContext('2d')?.clearRect(0, 0, width, height);
    actions.removeCapture();
  }, [refs.canvas]);

  const takePicture = React.useCallback(() => {
    const ctx = refs.canvas.current?.getContext('2d');

    if (!(refs.canvas.current && refs.video.current && ctx)) {
      return;
    }

    // When we take the picture, we need to swap the canvas, but if it's already swapped, don't do anything
    if (ctx.getTransform().a !== -1) {
      ctx.translate(state.width, 0);
      ctx.scale(-1, 1);
    }

    /**
     * @TODO change the math here so that it maps the stream dimensions onto the canvas correct in case
     *       they have different aspect ratios
     */
    ctx.clearRect(0, 0, state.width, state.height);
    ctx.drawImage(refs.video.current, 0, 0, state.width, state.height);
    actions.setCapture();
  }, [refs.canvas, refs.video, state.width, state.height]);

  const nicelyAskForPermission = () => {
    createModal(
      <Dialog title="Camera Permission" onConfirm={() => stream.start()}>
        In order to use a webcam to snap a photo, we need to get your permission. If you press
        confirm, then you will see the system dialog actually requesting permission.
      </Dialog>,
    );
  };

  const onButtonClick = React.useCallback(() => {
    if (state.capture) {
      // If there is a capture already taken, then clear the capture
      return clearCanvas();
    }

    if (cameraPermission === 'granted') {
      // If we have permission, then just snap a picture
      return takePicture();
    }

    if (cameraPermission === 'prompt') {
      // If we don't have permission, ask before having the system ask
      return nicelyAskForPermission();
    }

    return takePicture();
  }, [state.capture, nicelyAskForPermission, cameraPermission]);

  if (state.capture) {
    return null;
  }

  return (
    <Button {...props} name="toggle-video" variant="svg" onClick={onButtonClick}>
      <Icon size={20} type="camera" />
    </Button>
  );
};
