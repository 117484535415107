import * as React from 'react';

import { thisFiscalYear } from '@cobbler-io/utils/src/fiscal-year-dates';
import { pick } from '@cobbler-io/utils/src/pick';

import { ISO8601 } from '@cobbler-io/formatters/src/dates';

import { getEndOfMonth } from '@cobbler-io/dates/src/getEndOfMonth';
import { getStartOfMonth } from '@cobbler-io/dates/src/getStartOfMonth';
import { tzAdjust } from '@cobbler-io/dates/src/tzAdjust';

import AppBar from '@cobbler-io/core-ui/src/AppBar';
import { ErrorCallout } from '@cobbler-io/core-ui/src/Callout';

import { Budget, useCurrentBudget } from '@cobbler-io/redux/src/modules/current-budget';
import { useTenantFiscalYearStart } from '@cobbler-io/redux/src/modules/tenant-settings';

import { GetBudgetsQuery, useGetBudgetsQuery } from '@cobbler-io/app/src/api/graphql-types';
import { Loading } from '@cobbler-io/app/src/components/Loading';
import { getRevisionId } from '@cobbler-io/app/src/utils/revisions';
import { useBudgetLineParams } from '@cobbler-io/app/src/utils/useBudgetLineParams';

import { last, pipe } from 'ramda';

import { LogoutButton } from '../../oidc';

type BudgetGateProps = {
  children: JSX.Element;
};

type UpdateCurrentBudgetContextProps = {
  data: GetBudgetsQuery;
  tenantFYSM: ServerMonth;
  setBudget: UnaryFn<Budget, void>;
  revisionTag?: string;
};

const updateCurrentBudgetContext = (props: UpdateCurrentBudgetContextProps) => {
  const { data, tenantFYSM, setBudget, revisionTag } = props;
  const [budget] = data.a_budgets ?? [];

  if (!budget) {
    return;
  }

  const revisions = [...(budget.revisions ?? [])].map(x =>
    pick(['id', 'name', 'isForecast', 'isRollingForecast', 'isLocked'], x),
  );

  const { originalRevisionId, visibleRevisionIds } = budget;

  const activeRevisionId = budget.activeRevisionId ?? originalRevisionId;
  const latestRevisionId = last(revisions)?.id ?? originalRevisionId;

  const selectedRevisionId = getRevisionId(
    {
      activeId: activeRevisionId,
      latestId: latestRevisionId,
      originalId: originalRevisionId,
      revisions,
    },
    revisionTag,
  );

  const rollingForecastRevision = revisions.find(r => r.isRollingForecast);

  if (!rollingForecastRevision) {
    // eslint-disable-next-line no-console
    console.warn('No rolling forecast found for tenant');
  }

  const getMonthStart = pipe(tzAdjust, getStartOfMonth);
  const getMonthEnd = pipe(tzAdjust, getEndOfMonth);

  const fiscalYear = thisFiscalYear(tenantFYSM);
  const min = budget.dateRange?.start ? getMonthStart(budget.dateRange.start) : fiscalYear.start;
  const max = budget.dateRange?.end ? getMonthEnd(budget.dateRange.end) : fiscalYear.end;

  const rollingForecastRevisionId = rollingForecastRevision?.id ?? null;

  setBudget({
    activeRevisionId,
    id: budget.id,
    latestRevisionId,
    max: ISO8601(max),
    min: ISO8601(min),
    name: budget.name,
    originalRevisionId,
    revisions,
    rollingForecastRevisionId, // every tenant should have a rolling forecast revision
    selectedRevisionId: selectedRevisionId ?? activeRevisionId,
    visibleRevisionIds,
  });
};

export const BudgetGate = ({ children }: BudgetGateProps): JSX.Element => {
  const tenantFYSM = useTenantFiscalYearStart();
  const [currentBudget, setBudget] = useCurrentBudget();
  const { revisionTag } = useBudgetLineParams();

  const { data, loading, error } = useGetBudgetsQuery();

  React.useLayoutEffect(() => {
    const hasCurrentBudget = !!currentBudget;

    if (!data || hasCurrentBudget) {
      return;
    }

    updateCurrentBudgetContext({ data, revisionTag, setBudget, tenantFYSM });
  }, [currentBudget, data, setBudget, tenantFYSM, revisionTag]);

  if (error) {
    console.error(error);
    return (
      <div>
        <AppBar sidePanelActive={false} toggleSidePanel={() => {}}>
          <span>
            <LogoutButton name="sign out" />
          </span>
        </AppBar>

        <div>
          <ErrorCallout>Error getting budget data</ErrorCallout>
        </div>
      </div>
    );
  }

  if (loading) {
    return <Loading />;
  }

  return children;
};

BudgetGate.displayName = 'BudgetGate';
